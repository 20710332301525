import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { enableCustomReports } from "../../../constants";
import CountryGroupList from "./CountryGroupList";

const useStyles = makeStyles(() => ({
  customReports: {
    display: enableCustomReports ? "inline" : "none",
  },
}));

const CountryGroups = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h1">Country Groups</Typography>

      <p>
        <span className={classes.customReports}>
          The Custom Reports allow you to explore results not only by income
          group but also by additional groupings of countries based either on
          donor priorities or geographic regions.{" "}
        </span>
        Click on a group below to see the list of countries included within that
        group.
      </p>

      <CountryGroupList />
    </div>
  );
};

export default CountryGroups;
