import { useState } from "react";
import PropTypes from "prop-types";
import SideBySide from "../../../Common/SideBySide";
import ApacCpF1 from "../Figures/ApacCpF1";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
} from "../../../../utilities";

const calculateMetrics = (data) => ({
  total: data?.[3].value ?? 0,
  lowIncomeShare: data?.[0].share ?? 0,
  lowerMiddleIncomeShare: data?.[1].share ?? 0,
  upperMiddleIncomeShare: data?.[2].share ?? 0,
});

const Quantities = (props) => (
  <>
    <p>
      Currently {formatNumberAsHumanReadable(props.metrics.total)} units of{" "}
      {props.productName.toLowerCase()} are provided across low- and
      middle-income countries.
    </p>

    <p>
      {formatPercentage(props.metrics.lowIncomeShare)} of units are used in
      low-income countries,{" "}
      {formatPercentage(props.metrics.lowerMiddleIncomeShare)} are used in
      lower-middle-income countries, and the remaining{" "}
      {formatPercentage(props.metrics.upperMiddleIncomeShare)} are used in
      upper-middle-income countries.
    </p>
  </>
);

const Costs = (props) => (
  <>
    <p>
      Currently the cost of {props.productName.toLowerCase()} is{" "}
      {formatNumberAsHumanReadable(props.metrics.total, {
        prefix: "$",
      })}{" "}
      across low- and middle-income countries.
    </p>

    <p>
      {formatPercentage(props.metrics.lowIncomeShare)} of costs come from
      low-income countries,{" "}
      {formatPercentage(props.metrics.lowerMiddleIncomeShare)} are from
      lower-middle-income countries, and the remaining{" "}
      {formatPercentage(props.metrics.upperMiddleIncomeShare)} are from
      upper-middle-income countries.
    </p>
  </>
);

const ApacCpS1 = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(3);

  const { areaData: data } = useAreaQuery(
    `/custom/apac/cp/figures/1/${props.productId}/${selectedIndicatorId}`
  );

  const metrics = calculateMetrics(data);

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  return (
    <SideBySide
      component={
        <ApacCpF1
          productName={props.productName}
          indicatorId={selectedIndicatorId}
          data={data?.slice(0, -1)}
          onIndicatorChange={handleIndicatorChange}
        />
      }
    >
      {selectedIndicatorId === 3 ? (
        <Quantities productName={props.productName} metrics={metrics} />
      ) : (
        <Costs productName={props.productName} metrics={metrics} />
      )}

      <p>Use the dropdown to explore other indicators.</p>
    </SideBySide>
  );
};

ApacCpS1.propTypes = {
  productId: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default ApacCpS1;
