import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ReactComponent as TopCaption } from "../../images/top_caption.svg";
import { ReactComponent as BottomCaption } from "../../images/bottom_caption.svg";

const useStyles = makeStyles((theme) => ({
  component: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(3, 0),
  },
  area: {
    backgroundColor: "hotpink",
    borderRadius: 0,
    color: "white",
    fontSize: "1.125rem",
    margin: "0 5px",
    padding: theme.spacing(2, 0),
    textTransform: "none",
    width: 200,
  },
  caption: {
    margin: "0 5px",
    width: 830,
  },
  topCaption: {
    height: 64,
  },
  contraception: {
    backgroundColor: theme.palette.areas.contraception.primary,
  },
  menstrualHygiene: {
    backgroundColor: theme.palette.areas.menstrualHygiene.primary,
  },
  abortion: {
    backgroundColor: theme.palette.areas.abortion.primary,
  },
  maternalHealth: {
    backgroundColor: theme.palette.areas.maternalHealth.primary,
  },
  bottomCaption: {
    height: 80,
  },
}));

const Overview = () => {
  const classes = useStyles();

  return (
    <div className={classes.component}>
      <div className={classNames(classes.caption, classes.topCaption)}>
        <TopCaption />
      </div>
      <div>
        <Button
          component={Link}
          to="/contraception"
          className={classNames(classes.area, classes.contraception)}
        >
          Contraception
        </Button>
        <Button
          component={Link}
          to="/menstrual-hygiene"
          className={classNames(classes.area, classes.menstrualHygiene)}
        >
          Menstrual Hygiene
        </Button>
        <Button
          component={Link}
          to="/abortion"
          className={classNames(classes.area, classes.abortion)}
        >
          Abortion &amp; PAC
        </Button>
        <Button
          component={Link}
          to="/maternal-health"
          className={classNames(classes.area, classes.maternalHealth)}
        >
          Maternal Health
        </Button>
      </div>
      <div className={classNames(classes.caption, classes.bottomCaption)}>
        <BottomCaption />
      </div>
    </div>
  );
};

export default Overview;
