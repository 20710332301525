import { Typography } from "@material-ui/core";
import Footnote from "../../Common/Footnote";

const ContraceptionQuantities = () => (
  <div>
    <Typography variant="h1">Supply Quantities</Typography>

    <p>
      The quantity is the amount of supplies that a user of contraception must
      personally use over the course of a year to avoid becoming pregnant,
      multiplied by the number of users.
    </p>
    <p>
      Different approaches were used to estimate quantities for short-acting
      versus long-acting and permanent methods. Users of short-acting methods
      must use multiple units of supplies each year to obtain a full year of
      coverage
      <Footnote number={8}>
        The following global assumptions are used: 4 injectables, 12 EC doses,
        and 23.8 &quot;other&quot; methods based on a combination of female
        condoms, diaphragms, SDM, and other vaginal barrier methods. These
        figures are aligned to assumptions used in Adding It Up.
      </Footnote>
      . By contrast, most users of implants, IUDs, and sterilizations will have
      no need to receive any supplies in the current year as they are already
      protected by a service received in a previous year. Rather, any increase
      in use from the previous year is assumed to require one supply in addition
      to a share of the previous year&apos;s users whose use needs to be
      replaced
      <Footnote number={9}>
        This is done using each method&apos;s CYP factor: 10 for sterilization
        (8 in Bangladesh, India and Nepal), and an average factor of 3.15 for
        implants to reflect a mix of 3, 4, and 5 year durations, and 4.6 for
        IUDs.
      </Footnote>
      .
    </p>
    <p>
      Quantities are attributed to either the public or private sector, with the
      private sector further subdivided into subsidized and non-subsidized,
      based on the user estimates by sector described above.
    </p>
    <p>
      The quantities presented in this work are different from procurement
      volumes, which may be higher or lower depending on context. Procurement
      needs are typically a function of broader programmatic considerations,
      such as availability (or lack thereof) of resources; the desire to fill
      supply pipelines and maintain adequate inventory levels, or simply the
      volumes of product already in the supply chain.
    </p>
  </div>
);

export default ContraceptionQuantities;
