import { downloadCsvFile } from "../utilities";
import { useAnalytics } from "./useAnalytics";

export const csvDownloadGoalId = "4KGPFIVX";

const useCsvDownload = () => {
  const analytics = useAnalytics();

  const downloadCsv = (path, filename) => {
    downloadCsvFile(path, filename);

    analytics.trackGoal(csvDownloadGoalId);
  };

  return downloadCsv;
};

export default useCsvDownload;
