import PropTypes from "prop-types";
import Select from "../Common/Box/Select";
import ChooserOptions from "../Common/Box/Choosers/ChooserOptions";

const get_products = (costs) => ({
  "menstrual-hygiene": [
    {
      name: "All (inc. non-purpose-made)",
      value: 0,
    },
    {
      name: "Purpose-made",
      value: -1,
    },
    {
      name: "Disposables",
      value: 14,
    },
    {
      name: "Reusables",
      value: -2,
    },
    {
      name: "Reusable pads",
      value: 16,
    },
    {
      name: "Reusable cups",
      value: 3,
    },
  ],
  abortion: [
    {
      name: "All (exc. non-costed)",
      value: 1,
    },
    {
      name: "Surgical: Vacuum aspiration",
      value: 7,
    },
    {
      name: "Medical: All",
      value: 100,
    },
    {
      name: "Medical: Miso only",
      value: 10,
    },
    !costs
      ? {
          name: "Medical: Miso & mife",
          value: 9,
        }
      : {
          name: "Medical: Mife",
          value: 8,
        },
  ],
});

const ProductChooser = (props) => {
  const products = get_products(props.costs);

  return (
    <Select
      label="Product(s)"
      value={props.value}
      onChange={(value) => {
        const id = parseInt(value, 10);

        props.onChange?.(id);
      }}
    >
      <ChooserOptions
        options={products[props.area].filter(
          (product) => props.allowAll || !product.name.startsWith("All")
        )}
      />
    </Select>
  );
};

ProductChooser.propTypes = {
  area: PropTypes.oneOf([
    "contraception",
    "menstrual-hygiene",
    "abortion",
    "maternal-health",
  ]),
  costs: PropTypes.bool,
  value: PropTypes.number,
  allowAll: PropTypes.bool,
  onChange: PropTypes.func,
};

ProductChooser.defaultProps = {
  area: "menstrual-hygiene",
  costs: false,
  allowAll: true,
};

export default ProductChooser;
