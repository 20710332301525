import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import Table from "../../Common/Box/Table";

const number = 1;

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
    },
    "& th": {
      padding: theme.table.cellPadding,
    },
  },
  subtitle: {
    fontStyle: "italic",
    fontWeight: "bold",
  },
  indicator: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  ...Object.entries(theme.palette.areas.maternalHealth.methods).reduce(
    (accumulator, [key, value]) => {
      accumulator[key] = {
        backgroundColor: value,
      };

      return accumulator;
    },
    {}
  ),
}));

const Component = () => {
  const classes = useStyles();

  return (
    <Table
      key="MH_t11"
      number={number.toString()}
      title="Seven Priority Maternal Health Drugs Plus Two Emerging Drugs"
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th>Drug</th>
            <th>Interventions included</th>
          </tr>
        </thead>
        <tbody>
          <tr className={classes.subtitle}>
            <td colSpan={3}>Seven Priority Maternal Health Drugs</td>
          </tr>
          <tr>
            <td>
              <div
                className={classNames(classes.indicator, classes.ironFolicAcid)}
              />
            </td>
            <td>Iron and folic acid (IFA)</td>
            <td>Iron and folic acid supplementation during pregnancy</td>
          </tr>
          <tr>
            <td>
              <div
                className={classNames(classes.indicator, classes.hydralazine)}
              />
            </td>
            <td>Hydralazine (antihypertensive)</td>
            <td>Acute hypertension event management</td>
          </tr>
          <tr>
            <td>
              <div
                className={classNames(classes.indicator, classes.methyldopa)}
              />
            </td>
            <td>Methyldopa (antihypertensive)</td>
            <td>Acute, chronic and gestational hypertension management</td>
          </tr>
          <tr>
            <td>
              <div
                className={classNames(
                  classes.indicator,
                  classes.magnesiumSulfate
                )}
              />
            </td>
            <td>Magnesium Sulfate</td>
            <td>Pre-eclampsia and eclampsia treatment</td>
          </tr>
          <tr>
            <td>
              <div
                className={classNames(classes.indicator, classes.oxytocin)}
              />
            </td>
            <td>Oxytocin (uterotonic)</td>
            <td>
              Induction, augmentation, postpartum hemorrhage (PPH) prevention
              and treatment
            </td>
          </tr>
          <tr>
            <td>
              <div
                className={classNames(classes.indicator, classes.misoprostol)}
              />
            </td>
            <td>Misoprostol (uterotonic)</td>
            <td>Induction, PPH prevention and treatment</td>
          </tr>
          <tr>
            <td>
              <div
                className={classNames(classes.indicator, classes.metronidazole)}
              />
            </td>
            <td>Metronidazole (MTN)</td>
            <td>Infection treatment</td>
          </tr>
          <tr className={classes.subtitle}>
            <td colSpan={3}>Two Emerging drugs</td>
          </tr>
          <tr>
            <td>
              <div
                className={classNames(
                  classes.indicator,
                  classes.heatStableCarbetocin
                )}
              />
            </td>
            <td>Heat-stable carbetocin (HSC) (uterotonic)</td>
            <td>PPH prevention</td>
          </tr>
          <tr>
            <td>
              <div
                className={classNames(
                  classes.indicator,
                  classes.tranexamicAcid
                )}
              />
            </td>
            <td>Tranexamic acid (TXA)</td>
            <td>PPH treatment</td>
          </tr>
        </tbody>
      </table>
    </Table>
  );
};

export default Component;
