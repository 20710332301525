import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Overview from "../../Introduction/Overview";

const useStyles = makeStyles((theme) => ({
  graphic: {
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "center",
  },
}));

const OverviewOfLeap = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h1">Overview of LEAP</Typography>

      <p>
        Building on the approach developed and refined for the 2019
        Contraceptive Commodity Gap Analysis, LEAP draws on a range of data
        sources to estimate use and cost of Reproductive Health supplies in low-
        and middle-income countries. LEAP continues the analysis of
        contraceptive supplies that has been conducted previously for the
        Contraceptive Commodity Gap Analysis and adds analysis of supplies for
        three new health areas: menstrual hygiene, abortion and post-abortion
        care (PAC), and maternal health. For the three new health areas during
        2020 and early 2021 we consulted with experts and working groups and ran
        an online survey to inform the development of LEAP.
      </p>
      <p>
        While the four health areas are unique, for each we are able to present
        similar results: what services or supplies women use, the quantity of
        supplies needed, and the cost of these supplies. For each health area,
        we start by exploring current use and cost, then project potential
        changes between 2019 and 2030. To project changes into the future for
        the three new health areas, for which we have limited data, we explore
        three future scenarios. For contraception we continue with a single
        trajectory forward, informed by existing projections of contraceptive
        use.
      </p>
      <p>
        While we present results separately for each of the four health areas,
        the estimates for each are interrelated as shown in the visual below;
        these interrelationships have been considered in the future scenarios
        included within LEAP.
      </p>

      <div className={classes.graphic}>
        <Overview />
      </div>

      <p>
        Full details of the methods and analysis used for each health area can
        be found below.
      </p>
    </div>
  );
};

export default OverviewOfLeap;
