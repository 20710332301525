import { useState } from "react";
import PropTypes from "prop-types";
import MhmCpF2 from "../Figures/MhmCpF2";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import SideBySide from "../../../Common/SideBySide";
import { formatPercentage, getIndicatorDetails } from "../../../../utilities";

const calculateShare = (data) => {
  if (!data) {
    return 0;
  }

  const allCountryTotal = data[0].value;
  const topCountryTotal = data
    .slice(1)
    .reduce((accumulator, { value }) => accumulator + value, 0);

  return (topCountryTotal / allCountryTotal) * 100;
};

const MenstruatorSummary = (props) => (
  <p>
    The top ten countries in terms of the number of menstruators using{" "}
    {props.methodName.toLowerCase()} account for {formatPercentage(props.share)}{" "}
    of all reusable pads users across low- and middle-income countries.
  </p>
);

const CostSummary = (props) => (
  <p>
    The top ten countries in terms of the cost of reusable pads account for{" "}
    {formatPercentage(props.share)} of all {props.methodName.toLowerCase()}{" "}
    costs across low- and middle-income countries.
  </p>
);

const QuantitySummary = (props) => (
  <p>
    The top ten countries in terms of number of units of{" "}
    {props.methodName.toLowerCase()} purchased account for{" "}
    {formatPercentage(props.share)} of all {props.methodName.toLowerCase()}{" "}
    purchased across low- and middle-income countries.
  </p>
);

const Summary = ({ methodName, indicatorId, ...rest }) => {
  const share = calculateShare(rest.data);

  switch (indicatorId) {
    case 1:
      return <CostSummary methodName={methodName} share={share} />;

    case 2:
      return <MenstruatorSummary methodName={methodName} share={share} />;

    case 3:
      return <QuantitySummary methodName={methodName} share={share} />;

    default:
      return null;
  }
};

const MhmCpCurrentTopCountries = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(2);
  const [excludeLargeCountries, setExcludeLargeCountries] = useState(false);

  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cp/figures/2/${
      props.methodId
    }/${selectedIndicatorId}?limit=10${
      excludeLargeCountries ? "&excludeLargeCountries=true" : ""
    }`
  );

  const selectedIndicatorDetails = getIndicatorDetails(selectedIndicatorId);

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  const handleExcludeLargeCountriesChange = (value) => {
    setExcludeLargeCountries(value);
  };

  return (
    <>
      <SideBySide
        component={
          <MhmCpF2
            methodName={props.methodName}
            indicatorId={selectedIndicatorId}
            indicatorName={selectedIndicatorDetails.name}
            indicatorQualifier={selectedIndicatorDetails.qualifier}
            excludeLargeCountries={excludeLargeCountries}
            data={data}
            onIndicatorChange={handleIndicatorChange}
            onExcludeLargeCountriesChange={handleExcludeLargeCountriesChange}
          />
        }
        componentPosition="right"
      >
        {data ? (
          <Summary
            methodName={props.methodName}
            indicatorId={selectedIndicatorId}
            data={data}
          />
        ) : (
          <div />
        )}
      </SideBySide>
    </>
  );
};

MhmCpCurrentTopCountries.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
};

export default MhmCpCurrentTopCountries;
