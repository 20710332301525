import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import countries from "../../../data/countries.json";
import mapData from "../../../data/map.json";
import theme from "../../../theme.js";

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Low":
      return theme.palette.gni.lic;

    case "Lower-middle":
      return theme.palette.gni.lmic;

    case "Upper-middle":
      return theme.palette.gni.umic;

    default:
      return "white";
  }
};

const getData = (mapData, countries) => {
  const incomeGroups = countries.reduce((accumulator, country) => {
    accumulator[country.alpha3] = country.incomeGroup;

    return accumulator;
  }, {});

  return mapData.features.map((feature) => ({
    "iso-a3": feature.properties["iso-a3"],
    incomeGroup: incomeGroups[feature.properties["iso-a3"]],
  }));
};

const IncomeGroupMap = () => {
  const data = getData(mapData, countries);

  const series = [
    {
      name: "World",
      color: "#fff",
      data,
      showInLegend: false,
    },
    ...["Low", "Lower-middle", "Upper-middle"].map((incomeGroup) => ({
      name: incomeGroup,
      color: getSeriesColor(incomeGroup, theme),
      data: data.filter((item) => item.incomeGroup === incomeGroup),
    })),
  ];

  const options = {
    chart: {
      map: mapData,
    },
    title: {
      text: "Map of Countries by Income Group",
    },
    plotOptions: {
      map: {
        allAreas: false,
        joinBy: "iso-a3",
      },
    },
    subtitle: {
      text: null,
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return `${this.key}: ${this.point.incomeGroup}`;
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    series,
    credits: {
      enabled: false,
    },
  };

  return (
    <HighchartsReact
      options={options}
      constructorType="mapChart"
      highcharts={Highcharts}
    />
  );
};

export default IncomeGroupMap;
