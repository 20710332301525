import PropTypes from "prop-types";
import SideBySide from "../../../Common/SideBySide";
import FpCsF6 from "../Figures/FpCsF6";
import FpCsF7 from "../Figures/FpCsF7";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getMethodPlural,
  getQualitativeChangeDirection,
  toLowerCase,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  if (!data) {
    return {
      totalUsers2019: 0,
      totalUsers2030: 0,
      change: 0,
      percentageChange: 0,
      mostRapidlyGrowingMethod: {
        name: "",
        change: 0,
        percentageChange: 0,
      },
    };
  }

  const totalUsers2019 = data.reduce(
    (accumulator, method) => accumulator + method.years[0].value,
    0
  );

  const totalUsers2030 = data.reduce(
    (accumulator, method) => accumulator + method.years[2].value,
    0
  );

  const change = totalUsers2030 - totalUsers2019;

  const methodGrowths = data
    .map((method) => {
      const change = method.years[2].value - method.years[0].value;

      return {
        name: getMethodPlural(method.name),
        change,
        percentageChange: (change / method.years[0].value) * 100,
      };
    })
    .sort((a, b) => b.change - a.change);

  return {
    totalUsers2019,
    totalUsers2030,
    change,
    percentageChange: (change / totalUsers2019) * 100,
    mostRapidlyGrowingMethod: methodGrowths[0],
  };
};

const FpCsFutureUse = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/fp/cs/figures/6/${props.regionId}/5`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        The total number of users in {toLowerCase(props.regionName)} would{" "}
        {getQualitativeChangeDirection(metrics.percentageChange)} from{" "}
        {formatNumberAsHumanReadable(metrics.totalUsers2019)} in 2019 to{" "}
        {formatNumberAsHumanReadable(metrics.totalUsers2030)} in 2030 (
        {formatNumberAsHumanReadable(metrics.change, {
          prefixWithSign: true,
        })}
        ,{" "}
        {formatPercentage(metrics.percentageChange, {
          prefixWithSign: true,
        })}
        ).
      </p>

      <p>
        If current patterns in the evolution of method mix persist, growth would
        not be seen evenly across all methods. The use of{" "}
        {metrics.mostRapidlyGrowingMethod.name} would see the most rapid growth
        in absolute terms (
        {formatNumberAsHumanReadable(metrics.mostRapidlyGrowingMethod.change, {
          prefixWithSign: true,
        })}
        ,{" "}
        {formatPercentage(metrics.mostRapidlyGrowingMethod.percentageChange, {
          prefixWithSign: true,
        })}
        ).
      </p>

      <FpCsF6 regionName={props.regionName} data={data} />

      <SideBySide
        component={<FpCsF7 regionName={props.regionName} data={data} />}
        componentPosition="right"
      >
        Because gains and declines in the number of users of each method are
        relatively small when compared to the total number of users, the impact
        on method mix overall will be fairly minimal in most countries.
      </SideBySide>
    </>
  );
};

FpCsFutureUse.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default FpCsFutureUse;
