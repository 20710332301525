import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Table from "../../../Common/Box/Table";
import { formatNumber, formatPercentage } from "../../../../utilities";

const number = 1;

const format = (n) =>
  n !== 0
    ? formatNumber(n, {
        significantDigits: 3,
      })
    : "";

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child td:not(:first-child)": {
      minWidth: "6rem",
    },
  },
  total: {
    backgroundColor: theme.palette.area.background,
  },
}));

const MhCsT1 = (props) => {
  const classes = useStyles();

  return (
    <Table
      key="MH_CS_t1"
      number={number.toString()}
      title="Number of Pregnancies and Births"
      subtitle={`${props.regionName} and all Low- and Middle-Income Countries, 2019`}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th className={classes.total} />
            <th colSpan={2}>Pregnancy outcomes</th>
          </tr>
          <tr>
            <th />
            <th className={classes.total}>Pregnancies</th>
            <th className={classes.changeGroupBoundary}>
              Abortions or miscarriages
            </th>
            <th>Births (live &amp; still)</th>
          </tr>
        </thead>
        <tbody>
          {props.data &&
            props.data.map((countryGroup) => (
              <tr key={countryGroup.name}>
                <td>{countryGroup.name}</td>
                {countryGroup.indicators.map(({ name, value }, index) => (
                  <td
                    key={name}
                    className={index === 0 ? classes.total : undefined}
                  >
                    {countryGroup.name !== "Share in region"
                      ? format(value)
                      : formatPercentage(value)}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </Table>
  );
};

MhCsT1.propTypes = {
  regionName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      indicators: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ),
};

export default MhCsT1;
