import { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ErrorBoundary } from "react-error-boundary";
import { animateScroll } from "react-scroll";
import Error from "../Common/Error";
import Narrative from "../Common/Narrative";

const useStyles = makeStyles((theme) => ({
  component: {
    marginBottom: "8rem",
    marginLeft: theme.gutters.default.left,
    marginRight: theme.gutters.default.right,
  },
}));

const ErrorBoundedComponent = (props) => (
  <ErrorBoundary key={props.id} FallbackComponent={Error}>
    {props.children}
  </ErrorBoundary>
);

const Chapter = ({ children }) => {
  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 0,
    });
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.component}>
      <Narrative>
        <ErrorBoundedComponent FallbackComponent={Error}>
          {children}
        </ErrorBoundedComponent>
      </Narrative>
    </div>
  );
};

Chapter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Chapter;
