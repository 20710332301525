import { Typography } from "@material-ui/core";

const Rounding = () => (
  <div>
    <Typography variant="h1">Rounding</Typography>

    <p>
      LEAP results are meant to provide an overall picture of the utilization
      and cost of reproductive health supplies. The methodology used does not
      allow for detailed precision, therefore whole numbers are presented
      rounded to three significant digits. When data from graphs or tables is
      downloaded into a CSV file, unrounded figures are provided to facilitate
      calculations. This detail does not indicate precision, and only numbers
      rounded to three significant digits should be presented.
    </p>
    <p>
      Note that due to rounding in some instances relative results may not sum
      to 100%.
    </p>
  </div>
);

export default Rounding;
