import { useState } from "react";
import PropTypes from "prop-types";
import SideBySide from "../../../Common/SideBySide";
import FpCpF1 from "../Figures/FpCpF1";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getMethodPlural,
  getMethodVerb,
  getQuantitiesAndCostsMethodPlural,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  if (!data) {
    return {
      total: 0,
      incomeGroupShares: [0, 0, 0],
    };
  }

  const total = data.reduce((accumulator, { value }) => accumulator + value, 0);

  return {
    total,
    incomeGroupShares: data.map(({ value }) => (value / total) * 100),
  };
};

const Users = (props) => {
  const metrics = calculateMetrics(props.data);

  return (
    <>
      <p>
        Currently there are an estimated{" "}
        {formatNumberAsHumanReadable(metrics.total)} contraceptive users using{" "}
        {getMethodPlural(props.methodName)} across all low- and middle-income
        countries.
      </p>

      <p>
        {formatPercentage(metrics.incomeGroupShares[0])} reside in low-income
        countries, {formatPercentage(metrics.incomeGroupShares[1])} reside in
        lower-middle-income countries, and the remaining{" "}
        {formatPercentage(metrics.incomeGroupShares[2])} live in
        upper-middle-income countries.
      </p>
    </>
  );
};

const Quantities = (props) => {
  const metrics = calculateMetrics(props.data);

  return (
    <>
      <p>
        An estimated {formatNumberAsHumanReadable(metrics.total)}{" "}
        {getQuantitiesAndCostsMethodPlural(props.methodName)} were{" "}
        {getMethodVerb(props.methodId)} in 2019 across all low- and
        middle-income countries.
      </p>

      <p>
        {formatPercentage(metrics.incomeGroupShares[0])} in low-income
        countries, {formatPercentage(metrics.incomeGroupShares[1])} in
        lower-middle-income countries, and the remaining{" "}
        {formatPercentage(metrics.incomeGroupShares[2])} in upper-middle-income
        countries.
      </p>
    </>
  );
};

const Costs = (props) => {
  const metrics = calculateMetrics(props.data);

  return (
    <>
      <p>
        The current supply cost of {getMethodPlural(props.methodName)}{" "}
        {getMethodVerb(props.methodId)} in low- and middle-income countries is $
        {formatNumberAsHumanReadable(metrics.total)}.
      </p>

      <p>
        {formatPercentage(metrics.incomeGroupShares[0])} of costs are from
        low-income countries, {formatPercentage(metrics.incomeGroupShares[1])}{" "}
        are from lower-middle-income countries, and the remaining{" "}
        {formatPercentage(metrics.incomeGroupShares[2])} are from
        upper-middle-income countries.
      </p>
    </>
  );
};

const Summary = ({ indicatorId, ...rest }) => {
  switch (indicatorId) {
    case 5:
      return <Users {...rest} />;

    case 3:
      return <Quantities {...rest} />;

    case 1:
      return <Costs {...rest} />;

    default:
      return null;
  }
};

const FpCpS1 = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(5);

  const { areaData: data } = useAreaQuery(
    `/custom/fp/cp/figures/1/${props.methodId}/${selectedIndicatorId}`
  );

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  return (
    <>
      <SideBySide
        component={
          <FpCpF1
            methodName={props.methodName}
            indicatorId={selectedIndicatorId}
            data={data}
            onIndicatorChange={handleIndicatorChange}
          />
        }
      >
        <Summary
          indicatorId={selectedIndicatorId}
          methodId={props.methodId}
          methodName={props.methodName}
          data={data}
        />
      </SideBySide>
    </>
  );
};

FpCpS1.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
};

export default FpCpS1;
