import PropTypes from "prop-types";
import Figure from "../../../Common/Box/Figure";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { capitalizePhrase, getTooltip } from "../../../../utilities";

const number = 4;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Disposable":
      return theme.palette.areas.menstrualHygiene.methods.disposable;

    case "Reusable":
      return theme.palette.areas.menstrualHygiene.methods.reusable;

    case "Non-purpose-made":
      return "#ddd";

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const series = data.map((method) => ({
    name: method.name,
    color: getSeriesColor(method.name, theme),
    data: method.scenarios.map((scenario) => scenario.menstruators),
  }));

  return {
    chart: {
      type: "column",
      width: 500,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: [
        "1: Maintain product use",
        "2: Increase purpose-made",
        "3: Increase purpose-made & reusable",
      ],
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          textAlign: "center",
        },
      },
    },
    yAxis: {
      title: undefined,
      min: 0,
      max: 100,
      reversedStacks: false,
      labels: {
        enabled: true,
        format: "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "percent",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Menstruators", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: {
          text: data.incomeGroup,
        },
      },
      enabled: false,
    },
    series,
  };
};

const MhmCsF4 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cs/figures/4/${props.regionId}`
  );

  return (
    <Figure
      id="MHM_CS_f4"
      number={number.toString()}
      fileStem={`mhm_cs_f${number}`}
      title="Future Use of Menstrual Hygiene Products"
      subtitle={`${capitalizePhrase(
        props.regionName
      )} by Future Scenario, 2030`}
      chartOptions={
        data &&
        options(data, {
          regionName: props.regionName,
        })
      }
    />
  );
};

MhmCsF4.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCsF4;
