import axios from "axios";
import fileDownload from "js-file-download";
import cloneDeep from "lodash/cloneDeep";
import {
  darken as muiDarken,
  lighten as muiLighten,
} from "@material-ui/core/styles";
import countries from "./data/countries.json";
import countryGroups from "./data/countryGroups.json";
import customReportLookups from "./data/customReportLookups.json";
import factors from "./data/factors.json";
import theme from "./theme";

const getKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value);

const toCamelCase = (s) =>
  s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });

const toSnakeCase = (s) =>
  s.replace(/[^a-z0-9]+/gi, "_").replace(/^_+|_+$/g, "") ?? "_";

const toThemeName = (chapter) => {
  switch (chapter) {
    case "introduction":
    case "custom":
    case "readers-guide":
      return "general";

    default:
      return toCamelCase(chapter);
  }
};

export const capitalizePhrase = (phrase) =>
  phrase.charAt(0).toUpperCase() + phrase.slice(1);

export const downloadCsvFile = (path, filename) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const url = baseUrl + path;

  axios
    .get(url, {
      responseType: "blob",
    })
    .then((result) => {
      fileDownload(result.data, filename.toLowerCase());
    });
};

export const darken = (color, amount = 0.13) => muiDarken(color, amount);

export const formatMethodLowerCase = (method) =>
  method === "IUD" ? method : method.toLowerCase();

export const formatRegionForSentence = (phrase, capitalizeInitialLetter) => {
  const terms = [
    "Active",
    "Countries",
    "Eligible",
    "Low-Income",
    "Lower-Middle-Income",
    "Priority",
    "Upper-Middle-Income",
  ];

  let lowerCasePhrase = phrase;

  for (let term of terms) {
    lowerCasePhrase = lowerCasePhrase.replace(term);
  }

  if (capitalizeInitialLetter) {
    lowerCasePhrase = capitalizePhrase(lowerCasePhrase);
  }

  return lowerCasePhrase;
};

export const formatNumber = (n, options = {}) => {
  let formattedValue = (options.useAccountingFormat
    ? Math.abs(n)
    : n
  ).toLocaleString(options.locale ?? "en-US", {
    maximumSignificantDigits: options.significantDigits,
    minimumFractionDigits:
      options.significantDigits === undefined
        ? options.decimalPlaces
        : undefined,
    maximumFractionDigits:
      options.significantDigits === undefined
        ? options.decimalPlaces
        : undefined,
  });

  if (options.prefix) {
    if (formattedValue.startsWith("-")) {
      formattedValue = "-" + options.prefix + formattedValue.slice(1);
    } else {
      formattedValue = options.prefix + formattedValue;
    }
  }

  if (options.suffix) {
    formattedValue += options.suffix;
  }

  if (options.useAccountingFormat && n < 0) {
    formattedValue = `(${formattedValue})`;
  }

  return formattedValue;
};

export const formatNumberAsHumanReadable = (n, options = {}) => {
  let mantissa;
  let magnitude;

  if (n > 1e9) {
    mantissa = n / 1e9;
    magnitude = "billion";
  } else if (n > 1e6) {
    mantissa = n / 1e6;
    magnitude = "million";
  } else {
    mantissa = n;
  }

  let formattedNumber = formatNumber(mantissa, {
    significantDigits: 3,
  });

  if (magnitude) {
    formattedNumber = `${formattedNumber} ${magnitude}`;
  }

  if (options.prefix) {
    formattedNumber =
      n >= 0
        ? `${options.prefix}${formattedNumber}`
        : `-${options.prefix}${formattedNumber.slice(1)}`;
  }

  if (options.prefixWithSign && n >= 0) {
    formattedNumber = `+${formattedNumber}`;
  }

  return formattedNumber;
};

export const formatPanelTitle = (title) => {
  if (title === "Medical: All") {
    return "Medical:<br />All";
  }

  return title;
};

export const formatPercentage = (n, options = {}) => {
  let prefix;

  if (options.prefixWithSign && !options.useAccountingFormat && n >= 0) {
    prefix = "+";
  }

  return formatNumber(n, {
    decimalPlaces: 0,
    prefix,
    suffix: "%",
    ...options,
  });
};

export const getChapterTheme = (chapterSlug) => {
  const chapterTheme = cloneDeep(theme);

  const themeName = toThemeName(chapterSlug);

  chapterTheme.palette.area = theme.palette.areas[themeName];

  const color = theme.palette.areas[themeName].primary;
  chapterTheme.palette.area.background = lighten(color, 0.8);
  chapterTheme.typography.h1.color = color;
  chapterTheme.typography.h2.color = color;
  chapterTheme.typography.h6.color = color;

  return chapterTheme;
};

export const getChartColors = () => {
  const palette = theme.palette;

  const areas = Object.fromEntries(
    Object.entries(palette.areas)
      .filter(([k]) => !["accent", "general"].includes(k))
      .map(([k, v]) => [k, v.primary])
  );

  return {
    areas,
    general: palette.areas.general.primary,
    gni: palette.gni,
    methods: {
      abortion: palette.areas.abortion.methods,
      contraception: palette.areas.contraception.methods,
      menstrualHygiene: palette.areas.menstrualHygiene.methods,
    },
    scenarios: palette.scenarios,
    sectors: palette.sectors,
  };
};

export const getCountryGroupName = (countryGroupId, version) => {
  if (countryGroupId === 139) {
    switch (version) {
      case "short":
        return "GFF 36";

      default:
        return "GFF Active Countries";
    }
  }

  if (countryGroupId === 158) {
    switch (version) {
      case "short":
        return "Low- and Middle-";

      default:
        return "Low- and Middle-Income Countries";
    }
  }

  const longNameCountryGroups = [
    ...countryGroups.incomeGroup,
    ...countryGroups.donorPriority,
    ...countryGroups.regional.worldBank,
    ...countryGroups.regional.unpd,
  ];

  const countryGroup = longNameCountryGroups.find(
    ({ id }) => id === countryGroupId
  );

  switch (version) {
    case "long":
      return (
        (countryGroup && (countryGroup.longName ?? countryGroup.name)) ??
        countryGroupId
      );

    case "short":
      return (
        (countryGroup && (countryGroup.shortName ?? countryGroup.name)) ??
        countryGroupId
      );

    default:
      return (countryGroup && countryGroup.name) ?? countryGroupId;
  }
};

export const getCountryName = (countryId, version) => {
  const country = countries.find(({ id }) => id === countryId);

  if (!country) {
    return undefined;
  }

  switch (version) {
    case "long":
      return (
        (country.useDefinitiveArticle ? "the " : "") +
        (country.longName ?? country.name)
      );

    case "short":
      return country.shortName ?? country.name;

    default:
      return (country.useDefinitiveArticle ? "the " : "") + country.name;
  }
};

export const getDownloadFileStem = (id, number, selection) => {
  const match = id.match(/^(\D+)\d+$/);

  let fileStem = `${match[1]}${number}`;

  if (selection) {
    fileStem = `${fileStem}_${toSnakeCase(selection)}`;
  }

  return fileStem.toLowerCase();
};

export const getIncomeGroup = (id) =>
  id === 0 ? "All" : getKeyByValue(factors.incomeGroups, id);

export const getIncomeGroupDescription = (countryId) => {
  const country = customReportLookups.countries[countryId];

  switch (country.incomeGroup) {
    case 1:
      return "a low-income country";

    case 2:
      return "a lower-middle-income country";

    default:
      return "an upper-middle-income country";
  }
};

export const getIndicatorDetails = (id) => {
  switch (id) {
    case 1:
      return { id, name: "Costs", qualifier: "Cost of" };

    case 2:
      return { id, name: "Menstruators", qualifier: "Use of" };

    case 3:
      return { id, name: "Quantities", qualifier: "Units of" };

    case 4:
      return { id, name: "Cases", qualifier: "Use of" };

    case 5:
      return { id, name: "Users", qualifier: "Use of" };

    default:
      return { id, name: "Unknown", qualifier: "Unknown of" };
  }
};

export const getMethod = (id) => {
  switch (id) {
    case 0:
      return "All";

    case 1000:
      return "Three life-saving commodities";

    default:
      return getKeyByValue(factors.methods, id);
  }
};

export const getMethodDescription = (abbreviation) => {
  switch (abbreviation) {
    case "HSC":
      return "Heat-stable<br />carbetocin";

    case "IFA":
      return "Iron and folic acid";

    case "Mag sulfate":
      return "Magnesium<br />sulfate";

    case "MTN":
      return "Metronidazole";

    case "TXA":
      return "Tranexamic acid";

    default:
      return abbreviation;
  }
};

export const getMethodGroup = (id) => {
  switch (id) {
    case 1:
      return "Needed: Received";

    case 2:
      return "Needed: Not Received";

    default:
      return "All Cases";
  }
};

export const getMethodPlural = (method) => {
  switch (method) {
    case "IUD":
      return "IUDs";

    case "Other":
      return "other modern methods";

    case "Sterilization":
      return "sterilization";

    default:
      return `${method}s`.toLowerCase();
  }
};

export const getMethodVerb = (methodId) =>
  [5, 4].includes(methodId) ? "inserted" : "used";

export const getQualitativeComparison = (value, comparator, margin = 1) => {
  if (Math.abs(value - comparator) < margin) {
    return "similar to";
  }

  if (value < comparator) {
    return "lower than";
  }

  return "higher than";
};

export const getQualitativeChangeDirection = (percentage, action) => {
  if (Math.abs(percentage) < 0.5) {
    return !action ? "change" : "remaining at";
  }

  if (percentage < 0) {
    return !action ? "decrease" : "decreasing to";
  }

  return !action ? "increase" : "increasing to";
};

export const getQualitativeChangeMagnitude = (percentage) => {
  if (Math.abs(percentage) < 10) {
    return "slight";
  }

  if (Math.abs(percentage) < 50) {
    return "modest";
  }

  if (Math.abs(percentage) < 200) {
    return "substantial";
  }

  return "dramatic";
};

export const getQuantitiesAndCostsMethodPlural = (method) => {
  switch (method) {
    case "Pill":
      return "pill cycles";

    case "Sterilization":
      return "sterilization kits";

    default:
      return getMethodPlural(method);
  }
};

export const getRegionName = (id, version) =>
  getCountryName(id, version) ?? getCountryGroupName(id, version);

export const getRegionalUnitName = (id, plural) => {
  let name;

  switch (id) {
    case 0:
      name = "World Bank Region";

      break;

    case 1:
      name = "UNPD Region";

      break;

    default:
      name = "Region";

      break;
  }

  return name + (plural ? "s" : "");
};

export const getScenario = (id) => getKeyByValue(factors.scenarios, id);

export const getSector = (id) =>
  id === 0 ? "Total" : getKeyByValue(factors.sectors, id);

export const getScenarioNumber = (id) => {
  switch (id) {
    case 5:
      return 1;

    case 3:
      return 2;

    case 4:
      return 3;

    default:
      return 0;
  }
};

export const getSingular = (s) => s.replace(/s$/, "");

export const getTooltip = (indicator, value, percentage, formattingOptions) => {
  let tooltip = "";

  if (indicator) {
    tooltip += `${indicator}: <strong>${formatNumber(value, {
      significantDigits: 3,
      ...(formattingOptions ?? {}),
    })}</strong>`;
  }

  if (percentage != null) {
    if (tooltip) {
      tooltip += "<br />";
    }

    tooltip += `Share: <strong>${formatPercentage(percentage)}</strong>`;
  }

  return tooltip;
};

export const lighten = (color, amount = 0.13) => muiLighten(color, amount);

export const toLowerCase = (s, capitalizeInitialLetter) => {
  const terms = [
    "Active",
    "and Middle-Income",
    "Countries",
    "Eligible",
    "Low-",
    "Low-Income",
    "Lower-Middle-Income",
    "Priority",
    "The",
    "Upper-Middle-Income",
  ];

  let lowerCase = s;

  for (let term of terms) {
    lowerCase = lowerCase.replace(term, term.toLowerCase());
  }

  if (capitalizeInitialLetter) {
    lowerCase = capitalizePhrase(lowerCase);
  }

  return lowerCase;
};

export const toTitleCase = (s) => {
  if (["IUD", "IUDs"].includes(s)) {
    return s;
  }

  return s.replace(
    /(\w*\W*|\w*)\s*/g,
    (w) => w.charAt(0).toUpperCase() + w.substr(1).toLowerCase()
  );
};

export const translateSectorName = (name) => {
  switch (name) {
    case "Iron and folic acid":
      return "Iron and Folic Acid Supplementation";

    case "Antihypertensives":
      return "Hypertension Management";

    case "Magnesium sulfate":
      return "Pre-eclampsia and Eclampsia Treatment";

    case "Uterotonics":
      return "Induction and Augmentation of Labor, and Prevention and Treatment of PPH";

    case "Metronidazole":
      return "Maternal Infection Treatment";

    default:
      return name;
  }
};
