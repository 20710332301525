import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import sum from "lodash/sum";
import Table from "../../../Common/Box/Table";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  capitalizePhrase,
  formatNumber,
  formatPercentage,
} from "../../../../utilities";

const number = 3;

const renameMethod = (methodName) => {
  if (methodName === "Medical: Miso only") {
    return "Miso";
  }

  return methodName.replace(/^Medical: /, "");
};

const format = (n) =>
  formatNumber(n, {
    significantDigits: 3,
    prefix: "$",
  });

const summarizeMethod = (years) => {
  const mainYears = [years[0], years[6], years[11]];
  const change = mainYears[2] - mainYears[0];

  return {
    years: mainYears,
    change,
    percentageChange: (change / mainYears[0]) * 100,
    cumulativeTotal: sum(years.slice(2)),
  };
};

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  scenarioTitle: {
    borderBottom: `1px solid ${theme.table.border.secondary}`,
    borderTop: `1px solid ${theme.table.border.secondary}`,
    fontWeight: "bold",
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td.name": {
      textAlign: "left",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& td.scenarioTitle": {
      borderBottom: `1px solid ${theme.table.border.secondary}`,
      borderTop: `1px solid ${theme.table.border.secondary}`,
      fontWeight: "bold",
      paddingTop: "1.5rem",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child td:not(:first-child)": {
      minWidth: "6rem",
    },
    "& tr.total": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
      fontWeight: "bold",
    },
    "& .breakdown": {
      fontStyle: "italic",
    },
  },
}));

const ScenarioSubTable = (props) => {
  const classes = useStyles();

  const scenarioName = `${props.scenarioNumber}. ${props.data.name}`;

  const methods = props.data.methods;

  return (
    <>
      <tr>
        <td colSpan={8} className="scenarioTitle">
          {scenarioName}
        </td>
      </tr>
      {methods.map((method, index) => {
        const summary = summarizeMethod(method.years);
        const breakdown = index >= 2 && index <= 3;

        return (
          <tr
            key={method.name}
            className={classNames(
              breakdown ? "breakdown" : undefined,
              method.name === "Total" ? "total" : undefined
            )}
          >
            {index !== 2 ? null : (
              <td rowSpan={2} className="name">
                Within medical
              </td>
            )}

            <td
              className={classNames("name", classes.nameGroupBoundary)}
              colSpan={!breakdown ? 2 : 1}
            >
              {renameMethod(method.name)}
            </td>

            {summary.years.map((value, index) => (
              <td key={[2019, 2025, 2030][index]}>{format(value)}</td>
            ))}

            <td className={classes.changeGroupBoundary}>
              {format(summary.change)}
            </td>
            <td>
              {summary.percentageChange !== 0
                ? formatPercentage(summary.percentageChange)
                : ""}
            </td>

            <td className={classes.changeGroupBoundary}>
              {format(summary.cumulativeTotal)}
            </td>
          </tr>
        );
      })}
    </>
  );
};

const ScenariosTable = ({ data }) => {
  const classes = useStyles();

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <th />
          <th className={classes.nameGroupBoundary} />
          <th colSpan={3}>Total Costs</th>
          <th colSpan={2} className={classes.changeGroupBoundary}>
            Change (2019 to 2030)
          </th>
          <th className={classes.changeGroupBoundary}>
            10-year cumulative total
          </th>
        </tr>
        <tr>
          <th />
          <th className={classes.nameGroupBoundary} />
          <th>2019</th>
          <th>2025</th>
          <th>2030</th>
          <th className={classes.changeGroupBoundary}>#</th>
          <th>%</th>
          <th className={classes.changeGroupBoundary}>(2021-2030)</th>
        </tr>
      </thead>
      <tbody>
        {data.map((scenario, index) => (
          <ScenarioSubTable
            key={scenario.name}
            scenarioNumber={index + 1}
            data={scenario}
          />
        ))}
      </tbody>
    </table>
  );
};

const ApacCsT6 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/apac/cs/tables/6/${props.regionId}`
  );

  return (
    <Table
      id="APAC_CS_t6"
      number={number.toString()}
      title={`Change in Cost of Abortion and PAC Supplies in ${capitalizePhrase(
        props.regionName
      )} `}
      subtitle="Future Scenario by Method, 2019-2030"
    >
      {<ScenariosTable data={data ?? []} />}
    </Table>
  );
};

ApacCsT6.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default ApacCsT6;
