import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import Information from "../../Common/Box/Information";

const MhB1 = () => (
  <Information
    key="MH_b1"
    number="1"
    title="Unequal burden of Maternal Mortality"
  >
    <p>
      MMR varies widely between low-income countries (462/100,000) and
      high-income countries (11/100,000). Regional differences are equally stark
      <Footnote number={2}>
        WHO{" "}
        <ExternalLink
          href="https://unfpa.org/sites/default/files/pub-pdf/Maternal_mortality_report.pdf"
          text="Trends in maternal mortality 2000 to 2017: estimates by WHO, UNICEF, UNFPA, World Bank Group and the United Nations Population Division"
          useItalics={true}
        />{" "}
        Geneva: World Health Organization; 2019. Licence: CC BY-NC-SA 3.0 IGO.
      </Footnote>
      <Footnote number={3}>
        WHO. Global Health Observatory (GHO) data{" "}
        <ExternalLink
          href="https://www.who.int/data/gho/data/indicators/indicator-details/GHO/maternal-mortality-ratio-(per-100-000-live-births)"
          text="Maternal mortality ratio"
          useItalics={true}
        />{" "}
        Accessed 7 August 2021.
      </Footnote>
      :
    </p>

    <ul>
      <li>
        94% of all maternal deaths occur in low-income and lower-middle-income
        countries;
      </li>
      <li>86% occur in sub-Saharan Africa and Southern Asia;</li>
      <li>25% occur in Bangladesh, Ethiopia and Nigeria.</li>
    </ul>
  </Information>
);

export default MhB1;
