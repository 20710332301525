import Highcharts from "highcharts";

const getSvg = (charts, options, callback) => {
  const svgs = [];
  let left = 0;
  let height = 0;

  let addSvg = function (chartSvg) {
    // Grab width/height from exported chart
    const svgWidth = +chartSvg.match(
      /^<svg[^>]*width\s*=\s*"?(\d+)"?[^>]*>/
    )[1];

    const svgHeight = +chartSvg.match(
      /^<svg[^>]*height\s*=\s*"?(\d+)"?[^>]*>/
    )[1];

    // Offset the position of this chart in the final SVG
    let combinedSvg = chartSvg.replace(
      "<svg",
      `<g transform="translate(${left},0)" `
    );

    combinedSvg = combinedSvg.replace("</svg>", "</g>");

    left += svgWidth;
    height = Math.max(height, svgHeight);

    svgs.push(combinedSvg);
  };

  let exportChart = function (i) {
    if (i === charts.length) {
      return callback(
        `<svg height="${height}" width="${left}" version="1.1" xmlns="http://www.w3.org/2000/svg">${svgs.join(
          ""
        )}</svg>`
      );
    }
    charts[i].getSVGForLocalExport(
      options,
      {},
      function (e) {
        console.log("Failed to get SVG");
      },
      function (svg) {
        addSvg(svg);

        return exportChart(i + 1); // Export next only when this SVG is received
      }
    );
  };

  exportChart(0);
};

const exportCharts = (charts, options) => {
  options = Highcharts.merge(Highcharts.getOptions().exporting, options);

  const highchartsCharts = Object.keys(charts)
    .sort()
    .map((key) => charts[key].chart);

  getSvg(highchartsCharts, options, (svg) => {
    Highcharts.post(options.url, {
      filename: options.filename || "chart",
      type: options.type,
      scale: options.scale,
      svg,
    });
  });
};

export default exportCharts;
