import PropTypes from "prop-types";
import factors from "../../../../data/factors.json";
import Select from "../Select";
import ChooserOptions from "./ChooserOptions";

const SectorChooser = (props) => {
  const sectors = Object.keys(factors.sectors)
    .map((key) => ({
      name: key,
      value: factors.sectors[key],
    }))
    .sort((sector) => -(sector.value !== 10 ? sector.value : 2.5));

  return (
    <Select
      label="Sector"
      value={props.value}
      onChange={(value) => {
        props.onChange?.(parseInt(value, 10));
      }}
    >
      <ChooserOptions options={sectors} />
    </Select>
  );
};

SectorChooser.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default SectorChooser;
