import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import BoxControls from "../../../Common/Box/BoxControls";
import ScenarioChooser from "../../../Common/Box/Choosers/ScenarioChooser";
import Figure from "../../../Common/Box/Figure";
import ProductChooser from "../../ProductChooser";
import theme from "../../../../theme";
import { getSingular, getTooltip } from "../../../../utilities";

const number = 4;

const getSeries = (data) => {
  return [
    {
      name: "2019",
      data: data.map((country, index) => ({
        x: index,
        y: country.years[0].value,
      })),
      color: "#c193ac",
      marker: {
        fillColor: "#c193ac",
      },
    },
    {
      name: "2025",
      data: data.map((country, index) => ({
        x: index,
        y: country.years[1].value,
      })),
      color: "#ac6f90",
      marker: {
        fillColor: "#ac6f90",
      },
    },
    {
      name: "2030",
      data: data.map((country, index) => ({
        x: index,
        y: country.years[2].value,
      })),
      color: "#7d4765",
      marker: {
        fillColor: "#7d4765",
      },
    },
  ];
};

const drawRange = (chart, countryCount, countryIndex, y1, y2) => {
  const countryOffset = countryCount - countryIndex;

  const xAxis = chart.xAxis[0];
  const yAxis = chart.yAxis[0];

  const itemGap = xAxis.translate(countryCount - 1.5);
  const itemCenter = xAxis.top + xAxis.translate(countryOffset) + itemGap;

  chart.renderer
    .path([
      "M",
      yAxis.left + yAxis.translate(y1),
      itemCenter,
      "L",
      yAxis.left + yAxis.translate(y2),
      itemCenter,
    ])
    .attr({
      "z-index": -1,
      "stroke-width": 1,
      stroke: theme.palette.grays.gray4,
    })
    .add();
};

const drawRanges = (chart, data) => {
  const countryCount = data.length;

  for (let i = 0; i < countryCount; i++) {
    const y1 = Math.min(...data[i].years.map((year) => year.value));
    const y2 = Math.max(...data[i].years.map((year) => year.value));

    drawRange(chart, countryCount, i, y1, y2);
  }
};

const options = (data) => (theme) => {
  const series = getSeries(data);

  return {
    chart: {
      type: "scatter",
      width: 1000,
      height: 600,
      inverted: true,
      backgroundColor: null,
      events: {
        redraw: function () {
          drawRanges(this, data, series);
        },
      },
    },
    xAxis: {
      categories: data.map((country) => country.name),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      opposite: true,
      labels: {
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          symbol: "circle",
          radius: 4,
          lineWidth: 1,
          lineColor: "silver",
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      reversed: false,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Services", this.y);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const useStyles = makeStyles((theme) => ({
  choosers: {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(2),
    width: "fit-content",
  },
}));

const ApacCcF4 = (props) => {
  const classes = useStyles();

  return (
    <Figure
      id="APAC_CC_f4"
      number={number.toString()}
      title="Change in Costed Abortion and PAC Services"
      subtitle={`By ${getSingular(
        props.regionalUnitName
      )} and Future Scenario, 2030`}
      chartOptions={props.data && options(props.data)}
      controls={
        <BoxControls>
          <div className={classes.choosers}>
            <ScenarioChooser
              area="abortion"
              value={props.scenarioId}
              onChange={props.onScenarioChange}
            />
            <ProductChooser
              area="abortion"
              value={props.productId}
              onChange={props.onProductChange}
            />
          </div>
        </BoxControls>
      }
      annotations={true}
    />
  );
};

ApacCcF4.propTypes = {
  regionalUnitName: PropTypes.string.isRequired,
  scenarioId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      years: PropTypes.arrayOf(
        PropTypes.shape({
          year: PropTypes.number.isRequired,
          value: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ),
  onScenarioChange: PropTypes.func,
  onProductChange: PropTypes.func,
};

export default ApacCcF4;
