import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  component: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    width: 1152,
  },
}));

const Narrative = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.component}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default Narrative;
