import PropTypes from "prop-types";
import SideBySide from "../../../Common/SideBySide";
import ApacCcF2 from "../Figures/ApacCcF2";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatNumberAsHumanReadable } from "../../../../utilities";

const calculateMetrics = (data) => {
  const total = data?.slice(-1)[0];

  return {
    minimumCost: total?.minimumValue ?? 0,
    maximumCost: total?.maximumValue ?? 0,
  };
};

const ApacCcS3 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/apac/cc/figures/2/${props.regionalUnitId}`
  );

  const metrics = calculateMetrics(data);

  return (
    <SideBySide
      component={
        <ApacCcF2 regionalUnitName={props.regionalUnitName} data={data} />
      }
    >
      <p>
        The cost of abortion and PAC supplies in 2019 $226 million. Medical
        methods account for 46% of the total supply costs, and within that
        misoprostol makes up 58% of the cost.
      </p>

      <p>
        The supply costs range from{" "}
        {formatNumberAsHumanReadable(metrics.minimumCost, {
          prefix: "$",
        })}{" "}
        to{" "}
        {formatNumberAsHumanReadable(metrics.maximumCost, {
          prefix: "$",
        })}{" "}
        across the {props.regionalUnitName}
      </p>
    </SideBySide>
  );
};

ApacCcS3.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default ApacCcS3;
