import PropTypes from "prop-types";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatPercentage,
  getQualitativeChangeDirection,
  getQualitativeChangeMagnitude,
  toLowerCase,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  if (!data) {
    return {
      percentagePurposeMadeProductsScenario2: 0,
      percentageChangePurposeMadeProducts: 0,
      percentageResuableProducts: 0,
    };
  }

  const totalProductsScenario1 = data.reduce(
    (accumulator, method) => accumulator + method.scenarios[0].menstruators,
    0
  );

  const totalPurposeMadeProductsScenario1 =
    totalProductsScenario1 - data[2].scenarios[0].menstruators;

  const percentagePurposeMadeProductsScenario1 =
    (totalPurposeMadeProductsScenario1 / totalProductsScenario1) * 100;

  const totalProductsScenario2 = data.reduce(
    (accumulator, method) => accumulator + method.scenarios[1].menstruators,
    0
  );

  const totalPurposeMadeProductsScenario2 =
    totalProductsScenario2 - data[2].scenarios[1].menstruators;

  const percentagePurposeMadeProductsScenario2 =
    (totalPurposeMadeProductsScenario2 / totalProductsScenario2) * 100;

  const percentageChangePurposeMadeProducts =
    percentagePurposeMadeProductsScenario2 -
    percentagePurposeMadeProductsScenario1;

  const totalProductsScenario3 = data.reduce(
    (accumulator, method) => accumulator + method.scenarios[2].menstruators,
    0
  );

  const percentageResuableProducts =
    (data[1].scenarios[2].menstruators / totalProductsScenario3) * 100;

  return {
    percentagePurposeMadeProductsScenario2,
    percentageChangePurposeMadeProducts,
    percentageResuableProducts,
  };
};

const MhmCsFutureUseMenstruators = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cs/figures/4/${props.regionId}`
  );

  const metrics = calculateMetrics(data);

  return (
    <div>
      <p>
        With an increase in the use of purpose-made products (scenarios 2 &amp;
        3), there would be a{" "}
        {getQualitativeChangeMagnitude(
          metrics.percentageChangePurposeMadeProducts
        )}{" "}
        {getQualitativeChangeDirection(
          metrics.percentageChangePurposeMadeProducts
        )}{" "}
        in the use of purpose-made products in {toLowerCase(props.regionName)}{" "}
        {getQualitativeChangeDirection(
          metrics.percentageChangePurposeMadeProducts,
          true
        )}{" "}
        {formatPercentage(metrics.percentagePurposeMadeProductsScenario2)} in
        2030.
      </p>

      {metrics.percentageChangePurposeMadeProducts <= 0 ? (
        <p>
          Because purpose-made product use was already higher than the average
          for the next-highest GNI group in the region, current levels were
          maintained in scenarios 2 &amp; 3.
        </p>
      ) : null}

      <p>
        Assuming a shift to increased use of purpose-made products coupled with
        an increase in reusable product use (Scenario 3), by 2030{" "}
        {formatPercentage(metrics.percentageResuableProducts)} of menstruators
        in {toLowerCase(props.regionName)} would be using reusable products.
      </p>
    </div>
  );
};

MhmCsFutureUseMenstruators.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCsFutureUseMenstruators;
