import Information from "../../Common/Box/Information";

const Component = () => (
  <Information key="APAC_b4" number="4" title="Misoprostol pricing">
    <p>
      Currently, the largest cost driver for abortion and PAC services is the
      cost of misoprostol, accounting for 63% of the total cost of medical
      methods, and 58% of the total cost of all abortion and PAC services. The
      cost per pill of misoprostol varies significantly, particularly amongst
      regions (see Table 3)&mdash;a single misoprostol pill in Latin America and
      the Caribbean costs over 16 times more than a pill in Asia.
    </p>

    <p>
      If more affordable misoprostol pills could be made available, this would
      have a huge impact on costs. For example, if all misoprostol were to cost
      the same as the median price per misoprostol pill in Asia ($0.16), the
      overall cost of abortion and PAC services would decrease by 43%; a cost
      saving of $98.3 million.
    </p>

    <p>
      Note that as the use of misoprostol combined with mifepristone increases,
      mifepristone is projected to become a much larger cost driver (see Box 7).
    </p>
  </Information>
);

export default Component;
