import { useState } from "react";
import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import ProductChooser from "../../ProductChooser";
import { capitalizePhrase, getTooltip } from "../../../../utilities";
import theme from "../../../../theme";

const number = 5;

const getSeries = (data, theme) => {
  return [
    {
      name: "Maintain product use",
      data: data.map((country, index) => ({
        x: index,
        y: country.scenarios[0].menstruators,
      })),
      color: theme.palette.scenarios.scenario1,
      marker: {
        fillColor: theme.palette.scenarios.scenario1,
      },
    },
    {
      name: "Increase purpose-made",
      data: data.map((country, index) => ({
        x: index,
        y: country.scenarios[1].menstruators,
      })),
      color: theme.palette.scenarios.scenario2,
      marker: {
        fillColor: theme.palette.scenarios.scenario2,
      },
    },
    {
      name: "Increase purpose-made & reusable",
      data: data.map((country, index) => ({
        x: index,
        y: country.scenarios[2].menstruators,
      })),
      color: theme.palette.scenarios.scenario3,
      marker: {
        fillColor: theme.palette.scenarios.scenario3,
      },
    },
  ];
};

const drawRange = (chart, countryCount, countryIndex, y1, y2) => {
  const countryOffset = countryCount - countryIndex;

  const xAxis = chart.xAxis[0];
  const yAxis = chart.yAxis[0];

  const itemGap = xAxis.translate(countryCount - 1.5);
  const itemCenter = xAxis.top + xAxis.translate(countryOffset) + itemGap;

  chart.renderer
    .path([
      "M",
      yAxis.left + yAxis.translate(y1),
      itemCenter,
      "L",
      yAxis.left + yAxis.translate(y2),
      itemCenter,
    ])
    .attr({
      "z-index": -1,
      "stroke-width": 1,
      stroke: theme.palette.grays.gray4,
    })
    .add();
};

const drawRanges = (chart, data) => {
  const countryCount = data.length;

  for (let i = 0; i < countryCount; i++) {
    const y1 = Math.min(
      ...data[i].scenarios.map((scenario) => scenario.menstruators)
    );
    const y2 = Math.max(
      ...data[i].scenarios.map((scenario) => scenario.menstruators)
    );

    drawRange(chart, countryCount, i, y1, y2);
  }
};

const options = (data) => (theme) => {
  const series = getSeries(data, theme);

  return {
    chart: {
      type: "scatter",
      width: 1000,
      height: 1000,
      inverted: true,
      backgroundColor: null,
      events: {
        redraw: function () {
          drawRanges(this, data, series);
        },
      },
    },
    xAxis: {
      categories: data.map((country) => country.name),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      opposite: true,
      labels: {
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          symbol: "circle",
          radius: 4,
          lineWidth: 1,
          lineColor: "silver",
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      reversed: false,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Menstruators", this.y);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const MhmCcF5 = (props) => {
  const [selectedProductId, setSelectedProductId] = useState(-1);

  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cc/figures/5/${props.regionId}/${selectedProductId}`
  );

  const handleProductChange = (productId) => {
    setSelectedProductId(productId);
  };

  return (
    <Figure
      id="MHM_CC_f5"
      number={number.toString()}
      title="Future Use of Menstrual Hygiene Products"
      subtitle={`${capitalizePhrase(
        props.regionName
      )} by Country and Future Scenario, 2030`}
      chartOptions={data && options(data)}
      controls={
        <BoxControls>
          <ProductChooser
            allowAll={false}
            value={selectedProductId}
            onChange={handleProductChange}
          />
        </BoxControls>
      }
      annotations={true}
    />
  );
};

MhmCcF5.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCcF5;
