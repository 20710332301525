import PropTypes from "prop-types";
import Figure from "../../../Common/Box/Figure";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { getTooltip } from "../../../../utilities";

const number = 9;

const getSeriesColor = (name) => {
  switch (name) {
    case "2019":
      return "#f8aaaa";

    case "2025":
      return "#f37676";

    case "2030":
      return "#ed3333";

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const series = data.map((series) => ({
    ...series,
    color: getSeriesColor(series.name),
  }));

  return {
    chart: {
      type: "column",
      width: 470,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: [
        "1: Maintain coverage",
        "2: Increase coverage",
        "3: Increase coverage and scale-up emerging drugs",
      ],
    },
    yAxis: {
      title: undefined,
      labels: {
        formatter: function () {
          return "$" + this.axis.defaultLabelFormatter.call(this);
        },
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Cost", this.y, this.percentage, {
          prefix: "$",
        });
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const MhCsF9 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/figures/9/${props.regionId}`
  );

  return (
    <Figure
      id="MH_CS_f9"
      number={number.toString()}
      fileStem={`mh_cs_f${number}`}
      title="Change in Cost of Seven Priority Maternal Health Drugs Plus Two Emerging Drugs"
      subtitle={`${props.regionName} by Future Scenario, 2019-2030`}
      chartOptions={data && options(data)}
    />
  );
};

MhCsF9.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsF9;
