import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router-dom";
import Information from "../Common/Box/Information";
import Chapter from "../Common/Chapter";
import Citation from "../Common/Citiation";
import SideBySide from "../Common/SideBySide";
import { enableCustomReports } from "../../constants";
import { useReadersGuide } from "../../hooks/useReadersGuide";
import Overview from "./Overview";

const useStyles = makeStyles((theme) => ({
  graphic: {
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "center",
  },
  citation: {
    marginTop: theme.spacing(6),
  },
  acknowledgements: {
    fontSize: "smaller",
  },
  customReports: {
    display: enableCustomReports ? "inline" : "none",
  },
}));

const Covid19 = () => (
  <Information number="1" title="COVID-19">
    <p>
      COVID-19 has, and may continue to cause unprecedented disruptions across
      the word. While limited data still exist to fully assess its impact on
      reproductive health services and supplies, emerging evidence suggests that
      in many places disruptions are likely to be minimal and short-lived. It
      was beyond the scope of LEAP to attempt to account for the impacts of
      COVID-19 and it is unknown at this stage to what extent these impacts will
      continue in the coming years.
    </p>
    <p>
      Nevertheless, because 2020 and 2021 are such atypical years, throughout
      LEAP the terms &quot;today&quot; or the &quot;current picture&quot;, refer
      to 2019, the last complete year before the pandemic.
    </p>
  </Information>
);

const Introduction = () => {
  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <Chapter>
      <Typography variant="h1">
        Introduction: Expanding our Focus Across RH Supply Needs
      </Typography>

      <p>
        In 2001 the Reproductive Health Supplies Coalition published what would
        become the first in a series of the Commodity Gap Analysis. At its
        foundation, the Gap Analysis had a narrow focus; covering only
        contraceptives and looking only at donor supported countries and
        predominantly public sector supplies. Over the last two decades the
        scope has expanded to encompass a wider set of low- and middle-income
        countries, focus on the public and private sectors, provide insights
        into the role of subsidy within the private sector, project further into
        the future, and make the data and information more accessible through an
        interactive and online platform. All of those changes have offered the
        reproductive health supplies community a rich set of findings that have
        helped to inform critical discussions.
      </p>

      <p>
        The expansion of the Gap Analysis, however welcomed, left a critical
        gap. The data only captured a sub-set of the supplies that women need to
        meet their reproductive health needs. This year, we break new ground by
        expanding to encompass three additional areas:{" "}
        <RouterLink to="/menstrual-hygiene">menstrual hygiene</RouterLink>,{" "}
        <RouterLink to="/abortion">
          abortion &amp; post-abortion care (PAC)
        </RouterLink>
        , and <RouterLink to="/maternal-health">maternal health</RouterLink>.
        Given the expansion in health areas, we have termed this new assessment
        LEAP for Landscape and Projection of Reproductive Health Supply Needs.
        We believe this new name captures the large movement forward in this
        year&apos;s report and better captures the breadth of the analyses
        included.
      </p>

      <p>
        By expanding to these new areas, we are able to capture the synergies
        between them – our future projections of contraceptive use inform our
        estimates of how many women will experience pregnancy, which in turn
        inform our estimates of how many women will not be menstruating due to
        being pregnant or post-partum. In addition, we are able to account for
        the impact of the use of hormonal methods on menstruation, leading some
        women to need fewer or no menstrual hygiene supplies.
      </p>

      <div className={classes.graphic}>
        <Overview />
      </div>

      <SideBySide component={<Covid19 />} componentPosition="right">
        <p>
          We have stayed true to our methods and our process. As in the past, we
          began by asking the community what they needed and wanted. We
          consulted experts. We brought together data from a wide range of
          sources. While the four health areas are unique, for each we are able
          to present similar results: what services or supplies women use, the
          quantity of supplies needed, and the cost of these supplies. For each,
          we start by exploring where we are today and then turn our eyes to the
          future ahead, examining potential pathways to 2025 and 2030. For
          contraception we continue with a single trajectory forward, informed
          by existing projections of contraceptive use. For the new health areas
          no such future projections exist, therefore for these areas we explore
          three future scenarios. First, a baseline scenario looks what would
          happen if we saw changes in demographics and contraceptive use, but no
          changes in the coverage and use of the new health services. Next, we
          explore a scenario where coverage improves, informed by the patterns
          seen in the next-highest income group. Finally, we layer this increase
          in coverage with changes in the types of methods and supplies used.
          These changes are aligned with conversations happening among advocates
          and planners: the scale-up of reusable menstrual hygiene products;
          increased use of medical abortion, especially those using
          mifepristone; and the scale-up of heat-stable carbetocin and
          tranexamic acid. This scenario allows for exploration of the
          implications of such changes.
        </p>
      </SideBySide>

      <p>
        In LEAP, we moved away from a focus on current spending and gaps and
        instead examine how supply costs will change going forward. While useful
        to call attention to a &apos;gap&apos; for advocacy purposes, this gap
        was calculated from a baseline that we held constant over the full
        ten-year period. But spending is not static, and so a focus on future
        gaps provides a false sense of the need. And, for the new health areas,
        where spending largely comes from Governments (in the case of maternal
        health) or out-of-pocket expenditures (in the case of menstrual hygiene
        and abortion), the concept of a funding gap holds less relevance. The
        new name also reflects this departure.
      </p>

      <p>
        To begin to explore our results, please select one of the four health
        areas at the top of the page to access the relevant Landscape Report.
        Each report looks across the full set of low- and middle-income
        countries and explores how results vary by across the three income
        groups (low, lower-middle and upper-middle).
        <span className={classes.customReports}>
          {" "}
          The <RouterLink to="/custom">Custom Reports</RouterLink> allow the
          reader to dig deeper into the data, find results for a specific
          country or region, compare countries or regions, or explore a single
          product.
        </span>{" "}
        Finally, look at the{" "}
        <Link underline="always" onClick={handleReadersGuideOpen("cost")}>
          Reader&apos;s Guide
        </Link>{" "}
        (accessible at the top right) for details behind our data sources and
        analysis approaches.
      </p>

      <p>
        We and our colleagues at Avenir Health see these new findings as a
        starting point. Our hope is that LEAP will continue to evolve based on
        feedback from the community, the availability of new data, and the
        continued movement of discussions and priorities related to RH supplies
        as we approach 2030.
      </p>

      <Citation className={classes.citation} />

      <div className={classes.acknowledgements}>
        <Typography variant="h2">Acknowledgements</Typography>

        <p>
          The Reproductive Health Supplies Coalition extends its thanks to those
          who made LEAP possible. We give special thanks to the Technical Leads
          from Avenir Health: Michelle Weinberger (Overall and Contraception),
          Nicole Bellows (Menstrual Hygiene), Meghan Reidy (Abortion and PAC),
          and Rachel Sanders (Maternal Health). We also acknowledge the
          contributions of consultants Gillian Eva and Judy Gold who helped
          bring LEAP together by leading a review of the previous Contraceptive
          Gap Analysis and stakeholder consultations for LEAP, and supporting
          the writing of LEAP. John Skibiak provided strategic direction and
          helped to shape the final product.
        </p>

        <p>
          LEAP would not be possible without the support and engagement of
          partners. Co-chairs and members of the Menstrual Health Supplies
          Workstream, Safe Abortion Supplies Workstream, and Maternal Health
          Supplies Caucus provided valuable insights to help shape these new
          health areas. We are also grateful to colleagues from DKT
          International, International Planned Parenthood Federation, PSI, MSI
          Reproductive Choices, and Track20 for sharing data and insights. In
          particular we would like to thank: Anisa Berdellima, Y.P Gupta, A.A.
          Jayachandran, Catherine Kilfedder, Seth McGovern, Lek Ngamkitpaiboon,
          and Chris Purdy for their contributions.
        </p>

        <p>
          We would also like to thank Ann Biddlecom, Jason Bremner, Mary Grace
          Darmody, Ashish Das, Sally Dijkerman , Jennie Greaney, Ruud
          Grotenhuis, Eleni Han, Brendan Hayes, Alexis Heaton, Cheri Poss, Lekha
          Puri , Mindy Scibilia, Martyn Smith, Elizabeth Sully, Ellen Tompsett,
          and all those who took the time to complete our online surveys for
          providing valuable insights that helped to shape LEAP.
        </p>
        <p>
          We thank all of our partners, including the Bill and Melinda Gates
          Foundation, FCDO, the Global Financing Facility, UNFPA, and USAID. who
          have used the earlier Commodity Gap Analyses, and we hope will now use
          LEAP for their own planning and advocacy efforts.
        </p>
        <p>
          Finally, we would like to thank Lucian Alexe, Hannah Pandian, and
          Andrew Tait for helping to bring LEAP to life online. We also
          acknowledge the contribution of Cynthia Breckenridge, who created the
          design of the 2019 Commodity Gap Analysis, elements of which have been
          used in LEAP.
        </p>
      </div>
    </Chapter>
  );
};

export default Introduction;
