import { Link, Typography } from "@material-ui/core";
import { useReadersGuide } from "../../../hooks/useReadersGuide";
import MhT11 from "../../MaternalHealth/Tables/MhT11";

const MaternalHealthScope = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Scope</Typography>

      <Typography variant="h2">What</Typography>

      <p>
        This health area covers seven priority maternal health drugs plus two
        emerging drugs (see Table 1). This includes the three drugs defined by
        the WHO as &apos;life-saving commodities&apos;&mdash;oxytocin,
        misoprostol, and magnesium sulfate.
      </p>
      <p>
        For our analysis we sometimes group three of these drugs together as
        &quot;uterotonics&quot; (oxytocin, misoprostol, and heat-stable
        carbetocin) and two as &quot;antihypertensives&quot; (hydralazine and
        methyldopa). The remaining three drugs (magnesium sulfate, iron and
        folic acid, and metronidazole) are always presented separately as they
        are used for different interventions.
      </p>

      <MhT11 />

      <p>
        There are a wide range of other drugs and supplies used for maternal
        health care. When interpreting the results for this health area it is
        important to remember that we have only estimated the costs for these
        seven priority maternal health drugs and two emerging drugs. In
        addition, as with other health areas, our estimates focus on the drugs
        themselves and not other associated costs including labor, health
        infrastructure, and above-site costs. For maternal health in particular,
        these are likely to be substantial compared to the supply costs.
      </p>

      <Typography variant="h2">Who</Typography>

      <p>
        LEAP covers individuals needing and receiving one or more of the
        included drugs (see Table 1) during pregnancy and childbirth.
        Misoprostol used for abortion and PAC services is not included here, as
        these drugs are captured in the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-terms")}
        >
          Abortion &amp; PAC
        </Link>{" "}
        section of LEAP.
      </p>

      <Typography variant="h2">When</Typography>

      <p>
        Our results cover the time period from 2019 to 2030. We used 2019 as the
        baseline year for exploring the “current” landscape for maternal health
        interventions due to the uncertainties of the impacts of the global
        COVID-19 pandemic in 2020. See{" "}
        <Link underline="always" onClick={handleReadersGuideOpen("timeframe")}>
          Timeframe
        </Link>{" "}
        above for more detail.
      </p>

      <Typography variant="h2">Where</Typography>

      <p>
        Our analysis includes 129 low- and middle-income countries. The
        Landscape Report segments these countries into three income groups as
        defined by the World Bank: low-income countries, lower-middle-income
        countries, and upper-middle-income countries. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("income-groups")}
        >
          Income Groups
        </Link>{" "}
        above for more detail.
      </p>
      <p>
        The Custom Reports allow you to explore results not only by income group
        but also by region (geographic regions and donor priority regions). See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("country-groups")}
        >
          Country Groups
        </Link>{" "}
        above for more detail.
      </p>
    </div>
  );
};

export default MaternalHealthScope;
