import PropTypes from "prop-types";
import factors from "../../../../data/factors.json";
import Select from "../Select";
import ChooserOptions from "./ChooserOptions";

const rename = (name) => (name === "Cups" ? "Reusable cups" : name);

const validForArea = (area, indicator, method) => {
  switch (area) {
    case "contraception":
      return [
        "Condom",
        "IUD",
        "Implant",
        "Injectable",
        "Other",
        "Pill",
        "Sterilization",
      ].includes(method);

    case "menstrual-hygiene":
      return ["Disposables", "Reusables", "Reusable pads", "Cups"].includes(
        method
      );

    case "abortion":
      const relevantMethods = ["MVA", "Medical", "Miso"];

      if (indicator === "services") {
        relevantMethods.push("Mife+miso");
      } else if (indicator === "costs") {
        relevantMethods.push("Mife");
      }

      return relevantMethods.includes(method);

    case "maternal-health":
      return [
        "Heat-stable carbetocin",
        "Hydralazine",
        "Iron-folic acid",
        "Magnesium sulfate",
        "Methyldopa",
        "Metronidazole",
        "Misoprostol",
        "Oxytocin",
        "Tranexamic acid",
      ].includes(method);

    default:
      return false;
  }
};

const MethodChooser = (props) => {
  let label;

  switch (props.area) {
    case "menstrual-hygiene":
      label = "Product";

      break;

    case "maternal-health":
      label = "Drug(s)";

      break;

    default:
      label = "Method";

      break;
  }

  let methods = Object.keys(factors.methods)
    .filter((key) => validForArea(props.area, props.indicator, key))
    .map((key) => ({
      name: rename(key),
      value: factors.methods[key],
    }));

  switch (props.area) {
    case "abortion":
      methods = [
        {
          name: "Surgical: Vacuum aspiration",
          value: factors.methods["MVA"],
        },
        {
          name: "Medical: All",
          value: factors.methods["Medical"],
        },
        {
          name: "Medical: Miso only",
          value: factors.methods["Miso"],
        },
        {
          name: !props.mifeOnly ? "Medical: Miso & mife" : "Medical: Mife",
          value: factors.methods[!props.mifeOnly ? "Mife+miso" : "Mife"],
        },
      ];

      break;

    case "contraception":
      methods = [
        {
          name: "Sterilization",
          value: factors.methods["Sterilization"],
        },
        {
          name: "Implant",
          value: factors.methods["Implant"],
        },
        {
          name: "IUD",
          value: factors.methods["IUD"],
        },
        {
          name: "Injectable",
          value: factors.methods["Injectable"],
        },
        {
          name: "Pill",
          value: factors.methods["Pill"],
        },
        {
          name: "Condom",
          value: factors.methods["Condom"],
        },
        {
          name: "Other",
          value: factors.methods["Other"],
        },
      ];

      break;

    case "menstrual-hygiene":
      methods = [
        {
          name: "Disposables",
          value: factors.methods["Disposables"],
        },
        {
          name: "Reusables",
          value: factors.methods["Reusables"],
        },
        {
          name: "Reusable pads",
          value: factors.methods["Reusable pads"],
        },
        {
          name: "Reusable cups",
          value: factors.methods["Cups"],
        },
      ];

      break;

    case "maternal-health":
      methods = [
        {
          name: "Three life-saving commodities",
          value: 1000,
        },
        {
          name: "Iron and folic acid",
          value: 111,
        },
        {
          name: "Hydralazine",
          value: 116,
        },
        {
          name: "Methyldopa",
          value: 125,
        },
        {
          name: "Magnesium sulfate",
          value: 110,
        },
        {
          name: "Misoprostol",
          value: 120,
        },
        {
          name: "Oxytocin",
          value: 105,
        },
        {
          name: "Heat-stable carbetocin",
          value: 108,
        },
        {
          name: "Tranexamic acid",
          value: 126,
        },
        {
          name: "Metronidazole",
          value: 1011,
        },
      ];

      break;

    default:
      break;
  }

  return (
    <Select
      label={label}
      value={props.value}
      onChange={(value) => {
        props.onChange?.(parseInt(value, 10));
      }}
    >
      <ChooserOptions
        options={[
          {
            name: "All",
            value: 0,
          },
          ...methods,
        ]}
      />
    </Select>
  );
};

MethodChooser.propTypes = {
  area: PropTypes.oneOf([
    "contraception",
    "menstrual-hygiene",
    "abortion",
    "maternal-health",
  ]).isRequired,
  indicator: PropTypes.oneOf(["costs", "services"]),
  value: PropTypes.number,
  mifeOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

MethodChooser.defaultProps = {
  mifeOnly: false,
};

export default MethodChooser;
