import { useState } from "react";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import Error from "../Common/Error";
import Narrative from "../Common/Narrative";
import FeedbackDialog from "../Common/FeedbackDialog";
import useTimeout from "../../hooks/useTimeout";
import useLocalStorage from "../../hooks/useLocalStorage";
import AbortionComparison from "./Abortion/AbortionComparison";
import AbortionDeepDive from "./Abortion/AbortionDeepDive";
import AbortionLandscape from "./Abortion/AbortionLandscape";
import ContraceptionComparison from "./Contraception/ContraceptionComparison";
import ContraceptionDeepDive from "./Contraception/ContraceptionDeepDive";
import ContraceptionLandscape from "./Contraception/ContraceptionLandscape";
import CustomReportsMenu from "./CustomReportsMenu";
import MaternalHealthComparison from "./MaternalHealth/MaternalHealthComparison";
import MaternalHealthDeepDive from "./MaternalHealth/MaternalHealthDeepDive";
import MaternalHealthLandscape from "./MaternalHealth/MaternalHealthLandscape";
import MenstrualHygieneComparison from "./MenstrualHygiene/MenstrualHygieneComparison";
import MenstrualHygieneDeepDive from "./MenstrualHygiene/MenstrualHygieneDeepDive";
import MenstrualHygieneLandscape from "./MenstrualHygiene/MenstrualHygieneLandscape";

const useStyles = makeStyles((theme) => ({
  content: {
    marginBottom: "8rem",
    marginLeft: theme.navigation.section.width + theme.gutters.landscape.left,
    marginRight: theme.gutters.landscape.right,
    minWidth: 1152,
  },
  paper: {
    border: "none",
    backgroundColor: theme.palette.background.default,
    width: theme.navigation.section.width,
  },
  drawerContent: {
    display: "flex",
    alignContent: "center",
    minHeight: `calc(100vh - ${theme.appBar.height}px)`,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.appBar.height,
  },
  drawerContentWrapper: {
    margin: "auto 0",
  },
}));

const CustomReports = () => {
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  const [
    feedbackPreviouslyOffered,
    setFeedbackPreviouslyOffered,
  ] = useLocalStorage("feedbackOffered", false);

  const closeFeedbackDialog = () => {
    setFeedbackPreviouslyOffered(true);
    setFeedbackDialogOpen(false);
  };

  const handleFeedbackDialogClose = () => {
    closeFeedbackDialog();

    window.open("https://surveyhero.com/c/t3z9crxn");
  };

  const handleFeedbackDialogCancel = () => {
    closeFeedbackDialog();
  };

  useTimeout(() => {
    if (!feedbackPreviouslyOffered) {
      setFeedbackDialogOpen(true);
    }
  }, 120000);

  const classes = useStyles();

  return (
    <div>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.paper,
        }}
      >
        <div className={classes.drawerContent}>
          <div className={classes.drawerContentWrapper}>
            <CustomReportsMenu />
          </div>
        </div>
      </Drawer>
      <div className={classes.content}>
        <Narrative>
          <ErrorBoundary key={"custom-reports"} FallbackComponent={Error}>
            <Routes>
              <Route
                path="contraception"
                element={<ContraceptionLandscape />}
              />
              <Route
                path="contraception/comparison"
                element={<ContraceptionComparison />}
              />
              <Route
                path="contraception/deep-dive"
                element={<ContraceptionDeepDive />}
              />
              <Route
                path="menstrual-hygiene"
                element={<MenstrualHygieneLandscape />}
              />
              <Route
                path="menstrual-hygiene/comparison"
                element={<MenstrualHygieneComparison />}
              />
              <Route
                path="menstrual-hygiene/deep-dive"
                element={<MenstrualHygieneDeepDive />}
              />
              <Route path="abortion" element={<AbortionLandscape />} />
              <Route
                path="abortion/comparison"
                element={<AbortionComparison />}
              />
              <Route path="abortion/deep-dive" element={<AbortionDeepDive />} />
              <Route
                path="maternal-health"
                element={<MaternalHealthLandscape />}
              />
              <Route
                path="maternal-health/comparison"
                element={<MaternalHealthComparison />}
              />
              <Route
                path="maternal-health/deep-dive"
                element={<MaternalHealthDeepDive />}
              />
              <Route element={<Navigate to="/custom/contraception" />} />
            </Routes>
          </ErrorBoundary>
        </Narrative>
      </div>

      <FeedbackDialog
        open={feedbackDialogOpen}
        onClose={handleFeedbackDialogClose}
        onCancel={handleFeedbackDialogCancel}
      />
    </div>
  );
};

export default CustomReports;
