import PropTypes from "prop-types";
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import useChapterTheme from "../hooks/useChapterTheme";

const ThemeProvider = ({ children }) => {
  const theme = useChapterTheme();

  return (
    <MuiThemeProvider theme={createMuiTheme(theme)}>
      {children}
    </MuiThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ThemeProvider;
