import { useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CustomReport from "../CustomReport";
import DatasetButton from "../DatasetButton";
import RegionChooser from "../RegionChooser";
import { getRegionName } from "../../../utilities";
import AbortionIntroduction from "./AbortionIntroduction";
import AbortionScenarios from "./AbortionScenarios";
import ApacCsB1 from "./Boxes/ApacCsB1";
import ApacCsS1 from "./Summaries/ApacCsS1";
import ApacCsS2 from "./Summaries/ApacCsS2";
import ApacCsS3 from "./Summaries/ApacCsS3";
import ApacCsS4 from "./Summaries/ApacCsS4";
import ApacCsS5 from "./Summaries/ApacCsS5";
import ApacCsS6 from "./Summaries/ApacCsS6";
import ApacCsS7 from "./Summaries/ApacCsS7";
import ApacCsS8 from "./Summaries/ApacCsS8";
import ApacCsT4 from "./Tables/ApacCsT4";
import ApacCsT5 from "./Tables/ApacCsT5";
import ApacCsT6 from "./Tables/ApacCsT6";
import ApacCsT7 from "./Tables/ApacCsT7";

const useStyles = makeStyles((theme) => ({
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
}));

const AbortionLandscape = () => {
  const [selectedRegionId, setSelectedRegionId] = useState(144);

  const classes = useStyles();

  const selectedRegionLongName = getRegionName(selectedRegionId, "long");

  const handleRegionChange = (regionId) => {
    setSelectedRegionId(regionId);
  };

  return (
    <CustomReport>
      <Typography variant="h1">
        Abortion and PAC Landscape for {selectedRegionLongName}
      </Typography>

      <RegionChooser
        area="abortion"
        excludeAllCountries={true}
        value={selectedRegionId}
        onChange={handleRegionChange}
      />

      <section id="introduction">
        <Typography variant="h2">Background</Typography>

        <div className={classes.boxes}>
          <AbortionIntroduction>
            The <strong>Abortion &amp; PAC Regional Landscape</strong> provides
            estimates on the number of abortion and PAC services provided, the
            quantities of commodities, and the associated supply costs for the
            selected region.
          </AbortionIntroduction>

          <ApacCsB1 regionId={selectedRegionId} />
        </div>
      </section>

      <section id="current">
        <Typography variant="h2">Where We Are Now</Typography>

        <div className={classes.boxes}>
          <Typography variant="h6">
            Current Number of Abortion and PAC Services Across{" "}
            {selectedRegionLongName}
          </Typography>

          <ApacCsS1
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <ApacCsS2
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <Typography variant="h6">
            Current Costs of Abortion and PAC Supplies in{" "}
            {selectedRegionLongName}
          </Typography>

          <ApacCsS3
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />
        </div>
      </section>

      <section id="future">
        <Typography variant="h2">Changes Ahead</Typography>

        <div className={classes.boxes}>
          <AbortionScenarios />

          <Typography variant="h6">
            Future Number of Abortion and PAC Services in{" "}
            {selectedRegionLongName}
          </Typography>

          <ApacCsS4
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <ApacCsS5
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <ApacCsS6
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <Typography variant="h6">
            Future Costs of Abortion and PAC Supplies in{" "}
            {selectedRegionLongName}
          </Typography>

          <ApacCsS7
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <ApacCsS8
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />
        </div>
      </section>

      <section id="annex">
        <Typography variant="h2">Annex</Typography>

        <div className={classes.boxes}>
          <ApacCsT4
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <ApacCsT5
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <ApacCsT6
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <ApacCsT7
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <div className={classes.datasetDownload}>
            <DatasetButton area="Abortion & PAC" regionId={selectedRegionId} />
          </div>
        </div>
      </section>
    </CustomReport>
  );
};

export default AbortionLandscape;
