import PropTypes from "prop-types";
import ExternalLink from "../../../Common/ExternalLink";
import Footnote from "../../../Common/Footnote";
import MhCcF3 from "../Figures/MhCcF3";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatPercentage } from "../../../../utilities";

const calculateMetrics = (data) => {
  const shares = data?.map(({ share }) => share);

  return {
    minimumShare: shares ? Math.min(...shares) : 0,
    maximumShare: shares ? Math.max(...shares) : 0,
  };
};

const MhCcS4 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cc/figures/1/${props.regionalUnitId}/110`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        Magnesium sulfate is classified as one of the three life-saving maternal
        health commodities, and is used to treat pre-eclampsia and eclampsia
        <Footnote number={5}>
          Abalos E, Cuesta C, Carroli G, Qureshi Z, Widmer M, Vogel JP, Souza
          JP {" "}
          <ExternalLink
            href="https://pubmed.ncbi.nlm.nih.gov/24641531/"
            text="WHO Multicountry Survey on Maternal and Newborn Health Research Network. Pre-eclampsia, eclampsia and adverse maternal and perinatal outcomes: a secondary analysis of the World Health Organization Multicountry Survey on Maternal and Newborn Health"
            useItalics={true}
          />{" "}
          BJOG. 2014 Mar;121 Suppl 1:14-24.
        </Footnote>
        . Roughly 2% of all pregnancies will lead to pre-eclampsia and/or
        eclampsia. In Figure 3, we present the cases of pre-eclampsia and
        eclampsia , separating each into cases where magnesium sulfate was
        provided and those where it was not.
      </p>

      <p>
        Across all low- and middle-income countries, magnesium sulfate is given
        in 53% of cases. This ranges from{" "}
        {formatPercentage(metrics.minimumShare)} to{" "}
        {formatPercentage(metrics.maximumShare)} across {props.regionalUnitName}
        .
      </p>

      <MhCcF3 regionalUnitName={props.regionalUnitName} data={data} />
    </>
  );
};

MhCcS4.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcS4;
