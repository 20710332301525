import { useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CustomReport from "../CustomReport";
import RegionalUnitChooser from "../RegionalUnitChooser";
import SupplyChooser from "../SupplyChooser";
import { getRegionalUnitName, toTitleCase } from "../../../utilities";
import MhCpB1 from "./Boxes/MhCpB1";
import MaternalHealthIntroduction from "./MaternalHealthIntroduction";
import MaternalHealthScenarios from "./MaternalHealthScenarios";
import MhCpS1 from "./Summaries/MhCpS1";
import MhCpS2 from "./Summaries/MhCpS2";
import MhCpS3 from "./Summaries/MhCpS3";
import MhCpS4 from "./Summaries/MhCpS4";

const useStyles = makeStyles((theme) => ({
  choosers: {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(4),
    width: "fit-content",
  },
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
}));

const MaternalHealthDeepDive = () => {
  const [selectedProductId, setSelectedProductId] = useState(111);
  const [selectedProductName, setSelectedProductName] = useState(
    "Iron-folic acid"
  );
  const [selectedRegionalUnitId, setSelectedRegionalUnitId] = useState(0);

  const classes = useStyles();

  const selectedRegionalUnitName = getRegionalUnitName(
    selectedRegionalUnitId,
    true
  );

  const handleProductChange = (product) => {
    setSelectedProductId(product.id);
    setSelectedProductName(product.name);
  };

  const handleRegionalUnitChange = (regionalUnitId) => {
    setSelectedRegionalUnitId(regionalUnitId);
  };

  return (
    <CustomReport>
      <Typography variant="h1">
        Maternal Health Product Deep Dive into{" "}
        {toTitleCase(selectedProductName)}
      </Typography>

      <div className={classes.choosers}>
        <SupplyChooser
          area="maternal-health"
          value={selectedProductId}
          onChange={handleProductChange}
        />

        <RegionalUnitChooser
          value={selectedRegionalUnitId}
          onChange={handleRegionalUnitChange}
        />
      </div>

      <section id="introduction">
        <Typography variant="h2">Background</Typography>

        <div className={classes.boxes}>
          <MaternalHealthIntroduction>
            The <strong>Maternal Health Product Deep Dive</strong> provides
            estimates on the number of cases receiving, the quantities and
            related costs of the selected drug.
          </MaternalHealthIntroduction>

          <MhCpB1
            productId={selectedProductId}
            productName={selectedProductName}
          />
        </div>
      </section>

      <section id="current">
        <Typography variant="h2">Where We Are Now</Typography>

        <div className={classes.boxes}>
          {![108, 126].includes(selectedProductId) ? (
            <>
              <MhCpS1
                methodId={selectedProductId}
                methodName={selectedProductName}
              />

              <MhCpS2
                methodId={selectedProductId}
                methodName={selectedProductName}
                regionalUnitId={selectedRegionalUnitId}
                regionalUnitName={selectedRegionalUnitName}
              />
            </>
          ) : (
            <p>
              This drug is not included in the current landscape, see Changes
              Ahead below.
            </p>
          )}
        </div>
      </section>

      <section id="future">
        <Typography variant="h2">Changes Ahead</Typography>

        <div className={classes.boxes}>
          <MaternalHealthScenarios />

          <MhCpS3
            methodId={selectedProductId}
            methodName={selectedProductName}
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <MhCpS4
            methodId={selectedProductId}
            methodName={selectedProductName}
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />
        </div>
      </section>
    </CustomReport>
  );
};

export default MaternalHealthDeepDive;
