import { useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import BoxControls from "../../Common/Box/BoxControls";
import Box from "../../Common/Box/Box";
import Chart from "../../Common/Box/Chart";
import DrugChooser from "../../Common/Box/Choosers/DrugChooser";
import Legend from "../../Common/Box/Legend";
import { chartDownloadImageScaleFactor } from "../../../constants";
import initialData from "../../../data/initialData.json";
import exportCharts from "../../../export";
import useAreaQuery from "../../../hooks/useAreaQuery";
import useCsvDownload from "../../../hooks/useCsvDownload";
import theme from "../../../theme";
import {
  getDownloadFileStem,
  getTooltip,
  toTitleCase,
  translateSectorName,
} from "../../../utilities";

const key = "MH_f7";
const number = 7;

const getMaximumValue = (data) => {
  const scenarioCount = data[0].methods[0].data.length;

  let maximumValue = 0;

  for (let incomeGroup of data) {
    for (
      let scenarioIndex = 0;
      scenarioIndex < scenarioCount;
      scenarioIndex++
    ) {
      let scenarioTotal = 0;

      for (let method of incomeGroup.methods) {
        scenarioTotal += method.data[scenarioIndex];
      }

      maximumValue = Math.max(maximumValue, scenarioTotal);
    }
  }

  return maximumValue;
};

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Heat-stable carbetocin":
      return theme.palette.areas.maternalHealth.methods.heatStableCarbetocin;

    case "Hydralazine":
      return theme.palette.areas.maternalHealth.methods.hydralazine;

    case "Iron-folic acid":
      return theme.palette.areas.maternalHealth.methods.ironFolicAcid;

    case "Magnesium sulfate":
      return theme.palette.areas.maternalHealth.methods.magnesiumSulfate;

    case "Methyldopa":
      return theme.palette.areas.maternalHealth.methods.methyldopa;

    case "Metronidazole":
      return theme.palette.areas.maternalHealth.methods.metronidazole;

    case "Misoprostol":
      return theme.palette.areas.maternalHealth.methods.misoprostol;

    case "Needed: Not received":
      return theme.palette.areas.maternalHealth.methods.needsDidNotGet;

    case "Out-of-scope":
      return theme.palette.areas.maternalHealth.methods.outOfScope;

    case "Oxytocin":
      return theme.palette.areas.maternalHealth.methods.oxytocin;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = data.methods.map((method) => ({
    ...method,
    color: getSeriesColor(method.name, theme),
  }));

  return {
    chart: {
      type: "column",
      height: 350,
      backgroundColor: null,
      width: props.showYAxis ? 297 : 250,
      spacing: [10, 0, 15, 0],
    },
    title: {
      text: toTitleCase(data.incomeGroup),
      align: "center",
      x: props.showYAxis ? 22 : undefined,
    },
    xAxis: {
      categories: [
        "1: Maintain coverage",
        "2: Increase coverage",
        "3: Increase coverage and scale-up emerging drugs",
      ],
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          textAlign: "center",
        },
      },
    },
    yAxis: {
      title: undefined,
      min: 0,
      max: absolute ? props.yAxisMaximum : 100,
      reversedStacks: false,
      labels: {
        enabled: props.showYAxis,
        format: absolute ? undefined : "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: absolute ? "normal" : "percent",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Cases", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      chartOptions: {
        title: {
          text: data.incomeGroup,
        },
      },
      enabled: false,
    },
    series,
  };
};

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
  },
  facet: {
    margin: theme.spacing(0, 3),
  },
  facetPanel: {
    display: "flex",
  },
  legend: {
    marginTop: theme.spacing(),
  },
}));

const MhF7A = () => {
  const [selectedSectorId, setSelectedSectorId] = useState(112);
  const [selectedSectorName, setSelectedSectorName] = useState(
    "Iron and folic acid"
  );

  const [comparisonMode, setComparisonMode] = useState("relative");

  const { areaData: data } = useAreaQuery(
    `/mh/figures/7a/${selectedSectorId}`,
    initialData["mh_f7"]
  );

  const highchartRefs = [useRef(), useRef(), useRef()];

  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleSectorChange = (sector) => {
    setSelectedSectorId(sector.id);
    setSelectedSectorName(sector.name);
  };

  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  const handleCsvDownload = () => {
    const fileStem = getDownloadFileStem(key, number, selectedSectorName);

    downloadCsv(`/mh/figures/7a/${selectedSectorId}/csv`, `${fileStem}.csv`);
  };

  const yAxisMaximum = getMaximumValue(data) * 1.02;

  return (
    <Box
      id={key}
      caption={`Figure ${number}`}
      title={`Future Cases that Need and Receive Drugs for ${translateSectorName(
        selectedSectorName
      )}`}
      subtitle="By Future Scenario and Income Group, 2030"
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        >
          <DrugChooser value={selectedSectorId} onChange={handleSectorChange} />
        </BoxControls>
      }
      onDownloadCsv={handleCsvDownload}
      onDownloadPng={() => {
        exportCharts(
          {
            chart1: highchartRefs[0].current,
            chart2: highchartRefs[1].current,
            chart3: highchartRefs[2].current,
          },
          {
            filename: key,
            scale: chartDownloadImageScaleFactor,
          }
        );
      }}
    >
      <div className={classes.content}>
        <div className={classes.facetPanel}>
          {data.map((incomeGroup, index) => (
            <div key={incomeGroup.incomeGroup} className={classes.facet}>
              <Chart
                highchartRef={highchartRefs[index]}
                options={options(incomeGroup, {
                  yAxisMaximum,
                  showYAxis: index === 0,
                  comparisonMode,
                })}
              />
            </div>
          ))}
        </div>

        <div className={classes.legend}>
          <Legend
            series={data[0].methods.map(({ name }) => ({
              name,
              color: getSeriesColor(name, theme),
            }))}
            markerStyle="circle"
            orientation="horizontal"
          />
        </div>
      </div>
    </Box>
  );
};

export default MhF7A;
