import { useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CustomReport from "../CustomReport";
import RegionChooser from "../RegionChooser";
import { getRegionName } from "../../../utilities";
import FpCcB1 from "./Boxes/FpCcB1";
import ContraceptionIntroduction from "./ContraceptionIntroduction";
import ContraceptionScenarios from "./ContraceptionScenarios";
import FpCcCurrentCosts from "./Summaries/FpCcCurrentCosts";
import FpCcCurrentUse from "./Summaries/FpCcCurrentUse";
import FpCcFutureUse from "./Summaries/FpCcFutureUse";
import FpCcFutureCosts from "./Summaries/FpCcFutureCosts";

const useStyles = makeStyles((theme) => ({
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
}));

const ContraceptionComparison = () => {
  const [selectedRegionId, setSelectedRegionId] = useState(145);

  const classes = useStyles();

  const selectedRegionLongName = getRegionName(selectedRegionId, "long");

  const handleRegionChange = (regionId) => {
    setSelectedRegionId(regionId);
  };

  return (
    <CustomReport>
      <Typography variant="h1">
        Contraception Comparison Across {selectedRegionLongName}
      </Typography>

      <RegionChooser
        excludeAllCountries={true}
        value={selectedRegionId}
        onChange={handleRegionChange}
      />

      <section id="introduction">
        <Typography variant="h2">Background</Typography>

        <div className={classes.boxes}>
          <ContraceptionIntroduction>
            The <strong>Contraception Comparison Report</strong> provides
            estimates of the number of users, they methods they use, and related
            costs for the countries within the selected region.
          </ContraceptionIntroduction>

          <FpCcB1
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />
        </div>
      </section>

      <section id="current">
        <Typography variant="h2">Where We Are Now</Typography>

        <div className={classes.boxes}>
          <Typography variant="h6">
            Current Use of Contraceptives in {selectedRegionLongName}
          </Typography>

          <FpCcCurrentUse
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <Typography variant="h6">
            Current Cost of Contraceptive Supplies in {selectedRegionLongName}
          </Typography>

          <FpCcCurrentCosts
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />
        </div>
      </section>

      <section id="future">
        <Typography variant="h2">Changes Ahead</Typography>

        <div className={classes.boxes}>
          <ContraceptionScenarios />

          <Typography variant="h6">
            Future Use of Contraceptives in {selectedRegionLongName}
          </Typography>

          <FpCcFutureUse
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <Typography variant="h6">
            Future Cost of Contraceptive Supplies in {selectedRegionLongName}
          </Typography>

          <FpCcFutureCosts
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />
        </div>
      </section>
    </CustomReport>
  );
};

export default ContraceptionComparison;
