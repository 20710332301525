import { useState } from "react";
import PropTypes from "prop-types";
import SideBySide from "../../../Common/SideBySide";
import MhCpF3 from "../Figures/MhCpF3";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatNumberAsHumanReadable } from "../../../../utilities";

const calculateTotal = (scenario) =>
  scenario
    ? scenario.incomeGroups.reduce(
        (accumulator, { value }) => accumulator + value,
        0
      )
    : 0;

const Cases = (props) => (
  <>
    <p>
      If current coverage is maintained (Scenario 1), there would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data?.[0]))} cases
      receiving {props.methodName.toLowerCase()} in 2030 across all low- and
      middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[0].value ?? 0)}{" "}
      in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[1].value ?? 0)}{" "}
      in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[2].value ?? 0)}{" "}
      in upper-middle-income countries.
    </p>
    <p>
      Building in increases in coverage (Scenario 2), there would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data?.[1]))} cases
      receiving {props.methodName.toLowerCase()} in 2030 across all low- and
      middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[0].value ?? 0)}{" "}
      in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[1].value ?? 0)}{" "}
      in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[2].value ?? 0)}{" "}
      in upper-middle-income countries.
    </p>

    {props.includeScenario3 ? (
      <p>
        If increases in coverage are coupled with scale-up of emerging drugs
        (Scenario 3), where would be{" "}
        {formatNumberAsHumanReadable(calculateTotal(props.data?.[2]))} cases
        receiving {props.methodName.toLowerCase()} in 2030 across all low- and
        middle-income countries;{" "}
        {formatNumberAsHumanReadable(
          props.data?.[2].incomeGroups[0].value ?? 0
        )}{" "}
        in low-income countries,{" "}
        {formatNumberAsHumanReadable(
          props.data?.[2].incomeGroups[1].value ?? 0
        )}{" "}
        in lower-middle-income countries, and{" "}
        {formatNumberAsHumanReadable(
          props.data?.[2].incomeGroups[2].value ?? 0
        )}{" "}
        in upper-middle-income countries.
      </p>
    ) : null}
  </>
);

const Quantities = (props) => (
  <>
    <p>
      If current coverage is maintained (Scenario 1), there would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data?.[0]))} units of{" "}
      {props.methodName.toLowerCase()} used in 2030 across all low- and
      middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[0].value ?? 0)}{" "}
      in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[1].value ?? 0)}{" "}
      in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[2].value ?? 0)}{" "}
      in upper-middle-income countries.
    </p>
    <p>
      Building in increases in coverage (Scenario 2), there would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data?.[1]))} units of{" "}
      {props.methodName.toLowerCase()} used in 2030 across all low- and
      middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[0].value ?? 0)}{" "}
      in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[1].value ?? 0)}{" "}
      in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[2].value ?? 0)}{" "}
      in upper-middle-income countries.
    </p>

    {props.includeScenario3 ? (
      <p>
        If increases in coverage are coupled with scale-up of emerging drugs
        (Scenario 3), where would be{" "}
        {formatNumberAsHumanReadable(calculateTotal(props.data?.[2]))} units of{" "}
        {props.methodName.toLowerCase()} used in 2030 across all low- and
        middle-income countries;{" "}
        {formatNumberAsHumanReadable(
          props.data?.[2].incomeGroups[0].value ?? 0
        )}{" "}
        in low-income countries,{" "}
        {formatNumberAsHumanReadable(
          props.data?.[2].incomeGroups[1].value ?? 0
        )}{" "}
        in lower-middle-income countries, and{" "}
        {formatNumberAsHumanReadable(
          props.data?.[2].incomeGroups[2].value ?? 0
        )}{" "}
        in upper-middle-income countries.
      </p>
    ) : null}
  </>
);

const Costs = (props) => (
  <>
    <p>
      If current coverage is maintained (Scenario 1), the total cost of{" "}
      {props.methodName.toLowerCase()} would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data?.[0]), {
        prefix: "$",
      })}{" "}
      in 2030 across all low- and middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[0].value ?? 0, {
        prefix: "$",
      })}{" "}
      in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[1].value ?? 0, {
        prefix: "$",
      })}{" "}
      in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[2].value ?? 0, {
        prefix: "$",
      })}{" "}
      in upper-middle-income countries.
    </p>
    <p>
      Building in increases in coverage (Scenario 2), the total cost of{" "}
      {props.methodName.toLowerCase()} would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data?.[1]), {
        prefix: "$",
      })}{" "}
      million in 2030 across all low- and middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[0].value ?? 0, {
        prefix: "$",
      })}{" "}
      in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[1].value ?? 0, {
        prefix: "$",
      })}{" "}
      in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[2].value ?? 0, {
        prefix: "$",
      })}{" "}
      in upper-middle-income countries.
    </p>

    {props.includeScenario3 ? (
      <p>
        If increases in coverage are coupled with scale-up of emerging drugs
        (Scenario 3), the total cost of {props.methodName.toLowerCase()} would
        be{" "}
        {formatNumberAsHumanReadable(calculateTotal(props.data?.[2]), {
          prefix: "$",
        })}{" "}
        in 2030 across all low- and middle-income countries;{" "}
        {formatNumberAsHumanReadable(
          props.data?.[2].incomeGroups[0].value ?? 0,
          {
            prefix: "$",
          }
        )}{" "}
        in low-income countries,{" "}
        {formatNumberAsHumanReadable(
          props.data?.[2].incomeGroups[1].value ?? 0,
          {
            prefix: "$",
          }
        )}{" "}
        in lower-middle-income countries, and{" "}
        {formatNumberAsHumanReadable(
          props.data?.[2].incomeGroups[2].value ?? 0,
          {
            prefix: "$",
          }
        )}{" "}
        in upper-middle-income countries.
      </p>
    ) : null}
  </>
);

const Summary = ({ indicatorId, ...rest }) => {
  switch (indicatorId) {
    case 1:
      return <Costs {...rest} />;

    case 3:
      return <Quantities {...rest} />;

    case 4:
      return <Cases {...rest} />;

    default:
      return null;
  }
};

const MhCpS3 = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(4);

  const { areaData: data } = useAreaQuery(
    `/custom/mh/cp/figures/3/${props.methodId}/${selectedIndicatorId}`
  );

  const includeScenario3 = [120, 105, 108, 126].includes(props.methodId);

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  return (
    <SideBySide
      component={
        <MhCpF3
          methodName={props.methodName}
          indicatorId={selectedIndicatorId}
          includeScenario3={includeScenario3}
          data={data}
          onIndicatorChange={handleIndicatorChange}
        />
      }
      componentPosition="right"
    >
      <Summary
        methodName={props.methodName}
        indicatorId={selectedIndicatorId}
        includeScenario3={includeScenario3}
        data={data}
      />
    </SideBySide>
  );
};

MhCpS3.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCpS3;
