import PropTypes from "prop-types";
import MhCsF6 from "../Figures/MhCsF6";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getRegionName,
  toLowerCase,
} from "../../../../utilities";
import SideBySide from "../../../Common/SideBySide";

const MhCsS8 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/figures/6/${props.regionId}`
  );

  const sectors = data?.sectors;

  return (
    <SideBySide
      component={
        <MhCsF6
          regionId={props.regionId}
          regionName={props.regionName}
          regionShortName={getRegionName(props.regionId, "short")}
          data={data?.methods}
        />
      }
      componentPosition="right"
    >
      <p>
        The total cost of the seven priority maternal health drugs in{" "}
        {toLowerCase(props.regionName)} is currently{" "}
        {formatNumberAsHumanReadable(data?.cost ?? 0, {
          prefix: "$",
        })}
        ; this accounts for {formatPercentage(data?.share ?? 0)} of the drug
        cost across all low- and middle-income countries.
      </p>

      <p>
        The maternal health drug costs are a function of the unit prices of the
        drugs, the number of cases receiving the drugs, and the units needed per
        case.
      </p>

      <p>
        {sectors?.[0].name ?? ""} accounts for the largest share of costs (
        {formatPercentage(sectors?.[0].share ?? 0)}) in{" "}
        {toLowerCase(props.regionName)}. This is followed by{" "}
        {sectors?.[1].name ?? ""} ({formatPercentage(sectors?.[1].share ?? 0)})
        and {sectors?.[2].name ?? ""} (
        {formatPercentage(sectors?.[2].share ?? 0)}).
      </p>
    </SideBySide>
  );
};

MhCsS8.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsS8;
