import { Link, Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const MaternalHealthBirths = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Pregnancies and Births</Typography>

      <p>
        The total number of pregnancies and births (live and still) were modeled
        for this report. First, projections were made using the FamPlan module
        in Spectrum for each country that accounted for changes in contraceptive
        use and method mix aligned with the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-terms")}
        >
          Contraception
        </Link>{" "}
        section of LEAP
        <Footnote number={1}>
          Use of traditional methods based on UN projections was also included
          in the projections, as these methods are not included within this
          report.
        </Footnote>
        . FamPlan results for the number of intended pregnancies were used; a
        global assumption was made that 18% of intended pregnancies end in
        miscarriage. The remaining pregnancies were assumed to end in a birth.
        Country specific stillbirth rates
        <Footnote number={2}>
          Estimates generated by the{" "}
          <ExternalLink
            href="https://data.unicef.org/topic/child-survival/stillbirths/"
            text="UN Inter-agency Group for Child Mortality Estimation (UN IGME)"
            useItalics={true}
          />
          .
        </Footnote>{" "}
        from 2019 were used to split births between live births and stillbirths.
        For 2020 to 2030 stillbirth rates were projected to decline based on the
        average annual change seen in each country between 2014 and 2019 with
        two exceptions. For countries with an increasing rate, the 2019 rate was
        held constant into the future. For countries with a decline, the rate
        was not allowed to decline below a minimum of 2 per 1,000 births.
      </p>
      <p>
        Next, the total number of contraceptive users by method were multiplied
        by typical use failure rates to estimate the number of unintended
        pregnancies as a result of method failure
        <Footnote number={3}>
          Aligned with projections developed for the Contraception section of
          this report. As above, traditional methods were included based on UNPD
          projections. The following typical use failure rates were used:
          sterilization (100%), implant (99.7%), IUD (98.8%), injection (98%),
          pill (93.7%), male condom (91.4%), EC (93.7%), LAM (85%), other modern
          (85%), traditional (81.85%).
        </Footnote>
        . UNPD projections for women with an unmet need
        <Footnote number={4}>
          United Nations, Department of Economic and Social Affairs, Population
          Division (2020){" "}
          <ExternalLink
            href="https://www.un.org/en/development/desa/population/theme/family-planning/cp_model.asp"
            text="Estimates and Projections of Family Planning Indicators 2020"
            useItalics={true}
          />{" "}
          New York: United Nations.
        </Footnote>{" "}
        were used to estimate the total number of women with an unmet need each
        year; it was estimated that 41% of these women experience an unintended
        pregnancy. These two estimates of unintended pregnancies were summed
        together to estimate total unintended pregnancies in each country and
        for each year. To estimate abortions, sub-regional estimates of the
        proportion of unintended pregnancies ending in abortion
        <Footnote number={5}>
          Estimates for 2015-2019 by sub-region based on Appendix Table 4 from
          Bearak J, Popinchalk A, Ganatra B, Moller AB, Tunçalp Ö, Beavin C,
          Kwok L, Alkema L{" "}
          <ExternalLink
            href="https://www.thelancet.com/journals/langlo/article/PIIS2214-109X(20)30315-6/fulltext"
            text="Unintended pregnancy and abortion by income, region, and the legal status of abortion: estimates from a comprehensive model for 1990-2019"
            useItalics={true}
          />{" "}
          Lancet Glob Health. 2020 Sep;8(9):e1152-e1161. doi:
          10.1016/S2214-109X(20)30315-6. Epub 2020 Jul 22. PMID: 32710833.
        </Footnote>{" "}
        were used to calculate total abortions. We decided to hold these
        proportions constant, as historical trends show movement in different
        directions in different regions making it difficult to predict how these
        patterns may change. To estimate miscarriages from unintended
        pregnancies a global rate of 0.07 miscarriages per abortion was used
        <Footnote number={6}>
          Aligned to an assumption developed for{" "}
          <ExternalLink
            href="https://www.msichoices.org/what-we-do/our-approach/our-technical-expertise/impact-2/"
            text="Impact 2"
          />
          ; this accounts for the fact that miscarriage rates will be lower for
          unintended pregnancies as some pregnancies will be terminated by an
          abortion before they would have experienced a miscarriage if
          continued.
        </Footnote>
        . Finally, the remaining unintended pregnancies were estimated to end in
        births. The same calculations described above were done to split births
        into live births and stillbirths.
      </p>
    </div>
  );
};

export default MaternalHealthBirths;
