import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Table from "../../../Common/Box/Table";
import { capitalizePhrase, formatNumber } from "../../../../utilities";

const number = 2;

const format = (n) =>
  n !== 0
    ? formatNumber(n, {
        significantDigits: 3,
      })
    : "";

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child td:not(:first-child)": {
      minWidth: "6rem",
    },
  },
  comparisonCountryGroup: {
    fontWeight: "bold",
  },
  total: {
    backgroundColor: theme.palette.area.background,
  },
}));

const ApacCsT2 = (props) => {
  const classes = useStyles();

  return (
    <Table
      key="APAC_CS_t"
      number={number.toString()}
      title="Abortion and PAC Services by Method"
      subtitle={`${capitalizePhrase(
        props.regionName
      )} and all Low- and Middle-Income Countries, 2019`}
    >
      {props.data ? (
        <table className={classes.table}>
          <thead>
            <tr>
              <th />
              <th className={classes.total}>Total</th>
              <th colSpan={2}>Costed</th>
              <th colSpan={2}>Not costed</th>
            </tr>
            <tr>
              <th />
              {props.data[0].methods.map((method) => (
                <th
                  key={method.name}
                  className={
                    method.name === "Total" ? classes.total : undefined
                  }
                >
                  {method.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.data.map((countryGroup, index) => (
              <tr
                key={countryGroup.name}
                className={
                  props.data.length > 3 && index === 0
                    ? classes.comparisonCountryGroup
                    : undefined
                }
              >
                <td>{countryGroup.name}</td>

                {countryGroup.methods.map((method) => (
                  <td
                    key={method.name}
                    className={
                      method.name === "Total" ? classes.total : undefined
                    }
                  >
                    {format(method.services)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div />
      )}
    </Table>
  );
};

ApacCsT2.propTypes = {
  regionName: PropTypes.string.isRequired,
};

export default ApacCsT2;
