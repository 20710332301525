import { Link, Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const AbortionAbortionServicesByType = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Total Abortion Services by Type</Typography>

      <p>
        Using our estimate of number of abortions, we first used the WHO safety
        classifications to estimate the number of abortion services that were
        safe, less safe and least safe
        <Footnote number={19}>
          World Health Organization{" "}
          <ExternalLink
            href="https://www.who.int/news-room/fact-sheets/detail/preventing-unsafe-abortion"
            text="Preventing Unsafe Abortion Factsheet"
            useItalics={true}
          />
          .
        </Footnote>
        . Within the safe and less safe categories we then estimated the number
        of abortion services by method (surgical or medical), using regional
        data from the Guttmacher Institute&apos;s Adding it Up Methodology
        Report, including the country-specific data points they included where
        these were available
        <Footnote number={20}>
          Riley T, Sully EA, Lince-Derroche N, Firestein L, Murro R, Biddlecom
          A, Darroch JE{" "}
          <ExternalLink
            href="https://www.guttmacher.org/sites/default/files/report_pdf/adding-it-up-investing-in-sexual-reproductive-health-2019-methodology.pdf"
            text="Adding It Up: Investing in Sexual and Reproductive Health 2019&mdash;Methodology Report"
            useItalics={true}
          />{" "}
          New York: Guttmacher Institute, 2020.
        </Footnote>
        . We refined estimates of the types of medical abortions (misoprostol
        only versus misoprostol combined with mifepristone) using data on
        medical abortions shared by PSI, DTK International and published in
        DKT&apos;s{" "}
        <ExternalLink
          href="https://www.dktinternational.org/contraceptive-social-marketing-statistics/"
          text="DKT Social Marketing Statistics"
        />{" "}
        to capture the more recent introduction of mifepristone and combipacks
        in many contexts
        <Footnote number={21}>
          In some instances we increased the share of medical abortions (by
          decreasing D&C) to more closely align with total medical abortion
          estimates based on the NGO and SMO data.
        </Footnote>
        . See Table 3 under{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-future-scenarios")}
        >
          Future Scenarios
        </Link>{" "}
        for detailed assumptions.
      </p>
    </div>
  );
};

export default AbortionAbortionServicesByType;
