import { useEffect } from "react";
import PropTypes from "prop-types";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ErrorBoundary } from "react-error-boundary";
import { animateScroll } from "react-scroll";
import Error from "../Common/Error";
import Narrative from "../Common/Narrative";
import SectionMenu from "./SectionMenu";
import Citation from "./Citiation";

const useStyles = makeStyles((theme) => ({
  content: {
    marginBottom: "8rem",
    marginLeft: theme.navigation.section.width + theme.gutters.landscape.left,
    marginRight: theme.gutters.landscape.right,
    minWidth: 1152,
  },
  paper: {
    border: "none",
    backgroundColor: theme.palette.background.default,
    width: theme.navigation.section.width,
  },
  drawerContent: {
    display: "flex",
    alignContent: "center",
    minHeight: `calc(100vh - ${theme.appBar.height}px)`,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.appBar.height,
  },
  drawerContentWrapper: {
    margin: "auto 0",
  },
  citation: {
    marginTop: theme.spacing(6),
  },
}));

const ErrorBoundedComponent = (props) => (
  <ErrorBoundary key={props.id} FallbackComponent={Error}>
    {props.children}
  </ErrorBoundary>
);

const Landscape = ({ children }) => {
  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 0,
    });
  }, []);

  const classes = useStyles();

  return (
    <div>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.paper,
        }}
      >
        <div className={classes.drawerContent}>
          <div className={classes.drawerContentWrapper}>
            <SectionMenu />
          </div>
        </div>
      </Drawer>
      <div className={classes.content}>
        <Narrative>
          <ErrorBoundedComponent FallbackComponent={Error}>
            {children}
          </ErrorBoundedComponent>

          <Citation className={classes.citation} />
        </Narrative>
      </div>
    </div>
  );
};

Landscape.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Landscape;
