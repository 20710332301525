import { useState } from "react";
import PropTypes from "prop-types";
import ApacCcF6 from "../Figures/ApacCcF6";
import useAreaQuery from "../../../../hooks/useAreaQuery";

const ApacCcS6 = (props) => {
  const [selectedScenarioId, setSelectedScenarioId] = useState(5);
  const [selectedProductId, setSelectedProductId] = useState(1);

  const { areaData: data } = useAreaQuery(
    `/custom/apac/cc/figures/6/${props.regionalUnitId}/${selectedScenarioId}/${selectedProductId}`
  );

  const handleScenarioChange = (scenarioId) => {
    setSelectedScenarioId(scenarioId);
  };

  const handleProductChange = (productId) => {
    setSelectedProductId(productId);
  };

  return (
    <>
      <p>
        Changes in the number and type of abortion and PAC services would lead
        to changes in the cost of supplies. The pace of change varies by region
        as shown in the figure below. Use the dropdowns to explore changes by
        scenario and method.
      </p>

      <ApacCcF6
        regionalUnitName={props.regionalUnitName}
        scenarioId={selectedScenarioId}
        productId={selectedProductId}
        data={data}
        onScenarioChange={handleScenarioChange}
        onProductChange={handleProductChange}
      />
    </>
  );
};

ApacCcS6.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default ApacCcS6;
