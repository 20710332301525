import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Table from "../../../Common/Box/Table";
import { formatNumber, getSingular } from "../../../../utilities";

const number = 1;

const format = (n) =>
  n !== 0
    ? formatNumber(n, {
        significantDigits: 3,
      })
    : "";

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child td:not(:first-child)": {
      minWidth: "6rem",
    },
  },
  total: {
    backgroundColor: theme.palette.area.background,
  },
}));

const MhCcT1 = (props) => {
  const classes = useStyles();

  return (
    <Table
      key="MH_CC_t1"
      number={number.toString()}
      title="Number of Pregnancies and Births"
      subtitle={`By ${getSingular(props.regionalUnitName)}, 2019`}
    >
      {props.data ? (
        <table className={classes.table}>
          <thead>
            <tr>
              <th />
              <th className={classes.total} />
              <th colSpan={2}>Pregnancy outcomes</th>
            </tr>
            <tr>
              <th />
              <th className={classes.total}>Total</th>
              <th>
                Abortions or
                <br />
                miscarriages
              </th>
              <th>
                Births
                <br />
                (live &amp; still)
              </th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((countryGroup) => (
              <tr key={countryGroup.name}>
                <td>{countryGroup.name}</td>
                <td className={classes.total}>
                  {format(countryGroup.pregnancies)}
                </td>
                <td>{format(countryGroup.abortions)}</td>
                <td>{format(countryGroup.births)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div />
      )}
    </Table>
  );
};

MhCcT1.propTypes = {
  regionalUnitName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      pregnancies: PropTypes.number.isRequired,
      abortions: PropTypes.number.isRequired,
      births: PropTypes.number.isRequired,
    })
  ),
};

export default MhCcT1;
