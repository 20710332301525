import { useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CustomReport from "../CustomReport";
import SupplyChooser from "../SupplyChooser";
import { getMethodPlural, toTitleCase } from "../../../utilities";
import ContraceptionIntroduction from "./ContraceptionIntroduction";
import ContraceptionScenarios from "./ContraceptionScenarios";
import FpCpB1 from "./Boxes/FpCpB1";
import FpCpS1 from "./Summaries/FpCpS1";
import FpCpS2 from "./Summaries/FpCpS2";
import FpCpS3 from "./Summaries/FpCpS3";
import FpCpS4 from "./Summaries/FpCpS4";
import FpCpS5 from "./Summaries/FpCpS5";
import FpCpS6 from "./Summaries/FpCpS6";

const useStyles = makeStyles((theme) => ({
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
}));

const ContraceptionDeepDive = () => {
  const [selectedMethodId, setSelectedMethodId] = useState(5);
  const [selectedMethodName, setSelectedMethodName] = useState("Implant");

  const classes = useStyles();

  const handleMethodChange = (method) => {
    setSelectedMethodId(method.id);
    setSelectedMethodName(method.name);
  };

  return (
    <CustomReport>
      <Typography variant="h1">
        Contraception Product Deep Dive into{" "}
        {toTitleCase(getMethodPlural(selectedMethodName))}
      </Typography>

      <SupplyChooser
        area="contraception"
        value={selectedMethodId}
        onChange={handleMethodChange}
      />

      <section id="introduction">
        <Typography variant="h2">Background</Typography>

        <div className={classes.boxes}>
          <ContraceptionIntroduction>
            The <strong>Contraception Product Deep Dive</strong> provides
            estimates of the number of users, the quantities of supplies used,
            and related costs for a selected supply.
          </ContraceptionIntroduction>

          <FpCpB1 />
        </div>
      </section>

      <section id="current">
        <Typography variant="h2">Where We Are Now</Typography>

        <div className={classes.boxes}>
          <FpCpS1 methodId={selectedMethodId} methodName={selectedMethodName} />

          <FpCpS2 methodId={selectedMethodId} methodName={selectedMethodName} />

          <FpCpS3 methodId={selectedMethodId} methodName={selectedMethodName} />

          <FpCpS4 methodId={selectedMethodId} methodName={selectedMethodName} />
        </div>
      </section>

      <section id="future">
        <Typography variant="h2">Changes Ahead</Typography>

        <div className={classes.boxes}>
          <ContraceptionScenarios />

          <FpCpS5 methodId={selectedMethodId} methodName={selectedMethodName} />

          <FpCpS6 methodId={selectedMethodId} methodName={selectedMethodName} />
        </div>
      </section>
    </CustomReport>
  );
};

export default ContraceptionDeepDive;
