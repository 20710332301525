import PropTypes from "prop-types";
import ExternalLink from "../../../Common/ExternalLink";
import Footnote from "../../../Common/Footnote";
import MhCcF2 from "../Figures/MhCcF2";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatPercentage } from "../../../../utilities";

const calculateMetrics = (data) => {
  const shares = data?.map(({ share }) => share);

  return {
    minimumShare: shares ? Math.min(...shares) : 0,
    maximumShare: shares ? Math.max(...shares) : 0,
  };
};

const MhCcS3 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cc/figures/1/${props.regionalUnitId}/102`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        Hydralazine and methyldopa are both used to manage hypertension, which
        occurs in roughly 4-7% of all pregnancies{" "}
        <Footnote number={4}>
          Abalos E, Cuesta C, Carroli G, Qureshi Z, Widmer M, Vogel JP, Souza
          JP {" "}
          <ExternalLink
            href="https://pubmed.ncbi.nlm.nih.gov/24641531/"
            text="WHO Multicountry Survey on Maternal and Newborn Health Research Network. Pre-eclampsia, eclampsia and adverse maternal and perinatal outcomes: a secondary analysis of the World Health Organization Multicountry Survey on Maternal and Newborn Health"
            useItalics={true}
          />{" "}
          BJOG. 2014 Mar;121 Suppl 1:14-24.
        </Footnote>
        . Both drugs are included in Figure 2, which represents all cases where
        a pregnant woman experienced acute, gestational, and/or chronic
        hypertension. These are the most commonly used hypertension control
        drugs; instances of other drugs being used for hypertension are noted as
        &quot;out of scope&quot;.
      </p>

      <p>
        Across all low- and middle-income countries, antihypertensive drugs are
        used in 16% of cases. This ranges from{" "}
        {formatPercentage(metrics.minimumShare)} to{" "}
        {formatPercentage(metrics.maximumShare)} across {props.regionalUnitName}
        .
      </p>

      <MhCcF2 regionalUnitName={props.regionalUnitName} data={data} />
    </>
  );
};

MhCcS3.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcS3;
