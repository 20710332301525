import PropTypes from "prop-types";
import Select from "../Select";
import ChooserOptions from "./ChooserOptions";

const drugs = [
  {
    name: "Iron and folic acid",
    value: 112,
  },
  {
    name: "Antihypertensives",
    value: 102,
  },
  {
    name: "Magnesium sulfate",
    value: 110,
  },
  {
    name: "Uterotonics",
    value: 101,
  },
  {
    name: "Metronidazole",
    value: 111,
  },
];

const DrugChooser = (props) => (
  <Select
    label="Drug(s)"
    value={props.value}
    onChange={(value) => {
      const id = parseInt(value, 10);
      const drug = drugs.find((drug) => drug.value === id);

      props.onChange?.({
        id: drug.value,
        name: drug.name,
      });
    }}
  >
    <ChooserOptions options={drugs} />
  </Select>
);

DrugChooser.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default DrugChooser;
