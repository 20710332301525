import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  button: {
    border: `1px solid ${theme.controls.button.border.primary}`,
    borderRadius: 0,
    color: theme.controls.button.primary,
    fontSize: "0.625rem",
    width: 70,
    "&:last-child": {
      borderLeft: "none",
    },
  },
  component: {
    display: "flex",
  },
  selected: {
    backgroundColor: theme.controls.button.selected.background,
    color: theme.controls.button.selected.primary,
  },
}));

const ComparisonModeSwitch = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.component}>
      <Button
        size="small"
        className={classNames(
          classes.button,
          props.mode === "absolute" ? classes.selected : null
        )}
        onClick={() => {
          if (props.mode !== "absolute") {
            props.onToggle?.();
          }
        }}
      >
        Absolute
      </Button>
      <Button
        size="small"
        className={classNames(
          classes.button,
          props.mode === "relative" ? classes.selected : null
        )}
        onClick={() => {
          if (props.mode !== "relative") {
            props.onToggle?.();
          }
        }}
      >
        Relative
      </Button>
    </div>
  );
};

ComparisonModeSwitch.propTypes = {
  mode: PropTypes.oneOf(["absolute", "relative"]),
  onToggle: PropTypes.func,
};

ComparisonModeSwitch.defaultProps = {
  mode: "absolute",
};

export default ComparisonModeSwitch;
