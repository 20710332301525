import PropTypes from "prop-types";
import ExternalLink from "../../../Common/ExternalLink";
import Footnote from "../../../Common/Footnote";
import MhCcF5 from "../Figures/MhCcF5";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatPercentage } from "../../../../utilities";

const calculateMetrics = (data) => {
  const shares = data?.map(({ share }) => share);

  return {
    minimumShare: shares ? Math.min(...shares) : 0,
    maximumShare: shares ? Math.max(...shares) : 0,
  };
};

const MhCcS6 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cc/figures/1/${props.regionalUnitId}/111`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        Metronidazole is an antibiotic; while used to treat a range of
        infections here we focus on its use to treat maternal infections which
        occur among roughly 7% of women during and immediately after childbirth
        <Footnote number={8}>
          The WHO Global Maternal Sepsis Study (GLOSS) Research Group {" "}
          <ExternalLink
            href="https://www.thelancet.com/journals/langlo/article/PIIS2214-109X(20)30109-1/fulltext"
            text="Frequency and management of maternal infection in health facilities in 52 countries (GLOSS): a 1-week inception cohort study"
            useItalics={true}
          />{" "}
          Lancet Glob Health, 8 (2020), pp. e661-e671.
        </Footnote>
        . In Figure 5, we present cases of maternal infections, separating each
        into cases where metronidazole was provided and those where it was not.
      </p>

      <p>
        Across all low- and middle-income countries, metronidazole is given in
        54% of cases. This ranges from {formatPercentage(metrics.minimumShare)}{" "}
        to {formatPercentage(metrics.maximumShare)} across{" "}
        {props.regionalUnitName}.
      </p>

      <MhCcF5 regionalUnitName={props.regionalUnitName} data={data} />
    </>
  );
};

MhCcS6.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcS6;
