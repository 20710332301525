import { useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "../../Common/Box/Box";
import BoxControls from "../../Common/Box/BoxControls";
import Chart from "../../Common/Box/Chart";
import MethodChooser from "../../Common/Box/Choosers/MethodChooser";
import Legend from "../../Common/Box/Legend";
import { chartDownloadImageScaleFactor } from "../../../constants";
import initialData from "../../../data/initialData.json";
import exportCharts from "../../../export";
import useAreaQuery from "../../../hooks/useAreaQuery";
import useCsvDownload from "../../../hooks/useCsvDownload";
import theme from "../../../theme";
import { getDownloadFileStem, getMethod, getTooltip } from "../../../utilities";

const key = "MH_f20";
const number = 10;

const getMaximumValue = (data) => {
  let maximumValue = 0;

  for (let incomeGroup of data) {
    for (let scenario of incomeGroup.scenarios)
      for (let year of scenario.years) {
        maximumValue = Math.max(maximumValue, year.value);
      }
  }

  return maximumValue;
};

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Maintain":
      return theme.palette.scenarios.scenario1;

    case "Inc. coverage":
      return theme.palette.scenarios.scenario2;

    case "Inc. coverage & new tech.":
      return theme.palette.scenarios.scenario3;

    default:
      return "black";
  }
};

const options = (data, maximumValue, showYAxis) => (theme) => ({
  chart: {
    type: "spline",
    height: 350,
    backgroundColor: null,
    width: showYAxis ? 236 : 180,
  },
  title: {
    text: data.incomeGroup,
    align: "center",
    x: showYAxis ? 28 : undefined,
  },
  xAxis: {
    type: "datetime",
    min: 2017,
    max: 2032,
    tickPositions: [2019, 2025, 2030],
    tickLength: 0,
    labels: {
      formatter: function () {
        return [2019, 2025, 2030].includes(this.value) ? this.value : null;
      },
      style: {
        color: theme.figure.axis.secondary,
        fontSize: theme.figure.axis.fontSize,
      },
    },
  },
  yAxis: {
    title: undefined,
    min: 0,
    max: maximumValue,
    labels: {
      enabled: showYAxis,
      formatter: function () {
        return "$" + this.axis.defaultLabelFormatter.call(this);
      },
      style: {
        color: theme.figure.axis.secondary,
        fontSize: theme.figure.axis.fontSize,
      },
    },
  },
  plotOptions: {
    series: {
      lineWidth: 3,
      marker: {
        symbol: "circle",
        lineWidth: 3,
        fillColor: "white",
      },
      zoneAxis: "x",
      zones: [
        {
          value: 2025,
        },
        {
          dashStyle: "shortDot",
        },
      ],
    },
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    backgroundColor: "white",
    useHTML: true,
    outside: true,
    formatter: function () {
      return getTooltip("Cost", this.y, undefined, {
        prefix: "$",
      });
    },
    style: {
      fontSize: "0.875rem",
      color: theme.palette.tooltip.primary,
    },
  },
  exporting: {
    enabled: false,
  },
  series: data.scenarios.map((scenario) => {
    const color = getSeriesColor(scenario.name, theme);

    return {
      ...scenario,
      color,
      marker: {
        lineColor: color,
      },
      data: scenario.years.map((year) => ({
        x: year.year,
        y: year.value,
        marker: {
          enabled: [2019, 2025, 2030].includes(year.year),
        },
      })),
    };
  }),
});

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: "center",
    display: "flex",
  },
  facet: {
    margin: "2rem 0",
  },
  facetPanel: {
    display: "flex",
    justifyContent: "space-between",
  },
  legend: {
    marginLeft: theme.spacing(4),
  },
}));

const MhF20 = () => {
  const [selectedMethodId, setSelectedMethodId] = useState(0);

  const highchartRefs = [useRef(), useRef(), useRef()];

  const downloadCsv = useCsvDownload();

  const { areaData: data } = useAreaQuery(
    `/mh/figures/20/${selectedMethodId}`,
    initialData["mh_f20"]
  );

  const classes = useStyles();

  const handleMethodChange = (methodId) => {
    setSelectedMethodId(methodId);
  };

  const handleCsvDownload = () => {
    const fileStem = getDownloadFileStem(
      key,
      number,
      getMethod(selectedMethodId)
    );

    downloadCsv(`/mh/figures/20/${selectedMethodId}/csv`, `${fileStem}.csv`);
  };

  const yAxisMaximum = getMaximumValue(data) * 1.02;

  return (
    <Box
      key={key}
      caption={`Figure ${number}`}
      title="Change in Cost of Seven Priority Maternal Health Drugs Plus Two Emerging Drugs"
      subtitle="By Future Scenario and Income Group, 2019-2030"
      selection={getMethod(selectedMethodId)}
      controls={
        <BoxControls>
          <MethodChooser
            area="maternal-health"
            value={selectedMethodId}
            onChange={handleMethodChange}
          />
        </BoxControls>
      }
      onDownloadCsv={handleCsvDownload}
      onDownloadPng={() => {
        exportCharts(
          {
            chart1: highchartRefs[0].current,
            chart2: highchartRefs[1].current,
            chart3: highchartRefs[2].current,
          },
          {
            filename: key,
            scale: chartDownloadImageScaleFactor,
          }
        );
      }}
    >
      <div className={classes.content}>
        <div className={classes.facetPanel}>
          {data.map((incomeGroup, index) => (
            <div key={incomeGroup.incomeGroup} className={classes.facet}>
              <Chart
                highchartRef={highchartRefs[index]}
                options={options(incomeGroup, yAxisMaximum, index === 0)}
              />
            </div>
          ))}
        </div>

        <div className={classes.legend}>
          <Legend
            series={[
              {
                name: "3: Increase coverage and scale-up emerging drugs ",
                color: getSeriesColor("Inc. coverage & new tech.", theme),
              },
              {
                name: "2: Increase coverage",
                color: getSeriesColor("Inc. coverage", theme),
              },
              {
                name: "1: Maintain coverage",
                color: getSeriesColor("Maintain", theme),
              },
            ]}
            markerStyle="point"
          />
        </div>
      </div>
    </Box>
  );
};

export default MhF20;
