import { Link as RouterLink } from "react-router-dom";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import Information from "../../Common/Box/Information";

const Component = () => (
  <Information
    key="APAC_b5"
    number="5"
    title="The Impact of Changes in Contraceptive Use and Unmet Need for Contraception on Unintended Pregnancies"
  >
    <p>
      Abortions are largely the result of unintended pregnancies. In the future
      we project the number of unintended pregnancies to change based on:
    </p>

    <ul>
      <li>Demographic changes in the number of women of reproductive age;</li>
      <li>
        Changes to unmet need for contraception based on UN Population Division
        projections
        <Footnote number={20}>
          United Nations, Department of Economic and Social Affairs, Population
          Division{" "}
          <ExternalLink
            href="https://www.un.org/en/development/desa/population/theme/family-planning/cp_model.asp"
            text="Estimates and Projections of Family Planning Indicators 2020"
            useItalics={true}
          />{" "}
          New York: United Nations.
        </Footnote>
        , and;
      </li>
      <li>
        Changes in women experiencing method failure based on changes in
        contraceptive use and contraceptive method mix (see the{" "}
        <RouterLink to="/contraception">Contraception Landscape</RouterLink>
        ).
      </li>
    </ul>

    <p>
      Changes in unmet need for contraception, which are driven in part by
      increases in contraceptive use, would play the largest role in reducing
      unintended pregnancy. Based on expected changes, we estimate there would
      be 59.7 million unintended pregnancies in 2030, compared to 64.3 million
      if there had been no changes to unmet need for contraception.
    </p>

    <p>
      These changes in unmet need for contraception are by far the major driver
      in changing the number of unintended pregnancies and thus the number of
      abortions. We found changes in contraceptive method mix and associated
      failure rates only had a marginal impact on the number of unintended
      pregnancies in 2030.
    </p>
  </Information>
);

export default Component;
