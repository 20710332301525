import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import useCsvDownload from "../../hooks/useCsvDownload";

const getSlug = (area) => {
  switch (area) {
    case "Abortion & PAC":
      return "apac";

    case "Contraception":
      return "fp";

    case "Maternal Health":
      return "mh";

    case "Menstrual Hygiene":
      return "mhm";

    default:
      return area;
  }
};

const createLink = (slug, regionId) => {
  return `/custom/${slug}/data/${regionId}`;
};

const useStyles = makeStyles((theme) => ({
  component: {
    color: "primary",
  },
}));

const Dataset = (props) => {
  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleCsvDownload = () => {
    const slug = getSlug(props.area);

    const link = createLink(slug, props.regionId);

    downloadCsv(link, `custom_${slug}_${props.regionId}.csv`);
  };

  return (
    <Button
      variant="contained"
      color="default"
      className={classes.component}
      onClick={handleCsvDownload}
    >
      Download {props.area} data
    </Button>
  );
};

Dataset.propTypes = {
  area: PropTypes.oneOf([
    "Abortion & PAC",
    "Contraception",
    "Maternal Health",
    "Menstrual Hygiene",
  ]).isRequired,
  regionId: PropTypes.number.isRequired,
};

export default Dataset;
