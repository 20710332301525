import Figure from "../../../Common/Box/Figure";
import { capitalizePhrase, getTooltip } from "../../../../utilities";

const number = 6;

const getSeriesColor = (name) => {
  switch (name) {
    case "2019":
      return "#c193ac";

    case "2025":
      return "#ac6f90";

    case "2030":
      return "#7d4765";

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const series = data.map((series) => ({
    ...series,
    color: getSeriesColor(series.name),
  }));

  return {
    chart: {
      type: "column",
      width: 470,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: [
        "1: Maintain safety profile & method mix",
        "2: Shift safety profile",
        "3: Shift safety profile & method mix",
      ],
    },
    yAxis: {
      title: undefined,
      labels: {
        formatter: function () {
          return "$" + this.axis.defaultLabelFormatter.call(this);
        },
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Cost", this.y, this.percentage, {
          prefix: "$",
        });
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const ApacCsF6 = (props) => {
  return (
    <Figure
      id="APAC_CS_f6"
      number={number.toString()}
      fileStem={`apac_cs_f${number}`}
      title="Change in Cost of Abortion and PAC Supplies"
      subtitle={`${capitalizePhrase(
        props.regionName
      )} by Future Scenario, 2019-2030`}
      chartOptions={options(props.data)}
    />
  );
};

export default ApacCsF6;
