import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  component: {
    stroke: theme.figure.axis.secondary,
    height: 200,
    width: 50,
  },
}));

const DetailIndicator = () => {
  const classes = useStyles();

  return (
    <svg
      height="100%"
      width="100%"
      viewBox="0 0 50 100"
      preserveAspectRatio="none"
      className={classes.component}
    >
      <line x1="2" y1="48" x2="48" y2="0" />
      <line x1="2" y1="48" x2="48" y2="100" />
    </svg>
  );
};

export default DetailIndicator;
