import PropTypes from "prop-types";
import FpCcF8 from "../Figures/FpCcF8";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getMethodPlural,
  getQualitativeChangeDirection,
  toLowerCase,
} from "../../../../utilities";

const FpCcFutureCosts = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/fp/cc/summaries/6/${props.regionId}/1`
  );

  const costsTotal = data?.slice(-1)[0];

  return (
    <>
      <p>
        We analyze &quot;cost&quot; based on the last price paid for a
        contraceptive method before it is actually used or obtained by the
        consumer. We employ this definition because it is at this
        &quot;handover&quot; point in a product&apos;s value chain where price
        acts as the principal cost barrier to access. For products made
        available through the public sector, cost is calculated on the basis of
        unit costs paid by procurers (governments and/or donors) before it is
        distributed, generally for free, to users. In the private sector, costs
        reflect the prices paid by users themselves, typically through direct
        out-of-pocket expenditures. Note that all costs are based on current
        supply costs, held constant for all years through to 2030.
      </p>

      {data && (
        <>
          <p>
            If current trends continue, we anticipate the total cost of
            contraceptive supplies in {toLowerCase(props.regionName)} would{" "}
            {getQualitativeChangeDirection(costsTotal.percentageChange)} from{" "}
            {formatNumberAsHumanReadable(costsTotal.initial, {
              prefix: "$",
            })}{" "}
            in 2019 to $
            {formatNumberAsHumanReadable(costsTotal.final, {
              prefix: "$",
            })}{" "}
            in 2030 (
            {formatNumberAsHumanReadable(costsTotal.change, {
              prefix: "$",
              prefixWithSign: true,
            })}
            ,{" "}
            {formatPercentage(costsTotal.percentageChange, {
              prefixWithSign: true,
            })}
            ).
          </p>

          <p>
            As with users, if current patterns in the evolution of method mix
            persist, change in costs would not be even across all methods. The
            cost of {getMethodPlural(data[0].name)} would see the most rapid
            growth in absolute terms (
            {formatNumberAsHumanReadable(data[0].change, {
              prefix: "$",
              prefixWithSign: true,
            })}
            ,{" "}
            {formatPercentage(data[0].percentageChange, {
              prefixWithSign: true,
            })}
            ).
          </p>
        </>
      )}

      <p>
        Changes in total supply costs and costs by method would differ by
        country as shown in the figure below.
      </p>

      <FpCcF8 regionId={props.regionId} regionName={props.regionName} />
    </>
  );
};

FpCcFutureCosts.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default FpCcFutureCosts;
