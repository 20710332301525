import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router-dom";
import Information from "../../../Common/Box/Information";

const useStyles = makeStyles(() => ({
  content: {
    width: "100%",
    justifyContent: "flex-start",
  },
}));

const MhmCpB1 = () => {
  const classes = useStyles();

  return (
    <Information key="MHM_CP_b1" number="1" title="Scope" classes={classes}>
      <p>
        The methodology for LEAP was developed to produce results for all low-
        and middle-income countries. While we make country results available,
        they should be viewed in this context. Country-level results are derived
        from either country-specific data sources or regional and income group
        averages.
      </p>
      <p>
        For detailed results for a single country, see the{" "}
        <RouterLink to="/custom/menstrual-hygiene">Custom Landscape</RouterLink>
        .
      </p>
    </Information>
  );
};
export default MhmCpB1;
