import { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import BoxControls from "../../Common/Box/BoxControls";
import MethodGroupChooser from "../../Common/Box/Choosers/MethodGroupChooser";
import Table from "../../Common/Box/Table";
import initialData from "../../../data/initialData.json";
import useAreaQuery from "../../../hooks/useAreaQuery";
import useCsvDownload from "../../../hooks/useCsvDownload";
import {
  formatNumber,
  getDownloadFileStem,
  getMethodGroup,
  toTitleCase,
} from "../../../utilities";

const key = "MH_t1";
const number = 3;

const format = (n) =>
  formatNumber(n, {
    significantDigits: 3,
  });

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& th:not(:first-child)": {
      width: "8rem",
    },
  },
}));

const Component = () => {
  const [selectedMethodGroupId, setSelectedMethodGroupId] = useState(1);

  const { areaData: data } = useAreaQuery(
    `/mh/tables/1/${selectedMethodGroupId}`,
    initialData["mh_t1"]
  );

  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleMethodGroupChange = (methodGroupId) => {
    setSelectedMethodGroupId(methodGroupId);
  };

  const handleCsvDownload = () => {
    const fileStem = getDownloadFileStem(
      key,
      number,
      getMethodGroup(selectedMethodGroupId)
    );

    downloadCsv(`/mh/tables/1/${selectedMethodGroupId}/csv`, `${fileStem}.csv`);
  };

  return (
    <Table
      key={key}
      number={number.toString()}
      title="Total Cases Needing and Receiving Seven Priority Maternal Health Drugs"
      subtitle="By Income Group, 2019"
      source='Note: in this table cases needing and receiving the antihypertensive drugs (hydralazine and methyldopa) are presented summed together and cases receiving out-of-scope drugs excluded from the "Needed: Received" total; and cases needing and receiving uterotonics (misoprostol and oxytocin) are presented summed together.'
      controls={
        <BoxControls>
          <MethodGroupChooser
            value={selectedMethodGroupId}
            onChange={handleMethodGroupChange}
          />
        </BoxControls>
      }
      onDownloadCsv={handleCsvDownload}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th>Iron-folic acid</th>
            <th>Antihypertensives</th>
            <th>Magnesium sulfate</th>
            <th>Uterotonics</th>
            <th>Metronidazole</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.incomeGroup}>
              <td>{toTitleCase(item.incomeGroup)}</td>
              <td>{format(item.ironFolicAcid)}</td>
              <td>{format(item.antihypertensives)}</td>
              <td>{format(item.magnesiumSulfate)}</td>
              <td>{format(item.uterotonics)}</td>
              <td>{item.metronidazole ? format(item.metronidazole) : ""}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Table>
  );
};

export default Component;
