import PropTypes from "prop-types";
import { CssBaseline } from "@material-ui/core";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import highMaps from "highcharts/modules/map";
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting";
import VariwideModule from "highcharts/modules/variwide";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactGA from "react-ga";
import { HashRouter as Router } from "react-router-dom";
import { ProvideAnalytics } from "../hooks/useAnalytics";
import { ProvideReadersGuide } from "../hooks/useReadersGuide";
import theme from "../theme";
import ThemeProvider from "./ThemeProvider";

ReactGA.initialize("UA-59586848-1");

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: theme.typography.fontFamily,
    },
  },
  lang: {
    numericSymbols: ["k", "M", "B", "T", "P", "E"],
  },
});

VariwideModule(Highcharts);
highMaps(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsOfflineExporting(Highcharts);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const Configuration = ({ children }) => (
  <Router>
    <ThemeProvider>
      <CssBaseline />
      <ProvideAnalytics>
        <QueryClientProvider client={queryClient}>
          <ProvideReadersGuide>
            <div>{children}</div>
          </ProvideReadersGuide>
        </QueryClientProvider>
      </ProvideAnalytics>
    </ThemeProvider>
  </Router>
);

Configuration.propType = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Configuration;
