import Figure from "../../Common/Box/Figure";
import initialData from "../../../data/initialData.json";
import { getTooltip } from "../../../utilities";

const number = 4;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Low":
      return "#f37447";

    case "Lower-middle":
      return "#f69877";

    case "Upper-middle":
      return "#f8b096";

    default:
      return "black";
  }
};

const options = (data) => (theme) => {
  const series = data.map((series) => ({
    ...series,
    color: getSeriesColor(series.name, theme),
  }));

  return {
    chart: {
      type: "column",
      width: 400,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: ["Menstruators", "Costs"],
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        format: "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "percent",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        const indicator = this.x === "Costs" ? "Cost" : "Menstruators";

        return getTooltip(indicator, this.y, this.percentage, {
          prefix: indicator === "Cost" ? "$" : undefined,
        });
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const Component = () => {
  return (
    <Figure
      id="MHM_f5"
      number={number.toString()}
      fileStem={`mhm_f${number}`}
      title="Share of Menstruators versus Costs"
      subtitle="By Income Group, 2019"
      chartOptions={options(initialData["mhm_f5"])}
    />
  );
};

export default Component;
