import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const FeedbackDialog = (props) => (
  <Dialog open={props.open} onClose={props.onClose}>
    <DialogTitle>Custom Report Feedback Survey</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Would you be willing to complete a short survey to provide feedback on
        the LEAP Custom Reports? This feedback will help support improvements to
        and wider use of the Custom Reports.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onCancel} color="primary">
        No
      </Button>
      <Button onClick={props.onClose} color="primary" autoFocus>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

FeedbackDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};

FeedbackDialog.defaultProps = {
  open: false,
};

export default FeedbackDialog;
