import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ReactComponent as SadIcon } from "../../images/sad.svg";

const useStyles = makeStyles((theme) => ({
  component: {
    alignItems: "center",
    color: theme.palette.error.main,
    display: "flex",
    fontSize: "1rem",
  },
  icon: {
    fill: theme.palette.error.main,
    height: 48,
    marginRight: theme.spacing(),
    width: 48,
  },
}));

const Error = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.component}>
      <SadIcon className={classes.icon} />
      <div>{message}</div>
    </div>
  );
};

Error.propTypes = {
  message: PropTypes.string,
};

Error.defaultProps = {
  message: "Oops. Something went wrong. Try refreshing the page.",
};

export default Error;
