import { Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";

const AbortionCosts = () => (
  <div>
    <Typography variant="h1">Supply Costs</Typography>

    <p>
      The supply cost reflects the quantity of supplies used, multiplied by
      their unit price. These costs are generally paid by the individual (i.e.
      the person seeking an abortion or PAC service), though PAC-related costs
      may be more likely to be covered by public health systems
      <Footnote number={27}>
        Vlassoff M, Singh S, Onda T{" "}
        <ExternalLink
          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5013781/"
          text="The cost of post-abortion care in developing countries: a comparative analysis of four studies"
          useItalics={true}
        />{" "}
        Health Policy Plan. 2016 Oct; 31(8): 1020–1030.
      </Footnote>
      <Footnote number={28}>
        van der Meulen Rodgers Y, Coast E, Lattof SR, Poss C, Moore B{" "}
        <ExternalLink
          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8101771/"
          text="The macroeconomics of abortion: A scoping review and analysis of the costs and outcomes"
          useItalics={true}
        />{" "}
        PLoS One. 2021; 16(5): e0250692.
      </Footnote>
      . All costs are in US dollars and unit prices are held constant into the
      future (e.g. no inflation).
    </p>
    <p>
      For vacuum aspiration, as limited data exist to capture regional price
      differences, we used a global price of $16.50 for an MVA kit. This was
      based on the price of the IPAS MVA kit along with an assumption that each
      kit can be used 25 times, resulting in a per-service cost of $0.66
      <Footnote number={29}>
        DKT WomanCare{" "}
        <ExternalLink
          href="https://dktwomancare.org/corporate-news/price-reduction-ipas-mva"
          text="Global Announces Ipas Manual Vacuum Aspiration (MVA) Technology Price Reduction for All FP2020 Focus Countries"
          useItalics={true}
        />{" "}
        July 2019.
      </Footnote>
      . We sourced the cost of misoprostol and mifepristone from the IPPF{" "}
      <ExternalLink
        href="https://www.medab.org/"
        text="Medical Abortion Commodities Database"
      />
      , including additional country data that was shared for use in this
      analysis. The costs of misoprostol and mifepristone vary widely by region
      (see Table 3 in the Abortion &amp; PAC Landscape), with the lowest costs
      found in Asia. Where they exist, combipacks combining misoprostol and
      mifepristone are generally cheaper, pill for pill, than buying misoprostol
      and mifepristone individually, but are not as widely available.
    </p>
    <p>
      Note the costs included in LEAP for abortion and PAC only include the
      supplies needed for each service, and not any associated costs such as
      provider time, provision of a clean clinical space or supporting supplies
      such as gauze or pain relief. Thus supply costs may only account for a
      fraction of total service costs, particularly for services provided using
      surgical methods.
    </p>
  </div>
);

export default AbortionCosts;
