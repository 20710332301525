import { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DeferrerRenderer from "../DeferredRenderer";
import useVisible from "../../../hooks/useVisible";
import theme from "../../../theme";

const useStyles = makeStyles({
  placeholder: (props) => ({
    height: props.chart.height,
  }),
});

const Chart = (props) => {
  const ref = useRef();
  const visible = useVisible(ref);

  const options = {
    title: undefined,
    credits: {
      enabled: false,
    },
    ...props.options(theme),
  };

  if (!options.plotOptions) {
    options.plotOptions = {};
  }

  if (!options.plotOptions.series) {
    options.plotOptions.series = {};
  }

  options.plotOptions.series.animation = false;

  if (!options.exporting) {
    options.exporting = {};
  }

  options.exporting.filename = props.filename;

  const classes = useStyles(options);

  return (
    <div ref={ref}>
      <DeferrerRenderer
        width={options.chart.width}
        height={options.chart.height}
      >
        {visible ? (
          <HighchartsReact
            ref={props.highchartRef}
            highcharts={Highcharts}
            options={options}
            immutable={props.annotations}
            callback={
              props.annotations
                ? (chart) => {
                    chart.redraw();
                  }
                : null
            }
          />
        ) : (
          <div className={classes.placeholder} />
        )}
      </DeferrerRenderer>
    </div>
  );
};

Chart.propType = {
  highchartRef: PropTypes.object,
  options: PropTypes.func.isRequired,
  annotations: PropTypes.bool,
};

Chart.defaultProps = {
  annotations: false,
};

export default Chart;
