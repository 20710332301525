import { makeStyles } from "@material-ui/styles";
import { ReactComponent as PlaceholderIcon } from "../../../images/chart_placeholder.svg";

const useStyles = makeStyles((theme) => ({
  component: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
  },
  icon: {
    fill: theme.palette.placeholders.primary,
    width: 256,
  },
}));

const ChartPlaceholder = () => {
  const classes = useStyles();

  return (
    <div className={classes.component}>
      <PlaceholderIcon className={classes.icon} />
    </div>
  );
};

export default ChartPlaceholder;
