import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import BoxControls from "../../../Common/Box/BoxControls";
import ScenarioChooser from "../../../Common/Box/Choosers/ScenarioChooser";
import Figure from "../../../Common/Box/Figure";
import ProductChooser from "../../ProductChooser";
import { capitalizePhrase, getTooltip } from "../../../../utilities";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import theme from "../../../../theme";

const number = 3;

const getSeries = (data) => {
  return [
    {
      name: "2019",
      data: data.map((country, index) => ({
        x: index,
        y: country.years[0].menstruators,
      })),
      color: "#a9c9c4",
      marker: {
        fillColor: "#a9c9c4",
      },
    },
    {
      name: "2025",
      data: data.map((country, index) => ({
        x: index,
        y: country.years[1].menstruators,
      })),
      color: "#4ca198",
      marker: {
        fillColor: "#4ca198",
      },
    },
    {
      name: "2030",
      data: data.map((country, index) => ({
        x: index,
        y: country.years[2].menstruators,
      })),
      color: "#3d847d",
      marker: {
        fillColor: "#3d847d",
      },
    },
  ];
};

const drawRange = (chart, countryCount, countryIndex, y1, y2) => {
  const countryOffset = countryCount - countryIndex;

  const xAxis = chart.xAxis[0];
  const yAxis = chart.yAxis[0];

  const itemGap = xAxis.translate(countryCount - 1.5);
  const itemCenter = xAxis.top + xAxis.translate(countryOffset) + itemGap;

  chart.renderer
    .path([
      "M",
      yAxis.left + yAxis.translate(y1),
      itemCenter,
      "L",
      yAxis.left + yAxis.translate(y2),
      itemCenter,
    ])
    .attr({
      "z-index": -1,
      "stroke-width": 1,
      stroke: theme.palette.grays.gray4,
    })
    .add();
};

const drawRanges = (chart, data) => {
  const countryCount = data.length;

  for (let i = 0; i < countryCount; i++) {
    const y1 = Math.min(...data[i].years.map((year) => year.menstruators));
    const y2 = Math.max(...data[i].years.map((year) => year.menstruators));

    drawRange(chart, countryCount, i, y1, y2);
  }
};

const options = (data) => (theme) => {
  const series = getSeries(data);

  return {
    chart: {
      type: "scatter",
      width: 1000,
      height: 1000,
      inverted: true,
      backgroundColor: null,
      events: {
        redraw: function () {
          drawRanges(this, data, series);
        },
      },
    },
    xAxis: {
      categories: data.map((country) => country.name),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      opposite: true,
      labels: {
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          symbol: "circle",
          radius: 4,
          lineWidth: 1,
          lineColor: "silver",
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      reversed: false,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Menstruators", this.y);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const useStyles = makeStyles((theme) => ({
  choosers: {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(2),
    width: "fit-content",
  },
}));

const MhmCcF3 = (props) => {
  const [selectedScenarioId, setSelectedScenarioId] = useState(5);
  const [selectedProductId, setSelectedProductId] = useState(0);

  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cc/figures/3/${props.regionId}/${selectedScenarioId}/${selectedProductId}`
  );

  const classes = useStyles();

  const handleScenarioChange = (scenarioId) => {
    setSelectedScenarioId(scenarioId);
  };

  const handleProductChange = (productId) => {
    setSelectedProductId(productId);
  };

  return (
    <Figure
      id="MHM_CC_f3"
      number={number.toString()}
      title="Change in Menstruators"
      subtitle={`${capitalizePhrase(
        props.regionName
      )} by Country and Future Scenario, 2019-2030`}
      chartOptions={data && options(data)}
      controls={
        <BoxControls>
          <div className={classes.choosers}>
            <ScenarioChooser
              area="menstrual-hygiene"
              value={selectedScenarioId}
              onChange={handleScenarioChange}
            />
            <ProductChooser
              value={selectedProductId}
              onChange={handleProductChange}
            />
          </div>
        </BoxControls>
      }
      annotations={true}
    />
  );
};

MhmCcF3.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCcF3;
