import PropTypes from "prop-types";
import MhCsF8 from "../Figures/MhCsF8";

const MhCsS11 = (props) => (
  <>
    <p>
      Based on changes in the number of pregnancies and births, the number of
      cases receiving the needed drugs will change over time, even if coverage
      of maternal health interventions is maintained at current levels, as
      envisioned under Scenario 1. When coupled with the increases in
      intervention coverage envisioned under Scenario 2, ever more dramatic
      changes can be seen. Meanwhile, Scenario 3, which layers in the scale-up
      of two emerging drugs, holds significant implications for the use of drugs
      for prevention and treatment of PPH (uterotonics and tranexamic acid).
    </p>

    <p>
      Changes in the number of cases receiving drugs will different for each of
      drug, as shown below in Figure 8.
    </p>

    <MhCsF8 regionId={props.regionId} regionName={props.regionName} />
  </>
);

MhCsS11.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsS11;
