import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExternalLink from "./ExternalLink";

const useStyles = makeStyles((theme) => ({
  content: {
    fontSize: "0.875rem",
    maxWidth: 500,
    padding: theme.spacing(),
    "& a": {
      color: "white",
    },
  },
  label: {
    color: theme.palette.link.primary,
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const Footnote = (props) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={
        <div>
          <div>{props.children}</div>
          {props.href && (
            <div>
              <ExternalLink href={props.href} />
            </div>
          )}
        </div>
      }
      interactive
      classes={{
        tooltip: classes.content,
      }}
    >
      <span className={classes.label}>[{props.number}]</span>
    </Tooltip>
  );
};

Footnote.propTypes = {
  number: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  href: PropTypes.string,
};

export default Footnote;
