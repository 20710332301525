import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Select as MuiSelect,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  component: {
    "& .MuiInputLabel-root": {
      color: theme.controls.select.label.primary,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `${theme.controls.select.borderWidth}px solid ${theme.controls.select.primary}`,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.controls.select.focused.primary,
      borderWidth: theme.controls.select.focused.borderWidth,
    },
    "& .MuiSelect-icon": {
      fontSize: "1.5rem",
    },
    "& .MuiSelect-select": {
      backgroundColor: theme.controls.select.background,
      fontSize: "0.875rem",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.controls.select.secondary,
    },
    "&:hover .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.controls.select.focused.secondary,
    },
  },
}));

const Select = ({ id, label, ...rest }) => {
  const labelRef = useRef();
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(labelRef.current.offsetWidth);
  }, []);

  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      component="div"
      className={classes.component}
    >
      {label && (
        <InputLabel ref={labelRef} htmlFor={id} shrink>
          {label}
        </InputLabel>
      )}
      <MuiSelect
        {...rest}
        native
        input={<OutlinedInput id={id} margin="dense" labelWidth={labelWidth} />}
        onChange={(e) => {
          rest.onChange?.(e.target.value);
        }}
      />
    </FormControl>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default Select;
