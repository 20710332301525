import { Link, Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const AbortionDataSources = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Data Sources</Typography>

      <p>
        Data for this analysis is mostly at the regional level. We used the
        following primary data sources in the development of our estimates:
      </p>

      <ul>
        <li>
          The{" "}
          <Link
            underline="always"
            onClick={handleReadersGuideOpen(
              "maternal-health-pregnancies-and-births"
            )}
          >
            Maternal Health
          </Link>{" "}
          section of LEAP for numbers of abortions and miscarriages over time by
          country;
        </li>
        <li>
          <ExternalLink
            href="https://www.guttmacher.org/adding-it-up"
            text="Guttmacher Institute 2019 Adding It Up Report"
          />{" "}
          and accompanying methodology to inform the estimates of abortion
          safety profile by country group
          <Footnote number={9}>
            Estimates in Adding it Up are aligned to Ganatra et al 2017{" "}
            <ExternalLink
              href="https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(17)31794-4/fulltext"
              text="Global, regional, and subregional classification of abortions by safety, 2010–14: estimates from a Bayesian hierarchical model"
              useItalics={true}
            />{" "}
            Lancet 2017 Nov 25;390(10110):2372-2381.
          </Footnote>
          , and use of surgical and medical methods within each income group;
          and
        </li>
        <li>
          <ExternalLink
            href="https://www.medab.org/our-research#cost-of-medical-abortion-products"
            text="IPPF's Medical Abortion Commodities Database"
          />{" "}
          as the primary source of medical abortion cost data.
        </li>
      </ul>

      <p>
        We reviewed assumptions and data sources with the RHSC Safe Abortion
        Supplies Workstream in early 2021. An online survey was also shared with
        Workstream members in early 2021 to gain additional inputs into
        assumptions and scenarios; 11 individuals completed the survey.
      </p>
    </div>
  );
};

export default AbortionDataSources;
