import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import Information from "../../Common/Box/Information";

const FpB3 = () => (
  <Information
    key="FP_b3"
    number="3"
    title="Interpretation of Country-Level Income Group Patterns"
  >
    <p>
      Patterns across income groups should not be interpreted as inevitable
      pathways that we expect countries to follow as their income levels
      increase. First, patterns of contraceptive use and method mix are
      determined based on a range of contextual factors in each country,
      influenced by policy decisions, system infrastructure, and individual
      decisions. Second, in some instances just one or two countries dominate
      the pattern seen in an income group, further exacerbating this issue.
    </p>
    <p>
      For example, one country, Brazil, accounts for more than a third of costs
      in upper-middle-income countries. Most users in Brazil rely on
      short-acting methods obtained from the private sector. Recent studies have
      suggested that limited availability of IUDs and implants in Brazil&apos;s
      public, and even the private sector, contribute to this method skew
      <Footnote number={10}>
        Bahamondes L, Fernandes A, Monteiro I. 2017{" "}
        <ExternalLink
          href="https://dx.doi.org/10.1055/s-0037-1604423"
          text="Barriers to Implementing and Consolidating a Family Planning Program that would meet Brazilian Needs"
          useItalics={true}
        />{" "}
        Revista Brasileira de Ginecologia e Obstetrícia, 39(8), 373–375.
      </Footnote>
      . The predominance of private out-of-pocket spending in
      upper-middle-income countries should therefore not be seen as inevitable,
      but as the result of policy choices made in countries like Brazil. Indeed,
      this high reliance on private out-of-pocket expenditures provides a
      cautionary tale; as countries transition away from donor-funded supplies,
      mechanisms must be put into place to ensure affordable access to a wide
      range of contraceptives.
    </p>
  </Information>
);

export default FpB3;
