import { Link, Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import Information from "../../Common/Box/Information";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const Component = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <Information key="APAC_methods" number="2" title="Abortion and PAC Methods">
      <p>
        As described in Box 1, abortion is used to refer to services to
        intentionally end a pregnancy, including any abortions that are
        incomplete, while post abortion care (PAC) is used to refer to services
        to complete an incomplete abortion or miscarriage.
      </p>

      <Typography variant="h6">Method Description</Typography>

      <p>
        Safe and less safe abortion services, and PAC services, can be provided
        using either:
      </p>

      <ul>
        <li>
          <strong>Surgical methods</strong>&mdash;either vacuum aspiration or
          dilation methods;
        </li>
        <li>
          <strong>Medical methods</strong>&mdash;misoprostol with and without
          mifepristone.
        </li>
      </ul>

      <p>
        For abortions using medical methods, combined use of misoprostol and
        mifepristone is more effective than misoprostol alone
        <Footnote number={13}>
          Kulier R, Kapp N, Gülmezoglu AM, Hofmeyr GJ, Cheng L, Campana A{" "}
          <ExternalLink
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7144729/"
            text="Medical methods for first trimester abortion"
            useItalics={true}
          />{" "}
          Cochrane Database Syst Rev . 2011 Nov 9; 2011(11):CD002855.
        </Footnote>
        <Footnote number={14}>
          Lerma K, Blumenthal PD{" "}
          <ExternalLink
            href="https://www.sciencedirect.com/science/article/pii/S1521693419300495"
            text="Current and potential methods for second trimester abortion"
            useItalics={true}
          />{" "}
          Best Practice & Research Clinical Obstetrics and Gynaecology 63 (2020)
          24-36.
        </Footnote>
        . Misoprostol and mifepristone may be packaged together (known as a
        combipack) or supplied separately; a different number of misoprostol
        pills are required at different pregnancy gestations (see the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-quantities")}
        >
          Reader&apos;s Guide
        </Link>
        ). For PAC services using medical methods, only misoprostol is used.
      </p>

      <Typography variant="h6">Costing Supplies within LEAP</Typography>

      <p>
        For the purpose of LEAP, we have focused on the costing the supplies
        used for the following methods:
      </p>

      <ul>
        <li>
          <strong>
            Surgical abortion and PAC services provided via vacuum aspiration
          </strong>
          : manual vacuum aspiration (MVA) or electric vacuum aspiration (EVA);
        </li>
        <li>
          <strong>Medical abortion services</strong> using misoprostol with and
          without mifepristone; and
        </li>
        <li>
          <strong>PAC services</strong> provided using misoprostol.
        </li>
      </ul>

      <p>We have not costed the following services:</p>

      <ul>
        <li>
          <strong>Least safe abortion services</strong>, as they as they are
          typically performed by unregulated providers and/or using products and
          techniques that are not considered safe or appropriate reproductive
          health supplies (e.g. toxic substances, sharp objects); and
        </li>
        <li>
          Surgical abortion services provided using{" "}
          <strong>dilation methods</strong> (dilation and curettage (D&amp;C)
          and dilation and evacuation (D&amp;E)). We have also assumed that no
          PAC services are provided using dilation methods. Dilation methods
          have not been costed as there are limited supply costs (equipment used
          is not specific to provision of abortion and PAC services).
        </li>
      </ul>

      <p>
        Note that any emergency obstetrics care required due to severe
        complications from a PAC service are not included in the analysis.
      </p>
    </Information>
  );
};

export default Component;
