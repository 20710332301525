import { makeStyles } from "@material-ui/styles";
import Table from "../../Common/Box/Table";
import initialData from "../../../data/initialData.json";
import useCsvDownload from "../../../hooks/useCsvDownload";
import { formatNumber } from "../../../utilities";

const number = 2;

const format = (n) =>
  formatNumber(n, {
    prefix: "$",
    decimalPlaces: 2,
  });

const useStyles = makeStyles((theme) => ({
  groupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& th:not(:first-child)": {
      width: "6rem",
    },
  },
}));

const Component = () => {
  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleCsvDownload = () => {
    downloadCsv("/mhm/tables/2/csv", `mhm_t${number}.csv`);
  };

  const data = initialData["mhm_t2"];

  return (
    <Table
      id="MHM_t2"
      number={number.toString()}
      title="Prices for Purpose-Made Menstrual Hygiene Products"
      subtitle="Unit Price for a Single Product by Region"
      onDownloadCsv={handleCsvDownload}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th colSpan={2} className={classes.groupBoundary}>
              Disposable
            </th>
            <th colSpan={2} className={classes.groupBoundary}>
              Reusable
            </th>
          </tr>
          <tr>
            <th />
            <th className={classes.groupBoundary}>Pads</th>
            <th>Tampons</th>
            <th className={classes.groupBoundary}>Pads</th>
            <th>Cups</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.region}>
              <td>{item.region}</td>
              <td className={classes.groupBoundary}>{format(item.pads)}</td>
              <td>{format(item.tampons)}</td>
              {index === 0 ? (
                <>
                  <td rowSpan={data.length} className={classes.groupBoundary}>
                    {format(item.reusablePads)}
                  </td>
                  <td rowSpan={data.length}>{format(item.cups)}</td>
                </>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    </Table>
  );
};

export default Component;
