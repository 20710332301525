import { makeStyles } from "@material-ui/styles";
import Table from "../../Common/Box/Table";
import initialData from "../../../data/initialData.json";
import useCsvDownload from "../../../hooks/useCsvDownload";
import { formatNumber } from "../../../utilities";

const number = 2;

const format = (n) =>
  formatNumber(n, {
    significantDigits: 3,
  });

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& th:not(:first-child)": {
      width: "8rem",
    },
  },
  total: {
    backgroundColor: theme.palette.area.background,
  },
}));

const Component = () => {
  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleCsvDownload = () => {
    downloadCsv("/apac/tables/2/csv", `apac_t${number}.csv`);
  };

  const data = initialData["apac_t2"];

  return (
    <Table
      key="APAC_t2"
      number={number.toString()}
      title="Abortion and PAC Services by Method"
      subtitle="By Income Group, 2019"
      onDownloadCsv={handleCsvDownload}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th className={classes.total}>Total</th>
            <th colSpan={2}>Costed</th>
            <th colSpan={2}>Not costed</th>
          </tr>
          <tr>
            <th />
            <th className={classes.total}>Total</th>
            <th>
              Surgical:
              <br />
              Vacuum
              <br />
              aspiration
            </th>
            <th>
              Medical:
              <br />
              All
            </th>
            <th>
              Surgical:
              <br />
              Dilation
            </th>
            <th>
              Least safe
              <br />
              abortions
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.incomeGroup}>
              <td>{item.incomeGroup}</td>
              <td className={classes.total}>{format(item.total)}</td>
              <td>{format(item.mva)}</td>
              <td>{format(item.medical)}</td>
              <td>{format(item.dilation)}</td>
              <td>{format(item.leastSafeAbortions)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Table>
  );
};

export default Component;
