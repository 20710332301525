import { useState } from "react";
import PropTypes from "prop-types";
import SideBySide from "../../../Common/SideBySide";
import ApacCpF3 from "../Figures/ApacCpF3";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatNumberAsHumanReadable } from "../../../../utilities";

const calculateTotal = (scenario) =>
  scenario
    ? scenario.incomeGroups.reduce(
        (accumulator, { value }) => accumulator + value,
        0
      )
    : 0;

const Quantities = (props) => (
  <ul>
    <li>
      If current levels of use are maintained (Scenario 1), there would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data?.[0]))} units of{" "}
      {props.productName.toLowerCase()} used in 2030 across all low- and
      middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[0].value ?? 0)}{" "}
      in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[1].value ?? 0)}{" "}
      in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[2].value ?? 0)}{" "}
      in upper-middle-income countries.
    </li>
    <li>
      With an increase in safety but no change in the mix of methods being used
      (Scenario 2) there would be a{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data?.[1]))} units of{" "}
      {props.productName.toLowerCase()} used in 2030 across all low- and
      middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[0].value ?? 0)}{" "}
      in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[1].value ?? 0)}{" "}
      in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[2].value ?? 0)}{" "}
      in upper-middle-income countries.
    </li>
    <li>
      With an increase in safety as well as an increase in the use of medical
      services (particularly those using mifepristone), there would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data?.[2]))} units of{" "}
      {props.productName.toLowerCase()} used in 2030 across all low- and
      middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data?.[2].incomeGroups[0].value ?? 0)}{" "}
      in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data?.[2].incomeGroups[1].value ?? 0)}{" "}
      in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data?.[2].incomeGroups[2].value ?? 0)}{" "}
      in upper-middle-income countries.
    </li>
  </ul>
);

const Costs = (props) => (
  <ul>
    <li>
      If current levels of use are maintained (Scenario 1), the total cost of{" "}
      {props.productName.toLowerCase()} would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data?.[0]), {
        prefix: "$",
      })}{" "}
      in 2030 across all low- and middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[0].value ?? 0, {
        prefix: "$",
      })}{" "}
      in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[1].value ?? 0, {
        prefix: "$",
      })}{" "}
      in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data?.[0].incomeGroups[2].value ?? 0, {
        prefix: "$",
      })}{" "}
      in upper-middle-income countries.
    </li>
    <li>
      With an increase in safety but no change in the mix of methods being used
      (Scenario 2) the total cost of {props.productName.toLowerCase()} would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data?.[1]), {
        prefix: "$",
      })}{" "}
      in 2030 across all low- and middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[0].value ?? 0, {
        prefix: "$",
      })}{" "}
      in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[1].value ?? 0, {
        prefix: "$",
      })}{" "}
      in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data?.[1].incomeGroups[2].value ?? 0, {
        prefix: "$",
      })}{" "}
      in upper-middle-income countries.
    </li>
    <li>
      With an increase in safety as well as an increase in the use of medical
      services (particularly those using mifepristone), the total cost of{" "}
      {props.productName.toLowerCase()} would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data?.[2]), {
        prefix: "$",
      })}{" "}
      in 2030 across all low- and middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data?.[2].incomeGroups[0].value ?? 0, {
        prefix: "$",
      })}{" "}
      in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data?.[2].incomeGroups[1].value ?? 0, {
        prefix: "$",
      })}{" "}
      in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data?.[2].incomeGroups[2].value ?? 0, {
        prefix: "$",
      })}{" "}
      in upper-middle-income countries.
    </li>
  </ul>
);

const ApacCpS3 = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(3);

  const { areaData: data } = useAreaQuery(
    `/custom/apac/cp/figures/3/${props.productId}/${selectedIndicatorId}`
  );

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  return (
    <SideBySide
      component={
        <ApacCpF3
          productName={props.productName}
          indicatorId={selectedIndicatorId}
          data={data}
          onIndicatorChange={handleIndicatorChange}
        />
      }
      componentPosition="right"
    >
      {selectedIndicatorId === 3 ? (
        <Quantities productName={props.productName} data={data} />
      ) : (
        <Costs productName={props.productName} data={data} />
      )}

      <p>Use the dropdown to explore other indicators.</p>
    </SideBySide>
  );
};

ApacCpS3.propTypes = {
  productId: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
};

export default ApacCpS3;
