import { useState } from "react";
import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import { capitalizePhrase, getTooltip } from "../../../../utilities";
import useAreaQuery from "../../../../hooks/useAreaQuery";

const number = 1;

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = [
    {
      name: "Purpose-made",
      data: data.map((country) => country.methods[0].menstruators),
      color: theme.palette.areas.general.primary,
    },
    {
      name: "Non-purpose-made",
      data: data.map((country) => country.methods[1].menstruators),
      color: theme.palette.areas.general.secondary,
    },
  ];

  return {
    chart: {
      type: "bar",
      width: 1000,
      height: 800,
      backgroundColor: null,
      marginBottom: 70,
    },
    xAxis: {
      categories: data.map((country) => country.name),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      opposite: true,
      reversedStacks: false,
      labels: {
        format: absolute ? undefined : "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      bar: {
        stacking: absolute ? "normal" : "percent",
        borderWidth: 0,
        groupPadding: 0.1,
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      reversed: false,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Menstruators", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const MhmCcF1 = (props) => {
  const [comparisonMode, setComparisonMode] = useState("absolute");

  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cc/figures/1/${props.regionId}`
  );

  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  return (
    <Figure
      id="MHM_CC_f1"
      number={number.toString()}
      title="Use of Menstrual Hygiene Products"
      subtitle={`${capitalizePhrase(props.regionName)} by Country, 2019`}
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        />
      }
      chartOptions={
        data &&
        options(data, {
          comparisonMode,
        })
      }
    />
  );
};

MhmCcF1.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCcF1;
