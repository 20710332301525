import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import Information from "../../Common/Box/Information";

const FpB5 = () => (
  <Information
    key="FP_b5"
    number="5"
    title="Future Increases in Use of Implants"
  >
    Recent survey data suggest increases in implant use, with particularly large
    gains in sub-Saharan Africa. This growth has been credited, in part, to cost
    reductions and increased donor procurement
    <Footnote number={11}>
      Jacobstein R{" "}
      <ExternalLink
        href="https://www.ghspjournal.org/content/6/1/17"
        text="Liftoff: The Blossoming of Contraceptive Implant Use in Africa"
        useItalics={true}
      />{" "}
      Global Health: Science and Practice Mar 2018, 6 (1) 17-39.
    </Footnote>
    <Footnote number={12}>
      Braun R, Grever A{" "}
      <ExternalLink
        href="https://www.ghspjournal.org/content/8/2/205"
        text="Scaling Up Access to Implants: A Summative Evaluation of the Implants Access Program"
        useItalics={true}
      />{" "}
      Global Health: Science and Practice Jun 2020, 8 (2) 205-219.
    </Footnote>
    . Our projections assume this growth will carry on into the future, though
    supply- and demand- side constraints and overall reductions in donor funding
    could slow this growth trajectory. And in a post COVID-19 world, we may see
    preferences shift away from clinical-based methods towards more
    user-controlled self-care methods
    <Footnote number={13}>
      Weinberger M, Hayes B, White J, Skibiak J{" "}
      <ExternalLink
        href="https://www.ghspjournal.org/content/8/2/169"
        text="Doing Things Differently: What It Would Take to Ensure Continued Access to Contraception During COVID-19"
        useItalics={true}
      />{" "}
      Global Health: Science and Practice Jun 2020, 8 (2) 169-175.
    </Footnote>
    <Footnote number={14}>
      Moon P, Christofield M, Lathrop E, Lu R{" "}
      <ExternalLink
        href="https://knowledgesuccess.org/2020/03/30/acceleration-of-self-care-in-the-time-of-covid-19/"
        text="Acceleration of Self-Care in the Time of COVID-19"
        useItalics={true}
      />{" "}
      March 30, 2020.
    </Footnote>
    .
  </Information>
);

export default FpB5;
