import PropTypes from "prop-types";
import MhCsF10 from "../Figures/MhCsF10";

const MhCsS13 = (props) => (
  <>
    <p>
      Figure 10 shows the change in cost for each of the seven priority maternal
      health drugs and the two emerging drugs between 2019 and 2030. In absolute
      terms, the cost of some drugs is expected to remain fairly low, either
      because they are inexpensive or because cases receiving the drug(s) would
      remain low, even with increased coverage. While for other drugs that have
      higher case numbers, larger increases in cases or higher unit prices, cost
      increases would be larger.
    </p>

    <MhCsF10 regionId={props.regionId} regionName={props.regionName} />
  </>
);

MhCsS13.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsS13;
