import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
    },
    "& td:last-child": {
      textAlign: "right",
    },
    "& th": {
      padding: theme.table.cellPadding,
    },
  },
}));

const RgMhT4 = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6">Table 4. Drug Costs</Typography>

      <table className={classes.table}>
        <thead>
          <tr>
            <th>Drug</th>
            <th>Formulation</th>
            <th>Unit Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Iron and folic acid (IFA)</td>
            <td>Ferrous Salt + Folic Acid, tablet, 60 + 0.4 mg</td>
            <td>$0.0031</td>
          </tr>
          <tr>
            <td>Methyldopa</td>
            <td>250 mg tablet</td>
            <td>$0.0387</td>
          </tr>
          <tr>
            <td>Hydralazine</td>
            <td>20mg amp</td>
            <td>$3.9560</td>
          </tr>
          <tr>
            <td>Magnesium sulfate</td>
            <td>5g/10mL ampule</td>
            <td>$0.4780</td>
          </tr>
          <tr>
            <td>Oxytocin</td>
            <td>10 IU 1ml amp</td>
            <td>$0.2270</td>
          </tr>
          <tr>
            <td>Misoprostol</td>
            <td>25, 200mcg tablet</td>
            <td>$0.1350</td>
          </tr>
          <tr>
            <td>Metronidazole</td>
            <td>500mg/100ml vial</td>
            <td>$0.5750</td>
          </tr>
          <tr>
            <td>Heat-stable carbetocin (HSC)</td>
            <td>100 &mu;g</td>
            <td>$0.3300</td>
          </tr>
          <tr>
            <td>Tranexamic acid (TXA)</td>
            <td>10 Ml ampoule</td>
            <td>$1.3000</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RgMhT4;
