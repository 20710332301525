import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Table from "../../../Common/Box/Table";
import { formatNumber, formatPercentage } from "../../../../utilities";

const key = "MH_CC_t3";
const number = 3;

const format = (n) =>
  formatNumber(n, {
    significantDigits: 3,
  });

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child td:not(:first-child)": {
      minWidth: "6rem",
    },
  },
}));

const MhCcT3 = (props) => {
  const classes = useStyles();

  return (
    <Table
      key={key}
      number={number.toString()}
      title="Change in Total Births"
      subtitle={`By ${props.regionalUnitName}, 2019-2030`}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th colSpan={3}>Total Births</th>
            <th colSpan={2} className={classes.changeGroupBoundary}>
              Change (2019 to 2030)
            </th>
          </tr>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th>2019</th>
            <th>2025</th>
            <th>2030</th>
            <th className={classes.changeGroupBoundary}>#</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {props.data &&
            props.data.map((countryGroup) => {
              return (
                <tr key={countryGroup.name}>
                  <td className={classes.nameGroupBoundary}>
                    {countryGroup.name}
                  </td>

                  {countryGroup.years.map((year) => (
                    <td key={year.year}>{format(year.births)}</td>
                  ))}

                  <td className={classes.changeGroupBoundary}>
                    {format(countryGroup.change)}
                  </td>
                  <td>{formatPercentage(countryGroup.percentageChange)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Table>
  );
};

MhCcT3.propTypes = {
  regionalUnitName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      years: PropTypes.arrayOf(
        PropTypes.shape({
          year: PropTypes.number.isRequired,
          births: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ),
  onScenarioChange: PropTypes.func,
};

export default MhCcT3;
