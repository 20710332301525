import { useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CustomReport from "../CustomReport";
import SupplyChooser from "../SupplyChooser";
import { toTitleCase } from "../../../utilities";
import MhmCpB1 from "./Boxes/MhmCpB1";
import MenstrualHygieneIntroduction from "./MenstrualHygieneIntroduction";
import MenstrualHygieneScenarios from "./MenstrualHygieneScenarios";
import MhmCpCurrentIncomeGroups from "./Summaries/MhmCpCurrentIncomeGroups";
import MhmCpCurrentTopCountries from "./Summaries/MhmCpCurrentTopCountries";
import MhmCpFutureIncomeGroups from "./Summaries/MhmCpFutureIncomeGroups";
import MhmCpF3 from "./Figures/MhmCpF3";
import MhmCpF5 from "./Figures/MhmCpF5";

const useStyles = makeStyles((theme) => ({
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
  choosers: {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(2),
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  datasetDownload: {
    marginTop: theme.spacing(2),
  },
}));

const MenstrualHygieneDeepDive = () => {
  const [selectedMethodId, setSelectedMethodId] = useState(14);
  const [selectedMethodName, setSelectedMethodName] = useState("Disposables");

  const classes = useStyles();

  const handleMethodChange = (method) => {
    setSelectedMethodId(method.id);
    setSelectedMethodName(method.name);
  };

  return (
    <CustomReport>
      <Typography variant="h1">
        Menstrual Hygiene Product Deep Dive into{" "}
        {toTitleCase(selectedMethodName)}
      </Typography>

      <div>
        <SupplyChooser
          area="menstrual-hygiene"
          value={selectedMethodId}
          onChange={handleMethodChange}
        />
      </div>

      <section id="introduction">
        <Typography variant="h2">Background</Typography>

        <div className={classes.boxes}>
          <MenstrualHygieneIntroduction reportName="Menstrual Hygiene Product Deep Dive">
            The <strong>Menstrual Hygiene Product Deep Dive</strong> provides
            estimates on the number of menstruators, purpose-made menstrual
            hygiene commodities, and related costs for the selected product.
          </MenstrualHygieneIntroduction>

          <MhmCpB1 />
        </div>
      </section>

      <section id="current">
        <Typography variant="h2">Where We Are Now</Typography>

        <div className={classes.boxes}>
          <MhmCpCurrentIncomeGroups
            methodId={selectedMethodId}
            methodName={selectedMethodName}
          />

          <MhmCpCurrentTopCountries
            methodId={selectedMethodId}
            methodName={selectedMethodName}
          />

          <MhmCpF3
            methodId={selectedMethodId}
            methodName={selectedMethodName}
          />
        </div>
      </section>

      <section id="future">
        <Typography variant="h2">Changes Ahead</Typography>

        <div className={classes.boxes}>
          <MenstrualHygieneScenarios />

          <MhmCpFutureIncomeGroups
            methodId={selectedMethodId}
            methodName={selectedMethodName}
          />

          <MhmCpF5
            methodId={selectedMethodId}
            methodName={selectedMethodName}
          />
        </div>
      </section>
    </CustomReport>
  );
};

export default MenstrualHygieneDeepDive;
