import PropTypes from "prop-types";
import ApacCsF4 from "../Figures/ApacCsF4";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatPercentage, toLowerCase } from "../../../../utilities";

const calculateMetrics = (data) => {
  if (!data) {
    return {
      leastSafeAbortionShareUnderScenario1: 0,
      leastSafeAbortionShareUnderScenario2: 0,
      medicalMethodShareUnderScenario1: 0,
      medicalMethodShareUnderScenario2: 0,
      medicalMethodShareUnderScenario3: 0,
      misoMifeMedicalShareUnderScenario2: 0,
      misoMifeMedicalShareUnderScenario3: 0,
    };
  }

  const totalServicesUnderScenario1 = data.reduce(
    (accumulator, { scenarios }) => accumulator + scenarios[0].services,
    0
  );

  const totalServicesUnderScenario2 = data.reduce(
    (accumulator, { scenarios }) => accumulator + scenarios[1].services,
    0
  );

  const totalServicesUnderScenario3 = data.reduce(
    (accumulator, { scenarios }) => accumulator + scenarios[2].services,
    0
  );

  return {
    leastSafeAbortionShareUnderScenario1:
      (data[4].scenarios[0].services / totalServicesUnderScenario1) * 100,
    leastSafeAbortionShareUnderScenario2:
      (data[4].scenarios[1].services / totalServicesUnderScenario2) * 100,
    medicalMethodShareUnderScenario1:
      ((data[1].scenarios[0].services + data[2].scenarios[0].services) /
        totalServicesUnderScenario1) *
      100,
    medicalMethodShareUnderScenario2:
      ((data[1].scenarios[1].services + data[2].scenarios[1].services) /
        totalServicesUnderScenario2) *
      100,
    medicalMethodShareUnderScenario3:
      ((data[1].scenarios[2].services + data[2].scenarios[2].services) /
        totalServicesUnderScenario3) *
      100,
    misoMifeMedicalShareUnderScenario2:
      (data[2].scenarios[1].services /
        (data[1].scenarios[1].services + data[2].scenarios[1].services)) *
      100,
    misoMifeMedicalShareUnderScenario3:
      (data[2].scenarios[2].services /
        (data[1].scenarios[2].services + data[2].scenarios[2].services)) *
      100,
  };
};

const ApacCsS5 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/apac/cs/figures/4/${props.regionId}`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        The profile of services would change with a shift in the safety profile
        of services (Scenario 2), and if this shift in safety profile is
        combined with increased use of medical methods (Scenario 3).
      </p>

      <p>
        A shift in the safety profile (Scenarios 2 &amp; 3) would result in a
        least safe abortions accounting for around{" "}
        {formatPercentage(metrics.leastSafeAbortionShareUnderScenario2)} of
        services in {toLowerCase(props.regionName)} in 2030, as compared to{" "}
        {formatPercentage(metrics.leastSafeAbortionShareUnderScenario1)} if the
        safety profile and method mix were maintained (scenario 1).
      </p>

      <p>
        The proportion of abortion and PAC services using medical methods would
        be {formatPercentage(metrics.medicalMethodShareUnderScenario1)} if the
        safety profile and method mix were maintained (Scenario 1),{" "}
        {formatPercentage(metrics.medicalMethodShareUnderScenario2)} with a
        shift in safety profile (Scenario 2) and{" "}
        {formatPercentage(metrics.medicalMethodShareUnderScenario3)} if the
        shift in safety profile was layered with a change in method mix
        (scenario 3)
      </p>

      <p>
        The proportion of medical services that use misoprostol and mifepristone
        combined would also shift. Under Scenario 2,{" "}
        {formatPercentage(metrics.misoMifeMedicalShareUnderScenario2)} of
        medical services in 2030 would use the combined regime compared to{" "}
        {formatPercentage(metrics.misoMifeMedicalShareUnderScenario3)} of
        medical services under Scenario 3.
      </p>

      <ApacCsF4
        regionId={props.regionId}
        regionName={props.regionName}
        data={data}
      />
    </>
  );
};

ApacCsS5.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default ApacCsS5;
