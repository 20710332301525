import { Link, Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const MenstrualHygieneDataSources = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Data Sources</Typography>

      <p>
        There are currently no standardized, global metrics for measuring
        menstrual hygiene product use, so we drew from multiple data sources,
        and combined different variables, to develop overall profiles of
        menstrual hygiene product use.
      </p>
      <p>
        The following were the primary data sources used in the development of
        our estimates for menstrual hygiene products:
      </p>

      <ul>
        <li>
          <ExternalLink
            href="https://population.un.org/wpp/"
            text="UNPD Population Prospect (WPP) 2019"
          />{" "}
          data to input annual number of women ages 12-49;
        </li>
        <li>
          Users by method from the{" "}
          <Link
            underline="always"
            onClick={handleReadersGuideOpen("contraception-users-by-method")}
          >
            Contraceptive section
          </Link>{" "}
          of LEAP to determine users of hormonal contraception with reduced
          menstruation;
        </li>
        <li>
          Numbers of pregnant women and births developed for the{" "}
          <Link
            underline="always"
            onClick={handleReadersGuideOpen(
              "maternal-health-pregnancies-and-births"
            )}
          >
            Maternal Health section
          </Link>{" "}
          of LEAP to determine how many women were not menstruating due to being
          pregnant or postpartum amenorrheic;
        </li>
        <li>
          <ExternalLink
            href="https://population.un.org/wup/"
            text="UNPD World Urbanization Prospects 2018"
          />{" "}
          data to input annual percent of population estimated to live in urban
          areas;
        </li>
        <li>
          <ExternalLink
            href="http://mics.unicef.org/surveys"
            text="Multiple Indicator Cluster Survey (MICS)"
          />{" "}
          data from 33 countries to determine the use of purpose-made menstrual
          hygiene products;
        </li>
        <li>
          <ExternalLink
            href="https://www.pmadata.org/"
            text="Performance Monitoring for Accountability (PMA) 2020"
          />{" "}
          data from seven countries to determine the use of purpose-made
          menstrual hygiene products;
        </li>
        <li>
          <ExternalLink
            href="https://dhsprogram.com/data/available-datasets.cfm"
            text="Demographic Health Survey (DHS)"
          />{" "}
          data from India (NHSF-4) to determine the use of purpose-made
          menstrual hygiene products in India, and;
        </li>
        <li>
          <ExternalLink
            href="https://www.marketsandmarkets.com/Market-Reports/feminine-hygiene-product-market-69114569.html"
            text="MarketsandMarkets"
          />{" "}
          Feminine Hygiene Products Market data provided information on unit
          prices and the breakdown of disposable menstrual hygiene product use.
        </li>
      </ul>

      <p>
        While survey data on menstrual hygiene product use is limited, by
        pooling data sources we were able to develop country specific estimates
        for 42 countries. As more surveys include questions about menstrual
        health and measures are standardized across surveys, future versions of
        LEAP will be able to include more country specific estimates.
      </p>
      <p>
        Assumptions and data sources were reviewed with the RHSC Menstrual
        Health Supplies Workstream in early 2021 . An online survey was also
        shared with Workstream members in early 2021 to gain additional inputs
        into assumptions and scenarios; four individuals completed the survey.
      </p>
    </div>
  );
};

export default MenstrualHygieneDataSources;
