import { Link, Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const ContraceptionTotalUsers = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Total Users</Typography>

      <p>
        The total number of modern method users was aligned to the median
        variant projection from the UN Population Division (UNPD)
        <Footnote number={3}>
          United Nations, Department of Economic and Social Affairs, Population
          Division (2020){" "}
          <ExternalLink
            href="https://www.un.org/en/development/desa/population/theme/family-planning/cp_model.asp"
            text="Estimates and Projections of Family Planning Indicators 2020"
            useItalics={true}
          />{" "}
          New York: United Nations.
        </Footnote>
        . These estimates and projections were created using a statistical model
        which used all available survey data from Demographic and Health Surveys
        (DHS), Multiple Indicator Cluster surveys (MICS), PMA2020, and national
        surveys
        <Footnote number={4}>
          United Nations, Department of Economic and Social Affairs, Population
          Division (2018){" "}
          <ExternalLink
            href="https://www.un.org/en/development/desa/population/theme/family-planning/cp_model.asp"
            text="World Contraceptive Use 2020"
            useItalics={true}
          />{" "}
          New York: United Nations.
        </Footnote>
        . While total modern users are presented together in the results, they
        were separated into married modern users and unmarried modern users for
        the purposes of calculations (see more under{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-users-by-method")}
        >
          Users by Method
        </Link>{" "}
        below).
      </p>
    </div>
  );
};

export default ContraceptionTotalUsers;
