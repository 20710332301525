import PropTypes from "prop-types";
import MhCsF7 from "../Figures/MhCsF7";

const MhCsS10 = (props) => (
  <>
    <p>
      In 2030, the total number of cases that need each drug or group of drugs
      will be the same in all three scenarios, however, the share of cases that
      receive the drug(s) will vary based on the coverage of interventions under
      each scenario. Figure 7 shows the share of cases that need and receive
      versus need but don't receive each drug. As above, we present results
      based on the intervention or group of interventions using one of the seven
      priority health drugs, plus the two emerging drugs, and drugs that are
      used for the same/similar interventions are presented together. Use the
      dropdown below the figure to see results for other drug(s).
    </p>

    <MhCsF7 regionId={props.regionId} regionName={props.regionName} />
  </>
);

MhCsS10.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsS10;
