import PropTypes from "prop-types";
import ExternalLink from "../../../Common/ExternalLink";
import Footnote from "../../../Common/Footnote";
import MhCcF4 from "../Figures/MhCcF4";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatPercentage } from "../../../../utilities";

const calculateMetrics = (data) => {
  const shares = data?.map(({ share }) => share);

  return {
    minimumShare: shares ? Math.min(...shares) : 0,
    maximumShare: shares ? Math.max(...shares) : 0,
  };
};

const MhCcS5 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cc/figures/1/${props.regionalUnitId}/101`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        Misoprostol and oxytocin (both uterotonics) can be used for induction of
        labor and to prevent and treat PPH, with oxytocin additionally used for
        augmentation. Our estimates are based on a global assumption that 8% of
        women need induction, 20% need augmentation, all births need PPH
        prevention, and 11% need PPH treatment
        <Footnote number={6}>
          JSI and SIAPS. 2015 {" "}
          <ExternalLink
            href="https://marketbookshelf.com/publications/quantification-health-commodities-forecasting-consumption-select-reproductive-maternal-newborn-child-health-commodities/"
            text="Quantification of Health Commodities: RMNCH Supplement Forecasting Consumption of Select Reproductive, Maternal, Newborn and Child Health Commodities"
            useItalics={true}
          />{" "}
          Submitted to the US Agency for International Development by the
          Systems for Improved Access to Pharmaceuticals and Services (SIAPS)
          Program. Arlington, VA: Management Sciences for Health. Submitted to
          the United Nations Children's Fund by JSI, Arlington, VA: JSI Research
          & Training Institute, Inc.
        </Footnote>
        <Footnote number={7}>
          Calvert C, Thomas SL, Ronsmans C, Wagner KS, Adler AJ, Filippi V {" "}
          <ExternalLink
            href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0041114"
            text="Identifying regional variation in the prevalence of postpartum hemorrhage: a systematic review and meta-analysis"
            useItalics={true}
          />{" "}
          PLoS One. 2012;7(7):e41114.
        </Footnote>
        . In Figure 4, we present cases requiring these interventions,
        separating each into cases where uterotonics were provided and those
        where they were not.
      </p>

      <p>
        Across all low- and middle-income countries, uterotonics are provided in
        44% of cases. This ranges from {formatPercentage(metrics.minimumShare)}{" "}
        to {formatPercentage(metrics.maximumShare)} across{" "}
        {props.regionalUnitName}.
      </p>

      <MhCcF4 regionalUnitName={props.regionalUnitName} data={data} />
    </>
  );
};

MhCcS5.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcS5;
