import PropTypes from "prop-types";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import MhCsT1 from "../Tables/MhCsT1";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeComparison,
  toLowerCase,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  const pregnancyCount = data?.[0].indicators[0].value ?? 0;
  const abortionMiscarriageCount = data?.[0].indicators[1].value ?? 0;

  return {
    pregnancyCount: data?.[0].indicators[0].value ?? 0,
    pregnancyPercentage: data?.slice(-1)[0].indicators[0].value ?? 0,
    abortionMiscarriagePercentage:
      abortionMiscarriageCount > 0
        ? (abortionMiscarriageCount / pregnancyCount) * 100
        : 0,
  };
};

const MhCsS1 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/tables/1/${props.regionId}`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        In 2019 there were an estimated{" "}
        {formatNumberAsHumanReadable(metrics.pregnancyCount)} pregnancies in{" "}
        {toLowerCase(props.regionName)}; this accounts for{" "}
        {formatPercentage(metrics.pregnancyPercentage)} of pregnancies across
        all low- and middle-income countries.
      </p>

      <p>
        In {toLowerCase(props.regionName)},{" "}
        {formatPercentage(metrics.abortionMiscarriagePercentage)} of all
        pregnancies end in an abortion or miscarriage. This is{" "}
        {getQualitativeComparison(
          metrics.abortionMiscarriagePercentage,
          39,
          3.5
        )}{" "}
        the average across all low- and middle-income countries (39%).
      </p>

      <MhCsT1 regionName={props.regionName} data={data} />
    </>
  );
};

MhCsS1.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsS1;
