import { List, ListSubheader } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import countryGroups from "../../../data/countryGroups.json";
import CountryGroupItem from "./CountryGroupItem";

const useStyles = makeStyles((theme) => ({
  section: {
    color: theme.typography.listSection.color,
    fontSize: theme.typography.listSection.fontSize,
    fontWeight: theme.typography.listSection.fontWeight,
    textTransform: "uppercase",
  },
}));

const CountryGroupList = () => {
  const classes = useStyles();

  return (
    <List>
      <ListSubheader disableSticky={true} className={classes.section}>
        Income
      </ListSubheader>
      {countryGroups.incomeGroup.map((group) => (
        <CountryGroupItem key={group.name} {...group} />
      ))}
      <ListSubheader disableSticky={true} className={classes.section}>
        Donor Priority
      </ListSubheader>
      {countryGroups.donorPriority.map((group) => (
        <CountryGroupItem key={group.name} {...group} />
      ))}
      <ListSubheader disableSticky={true} className={classes.section}>
        Regional (World Bank)
      </ListSubheader>
      {countryGroups.regional.worldBank.map((group) => (
        <CountryGroupItem key={`wb-${group.name}`} {...group} />
      ))}
      <ListSubheader disableSticky={true} className={classes.section}>
        Regional (UNPD)
      </ListSubheader>
      {countryGroups.regional.unpd.map((group) => (
        <CountryGroupItem key={`unpd-${group.name}`} {...group} />
      ))}
    </List>
  );
};

export default CountryGroupList;
