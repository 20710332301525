import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import BoxControls from "../../../Common/Box/BoxControls";
import Table from "../../../Common/Box/Table";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatNumber, getSingular } from "../../../../utilities";
import CaseGroupChooser from "../CaseGroupChooser";

const number = 2;

const format = (n) =>
  n !== 0
    ? formatNumber(n, {
        significantDigits: 3,
      })
    : "";

const createTitle = (caseGroupId) => {
  let clause;

  switch (caseGroupId) {
    case 1:
      clause = "Needing and Receiving";

      break;

    case 2:
      clause = "Needing but Not Receiving";

      break;

    default:
      clause = "Needing";
  }

  return `Total Cases ${clause} Seven Priority Maternal Health Drugs`;
};

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child td:not(:first-child)": {
      minWidth: "6rem",
    },
  },
  total: {
    backgroundColor: theme.palette.area.background,
  },
}));

const MhCcT2 = (props) => {
  const [selectedCaseGroupId, setSelectedCaseGroupId] = useState(1);

  const { areaData: data } = useAreaQuery(
    `/custom/mh/cc/tables/2/${props.regionalUnitId}/${selectedCaseGroupId}`
  );

  const classes = useStyles();

  const handleCaseGroupChange = (caseGroupId) => {
    setSelectedCaseGroupId(caseGroupId);
  };

  return (
    <Table
      key="MH_CC_t2"
      number={number.toString()}
      title={createTitle(selectedCaseGroupId)}
      subtitle={`By ${getSingular(props.regionalUnitName)}, 2019`}
      source='Note: in this table cases needing and receiving the antihypertensive drugs (hydralazine and methyldopa) are presented summed together and cases receiving out-of-scope drugs excluded from the "Needed: Received" total; and cases needing and receiving uterotonics (misoprostol and oxytocin) are presented summed together.'
      controls={
        <BoxControls>
          <CaseGroupChooser
            value={selectedCaseGroupId}
            onChange={handleCaseGroupChange}
          />
        </BoxControls>
      }
    >
      {data ? (
        <table className={classes.table}>
          <thead>
            <tr>
              <th />
              {data[0].sectors.map(({ name }) => (
                <th key={name}>{name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((countryGroup) => (
              <tr key={countryGroup.name}>
                <td>{countryGroup.name}</td>
                {countryGroup.sectors.map((sector) => (
                  <td key={sector.name}>{format(sector.services)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div />
      )}
    </Table>
  );
};

MhCcT2.propTypes = {
  regionalUnitName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      sectors: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          services: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ),
};

export default MhCcT2;
