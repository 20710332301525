import { makeStyles } from "@material-ui/styles";
import Table from "../../Common/Box/Table";
import useCsvDownload from "../../../hooks/useCsvDownload";
import { formatNumber } from "../../../utilities";

const number = 4;

const data = [
  {
    drug: "Iron and folic acid",
    unitPrice: 0.0031,
  },
  {
    drug: "Methyldopa",
    unitPrice: 0.0387,
  },
  {
    drug: "Hydralazine",
    unitPrice: 3.956,
  },
  {
    drug: "Magnesium sulfate",
    unitPrice: 0.478,
  },
  {
    drug: "Oxytocin",
    unitPrice: 0.227,
  },
  {
    drug: "Misoprostol",
    unitPrice: 0.135,
  },
  {
    drug: "Metronidazole",
    unitPrice: 0.575,
  },
  {
    drug: "Heat-stable carbetocin",
    unitPrice: 0.33,
  },
  {
    drug: "Tranexamic acid",
    unitPrice: 1.3,
  },
];

const format = (n) =>
  formatNumber(n, {
    prefix: "$",
    decimalPlaces: 4,
  });

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
    },
    "& td:last-child": {
      textAlign: "right",
    },
    "& th": {
      padding: theme.table.cellPadding,
    },
  },
  subtitle: {
    fontStyle: "italic",
    fontWeight: "bold",
  },
  indicator: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  ...Object.entries(theme.palette.areas.maternalHealth.methods).reduce(
    (accumulator, [key, value]) => {
      accumulator[key] = {
        backgroundColor: value,
      };

      return accumulator;
    },
    {}
  ),
}));

const Component = () => {
  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleCsvDownload = () => {
    downloadCsv("/mh/tables/14/csv", `mh_t${number}.csv`);
  };

  return (
    <Table
      key="MH_t14"
      number={number.toString()}
      title="Unit Prices for Seven Priority Maternal Health Drugs Plus Two Emerging Drugs"
      onDownloadCsv={handleCsvDownload}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th>Drug</th>
            <th>Unit Price</th>
          </tr>
        </thead>
        <tbody>
          {data.map(({ drug, unitPrice }) => (
            <tr key={drug}>
              <td>{drug}</td>
              <td>{format(unitPrice)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Table>
  );
};

export default Component;
