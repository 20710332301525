import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import useInterval from "../../hooks/useInterval";

const useStyles = makeStyles({
  placeholder: {
    height: (props) => props.height,
    width: (props) => props.width,
  },
});

const DeferredRenderer = (props) => {
  const [render, setRender] = useState(props.delayMilliseconds < 0);

  useInterval(() => {
    setRender(true);
  }, props.delayMilliseconds);

  const classes = useStyles({
    width: props.width,
    height: props.height,
  });

  return render ? props.children : <div className={classes.placeholder} />;
};

DeferredRenderer.propTypes = {
  delayMilliseconds: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

DeferredRenderer.defaultProps = {
  delayMilliseconds: 100,
};

export default DeferredRenderer;
