import { Typography } from "@material-ui/core";

const FutureScenarios = () => (
  <div>
    <Typography variant="h1">Future Scenarios</Typography>

    <p>
      For future projections, for contraception we have continued with a single
      trajectory forward, informed by existing projections of contraceptive use.
    </p>
    <p>
      For the new health areas no such future projections exist, therefore for
      these areas we explore three future scenarios. First, a baseline scenario
      (Scenario 1) looks at what would happen if we saw changes in demographics
      and contraceptive use, but no changes in the coverage and use of the new
      health services and supplies. Next, we explore a scenario (Scenario 2)
      where patterns of use shift or improve, informed by the patterns seen in
      the next-highest income group. Finally, we layer this change in use from
      Scenario 2 with changes in the types of services and supplies used for
      Scenario 3. For each health area, this third scenario aligns with
      conversations happening among advocates and planners: the scale-up of
      reusable menstrual hygiene products; increased use of medical abortion,
      especially those using mifepristone; and the scale-up of heat-stable
      carbetocin and tranexamic acid. This scenario allows for exploration of
      the implications of such changes.
    </p>
    <p>
      While it is impossible to predict what lies ahead, the general direction
      of future trends will be shaped by the discussions and research taking
      place now. We see our future projections as a starting point for
      discussions, providing a baseline assessment of where things could be to
      help inform conversations about what changes may be needed to address
      reproductive health supply needs.
    </p>
  </div>
);

export default FutureScenarios;
