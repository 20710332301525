import { Typography } from "@material-ui/core";
import RgApacT3 from "./RgApacT3";

const AbortionFutureScenarios = () => (
  <div>
    <Typography variant="h1">Future Scenarios</Typography>

    <p>
      Based on consultations with experts, we selected three scenarios to
      explore potential changes in the number and cost of abortion and PAC
      services between 2019 and 2030: 1) safety profile and method mix is
      maintained at 2019 levels; 2) shift in safety profile towards safer
      services; 3) shift in safety profile and method mix. The number of
      abortions change in accordance with projected changes in population and
      contraceptive use; however, the safety profile of the abortion services
      and the methods used vary, as do the resulting number of PAC services.
    </p>
    <p>
      <strong>Scenario 1: Maintain safety profile and method mix.</strong>
      This scenario accounts for demographic changes, changes in contraceptive
      use (and thus resulting changes in fertility) and contraceptive method mix
      (based on existing trend data). However, we maintain the same distribution
      of the safety profile of abortion services (i.e. the proportion that are
      safe/less safe/least safe), and the same distribution of surgical and
      medical methods, currently found in each income group.
    </p>
    <p>
      <strong>Scenario 2: Shift safety profile.</strong> This scenario assumes
      the same demographic and contraceptive use changes in Scenario 1. But it
      improves the safety profile of services by matching the proportion of
      abortion services that are safe/less safe/least safe to the average
      pattern seen in the next highest income group, so that by 2030:
    </p>

    <ul>
      <li>
        Low-income countries shift to have a distribution representing the
        average of lower-middle-income countries;
      </li>
      <li>
        Lower-middle-income countries shift to a distribution representing the
        average of upper-middle-income countries; and
      </li>
      <li>
        Upper-middle-income countries shift to a distribution representing the
        average of high-income countries.
      </li>
    </ul>

    <p>
      These shifts occur over time following a linear trend. However, if in 2019
      a country already has a distribution with more safe abortions than what
      they would shift to using this average pattern, then they keep their
      original distribution through to 2030.
    </p>
    <p>
      <strong>Scenario 3: Shift safety profile and method mix:</strong> This
      scenario is the same as Scenario 2 but also builds in a shift to greater
      use of medical (especially combined use of misoprostol and mifepristone)
      rather than surgical methods among safe and less safe abortions. The
      magnitude of the shift to medical methods is based on the safety
      classification of the abortion services:
    </p>

    <ul>
      <li>
        For abortions classed as &apos;safe&apos;, we assume that by 2030 all
        medical abortions would use a combination of mifepristone and
        misoprostol (no misoprostol-only abortions), and 50% of all safe
        abortions would be medical abortions (vs 30-45% currently).
      </li>
      <li>
        For abortions classed as &apos;less safe&apos;, regions with less than
        50% medical abortions in 2019 would shift to 50% medical abortion by
        2030. The proportion of medical abortions using a combination of
        misoprostol and mifepristone also would increase in all regions.
      </li>
    </ul>

    <p>
      Different profiles of increased use in medical methods are applied based
      on current use, and some misoprostol-only abortions remain; see Table 3
      below for more detail.
    </p>
    <p>
      The methods used for providing PAC services remain constant; in all three
      scenarios PAC services for incomplete abortion/non-severe bleeding and
      miscarriages through 13 weeks are assumed to involve surgical methods
      (vacuum aspiration) 50% of the time and medical methods (misoprostol) 50%
      of the time.
    </p>

    <RgApacT3 />
  </div>
);

export default AbortionFutureScenarios;
