import { useState } from "react";
import BoxControls from "../../Common/Box/BoxControls";
import IncomeGroupChooser from "../../Common/Box/Choosers/IncomeGroupChooser";
import Figure from "../../Common/Box/Figure";
import initialData from "../../../data/initialData.json";
import useAreaQuery from "../../../hooks/useAreaQuery";
import { getIncomeGroup, getTooltip } from "../../../utilities";

const number = 9;

const getSeriesColor = (name) => {
  switch (name) {
    case "2019":
      return "#a9c9c4";

    case "2025":
      return "#4ca198";

    case "2030":
      return "#3d847d";

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const series = data.map((series) => ({
    ...series,
    color: getSeriesColor(series.name),
  }));

  return {
    chart: {
      type: "column",
      width: 470,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: [
        "1: Maintain product use",
        "2: Increase purpose-made",
        "3: Increase purpose-made & reusable",
      ],
    },
    yAxis: {
      title: undefined,
      labels: {
        formatter: function () {
          return "$" + this.axis.defaultLabelFormatter.call(this);
        },
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Cost", this.y, this.percentage, {
          prefix: "$",
        });
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const Component = (props) => {
  const [selectedIncomeGroupId, setSelectedIncomeGroupId] = useState(0);

  const { areaData: data } = useAreaQuery(
    `/mhm/figures/11/${selectedIncomeGroupId}`,
    initialData["mhm_f11"]
  );

  const handleIncomeGroupChange = (incomeGroupId) => {
    setSelectedIncomeGroupId(incomeGroupId);
  };

  return (
    <Figure
      id="MHM_f11"
      number={number.toString()}
      fileStem={`mhm_f${number}`}
      title="Change in Cost of Purpose-Made Menstrual Hygiene Products"
      subtitle="By Future Scenario, 2019-2030"
      chartOptions={options(data)}
      selection={getIncomeGroup(selectedIncomeGroupId)}
      controls={
        <BoxControls>
          <IncomeGroupChooser
            value={selectedIncomeGroupId}
            onChange={handleIncomeGroupChange}
          />
        </BoxControls>
      }
    />
  );
};

export default Component;
