import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Footnote from "../../Common/Footnote";
import { enableCustomReports } from "../../../constants";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const useStyles = makeStyles({
  customReports: {
    display: enableCustomReports ? "block" : "none",
  },
});

const ContraceptionScope = () => {
  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Scope</Typography>

      <Typography variant="h2">What</Typography>

      <p>
        We focus on the use of modern contraceptive methods, and categorize
        methods as follows:
      </p>

      <Typography variant="h6">Long-acting and permanent methods</Typography>

      <ol>
        <li>Sterilization (male and female)</li>
        <li>Implants</li>
        <li>IUDs</li>
      </ol>

      <Typography variant="h6">Short-acting methods</Typography>

      <ol>
        <li>Injectables</li>
        <li>Pills</li>
        <li>
          Condoms (male; for contraception only)
          <Footnote number={1}>
            LEAP only quantifies condoms used primarily for contraception;
            therefore, estimates do not represent the full condom market.
          </Footnote>
        </li>
        <li>
          Other
          <Footnote number={2}>
            &quot;Other&quot; methods of contraception include, where data are
            available, female condom, emergency contraception, Standard Days
            Method, LAM, spermicide, and other barrier methods.
          </Footnote>
        </li>
      </ol>

      <p>
        Our primary focus is on the contraceptive supplies used by women (namely
        the contraceptive commodities and any associated clinical supplies).
      </p>

      <Typography variant="h2">Who</Typography>

      <p>
        LEAP covers the use of modern contraceptives among all women aged 15-49.
        This includes both married and unmarried users. While we calculate use
        separately by marital status (see{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-users-by-method")}
        >
          Users by Method
        </Link>{" "}
        below), we present results for all users combined.
      </p>

      <Typography variant="h2">When</Typography>

      <p>
        Our results cover the time period from 2019 to 2030. We used 2019 as the
        baseline year for exploring the &quot;current&quot; landscape for
        contraceptive supplies due to the uncertainties of the impacts of the
        global COVID-19 pandemic in 2020. See{" "}
        <Link underline="always" onClick={handleReadersGuideOpen("timeframe")}>
          Timeframe
        </Link>{" "}
        above for more detail.
      </p>

      <Typography variant="h2">Where</Typography>

      <p>
        Our analysis includes 129 low- and middle-income countries. The
        Landscape Report segments these countries into three income groups as
        defined by the World Bank: low-income countries, lower-middle- income
        countries, and upper-middle-income countries. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("income-groups")}
        >
          Income Groups
        </Link>{" "}
        above for more detail.
      </p>
      <p className={classes.customReports}>
        The Custom Reports allow you to explore results not only by income group
        but also by individual country and additional groupings of countries
        based either on donor priorities or geographic regions. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("country-groups")}
        >
          Country Groups
        </Link>{" "}
        above for more detail.
      </p>
    </div>
  );
};

export default ContraceptionScope;
