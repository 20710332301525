import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Table from "../../../Common/Box/Table";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { capitalizePhrase, formatNumber } from "../../../../utilities";

const key = "MHM_CS_t1";
const number = 1;

const format = (n, options = {}) => {
  if (n == null) {
    return null;
  }

  return formatNumber(n, {
    significantDigits: 3,
    ...options,
  });
};

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& th:not(:first-child)": {
      width: "8rem",
    },
    "& .subtotal": {
      fontWeight: "bold",
    },
  },
}));

const MhmCsT1 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cs/tables/1/${props.regionId}`
  );

  const classes = useStyles();

  return (
    <Table
      key={key}
      number={number.toString()}
      title="Total Menstruators, Quantities and Costs"
      subtitle={`${capitalizePhrase(
        props.regionName
      )} by Menstrual Hygiene Product Type, 2019`}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th>Menstruators</th>
            <th>Quantities</th>
            <th>Costs</th>
          </tr>
        </thead>
        <tbody>
          <tr className="subtotal">
            <td>Purpose-made</td>
            <td>{format(data?.purposeMade?.menstruators)}</td>
            <td />
            <td>{format(data?.purposeMade?.costs, { prefix: "$" })}</td>
          </tr>
          <tr>
            <td>Disposable</td>
            <td>{format(data?.disposable?.menstruators)}</td>
            <td>{format(data?.disposable?.quantities)}</td>
            <td>{format(data?.disposable?.costs, { prefix: "$" })}</td>
          </tr>
          <tr>
            <td>Reusable pads</td>
            <td>{format(data?.reusablePads?.menstruators)}</td>
            <td>{format(data?.reusablePads?.quantities)}</td>
            <td>{format(data?.reusablePads?.costs, { prefix: "$" })}</td>
          </tr>
          <tr>
            <td>Cups</td>
            <td>{format(data?.cups?.menstruators)}</td>
            <td>{format(data?.cups?.quantities)}</td>
            <td>{format(data?.cups?.costs, { prefix: "$" })}</td>
          </tr>
          <tr className="subtotal">
            <td>Non-purpose-made</td>
            <td>{format(data?.nonPurposeMade?.menstruators)}</td>
            <td>{format(data?.nonPurposeMade?.quantities)}</td>
            <td>{format(data?.nonPurposeMade?.costs, { prefix: "$" })}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>{format(data?.total?.menstruators)}</td>
            <td />
            <td>{format(data?.total?.costs, { prefix: "$" })}</td>
          </tr>
        </tbody>
      </table>
    </Table>
  );
};

MhmCsT1.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCsT1;
