import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import MethodChooser from "../../../Common/Box/Choosers/MethodChooser";
import Figure from "../../../Common/Box/Figure";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { capitalizePhrase, getTooltip } from "../../../../utilities";

const number = 6;

const getSeriesColor = (name) => {
  switch (name) {
    case "2019":
      return "#a9c9c4";

    case "2025":
      return "#4ca198";

    case "2030":
      return "#3d847d";

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const series = data.map((series) => ({
    ...series,
    color: getSeriesColor(series.name),
  }));

  return {
    chart: {
      type: "column",
      width: 470,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: [
        "1: Maintain product use",
        "2: Increase purpose-made",
        "3: Increase purpose-made & reusable",
      ],
    },
    yAxis: {
      title: undefined,
      labels: {
        formatter: function () {
          return "$" + this.axis.defaultLabelFormatter.call(this);
        },
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Cost", this.y, this.percentage, {
          prefix: "$",
        });
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const MhmCsF6 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cs/figures/6/${props.regionId}/${props.methodId}`
  );

  return (
    <Figure
      id="MHM_CS_f6"
      number={number.toString()}
      fileStem={`mhm_cs_f${number}`}
      title="Change in Cost of Purpose-Made Menstrual Hygiene Products"
      subtitle={`${capitalizePhrase(
        props.regionName
      )} by Future Scenario, 2019-2030`}
      controls={
        <BoxControls>
          <MethodChooser
            area="menstrual-hygiene"
            value={props.methodId}
            onChange={props.onMethodChange}
          />
        </BoxControls>
      }
      chartOptions={data && options(data)}
    />
  );
};

MhmCsF6.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
  methodId: PropTypes.number.isRequired,
  onMethodChange: PropTypes.func,
};

export default MhmCsF6;
