import PropTypes from "prop-types";
import ChooserOptions from "../../Common/Box/Choosers/ChooserOptions";
import Select from "../../Common/Box/Select";

const caseGroups = [
  {
    name: "Needed: Received",
    value: 1,
  },
  {
    name: "Needed: Not received",
    value: 2,
  },
  {
    name: "Total cases",
    value: 0,
  },
];

const CaseGroupChooser = (props) => (
  <Select
    label="Cases"
    value={props.value}
    onChange={(value) => {
      props.onChange?.(parseInt(value, 10));
    }}
  >
    <ChooserOptions options={caseGroups} />
  </Select>
);

CaseGroupChooser.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

CaseGroupChooser.defaultProps = {
  value: 1,
};

export default CaseGroupChooser;
