import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import { useAnalytics } from "../../hooks/useAnalytics";
import useChapterDetails from "../../hooks/useChapterDetails";
import { useReadersGuide } from "../../hooks/useReadersGuide";
import { getChapterTheme } from "../../utilities";
import CompanyDetails from "./CompanyDetails";
import Section from "./Section";
import TableOfContents from "./TableOfContents";

const getSectionChapter = (section) => {
  if (!section) {
    return undefined;
  }

  for (let chapter of [
    "contraception",
    "menstrual-hygiene",
    "abortion",
    "maternal-health",
  ]) {
    if (section.startsWith(chapter)) {
      return chapter;
    }
  }

  return "overall";
};

const useSelectedChapter = () => {
  const { slug } = useChapterDetails();

  return [
    "contraception",
    "menstrual-hygiene",
    "abortion",
    "maternal-health",
  ].includes(slug)
    ? slug
    : "overall";
};

const useStyles = makeStyles((theme) => ({
  companyDetails: {
    marginTop: theme.spacing(8),
  },
  content: {
    display: "flex",
    justifyContent: "center",
    marginLeft: theme.gutters.readersGuide.left,
    width: "100%",
  },
  main: {
    display: "flex",
    marginLeft: theme.gutters.default.left,
    marginRight: theme.gutters.default.right,
  },
  navigation: {
    display: "flex",
    flexDirection: "column",
    margin: `3rem 0 0 0`,
    minWidth: theme.navigation.readersGuide.width,
  },
  toolbar: {
    textAlign: "right",
  },
}));

const ReadersGuide = ({ onClose }) => {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.trackPageView({
      url: "/readers-guide",
    });
  }, [analytics]);

  const selectedChapter = useSelectedChapter();
  const { slug, setSlug } = useReadersGuide();

  const [selectedSection, setSelectedSection] = useState();

  const toolbarRef = useRef();

  useEffect(() => {
    setSelectedSection(slug);
    toolbarRef.current.scrollIntoView();
  }, [slug]);

  const classes = useStyles({
    theme: getChapterTheme("readers-guide"),
  });

  const handleSectionChange = (sectionId) => {
    setSlug(sectionId);
  };

  console.log(`Slug: ${slug}`);

  return (
    <MuiThemeProvider
      theme={(theme) =>
        createTheme({
          ...theme,
          palette: {
            ...theme.palette,
            title: {
              ...theme.palette.title,
              primary: "#5c9cb6",
            },
          },
          typography: {
            ...theme.typography,
            h1: {
              ...theme.typography.h1,
              color: "#5c9cb6",
            },
            h2: {
              ...theme.typography.h2,
              color: "#5c9cb6",
            },
          },
        })
      }
    >
      <div ref={toolbarRef} className={classes.toolbar}>
        <IconButton
          onClick={() => {
            onClose?.();
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <div className={classes.main}>
        <div className={classes.navigation}>
          <TableOfContents
            selectedChapter={getSectionChapter(selectedSection)}
            selectedSection={selectedSection}
            onChange={(section) => {
              handleSectionChange(section.section);
            }}
          />
          <div className={classes.companyDetails}>
            <CompanyDetails />
          </div>
        </div>

        <div className={classes.content}>
          <Section section={selectedSection || selectedChapter} />
        </div>
      </div>
    </MuiThemeProvider>
  );
};

ReadersGuide.propTypes = {
  onClose: PropTypes.func,
};

export default ReadersGuide;
