import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { enableCustomReports } from "../../../constants";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const useStyles = makeStyles({
  customReports: {
    display: enableCustomReports ? "block" : "none",
  },
});

const AbortionScope = () => {
  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Scope</Typography>

      <Typography variant="h2">What</Typography>

      <p>
        Abortion and Post Abortion Care (PAC) services are an important
        component of the sexual and reproductive health and rights for women and
        girls globally, to ensure everyone can choose if, when and with whom to
        have children. Even if there was global access to uninterrupted,
        affordable and effective contraception, there would still be a need for
        abortion due to method failure, unprotected sex (consensual and
        non-consensual) and medical reasons. However, laws and policies
        regulating abortion and PAC services vary immensely across and within
        countries
        <Footnote number={2}>
          Centre for Reproductive Rights{" "}
          <ExternalLink
            href="https://maps.reproductiverights.org/worldabortionlaws"
            text="The World's Abortion Laws"
            useItalics={true}
          />
          .
        </Footnote>
        ; this variation, along with abortion stigma
        <Footnote number={3}>
          LeTourneau, K{" "}
          <ExternalLink
            href="https://inroads-cdn.s3.amazonaws.com/files/inroads-technical-report-ENG-LR.pdf"
            text="Abortion stigma around the world: A synthesis of the qualitative literature"
            useItalics={true}
          />{" "}
          A technical report for members of The International Network for the
          Reduction of Abortion Discrimination and Stigma (inroads). 2016,
          Chapel Hill, NC: inroads.
        </Footnote>
        , results in great variation in access to services.
      </p>
      <p>
        In LEAP, abortion is used to refer to services to intentionally end a
        pregnancy, including any abortions that are incomplete, while post
        abortion care (PAC) is used to refer to services to treat abortion
        complications, including to complete an incomplete abortion or
        miscarriage and treat non-severe bleeding. The World Health Organization
        (WHO) classes abortions as &apos;safe&apos; if the service is provided
        by an appropriately trained person using a surgical or medical method
        recommended by WHO appropriate to the pregnancy gestation
        <Footnote number={4}>
          World Health Organization{" "}
          <ExternalLink
            href="https://www.who.int/news-room/fact-sheets/detail/preventing-unsafe-abortion"
            text="Preventing Unsafe Abortion Factsheet"
            useItalics={true}
          />
          .
        </Footnote>
        . &apos;Less safe&apos; abortions meet only one of these two criteria
        e.g. abortions provided by a trained health worker using an outdated
        method, or if a person self-induces an abortion using a safe method
        (e.g. misoprostol) without adequate information or support from a
        trained individual. &apos;Least safe abortions&apos; meet neither of the
        two criteria; they are provided by untrained people using dangerous
        methods, such as toxic substances or insertion of sharp objects
        <Footnote number={5}>
          World Health Organization{" "}
          <ExternalLink
            href="https://www.who.int/news-room/fact-sheets/detail/preventing-unsafe-abortion"
            text="Preventing Unsafe Abortion Factsheet"
            useItalics={true}
          />
          .
        </Footnote>
        . The quality of the medical and surgical supplies used also affects the
        safety of services
        <Footnote number={6}>
          Clark H, RamoRao S, Townsend J{" "}
          <ExternalLink
            href="https://www.rhsupplies.org/uploads/tx_rhscpublications/Safe_Abortion_Supplies_Landscaping_Report.pdf"
            text="Ensuring Access to Safe Abortion Supplies: Landscaping of barriers and Opportunities"
            useItalics={true}
          />{" "}
          2017: Population Council.
        </Footnote>
        .
      </p>
      <p>
        Safe and less safe abortion services, and PAC services, can be provided
        using either:
      </p>

      <ul>
        <li>
          <strong>Surgical methods</strong>&mdash;either vacuum aspiration or
          dilation methods.
        </li>
        <li>
          <strong>Medical methods</strong>&mdash;misoprostol with and without
          mifepristone. Where mifepristone is packaged together with
          misoprostol, this is known as a combipack. Different numbers of
          misoprostol pills are required at different pregnancy gestations; see{" "}
          <Link
            underline="always"
            onClick={handleReadersGuideOpen("abortion-quantities")}
          >
            Supply Quantities
          </Link>{" "}
          below for more detail
        </li>
      </ul>

      <p>
        For the purpose of LEAP, we have focused on costing the supplies for:
      </p>

      <ol>
        <li>
          <strong>
            Surgical abortion and PAC services provided via vacuum aspiration
          </strong>
          : either via manual vacuum aspiration (MVA) or electric vacuum
          aspiration (EVA);
        </li>
        <li>
          <strong>Medical abortion services</strong> using misoprostol with and
          without mifepristone; and
        </li>
        <li>
          <strong>PAC services</strong> provided using misoprostol.
        </li>
      </ol>

      <p>
        Least safe abortion services have not been costed as they are typically
        performed by unregulated providers and/or using products and techniques
        that are not considered safe or appropriate reproductive health supplies
        (e.g. toxic substances, sharp objects). Dilation methods of surgical
        abortion (dilation and curettage (D&amp;C) and dilation and evacuation
        (D&amp;E)) have not been costed as there are limited supply costs and
        the equipment used is not specific to provision of abortion and PAC
        services. In addition, any emergency obstetrics care required due to
        severe complications from a PAC service are not included in the
        analysis.
      </p>

      <Typography variant="h2">Who</Typography>

      <p>
        LEAP covers individuals utilizing safe and less safe abortion services
        as well as PAC services to complete an incomplete abortion or
        miscarriage. Consistent with other abortion measurement efforts,
        abortion resulting from intended pregnancies are not included in our
        estimates
        <Footnote number={7}>
          Sully EA, Biddlecom A, Darroch JE, Riley T, Ashford LS, Lince-Deroche
          N et al{" "}
          <ExternalLink
            href="https://www.guttmacher.org/report/adding-it-up-investing-in-sexual-reproductive-health-2019"
            text="Adding It Up: Investing in Sexual and Reproductive Health 2019"
            useItalics={true}
          />{" "}
          New York: Guttmacher Institute, 2020.
        </Footnote>
        <Footnote number={8}>
          Bearak J, Popinchalk A, Ganatra B, et al{" "}
          <ExternalLink
            href="http://dx.doi.org/10.1016/S2214-109X(20)30315-6"
            text="Unintended pregnancy and abortion by income, region, and the legal status of abortion: estimates from a comprehensive model for 1990–2019"
            useItalics={true}
          />{" "}
          Lancet Global Health 2020; published online July 22.
        </Footnote>
        .
      </p>

      <Typography variant="h2">When</Typography>

      <p>
        Our results cover the time period from 2019 to 2030. We used 2019 as the
        baseline year for exploring the &quot;current&quot; landscape for
        abortion and PAC services due to the uncertainties of the impacts of the
        global COVID-19 pandemic in 2020. See{" "}
        <Link underline="always" onClick={handleReadersGuideOpen("timeframe")}>
          Timeframe
        </Link>{" "}
        above for more detail.
      </p>

      <Typography variant="h2">Where</Typography>

      <p>
        Our analysis includes 129 low- and middle-income countries. The
        Landscape Report segments these countries into three income groups as
        defined by the World Bank: low-income countries, lower-middle-income
        countries, and upper-middle-income countries. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("income-groups")}
        >
          Income Groups
        </Link>{" "}
        above for more detail.
      </p>
      <p className={classes.customReports}>
        The Custom Reports allow you to explore results not only by income group
        but also by region (geographic regions and donor priority regions). See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("country-groups")}
        >
          Country Groups
        </Link>{" "}
        above for more detail.
      </p>
    </div>
  );
};

export default AbortionScope;
