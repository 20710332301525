import PropTypes from "prop-types";
import ExternalLink from "../../../Common/ExternalLink";
import Footnote from "../../../Common/Footnote";
import SideBySide from "../../../Common/SideBySide";
import MhCsF1 from "../Figures/MhCsF1";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeComparison,
  getRegionName,
  toLowerCase,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  const notReceived = data?.[0].methods.slice(-1)[0].services ?? 0;
  const lmicNotReceived = data?.slice(-1)[0].methods.slice(-1)[0].services ?? 1;

  return {
    methodShare: data?.[0].share ?? 0,
    notReceived,
    shortfallShare: (notReceived / lmicNotReceived) * 100,
  };
};

const MhCsS2 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/figures/1/${props.regionId}/112`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        The WHO recommends that daily oral iron and folic acid supplementation
        should be used in all pregnancies to prevent anemia and birth defects
        <Footnote number={2}>
          World Health Organization{" "}
          <ExternalLink
            href="https://www.who.int/reproductivehealth/publications/maternal_perinatal_health/a91272/en/"
            text="Standards for Maternal and Neonatal Care. Group 1: General standards of care for healthy pregnancy and childbirth"
            useItalics={true}
          />{" "}
          2007.
        </Footnote>
        . Figure 1, therefore, shows all pregnant women divided into those who
        received iron and folic acid and those who did not
        <Footnote number={3}>
          Specifically the cases here are all women who carry their pregnancies
          to term.
        </Footnote>
        .
      </p>

      <SideBySide
        component={
          <MhCsF1
            regionId={props.regionId}
            regionName={props.regionName}
            regionShortName={getRegionName(props.regionId, "short")}
            data={data}
          />
        }
      >
        <p>
          Results are shown for {toLowerCase(props.regionName)} in comparison to
          the three country-level income groups.
        </p>

        <p>
          In {toLowerCase(props.regionName)}, iron and folic acid is given in{" "}
          {formatPercentage(metrics.methodShare)} of cases. This is{" "}
          {getQualitativeComparison(metrics.methodShare, 15)} low-income
          countries (15%) and{" "}
          {getQualitativeComparison(metrics.methodShare, 44)}{" "}
          upper-middle-income countries (44%).
        </p>

        <p>
          In {toLowerCase(props.regionName)}{" "}
          {formatNumberAsHumanReadable(metrics.notReceived)} cases do not
          receive the recommended drug; this represents{" "}
          {formatPercentage(metrics.shortfallShare)} of the shortfall across all
          low- and middle-income countries.
        </p>
      </SideBySide>
    </>
  );
};

MhCsS2.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsS2;
