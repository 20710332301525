import PropTypes from "prop-types";
import ExternalLink from "../../../Common/ExternalLink";
import Footnote from "../../../Common/Footnote";
import SideBySide from "../../../Common/SideBySide";
import MhCsF2 from "../Figures/MhCsF2";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeComparison,
  getRegionName,
  toLowerCase,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  const notReceived = data?.[0].methods.slice(-1)[0].services ?? 0;
  const lmicNotReceived = data?.slice(-1)[0].methods.slice(-1)[0].services ?? 1;

  return {
    methodShare: data?.[0].share ?? 0,
    notReceived,
    shortfallShare: (notReceived / lmicNotReceived) * 100,
  };
};

const MhCsS3 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/figures/1/${props.regionId}/102`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        Hydralazine and methyldopa are both used to manage hypertension, which
        occurs in roughly 4-7% of all pregnancies
        <Footnote number={4}>
          Abalos E, Cuesta C, Carroli G, Qureshi Z, Widmer M, Vogel JP, Souza
          JP {" "}
          <ExternalLink
            href="https://pubmed.ncbi.nlm.nih.gov/24641531/"
            text="WHO Multicountry Survey on Maternal and Newborn Health Research Network. Pre-eclampsia, eclampsia and adverse maternal and perinatal outcomes: a secondary analysis of the World Health Organization Multicountry Survey on Maternal and Newborn Health"
            useItalics={true}
          />{" "}
          BJOG. 2014 Mar;121 Suppl 1:14-24.
        </Footnote>
        . Both drugs are included in Figure 2, which represents all cases where
        a pregnant woman experienced acute, gestational, and/or chronic
        hypertension. These are the most commonly used hypertension control
        drugs; instances of other drugs being used for hypertension are noted as
        "out of scope".
      </p>

      <SideBySide
        component={
          <MhCsF2
            regionId={props.regionId}
            regionName={props.regionName}
            regionShortName={getRegionName(props.regionId, "short")}
            data={data}
          />
        }
        componentPosition="right"
      >
        <p>
          Results are shown for {toLowerCase(props.regionName)} in comparison to
          the three country-level income groups.
        </p>

        <p>
          In {toLowerCase(props.regionName)}, antihypertensive drugs are used in{" "}
          {formatPercentage(metrics.methodShare)} of cases. This is{" "}
          {getQualitativeComparison(metrics.methodShare, 9)} low-income
          countries (9%) and {getQualitativeComparison(metrics.methodShare, 21)}{" "}
          upper-middle-income countries (21%).
        </p>

        <p>
          In {toLowerCase(props.regionName)}{" "}
          {formatNumberAsHumanReadable(metrics.notReceived)} cases do not
          receive the recommended drugs; this represents{" "}
          {formatPercentage(metrics.shortfallShare)} of the shortfall across all
          low- and middle-income countries.
        </p>
      </SideBySide>
    </>
  );
};

MhCsS3.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsS3;
