import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import absoluteChart from "../../../images/absolute.png";
import relativeChart from "../../../images/relative.png";

const useStyles = makeStyles((theme) => ({
  graphic: {
    display: "flex",
    marginTop: theme.spacing(4),
    justifyContent: "center",
    "& img": {
      margin: theme.spacing(0, 4),
    },
  },
}));

const RelativeVersusAbsolute = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h1">Relative vs Absolute</Typography>

      <p>
        Throughout LEAP, results are presented one of two ways: relative or
        absolute. Both provide useful but distinct ways to understand how things
        vary within categories and across all categories. Where relevant,
        figures can be toggled to switch between showing results as relative (%
        of each element) or absolute (whole numbers). Take, for example, the
        figure showing contraceptive users by method, comparing the public
        versus private sector. The absolute view tells us how many contraceptive
        users there are within each sector, both overall and by method. We can
        see from this that there are more public sector injectable users than
        private sector injectable users. However, this does not tell us about
        the contraceptive method mix within each sector&mdash;e.g. what
        proportion of public sector users rely on injectables as compared to
        private sector users. Changing the figure to the relative view, by
        clicking the relative button on the bottom right, will show this view.
        This is useful for understanding patterns within each sector and how
        they differ.
      </p>

      <div className={classes.graphic}>
        <img src={absoluteChart} alt="absolute chart" />
        <img src={relativeChart} alt="relative chart" />
      </div>
    </div>
  );
};

export default RelativeVersusAbsolute;
