import PropTypes from "prop-types";
import Figure from "../../../Common/Box/Figure";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { capitalizePhrase, getTooltip } from "../../../../utilities";
import theme from "../../../../theme";

const number = 5;

const useColor = "#f37676";
const costColor = "#8fbc8c";

const getSeries = (data, theme) => {
  return [
    {
      name: "Use",
      data: data.map((country, index) => ({
        x: index,
        y: country.publicSectorShareOfUsers,
      })),
      color: useColor,
      marker: {
        fillColor: useColor,
      },
    },
    {
      name: "Cost",
      data: data.map((country, index) => ({
        x: index,
        y: country.publicSectorShareOfCosts,
      })),
      color: costColor,
      marker: {
        fillColor: costColor,
      },
    },
  ];
};

const drawRange = (chart, countryCount, countryIndex, y1, y2) => {
  const countryOffset = countryCount - countryIndex;

  const xAxis = chart.xAxis[0];
  const yAxis = chart.yAxis[0];

  const itemGap = xAxis.translate(countryCount - 1.5);
  const itemCenter = xAxis.top + xAxis.translate(countryOffset) + itemGap;

  chart.renderer
    .path([
      "M",
      yAxis.left + yAxis.translate(y1),
      itemCenter,
      "L",
      yAxis.left + yAxis.translate(y2),
      itemCenter,
    ])
    .attr({
      "z-index": -1,
      "stroke-width": 1,
      stroke: theme.palette.grays.gray4,
    })
    .add();
};

const drawRanges = (chart, data) => {
  const countryCount = data.length;

  for (let i = 0; i < countryCount; i++) {
    const country = data[i];

    const y1 = Math.min(
      country.publicSectorShareOfUsers,
      country.publicSectorShareOfCosts
    );
    const y2 = Math.max(
      country.publicSectorShareOfUsers,
      country.publicSectorShareOfCosts
    );

    drawRange(chart, countryCount, i, y1, y2);
  }
};

const options = (data) => (theme) => {
  const series = getSeries(data, theme);

  return {
    chart: {
      type: "scatter",
      width: 1000,
      height: 1000,
      inverted: true,
      backgroundColor: null,
      events: {
        redraw: function () {
          drawRanges(this, data, series);
        },
      },
    },
    xAxis: {
      categories: data.map((country) => country.name),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      opposite: true,
      max: 100,
      labels: {
        formatter: function () {
          return `${this.value}%`;
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          symbol: "circle",
          radius: 4,
          lineWidth: 1,
          lineColor: "silver",
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      reversed: false,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip(
          this.series.index === 0 ? "Users" : "Cost",
          Math.round(this.y),
          undefined,
          {
            suffix: "%",
          }
        );
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const FpCcF5 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/fp/cc/figures/5/${props.regionId}`
  );

  return (
    <Figure
      id="FP_CC_f5"
      number={number.toString()}
      title="Public Sector Share of Contraceptive Use vs Supply Cost"
      subtitle={`${capitalizePhrase(props.regionName)} by Country, 2019`}
      chartOptions={data && options(data)}
      annotations={true}
    />
  );
};

FpCcF5.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default FpCcF5;
