import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router-dom";
import Information from "../../../Common/Box/Information";
import customReportLookups from "../../../../data/customReportLookups.json";
import { toLowerCase } from "../../../../utilities";

const useStyles = makeStyles(() => ({
  content: {
    width: "100%",
    justifyContent: "flex-start",
  },
}));

const FpCcB1 = (props) => {
  const classes = useStyles();

  const countryGroup = customReportLookups.countryGroups[props.regionId];

  return (
    <Information
      key="MHM_CC_b1"
      number="1"
      title="Scope"
      classes={classes.content}
    >
      <p>
        This Custom Report shows summary results for{" "}
        {toLowerCase(props.regionName)} and detailed results for countries
        within this region.
      </p>
      <p>{countryGroup.inclusionNote}</p>
      <p>
        The methodology for LEAP was developed to produce results for all low-
        and middle-income countries. While we make country results available,
        they should be viewed in this context.
      </p>
      <p>
        For detailed results for a single country, see the{" "}
        <RouterLink to="/custom/contraception">Custom Landscape</RouterLink>.
      </p>
    </Information>
  );
};

FpCcB1.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default FpCcB1;
