import { useState } from "react";
import BoxControls from "../../Common/Box/BoxControls";
import MethodChooser from "../../Common/Box/Choosers/MethodChooser";
import Figure from "../../Common/Box/Figure";
import initialData from "../../../data/initialData.json";
import useAreaQuery from "../../../hooks/useAreaQuery";
import { getMethod, getTooltip } from "../../../utilities";

const number = 13;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Low":
      return theme.palette.gni.lic;

    case "Lower-middle":
      return theme.palette.gni.lmic;

    case "Upper-middle":
      return theme.palette.gni.umic;

    default:
      return "black";
  }
};

const options = (data) => (theme) => {
  const series = data.map((series) => ({
    ...series,
    color: getSeriesColor(series.name, theme),
  }));

  return {
    chart: {
      type: "column",
      width: 470,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: ["Users", "Costs"],
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        format: "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "percent",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        const indicator = this.x === "Costs" ? "Cost" : "Users";

        return getTooltip(indicator, this.y, this.percentage, {
          prefix: indicator === "Cost" ? "$" : undefined,
        });
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const Component = () => {
  const [selectedMethodId, setSelectedMethodId] = useState(0);

  const { areaData: data } = useAreaQuery(
    `/fp/figures/12/${selectedMethodId}`,
    initialData["fp_f12"]
  );

  const handleMethodChange = (methodId) => {
    setSelectedMethodId(methodId);
  };

  return (
    <Figure
      id="FP_f12"
      number={number.toString()}
      fileStem={`fp_f${number}`}
      title="Contraceptive Use vs Supply Cost"
      subtitle="By Income Group, 2019"
      selection={getMethod(selectedMethodId)}
      controls={
        <BoxControls>
          <MethodChooser
            area="contraception"
            value={selectedMethodId}
            onChange={handleMethodChange}
          />
        </BoxControls>
      }
      chartOptions={options(data)}
    />
  );
};

export default Component;
