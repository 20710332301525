const grays = {
  gray1: "#fafafa",
  gray2: "#f3f3f3",
  gray3: "#e5e5e5",
  gray4: "#bababa",
  gray5: "#888",
  gray6: "#464646",
};

const theme = {
  appBar: {
    height: 140,
    toolbar: {
      height: 80,
    },
  },
  controls: {
    button: {
      border: {
        primary: grays.gray4,
      },
      primary: grays.gray5,
      selected: {
        background: grays.gray2,
        primary: grays.gray6,
      },
    },
    select: {
      background: "white",
      borderWidth: 1,
      focused: {
        borderWidth: 3,
        primary: grays.gray3,
        secondary: grays.gray4,
      },
      label: {
        primary: grays.gray5,
      },
      primary: grays.gray4,
      secondary: grays.gray6,
    },
  },
  figure: {
    axis: {
      fontSize: "0.875rem",
      primary: "black",
      secondary: "#bababa",
    },
    gridLineColor: grays.gray4,
    legend: {
      fontSize: "0.75rem",
    },
  },
  gutters: {
    default: {
      left: 220,
      right: 100,
    },
    landscape: {
      left: 20,
      right: 100,
    },
    readersGuide: {
      left: 50,
    },
  },
  palette: {
    areas: {
      abortion: {
        primary: "#ac6f90",
        methods: {
          abortion: "#5c9cb6",
          all: "9a587c",
          dilation: "#b8b8b8",
          leastSafeAbortions: "#7f7f7f",
          mife: "#c193ac",
          miso: "#ac6f90",
          mva: "#5ea69b",
          outOfScope: "#afabab",
          pac: "#f69877",
        },
      },
      accent: {
        primary: "#e7f0f4",
      },
      contraception: {
        primary: "#f69877",
        methods: {
          condom: "#8fbc8c",
          implant: "#f69877",
          injectable: "#f37676",
          iud: "#e0aa25",
          other: "#5c9cb6",
          pill: "#c193ac",
          sterilization: "#5ea69b",
        },
      },
      general: {
        primary: "#5c9cb6",
        secondary: grays.gray4,
      },
      maternalHealth: {
        primary: "#f37676",
        methods: {
          ironFolicAcid: "#5c9cb6",
          hydralazine: "#5ea69b",
          methyldopa: "#8fbc8c",
          magnesiumSulfate: "#e0aa25",
          misoprostol: "#9a587c",
          oxytocin: "#f37676",
          metronidazole: "#c193ac",
          heatStableCarbetocin: "#f69877",
          tranexamicAcid: "#e99d7b",
          outOfScope: "#B8B8B8",
          needsDidNotGet: "#969696",
        },
      },
      menstrualHygiene: {
        primary: "#5ea69b",
        methods: {
          commodity: "#5c9cb6",
          disposable: "#5ea69b",
          disposalPartial: "#8fbc8c",
          nonCommodity: "#afabab",
          outOfScope: "#b8b8b8",
          reusable: "#f37676",
          reusableCup: "#e0aa25",
          reusablePad: "#f69877",
        },
      },
    },
    background: {
      default: grays.gray1,
      secondary: "#e7f0f4",
    },
    box: {
      download: {
        primary: grays.gray6,
      },
    },
    gni: {
      lic: "#5c9cb6",
      lmic: "#5ea69b",
      umic: "#8fbc8c",
    },
    grays,
    link: {
      primary: "#5c9cb6",
    },
    navBar: {
      toolbar: {
        background: "white",
      },
    },
    placeholders: {
      primary: "#5c9cb6",
    },
    scenarios: {
      scenario1: "#f69877",
      scenario2: "#5ea69b",
      scenario3: "#ac6f90",
    },
    sectors: {
      private: "#ac6f90",
      public: "#f69877",
      subsidized: "#c193ac",
    },
    title: {
      accent: "rgba(92, 156, 182, 0.25)",
      primary: "#5c9cb6",
      chart: grays.gray6,
    },
    tooltip: {
      primary: grays.gray6,
    },
  },
  navigation: {
    readersGuide: {
      width: 250,
    },
    section: {
      width: 200,
    },
  },
  sideBySide: {
    gap: "4rem",
  },
  table: {
    background: {
      primary: "white",
      secondary: "#f2f7f9",
    },
    border: {
      primary: grays.gray3,
      secondary: grays.gray5,
    },
    cellPadding: "0.5rem",
    fontSize: "0.875rem",
  },
  typography: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: 16,
    h1: {
      color: "#5c9cb6",
      fontSize: "2rem",
      fontWeight: 700,
      margin: "3rem 0 1rem 0",
    },
    h2: {
      color: "#5c9cb6",
      fontSize: "1.5rem",
      fontWeight: 400,
      margin: "3rem 0 1rem 0",
    },
    h6: {
      color: "#5c9cb6",
      fontSize: "1.2rem",
      fontWeight: 400,
    },
    legend: {
      fontSize: "0.75rem",
      fontWeight: "bold",
    },
    listSection: {
      color: grays.gray5,
      fontSize: "1rem",
      fontWeight: "normal",
    },
  },
  overrides: {
    MuiLink: {
      root: {
        color: "#5c9cb6",
        cursor: "pointer",
      },
      underlineHover: {
        textDecoration: "underline",
      },
    },
    MuiSelect: {
      select: {
        height: "1.2rem",
      },
    },
  },
};

export default theme;
