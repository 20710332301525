import { makeStyles } from "@material-ui/styles";
import Table from "../../Common/Box/Table";
import useCsvDownload from "../../../hooks/useCsvDownload";
import { formatNumber, formatPercentage } from "../../../utilities";

const number = 6;

const data = [
  {
    incomeGroup: "Low",
    years: [
      {
        year: 2019,
        births: 23000000,
      },
      {
        year: 2025,
        births: 27400000,
      },
      {
        year: 2030,
        births: 31400000,
      },
    ],
    change: 8400000,
    percentage: 37,
  },
  {
    incomeGroup: "Lower-Middle",
    years: [
      {
        year: 2019,
        births: 64800000,
      },
      {
        year: 2025,
        births: 69500000,
      },
      {
        year: 2030,
        births: 73600000,
      },
    ],
    change: 8860000,
    percentage: 14,
  },
  {
    incomeGroup: "Upper-Middle",
    years: [
      {
        year: 2019,
        births: 42200000,
      },
      {
        year: 2025,
        births: 40200000,
      },
      {
        year: 2030,
        births: 39400000,
      },
    ],
    change: -2800000,
    percentage: -7,
  },
  {
    incomeGroup: "Total",
    years: [
      {
        year: 2019,
        births: 130000000,
      },
      {
        year: 2025,
        births: 137000000,
      },
      {
        year: 2030,
        births: 144000000,
      },
    ],
    change: 14500000,
    percentage: 11,
  },
];

const format = (n) =>
  formatNumber(n, {
    significantDigits: 3,
  });

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child td:not(:first-child)": {
      minWidth: "6rem",
    },
  },
}));

const Component = () => {
  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleCsvDownload = () => {
    downloadCsv("/mh/tables/16/csv", `mh_t${number}.csv`);
  };

  return (
    <Table
      id="MH_t16"
      number={number.toString()}
      title="Change in Total Births"
      subtitle="By Income Group, 2019-2030"
      onDownloadCsv={handleCsvDownload}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th colSpan={3}>Total Births</th>
            <th colSpan={2} className={classes.changeGroupBoundary}>
              Change (2019 to 2030)
            </th>
          </tr>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th>2019</th>
            <th>2025</th>
            <th>2030</th>
            <th className={classes.changeGroupBoundary}>#</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.incomeGroup}>
              <td className={classes.nameGroupBoundary}>{item.incomeGroup}</td>

              {item.years.map((year) => (
                <td key={year.year}>{format(year.births)}</td>
              ))}

              <td className={classes.changeGroupBoundary}>
                {format(item.change)}
              </td>
              <td>{formatPercentage(item.percentage)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Table>
  );
};

export default Component;
