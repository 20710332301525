import { useState } from "react";
import PropTypes from "prop-types";
import ApacCcF7 from "../Figures/ApacCcF7";
import useAreaQuery from "../../../../hooks/useAreaQuery";

const ApacCcS9 = (props) => {
  const [selectedProductId, setSelectedProductId] = useState(1);

  const { areaData: data } = useAreaQuery(
    `/custom/apac/cc/figures/5/${props.regionalUnitId}/${selectedProductId}?indicator=1`
  );

  const handleProductChange = (productId) => {
    setSelectedProductId(productId);
  };

  return (
    <>
      <p>
        Patterns in the cost of abortion and PAC supplies would vary across
        regions. Use the dropdown to explore differences by method between the
        three scenarios in 2030.
      </p>

      <ApacCcF7
        regionalUnitName={props.regionalUnitName}
        productId={selectedProductId}
        data={data}
        onProductChange={handleProductChange}
      />
    </>
  );
};

ApacCcS9.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default ApacCcS9;
