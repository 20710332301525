import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Information from "../../../Common/Box/Information";
import customReportLookups from "../../../../data/customReportLookups.json";
import { getCountryName } from "../../../../utilities";

const useStyles = makeStyles(() => ({
  content: {
    width: "100%",
    justifyContent: "flex-start",
  },
}));

const CountryDetails = ({ regionId }) => {
  const country = customReportLookups.countries[regionId];

  const survey = country.survey
    ? `country specific data based on the following survey: ${country.survey}`
    : "regional and income group averages as no country survey was available";

  return (
    <>
      <p>
        The methodology for LEAP was developed to produce results for all low-
        and middle-income countries. While we make country results available,
        they should be viewed in this context. Country-level results are derived
        from either country-specific data sources or regional and income group
        averages.
      </p>

      <p>
        For {getCountryName(regionId)}, menstrual hygiene product mix is
        informed by {survey}.
      </p>
    </>
  );
};

const CountryGroupDetails = ({ regionId }) => {
  const countryGroup = customReportLookups.countryGroups[regionId];

  return <div>{countryGroup.inclusionNote}</div>;
};

const MhmCsB1 = ({ regionId }) => {
  const classes = useStyles();

  const countryName = getCountryName(regionId);

  return (
    <Information key="MHM_CS_b1" number="1" title="Scope" classes={classes}>
      {countryName ? (
        <CountryDetails regionId={regionId} />
      ) : (
        <CountryGroupDetails regionId={parseInt(regionId, 10)} />
      )}
    </Information>
  );
};

MhmCsB1.propTypes = {
  regionId: PropTypes.number.isRequired,
};

export default MhmCsB1;
