import { useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "../../Common/Box/Box";
import Chart from "../../Common/Box/Chart";
import { chartDownloadImageScaleFactor } from "../../../constants";
import initialData from "../../../data/initialData.json";
import exportCharts from "../../../export";
import useCsvDownload from "../../../hooks/useCsvDownload";
import { getTooltip } from "../../../utilities";

const key = "MHM_f6";
const number = 5;

const segmentColors = [
  "#447c74",
  "#4f8f86",
  "#5ea69b",
  "#84bab2",
  "#9ac6c0",
  "#bfbfbf",
];

const labels = {
  lowIncome: [
    {
      text: "Ethiopia",
      x: 205 + 5,
      y: 111,
      color: "white",
    },
    {
      text: "DR Congo",
      x: 235 + 5,
      y: 185,
      color: "white",
    },
    {
      text: "Uganda",
      x: 233 + 5,
      y: 250,
      color: "white",
    },
    {
      text: "Sudan",
      x: 205 + 5,
      y: 315,
      color: "black",
    },
    {
      text: "Yemen",
      x: 150 + 5,
      y: 316,
      color: "black",
    },
    {
      text: "Remaining<br />countries",
      x: 90 + 5,
      y: 168,
      color: "black",
    },
  ],
  lowMiddleIncome: [
    {
      text: "India",
      x: 230 + 5,
      y: 160,
      color: "white",
    },
    {
      text: "Nigeria",
      x: 258 + 5,
      y: 293,
      color: "black",
    },
    {
      text: "Egypt",
      x: 195 + 5,
      y: 317,
      color: "black",
    },
    {
      text: "Philippines",
      x: 120 + 5,
      y: 317,
      color: "black",
    },
    {
      text: "Pakistan",
      x: 78 + 5,
      y: 300,
      color: "black",
    },
    {
      text: "Remaining<br />countries",
      x: 95 + 5,
      y: 155,
      color: "black",
    },
  ],
  upperMiddleIncome: [
    {
      text: "China",
      x: 230 + 5,
      y: 160,
      color: "white",
    },
    {
      text: "Mexico",
      x: 240 + 5,
      y: 305,
      color: "black",
    },
    {
      text: "Brazil",
      x: 150 + 5,
      y: 320,
      color: "black",
    },
    {
      text: "Russia",
      x: 75 + 5,
      y: 295,
      color: "black",
    },
    {
      text: "Indonesia",
      x: 16 + 5,
      y: 250,
      color: "black",
    },
    {
      text: "Remaining<br />countries",
      x: 100 + 5,
      y: 135,
      color: "black",
    },
  ],
};

const options = (data, labels) => (theme) => ({
  chart: {
    type: "pie",
    backgroundColor: null,
    height: 350,
    width: 380,
    events: {
      render: function () {
        const chart = this;

        for (let label of labels) {
          chart.renderer
            .label(label.text, label.x, label.y, null, null, null, true)
            .css({ color: label.color, textAlign: "center" })
            .attr({
              zIndex: 6,
            })
            .add();
        }
      },
    },
  },
  title: {
    text: data[0].name,
    align: "center",
  },
  plotOptions: {
    pie: {
      size: "95%",
      dataLabels: {
        enabled: false,
        distance: 10,
        style: {
          fontWeight: "normal",
        },
      },
    },
  },
  tooltip: {
    backgroundColor: "white",
    useHTML: true,
    outside: true,
    formatter: function () {
      return getTooltip("Cost", this.y, this.percentage, {
        prefix: "$",
      });
    },
    style: {
      fontSize: "0.875rem",
      color: theme.palette.tooltip.primary,
    },
  },
  exporting: {
    chartOptions: {
      title: {
        text: data[0].name,
      },
    },
    enabled: false,
  },
  series: [
    {
      name: data[0].name,

      data: data[0].data.map((item, i) => ({
        ...item,
        color: segmentColors[i],
      })),
    },
  ],
});

const useStyles = makeStyles((theme) => ({
  chart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  facetPanel: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  title: {
    color: theme.palette.title.chart,
    fontSize: "1.25rem",
  },
}));

const Component = () => {
  const highchartRefs = [useRef(), useRef(), useRef()];

  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleCsvDownload = () => {
    downloadCsv("/mhm/figures/6/csv", `mhm_f${number}.csv`);
  };

  const data = initialData["mhm_f6"];

  return (
    <Box
      id={key}
      caption={`Figure ${number}`}
      title="Cost of Purpose-Made Menstrual Hygiene Products"
      subtitle="Top Five Countries in Each Income Group, 2019"
      onDownloadCsv={handleCsvDownload}
      onDownloadPng={() => {
        exportCharts(
          {
            chart1: highchartRefs[0].current,
            chart2: highchartRefs[1].current,
            chart3: highchartRefs[2].current,
          },
          {
            filename: key,
            scale: chartDownloadImageScaleFactor,
          }
        );
      }}
    >
      <div className={classes.facetPanel}>
        <Chart
          highchartRef={highchartRefs[0]}
          options={options(data["mhm_f6_a"], labels.lowIncome)}
        />
        <Chart
          highchartRef={highchartRefs[1]}
          options={options(data["mhm_f6_b"], labels.lowMiddleIncome)}
        />
        <Chart
          highchartRef={highchartRefs[2]}
          options={options(data["mhm_f6_c"], labels.upperMiddleIncome)}
        />
      </div>
    </Box>
  );
};

export default Component;
