import Information from "../../Common/Box/Information";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";

const MhmB1 = () => (
  <Information key="MHM_b1" number="1" title="Definitions">
    <p>
      <strong>Units</strong>: The &apos;unit&apos; of analysis for menstrual
      hygiene in LEAP is a menstruator. We use the term &apos;menstruator&apos;
      to refer to anyone who menstruates, including some, but not all, women,
      girls, transgender men, and non-binary people. In some places we use the
      term &apos;women and girls&apos; for readability purposes
      <Footnote number={4}>
        Amaya L, Marcatalli J, and Bhavaraju N{" "}
        <ExternalLink
          href="https://www.fsg.org/publications/advancing-gender-equity-improving-menstrual-health"
          text="Advancing Gender Equity by Improving Menstrual Health"
          useItalics={true}
        />{" "}
        FSG. April 2020.
      </Footnote>
      .
    </p>
    <p>
      <strong>Cost</strong>: The &apos;cost&apos; is the price paid by
      menstruators for menstrual hygiene products. While we use the term
      &apos;cost&apos; across LEAP, for menstrual hygiene this largely reflects
      out-of-pocket expenditure by consumers. In some contexts, products may be
      made freely available, for example distribution of pads in schools.
      However, limited data exist to provide visibility into who bears these
      costs.
    </p>
  </Information>
);

export default MhmB1;
