import PropTypes from "prop-types";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  if (!data) {
    return {
      start: 0,
      end: 0,
      change: 0,
      percentageChange: 0,
    };
  }

  const menstruators2019 = data[0].menstruators;
  const menstruators2030 = data[2].menstruators;

  const change = menstruators2030 - menstruators2019;
  const percentageChange = (change / menstruators2019) * 100;

  return {
    start: menstruators2019,
    end: menstruators2030,
    change,
    percentageChange,
  };
};

const MhmCsFutureUseMenstruators = ({ regionId }) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cs/figures/3/${regionId}`
  );

  const metrics = calculateMetrics(data);

  return (
    <div>
      <p>
        In all scenarios, the total number of menstruators would increase from{" "}
        {formatNumberAsHumanReadable(metrics.start)} in 2019 to{" "}
        {formatNumberAsHumanReadable(metrics.end)} in 2030 (
        {formatNumberAsHumanReadable(metrics.change, true)},{" "}
        {formatPercentage(metrics.percentageChange, {
          prefixWithSign: true,
        })}
        ).
      </p>
    </div>
  );
};

MhmCsFutureUseMenstruators.propTypes = {
  regionId: PropTypes.number.isRequired,
};

export default MhmCsFutureUseMenstruators;
