import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Table from "../../../Common/Box/Table";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { capitalizePhrase, formatNumber } from "../../../../utilities";

const key = "APAC_CS_t4";
const number = 1;

const format = (n, options = {}) => {
  if (n == null) {
    return null;
  }

  return formatNumber(n, {
    significantDigits: 3,
    ...options,
  });
};

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td.name": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& th:not(:first-child)": {
      width: "8rem",
    },
    "& .breakdown": {
      fontStyle: "italic",
    },
  },
}));

const ApacCsT4 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/apac/cs/tables/4/${props.regionId}`
  );

  const classes = useStyles();

  const indicators = data?.[0].indicators ?? [];

  return (
    <Table
      key={key}
      number={number.toString()}
      title="Total Services, Quantities, Costs"
      subtitle={`${capitalizePhrase(props.regionName)} by Method, 2019`}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th colSpan={2} />
            {indicators.map((indicator) => (
              <th key={indicator.name}>{indicator.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && (
            <>
              <tr>
                <td colSpan={2} className="name">
                  Surgical: Vacuum aspiration
                </td>

                {data[0].indicators.map((indicator, index) => (
                  <td key={indicator.name}>
                    {format(indicator.value, {
                      prefix: index !== 2 ? "" : "$",
                    })}
                  </td>
                ))}
              </tr>
              <tr>
                <td colSpan={2} className="name">
                  Medical: All
                </td>

                {data[1].indicators.map((indicator, index) => (
                  <td key={indicator.name}>
                    {index !== 1
                      ? format(indicator.value, {
                          prefix: index !== 2 ? "" : "$",
                        })
                      : null}
                  </td>
                ))}
              </tr>
              <tr className="breakdown">
                <td rowSpan={3} className="name">
                  Within medical
                </td>
                <td className="name">Miso only</td>

                {data[2].indicators.map((indicator, index) => (
                  <td key={indicator.name}>
                    {format(indicator.value, {
                      prefix: index !== 2 ? "" : "$",
                    })}
                  </td>
                ))}
              </tr>
              <tr className="breakdown">
                <td className="name">Miso &amp; mife</td>

                {data[3].indicators.map((indicator, index) => (
                  <td key={indicator.name}>
                    {format(indicator.value, {
                      prefix: index !== 2 ? "" : "$",
                    })}
                  </td>
                ))}
              </tr>
              <tr className="breakdown">
                <td className="name">Mife</td>

                {data[4].indicators.map((indicator, index) => (
                  <td key={indicator.name}>
                    {format(indicator.value, {
                      prefix: index !== 2 ? "" : "$",
                    })}
                  </td>
                ))}
              </tr>
              <tr>
                <td colSpan={2} className="name">
                  Total
                </td>

                {data[5].indicators.map((indicator, index) => (
                  <td key={indicator.name}>
                    {index !== 1
                      ? format(indicator.value, {
                          prefix: index !== 2 ? "" : "$",
                        })
                      : null}
                  </td>
                ))}
              </tr>
            </>
          )}
        </tbody>
      </table>
    </Table>
  );
};

ApacCsT4.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default ApacCsT4;
