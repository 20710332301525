import { useState } from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Box from "../../../Common/Box/Box";
import BoxControls from "../../../Common/Box/BoxControls";
import countries from "../../../../data/countries.json";
import mapData from "../../../../data/map.json";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import IndicatorChooser from "../../IndicatorChooser";
import PeriodChooser from "../../PeriodChooser";
import theme from "../../../../theme.js";
import {
  darken,
  getIndicatorDetails,
  getTooltip,
  lighten,
  toTitleCase,
} from "../../../../utilities";
import ScenarioChooser from "../../../Common/Box/Choosers/ScenarioChooser";

const number = 5;

const getData = (mapData, countryCodes, data) => {
  const values = data.slice(1).reduce((accumulator, country) => {
    if (countryCodes[country.id]) {
      accumulator[countryCodes[country.id]] = {
        value: country.value,
        percentage: country.percentage,
      };
    }

    return accumulator;
  }, {});

  return mapData.features.map((feature) => ({
    "iso-a3": feature.properties["iso-a3"],
    value: values[feature.properties["iso-a3"]]?.value,
    percentage: values[feature.properties["iso-a3"]]?.percentage,
  }));
};

const useStyles = makeStyles((theme) => ({
  controls: {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: "fit-content",
  },
  excludeLargeCountries: {
    fontSize: "0.875rem",
  },
}));

const Controls = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.controls}>
      <IndicatorChooser
        area="menstrual-hygiene"
        value={props.indicatorId}
        onChange={props.onIndicatorChange}
      />
      <PeriodChooser
        indicatorId={props.indicatorId}
        value={props.periodId}
        onChange={props.onPeriodChange}
      />
      <ScenarioChooser
        area="menstrual-hygiene"
        value={props.scenarioId}
        onChange={props.onScenarioChange}
      />
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={props.excludeLargeCountries}
            color="primary"
            onChange={(e) => {
              props.onExcludeLargeCountriesChange(e.target.checked);
            }}
          />
        }
        label="Exclude China & India"
        checked={props.excludeLargeCountries}
        classes={{
          label: classes.excludeLargeCountries,
        }}
      />
    </div>
  );
};

const MhmCpF5 = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(2);
  const [selectedPeriodId, setSelectedPeriodId] = useState(0);
  const [selectedScenarioId, setSelectedScenarioId] = useState(5);
  const [excludeLargeCountries, setExcludeLargeCountries] = useState(false);

  const { areaData } = useAreaQuery(
    `/custom/mhm/cp/figures/5/${
      props.methodId
    }/${selectedIndicatorId}/${selectedPeriodId}/${selectedScenarioId}${
      excludeLargeCountries ? "?excludeLargeCountries=true" : ""
    }`
  );

  const selectedIndicatorDetails = getIndicatorDetails(selectedIndicatorId);

  const valuePrefix =
    selectedPeriodId !== 3 && selectedIndicatorId === 1 ? "$" : "";

  const valueSuffix = selectedPeriodId === 3 ? "%" : "";

  const countryCodes = countries.reduce((accumulator, country) => {
    accumulator[country.id] = country.alpha3;

    return accumulator;
  }, {});

  const data = getData(mapData, countryCodes, areaData ?? []);

  const series = [
    {
      name: "World",
      color: theme.palette.grays.gray3,
      data,
    },
  ];

  const options = {
    chart: {
      map: mapData,
      width: 1000,
    },
    title: {
      text: undefined,
    },
    plotOptions: {
      map: {
        allAreas: false,
        joinBy: "iso-a3",
      },
    },
    subtitle: {
      text: null,
    },
    colorAxis: {
      minColor: lighten(theme.palette.areas.menstrualHygiene.primary, 0.8),
      maxColor: darken(theme.palette.areas.menstrualHygiene.primary, 0.2),
      labels: {
        formatter: function () {
          return (
            valuePrefix +
            this.axis.defaultLabelFormatter.call(this) +
            valueSuffix
          );
        },
      },
      reversed: false,
    },
    legend: {
      title: {
        text: selectedIndicatorDetails?.name,
      },
      itemMarginTop: 10,
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return this.point.value
          ? getTooltip(this.key, this.point.value, this.point.percentage, {
              prefix: valuePrefix,
              suffix: valueSuffix,
            })
          : false;
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    series,
    credits: {
      enabled: false,
    },
  };

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  const handlePeriodChange = (periodId) => {
    setSelectedPeriodId(periodId);
  };

  const handleScenarioChange = (scenarioId) => {
    setSelectedScenarioId(scenarioId);
  };

  const handleExcludeLargeCountriesChange = (value) => {
    setExcludeLargeCountries(value);
  };

  return (
    <>
      <p>
        The {selectedIndicatorDetails.qualifier.toLowerCase()}{" "}
        {props.methodName.toLowerCase()} would vary across countries as shown in
        the map below. Use the drop downs to explore results.
      </p>

      <Box
        caption={`Figure ${number}`}
        title={`Future ${selectedIndicatorDetails.qualifier} ${toTitleCase(
          props.methodName
        )}`}
        subtitle="Selected Scenario by Country, 2025"
        controls={
          <BoxControls>
            <Controls
              indicatorId={selectedIndicatorId}
              periodId={selectedPeriodId}
              scenarioId={selectedScenarioId}
              excludeLargeCountries={excludeLargeCountries}
              onIndicatorChange={handleIndicatorChange}
              onPeriodChange={handlePeriodChange}
              onScenarioChange={handleScenarioChange}
              onExcludeLargeCountriesChange={handleExcludeLargeCountriesChange}
            />
          </BoxControls>
        }
      >
        <div>
          <HighchartsReact
            options={options}
            constructorType="mapChart"
            highcharts={Highcharts}
          />
        </div>
      </Box>
    </>
  );
};

MhmCpF5.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
};

export default MhmCpF5;
