import { Typography } from "@material-ui/core";

const MenstrualHygieneTotalMenstruators = () => (
  <div>
    <Typography variant="h1">Total Menstruators</Typography>

    <p>
      We estimated the total number of menstruators in each country as all girls
      aged 12-15 years, and women of reproductive age (15-49) who menstruate
      (i.e. excluding pregnant and postpartum amenorrheic women).
    </p>
    <p>
      Estimates were made for each year from 2019 to 2030 based on UNPD
      population projections, and projections of the number of pregnant and
      postpartum amenorrheic women developed for LEAP. Additional analysis was
      conducted based on DHS data to estimate the number of postpartum
      amenorrheic women, relative to the number of pregnant women in each
      country. As fertility rates decline, a larger share of women of
      reproductive age will menstruate in a given year, and in areas of high
      fertility, many women do not menstruate because they are pregnant or
      postpartum amenorrheic.
    </p>
  </div>
);

export default MenstrualHygieneTotalMenstruators;
