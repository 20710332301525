import { useState } from "react";
import PropTypes from "prop-types";
import FpCpF5 from "../Figures/FpCpF5";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getMethodPlural,
  getMethodVerb,
  getQualitativeChangeDirection,
  getQuantitiesAndCostsMethodPlural,
} from "../../../../utilities";

const calculateMetrics = (incomeGroup) => {
  const initial = incomeGroup.years[0].value;
  const final = incomeGroup.years[2].value;
  const change = final - initial;

  return {
    final,
    change,
    percentageChange: (change / initial) * 100,
  };
};

const Users = (props) => (
  <>
    <p>
      The total number of contraceptive users using{" "}
      {getMethodPlural(props.methodName)} would{" "}
      {getQualitativeChangeDirection(props.metrics[3].percentageChange)} to{" "}
      {formatNumberAsHumanReadable(props.metrics[3].final)} in 2030 across all
      low- and middle-income countries (
      {formatNumberAsHumanReadable(props.metrics[3].change, {
        prefixWithSign: true,
      })}
      ,{" "}
      {formatPercentage(props.metrics[3].percentageChange, {
        prefixWithSign: true,
      })}
      ) .
    </p>

    <p>
      The change would vary across the three country income groups:{" "}
      {formatNumberAsHumanReadable(props.metrics[0].change, {
        prefixWithSign: true,
      })}{" "}
      (
      {formatPercentage(props.metrics[0].percentageChange, {
        prefixWithSign: true,
      })}
      ) in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.metrics[1].change, {
        prefixWithSign: true,
      })}{" "}
      (
      {formatPercentage(props.metrics[1].percentageChange, {
        prefixWithSign: true,
      })}
      ) in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.metrics[2].change, {
        prefixWithSign: true,
      })}{" "}
      (
      {formatPercentage(props.metrics[2].percentageChange, {
        prefixWithSign: true,
      })}
      ) in upper-middle-income countries.
    </p>
  </>
);

const Quantities = (props) => (
  <>
    <p>
      The total number of {getQuantitiesAndCostsMethodPlural(props.methodName)}{" "}
      {getMethodVerb(props.methodId)} would{" "}
      {getQualitativeChangeDirection(props.metrics[3].percentageChange)} to{" "}
      {formatNumberAsHumanReadable(props.metrics[3].final)} in 2030 across all
      low- and middle-income countries (
      {formatNumberAsHumanReadable(props.metrics[3].change, {
        prefixWithSign: true,
      })}
      ,{" "}
      {formatPercentage(props.metrics[3].percentageChange, {
        prefixWithSign: true,
      })}
      ).
    </p>
    <p>
      The change would vary across the three country income groups:{" "}
      {formatNumberAsHumanReadable(props.metrics[0].change, {
        prefixWithSign: true,
      })}{" "}
      (
      {formatPercentage(props.metrics[0].percentageChange, {
        prefixWithSign: true,
      })}
      ) in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.metrics[1].change, {
        prefixWithSign: true,
      })}{" "}
      (
      {formatPercentage(props.metrics[1].percentageChange, {
        prefixWithSign: true,
      })}
      ) in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.metrics[2].change, {
        prefixWithSign: true,
      })}{" "}
      (
      {formatPercentage(props.metrics[2].percentageChange, {
        prefixWithSign: true,
      })}
      ) in upper-middle-income countries.
    </p>
  </>
);

const Costs = (props) => (
  <>
    <p>
      The total supply cost of {getMethodPlural(props.methodName)}{" "}
      {getMethodVerb(props.methodId)} would{" "}
      {getQualitativeChangeDirection(props.metrics[3].percentageChange)} to{" "}
      {formatNumberAsHumanReadable(props.metrics[3].final, {
        prefix: "$",
      })}{" "}
      in 2030 across all low- and middle-income countries (
      {formatNumberAsHumanReadable(props.metrics[3].change, {
        prefix: "$",
        prefixWithSign: true,
      })}
      ,{" "}
      {formatPercentage(props.metrics[3].percentageChange, {
        prefixWithSign: true,
      })}
      ).
    </p>
    <p>
      The change would vary across the three country income groups:{" "}
      {formatNumberAsHumanReadable(props.metrics[0].change, {
        prefix: "$",
        prefixWithSign: true,
      })}{" "}
      (
      {formatPercentage(props.metrics[0].percentageChange, {
        prefixWithSign: true,
      })}
      ) in low-income countries,{" "}
      {formatNumberAsHumanReadable(props.metrics[1].change, {
        prefix: "$",
        prefixWithSign: true,
      })}{" "}
      (
      {formatPercentage(props.metrics[1].percentageChange, {
        prefixWithSign: true,
      })}
      ) in lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.metrics[2].change, {
        prefix: "$",
        prefixWithSign: true,
      })}{" "}
      (
      {formatPercentage(props.metrics[2].percentageChange, {
        prefixWithSign: true,
      })}
      ) in upper-middle-income countries.
    </p>
  </>
);

const Summary = (props) => {
  const metrics = props.data?.map((incomeGroup) =>
    calculateMetrics(incomeGroup)
  );

  switch (props.indicatorId) {
    case 5:
      return (
        <Users
          methodId={props.methodId}
          methodName={props.methodName}
          metrics={metrics}
        />
      );

    case 3:
      return (
        <Quantities
          methodId={props.methodId}
          methodName={props.methodName}
          metrics={metrics}
        />
      );

    case 1:
      return (
        <Costs
          methodId={props.methodId}
          methodName={props.methodName}
          metrics={metrics}
        />
      );

    default:
      return null;
  }
};

const FpCpS5 = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(5);

  const { areaData: data } = useAreaQuery(
    `/custom/fp/cp/figures/5/${props.methodId}/${selectedIndicatorId}`
  );

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  return (
    <>
      {data ? (
        <Summary
          indicatorId={selectedIndicatorId}
          methodId={props.methodId}
          methodName={props.methodName}
          data={data}
        />
      ) : (
        <div />
      )}

      <FpCpF5
        methodName={props.methodName}
        indicatorId={selectedIndicatorId}
        data={data?.slice(0, -1)}
        onIndicatorChange={handleIndicatorChange}
      />
    </>
  );
};

FpCpS5.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
};

export default FpCpS5;
