import PropTypes from "prop-types";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import MhCsT3 from "../Tables/MhCsT3";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  toLowerCase,
} from "../../../../utilities";

const MhCsS9 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/tables/3/${props.regionId}`
  );

  return (
    <>
      <p>
        Between 2019 and 2030, the total number of births would increase by{" "}
        {formatPercentage(data?.[0].percentageChange ?? 0)} in{" "}
        {toLowerCase(props.regionName)} (
        {formatNumberAsHumanReadable(data?.[0].change ?? 0, {
          prefixWithSign: true,
        })}
        ). This compares to an 11% increase across all low- and middle-income
        countries.
      </p>

      <MhCsT3 regionName={props.regionName} data={data} />
    </>
  );
};

MhCsS9.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsS9;
