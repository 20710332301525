import PropTypes from "prop-types";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import MhCsT2 from "../Tables/MhCsT2";

const MhCsS7 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/tables/2/${props.regionId}`
  );

  return (
    <>
      <p>
        Because the seven priority maternal drugs can play different therapeutic
        roles in different interventions, and coverage of services varies by
        intervention, there is considerable variation in the number of cases
        requiring and receiving each drug. Figures 1-5 illustrated the different
        coverage levels separately for each drug or group of drugs; Table 2
        brings this data together to compare the number of cases for each of the
        priority maternal health drugs.
      </p>

      <MhCsT2 regionName={props.regionName} data={data} />

      <p>
        Note: in this table cases needing and receiving the antihypertensive
        drugs (hydralazine and methyldopa) are presented summed together and
        cases receiving out-of-scope drugs excluded from the "Needed: Received"
        total; and cases needing and receiving uterotonics (misoprostol and
        oxytocin) are presented summed together.
      </p>
    </>
  );
};

MhCsS7.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsS7;
