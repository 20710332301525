import PropTypes from "prop-types";
import MhmCcF7 from "../Figures/MhmCcF7";
import MhmCcF8 from "../Figures/MhmCcF8";
import MhmCsFutureCosts from "./MhmCsFutureCosts";

const MhmCcFutureCosts = (props) => (
  <>
    <MhmCsFutureCosts regionId={props.regionId} regionName={props.regionName} />

    <p>
      The pace of change differs by country, as shown in the figure below. Use
      the dropdowns to explore changes across scenarios and by product.
    </p>

    <MhmCcF7 regionId={props.regionId} regionName={props.regionName} />

    <p>
      Patterns in the cost purpose-made menstrual hygiene products vary across
      countries. Use the dropdown to explore differences by product between the
      three scenarios in 2030.
    </p>

    <MhmCcF8 regionId={props.regionId} regionName={props.regionName} />
  </>
);

MhmCcFutureCosts.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCcFutureCosts;
