import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Table from "../../../Common/Box/Table";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatNumber } from "../../../../utilities";

const key = "MH_CS_t4";
const number = 1;

const format = (n, options = {}) => {
  if (n == null) {
    return null;
  }

  return formatNumber(n, {
    significantDigits: 3,
    ...options,
  });
};

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& th:not(:first-child)": {
      width: "8rem",
    },
    "& .subtotal": {
      fontWeight: "bold",
    },
  },
}));

const MhCsT4 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/tables/4/${props.regionId}`
  );

  const classes = useStyles();

  return (
    <Table
      key={key}
      number={number.toString()}
      title="Total Cases Receiving Drugs, Quantities, and Costs"
      subtitle={`${props.regionName} by Priority Maternal Health Drug, 2019`}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th>Cases Receiving</th>
            <th>Quantities</th>
            <th>Costs</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((method) => (
              <tr key={method.name}>
                <td>{method.name}</td>
                {method.indicators.map((indicator) => (
                  <td key={indicator.name}>
                    {format(indicator.value, {
                      prefix: indicator.name === "Costs" ? "$" : undefined,
                    })}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </Table>
  );
};

MhCsT4.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsT4;
