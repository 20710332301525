import PropTypes from "prop-types";
import MhCcT2 from "../Tables/MhCcT2";

const MhCcS7 = (props) => (
  <>
    <p>
      Because the seven priority maternal drugs can play different therapeutic
      roles in different interventions, and coverage of services varies by
      intervention, there is considerable variation in the number of cases
      requiring and receiving each drug. Figures 1-5 illustrated the different
      coverage levels separately for each drug or group of drugs; Table 2 brings
      this data together to compare the number of cases for each of the priority
      maternal health drugs.
    </p>

    <MhCcT2
      regionalUnitId={props.regionalUnitId}
      regionalUnitName={props.regionalUnitName}
    />
  </>
);

MhCcS7.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcS7;
