import PropTypes from "prop-types";
import MhmCsF7 from "../Figures/MhmCsF7";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeChangeDirection,
  getQualitativeChangeMagnitude,
} from "../../../../utilities";

const calculateChange = (scenarioIndex, data) => {
  if (!data) {
    return {
      start: 0,
      end: 0,
      change: 0,
      percentageChange: 0,
    };
  }

  const years = data[scenarioIndex].years;

  const start = years[0].value;
  const end = years[2].value;
  const change = end - start;

  return {
    start,
    end,
    change,
    percentageChange: (change / start) * 100,
  };
};

const calculateMetrics = (data) => {
  if (!data) {
    return {
      disposable: Array(3).fill(calculateChange(0)),
      reusable: calculateChange(0),
    };
  }
  const disposableProducts = data.find(
    (method) => method.method === "Disposable"
  ).scenarios;

  const reusableProducts = data.find((method) => method.method === "Reusable")
    .scenarios;

  return {
    disposable: Array.from({ length: 3 }, (_, i) =>
      calculateChange(i, disposableProducts)
    ),
    reusable: calculateChange(2, reusableProducts),
  };
};

const Summary = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cs/figures/7/${props.regionId}`
  );

  const {
    disposable: disposableMetrics,
    reusable: reusableMetrics,
  } = calculateMetrics(data);

  return (
    <div>
      <p>Changes in costs would vary by product across the three scenarios:</p>
      <ul>
        <li>
          If current levels of use were maintained (Scenario 1), the cost of
          disposable products would see a{" "}
          {getQualitativeChangeMagnitude(disposableMetrics[0].percentageChange)}{" "}
          {getQualitativeChangeDirection(disposableMetrics[0].percentageChange)}{" "}
          from{" "}
          {formatNumberAsHumanReadable(disposableMetrics[0].start, {
            prefix: "$",
          })}{" "}
          in 2019 to{" "}
          {formatNumberAsHumanReadable(disposableMetrics[0].end, {
            prefix: "$",
          })}{" "}
          in 2030 (
          {formatNumberAsHumanReadable(disposableMetrics[0].change, {
            prefix: "$",
            prefixWithSign: true,
          })}
          ,{" "}
          {formatPercentage(disposableMetrics[0].percentageChange, {
            prefixWithSign: true,
          })}
          ).
        </li>
        <li>
          With an increase in use of purpose-made products but no change in the
          types of methods being used (Scenario 2) there would be a{" "}
          {getQualitativeChangeMagnitude(disposableMetrics[1].percentageChange)}{" "}
          {getQualitativeChangeDirection(disposableMetrics[1].percentageChange)}{" "}
          in the cost of disposable products from{" "}
          {formatNumberAsHumanReadable(disposableMetrics[1].start, {
            prefix: "$",
          })}{" "}
          in 2019 to{" "}
          {formatNumberAsHumanReadable(disposableMetrics[1].end, {
            prefix: "$",
          })}{" "}
          in 2030 (
          {formatNumberAsHumanReadable(disposableMetrics[1].change, {
            prefix: "$",
            prefixWithSign: true,
          })}
          ,{" "}
          {formatPercentage(disposableMetrics[1].percentageChange, {
            prefixWithSign: true,
          })}
          ).
        </li>
        <li>
          With an increase in the use of purpose-made products as well as an
          increase in the use of reusables, there would be a{" "}
          {getQualitativeChangeMagnitude(disposableMetrics[2].percentageChange)}{" "}
          {getQualitativeChangeDirection(disposableMetrics[2].percentageChange)}{" "}
          in the cost of disposable products from{" "}
          {formatNumberAsHumanReadable(disposableMetrics[2].start, {
            prefix: "$",
          })}{" "}
          in 2019 to{" "}
          {formatNumberAsHumanReadable(disposableMetrics[2].end, {
            prefix: "$",
          })}{" "}
          in 2030 (
          {formatNumberAsHumanReadable(disposableMetrics[2].change, {
            prefix: "$",
            prefixWithSign: true,
          })}
          ,{" "}
          {formatPercentage(disposableMetrics[2].percentageChange, {
            prefixWithSign: true,
          })}
          ) and a{" "}
          {getQualitativeChangeMagnitude(reusableMetrics.percentageChange)}{" "}
          {getQualitativeChangeDirection(reusableMetrics.percentageChange)} in
          the cost of reusable products from{" "}
          {formatNumberAsHumanReadable(reusableMetrics.start, {
            prefix: "$",
          })}{" "}
          in 2019 to{" "}
          {formatNumberAsHumanReadable(reusableMetrics.end, {
            prefix: "$",
          })}{" "}
          in 2030 (
          {formatNumberAsHumanReadable(reusableMetrics.change, {
            prefix: "$",
            prefixWithSign: true,
          })}
          ,{" "}
          {formatPercentage(reusableMetrics.percentageChange, {
            prefixWithSign: true,
          })}
          ).
        </li>
      </ul>
    </div>
  );
};

const MhmCsFutureCostsByMethod = (props) => {
  return (
    <div>
      <Summary regionId={props.regionId} />

      <MhmCsF7 regionId={props.regionId} regionName={props.regionName} />
    </div>
  );
};

MhmCsFutureCostsByMethod.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCsFutureCostsByMethod;
