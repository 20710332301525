import PropTypes from "prop-types";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatPercentage,
  getQualitativeChangeDirection,
} from "../../../../utilities";
import MhCcT3 from "../Tables/MhCcT3";

const calculateMetrics = (data) => {
  const percentageChanges = data?.map(
    ({ percentageChange }) => percentageChange
  );

  return {
    minimumPercentageChange: percentageChanges
      ? Math.min(...percentageChanges)
      : 0,
    maximumPercentageChange: percentageChanges
      ? Math.max(...percentageChanges)
      : 0,
  };
};

const MhCcS9 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cc/tables/3/${props.regionalUnitId}`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        Between 2019 and 2030, the total number of births would increase by 11%
        in low- and middle-income countries if changes in contraceptive use and
        abortion follow the trajectories established for the other sections of
        this LEAP analysis.
      </p>

      <p>
        This ranges from a{" "}
        {getQualitativeChangeDirection(metrics.minimumPercentageChange)} of{" "}
        {formatPercentage(Math.abs(metrics.minimumPercentageChange))} to an{" "}
        {getQualitativeChangeDirection(metrics.maximumPercentageChange)} of{" "}
        {formatPercentage(Math.abs(metrics.maximumPercentageChange))} across{" "}
        {props.regionalUnitName}.
      </p>

      <MhCcT3 regionalUnitName={props.regionalUnitName} data={data} />
    </>
  );
};

MhCcS9.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcS9;
