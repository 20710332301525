import PropTypes from "prop-types";
import SideBySide from "../../../Common/SideBySide";
import FpCsF1 from "../Figures/FpCsF1";
import FpCsF2 from "../Figures/FpCsF2";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  toLowerCase,
} from "../../../../utilities";

const Summary1 = (props) => {
  const usersOfContraception = props.data.reduce(
    (accumulator, method) =>
      accumulator +
      method.sectors.reduce(
        (accumulator, sector) => accumulator + sector.value,
        0
      ),
    0
  );

  const usersOfPublicSectorContraception = props.data.reduce(
    (accumulator, method) => accumulator + method.sectors[0].value,
    0
  );

  const methodShares = props.data
    .map((method) => {
      const usersOfMethod = method.sectors.reduce(
        (accumulator, sector) => accumulator + sector.value,
        0
      );

      return {
        ...method,
        percentage: (usersOfMethod / usersOfContraception) * 100,
      };
    })
    .sort((a, b) => b.percentage - a.percentage);

  const mostCommonMethod = methodShares[0];

  const publicSectorShare =
    (usersOfPublicSectorContraception / usersOfContraception) * 100;

  let publicSectorShareDescription;

  if (publicSectorShare > 55) {
    publicSectorShareDescription = `most contraceptive users get their method from the public sector (${formatPercentage(
      publicSectorShare
    )})`;
  } else if (publicSectorShare > 45) {
    publicSectorShareDescription = `contraceptive users are fairly evenly split between the public and priavte sector (${formatPercentage(
      publicSectorShare
    )}, ${formatPercentage(100 - publicSectorShare)})`;
  } else {
    publicSectorShareDescription = `most contraceptive users get their method from the private sector (${formatPercentage(
      100 - publicSectorShare
    )})`;
  }

  return (
    <>
      <p>In {toLowerCase(props.regionName)}:</p>

      <ul>
        <li>
          There are {formatNumberAsHumanReadable(usersOfContraception)} users of
          modern contraception,
        </li>
        <li>
          the most commonly used method is {mostCommonMethod.name.toLowerCase()}{" "}
          accounting for {formatPercentage(mostCommonMethod.percentage)} of use,
        </li>
        <li>{publicSectorShareDescription}.</li>
      </ul>
    </>
  );
};

const Summary2 = (props) => {
  const isLongActingMethod = (method) =>
    ["Sterilization", "Implant", "IUD"].includes(method);

  const publicSectorUsers = props.data.reduce(
    (accumulator, method) => accumulator + method.sectors[0].value,
    0
  );

  const privateSectorUsers = props.data.reduce(
    (accumulator, method) =>
      accumulator + method.sectors[1].value + method.sectors[2].value,
    0
  );

  const publicSectorLongActingMethodUsers = props.data
    .filter(({ name }) => isLongActingMethod(name))
    .reduce((accumulator, method) => accumulator + method.sectors[0].value, 0);

  const privateSectorLongActingMethodUsers = props.data
    .filter(({ name }) => !isLongActingMethod(name))
    .reduce(
      (accumulator, method) =>
        accumulator + method.sectors[1].value + method.sectors[2].value,
      0
    );

  return (
    <p>
      In {toLowerCase(props.regionName)}{" "}
      {formatPercentage(
        (publicSectorLongActingMethodUsers / publicSectorUsers) * 100
      )}{" "}
      of public sector users rely on long-acting and permanent methods. In the
      private sector, by contrast,{" "}
      {formatPercentage(
        (privateSectorLongActingMethodUsers / privateSectorUsers) * 100
      )}{" "}
      of users use short-acting methods such as pills, injectables, and condoms.
    </p>
  );
};

const Summary3 = (props) => {
  const privateSectorUsers = props.data.reduce(
    (accumulator, method) =>
      accumulator + method.sectors[1].value + method.sectors[2].value,
    0
  );

  const subsidizedProductUsers = props.data.reduce(
    (accumulator, method) => accumulator + method.sectors[1].value,
    0
  );

  return (
    <p>
      In {toLowerCase(props.regionName)} an estimated{" "}
      {formatPercentage((subsidizedProductUsers / privateSectorUsers) * 100)} of
      users getting their method from the private sector are accessing
      subsidized products.
    </p>
  );
};

const FpCsCurrentUse = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/fp/cs/figures/1/${props.regionId}/5`
  );

  return (
    <>
      <SideBySide
        component={
          <FpCsF1
            regionId={props.regionId}
            regionName={props.regionName}
            data={data}
          />
        }
      >
        {data ? (
          <>
            <Summary1 regionName={props.regionName} data={data} />

            <p>
              There are often distinct differences in the method mix among users
              who obtain their method from the public sector and those who
              obtain their method from the private sector.
            </p>

            <Summary2 regionName={props.regionName} data={data} />

            <p>
              Contrasting the public and private sectors, method by method
              (Figure 2), provides a clearer picture of the distinct role each
              sector plays.
            </p>
          </>
        ) : (
          <div />
        )}
      </SideBySide>

      <SideBySide
        component={
          <FpCsF2
            regionId={props.regionId}
            regionName={props.regionName}
            data={data}
          />
        }
        componentPosition="right"
      >
        {data ? (
          <>
            <p>
              In Figure 2, and elsewhere in this report, the private sector is
              further subdivided based on the role of subsidy. Many of the
              products sold through private sector outlets are procured (in
              whole or in part) with public sector resources allowing them to be
              sold at below-market prices. Accounting for this public
              contribution is especially critical to understanding the relative
              contribution of public sector partners such as social marketing
              organizations (SMOs) which often, though not always, benefit from
              such subsidies as opposed to the purely commercial actors.
            </p>

            {data && <Summary3 regionName={props.regionName} data={data} />}
          </>
        ) : (
          <div />
        )}
      </SideBySide>
    </>
  );
};

FpCsCurrentUse.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default FpCsCurrentUse;
