import { useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CustomReport from "../CustomReport";
import RegionalUnitChooser from "../RegionalUnitChooser";
import { getRegionalUnitName } from "../../../utilities";
import AbortionIntroduction from "./AbortionIntroduction";
import ApacCcB1 from "./Boxes/ApacCcB1";
import ApacCcS1 from "./Summaries/ApacCcS1";
import ApacCcS2 from "./Summaries/ApacCcS2";
import ApacCcS3 from "./Summaries/ApacCcS3";
import ApacCcS4 from "./Summaries/ApacCcS4";
import ApacCcS5 from "./Summaries/ApacCcS5";
import ApacCcS6 from "./Summaries/ApacCcS6";
import ApacCcS7 from "./Summaries/ApacCcS7";
import ApacCcS8 from "./Summaries/ApacCcS8";
import ApacCcS9 from "./Summaries/ApacCcS9";
import AbortionScenarios from "./AbortionScenarios";

const useStyles = makeStyles((theme) => ({
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
}));

const AbortionComparison = () => {
  const [selectedRegionalUnitId, setSelectedRegionalUnitId] = useState(0);

  const classes = useStyles();

  const selectedRegionalUnitName = getRegionalUnitName(
    selectedRegionalUnitId,
    true
  );

  const handleRegionalUnitChange = (regionalUnitId) => {
    setSelectedRegionalUnitId(regionalUnitId);
  };

  return (
    <CustomReport>
      <Typography variant="h1">
        Abortion and PAC Comparison Across {selectedRegionalUnitName}
      </Typography>

      <RegionalUnitChooser
        value={selectedRegionalUnitId}
        onChange={handleRegionalUnitChange}
      />

      <section id="introduction">
        <Typography variant="h2">Background</Typography>

        <div className={classes.boxes}>
          <AbortionIntroduction>
            The <strong>Abortion &amp; PAC Regional Comparison Report</strong>{" "}
            provides regional estimates on the number of abortion and PAC
            services provided, the quantities of commodities, and the associated
            supply costs by region for the selected regional breakdown.
          </AbortionIntroduction>

          <ApacCcB1 regionId={selectedRegionalUnitId} />
        </div>
      </section>

      <section id="current">
        <Typography variant="h2">Where We Are Now</Typography>

        <div className={classes.boxes}>
          <Typography variant="h6">
            Current Number of Abortion and PAC Services Across{" "}
            {selectedRegionalUnitName}
          </Typography>

          <ApacCcS1
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <ApacCcS2
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <Typography variant="h6">
            Current Costs of Abortion and PAC Supplies Across{" "}
            {selectedRegionalUnitName}
          </Typography>

          <p>
            We analyze the cost of the supplies used to provide abortion and PAC
            services, and not any associated fees. Costs for surgical abortion
            (vacuum aspiration) are based on the costs of manual vacuum
            aspiration (MVA) kits with aspirator and cannulae plus one dose of
            misoprostol, while costs for medical abortion are based on costs of
            the drugs (misoprostol with or without mifepristone; see the
            Reader's Guide for more detail). These costs are generally paid by
            the consumer (i.e. the person seeking an abortion), though
            PAC-related costs may be more likely to be covered by public health
            systems.
          </p>

          <ApacCcS3
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />
        </div>
      </section>

      <section id="future">
        <Typography variant="h2">Changes Ahead</Typography>

        <div className={classes.boxes}>
          <AbortionScenarios />

          <Typography variant="h6">
            Future Number of Abortion and PAC Services Across{" "}
            {selectedRegionalUnitName}
          </Typography>

          <ApacCcS4
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <ApacCcS5
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <ApacCcS6
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <ApacCcS7
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <Typography variant="h6">
            Future Costs of Abortion and PAC Supplies Across{" "}
            {selectedRegionalUnitName}
          </Typography>

          <ApacCcS8
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <ApacCcS9
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />
        </div>
      </section>
    </CustomReport>
  );
};

export default AbortionComparison;
