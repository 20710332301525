import { Typography } from "@material-ui/core";

const MenstrualHygieneMenstruatorsByProductType = () => (
  <div>
    <Typography variant="h1">Menstruators by Product Type</Typography>

    <p>
      We based the proportion of menstruators using purpose-made products on
      data from household surveys (MICS, PMA, and DHS). Country-specific data
      were available for 42 countries. We summarized the urban and rural
      averages for these data by six geographic regions (see below) and the
      three income groups (low, lower-middle and upper-middle). These data were
      then used to construct estimates for the remaining countries based on
      their geographic region, income group, and urban/rural split.
    </p>

    <Typography variant="h6">Regions</Typography>

    <ol>
      <li>East Asia &amp; Pacific</li>
      <li>Europe &amp; Central Asia</li>
      <li>Latin America &amp; Caribbean</li>
      <li>Middle East &amp; North Africa</li>
      <li>South Asia</li>
      <li>Sub-Saharan Africa</li>
    </ol>
  </div>
);

export default MenstrualHygieneMenstruatorsByProductType;
