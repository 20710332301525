import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const ContraceptionIntroduction = ({ children }) => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <>
      <p>
        In the ten years since the London Family Planning Summit, the global
        community has witnessed a renewed focus on meeting the family planning
        needs of women and men in low- and middle-income countries. The call for
        &quot;universal access to sexual and reproductive health-care services,
        including family planning&quot; within the Sustainable Development Goals
        <Footnote number={1}>
          <ExternalLink
            href="https://sdgs.un.org/goals/goal3"
            text="Sustainable Development Goal 3.7"
            useItalics={true}
          />
          : By 2030, achieve access to adequate and equitable sanitation and
          hygiene for all and end open defecation, paying special attention to
          the needs of women and girls and those in vulnerable situations.
        </Footnote>
         has catalyzed efforts to improve access to high-quality and affordable
        contraceptives. The goal of achieving universal access will require
        commitment and investment on all fronts—and in particular the delivery
        of quality-assured contraceptives required to meet women&apos;s diverse
        needs and preferences. The type of contraceptive will be determined by
        the decisions of individual women and couples and/or by those entities
        that buy supplies on their behalf. And of course, the availability,
        affordability, and acceptability of contraceptives will also shape the
        future market for them. As with past Commodity Gap Analysis reports,
        this LEAP analysis provides rich findings to help inform discussions
        about the future of contraceptive supplies. This report does not hold
        all the answers but can help highlight challenges and spark discussions.
      </p>
      <p>{children}</p>
      <p>
        The &apos;unit&apos; of analysis for contraception in LEAP is a user
        defined as a person using a modern contraceptive method. While in some
        places in this report we use the term &apos;women and girls&apos; for
        readability purposes, we recognize that some users may identify as
        transgender men and non-binary people. The &apos;cost&apos; reflects the
        last price paid for a contraceptive before it is actually used or
        obtained by the user. We employ this definition because it is at this
        &quot;handover&quot; point in a product&apos;s value chain where price
        acts as the principal cost barrier to access. For products made
        available through the public sector, cost is calculated on the basis of
        unit costs paid by procurers (Governments and/or donors) before it is
        distributed, generally for free, to users. In the private sector, costs
        reflect the prices paid by users themselves, typically through direct
        out-of-pocket expenditures.
      </p>
      <p>
        For information across all low- and middle-income countries, please
        refer to the{" "}
        <RouterLink to="/contraception">
          Contraception Landscape Report
        </RouterLink>
        . Additional methodological details can also be found in the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-scope")}
        >
          Reader&apos;s Guide
        </Link>
        .
      </p>
    </>
  );
};

ContraceptionIntroduction.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ContraceptionIntroduction;
