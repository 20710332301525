import { useState } from "react";
import PropTypes from "prop-types";
import { getIndicatorDetails } from "../../../../utilities";
import MhCpF2 from "../Figures/MhCpF2";

const MhCpS2 = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(4);

  const indicatorDetails = getIndicatorDetails(selectedIndicatorId);

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  return (
    <>
      <p>
        The distribution of {indicatorDetails.name.toLowerCase()} across{" "}
        {props.regionalUnitName} can be seen in Figure 2. Use the dropdown to
        explore other indicators.
      </p>

      <MhCpF2
        methodId={props.methodId}
        methodName={props.methodName}
        regionalUnitId={props.regionalUnitId}
        regionalUnitName={props.regionalUnitName}
        indicatorId={selectedIndicatorId}
        onIndicatorChange={handleIndicatorChange}
      />
    </>
  );
};

MhCpS2.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCpS2;
