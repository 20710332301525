import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    marginTop: theme.spacing(),
    "&:not(last-child)": {
      marginBottom: theme.spacing(2),
    },
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      verticalAlign: "top",
    },
    "& td:first-child": {
      whiteSpace: "nowrap",
    },
    "& td > div:not(last-child)": {
      marginBottom: theme.spacing(),
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
  },
}));

const Definitions = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h1">Definitions</Typography>

      <Typography variant="h2">Key Terms</Typography>

      <p>
        For each health area, LEAP looks at the utilization of the specified
        services and supplies (the &apos;unit&apos;), the volume of supplies
        used (the &apos;quantities&apos;), and the associated costs. Due to
        differences across the four health areas, units and quantities are
        defined slightly differently for each health area as shown in the table
        below.
      </p>
      <p>
        For costs, LEAP presents costs in current US dollars for all health
        areas, holding unit prices constant into the future (i.e. no adjustments
        for potential future inflation are made). We use the term
        &apos;cost&apos; throughout LEAP, however what is included in costs and
        what perspective it represents is different&mdash;as shown below.
      </p>
      <p>
        The tables below show how units, quantities and costs are defined for
        each health area.
      </p>

      <Typography variant="h6">Unit Defintions</Typography>

      <table className={classes.table}>
        <thead>
          <tr>
            <th>Health Area</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Contraception</td>
            <td>
              The main unit of analysis is a &apos;user&apos;, defined as a
              person using a modern contraceptive method (see Contraception
              section below for full list of methods included).
            </td>
          </tr>
          <tr>
            <td>Menstrual Hygiene</td>
            <td>
              The main unit of analysis is a &apos;menstruator&apos;, defined as
              a person who has menstrual cycles in the current year. This
              encompasses women from age 12 (the average onset of menses) to age
              49, but excludes women who are pregnant or postpartum amenorrheic.
            </td>
          </tr>
          <tr>
            <td>Abortion and PAC</td>
            <td>
              <div>
                The main unit of analysis is a &apos;service&apos;, defined
                either as an abortion service to end a pregnancy or a post
                abortion care (PAC) service to complete an incomplete abortion
                or miscarriage.
              </div>
              <div>
                &apos;Service&apos; allows us to account for when one pregnant
                woman may receive multiple services, for example when an
                abortion also requires PAC to complete the abortion.
              </div>
            </td>
          </tr>
          <tr>
            <td>Maternal Health</td>
            <td>
              The main unit of analysis is a &apos;case&apos; defined as an
              intervention to prevent, manage or treat complications during
              pregnancy and childbirth that uses one of the maternal health
              drugs under review (see Maternal Health Scope below). Using
              &apos;case&apos; as the unit of analysis allows for the fact that
              one pregnant woman may receive multiple interventions that use the
              same drug, a case can include multiple doses of a drug, and not
              everyone who needs an intervention receives it. We split cases
              into those that needed but did not receive a drug and those that
              needed and received a drug.
            </td>
          </tr>
        </tbody>
      </table>

      <Typography variant="h6">Quantities Defintions</Typography>

      <table className={classes.table}>
        <thead>
          <tr>
            <th>Health Area</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Contraception</td>
            <td>
              The quantities are the contraceptive commodities and associated
              clinical supplies (referred to collectively as
              &apos;supplies&apos;) that a user of contraception must personally
              use over the course of a year to avoid becoming pregnant. For
              long-acting and permanent methods only a subset of users will
              require a supply in a given year, while for short-acting methods
              multiple supplies are needed over a year.
            </td>
          </tr>
          <tr>
            <td>Menstrual Hygiene</td>
            <td>
              The quantities are the menstrual hygiene &apos;products&apos; used
              by menstruators over the course of a year. We include products
              that are purpose-made to absorb or collect menstrual blood. This
              includes products that are disposable (e.g. pads and tampons) and
              reusable (e.g. reusable pads and cups). Our quantity estimates
              account for the duration of use of reusable products.
            </td>
          </tr>
          <tr>
            <td>Abortion and PAC</td>
            <td>
              The quantities are the &apos;supplies&apos; needed for abortion
              and PAC services. This includes doses of misoprostol and
              mifepristone and units of manual vacuum aspiration (MVA) kits.
            </td>
          </tr>
          <tr>
            <td>Maternal Health</td>
            <td>
              The quantities are the number of drugs required to fully complete
              an intervention, multiplied by the number of cases receiving that
              intervention. For each case, the number of drugs received will
              differ depending on the intervention for which they are used.
            </td>
          </tr>
        </tbody>
      </table>

      <Typography variant="h6">Cost Defintions</Typography>

      <table className={classes.table}>
        <thead>
          <tr>
            <th>Health Area</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Contraception</td>
            <td>
              The cost reflects the last price paid for a contraceptive before
              it is actually used or obtained by the user. We employ this
              definition because it is at this &quot;handover&quot; point in a
              product&apos;s value chain where price acts as the principal cost
              barrier to access. For products made available through the public
              sector, cost is calculated on the basis of unit costs paid by
              procurers (Governments and/or donors) before it is distributed,
              generally for free, to users. In the private sector, costs reflect
              the prices paid by users themselves, typically through direct
              out-of-pocket expenditures.
            </td>
          </tr>
          <tr>
            <td>Menstrual Hygiene</td>
            <td>
              The cost is the price paid by menstruators for menstrual hygiene
              products used in a given year. Costs are considered from the
              perspective of the consumer. In some contexts, products may be
              made freely available, for example distribution of pads in
              schools. However, limited data exists to provide visibility into
              who bears these costs.
            </td>
          </tr>
          <tr>
            <td>Abortion and PAC</td>
            <td>
              <div>
                The cost is the price of the supplies to provide the abortion or
                PAC service, and not any associated fees (e.g. to cover a health
                provider&apos;s time to deliver a service, or provide any pre-
                or post-service counselling).
              </div>
              <div>
                Costs for surgical abortion (vacuum aspiration) are based on the
                costs of manual vacuum aspiration (MVA) kits with aspirator and
                cannulae plus one dose of misoprostol, while costs for medical
                abortion are based on costs of the drugs (misoprostol with or
                without mifepristone). These costs are generally paid by the
                consumer (i.e. the person seeking an abortion), though
                PAC-related costs may be more likely to be covered by public
                health systems.
              </div>
            </td>
          </tr>
          <tr>
            <td>Maternal Health</td>
            <td>
              The cost is the cost of the drug(s) used for an intervention. We
              determine cost based on the price paid by governments or donors to
              procure supplies for public sector distribution (generally at no
              charge to the user). We do not include in our cost figures:
              products that were purchased but never used; the costs that would
              be associated with women who need but don&apos;t receive a drug;
              or other associated costs such as labor, health infrastructure,
              and medical equipment.
            </td>
          </tr>
        </tbody>
      </table>

      <Typography variant="h2">Interpretations</Typography>

      <p>
        While sometimes LEAP refers to &apos;women and girls&apos; for
        readability purposes, we recognize that some people who use
        contraception, menstruate or access abortion, PAC or maternal health
        services identify as transgender men or non-binary people rather than a
        woman or a girl.
      </p>
      <p>
        LEAP presents the quantities and associated costs of supplies that are
        utilized by contraceptive users, menstruators, and those accessing
        abortion, PAC, or maternal health services.
      </p>
      <p>
        The costs presented in LEAP reflect the supplies used by individuals in
        a given year, not what is necessarily bought or &apos;procured&apos; in
        that year, as the latter, often referred to as &apos;procurement
        costs&apos;, are typically a function of broader programmatic
        considerations, such as availability (or lack thereof) of resources; the
        desire to fill supply pipelines and maintain adequate inventory levels,
        or simply the volumes of product already in the supply chain.
      </p>
      <p>
        In addition, LEAP only costs the supplies themselves and not the cost of
        skilled providers and clean clinical spaces that may be needed to
        provide some services. While important, these are beyond the scope of
        LEAP.
      </p>
    </div>
  );
};

export default Definitions;
