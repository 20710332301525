import Figure from "../../Common/Box/Figure";
import ExternalLink from "../../Common/ExternalLink";
import { formatNumber } from "../../../utilities";

const chartOptions = (theme) => ({
  chart: {
    type: "column",
    width: 470,
    height: 300,
    backgroundColor: null,
  },
  xAxis: {
    categories: ["Low", "Lower-Middle", "Upper-Middle"],
    title: undefined,
    labels: {
      autoRotation: undefined,
      useHTML: true,
      style: {
        color: theme.figure.axis.primary,
        fontSize: theme.figure.axis.fontSize,
      },
    },
  },
  yAxis: {
    title: undefined,
    reversedStacks: false,
    labels: {
      format: "{value}%",
      style: {
        color: theme.figure.axis.secondary,
        fontSize: theme.figure.axis.fontSize,
      },
    },
  },
  plotOptions: {
    column: {
      stacking: "percent",
      pointWidth: 30,
      borderWidth: 0,
    },
  },
  tooltip: {
    backgroundColor: "white",
    useHTML: true,
    outside: true,
    formatter: function () {
      return `Share: <strong>${formatNumber(this.y, {
        decimalPlaces: 0,
      })}%</strong>`;
    },
    style: {
      fontSize: "0.875rem",
      color: theme.palette.tooltip.primary,
    },
  },
  exporting: {
    chartOptions: {
      title: undefined,
      subtitle: undefined,
    },
    enabled: false,
  },
  series: [
    {
      name: "Safe",
      data: [21.8, 42.3, 67.1],
      color: "#5C9CB6",
    },
    {
      name: "Less safe",
      data: [24.4, 37.9, 27.8],
      color: "#8EBACC",
    },
    {
      name: "Least safe",
      data: [53.8, 19.7, 5.1],
      color: "#C5DCE5",
    },
  ],
});

const Component = () => (
  <Figure
    id="APAC_b1"
    number="1"
    title="Abortion Safety Profile"
    subtitle="By Income Group"
    source={
      <div>
        Source:{" "}
        <ExternalLink
          href="https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(17)31794-4/fulltext"
          text="Ganatra et al 2017"
        />{" "}
        Global, regional, and subregional classification of abortions by safety,
        2010–14: estimates from a Bayesian hierarchical model. Lancet 2017 Nov
        25;390(10110):2372-238
      </div>
    }
    chartOptions={chartOptions}
  />
);

export default Component;
