import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import SideBySide from "../../../Common/SideBySide";
import FpCsF3 from "../Figures/FpCsF3";
import FpCsF4 from "../Figures/FpCsF4";
import FpCsF5 from "../Figures/FpCsF5";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { useReadersGuide } from "../../../../hooks/useReadersGuide";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  toLowerCase,
} from "../../../../utilities";

const Summary1 = (props) => {
  const costOfContraception = props.data.reduce(
    (accumulator, method) =>
      accumulator +
      method.sectors.reduce(
        (accumulator, sector) => accumulator + sector.value,
        0
      ),
    0
  );

  const costOfPublicSectorContraception = props.data.reduce(
    (accumulator, method) => accumulator + method.sectors[0].value,
    0
  );

  const publicSectorShare =
    (costOfPublicSectorContraception / costOfContraception) * 100;

  const methodShares = props.data
    .map((method) => {
      const costOfMethod = method.sectors.reduce(
        (accumulator, sector) => accumulator + sector.value,
        0
      );

      return {
        ...method,
        value: costOfMethod,
        percentage: (costOfMethod / costOfContraception) * 100,
      };
    })
    .sort((a, b) => b.percentage - a.percentage);

  const mostCommonMethod = methodShares[0];

  return (
    <>
      <p>
        The total current cost of contraceptive supplies used by women and girls
        in {toLowerCase(props.regionName)} is{" "}
        {formatNumberAsHumanReadable(costOfContraception, {
          prefix: "$",
        })}
        . The largest share of costs comes from the{" "}
        {publicSectorShare >= 50 ? "public" : "private"} sector (
        {formatPercentage(
          publicSectorShare >= 50 ? publicSectorShare : 100 - publicSectorShare
        )}
        ).
      </p>

      <p>
        Costs are not evenly distributed across methods; the cost of{" "}
        {mostCommonMethod.name.toLowerCase()} accounts for the largest share of
        supply costs. At ${formatNumberAsHumanReadable(mostCommonMethod.value)}{" "}
        this method accounts for {formatPercentage(mostCommonMethod.percentage)}{" "}
        of the total supply cost.
      </p>
    </>
  );
};

const Summary2 = (props) => {
  const costOfContraception = props.costs.reduce(
    (accumulator, method) =>
      accumulator +
      method.sectors.reduce(
        (accumulator, sector) => accumulator + sector.value,
        0
      ),
    0
  );

  const methodCostShares = props.costs
    .map((method) => {
      const costOfMethod = method.sectors.reduce(
        (accumulator, sector) => accumulator + sector.value,
        0
      );

      return {
        ...method,
        percentage: (costOfMethod / costOfContraception) * 100,
      };
    })
    .sort((a, b) => b.percentage - a.percentage);

  const highestCostMethod = methodCostShares[0];

  const usersOfContraception = props.users.reduce(
    (accumulator, method) =>
      accumulator +
      method.sectors.reduce(
        (accumulator, sector) => accumulator + sector.value,
        0
      ),
    0
  );

  const methodUserShares = props.users
    .map((method) => {
      const usersOfMethod = method.sectors.reduce(
        (accumulator, sector) => accumulator + sector.value,
        0
      );

      return {
        ...method,
        percentage: (usersOfMethod / usersOfContraception) * 100,
      };
    })
    .sort((a, b) => b.percentage - a.percentage);

  const mostCommonMethod = methodUserShares[0];

  return highestCostMethod.name === mostCommonMethod.name ? (
    <div>
      In {toLowerCase(props.regionName)}, the same method accounts for both the
      largest share of users ({highestCostMethod.name.toLowerCase()},{" "}
      {formatPercentage(mostCommonMethod.percentage)}) and costs (
      {formatPercentage(highestCostMethod.percentage)})
    </div>
  ) : (
    <div>
      In {toLowerCase(props.regionName)}, a different method accounts for the
      largest share of users ({mostCommonMethod.name.toLowerCase()},{" "}
      {formatPercentage(mostCommonMethod.percentage)}) and costs (
      {highestCostMethod.name.toLowerCase()},{" "}
      {formatPercentage(highestCostMethod.percentage)})
    </div>
  );
};

const FpCsCurrentCosts = (props) => {
  const [selectedSectorId, setSelectedSectorId] = useState(0);

  const { areaData: costs } = useAreaQuery(
    `/custom/fp/cs/figures/1/${props.regionId}/1`
  );

  const { areaData: users } = useAreaQuery(
    `/custom/fp/cs/figures/1/${props.regionId}/5`
  );

  const readersGuide = useReadersGuide();

  const handleSectorChange = (sectorId) => {
    setSelectedSectorId(sectorId);
  };

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <>
      <p>
        We define &quot;cost&quot; as the last price paid for a contraceptive
        method before it is actually used or obtained by the consumer. We employ
        this definition because it is at this &quot;handover&quot; point in a
        product&apos;s value chain where price acts as the principal cost
        barrier to access. For products made available through the public
        sector, cost is calculated on the basis of unit costs paid by procurers
        (governments and/or donors) before it is distributed, generally for
        free, to users. In the private sector, costs reflect the prices paid by
        users themselves, typically through direct out-of-pocket expenditures.
      </p>

      <p>
        See the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-costs")}
        >
          Reader&apos;s Guide
        </Link>{" "}
        for more details.
      </p>

      <SideBySide
        component={
          <FpCsF3
            regionId={props.regionId}
            regionName={props.regionName}
            data={costs}
          />
        }
      >
        {costs ? (
          <Summary1 regionName={props.regionName} data={costs} />
        ) : (
          <div />
        )}
      </SideBySide>

      <SideBySide
        component={
          <FpCsF4
            regionId={props.regionId}
            regionName={props.regionName}
            data={costs}
          />
        }
        componentPosition="right"
      >
        Just as the roles of the private sector differ for users of each method,
        so do they for costs. In many cases, a larger share of costs (as
        compared to users) come from the private sector, due to price
        differentials between supplies in the public and private sectors. Public
        sector costs below include the supply costs from users who go to the
        public sector, as well as the cost of the subsidy from private
        subsidized users. See more below on use versus cost.
      </SideBySide>

      {users && costs && (
        <SideBySide
          component={
            <FpCsF5
              regionId={props.regionId}
              regionName={props.regionName}
              sectorId={selectedSectorId}
              users={users}
              costs={costs}
              onSectorChange={handleSectorChange}
            />
          }
        >
          <p>
            The cost of contraceptive supplies is not simply a function of the
            number of contraceptive users. In some countries, the method mix of
            users and supply costs looks very similar, while in others stark
            contrasts are found.
          </p>
          <p>
            Similarities and differences between use and cost reflect
            differences in the methods used, the number of units required to
            achieve a year of protection, the sector in which the methods are
            obtained, and the relative cost of supplies in the public and
            private sector.
          </p>

          <Summary2 regionName={props.regionName} costs={costs} users={users} />
        </SideBySide>
      )}
    </>
  );
};

FpCsCurrentCosts.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default FpCsCurrentCosts;
