import { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Box from "../../../Common/Box/Box";
import Chart from "../../../Common/Box/Chart";
import DetailIndicator from "../../../Common/Box/DetailIndicator";
import Legend from "../../../Common/Box/Legend";
import { chartDownloadImageScaleFactor } from "../../../../constants";
import exportCharts from "../../../../export";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import theme from "../../../../theme";
import {
  capitalizePhrase,
  getTooltip,
  toTitleCase,
} from "../../../../utilities";

const key = "MHM_CS_f7";
const number = 7;

const getMaximumValue = (data) => {
  let maximumValue = 0;

  for (let method of data) {
    for (let scenario of method.scenarios)
      for (let year of scenario.years) {
        maximumValue = Math.max(maximumValue, year.value);
      }
  }

  return maximumValue;
};

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Maintain product use":
      return theme.palette.scenarios.scenario1;

    case "Increase purpose-made":
      return theme.palette.scenarios.scenario2;

    case "Increase purpose-made & reusable":
      return theme.palette.scenarios.scenario3;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => ({
  chart: {
    type: "spline",
    height: props.height,
    backgroundColor: null,
    width: props.showYAxis ? 236 : 180,
  },
  title: {
    text: toTitleCase(data.method === "Cups" ? "Reusable Cups" : data.method),
    align: "center",
    x: props.showYAxis ? 28 : undefined,
    style: {
      fontSize: props.titleFontSize ?? "18px",
    },
  },
  xAxis: {
    type: "datetime",
    min: 2017,
    max: 2032,
    tickPositions: [2019, 2025, 2030],
    tickLength: 0,
    labels: {
      formatter: function () {
        return [2019, 2025, 2030].includes(this.value) ? this.value : null;
      },
      style: {
        color: theme.figure.axis.secondary,
        fontSize: theme.figure.axis.fontSize,
      },
    },
  },
  yAxis: {
    title: undefined,
    min: 0,
    max: props.maximumValue,
    labels: {
      enabled: props.showYAxis,
      formatter: function () {
        return "$" + this.axis.defaultLabelFormatter.call(this);
      },
      style: {
        color: theme.figure.axis.secondary,
        fontSize: theme.figure.axis.fontSize,
      },
    },
  },
  plotOptions: {
    series: {
      lineWidth: 3,
      marker: {
        symbol: "circle",
        lineWidth: 3,
        fillColor: "white",
      },
      zoneAxis: "x",
      zones: [
        {
          value: 2025,
        },
        {
          dashStyle: "shortDot",
        },
      ],
    },
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    backgroundColor: "white",
    useHTML: true,
    outside: true,
    formatter: function () {
      return getTooltip("Cost", this.y, undefined, {
        prefix: "$",
      });
    },
    style: {
      fontSize: "0.875rem",
      color: theme.palette.tooltip.primary,
    },
  },
  exporting: {
    enabled: false,
  },
  series: data.scenarios.map((scenario) => {
    const color = getSeriesColor(scenario.name, theme);

    return {
      ...scenario,
      color,
      marker: {
        lineColor: color,
      },
      data: scenario.years.map((year) => [year.year, year.value]),
    };
  }),
});

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: "center",
    display: "flex",
  },
  detailIndicator: {
    margin: theme.spacing(0, 1),
  },
  facetPanel: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(2, 0),
  },
  legend: {
    marginLeft: theme.spacing(4),
  },
}));

const MhmCsF7 = (props) => {
  const highchartRefs = [useRef(), useRef(), useRef(), useRef()];

  const { areaData } = useAreaQuery(
    `/custom/mhm/cs/figures/7/${props.regionId}`
  );

  const classes = useStyles();

  const data = areaData || [];

  const yAxisMaximum = getMaximumValue(data.slice(0, 2)) * 1.02;
  const reusableYAxisMaximum = getMaximumValue(data.slice(2, 4)) * 1.02;

  return (
    <Box
      id={key}
      caption={`Figure ${number}`}
      title="Change in Cost of Purpose-Made Menstrual Hygiene Products"
      subtitle={`${capitalizePhrase(
        props.regionName
      )} by Future Scenario and Product Type, 2019-2030`}
      onDownloadPng={() => {
        exportCharts(
          {
            chart1: highchartRefs[0].current,
            chart2: highchartRefs[1].current,
            chart3: highchartRefs[2].current,
            chart4: highchartRefs[3].current,
          },
          {
            filename: key,
            scale: chartDownloadImageScaleFactor,
          }
        );
      }}
    >
      <div className={classes.content}>
        <div className={classes.facetPanel}>
          {data.slice(0, 2).map((method, index) => (
            <Chart
              key={method.method}
              highchartRef={highchartRefs[index]}
              options={options(method, {
                height: 350,
                maximumValue: yAxisMaximum,
                showYAxis: index === 0,
              })}
            />
          ))}

          <div className={classes.detailIndicator}>
            <DetailIndicator />
          </div>

          {data.slice(2, 4).map((method, index) => (
            <div key={method.method} className={classes.facet}>
              <Chart
                highchartRef={highchartRefs[index + 2]}
                options={options(method, {
                  height: 280,
                  maximumValue: reusableYAxisMaximum,
                  showYAxis: index === 0,
                  titleFontSize: "0.875rem",
                })}
              />
            </div>
          ))}
        </div>

        <div className={classes.legend}>
          <Legend
            series={[
              {
                name: "3: Increase purpose-made & reusable",
                color: getSeriesColor(
                  "Increase purpose-made & reusable",
                  theme
                ),
              },
              {
                name: "2: Increase purpose-made",
                color: getSeriesColor("Increase purpose-made", theme),
              },
              {
                name: "1: Maintain product use",
                color: getSeriesColor("Maintain product use", theme),
              },
            ]}
            markerStyle="point"
          />
        </div>
      </div>
    </Box>
  );
};

MhmCsF7.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCsF7;
