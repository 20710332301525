import { Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";

const Timeframe = () => (
  <div>
    <Typography variant="h1">Timeframe</Typography>

    <p>
      The analysis for LEAP covers 2019 to 2030. We used 2019 as the baseline
      year for exploring the &quot;current&quot; landscape for reproductive
      health supplies due to the uncertainties of the impacts of the global
      COVID-19 pandemic in 2020. As this work is primarily concerned with
      looking far into the future, we did not attempt to capture the impacts of
      the COVID-19 pandemic. Other work is underway to understand the potential
      impacts of COVID-19 on both health services
      <Footnote number={5}>
        For example see: Walker D, Krubiner C and Chi Y{" "}
        <ExternalLink
          href="https://www.cgdev.org/blog/what-do-we-really-know-about-covid-19s-impact-essential-health-services"
          text="What Do We Really Know about COVID-19s Impact on Essential Health Services?"
          useItalics={true}
        />{" "}
        Center for Global Development. February 18, 2021.
      </Footnote>{" "}
      and supply chains
      <Footnote number={6}>
        For example: Purdy C{" "}
        <ExternalLink
          href="https://www.devex.com/news/opinion-how-will-covid-19-affect-global-access-to-contraceptives-and-what-can-we-do-about-it-96745"
          text="How will COVID-19 affect global access to contraceptives - and what can we do about it?"
          useItalics={true}
        ></ExternalLink>{" "}
        March 11, 2020.
      </Footnote>
      . In our modelling of future scenarios, we have assumed countries will
      follow the trajectories that were already in place before COVID-19
      emerged.
    </p>
    <p>
      The further into the future our projections run, the less certainty we
      have; this uncertainty is represented in the figures with a dotted line.
    </p>
  </div>
);

export default Timeframe;
