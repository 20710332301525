import PropTypes from "prop-types";
import factors from "../../../../data/factors.json";
import Select from "../Select";
import ChooserOptions from "./ChooserOptions";

const renameMenstrualHygieneScenario = (name) => {
  switch (name) {
    case "Maintain":
      return "1: Maintain product use";

    case "Inc. purpose-made":
      return "2: Increase purpose-made";

    case "Inc. purpose-made & reusable":
      return "3: Increase purpose-made & reusable";

    default:
      return name;
  }
};

const renameAbortionScenario = (name) => {
  switch (name) {
    case "Maintain":
      return "1: Maintain safety & method mix";

    case "Shift in safety":
      return "2: Increase in safety";

    case "Shift in safety & method":
      return "3: Increase in safety & medical methods";

    default:
      return name;
  }
};

const renameScenario = (area, name) => {
  if (area === "menstrual-hygiene") {
    return renameMenstrualHygieneScenario(name);
  }

  if (area === "abortion") {
    return renameAbortionScenario(name);
  }

  return name;
};

const ScenarioChooser = (props) => {
  let scenarios = Object.keys(factors.scenarios).map((key) => ({
    name: key,
    value: factors.scenarios[key],
  }));

  if (props.area === "menstrual-hygiene") {
    scenarios = scenarios.filter((scenario) =>
      [1, 2, 5].includes(scenario.value)
    );
  } else if (props.area === "maternal-health") {
    scenarios = [
      {
        name: "1: Maintain coverage",
        value: 5,
      },
      {
        name: "2: Increase coverage",
        value: 1,
      },
      {
        name: "3: Increase coverage and scale-up emerging drugs",
        value: 104,
      },
    ];
  } else {
    scenarios = scenarios.filter((scenario) =>
      [3, 4, 5].includes(scenario.value)
    );
  }

  scenarios = [
    ...scenarios.filter(({ name }) => name === "Maintain"),
    ...scenarios.filter(({ name }) => name !== "Maintain"),
  ];

  scenarios = scenarios.map((scenario) => ({
    ...scenario,
    name: renameScenario(props.area, scenario.name),
  }));

  scenarios = scenarios.sort();

  switch (props.area) {
    case "abortion":
      scenarios = [
        {
          name: "1: Maintain safety profile & method mix",
          value: 5,
        },
        {
          name: "2: Shift safety profile",
          value: 3,
        },
        {
          name: "3: Shift safety profile & method mix",
          value: 4,
        },
      ];

      break;

    default:
      break;
  }

  return (
    <Select
      label="Scenario"
      value={props.value}
      onChange={(value) => {
        props.onChange?.(parseInt(value, 10));
      }}
    >
      <ChooserOptions options={scenarios} />
    </Select>
  );
};

ScenarioChooser.propTypes = {
  area: PropTypes.oneOf(["menstrual-hygiene", "abortion", "maternal-health"])
    .isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default ScenarioChooser;
