import { useState } from "react";
import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import SectorChooser from "../../../Common/Box/Choosers/SectorChooser";
import Figure from "../../../Common/Box/Figure";
import { capitalizePhrase, getTooltip } from "../../../../utilities";

const number = 1;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Sterilization":
      return theme.palette.areas.contraception.methods.sterilization;

    case "Implant":
      return theme.palette.areas.contraception.methods.implant;

    case "IUD":
      return theme.palette.areas.contraception.methods.iud;

    case "Injectable":
      return theme.palette.areas.contraception.methods.injectable;

    case "Pill":
      return theme.palette.areas.contraception.methods.pill;

    case "Condom":
      return theme.palette.areas.contraception.methods.condom;

    case "Other":
      return theme.palette.areas.contraception.methods.other;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const seriesNames = data[0].methods.map(({ name }) => name);

  const series = seriesNames.map((seriesName) => ({
    name: seriesName,
    data: data.map((country) => {
      const method = country.methods.find(({ name }) => name === seriesName);

      return method.value;
    }),
    color: getSeriesColor(seriesName, theme),
  }));

  return {
    chart: {
      type: "bar",
      width: 1000,
      height: 1000,
      backgroundColor: null,
      marginBottom: 70,
    },
    xAxis: {
      categories: data.map(({ name }) => name),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      opposite: true,
      reversedStacks: false,
      max: absolute ? null : 100,
      labels: {
        format: absolute ? undefined : "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      bar: {
        stacking: absolute ? "normal" : "percent",
        borderWidth: 0,
        groupPadding: 0.1,
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      reversed: false,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Users", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const FpCcF1 = (props) => {
  const [comparisonMode, setComparisonMode] = useState("absolute");

  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  return (
    <Figure
      id="FP_CC_f1"
      number={number.toString()}
      title="Contraceptive Users by Method"
      subtitle={`${capitalizePhrase(props.regionName)} by Country, 2019`}
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        >
          <SectorChooser
            value={props.sectorId}
            onChange={props.onSectorChange}
          />
        </BoxControls>
      }
      chartOptions={
        props.data?.length
          ? options(props.data, {
              comparisonMode,
            })
          : undefined
      }
    />
  );
};

FpCcF1.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
  sectorId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      methods: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ),
  onSectorChange: PropTypes.func,
};

export default FpCcF1;
