import { useState } from "react";
import PropTypes from "prop-types";
import MhCpF4 from "../Figures/MhCpF4";

const MhCpS4 = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(4);

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  return (
    <MhCpF4
      methodId={props.methodId}
      methodName={props.methodName}
      regionalUnitId={props.regionalUnitId}
      regionalUnitName={props.regionalUnitName}
      indicatorId={selectedIndicatorId}
      onIndicatorChange={handleIndicatorChange}
    />
  );
};

MhCpS4.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCpS4;
