import PropTypes from "prop-types";
import Figure from "../../../Common/Box/Figure";
import { capitalizePhrase, getTooltip } from "../../../../utilities";

const number = 3;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Surgical: Vacuum aspiration":
      return theme.palette.areas.abortion.methods.mva;

    case "Medical: Miso":
      return theme.palette.areas.abortion.methods.miso;

    case "Medical: Mife":
      return theme.palette.areas.abortion.methods.mife;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const series = data.map((series) => ({
    name: series.name,
    data: [series.cost],
    color: getSeriesColor(series.name, theme),
  }));

  return {
    chart: {
      type: "column",
      width: 500,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: [props.regionName],
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        formatter: function () {
          return `$${this.axis.defaultLabelFormatter.call(this)}`;
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Cost", this.y, this.percentage, {
          prefix: "$",
        });
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const ApacCsF3 = (props) => {
  return (
    <Figure
      id="APAC_CS_f3"
      number={number.toString()}
      fileStem={`apac_cs_f${number}`}
      title="Cost of Abortion and PAC Supplies by Method"
      subtitle={`${capitalizePhrase(props.regionName)}, 2019`}
      chartOptions={
        props.data &&
        options(props.data, {
          regionName: props.regionShortName,
        })
      }
    />
  );
};

ApacCsF3.propTypes = {
  regionName: PropTypes.string.isRequired,
  regionShortName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      cost: PropTypes.number.isRequired,
    })
  ),
};

export default ApacCsF3;
