import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import IndicatorChooser from "../../IndicatorChooser";
import {
  getIndicatorDetails,
  getSingular,
  getTooltip,
  toTitleCase,
} from "../../../../utilities";

const number = 2;

const options = (data, props) => (theme) => {
  const financial = props.indicatorId === 1;

  const series = [
    {
      data: data.map(({ value }) => value),
      color: theme.palette.areas.abortion.primary,
    },
  ];

  return {
    chart: {
      type: "column",
      width: 800,
      height: 400,
      inverted: true,
      backgroundColor: null,
    },
    xAxis: {
      categories: data.map(({ region }) => region),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        formatter: function () {
          return (
            (financial ? "$" : "") + this.axis.defaultLabelFormatter.call(this)
          );
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderWidth: 0,
        groupPadding: 0.1,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip(
          props.indicatorName,
          this.y,
          undefined,
          financial
            ? {
                prefix: "$",
              }
            : undefined
        );
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const ApacCpF2 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/apac/cp/figures/2/${props.regionalUnitId}/${props.productId}/${props.indicatorId}`
  );

  const indicatorDetails = getIndicatorDetails(props.indicatorId);

  return (
    <Figure
      id="APAC_CP_f2"
      number={number.toString()}
      fileStem={`apac_cp_f${number}`}
      title={`${indicatorDetails.qualifier} ${toTitleCase(props.productName)}`}
      subtitle={`By ${getSingular(props.regionalUnitName)}, 2019`}
      controls={
        <BoxControls>
          <IndicatorChooser
            area="abortion"
            value={props.indicatorId}
            onChange={props.onIndicatorChange}
          />
        </BoxControls>
      }
      chartOptions={
        data &&
        options(data, {
          indicatorId: props.indicatorId,
          indicatorName: indicatorDetails.qualifier,
        })
      }
    />
  );
};

ApacCpF2.propTypes = {
  productId: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
  indicatorId: PropTypes.number.isRequired,
  onIndicatorChange: PropTypes.func,
};

export default ApacCpF2;
