import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
    },
    "& th": {
      padding: theme.table.cellPadding,
    },
  },
}));

const RgMhT3 = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6">Table 3. Treatment Assumptions</Typography>

      <table className={classes.table}>
        <thead>
          <tr>
            <th>Drug</th>
            <th>Formulation</th>
            <th>Quantity assumptions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Iron and folic acid (IFA)</td>
            <td>Ferrous Salt + Folic Acid, tablet, 60 + 0.4 mg</td>
            <td>Average of 6 months, or 180 tablets per pregnant woman</td>
          </tr>
          <tr>
            <td>Methyldopa</td>
            <td>250 mg tablet</td>
            <td>
              Severe events: 12 (3 g in 24 hours)
              <br />
              Gestational hypertension: 450 (3 tabs per day, 5 months of
              pregnancy)
              <br />
              Chronic hypertension: 810 (3 tabs per day, 9 months of pregnancy)
            </td>
          </tr>
          <tr>
            <td>Hydralazine</td>
            <td>20mg amp</td>
            <td>Severe events: 5mg IV or IM up to a maximum of 20 mg.</td>
          </tr>
          <tr>
            <td>Magnesium sulfate</td>
            <td>5g/10mL ampule</td>
            <td>
              50%/50% mix between Pritchard's protocol (8 doses) and Zuspan’s
              protocol (6 doses).
            </td>
          </tr>
          <tr>
            <td>Oxytocin</td>
            <td>10 IU 1ml amp</td>
            <td>
              Induction: 10 IU
              <br />
              Augmentation: 10 IU
              <br />
              PPH prevention: 10 IU
              <br />
              PPH treatment: 40 IU
              <br />
            </td>
          </tr>
          <tr>
            <td>Misoprostol</td>
            <td>25, 200mcg tablet</td>
            <td>
              Induction: Oral misoprostol (25 µg, 2-hourly) up to 200 mcg
              <br />
              PPH prevention: 600 μg, or three 200 μg tablets
              <br />
              PPH treatment 800 μg, or four 200 μg tablet
            </td>
          </tr>
          <tr>
            <td>Metronidazole</td>
            <td>500mg/100ml vial</td>
            <td>500 mg IV every eight hours, 4 days per case </td>
          </tr>
          <tr>
            <td>Heat-stable carbetocin (HSC)</td>
            <td>100 &mu;g</td>
            <td>100 &mu;g</td>
          </tr>
          <tr>
            <td>Tranexamic acid (TXA)</td>
            <td>10 Ml ampoule</td>
            <td>
              10 Ml with a second dose if bleeding continues after 30 minutes
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RgMhT3;
