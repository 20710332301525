import { Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import RgMhT4 from "./RgMhT4";

const MaternalHealthCosts = () => (
  <div>
    <Typography variant="h1">Drug Costs</Typography>

    <p>
      The total costs reflect the estimated total volume of drugs used for each
      case multiplied by the unit price (see Table 4). All costs are based on
      current drug costs, held constant for all years through to 2030. Costs are
      primarily based on global UNICEF list prices
      <Footnote number={11}>
        <ExternalLink
          href="https://supply.unicef.org/"
          text="UNICEF Supply Catalogue"
          useItalics={true}
        />{" "}
        Accessed March 15, 2021.
      </Footnote>
      , with consistency checks against the MSH Drug Price Indicator Guide
      <Footnote number={12}>
        <ExternalLink
          href="https://mshpriceguide.org/en/home/"
          text="MSH International Medical Products Price Guide"
          useItalics={true}
        />{" "}
        Accessed March 15, 2021.
      </Footnote>{" "}
      and public price data where available. As such, a single global price has
      been used for all countries as limited data exists on price differences
      across countries.
    </p>
    <p>
      We assess costs from the perspective of the institutional purchasers of
      the drugs (governments, international donors, etc.), as opposed to the
      women receiving the drugs, since they are often provided free of charge by
      the public health system. Governments are the primary procurers of these
      drugs, although there is some limited purchasing by donors. In some
      instances women may purchase drugs from private providers, especially when
      there are stock-outs at facilities
      <Footnote number={13}>
        Meda, I.B., Baguiya, A., Ridde, V. et al{" "}
        <ExternalLink
          href="https://healtheconomicsreview.biomedcentral.com/articles/10.1186/s13561-019-0228-8"
          text="Out-of-pocket payments in the context of a free maternal health care policy in Burkina Faso: a national cross-sectional survey"
          useItalics={true}
        />{" "}
        Health Econ Rev 9, 11 (2019).
      </Footnote>
      <Footnote number={14}>
        Richard, F., Antony, M., Witter, S., Kelley, A., Sieleunou, I., Meessen,
        B., &amp; Kafando, Y.{" "}
        <ExternalLink
          href="http://blogs.shu.edu/ghg/files/2014/02/GHGJ_62_53-73_RICHARD_ET_AL.pdf"
          text="Fee exemption for maternal care in Sub-Saharan Africa: a review of 11 countries and lessons for the region"
          useItalics={true}
        />{" "}
        Global Health Governance, VI(2), 1-21.
      </Footnote>
      . Limited data exists on the share of women who make these purchases and
      the prices they pay, so this has not been accounted for in our analysis.
    </p>
    <p>
      Note the costs of drugs included in LEAP are not the same as procurement
      costs, which may be higher or lower than drug costs depending on context.
      Procurement needs reflect the volume needed to fill supply pipelines and
      maintain adequate inventory levels, and also take into account volumes
      already in the supply chain.
    </p>

    <RgMhT4 />
  </div>
);

export default MaternalHealthCosts;
