import { Typography } from "@material-ui/core";
import Footnote from "../../Common/Footnote";

const ContraceptionUsersByMethod = () => (
  <div>
    <Typography variant="h1">Users by Method</Typography>

    <p>
      Data from DHS, MICS, PMA2020 and other national surveys were used to
      estimate the number of users of each contraceptive method. For each
      country a starting method mix was taken from the most recent survey. From
      there, the method mixes for each subsequent year (from date of survey to
      2030) were calculated by applying sub-regional average annual changes in
      method mix by method. For projecting forward from the latest survey to the
      base year (2019), methods that were not already in use were kept at 0% of
      the method mix in 2019. However, going forward these methods were allowed
      to increase based on the regional patterns.
    </p>
    <p>
      The average annual change analysis was limited to countries whose most
      recent survey was from 2014 or later to reflect more recent shifts in
      method mix. The average annual change was calculated for each country; in
      most cases the older survey was limited to be the same type (e.g. DHS or
      MICS) as the most recent survey to ensure comparability. Average annual
      changes were calculated for each method. These changes were then averaged
      by region and sub-region. A total of 236 individual surveys from 99
      countries were incorporated into the method mix change analysis
      <Footnote number={5}>
        Calculations were done separately for married and unmarried method mix;
        in some countries different data sources were used for married and
        unmarried women, thus more than 2 surveys per country were used in some
        instances.
      </Footnote>
      .
    </p>
    <p>
      All calculations were done separately for married and unmarried users;
      this was done to utilize the wider availability of data for married women
      and to account for different method mix trends between married and
      unmarried users. Married and unmarried users of each method were then
      summed together; results by marital status are not presented.
    </p>
  </div>
);

export default ContraceptionUsersByMethod;
