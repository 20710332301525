import PropTypes from "prop-types";
import ApacCcF1 from "../Figures/ApacCcF1";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatPercentage } from "../../../../utilities";

const calculateMetrics = (data) => ({
  mva: {
    minimumShare: data?.[0].minimumShare ?? 0,
    maximumShare: data?.[0].maximumShare ?? 0,
  },
  medical: {
    minimumShare: data?.[1].minimumShare ?? 0,
    maximumShare: data?.[1].maximumShare ?? 0,
  },
});

const ApacCcS2 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/apac/cc/figures/1/${props.regionalUnitId}`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        Across all low- and middle-income countries in 2019, 35% of services
        were vacuum aspiration and 43% are medical services, while 13% were
        least safe.
      </p>

      <p>
        Surgical vacuum aspiration services range from{" "}
        {formatPercentage(metrics.mva.minimumShare)} to{" "}
        {formatPercentage(metrics.mva.maximumShare)}, while medical services
        range from {formatPercentage(metrics.medical.minimumShare)} to{" "}
        {formatPercentage(metrics.medical.maximumShare)} across{" "}
        {props.regionalUnitName}.
      </p>

      <ApacCcF1 regionalUnitName={props.regionalUnitName} data={data} />
    </>
  );
};

ApacCcS2.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default ApacCcS2;
