import { makeStyles } from "@material-ui/styles";
import Table from "../../Common/Box/Table";
import initialData from "../../../data/initialData.json";
import useCsvDownload from "../../../hooks/useCsvDownload";
import { formatNumber, formatPercentage } from "../../../utilities";

const number = 4;

const format = (n) =>
  formatNumber(n, {
    significantDigits: 3,
  });

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child td:not(:first-child)": {
      minWidth: "6rem",
    },
  },
}));

const Component = () => {
  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleCsvDownload = () => {
    downloadCsv("/mhm/tables/4/csv", `mhm_t${number}.csv`);
  };

  const data = initialData["mhm_t4"];

  return (
    <Table
      id="MHM_t4"
      number={number.toString()}
      title="Change in Total Menstruators"
      subtitle="By Income Group, 2019-2030"
      onDownloadCsv={handleCsvDownload}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th colSpan={3}>Total Menstruators</th>
            <th colSpan={2} className={classes.changeGroupBoundary}>
              Change (2019 to 2030)
            </th>
          </tr>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th>2019</th>
            <th>2025</th>
            <th>2030</th>
            <th className={classes.changeGroupBoundary}>#</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.incomeGroup}>
              <td className={classes.nameGroupBoundary}>{item.incomeGroup}</td>

              {item.years.map((year) => (
                <td key={year.year}>{format(year.value)}</td>
              ))}

              <td className={classes.changeGroupBoundary}>
                {format(item.change)}
              </td>
              <td>{formatPercentage(item.percentage)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Table>
  );
};

export default Component;
