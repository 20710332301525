import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import IndicatorChooser from "../../IndicatorChooser";
import {
  getIndicatorDetails,
  getMethodPlural,
  getQuantitiesAndCostsMethodPlural,
  getTooltip,
  toTitleCase,
} from "../../../../utilities";

const number = 1;

const options = (data, options) => (theme) => {
  const financial = options.indicatorDetails.id === 1;

  return {
    chart: {
      type: "column",
      width: 500,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: ["Low", "Lower-Middle", "Upper-Middle"],
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        formatter: function () {
          return (
            (financial ? "$" : "") + this.axis.defaultLabelFormatter.call(this)
          );
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip(
          options.indicatorDetails.name,
          this.y,
          undefined,
          financial ? { prefix: "$" } : undefined
        );
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series: [
      {
        data: data.map(({ value }) => value),
        color: theme.palette.areas.contraception.primary,
      },
    ],
  };
};

const FpCpF1 = (props) => {
  const indicatorDetails = getIndicatorDetails(props.indicatorId);

  return (
    <Figure
      id="FP_CP_f1"
      number={number.toString()}
      title={`${indicatorDetails.qualifier} ${toTitleCase(
        props.indicatorId === 3 || props.indicatorId === 1
          ? getQuantitiesAndCostsMethodPlural(props.methodName)
          : getMethodPlural(props.methodName)
      )}`}
      subtitle="By Income Group, 2019"
      controls={
        <BoxControls>
          <IndicatorChooser
            area="contraception"
            value={props.indicatorId}
            onChange={props.onIndicatorChange}
          />
        </BoxControls>
      }
      chartOptions={
        props.data &&
        options(props.data, {
          indicatorDetails,
        })
      }
    />
  );
};

FpCpF1.propTypes = {
  methodName: PropTypes.string.isRequired,
  indicatorId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  onIndicatorChange: PropTypes.func,
};

export default FpCpF1;
