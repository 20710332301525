import PropTypes from "prop-types";
import Select from "../Select";
import ChooserOptions from "./ChooserOptions";

const MethodGroupChooser = (props) => {
  return (
    <Select
      label="Cases"
      value={props.value}
      onChange={(value) => {
        props.onChange?.(parseInt(value, 10));
      }}
    >
      <ChooserOptions
        options={[
          {
            name: "Needed: Received",
            value: 1,
          },
          {
            name: "Needed: Not Received",
            value: 2,
          },
          {
            name: "All Cases",
            value: 0,
          },
        ]}
      />
    </Select>
  );
};

MethodGroupChooser.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default MethodGroupChooser;
