import { useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CustomReport from "../CustomReport";
import RegionChooser from "../RegionChooser";
import MhmCcB1 from "./Boxes/MhmCcB1";
import MenstrualHygieneIntroduction from "./MenstrualHygieneIntroduction";
import { getRegionName } from "../../../utilities";
import MhmCcF1 from "./Figures/MhmCcF1";
import MhmCcF2 from "./Figures/MhmCcF2";
import MhmCcF3 from "./Figures/MhmCcF3";
import MenstrualHygieneScenarios from "./MenstrualHygieneScenarios";
import MhmCcChangeInMenstruators from "./Summaries/MhmCcChangeInMenstruators";
import MhmCcCurrentCosts from "./Summaries/MhmCcCurrentCosts";
import MhmCcCurrentUse from "./Summaries/MhmCcCurrentUse";
import MhmCcFutureCosts from "./Summaries/MhmCcFutureCosts";
import MhmCcFutureUse from "./Summaries/MhmCcFutureUse";

const useStyles = makeStyles((theme) => ({
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
  datasetDownload: {
    marginTop: theme.spacing(2),
  },
}));

const MenstrualHygieneComparison = () => {
  const [selectedRegionId, setSelectedRegionId] = useState(145);

  const selectedRegionLongName = getRegionName(selectedRegionId, "long");

  const classes = useStyles();

  const handleRegionChange = (regionId) => {
    setSelectedRegionId(regionId);
  };

  return (
    <CustomReport>
      <Typography variant="h1">
        Menstrual Hygiene Comparison Across {selectedRegionLongName}
      </Typography>

      <RegionChooser value={selectedRegionId} onChange={handleRegionChange} />

      <section id="introduction">
        <Typography variant="h2">Background</Typography>

        <div className={classes.boxes}>
          <MenstrualHygieneIntroduction>
            The <strong>Menstrual Hygiene Comparison Report</strong> provides
            estimates of the number of menstruators, the methods they use, and
            related costs for countries in the selected region.
          </MenstrualHygieneIntroduction>

          <MhmCcB1
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />
        </div>
      </section>

      <section id="current">
        <Typography variant="h2">Where We Are Now</Typography>

        <div className={classes.boxes}>
          <Typography variant="h6">
            Current Use of Menstrual Hygiene Products in{" "}
            {selectedRegionLongName}
          </Typography>

          <MhmCcCurrentUse
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhmCcF1
            countrySelected={false}
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <Typography variant="h6">
            Current Costs of Menstrual Hygiene Products in{" "}
            {selectedRegionLongName}
          </Typography>

          <MhmCcCurrentCosts
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhmCcF2
            countrySelected={false}
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />
        </div>
      </section>

      <section id="future">
        <Typography variant="h2">Changes Ahead</Typography>

        <div className={classes.boxes}>
          <MenstrualHygieneScenarios />

          <Typography variant="h6">
            Future Use of Menstrual Hygiene Products in {selectedRegionLongName}
          </Typography>

          <MhmCcChangeInMenstruators
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhmCcF3
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhmCcFutureUse
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <Typography variant="h6">
            Future Costs of Menstrual Hygiene Products in{" "}
            {selectedRegionLongName}
          </Typography>

          <MhmCcFutureCosts
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />
        </div>
      </section>
    </CustomReport>
  );
};

export default MenstrualHygieneComparison;
