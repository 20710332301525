import PropTypes from "prop-types";
import { Button, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { enableCustomReports } from "../../constants";
import { lighten } from "../../utilities";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.areas.accent.default,
    borderRadius: 0,
    fontSize: "1rem",
    minWidth: 150,
    padding: theme.spacing(1.5, 0),
    textTransform: "none",
  },
  component: {
    alignItems: "center",
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(),
    gridAutoColumns: "1fr",
    height: theme.appBar.height - theme.appBar.toolbar.height,
    whiteSpace: "nowrap",
    width: "fit-width",
  },
  selected: {
    backgroundColor: (props) =>
      props.selectedChapter === "custom"
        ? theme.palette.areas.general.primary
        : theme.palette.area.primary,
    color: "white",
    "&:hover": {
      backgroundColor: (props) =>
        lighten(
          props.selectedChapter === "custom"
            ? theme.palette.areas.general.primary
            : theme.palette.area.primary,
          0.13
        ),
    },
  },
}));

const ChapterMenu = ({ selectedChapter }) => {
  const classes = useStyles({ selectedChapter });

  const createItem = (chapter, label, comingSoon) => {
    let item = (
      <Button
        component={!comingSoon ? Link : undefined}
        to={`/${chapter === "introduction" ? "" : chapter}`}
        className={classNames(
          classes.button,
          selectedChapter === chapter ? classes.selected : null
        )}
      >
        {label}
      </Button>
    );

    if (comingSoon) {
      item = <Tooltip title="Coming soon...">{item}</Tooltip>;
    }

    return item;
  };

  return (
    <div className={classes.component}>
      {createItem("introduction", "Introduction")}
      {createItem("contraception", "Contraception")}
      {createItem("menstrual-hygiene", "Menstrual Hygiene")}
      {createItem("abortion", "Abortion and PAC")}
      {createItem("maternal-health", "Maternal Health")}
      {createItem("custom", "Custom Reports", !enableCustomReports)}
    </div>
  );
};

ChapterMenu.propTypes = {
  selectedChapter: PropTypes.oneOf([
    "introduction",
    "contraception",
    "menstrual-hygiene",
    "abortion",
    "maternal-health",
    "custom",
  ]).isRequired,
};

export default ChapterMenu;
