import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
    },
    "& td:not(first-child)": {
      textAlign: "right",
    },
    "& th": {
      padding: theme.table.cellPadding,
    },
  },
  footnote: {
    fontSize: "0.875rem",
    fontStyle: "italic",
  },
}));

const RgApacT1 = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6">
        Table 1. Estimated Rates of Incomplete Abortion by Method and Safety
      </Typography>

      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th>
              Manual or
              <br />
              electric
              <br />
              vacuum
              <br />
              aspiration
            </th>
            <th>
              D&amp;E (safe) +<br />
              D&amp;C (less)
            </th>
            <th>
              Mifepristone
              <br />
              and
              <br />
              misoprostol (&lt;12 weeks/
              <br />
              84 days)
            </th>
            <th>
              Mifepristone
              <br />
              and
              <br />
              misoprostol
              <br />
              (&ge;12 weeks)
            </th>
            <th>Misoprostol</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Safe</td>
            <td>1%</td>
            <td>1%</td>
            <td>7%</td>
            <td>7%</td>
            <td>12%</td>
          </tr>
          <tr>
            <td>Less Safe</td>
            <td>5%</td>
            <td>10%</td>
            <td>21%</td>
            <td>21%</td>
            <td>36%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RgApacT1;
