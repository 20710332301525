import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const MaternalHealthIntroduction = ({ children }) => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <>
      <p>
        The health of women worldwide is at substantial risk due to
        pregnancy-related causes. In 2017, the global Maternal Mortality Ratio
        (MMR) was 211 maternal deaths per 100,000 live births. Sustainable
        Development Goal (SGD) 3.1 sets the target of reducing global MMR to
        less than 70 per 100,000 live births by 2030
        <Footnote number={1}>
          United Nations{" "}
          <ExternalLink
            href="https://sdgs.un.org/goals/goal3"
            text="Sustainable Development Goal 3"
            useItalics={true}
          />
          : By 2030, achieve access to adequate and equitable sanitation and
          hygiene for all and end open defecation, paying special attention to
          the needs of women and girls and those in vulnerable situations.
        </Footnote>
        .  Most maternal health complications can be prevented or treated with
        access to skilled care, including appropriate facilities and drugs
        during and after pregnancy and childbirth. This analysis focuses on the
        use and associated cost of seven priority maternal health drugs, future
        scenarios also explore the scale-up of two emerging drugs.
      </p>
      <p>{children}</p>
      <p>
        The &apos;unit&apos; of analysis maternal health in LEAP is a case which
        we define as a health intervention to prevent, manage or treat
        complications during pregnancy and childbirth that uses one of the
        maternal health drugs under review.  Using &apos;case&apos; as the unit
        of analysis allows for the fact that one pregnant woman may receive
        multiple interventions that use the same drug, a case can include
        multiple doses of a drug, and not everyone who needs an intervention
        receives it. We split cases into those that needed but did not receive a
        drug and those that needed and received a drug. While we use the term
        &apos;cost&apos; across LEAP, for maternal health this largely reflects
        prices paid by governments or donors to procure supplies for public
        sector distribution (generally at no charge to the user). We do not
        include in our cost figures: products that were purchased but never
        used; the costs that would be associated with women who need but
        don&apos;t receive a drug; or other associated costs such as labor,
        health infrastructure, and medical equipment.
      </p>
      <p>
        For information across all low- and middle-income countries, please
        refer to the{" "}
        <RouterLink to="/maternal-health">
          Maternal Health Landscape Report
        </RouterLink>
        . Additional methodological details can also be found in the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("maternal-health-scope")}
        >
          Reader&apos;s Guide
        </Link>
        .
      </p>
    </>
  );
};

MaternalHealthIntroduction.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MaternalHealthIntroduction;
