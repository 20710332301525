import { makeStyles } from "@material-ui/styles";
import { ReactComponent as FacebookLogo } from "../../images/facebook.svg";
import { ReactComponent as TwitterLogo } from "../../images/twitter.svg";
import { ReactComponent as YouTubeLogo } from "../../images/youtube.svg";

const useStyles = makeStyles((theme) => ({
  component: {
    color: theme.palette.title.primary,
  },
  copyright: {
    marginTop: theme.spacing(2),
  },
  links: {
    display: "grid",
    gridAutoFlow: "row",
    gridRowGap: theme.spacing(1.5),
    marginTop: theme.spacing(2),
    "& a": {
      color: theme.palette.title.primary,
    },
  },
  socialMediaIcon: {
    height: 30,
    width: 30,
  },
  socialMediaIcons: {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(4),
    gridAutoColumns: "1fr",
    margin: "0 auto",
    width: "fit-content",
  },
}));

const SocialMediaIcon = (props) => (
  <a href={props.href} target="_blank" rel="noreferrer">
    {props.children}
  </a>
);

const Link = (props) => (
  <a href={props.href} target="_blank" rel="noreferrer">
    {props.children}
  </a>
);

const CompanyDetails = () => {
  const classes = useStyles();

  return (
    <div className={classes.component}>
      <div className={classes.socialMediaIcons}>
        <SocialMediaIcon href="https://www.facebook.com/rhsupplies">
          <FacebookLogo className={classes.socialMediaIcon} />
        </SocialMediaIcon>
        <SocialMediaIcon href="https://twitter.com/Rh_Supplies">
          <TwitterLogo className={classes.socialMediaIcon} />
        </SocialMediaIcon>
        <SocialMediaIcon href="https://www.youtube.com/channel/UCfcw-_6hiSEQMdR1ltXMeTw">
          <YouTubeLogo className={classes.socialMediaIcon} />
        </SocialMediaIcon>
      </div>
      <div className={classes.links}>
        <Link
          href="https://www.rhsupplies.org/contact-us/"
          target="_blank"
          rel="noreferrer"
        >
          Contact Us
        </Link>
        <Link
          href="https://www.rhsupplies.org/terms-conditions/"
          target="_blank"
          rel="noreferrer"
        >
          Terms &amp; Conditions
        </Link>
        <Link
          href="https://www.rhsupplies.org/cookies-privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          Cookies &amp; Privacy Policy
        </Link>
        <Link
          href="https://www.rhsupplies.org/"
          target="_blank"
          rel="noreferrer"
        >
          RHSC Home
        </Link>
      </div>
      <div className={classes.copyright}>
        &copy; Reproductive Health Supplies Coalition 2021
      </div>
    </div>
  );
};

export default CompanyDetails;
