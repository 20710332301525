import { makeStyles } from "@material-ui/styles";
import Table from "../../Common/Box/Table";
import useCsvDownload from "../../../hooks/useCsvDownload";
import { formatNumber } from "../../../utilities";

const number = 2;

const data = [
  {
    incomeGroup: "Low",
    pregnancies: 32400000,
    abortions: 9440000,
    births: 23000000,
  },
  {
    incomeGroup: "Lower-Middle",
    pregnancies: 106000000,
    abortions: 41400000,
    births: 64800000,
  },
  {
    incomeGroup: "Upper-Middle",
    pregnancies: 75200000,
    abortions: 33000000,
    births: 42200000,
  },
  {
    incomeGroup: "Total",
    pregnancies: 214000000,
    abortions: 83800000,
    births: 130000000,
  },
];

const format = (n) =>
  formatNumber(n, {
    significantDigits: 3,
  });

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& th:not(:first-child)": {
      width: "8rem",
    },
  },
  groupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
}));

const Component = () => {
  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleCsvDownload = () => {
    downloadCsv("/mh/tables/12/csv", `mh_t${number}.csv`);
  };

  return (
    <Table
      key="MH_t12"
      number={number.toString()}
      title="Number of Pregnancies and Births"
      subtitle="By Income group, 2019"
      onDownloadCsv={handleCsvDownload}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th className={classes.groupBoundary} />
            <th colSpan={2}>Pregnancy Outcomes</th>
          </tr>
          <tr>
            <th />
            <th className={classes.groupBoundary}>Pregnancies</th>
            <th className={classes.groupBoundary}>
              Abortions or
              <br />
              miscarriages
            </th>
            <th>
              Births
              <br />
              (live & still)
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.incomeGroup}>
              <td>{item.incomeGroup}</td>
              <td className={classes.groupBoundary}>
                {format(item.pregnancies)}
              </td>
              <td className={classes.groupBoundary}>
                {format(item.abortions)}
              </td>
              <td>{format(item.births)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Table>
  );
};

export default Component;
