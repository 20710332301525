import { Link } from "@material-ui/core";
import Information from "../../Common/Box/Information";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const Component = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <Information key="APAC_b6" number="6" title="Future Scenarios">
      <p>
        <strong>Scenario 1: Maintain safety profile and method mix.</strong>{" "}
        This scenario accounts for demographic changes, changes in contraceptive
        uptake (and thus resulting changes in fertility) and contraceptive
        method mix (based on existing trend data). However, we maintain the same
        distribution of the safety profile of abortion services (i.e. the
        proportion that are safe/less safe/least safe), and the same
        distribution of surgical and medical methods, currently found in each
        income group.
      </p>

      <p>
        <strong>Scenario 2: Shift safety profile.</strong> This scenario assumes
        the same demographic and contraceptive use changes in Scenario 1. But it
        improves the safety profile of services by matching the proportion of
        abortion services that are safe/less safe/least safe to the average
        pattern seen in the next highest income group. This shift occurs over
        time following a linear trend. However if in 2019 a country already has
        a distribution with more safe abortions than what they would shift to
        using this average pattern, then they keep this original distribution
        through to 2030.
      </p>

      <p>
        <strong>Scenario 3: Shift safety profile and method mix.</strong> This
        scenario is the same as Scenario 2 but also builds in a shift to greater
        use of medical (especially combined use of misoprostol and mifepristone)
        rather than surgical methods among safe and less safe abortions. The
        magnitude of the shift to medical methods is based on the safety
        classification of the abortion services:
      </p>

      <ul>
        <li>
          For abortions classed as &apos;safe&apos;, we assume that by 2030 all
          medical abortions would use a combination of mifepristone and
          misoprostol (no misoprostol-only abortions), and 50% of all safe
          abortions would be medical abortions (vs 30-45% currently).
        </li>
        <li>
          or abortions classed as &apos;less safe&apos;, regions with less than
          50% medical abortions in 2019 would shift to 50% medical abortion by
          2030. The proportion of medical abortions using a combination of
          misoprostol and mifepristone also would increase in all regions
          (different profiles of increase are applied based on current use of
          medical methods, see the{" "}
          <Link
            underline="always"
            onClick={handleReadersGuideOpen("abortion-future-scenarios")}
          >
            Reader&apos;s Guide
          </Link>{" "}
          for more detail).
        </li>
      </ul>

      <p>
        The methods used for providing PAC services remain constant; in all
        three scenarios PAC services are assumed to involve surgical methods
        (vacuum aspiration) 50% of the time and medical methods (misoprostol)
        50% of the time.
      </p>

      <p>
        See the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-future-scenarios")}
        >
          Reader&apos;s Guide
        </Link>{" "}
        for more detail about the development of these scenarios.
      </p>
    </Information>
  );
};

export default Component;
