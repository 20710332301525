import { useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CustomReport from "../CustomReport";
import RegionalUnitChooser from "../RegionalUnitChooser";
import SupplyChooser from "../SupplyChooser";
import { getRegionalUnitName, toTitleCase } from "../../../utilities";
import AbortionIntroduction from "./AbortionIntroduction";
import AbortionScenarios from "./AbortionScenarios";
import ApacCpS1 from "./Summaries/ApacCpS1";
import ApacCpS2 from "./Summaries/ApacCpS2";
import ApacCpS3 from "./Summaries/ApacCpS3";
import ApacCpS4 from "./Summaries/ApacCpS4";

const useStyles = makeStyles((theme) => ({
  choosers: {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(4),
    width: "fit-content",
  },
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
}));

const AbortionDeepDive = () => {
  const [selectedProductId, setSelectedProductId] = useState(7);
  const [selectedProductName, setSelectedProductName] = useState(
    "Manual vacuum aspiration kits"
  );
  const [selectedRegionalUnitId, setSelectedRegionalUnitId] = useState(0);

  const classes = useStyles();

  const selectedRegionalUnitName = getRegionalUnitName(
    selectedRegionalUnitId,
    true
  );

  const handleProductChange = (product) => {
    setSelectedProductId(product.id);
    setSelectedProductName(product.name);
  };

  const handleRegionalUnitChange = (regionalUnitId) => {
    setSelectedRegionalUnitId(regionalUnitId);
  };

  return (
    <CustomReport>
      <Typography variant="h1">
        Abortion and PAC Product Deep Dive into{" "}
        {toTitleCase(selectedProductName)}
      </Typography>

      <div className={classes.choosers}>
        <SupplyChooser
          area="abortion"
          value={selectedProductId}
          onChange={handleProductChange}
        />

        <RegionalUnitChooser
          value={selectedRegionalUnitId}
          onChange={handleRegionalUnitChange}
        />
      </div>

      <section id="introduction">
        <Typography variant="h2">Background</Typography>

        <div className={classes.boxes}>
          <AbortionIntroduction>
            The <strong>Abortion &amp; PAC Product Deep Dive</strong> provides
            estimates on the number of abortion and PAC services provided, the
            quantities of commodities, and the associated supply costs for the
            selected product.
          </AbortionIntroduction>
        </div>
      </section>

      <section id="current">
        <Typography variant="h2">Where We Are Now</Typography>

        <div className={classes.boxes}>
          <ApacCpS1
            productId={selectedProductId}
            productName={selectedProductName}
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <ApacCpS2
            productId={selectedProductId}
            productName={selectedProductName}
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />
        </div>
      </section>

      <section id="future">
        <Typography variant="h2">Changes Ahead</Typography>

        <div className={classes.boxes}>
          <AbortionScenarios />

          <ApacCpS3
            productId={selectedProductId}
            productName={selectedProductName}
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <ApacCpS4
            productId={selectedProductId}
            productName={selectedProductName}
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />
        </div>
      </section>
    </CustomReport>
  );
};

export default AbortionDeepDive;
