import { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import BoxControls from "../../Common/Box/BoxControls";
import ScenarioChooser from "../../Common/Box/Choosers/ScenarioChooser";
import Table from "../../Common/Box/Table";
import initialData from "../../../data/initialData.json";
import useAreaQuery from "../../../hooks/useAreaQuery";
import useCsvDownload from "../../../hooks/useCsvDownload";
import {
  formatNumber,
  formatPercentage,
  getDownloadFileStem,
  getScenario,
} from "../../../utilities";

const key = "MHM_t5";
const number = 5;

const format = (n) =>
  formatNumber(n, {
    prefix: "$",
    significantDigits: 3,
  });

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td.category": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child th:not(.category)": {
      minWidth: "8rem",
    },
  },
}));

const Component = () => {
  const [selectedScenarioId, setSelectedScenarioId] = useState(2);

  const { areaData: data } = useAreaQuery(
    `/mhm/tables/5/${selectedScenarioId}`,
    initialData["mhm_t5"]
  );

  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const getSubtitle = () => {
    switch (selectedScenarioId) {
      case 1:
        return "Scenario 2: Increase Purpose-Made by Income Group, 2019-2030";

      case 2:
        return "Scenario 3: Increase Purpose-Made & Reusable by Income Group, 2019-2030";

      case 5:
        return "Scenario 1: Maintain Product Use by Income Group, 2019-2030";

      default:
        return "Future scenario by Income Group, 2019-2030";
    }
  };

  const handleScenarioChange = (scenarioId) => {
    setSelectedScenarioId(scenarioId);
  };

  const handleCsvDownload = () => {
    const fileStem = getDownloadFileStem(
      key,
      number,
      getScenario(selectedScenarioId)
    );

    downloadCsv(`/mhm/tables/5/${selectedScenarioId}/csv`, `${fileStem}.csv`);
  };

  return (
    <Table
      id={key}
      number={number.toString()}
      title="Change in Cost of Purpose-Made Menstrual Hygiene Products"
      subtitle={getSubtitle()}
      controls={
        <BoxControls>
          <ScenarioChooser
            area="menstrual-hygiene"
            value={selectedScenarioId}
            onChange={handleScenarioChange}
          />
        </BoxControls>
      }
      onDownloadCsv={handleCsvDownload}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th colSpan={3}>Total Costs</th>
            <th colSpan={2} className={classes.changeGroupBoundary}>
              Change (2019 to 2030)
            </th>
          </tr>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th>2019</th>
            <th>2025</th>
            <th>2030</th>
            <th className={classes.changeGroupBoundary}>#</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {data.map((incomeGroup) => (
            <tr key={incomeGroup.incomeGroup}>
              <td className={classNames("category", classes.nameGroupBoundary)}>
                {incomeGroup.incomeGroup}
              </td>

              {incomeGroup.years.map((year) => (
                <td key={year.year}>{format(year.value)}</td>
              ))}

              <td className={classes.changeGroupBoundary}>
                {format(incomeGroup.change)}
              </td>
              <td>{formatPercentage(incomeGroup.percentage)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Table>
  );
};

export default Component;
