import { useState } from "react";
import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import { capitalizePhrase, getTooltip } from "../../../../utilities";

const number = 1;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Sterilization":
      return theme.palette.areas.contraception.methods.sterilization;

    case "Implant":
      return theme.palette.areas.contraception.methods.implant;

    case "IUD":
      return theme.palette.areas.contraception.methods.iud;

    case "Injectable":
      return theme.palette.areas.contraception.methods.injectable;

    case "Pill":
      return theme.palette.areas.contraception.methods.pill;

    case "Condom":
      return theme.palette.areas.contraception.methods.condom;

    case "Other":
      return theme.palette.areas.contraception.methods.other;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = data.map((method) => ({
    name: method.name,
    data: [
      method.sectors[0].value,
      method.sectors[1].value + method.sectors[2].value,
    ],
    color: getSeriesColor(method.name, theme),
  }));

  return {
    chart: {
      type: "column",
      width: 470,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: ["Public", "Private"],
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        format: absolute ? undefined : "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: absolute ? "normal" : "percent",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Users", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const FpCsF1 = (props) => {
  const [comparisonMode, setComparisonMode] = useState("absolute");

  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  return (
    <Figure
      id="FP_CS_f1"
      number={number.toString()}
      fileStem={`fp_cs_f${number}`}
      title="Contraceptive Users by Method"
      subtitle={`${capitalizePhrase(props.regionName)} by Sector, 2019`}
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        />
      }
      chartOptions={props.data && options(props.data, { comparisonMode })}
    />
  );
};

FpCsF1.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      sectors: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        })
      ),
    })
  ),
};

export default FpCsF1;
