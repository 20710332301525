import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ReactComponent as MarkerIcon } from "../../../images/marker.svg";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  horizontal: {
    alignItems: "center",
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(3),
    whiteSpace: "nowrap",
  },
  icon: {
    fill: "black",
    width: 32,
  },
  marker: {
    marginRight: theme.spacing(),
  },
  name: {
    fontSize: theme.typography.legend.fontSize,
    fontWeight: theme.typography.legend.fontWeight,
    maxWidth: (props) => (props.orientation === "vertical" ? 120 : undefined),
    whiteSpace: "normal",
  },
  series: {
    alignItems: "center",
    display: "flex",
  },
  vertical: {
    display: "grid",
    gridAutoFlow: "row",
    gridRowGap: theme.spacing(0.5),
    whiteSpace: "nowrap",
  },
}));

const CircleMarker = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.marker}>
      <svg height="12" width="12">
        <circle cx="6" cy="6" r="6" fill={props.color} />
      </svg>
    </div>
  );
};

const PointMarker = (props) => {
  const classes = useStyles();

  return (
    <MarkerIcon
      className={classNames(classes.icon, classes.marker)}
      style={{
        fill: props.color,
      }}
    />
  );
};

const Legend = (props) => {
  const classes = useStyles({
    orientation: props.orientation,
  });

  return (
    <div
      className={
        props.orientation === "vertical" ? classes.vertical : classes.horizontal
      }
    >
      {props.series.map((item) => {
        let marker;

        switch (props.markerStyle) {
          case "point":
            marker = <PointMarker color={item.color} />;

            break;

          default:
            marker = <CircleMarker color={item.color} />;

            break;
        }

        return (
          <div key={item.name} className={classes.series}>
            {marker}
            <div className={classes.name}>{item.name}</div>
          </div>
        );
      })}
    </div>
  );
};

Legend.propTypes = {
  series: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string,
    })
  ).isRequired,
  markerStyle: PropTypes.oneOf(["circle", "point"]),
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
};

Legend.defaultProps = {
  markerStyle: "circle",
  orientation: "vertical",
};

export default Legend;
