import { useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "../../Common/Box/Box";
import BoxControls from "../../Common/Box/BoxControls";
import Chart from "../../Common/Box/Chart";
import IncomeGroupChooser from "../../Common/Box/Choosers/IncomeGroupChooser";
import { chartDownloadImageScaleFactor } from "../../../constants";
import initialData from "../../../data/initialData.json";
import exportCharts from "../../../export";
import useAreaQuery from "../../../hooks/useAreaQuery";
import useCsvDownload from "../../../hooks/useCsvDownload";
import {
  getDownloadFileStem,
  getIncomeGroup,
  getTooltip,
} from "../../../utilities";

const key = "FP_f14";
const number = 14;

const getMaximumValue = (data) => {
  let maximumValue = 0;

  for (let method of data) {
    for (let year of method.years) {
      maximumValue = Math.max(maximumValue, year.value);
    }
  }

  return maximumValue;
};

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Sterilization":
      return theme.palette.areas.contraception.methods.sterilization;

    case "Implant":
      return theme.palette.areas.contraception.methods.implant;

    case "IUD":
      return theme.palette.areas.contraception.methods.iud;

    case "Injectable":
      return theme.palette.areas.contraception.methods.injectable;

    case "Pill":
      return theme.palette.areas.contraception.methods.pill;

    case "Condom":
      return theme.palette.areas.contraception.methods.condom;

    case "Other":
      return theme.palette.areas.contraception.methods.other;

    default:
      return "black";
  }
};

const options = (data, maximumValue, showYAxis) => (theme) => {
  const color = getSeriesColor(data.name, theme);

  return {
    chart: {
      type: "spline",
      height: 350,
      backgroundColor: null,
      width: showYAxis ? 176 : 120,
    },
    title: {
      text: data.name,
      align: "center",
      x: showYAxis ? 24 : undefined,
    },
    xAxis: {
      type: "datetime",
      min: 2017,
      max: 2032,
      tickPositions: [2019, 2025, 2030],
      tickLength: 0,
      labels: {
        formatter: function () {
          return [2019, 2030].includes(this.value) ? this.value : null;
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      min: 0,
      max: maximumValue,
      labels: {
        enabled: showYAxis,
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      series: {
        lineWidth: 3,
        marker: {
          symbol: "circle",
          lineWidth: 3,
          fillColor: "white",
        },
        zoneAxis: "x",
        zones: [
          {
            value: 2025,
          },
          {
            dashStyle: "shortDot",
          },
        ],
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Users", this.y);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      enabled: false,
    },
    series: [
      {
        name: data.name,
        color,
        marker: {
          lineColor: color,
        },
        data: data.years.map((year) => [year.year, year.value]),
      },
    ],
  };
};

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: "center",
    display: "flex",
  },
  facet: {
    margin: "2rem 0",
  },
  facetPanel: {
    display: "flex",
    justifyContent: "space-between",
  },
  legend: {
    marginLeft: theme.spacing(4),
  },
}));

const Component = () => {
  const highchartRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const classes = useStyles();

  const [selectedIncomeGroupId, setSelectedIncomeGroupId] = useState(0);

  const { areaData: data } = useAreaQuery(
    `/fp/figures/14/${selectedIncomeGroupId}`,
    initialData["fp_f14"]
  );

  const downloadCsv = useCsvDownload();

  const handleIncomeGroupChange = (incomeGroupId) => {
    setSelectedIncomeGroupId(incomeGroupId);
  };

  const handleCsvDownload = () => {
    const fileStem = getDownloadFileStem(
      key,
      number,
      getIncomeGroup(selectedIncomeGroupId)
    );

    downloadCsv(
      `/fp/figures/14/${selectedIncomeGroupId}/csv`,
      `${fileStem}.csv`
    );
  };

  const yAxisMaximum = getMaximumValue(data) * 1.02;

  return (
    <Box
      id={key}
      caption={`Figure ${number}`}
      title="Change in Contraceptive Users"
      subtitle="By Method, 2019-2030"
      selection={getIncomeGroup(selectedIncomeGroupId)}
      controls={
        <BoxControls>
          <IncomeGroupChooser
            value={selectedIncomeGroupId}
            onChange={handleIncomeGroupChange}
          />
        </BoxControls>
      }
      onDownloadCsv={handleCsvDownload}
      onDownloadPng={() => {
        exportCharts(
          {
            chart1: highchartRefs[0].current,
            chart2: highchartRefs[1].current,
            chart3: highchartRefs[2].current,
            chart4: highchartRefs[3].current,
            chart5: highchartRefs[4].current,
            chart6: highchartRefs[5].current,
            chart7: highchartRefs[6].current,
          },
          {
            filename: key,
            scale: chartDownloadImageScaleFactor,
          }
        );
      }}
    >
      <div className={classes.content}>
        <div className={classes.facetPanel}>
          {data.map((method, index) => (
            <div key={method.name} className={classes.facet}>
              <Chart
                highchartRef={highchartRefs[index]}
                options={options(method, yAxisMaximum, index === 0)}
              />
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
};

export default Component;
