import PropTypes from "prop-types";
import { IconButton, makeStyles } from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../../../images/download.svg";

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.box.download.primary,
    height: 24,
    width: 24,
  },
}));

const DownloadButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick}>
      <DownloadIcon className={classes.icon} />
    </IconButton>
  );
};

DownloadButton.propTypes = {
  onClick: PropTypes.func,
};

export default DownloadButton;
