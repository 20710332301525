import PropTypes from "prop-types";
import { FormControl, FormHelperText, NativeSelect } from "@material-ui/core";
import ChooserOptions from "../Common/Box/Choosers/ChooserOptions";

const regionalUnits = [
  {
    name: "World Bank Regions",
    value: 0,
  },
  {
    name: "UNPD Regions",
    value: 1,
  },
];

const RegionalUnitChooser = (props) => (
  <FormControl>
    <NativeSelect
      label="Regional unit"
      value={props.value}
      onChange={(e) => {
        const id = parseInt(e.target.value, 10);

        props.onChange?.(id);
      }}
    >
      <ChooserOptions options={regionalUnits} />
    </NativeSelect>
    <FormHelperText>Choose the regional unit for your report</FormHelperText>
  </FormControl>
);

RegionalUnitChooser.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

RegionalUnitChooser.defaultProps = {
  value: 0,
};

export default RegionalUnitChooser;
