import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Information from "../../../Common/Box/Information";
import { useReadersGuide } from "../../../../hooks/useReadersGuide";

const getDrugDetails = (productId) => {
  switch (productId) {
    case 111: {
      return {
        interventions: "Iron and folic acid supplementation during pregnancy",
        formulation: "Ferrous Salt + Folic Acid, tablet, 60 + 0.4 mg",
      };
    }

    case 116: {
      return {
        interventions: "Acute hypertension event management",
        formulation: "250 mg tablet",
      };
    }

    case 125: {
      return {
        interventions: "Acute, chronic and gestational hypertension management",
        formulation: "20mg amp",
      };
    }

    case 110: {
      return {
        interventions: "Pre-eclampsia and eclampsia treatment",
        formulation: "5g/10mL ampule",
      };
    }

    case 120: {
      return {
        interventions: "Induction, PPH prevention and treatment",
        formulation: "25, 200mcg tablet",
      };
    }

    case 105: {
      return {
        interventions:
          "Induction, augmentation, postpartum hemorrhage (PPH) prevention and treatment",
        formulation: "10 IU 1ml amp",
      };
    }

    case 108: {
      return {
        interventions: "PPH prevention",
        formulation: "100 µg",
      };
    }

    case 126: {
      return {
        interventions: "PPH treatment",
        formulation: "10 Ml ampoule",
      };
    }

    case 1012: {
      return {
        interventions: "Infection treatment",
        formulation: "500mg/100ml vial",
      };
    }

    default: {
      return {
        interventions: "Unknown",
        formulation: "Unknown",
      };
    }
  }
};

const useStyles = makeStyles(() => ({
  content: {
    width: "100%",
    justifyContent: "flex-start",
  },
  table: {
    "& td:first-child": {
      fontWeight: "bold",
    },
  },
}));

const MhCpB1 = (props) => {
  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const drugDetails = getDrugDetails(props.productId);

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <Information key="MH_CP_b1" number="1" title="Scope" classes={classes}>
      <p>
        The methodology for LEAP was developed to produce results for all low-
        and middle-income countries. Inputs include default assumptions about
        prices which are drawn from global databases, and regional or income
        level estimates for incidence and prevalence of conditions. While we
        make global and regional results available, they should be viewed in
        this context and caution should be used in the application of these
        results for decision making.
      </p>
      <p>
        The methodology for LEAP was developed to produce results for all low-
        and middle-income countries. Inputs include default assumptions about
        prices which are drawn from global databases, and regional or income
        level estimates for incidence and prevalence of conditions. While we
        make global and regional results available, they should be viewed in
        this context and caution should be used in the application of these
        results for decision making.
      </p>

      <table className={classes.table}>
        <tbody>
          <tr>
            <td>Drug</td>
            <td>{props.productName}</td>
          </tr>
          <tr>
            <td>Interventions included</td>
            <td>{drugDetails.interventions}</td>
          </tr>
          <tr>
            <td>Formulation</td>
            <td>{drugDetails.formulation}</td>
          </tr>
        </tbody>
      </table>

      <p>
        See the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("maternal-health-scope")}
        >
          Reader&apos;s Guide
        </Link>{" "}
        for more details.
      </p>
    </Information>
  );
};

MhCpB1.propTypes = {
  productId: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
};

export default MhCpB1;
