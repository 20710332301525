import { useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "../../Common/Box/Box";
import BoxControls from "../../Common/Box/BoxControls";
import Chart from "../../Common/Box/Chart";
import MethodChooser from "../../Common/Box/Choosers/MethodChooser";
import { chartDownloadImageScaleFactor } from "../../../constants";
import initialData from "../../../data/initialData.json";
import exportCharts from "../../../export";
import useAreaQuery from "../../../hooks/useAreaQuery";
import useCsvDownload from "../../../hooks/useCsvDownload";
import { getDownloadFileStem, getMethod, getTooltip } from "../../../utilities";

const key = "FP_f16";
const number = 16;

const getMaximumValue = (data) => {
  let maximumValue = 0;

  for (let incomeGroup of data) {
    for (let year of incomeGroup.years) {
      maximumValue = Math.max(maximumValue, year.value);
    }
  }

  return maximumValue;
};

const getIncomeGroupColor = (name, theme) => {
  switch (name) {
    case "Low":
      return theme.palette.gni.lic;

    case "Lower-Middle":
      return theme.palette.gni.lmic;

    case "Upper-Middle":
      return theme.palette.gni.umic;

    default:
      return "black";
  }
};

const options = (data, maximumValue, showYAxis) => (theme) => {
  const color = getIncomeGroupColor(data.name, theme);

  return {
    chart: {
      type: "spline",
      height: 350,
      backgroundColor: null,
      width: showYAxis ? 236 : 180,
    },
    title: {
      text: data.name,
      align: "center",
      x: showYAxis ? 20 : undefined,
    },
    xAxis: {
      type: "datetime",
      min: 2017,
      max: 2032,
      tickPositions: [2019, 2025, 2030],
      tickLength: 0,
      labels: {
        formatter: function () {
          return [2019, 2025, 2030].includes(this.value) ? this.value : null;
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      min: 0,
      max: maximumValue,
      labels: {
        enabled: showYAxis,
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      series: {
        lineWidth: 3,
        marker: {
          symbol: "circle",
          lineWidth: 3,
          fillColor: "white",
        },
        zoneAxis: "x",
        zones: [
          {
            value: 2025,
          },
          {
            dashStyle: "shortDot",
          },
        ],
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Users", this.y);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      enabled: false,
    },
    series: [
      {
        name: "Maintain",
        data: data.years.map((year) => [year.year, year.value]),
        color,
        marker: {
          lineColor: color,
        },
      },
    ],
  };
};

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: "center",
    display: "flex",
  },
  facet: {
    margin: "2rem 0",
  },
  facetPanel: {
    display: "flex",
    justifyContent: "space-between",
  },
  legend: {
    marginLeft: theme.spacing(4),
  },
}));

const Component = () => {
  const [selectedMethodId, setSelectedMethodId] = useState(0);

  const highchartRefs = [useRef(), useRef(), useRef()];

  const { areaData: data } = useAreaQuery(
    `/fp/figures/16/${selectedMethodId}`,
    initialData["fp_f16"]
  );

  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleMethodChange = (methodId) => {
    setSelectedMethodId(methodId);
  };

  const handleCsvDownload = () => {
    const fileStem = getDownloadFileStem(
      key,
      number,
      getMethod(selectedMethodId)
    );

    downloadCsv(`/fp/figures/16/${selectedMethodId}/csv`, `${fileStem}.csv`);
  };

  const yAxisMaximum = getMaximumValue(data) * 1.02;

  return (
    <Box
      id={key}
      caption={`Figure ${number}`}
      title="Change in Contraceptive Users"
      subtitle="By Income Group, 2019-2030"
      selection={getMethod(selectedMethodId)}
      controls={
        <BoxControls>
          <MethodChooser
            area="contraception"
            value={selectedMethodId}
            onChange={handleMethodChange}
          />
        </BoxControls>
      }
      onDownloadCsv={handleCsvDownload}
      onDownloadPng={() => {
        exportCharts(
          {
            chart1: highchartRefs[0].current,
            chart2: highchartRefs[1].current,
            chart3: highchartRefs[2].current,
          },
          {
            filename: key,
            scale: chartDownloadImageScaleFactor,
          }
        );
      }}
    >
      <div className={classes.content}>
        <div className={classes.facetPanel}>
          {data.map((incomeGroup, index) => (
            <div key={incomeGroup.name} className={classes.facet}>
              <Chart
                highchartRef={highchartRefs[index]}
                options={options(incomeGroup, yAxisMaximum, index === 0)}
              />
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
};

export default Component;
