import { CancelToken } from "axios";
import { useQuery } from "react-query";
import api from "../api/api";

const fetchData = (resource, options) => {
  const source = CancelToken.source();

  const promise = api.fetchData(resource, {
    ...(options ?? {}),
    cancelToken: source.token,
  });

  promise.cancel = () => {
    source.cancel("Query was canceled by React Query");
  };

  return promise;
};

const useAreaQuery = (resource, initialData, options) => {
  const query = useQuery(resource, () => fetchData(resource, options), {
    keepPreviousData: true,
  });

  return {
    ...query,
    areaData: query.data?.data ?? initialData,
  };
};

export default useAreaQuery;
