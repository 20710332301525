import { Link, Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const AbortionTotalAbortions = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Total Abortions and Miscarriages</Typography>

      <p>
        We modelled the total number of abortions and miscarriages in each
        country. First, we made projections of the number of abortions and
        miscarriages using the{" "}
        <ExternalLink
          href="https://www.avenirhealth.org/software-spectrum.php"
          text="FamPlan module in Spectrum"
        />{" "}
        for each country, that accounted for changes in contraceptive use and
        method mix aligned with the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-scope")}
        >
          Contraception
        </Link>{" "}
        section of LEAP
        <Footnote number={10}>
          Use of traditional contraceptive methods based on UN projections were
          also included in the projections for abortions, as these methods are
          not included within the contraceptive section of LEAP.
        </Footnote>
        . FamPlan results for the number of intended pregnancies were used; a
        global assumption was made that 18% of intended pregnancies end in
        miscarriage
        <Footnote number={11}>
          Estimates of miscarriage rates range from 10-20%; 18% was used to
          create alignment with baseline live birth numbers from FamPlan.
        </Footnote>
        .
      </p>
      <p>
        Next, the total number of contraceptive users by method were multiplied
        by typical use failure rates to estimate the number of unintended
        pregnancies as a result of method failure
        <Footnote number={12}>
          Aligned with projections developed for the contraception section of
          LEAP with the addition of traditional methods based on UNPD
          projections. The following typical use effectiveness rates were used:
          sterilization (100%), implant (99.7%), IUD (98.8%), injection (98%),
          pill (93.7%), male condom (91.4%), EC (93.7%), LAM (85%), other modern
          (85%), traditional (81.9%).
        </Footnote>
        . UNPD projections for women with an unmet need were used to estimate
        the total number of women with an unmet need
        <Footnote number={13}>
          United Nations, Department of Economic and Social Affairs, Population
          Division (2020){" "}
          <ExternalLink
            href="https://www.un.org/en/development/desa/population/theme/family-planning/cp_model.asp"
            text="Estimates and Projections of Family Planning Indicators 2020"
            useItalics={true}
          />{" "}
          New York: United Nations.
        </Footnote>{" "}
        each year; it was estimated that 41% of these women experience an
        unintended pregnancy
        <Footnote number={14}>
          Based on an estimate provided by Adding it Up and used in other impact
          models, for example, MSI Reproductive Choices{" "}
          <ExternalLink
            href="https://www.msichoices.org/media/3319/impact_25_summary_of_changes_june_2018-1.pdf"
            text="Impact 2"
          />{" "}
          model.
        </Footnote>
        . We summed these two estimates of unintended pregnancies together to
        estimate total unintended pregnancies in each country and for each year.
      </p>
      <p>
        To estimate the number of abortions, sub-regional estimates of the
        proportion of unintended pregnancies ending in abortion
        <Footnote number={15}>
          Estimates for 2015-2019 by sub-region based on Appendix Table 4 from{" "}
          <ExternalLink
            href="http://dx.doi.org/10.1016/S2214-109X(20)30315-6"
            text="Bearak et al (2020)"
          />
          .
        </Footnote>{" "}
        were used to calculate total abortions. We decided to hold these
        proportions constant, as historical trends show movement in different
        directions in different regions, making it difficult to predict how
        these patterns may change. To estimate the number of miscarriages from
        unintended pregnancies, we used a global rate of 0.07 miscarriages per
        abortion
        <Footnote number={16}>
          Aligned to an assumption developed for the MSI Reproductive Choices{" "}
          <ExternalLink
            href="https://www.msichoices.org/what-we-do/our-approach/our-technical-expertise/impact-2/"
            text="Impact 2"
          />{" "}
          model; this accounts for the fact that miscarriage rates will be lower
          for unintended pregnancies than intended pregnancies, as some
          pregnancies will be ended by an abortion before a miscarriage would
          have occurred if the pregnancy had continued.
        </Footnote>
        .
      </p>
      <p>
        Note that the total number of abortions estimated through our approach
        (58.6 million in 2019) is lower than the estimate published by Bearak et
        al. (2020) (69.4 million on average for 2015-2019)
        <Footnote number={17}>
          Bearak J, Popinchalk A, Ganatra B, et al{" "}
          <ExternalLink
            href="http://dx.doi.org/10.1016/S2214-109X(20)30315-6"
            text="Unintended pregnancy and abortion by income, region, and the legal status of abortion: estimates from a comprehensive model for 1990–2019"
            useItalics={true}
          />{" "}
          Lancet Global Health 2020; published online July 22.
        </Footnote>
        .
      </p>
      <p>
        There is a large uncertainty in estimating both unintended pregnancy and
        abortion due in part to the lack of reliable data and definitional
        challenges in measuring pregnancy intention and outcomes. In addition,
        our model uses regional averages as abortion rates are generally not
        available at country level. Our estimates for unintended pregnancy align
        with our estimates for the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-scope")}
        >
          Contraception
        </Link>{" "}
        section of LEAP, and our abortion estimates are consistent with earlier
        abortions estimates which used a similar methodology as LEAP{" "}
        <Footnote number={18}>
          Singh S, Remez L, Sedgh G, Kwok L, Onda T.{" "}
          <ExternalLink
            href="https://www.guttmacher.org/sites/default/files/report_pdf/abortion-worldwide-2017.pdf"
            text="Abortion Worldwide 2017: Uneven Progress and Unequal Access"
          />{" "}
          Guttmacher Institute. 2018.
        </Footnote>
        .
      </p>
    </div>
  );
};

export default AbortionTotalAbortions;
