import { Link } from "@material-ui/core";
import Information from "../../Common/Box/Information";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const FpB2 = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <Information
      key="FP_b2"
      number="2"
      title="The Role of Subsidy in the Private Sector"
    >
      <p>
        Over the past few years the Commodity Gap Analysis refined an approach
        to highlighting the role of subsidy within the private sector; thereby
        differentiating consumers who purchase commercial products from those
        who receive free or subsidized supplies, often though not exclusively
        through NGOs and social marketing organizations (SMOs). This LEAP
        analysis continues that approach, drawing on updated data sources (see
        the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-users-by-sector")}
        >
          Reader&apos;s Guide
        </Link>
        ). Subsidy in the private sector may come from multiples public sector
        sources, including national governments
        <Footnote number={6}>
          <ExternalLink
            href="https://india.unfpa.org/sites/default/files/pub-pdf/AssessmentofContraceptiveSocialMarketingProgramme_LowRes.pdf"
            text="Assessment of Contraceptive Social Marketing Programme: Ministry of Health and Family Welfare, Government of India"
            useItalics={true}
          />{" "}
          UNFPA: India, 2015.
        </Footnote>
        .
      </p>
      <p>
        Service delivery models are continuing to evolve through the engagement
        of insurance schemes and the contracting of private sector providers. In
        addition, new market development approaches are moving away from a
        direct subsidy on supplies themselves to using subsidies to address
        market constraints
        <Footnote number={7}>
          Husband R. 2017{" "}
          <ExternalLink
            href="https://www.psi.org/2017/03/how-psi-is-shaping-markets-to-address-global-unmet-need/"
            text="How PSI is Shaping Markets to Address Global Unmet Need"
            useItalics={true}
          />{" "}
          UNFPA: India, 2015.
        </Footnote>
        <Footnote number={8}>
          Dalberg Global Development Advisors and Reproductive Health Supplies
          Coalition. 2014{" "}
          <ExternalLink
            href="https://www.rhsupplies.org/fileadmin/uploads/rhsc/Uploads/Documents/Dalberg_Report_Market_Shaping_for_Family_Planning_WEB.pdf"
            text="Market Shaping for Family Planning"
            useItalics={true}
          />
          .
        </Footnote>
        . These changes will continue to blur the distinction between what is
        public and private.
      </p>
    </Information>
  );
};

export default FpB2;
