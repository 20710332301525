import { Link } from "react-router-dom";
import Information from "../../Common/Box/Information";

const FpB4 = () => (
  <Information
    key="FP_b4"
    number="4"
    title="Impact of Changes in Contraceptive Use on Other LEAP Projections"
  >
    The changes to contraceptive use and method mix presented here also impact
    other sections of this report insofar as they form the basis for the
    demographic projections underlying the other three landscape reports. Use of
    hormonal contraceptives, for example, reduces bleeding and thus lower the
    need for <Link to="/menstrual-hygiene">menstrual hygiene</Link> supplies.
    Similarly, increases in contraceptive use (and subsequent reductions in
    unmet need) reduce the number of unintended pregnancies, thereby impacting
    the need for <Link to="/abortion">abortion and PAC</Link> supplies and{" "}
    <Link to="/maternal-health">maternal health</Link> supplies.
  </Information>
);

export default FpB4;
