import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const useStyles = makeStyles((theme) => ({
  table: {
    "& td": {
      padding: theme.spacing(0, 1),
    },
  },
}));

const ContraceptionTerms = () => {
  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Key Terms and Acronyms</Typography>

      <p>
        <strong>Unit</strong>: The &apos;unit&apos; for contraception is a
        &apos;user&apos;, defined as a person using a modern contraceptive
        method. We calculate contraceptive use and measure contraceptive
        prevalence based on the women using methods. Contraceptive users may use
        a method themselves, or rely on protection provided by their
        partner&apos;s method use. While in some places in this report we use
        the term &apos;women and girls&apos; for readability purposes, we
        recognize that some users may identify as transgender men and non-binary
        people. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-total-users")}
        >
          Total Users
        </Link>{" "}
        below for more detail.
      </p>
      <p>
        <strong>Quantities</strong>: The &apos;quantities&apos; for
        contraception are the contraceptive commodities and associated clinical
        supplies (referred to collectively as &apos;supplies&apos;) that a user
        of contraception must personally use over the course of a year to avoid
        becoming pregnant. For long-acting and permanent methods only a subset
        of users will require a supply in a given year, while for short-acting
        methods multiple supplies are needed over a year. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-quantities")}
        >
          Supply Quantities
        </Link>{" "}
        below for more detail.
      </p>
      <p>
        <strong>Cost</strong>: The &apos;cost&apos; reflects the last price paid
        for a contraceptive before it is actually used or obtained by the user.
        We employ this definition because it is at this &quot;handover&quot;
        point in a product&apos;s value chain where price acts as the principal
        cost barrier to access. For products made available through the public
        sector, cost is calculated on the basis of unit costs paid by procurers
        (Governments and/or donors) before it is distributed, generally for
        free, to users. In the private sector, costs reflect the prices paid by
        users themselves, typically through direct out-of-pocket expenditures.
        See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-costs")}
        >
          Supply Costs
        </Link>{" "}
        below for more detail.
      </p>

      <p>
        <strong>Acronyms</strong>:
      </p>

      <table className={classes.table}>
        <tbody>
          <tr>
            <td>CYP</td>
            <td>Couple Years of Protection</td>
          </tr>
          <tr>
            <td>DHS</td>
            <td>Demographic and Health Survey</td>
          </tr>
          <tr>
            <td>EC</td>
            <td>Emergency Contraception</td>
          </tr>
          <tr>
            <td>FP</td>
            <td>Family Planning</td>
          </tr>
          <tr>
            <td>IUD</td>
            <td>Intrauterine Device</td>
          </tr>
          <tr>
            <td>LAM</td>
            <td>Lactational Amenorrhea Method</td>
          </tr>
          <tr>
            <td>LEAP</td>
            <td>
              LandscapE And Projection of Reproductive Health Supply Needs
            </td>
          </tr>
          <tr>
            <td>MICS</td>
            <td>Multiple Indicator Cluster Survey</td>
          </tr>
          <tr>
            <td>NGO</td>
            <td>Non-Governmental Organization</td>
          </tr>
          <tr>
            <td>PMA</td>
            <td>Performance Monitoring for Accountability</td>
          </tr>
          <tr>
            <td>SDM</td>
            <td>Standard Days Method</td>
          </tr>
          <tr>
            <td>SMO</td>
            <td>Social Marketing Organization</td>
          </tr>
          <tr>
            <td>UN</td>
            <td>United Nations</td>
          </tr>
          <tr>
            <td>UNPD</td>
            <td>United Nations Population Division</td>
          </tr>
          <tr>
            <td>VAN</td>
            <td>Visibility and Analytics Network</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ContraceptionTerms;
