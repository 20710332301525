import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import SectorChooser from "../../../Common/Box/Choosers/SectorChooser";
import Figure from "../../../Common/Box/Figure";
import { capitalizePhrase, getTooltip } from "../../../../utilities";

const number = 5;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Sterilization":
      return theme.palette.areas.contraception.methods.sterilization;

    case "Implant":
      return theme.palette.areas.contraception.methods.implant;

    case "IUD":
      return theme.palette.areas.contraception.methods.iud;

    case "Injectable":
      return theme.palette.areas.contraception.methods.injectable;

    case "Pill":
      return theme.palette.areas.contraception.methods.pill;

    case "Condom":
      return theme.palette.areas.contraception.methods.condom;

    case "Other":
      return theme.palette.areas.contraception.methods.other;

    default:
      return "black";
  }
};

const getSeries = (sectorId, users, costs) =>
  users.map((method, index) => {
    const methodCosts = costs[index];

    let data;

    switch (sectorId) {
      case 1:
        data = [method.sectors[2].value, methodCosts.sectors[2].value];

        break;

      case 2:
        data = [method.sectors[1].value, methodCosts.sectors[1].value];

        break;

      case 3:
        data = [method.sectors[0].value, methodCosts.sectors[0].value];

        break;

      case 10:
        data = [
          method.sectors[1].value + method.sectors[2].value,
          methodCosts.sectors[1].value + methodCosts.sectors[2].value,
        ];

        break;

      default:
        data = [
          method.sectors.reduce(
            (accumulator, { value }) => accumulator + value,
            0
          ),
          methodCosts.sectors.reduce(
            (accumulator, { value }) => accumulator + value,
            0
          ),
        ];

        break;
    }

    return {
      name: method.name,
      data,
    };
  });

const options = (data) => (theme) => {
  const series = data.map((series) => ({
    ...series,
    color: getSeriesColor(series.name, theme),
  }));

  return {
    chart: {
      type: "column",
      width: 470,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: ["Users", "Costs"],
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      max: 100,
      labels: {
        format: "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "percent",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        const indicator = this.x === "Costs" ? "Cost" : "Users";

        return getTooltip(indicator, this.y, this.percentage, {
          prefix: indicator === "Cost" ? "$" : undefined,
        });
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const FpCsF5 = (props) => {
  const series = getSeries(props.sectorId, props.users, props.costs);

  return (
    <Figure
      id="FP_CS_f5"
      number={number.toString()}
      fileStem={`fp_cs_f${number}`}
      title="Contraceptive Use vs Supply Cost"
      subtitle={`${capitalizePhrase(props.regionName)} by Method, 2019`}
      controls={
        <BoxControls>
          <SectorChooser
            value={props.sectorId}
            onChange={props.onSectorChange}
          />
        </BoxControls>
      }
      chartOptions={options(series)}
    />
  );
};

FpCsF5.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
  sectorId: PropTypes.number.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      sectors: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  costs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      sectors: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  onSectorChange: PropTypes.func,
};

export default FpCsF5;
