import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-scroll";
import { lighten } from "../../utilities";

const useStyles = makeStyles((theme) => ({
  activeLink: {
    fontWeight: "bold",
    color: theme.palette.area.primary,
  },
  link: {
    textDecoration: "none",
    color: lighten(theme.palette.area.primary, 0.5),
    transition: "color 0.25s ease-in",
    "&:hover": {
      color: theme.palette.area.primary,
    },
  },
}));

const SectionMenuItem = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Link
        to={props.linkTo}
        offset={-140}
        smooth={true}
        duration={300}
        spy={true}
        hashSpy={false}
        className={classes.link}
        activeClass={classes.activeLink}
      >
        {props.children}
      </Link>
    </div>
  );
};

SectionMenuItem.propTypes = {
  linkTo: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SectionMenuItem;
