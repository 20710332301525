import { Typography } from "@material-ui/core";

const Interpretations = () => (
  <div>
    <Typography variant="h1">Interpretations</Typography>

    <p>
      While sometimes LEAP refers to &apos;women and girls&apos; for readability
      purposes, we recognize that some people who use contraception, menstruate
      or access abortion, PAC or maternal health services identify as
      transgender men or non-binary people rather than a woman or a girl.
    </p>
    <p>
      LEAP presents the quantities and associated costs of supplies that are
      utilized by contraceptive users, menstruators, and those accessing
      abortion, PAC, or maternal health services.
    </p>
    <p>
      The costs presented in LEAP focus on supply costs; these are not the same
      as procurement volumes and costs, which may be higher or lower than supply
      costs depending on context. Procurement needs reflect the volume needed to
      fill supply pipelines and maintain adequate inventory levels, and also
      take account for volumes already in the supply chain.
    </p>
    <p>
      In addition, LEAP only costs the supplies themselves and not the cost of
      skilled providers and clean clinical spaces that may be needed to provide
      some services. While important, these are beyond the scope of LEAP.
    </p>
  </div>
);

export default Interpretations;
