import { makeStyles } from "@material-ui/styles";
import Chart from "../../Common/Box/Chart";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import Information from "../../Common/Box/Information";
import SideBySide from "../../Common/SideBySide";
import { getTooltip } from "../../../utilities";

const number = 7;

const chartOptions = (theme) => ({
  chart: {
    type: "spline",
    width: 470,
    height: 400,
    backgroundColor: null,
  },
  xAxis: {
    type: "datetime",
    min: 2017,
    max: 2032,
    tickPositions: [2019, 2025, 2030],
    tickLength: 0,
    labels: {
      formatter: function () {
        return [2019, 2025, 2030].includes(this.value) ? this.value : null;
      },
      style: {
        color: theme.figure.axis.secondary,
        fontSize: theme.figure.axis.fontSize,
      },
    },
  },
  yAxis: {
    title: undefined,
    min: 0,
    labels: {
      formatter: function () {
        return "$" + this.axis.defaultLabelFormatter.call(this);
      },
      style: {
        color: theme.figure.axis.secondary,
        fontSize: theme.figure.axis.fontSize,
      },
    },
  },
  plotOptions: {
    series: {
      lineWidth: 3,
      marker: {
        symbol: "circle",
        lineWidth: 3,
        fillColor: "white",
      },
      zoneAxis: "x",
      zones: [
        {
          value: 2025,
        },
        {
          dashStyle: "shortDot",
        },
      ],
    },
  },
  legend: {
    layout: "vertical",
  },
  tooltip: {
    backgroundColor: "white",
    useHTML: true,
    outside: true,
    formatter: function () {
      return getTooltip("Cost", this.y, undefined, {
        prefix: "$",
      });
    },
    style: {
      fontSize: "0.875rem",
      color: theme.palette.tooltip.primary,
    },
  },
  exporting: {
    enabled: false,
  },
  series: [
    {
      name: "Current: Miso & mife not in combipack",
      data: [2019, 2025, 2030],
      color: "#439EB7",
      marker: {
        lineColor: "#439EB7",
      },
    },
    {
      name: "Combipack: Current prices",
      data: [
        [2019, 207194541],
        [2025, 253925653],
        [2030, 306786023],
      ],
      color: "#E28B55",
      marker: {
        lineColor: "#E28B55",
      },
    },
    {
      name: "Combipack: $3.54 everywhere",
      data: [
        [2019, 189478735],
        [2025, 190616659],
        [2030, 192988089],
      ],
      color: "#DCB64D",
      marker: {
        lineColor: "#DCB64D",
      },
    },
  ],
});

const useStyles = makeStyles((theme) => ({
  chart: {
    backgroundColor: theme.palette.area.background,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: theme.spacing(3),
  },
  titles: {
    margin: theme.spacing(0, 2, 2, 2),
  },
  title: {
    fontSize: "1.1rem",
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: "1.1rem",
    fontStyle: "italic",
    fontWeight: theme.typography.fontWeightLight,
  },
}));

const AccompanyingChart = ({ options }) => {
  const classes = useStyles();

  return (
    <div className={classes.chart}>
      <div className={classes.titles}>
        <div className={classes.title}>
          Change in Cost of Abortion &amp; PAC Supplies with Combipacks
        </div>
        <div className={classes.subtitle}>Future Scenario 3, 2019-2030</div>
      </div>

      <Chart options={options} />
    </div>
  );
};

const Component = () => (
  <Information
    key="APAC_be"
    number={number.toString()}
    title="Mifepristone Costs, and Potential Impact of Combipacks"
  >
    <SideBySide component={<AccompanyingChart options={chartOptions} />}>
      <p>
        Mifepristone currently has a much higher cost than misoprostol (see
        Table 3); this is due to the relatively complex pathways of the active
        pharmaceutical ingredient, and the limited number of commercial
        manufacturers of the product
        <Footnote number={21}>
          Clark H, RamoRao S, Townsend J{" "}
          <ExternalLink
            href="https://www.rhsupplies.org/uploads/tx_rhscpublications/Safe_Abortion_Supplies_Landscaping_Report.pdf"
            text="Ensuring Access to Safe Abortion Supplies: Landscaping of barriers and Opportunities"
            useItalics={true}
          />{" "}
          2017: Population Council.
        </Footnote>
        . Packaging mifepristone with misoprostol into a &apos;combipack&apos;
        is one strategy to reduce the cost of this product.
      </p>
      <p>
        Looking at Scenario 3, where the combination of mifepristone and
        misoprostol becomes a larger share of the method mix, we compare the
        cost of necessary number of pills for abortions occurring before 12
        weeks gestation packaged separately versus co-packaged in a
        &quot;combipack&quot; form. Given current prices, the overall cost of
        abortion and PAC services would be 14% lower if combipacks were used
        based on current combipack pricing.
      </p>
      <p>
        The cost of mifepristone, and cost of combipacks, varies substantially
        by region. As demand for these products grows, and as manufacturers
        secure regulatory authority in more places and see the prospects for
        greater return on investment, it is possible that greater competition
        will drive down the costs, leading to a reduction in total costs for
        abortion and PAC supplies. For example, if all regions had access to the
        inexpensive combipacks available in Asia (median price $3.54), costs
        would decline by 46%.
      </p>
    </SideBySide>
  </Information>
);

export default Component;
