import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import ApacCsF7 from "../Figures/ApacCsF7";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeChangeDirection,
  getQualitativeChangeMagnitude,
} from "../../../../utilities";

const calculateChange = (years) => {
  if (!years) {
    return {
      initial: 0,
      final: 0,
      change: 0,
      percentageChange: 0,
    };
  }

  const initial = years[0].cost;
  const final = years[2].cost;
  const change = final - initial;

  return {
    initial,
    final,
    change,
    percentageChange: (change / initial) * 100,
  };
};

const calculateMetrics = (data) => ({
  surgicalAspirationServicesUnderScenario1: calculateChange(
    data?.[0].scenarios[0].years
  ),
  medicalServicesUnderScenario1: calculateChange(data?.[1].scenarios[0].years),
  surgicalAspirationServicesUnderScenario2: calculateChange(
    data?.[0].scenarios[1].years
  ),
  medicalServicesUnderScenario2: calculateChange(data?.[1].scenarios[1].years),
  surgicalAspirationServicesUnderScenario3: calculateChange(
    data?.[0].scenarios[2].years
  ),
  medicalServicesUnderScenario3: calculateChange(data?.[1].scenarios[2].years),
  misoMifeMedicalServicesUnderScenario3: calculateChange(
    data?.[3].scenarios[2].years
  ),
  misoMedicalServicesUnderScenario3: calculateChange(
    data?.[2].scenarios[2].years
  ),
});

const useStyles = makeStyles((theme) => ({
  list: {
    "& li": {
      margin: theme.spacing(2, 0),
    },
  },
}));

const ApacCsS8 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/apac/cs/figures/7/${props.regionId}`
  );

  const classes = useStyles();

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        Between 2019 and 2030, different trends in the cost of abortion and PAC
        services are seen by method across the three scenarios (Figure 7).
      </p>

      <ul className={classes.list}>
        <li>
          With no changes in the safety profile or method mix (Scenario 1), the
          supply cost of surgical aspiration services would see a{" "}
          {getQualitativeChangeMagnitude(
            metrics.surgicalAspirationServicesUnderScenario1.percentageChange
          )}{" "}
          {getQualitativeChangeDirection(
            metrics.surgicalAspirationServicesUnderScenario1.percentageChange
          )}{" "}
          from{" "}
          {formatNumberAsHumanReadable(
            metrics.surgicalAspirationServicesUnderScenario1.initial,
            {
              prefix: "$",
            }
          )}{" "}
          in 2019 to{" "}
          {formatNumberAsHumanReadable(
            metrics.surgicalAspirationServicesUnderScenario1.final,
            {
              prefix: "$",
            }
          )}{" "}
          in 2030 (
          {formatNumberAsHumanReadable(
            metrics.surgicalAspirationServicesUnderScenario1.change,
            {
              prefixWithSign: true,
              prefix: "$",
            }
          )}
          ,{" "}
          {formatPercentage(
            metrics.surgicalAspirationServicesUnderScenario1.percentageChange,
            {
              prefixWithSign: true,
            }
          )}
          ); and the supply cost of medical services would see a{" "}
          {getQualitativeChangeMagnitude(
            metrics.medicalServicesUnderScenario1.percentageChange
          )}{" "}
          {getQualitativeChangeDirection(
            metrics.medicalServicesUnderScenario1.percentageChange
          )}{" "}
          from{" "}
          {formatNumberAsHumanReadable(
            metrics.medicalServicesUnderScenario1.initial,
            {
              prefix: "$",
            }
          )}{" "}
          in 2019 to{" "}
          {formatNumberAsHumanReadable(
            metrics.medicalServicesUnderScenario1.final,
            {
              prefix: "$",
            }
          )}{" "}
          in 2030 (
          {formatNumberAsHumanReadable(
            metrics.medicalServicesUnderScenario1.change,
            {
              prefixWithSign: true,
              prefix: "$",
            }
          )}
          ,{" "}
          {formatPercentage(
            metrics.medicalServicesUnderScenario1.percentageChange,
            {
              prefixWithSign: true,
            }
          )}
          ).
        </li>

        <li>
          With a shift in safety profile (Scenario 2), the supply cost of
          surgical aspiration services would see a{" "}
          {getQualitativeChangeMagnitude(
            metrics.surgicalAspirationServicesUnderScenario2.percentageChange
          )}{" "}
          {getQualitativeChangeDirection(
            metrics.surgicalAspirationServicesUnderScenario2.percentageChange
          )}{" "}
          from{" "}
          {formatNumberAsHumanReadable(
            metrics.surgicalAspirationServicesUnderScenario2.initial,
            {
              prefix: "$",
            }
          )}{" "}
          in 2019 to{" "}
          {formatNumberAsHumanReadable(
            metrics.surgicalAspirationServicesUnderScenario2.final,
            {
              prefix: "$",
            }
          )}{" "}
          in 2030 (
          {formatNumberAsHumanReadable(
            metrics.surgicalAspirationServicesUnderScenario2.change,
            {
              prefixWithSign: true,
              prefix: "$",
            }
          )}
          ,{" "}
          {formatPercentage(
            metrics.surgicalAspirationServicesUnderScenario2.percentageChange,
            {
              prefixWithSign: true,
            }
          )}
          ); and the supply cost of medical services would see a{" "}
          {getQualitativeChangeMagnitude(
            metrics.medicalServicesUnderScenario2.percentageChange
          )}{" "}
          {getQualitativeChangeDirection(
            metrics.medicalServicesUnderScenario2.percentageChange
          )}{" "}
          from{" "}
          {formatNumberAsHumanReadable(
            metrics.medicalServicesUnderScenario2.initial,
            {
              prefix: "$",
            }
          )}{" "}
          in 2019 to{" "}
          {formatNumberAsHumanReadable(
            metrics.medicalServicesUnderScenario2.final,
            {
              prefix: "$",
            }
          )}{" "}
          in 2031 (
          {formatNumberAsHumanReadable(
            metrics.medicalServicesUnderScenario2.change,
            {
              prefixWithSign: true,
              prefix: "$",
            }
          )}
          ,{" "}
          {formatPercentage(
            metrics.medicalServicesUnderScenario2.percentageChange,
            {
              prefixWithSign: true,
            }
          )}
          ).
        </li>

        <li>
          With a shift in safety profile and method mix (Scenario 3), the supply
          cost of surgical aspiration services would see a{" "}
          {getQualitativeChangeMagnitude(
            metrics.surgicalAspirationServicesUnderScenario3.percentageChange
          )}{" "}
          {getQualitativeChangeDirection(
            metrics.surgicalAspirationServicesUnderScenario3.percentageChange
          )}{" "}
          from{" "}
          {formatNumberAsHumanReadable(
            metrics.surgicalAspirationServicesUnderScenario3.initial,
            {
              prefix: "$",
            }
          )}{" "}
          in 2019 to{" "}
          {formatNumberAsHumanReadable(
            metrics.surgicalAspirationServicesUnderScenario3.final,
            {
              prefix: "$",
            }
          )}
          in 2030 (
          {formatNumberAsHumanReadable(
            metrics.surgicalAspirationServicesUnderScenario3.change,
            {
              prefixWithSign: true,
              prefix: "$",
            }
          )}
          ,{" "}
          {formatPercentage(
            metrics.surgicalAspirationServicesUnderScenario3.percentageChange,
            {
              prefixWithSign: true,
            }
          )}
          ); and the supply cost of medical services would see a{" "}
          {getQualitativeChangeMagnitude(
            metrics.medicalServicesUnderScenario3.percentageChange
          )}{" "}
          {getQualitativeChangeDirection(
            metrics.medicalServicesUnderScenario3.percentageChange
          )}{" "}
          from{" "}
          {formatNumberAsHumanReadable(
            metrics.medicalServicesUnderScenario3.initial,
            {
              prefix: "$",
            }
          )}{" "}
          in 2019 to{" "}
          {formatNumberAsHumanReadable(
            metrics.medicalServicesUnderScenario3.final,
            {
              prefix: "$",
            }
          )}{" "}
          in 2030 (
          {formatNumberAsHumanReadable(
            metrics.medicalServicesUnderScenario3.change,
            {
              prefixWithSign: true,
              prefix: "$",
            }
          )}
          ,{" "}
          {formatPercentage(
            metrics.medicalServicesUnderScenario3.percentageChange,
            {
              prefixWithSign: true,
            }
          )}
          ).
        </li>
      </ul>

      {data && (
        <ApacCsF7
          regionId={props.regionId}
          regionName={props.regionName}
          data={data}
        />
      )}
    </>
  );
};

ApacCsS8.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default ApacCsS8;
