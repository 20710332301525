import PropTypes from "prop-types";
import ChooserOptions from "../Common/Box/Choosers/ChooserOptions";
import Select from "../Common/Box/Select";

const getAreaIndicators = (area) => {
  const indicators = [
    {
      name: "Menstruators",
      value: 2,
    },
    {
      name: "Users",
      value: 5,
    },
    {
      name: "Quantities",
      value: 3,
    },
    {
      name: "Cases",
      value: 4,
    },
    {
      name: "Costs",
      value: 1,
    },
  ];

  switch (area) {
    case "abortion":
      return indicators.filter(({ value }) => [3, 1].includes(value));

    case "contraception":
      return indicators.filter(({ value }) => [5, 3, 1].includes(value));

    case "maternal-health":
      return [
        {
          name: "Cases",
          value: 4,
        },
        {
          name: "Quantities",
          value: 3,
        },
        {
          name: "Costs",
          value: 1,
        },
      ];

    case "menstrual-hygiene":
      return indicators.filter(({ value }) => [2, 3, 1].includes(value));

    default:
      return indicators;
  }
};

const IndicatorChooser = (props) => (
  <Select
    label="Indicator"
    value={props.value}
    onChange={(value) => {
      props.onChange?.(parseInt(value, 10));
    }}
  >
    <ChooserOptions options={getAreaIndicators(props.area)} />
  </Select>
);

IndicatorChooser.propTypes = {
  area: PropTypes.oneOf([
    "contraception",
    "menstrual-hygiene",
    "abortion",
    "maternal-health",
  ]).isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default IndicatorChooser;
