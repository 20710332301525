import { useState } from "react";
import PropTypes from "prop-types";
import ApacCcF3 from "../Figures/ApacCcF3";
import useAreaQuery from "../../../../hooks/useAreaQuery";

const ApacCcS5 = (props) => {
  const [selectedScenarioId, setSelectedScenarioId] = useState(5);

  const { areaData: data } = useAreaQuery(
    `/custom/apac/cc/figures/3/${props.regionalUnitId}/${selectedScenarioId}`
  );

  const handleScenarioChange = (scenarioId) => {
    setSelectedScenarioId(scenarioId);
  };

  return (
    <>
      <p>
        Patterns in the use of abortion and PAC services would vary across
        countries. Use the dropdown below to explore differences by method
        between the three scenarios in 2030.
      </p>

      <ApacCcF3
        regionalUnitName={props.regionalUnitName}
        scenarioId={selectedScenarioId}
        data={data}
        onScenarioChange={handleScenarioChange}
      />
    </>
  );
};

ApacCcS5.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default ApacCcS5;
