import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import Information from "../../Common/Box/Information";

const Component = () => (
  <Information
    key="APAC_b3"
    number="3"
    title="Cost of medical abortion by income group"
  >
    <p>
      The huge variation in the cost of misoprostol and mifepristone by
      geographic region results in some notable patterns in the cost of medical
      abortion by income group.
    </p>

    <ul>
      <li>
        For both misoprostol and mifepristone, the lowest average cost per pill
        is found in lower-middle-income countries, most likely as this is where
        most of the production of these products occurs.
      </li>
      <li>
        On average, misoprostol pills cost almost three times as much in both
        low-income and upper-middle-income countries compared to
        lower-middle-income countries.
      </li>
      <li>
        The average cost of mifepristone in low-income countries is almost
        double that of lower-middle-income countries, and exceeds even the
        average cost of mifepristone in high-income countries.
      </li>
    </ul>

    <p>
      These cost variations mean that the supply costs for medical abortion in
      low-income countries are currently the same, if not more than, the supply
      costs for medical abortion in high-income countries. This high cost in
      low-income countries may reflect legal restrictions on abortion which
      often drive up prices and create reliance on the black market to source
      supplies
      <Footnote number={18}>
        Clark H, RamoRao S, Townsend J{" "}
        <ExternalLink
          href="https://www.rhsupplies.org/uploads/tx_rhscpublications/Safe_Abortion_Supplies_Landscaping_Report.pdf"
          text="Ensuring Access to Safe Abortion Supplies: Landscaping of barriers and Opportunities"
          useItalics={true}
        />{" "}
        2017: Population Council.
      </Footnote>
      . Barriers to abortion such as social stigma may also further restrict
      access to abortion services in low-income countries.
    </p>
  </Information>
);

export default Component;
