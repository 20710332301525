import { useState } from "react";
import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { capitalizePhrase, getTooltip } from "../../../../utilities";

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Surgical: Vacuum aspiration":
      return theme.palette.areas.abortion.methods.mva;

    case "Medical: Miso only":
      return theme.palette.areas.abortion.methods.miso;

    case "Medical: Miso & mife":
      return theme.palette.areas.abortion.methods.mife;

    case "Surgical: Dilation (not costed)":
      return theme.palette.areas.abortion.methods.dilation;

    case "Least safe abortions (not costed)":
      return theme.palette.areas.abortion.methods.leastSafeAbortions;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = data.map((contraceptionType) => ({
    name: contraceptionType.name,
    data: contraceptionType.countryGroups.map(({ services }) => services),
    color: getSeriesColor(contraceptionType.name, theme),
  }));

  return {
    chart: {
      type: "column",
      width: 600,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: [
        props.regionShortName,
        "Low",
        "Lower-Middle",
        "Upper-Middle",
      ],
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
      plotLines: [
        {
          color: theme.figure.gridLineColor,
          dashStyle: "solid",
          value: 0.5,
          width: 3,
          zIndex: 2,
        },
      ],
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        format: absolute ? undefined : "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: absolute ? "normal" : "percent",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
        textOverflow: null,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Services", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const ApacCsF2 = (props) => {
  const [comparisonMode, setComparisonMode] = useState("relative");

  const { areaData: data } = useAreaQuery(
    `/custom/apac/cs/figures/2/${props.regionId}`
  );
  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  return (
    <Figure
      id="APAC_CS_f2"
      number="2"
      title="Abortion and PAC Services by Method"
      subtitle={`${capitalizePhrase(
        props.regionName
      )} and all Low- and Middle-Income Countries, 2019`}
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        />
      }
      chartOptions={
        data &&
        options(data, {
          regionName: props.regionName,
          regionShortName: props.regionShortName,
          comparisonMode,
        })
      }
    />
  );
};

ApacCsF2.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
  regionShortName: PropTypes.string.isRequired,
};

export default ApacCsF2;
