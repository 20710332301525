import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  component: {
    color: theme.palette.link.primary,
    textDecoration: "underline",
    fontStyle: (props) => (!props.useItalics ? "normal" : "italic"),
  },
}));

const ExternalLink = (props) => {
  const classes = useStyles(props);

  return (
    <a
      href={props.href}
      target="_blank"
      rel="noreferrer"
      className={classNames(props.className, classes.component)}
    >
      {props.text ?? props.href}
    </a>
  );
};

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
  useItalics: PropTypes.bool,
};

ExternalLink.defaultProps = {
  useItalics: false,
};

export default ExternalLink;
