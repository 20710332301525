import PropTypes from "prop-types";
import Figure from "../../../Common/Box/Figure";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { capitalizePhrase, getTooltip } from "../../../../utilities";

const number = 3;

const seriesColor = "#439eb7";

const options = (data) => (theme) => ({
  chart: {
    type: "spline",
    height: 300,
    backgroundColor: null,
    width: 300,
  },
  xAxis: {
    type: "datetime",
    min: 2017,
    max: 2032,
    tickPositions: [2019, 2025, 2030],
    tickLength: 0,
    labels: {
      formatter: function () {
        return [2019, 2025, 2030].includes(this.value) ? this.value : null;
      },
      style: {
        color: theme.figure.axis.secondary,
        fontSize: theme.figure.axis.fontSize,
      },
    },
  },
  yAxis: {
    title: undefined,
    min: 0,
    labels: {
      style: {
        color: theme.figure.axis.secondary,
        fontSize: theme.figure.axis.fontSize,
      },
    },
  },
  plotOptions: {
    series: {
      lineWidth: 3,
      marker: {
        symbol: "circle",
        lineWidth: 3,
        fillColor: "white",
      },
      zoneAxis: "x",
      zones: [
        {
          value: 2025,
        },
        {
          dashStyle: "shortDot",
        },
      ],
    },
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    backgroundColor: "white",
    useHTML: true,
    outside: true,
    formatter: function () {
      return getTooltip("Menstruators", this.y);
    },
    style: {
      fontSize: "0.875rem",
      color: theme.palette.tooltip.primary,
    },
  },
  exporting: {
    chartOptions: {
      title: undefined,
      subtitle: undefined,
    },
    enabled: false,
  },
  series: [
    {
      color: seriesColor,
      marker: {
        lineColor: seriesColor,
      },
      data: data.map((year) => [year.year, year.menstruators]),
    },
  ],
});

const MhmCsF3 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cs/figures/3/${props.regionId}`
  );

  return (
    <Figure
      id="MHM_CS_f3"
      number={number.toString()}
      fileStem={`mhm_cs_f${number}`}
      title="Change in Total Menstruators"
      subtitle={`${capitalizePhrase(props.regionName)}, 2019-2030`}
      chartOptions={
        data &&
        options(data, {
          regionName: props.regionName,
          regionShortName: props.regionShortName,
        })
      }
    />
  );
};

MhmCsF3.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCsF3;
