import PropTypes from "prop-types";
import ApacCsF1 from "../Figures/ApacCsF1";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import ApacCsT1 from "../Tables/ApacCsT1";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeComparison,
  getRegionName,
  toLowerCase,
} from "../../../../utilities";

const ApacCsS1 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/apac/cs/tables/1/${props.regionId}`
  );

  return data ? (
    <>
      <p>
        In 2019 there were an estimated{" "}
        {formatNumberAsHumanReadable(data[0].contraceptionTypes[0].services)}{" "}
        abortion and PAC services in {toLowerCase(props.regionName)}; this
        accounts for{" "}
        {formatPercentage(
          (data[0].contraceptionTypes[0].services /
            data.slice(-1)[0].contraceptionTypes[0].services) *
            100
        )}{" "}
        of abortion and PAC services across all low- and middle-income
        countries.
      </p>

      <p>
        In {toLowerCase(props.regionName)},{" "}
        {formatPercentage(
          (data[0].contraceptionTypes[1].services /
            data[0].contraceptionTypes[0].services) *
            100
        )}{" "}
        of services are abortions. This is{" "}
        {getQualitativeComparison(
          (data[0].contraceptionTypes[1].services /
            data[0].contraceptionTypes[0].services) *
            100,
          81,
          3.5
        )}{" "}
        the average across all low- and middle-income countries (81%).
      </p>

      <ApacCsT1 regionName={props.regionName} data={data} />

      <ApacCsF1
        regionId={props.regionId}
        regionName={props.regionName}
        regionShortName={getRegionName(props.regionId, "short")}
      />
    </>
  ) : null;
};

ApacCsS1.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default ApacCsS1;
