import { useState } from "react";
import PropTypes from "prop-types";
import FpCcF1 from "../Figures/FpCcF1";
import FpCcF2 from "../Figures/FpCcF2";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getMethodPlural,
  toLowerCase,
} from "../../../../utilities";

const calculateMetricsForSummary1 = (data) => {
  const methodUserDictionary = data.reduce((accumulator, country) => {
    for (let method of country.methods) {
      if (!accumulator[method.name]) {
        accumulator[method.name] = 0;
      }

      accumulator[method.name] += method.value;
    }

    return accumulator;
  }, {});

  const methodUsers = Object.keys(methodUserDictionary).map((method) => ({
    name: method,
    users: methodUserDictionary[method],
  }));

  const usersOfContraception = methodUsers.reduce(
    (accumulator, { users }) => accumulator + users,
    0
  );

  const methodUserShares = methodUsers
    .map((method) => ({
      ...method,
      percentage: (method.users / usersOfContraception) * 100,
    }))
    .sort((a, b) => b.percentage - a.percentage);

  const mostCommonMethod = methodUserShares[0];

  return {
    mostCommonMethod,
  };
};

const calculateMetricsForSummary2 = (data) => {
  const usersOfContraception = data.reduce(
    (accumulator, { total }) => accumulator + total,
    0
  );

  const usersOfPublicSectorContraception = data.reduce(
    (accumulator, country) => accumulator + country.sectors[0].value,
    0
  );

  const countryPublicSectorShares = data.map(
    (country) => (country.sectors[0].value / country.total) * 100
  );

  return {
    publicSectorShare:
      (usersOfPublicSectorContraception / usersOfContraception) * 100,
    smallestPublicSectorShare: Math.min(...countryPublicSectorShares),
    largestPublicSectorShare: Math.max(...countryPublicSectorShares),
  };
};

const FpCcCurrentUse = (props) => {
  const [selectedSectorId, setSelectedSectorId] = useState(0);

  const { areaData: userData } = useAreaQuery(
    `/custom/fp/cc/summaries/1/${props.regionId}/5`
  );

  const { areaData: methodData } = useAreaQuery(
    `/custom/fp/cc/figures/1/${props.regionId}/5/${selectedSectorId}`
  );

  const { areaData: sectorData } = useAreaQuery(
    `/custom/fp/cc/figures/2/${props.regionId}/5/${0}`
  );

  const metricsForSummary1 =
    methodData && calculateMetricsForSummary1(methodData);

  const metricsForSummary2 =
    sectorData && calculateMetricsForSummary2(sectorData);

  const handleSectorChange = (methodId) => {
    setSelectedSectorId(methodId === 4 ? 0 : methodId);
  };

  return (
    <>
      {userData && (
        <p>
          There are currently {formatNumberAsHumanReadable(userData.total)}{" "}
          users of modern contraception in {toLowerCase(props.regionName)},
          which represents {formatPercentage(userData.share)} of the total users
          living across all low- and middle-income countries.
        </p>
      )}

      {metricsForSummary1?.mostCommonMethod && (
        <p>
          The most commonly used method is{" "}
          {getMethodPlural(metricsForSummary1.mostCommonMethod.name)} accounting
          for {formatPercentage(metricsForSummary1.mostCommonMethod.percentage)}{" "}
          of use in {toLowerCase(props.regionName)}. Method use varies by
          country as shown in the figure below.
        </p>
      )}

      <FpCcF1
        regionId={props.regionId}
        regionName={props.regionName}
        sectorId={selectedSectorId}
        data={methodData}
        onSectorChange={handleSectorChange}
      />

      {metricsForSummary2 && (
        <>
          <p>
            In {toLowerCase(props.regionName)}{" "}
            {formatPercentage(metricsForSummary2.publicSectorShare)} of users
            get their method from the public sector. The roles of the public and
            private sector vary by country.
          </p>

          <p>
            The use of the public sector ranges from{" "}
            {formatPercentage(metricsForSummary2.smallestPublicSectorShare)} to{" "}
            {formatPercentage(metricsForSummary2.largestPublicSectorShare)}{" "}
            across countries in {toLowerCase(props.regionName)}. Variation can
            be seen in the figure below.
          </p>
        </>
      )}

      <FpCcF2
        regionId={props.regionId}
        regionName={props.regionName}
        data={sectorData}
      />
    </>
  );
};

FpCcCurrentUse.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default FpCcCurrentUse;
