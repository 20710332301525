import { Link, Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const MenstrualHygieneQuantities = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Product Quantities</Typography>

      <p>
        The quantity is the amount of products that a menstruator will use over
        the course of a year, multiplied by the number of menstruators using
        each type of product.
      </p>
      <p>
        We used different approaches for estimating the quantities for each
        menstrual hygiene product. For disposable products, it is estimated
        that, on average, a menstruator has 13 annual cycles and uses 13.3
        products (either tampons or pads) per cycle
        <Footnote number={2}>
          UNICEF{" "}
          <ExternalLink
            href="https://www.unicef.org/documents/guide-menstrual-hygiene-materials"
            text="Guide to Menstrual Hygiene Materials"
            useItalics={true}
          />{" "}
          May 2019.
        </Footnote>
        , leading to a total of 172.9 products per year. We then adjusted this
        figure for different groups of menstruators to account for:
      </p>

      <ol>
        <li>
          Use of hormonal contraceptives that reduce bleeding. This draws from
          estimates of{" "}
          <Link
            underline="always"
            onClick={handleReadersGuideOpen("contraception-users-by-method")}
          >
            Users by Method
          </Link>{" "}
          produced for the Contraceptive analysis within LEAP. Based on research
          on the impact of hormonal methods on the number of cycles and average
          days of bleeding per cycle, a proportion of &apos;full&apos; need for
          menstrual hygiene products is calculated for each method: implant
          (41%), injectables (30.8%), and pills (60%)
          <Footnote number={3}>
            Based on: (1) Diana Mansour, Tjeerd Korver, Maya Marintcheva-Petrova
            & Ian S. Fraser (2008){" "}
            <ExternalLink
              href="https://www.tandfonline.com/doi/full/10.1080/13625180801959931"
              text="The effects of Implanon&reg; on menstrual bleeding patterns"
              useItalics={true}
            />
            , The European Journal of Contraception & Reproductive Health Care,
            13:sup1, 13-28, and (2) Gerd Larsson, Lan Milsom, Göran Lindstedt,
            Göran Rybo (1992){" "}
            <ExternalLink
              href="https://www.sciencedirect.com/science/article/abs/pii/001078249290095B"
              text="The influence of a low-dose combined oral contraceptive on menstrual blood loss and iron status"
              useItalics={true}
            />
            , Contraception, Volume 46, Issue 4, Pages 327-334.
          </Footnote>
          . While use of the IUS has also been shown to reduce bleeding, copper
          IUDs, which make up the majority of IUD use, do not lead to bleeding
          reductions. As current data sources do not allow use of IUD and IUS to
          be separated, no adjustments were made for the reduction in bleeding
          due to IUS use.
        </li>
        <li>
          Some menstruators supplement with non-purpose made supplies and
          therefore use fewer disposable products. Informed by PMA2020 data; it
          was assumed that those who partially use disposables use half the
          volume of disposable products each cycle.
        </li>
      </ol>

      <p>
        The final average number of disposable products per menstruator was 154
        pads or tampons per year.
      </p>
      <p>
        For reusable pads we assumed that each user needs five reusable pads per
        year, and that these pads must be replaced annually
        <Footnote number={4}>
          UNICEF{" "}
          <ExternalLink
            href="https://www.unicef.org/documents/guide-menstrual-hygiene-materials"
            text="Guide to Menstrual Hygiene Materials"
            useItalics={true}
          />{" "}
          May 2019.
        </Footnote>
        . For reusable cups, we assumed that a cup can be used for five years
        before it is replaced
        <Footnote number={5}>
          UNICEF{" "}
          <ExternalLink
            href="https://www.unicef.org/documents/guide-menstrual-hygiene-materials"
            text="Guide to Menstrual Hygiene Materials"
            useItalics={true}
          />{" "}
          May 2019.
        </Footnote>
        . To calculate the quantity of cups needed each year, each new cup user
        was assumed to require one cup, and 1/5th of the previous year&apos;s
        cup users were assumed to need to replace their cup.
      </p>
      <p>
        For reusable products, no reductions were made based on hormonal
        contraceptive use or supplementing with non-purpose made products. This
        is because it was assumed that the initial investment in reusable pads
        or a cup would still be needed, even with a reduced menstrual flow.
      </p>
      <p>
        The quantities presented in this work are different from procurement
        volumes, which may be higher or lower depending on context. Procurement
        needs are typically a function of broader programmatic considerations,
        such as availability (or lack thereof) of resources; the desire to fill
        supply pipelines and maintain adequate inventory levels, or simply the
        volumes of product already in the supply chain.
      </p>
    </div>
  );
};

export default MenstrualHygieneQuantities;
