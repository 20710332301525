import { useState } from "react";
import PropTypes from "prop-types";
import SideBySide from "../../../Common/SideBySide";
import FpCpF2 from "../Figures/FpCpF2";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatMethodLowerCase,
  formatPercentage,
  getMethodPlural,
  getMethodVerb,
  getQuantitiesAndCostsMethodPlural,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  if (!data) {
    return {
      publicSectorShare: 0,
      incomeGroupPublicSectorShares: [0, 0, 0],
    };
  }

  const total = data.reduce(
    (accumulator, incomeGroup) =>
      accumulator +
      incomeGroup.sectors.reduce(
        (accumulator, { value }) => accumulator + value,
        0
      ),
    0
  );

  const publicSectorTotal = data.reduce(
    (accumulator, incomeGroup) => accumulator + incomeGroup.sectors[0].value,
    0
  );

  const incomeGroupTotals = data.map((incomeGroup) =>
    incomeGroup.sectors.reduce(
      (accumulator, { value }) => accumulator + value,
      0
    )
  );

  const incomeGroupPublicSectorShares = data.map(
    (incomeGroup, index) =>
      (incomeGroup.sectors[0].value / incomeGroupTotals[index]) * 100
  );

  return {
    publicSectorShare: (publicSectorTotal / total) * 100,
    incomeGroupPublicSectorShares,
  };
};

const Users = (props) => {
  const metrics = calculateMetrics(props.data);

  return (
    <>
      <p>
        Across all low- and middle-income countries,{" "}
        {formatPercentage(metrics.publicSectorShare)} of{" "}
        {formatMethodLowerCase(props.methodName)} users get their method from
        the public sector. Patterns vary by country income group.
      </p>

      <p>
        {formatPercentage(metrics.incomeGroupPublicSectorShares[0])} of users in
        low-income countries,{" "}
        {formatPercentage(metrics.incomeGroupPublicSectorShares[1])} of users in
        lower-middle-income countries, and{" "}
        {formatPercentage(metrics.incomeGroupPublicSectorShares[2])} of users in
        upper-middle-income countries get their{" "}
        {getMethodPlural(props.methodName)} from the public sector.
      </p>
    </>
  );
};

const Quantities = (props) => {
  const metrics = calculateMetrics(props.data);

  return (
    <>
      <p>
        Across all low- and middle-income countries,{" "}
        {formatPercentage(metrics.publicSectorShare)} of{" "}
        {getQuantitiesAndCostsMethodPlural(props.methodName)}{" "}
        {getMethodVerb(props.methodId)} come from the public sector.
      </p>

      <p>
        {formatPercentage(metrics.incomeGroupPublicSectorShares[0])} of units in
        low-income countries,{" "}
        {formatPercentage(metrics.incomeGroupPublicSectorShares[1])} of units in
        lower-middle-income countries, and{" "}
        {formatPercentage(metrics.incomeGroupPublicSectorShares[2])} of units in
        upper-middle-income countries come from the public sector for{" "}
        {getQuantitiesAndCostsMethodPlural(props.methodName)}.
      </p>
    </>
  );
};

const Costs = (props) => {
  const metrics = calculateMetrics(props.data);

  return (
    <>
      <p>
        Across all low- and middle-income countries,{" "}
        {formatPercentage(metrics.publicSectorShare)} of supply costs for{" "}
        {getMethodPlural(props.methodName)} {getMethodVerb(props.methodId)} come
        from the public sector. This includes the costs from users who go to the
        public sector as well as the cost of the subsidy from private subsidized
        users.
      </p>

      <p>
        {formatPercentage(metrics.incomeGroupPublicSectorShares[0])} of costs in
        low-income countries,{" "}
        {formatPercentage(metrics.incomeGroupPublicSectorShares[1])} of costs in
        lower-middle-income countries, and{" "}
        {formatPercentage(metrics.incomeGroupPublicSectorShares[2])} of costs in
        upper-middle-income countries come from the public sector for{" "}
        {getMethodPlural(props.methodName)}.
      </p>
    </>
  );
};

const Summary = ({ indicatorId, ...rest }) => {
  switch (indicatorId) {
    case 5:
      return <Users {...rest} />;

    case 3:
      return <Quantities {...rest} />;

    case 1:
      return <Costs {...rest} />;

    default:
      return null;
  }
};

const FpCpS2 = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(5);

  const { areaData: data } = useAreaQuery(
    `/custom/fp/cp/figures/2/${props.methodId}/${selectedIndicatorId}`
  );

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  return (
    <>
      <SideBySide
        component={
          <FpCpF2
            methodName={props.methodName}
            indicatorId={selectedIndicatorId}
            data={data}
            onIndicatorChange={handleIndicatorChange}
          />
        }
        componentPosition="right"
      >
        <Summary
          indicatorId={selectedIndicatorId}
          methodId={props.methodId}
          methodName={props.methodName}
          data={data}
        />
      </SideBySide>
    </>
  );
};

FpCpS2.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
};

export default FpCpS2;
