import { useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import BoxControls from "../../Common/Box/BoxControls";
import Box from "../../Common/Box/Box";
import Chart from "../../Common/Box/Chart";
import Legend from "../../Common/Box/Legend";
import { chartDownloadImageScaleFactor } from "../../../constants";
import initialData from "../../../data/initialData.json";
import exportCharts from "../../../export";
import useCsvDownload from "../../../hooks/useCsvDownload";
import theme from "../../../theme";
import { getTooltip } from "../../../utilities";

const key = "MHM_f9";
const number = 6;

const getMaximumValue = (data) => {
  let maximumValue = 0;

  for (let incomeGroup of data) {
    for (let scenarioIndex = 0; scenarioIndex < 3; scenarioIndex++) {
      let scenarioTotal = 0;

      for (let method of incomeGroup.methods) {
        scenarioTotal += method.data[scenarioIndex];
      }

      maximumValue = Math.max(maximumValue, scenarioTotal);
    }
  }

  return maximumValue;
};

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Disposable":
      return theme.palette.areas.menstrualHygiene.methods.disposable;

    case "Reusable":
      return theme.palette.areas.menstrualHygiene.methods.reusable;

    case "Out-of-scope":
      return theme.palette.areas.menstrualHygiene.methods.outOfScope;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = data.methods.map((method) => ({
    ...method,
    color: getSeriesColor(method.name, theme),
  }));

  return {
    chart: {
      type: "column",
      backgroundColor: null,
      height: 350,
      width: props.showYAxis ? 297 : 250,
      spacing: [10, 0, 15, 0],
    },
    title: {
      text: data.incomeGroup,
      align: "center",
      x: props.showYAxis ? 22 : undefined,
    },
    xAxis: {
      categories: [
        "1: Maintain product use",
        "2: Increase purpose-made",
        "3: Increase purpose-made & reusable",
      ],
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          textAlign: "center",
        },
      },
    },
    yAxis: {
      title: undefined,
      min: 0,
      max: absolute ? props.yAxisMaximum : 100,
      reversedStacks: false,
      labels: {
        enabled: props.showYAxis,
        format: absolute ? undefined : "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: absolute ? "normal" : "percent",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Menstruators", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      chartOptions: {
        title: {
          text: data.incomeGroup,
        },
      },
      enabled: false,
    },
    series,
  };
};

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
  },
  facet: {
    margin: theme.spacing(0, 3),
  },
  facetPanel: {
    display: "flex",
  },
  legend: {
    marginTop: theme.spacing(),
  },
}));

const Component = () => {
  const [comparisonMode, setComparisonMode] = useState("relative");

  const highchartRefs = [useRef(), useRef(), useRef()];

  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const data = initialData["mhm_f9"];

  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  const handleCsvDownload = () => {
    downloadCsv("/mhm/figures/9/csv", `mhm_f${number}.csv`);
  };

  const yAxisMaximum = getMaximumValue(data) * 1.02;

  return (
    <Box
      id={key}
      caption={`Figure ${number}`}
      title="Future Use of Menstrual Hygiene Products"
      subtitle="By Future Scenario and Income Group, 2030"
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        />
      }
      onDownloadCsv={handleCsvDownload}
      onDownloadPng={() => {
        exportCharts(
          {
            chart1: highchartRefs[0].current,
            chart2: highchartRefs[1].current,
            chart3: highchartRefs[2].current,
          },
          {
            filename: key,
            scale: chartDownloadImageScaleFactor,
          }
        );
      }}
    >
      <div className={classes.content}>
        <div className={classes.facetPanel}>
          {data.map((incomeGroup, index) => (
            <div key={incomeGroup.incomeGroup} className={classes.facet}>
              <Chart
                highchartRef={highchartRefs[index]}
                options={options(incomeGroup, {
                  yAxisMaximum,
                  showYAxis: index === 0,
                  comparisonMode,
                })}
              />
            </div>
          ))}
        </div>

        <div className={classes.legend}>
          <Legend
            series={[
              {
                name: "Disposable",
                color: getSeriesColor("Disposable", theme),
              },
              {
                name: "Reusable",
                color: getSeriesColor("Reusable", theme),
              },
              {
                name: "Non-purpose-made",
                color: getSeriesColor("Out-of-scope", theme),
              },
            ]}
            markerStyle="circle"
            orientation="horizontal"
          />
        </div>
      </div>
    </Box>
  );
};

export default Component;
