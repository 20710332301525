import { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import BoxControls from "../../Common/Box/BoxControls";
import MethodChooser from "../../Common/Box/Choosers/MethodChooser";
import Table from "../../Common/Box/Table";
import initialData from "../../../data/initialData.json";
import useAreaQuery from "../../../hooks/useAreaQuery";
import useCsvDownload from "../../../hooks/useCsvDownload";
import {
  formatNumber,
  formatPercentage,
  getDownloadFileStem,
  getMethod,
} from "../../../utilities";

const key = "FP_t4";
const number = 5;

const format = (n) =>
  formatNumber(n, {
    prefix: "$",
    significantDigits: 3,
  });

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td.category": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child th:not(.category)": {
      minWidth: "8rem",
    },
  },
}));

const Component = () => {
  const [selectedMethodId, setSelectedMethodId] = useState(0);

  const { areaData: data } = useAreaQuery(
    `/fp/tables/4/${selectedMethodId}`,
    initialData["fp_t4"]
  );

  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleMethodChange = (methodId) => {
    setSelectedMethodId(methodId);
  };

  const handleCsvDownload = () => {
    const fileStem = getDownloadFileStem(
      key,
      number,
      getMethod(selectedMethodId)
    );

    downloadCsv(`/fp/tables/4/${selectedMethodId}/csv`, `${fileStem}.csv`);
  };

  return (
    <Table
      key={key}
      number={number.toString()}
      title="Change in Contraceptive Supply Costs"
      subtitle="By Income Group, 2019-2030"
      controls={
        <BoxControls>
          <MethodChooser
            area="contraception"
            value={selectedMethodId}
            onChange={handleMethodChange}
          />
        </BoxControls>
      }
      onDownloadCsv={handleCsvDownload}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th colSpan={3}>Total Costs</th>
            <th colSpan={2} className={classes.changeGroupBoundary}>
              Change (2019 to 2030)
            </th>
          </tr>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th>2019</th>
            <th>2025</th>
            <th>2030</th>
            <th className={classes.changeGroupBoundary}>#</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {data.map((incomeGroup) => (
            <tr key={incomeGroup.incomeGroup}>
              <td className={classNames("category", classes.nameGroupBoundary)}>
                {incomeGroup.incomeGroup}
              </td>

              {incomeGroup.years.map((year) => (
                <td key={year.year}>{format(year.value)}</td>
              ))}

              <td className={classes.changeGroupBoundary}>
                {format(incomeGroup.change)}
              </td>
              <td>{formatPercentage(incomeGroup.percentage)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Table>
  );
};

export default Component;
