import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import ApacCsF6 from "../Figures/ApacCsF6";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeChangeDirection,
  getQualitativeChangeMagnitude,
} from "../../../../utilities";

const calculateChange = (years) => {
  if (!years) {
    return {
      initial: 0,
      final: 0,
      change: 0,
      percentageChange: 0,
    };
  }

  const initial = years[0];
  const final = years[2];
  const change = final - initial;

  return {
    initial,
    final,
    change,
    percentageChange: (change / initial) * 100,
  };
};

const calculateMetrics = (data) => ({
  scenario1: calculateChange(data?.all.map((year) => year.data[0])),
  scenario2: calculateChange(data?.all.map((year) => year.data[1])),
  mvaScenario3: calculateChange(data?.mva.map((year) => year.data[2])),
  misoMifeScenario3: calculateChange(
    data?.misoMife.map((year) => year.data[2])
  ),
});

const useStyles = makeStyles((theme) => ({
  list: {
    "& li": {
      margin: theme.spacing(2, 0),
    },
  },
}));

const ApacCsS7 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/apac/cs/figures/6/${props.regionId}`
  );

  const classes = useStyles();

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>Changes in costs would vary by method across the three scenarios:</p>

      <ul className={classes.list}>
        <li>
          If current safety profile and method mix were maintained (Scenario 1),
          the cost of abortion and PAC supplies would see a{" "}
          {getQualitativeChangeMagnitude(metrics.scenario1.percentageChange)}{" "}
          {getQualitativeChangeDirection(metrics.scenario1.percentageChange)}{" "}
          from{" "}
          {formatNumberAsHumanReadable(metrics.scenario1.initial, {
            prefix: "$",
          })}{" "}
          in 2019 to{" "}
          {formatNumberAsHumanReadable(metrics.scenario1.final, {
            prefix: "$",
          })}{" "}
          in 2030 (
          {formatNumberAsHumanReadable(metrics.scenario1.change, {
            prefixWithSign: true,
            prefix: "$",
          })}
          ,{" "}
          {formatPercentage(metrics.scenario1.percentageChange, {
            prefixWithSign: true,
          })}
          ).
        </li>
        <li>
          With an increase in safety but no change in the mix of methods being
          used (Scenario 2) there would be a{" "}
          {getQualitativeChangeMagnitude(metrics.scenario2.percentageChange)}{" "}
          {getQualitativeChangeDirection(metrics.scenario2.percentageChange)} in
          the cost of supplies, from{" "}
          {formatNumberAsHumanReadable(metrics.scenario2.initial, {
            prefix: "$",
          })}{" "}
          in 2019 to{" "}
          {formatNumberAsHumanReadable(metrics.scenario2.final, {
            prefix: "$",
          })}{" "}
          million in 2030 (
          {formatNumberAsHumanReadable(metrics.scenario2.change, {
            prefixWithSign: true,
            prefix: "$",
          })}
          ,{" "}
          {formatPercentage(metrics.scenario2.percentageChange, {
            prefixWithSign: true,
          })}
          ).
        </li>
        <li>
          With an increase in safety as well as an increase in the use of
          medical services (particularly those using mifepristone), there would
          be a{" "}
          {getQualitativeChangeMagnitude(metrics.mvaScenario3.percentageChange)}{" "}
          {getQualitativeChangeDirection(metrics.mvaScenario3.percentageChange)}{" "}
          in the cost of supplies, from{" "}
          {formatNumberAsHumanReadable(metrics.mvaScenario3.initial, {
            prefix: "$",
          })}{" "}
          in 2019 to{" "}
          {formatNumberAsHumanReadable(metrics.mvaScenario3.final, {
            prefix: "$",
          })}{" "}
          in 2030 (
          {formatNumberAsHumanReadable(metrics.mvaScenario3.change, {
            prefixWithSign: true,
            prefix: "$",
          })}
          ,{" "}
          {formatPercentage(metrics.mvaScenario3.percentageChange, {
            prefixWithSign: true,
          })}
          ).
        </li>
      </ul>

      {data && (
        <ApacCsF6
          regionId={props.regionId}
          regionName={props.regionName}
          data={data.all}
        />
      )}
    </>
  );
};

ApacCsS7.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default ApacCsS7;
