import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { useReadersGuide } from "../../../../hooks/useReadersGuide";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getIncomeGroupDescription,
  toLowerCase,
} from "../../../../utilities";

const calculateRegionalMenstruators = (data) => {
  if (!data) {
    return 0;
  }

  let menstruators = 0;

  for (let series of data) {
    menstruators += series.data[0];
  }

  return menstruators;
};

const calculateRegionalMenstruatorBreakdown = (data) => {
  if (!data) {
    return {
      purposeMade: 0,
      nonPurposeMade: 0,
    };
  }

  const regionalMenstruators = calculateRegionalMenstruators(data);

  return {
    purposeMade: (data[0].data[0] / regionalMenstruators) * 100,
    nonPurposeMade: (data[1].data[0] / regionalMenstruators) * 100,
  };
};

const MhmCsCurrentUse = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cs/figures/1/${props.regionId}`
  );

  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  const regionalMenstruators = calculateRegionalMenstruators(data);
  const regionalMenstruatorBreakdown = calculateRegionalMenstruatorBreakdown(
    data
  );

  return (
    <>
      <p>
        Currently there are an estimated{" "}
        {formatNumberAsHumanReadable(regionalMenstruators)} menstruators living
        in {toLowerCase(props.regionName)}
        {props.countrySelected ? (
          <>, {getIncomeGroupDescription(props.regionId)}</>
        ) : null}
        .
      </p>

      <p>
        An estimated{" "}
        {formatPercentage(regionalMenstruatorBreakdown.purposeMade)} of
        menstruators use purpose-made menstrual hygiene products (this includes
        disposable pads and tampons and reusable pads and cups, see the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("menstrual-hygiene-quantities")}
        >
          Reader&apos;s Guide
        </Link>{" "}
        for more information).
      </p>

      <p>
        The remaining{" "}
        {formatPercentage(regionalMenstruatorBreakdown.nonPurposeMade)} use
        non-purpose made products such as cloth and cotton wool.
      </p>

      <p>
        Among menstruators using purpose-made menstrual hygiene products, the
        current market is massively dominated by disposable products (pads and
        tampons) rather than reusable products. 
      </p>
      <p>
        Reusable products make up a very small proportion of product use at this
        time. Due to limited data, for the purpose of the analysis we assumed 5%
        of menstruators who use purpose-made menstrual hygiene products, use
        reusable products. Our analysis of future scenarios explored the
        possible impact on use and cost of menstrual hygiene products if use of
        reusable products increased.
      </p>
    </>
  );
};

MhmCsCurrentUse.propTypes = {
  countrySelected: PropTypes.bool.isRequired,
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCsCurrentUse;
