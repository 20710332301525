import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Table from "../../../Common/Box/Table";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  capitalizePhrase,
  formatNumber,
  formatPercentage,
} from "../../../../utilities";

const key = "FP_CS_t4";
const number = 4;

const format = (n) =>
  formatNumber(n, {
    significantDigits: 3,
  });

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child td:not(:first-child)": {
      minWidth: "6rem",
    },
  },
}));

const FpCsT4 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/fp/cs/tables/1/${props.regionId}/5`
  );
  const classes = useStyles();

  return (
    <Table
      key={key}
      number={number.toString()}
      title="Change in Contraceptive Users"
      subtitle={`${capitalizePhrase(props.regionName)} by Method, 2019-2030`}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th colSpan={3}>Total Users</th>
            <th colSpan={2} className={classes.changeGroupBoundary}>
              Change (2019 to 2030)
            </th>
          </tr>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th>2019</th>
            <th>2025</th>
            <th>2030</th>
            <th className={classes.changeGroupBoundary}>#</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((method) => {
              const [total] = method.sectors.slice(-1);

              return (
                <tr key={method.name}>
                  <td className={classes.nameGroupBoundary}>{method.name}</td>

                  {total.years.map((year) => (
                    <td key={year.year}>{format(year.value)}</td>
                  ))}

                  <td className={classes.changeGroupBoundary}>
                    {format(method.change)}
                  </td>
                  <td>{formatPercentage(method.percentageChange)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Table>
  );
};

FpCsT4.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default FpCsT4;
