import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { enableCustomReports } from "../../../constants";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const useStyles = makeStyles({
  customReports: {
    display: enableCustomReports ? "block" : "none",
  },
});

const MenstrualHygieneScope = () => {
  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Scope</Typography>

      <Typography variant="h2">What</Typography>

      <p>
        Menstrual hygiene management requires access to private, safe sanitation
        facilities, and clean menstrual hygiene products and knowledge of how to
        use them. Some menstrual hygiene products are purpose-made for menstrual
        hygiene management, while others are adapted for that purpose. For the
        purposes of LEAP, we focus on a subset of purpose-made products:
      </p>

      <ol>
        <li>Disposable sanitary pads;</li>
        <li>Tampons;</li>
        <li>Reusable sanitary pads and underwear, and;</li>
        <li>Menstrual cups.</li>
      </ol>

      <p>
        Purpose-made products that currently make up a very small proportion of
        the menstrual hygiene product market, such as reusable tampons and
        disposable cups, are subsumed within the larger categories and not
        presented separately. While we recognize that a substantial proportion
        of women in low- and middle-income countries use reusable cloth or
        homemade cloth pads to manage menstruation, LEAP focuses on the market
        of products that are purpose-made and purchased for catching menstrual
        blood.
      </p>
      <p>
        Within the results, disposable sanitary pads and tampons are presented
        together due to limited data to inform this split; however in most
        countries included in this analysis the disposable market is likely
        dominated by pads. Reusable sanitary pads and menstrual cups are
        sometimes presented together as &quot;reusable products&quot; and, where
        relevant, are shown by product type.
      </p>

      <Typography variant="h2">Who</Typography>

      <p>
        LEAP covers the menstrual hygiene product needs of menstruators aged
        12-49 years, based on average estimates of menarche and menopause
        <Footnote number={1}>
          UNHCR{" "}
          <ExternalLink
            href="https://wash.unhcr.org/download/institutional-survey-on-menstrual-hygiene-management/"
            text="Institutional Survey on Menstrual Hygiene Management"
            useItalics={true}
          />
          , 2019.
        </Footnote>
        . While menarche and menopause occur in some individuals before and
        after these ages, we used the average menstruation age span for
        estimation purposes.
      </p>

      <Typography variant="h2">When</Typography>

      <p>
        Our results cover the time period from 2019 to 2030. We used 2019 as the
        baseline year for exploring the &quot;current&quot; landscape for
        menstrual hygiene products due to the uncertainties of the impacts of
        the global COVID-19 pandemic in 2020. See{" "}
        <Link underline="always" onClick={handleReadersGuideOpen("timeframe")}>
          Timeframe
        </Link>{" "}
        above for more detail.
      </p>

      <Typography variant="h2">Where</Typography>

      <p>
        Our analysis includes 129 low- and middle-income countries. The
        Landscape Report segments these countries into three income groups as
        defined by the World Bank: low-income countries, lower-middle-income
        countries, and upper-middle-income countries. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("income-groups")}
        >
          Income Groups
        </Link>{" "}
        above for more detail.
      </p>
      <p className={classes.customReports}>
        The Custom Reports allow you to explore results not only by income group
        but also by individual country and additional groupings of countries
        based either on donor priorities or geographic regions. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("country-groups")}
        >
          Country Groups
        </Link>{" "}
        above for more detail.
      </p>
    </div>
  );
};

export default MenstrualHygieneScope;
