import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExternalLink from "../../Common/ExternalLink";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
    },
    "& td:not(first-child)": {
      textAlign: "right",
    },
    "& td.subtitle": {
      backgroundColor: theme.palette.areas.accent.primary,
      textAlign: "center",
    },
    "& th": {
      padding: theme.table.cellPadding,
    },
  },
  groupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  footnote: {
    fontSize: "0.875rem",
    fontStyle: "italic",
  },
}));

const RgApacT3 = () => {
  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h6">
        Table 3. Scenario 3 Shifts in Method Mix for Abortion Services (2019
        <sup>*</sup> to 2030)
      </Typography>

      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th colSpan={2} className={classes.groupBoundary}>
              Surgical
            </th>
            <th colSpan={2} className={classes.groupBoundary}>
              Medical:
              <br />
              Misoprostol Only
            </th>
            <th colSpan={2} className={classes.groupBoundary}>
              Medical:
              <br />
              Misoprostol in
              <br />
              combination with
              <br />
              Mifepristone
            </th>
          </tr>
          <tr>
            <th />
            <th className={classes.groupBoundary}>
              Vacuum
              <br />
              aspiration
            </th>
            <th>
              D&amp;E (safe) or
              <br />
              D&amp;C (less
              <br />
              safe)<sup>&dagger;</sup>
            </th>
            <th className={classes.groupBoundary}>&lt;12 weeks</th>
            <th>&ge;12 weeks</th>
            <th className={classes.groupBoundary}>
              &lt;12 weeks/
              <br />
              84 days
            </th>
            <th>&ge;12 weeks</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={7} className="subtitle">
              Safe 1 (Eastern Asia, Southern Europe, Eastern Europe, Bangladesh,
              Ethiopia, India, Nepal, South Africa)
            </td>
          </tr>
          <tr>
            <td>2019</td>
            <td className={classes.groupBoundary}>45.0%</td>
            <td>10.0%</td>
            <td className={classes.groupBoundary}>0.0%</td>
            <td>0.0%</td>
            <td className={classes.groupBoundary}>40.5%</td>
            <td>4.5%</td>
          </tr>
          <tr>
            <td>2030</td>
            <td className={classes.groupBoundary}>40.0%</td>
            <td>10.0%</td>
            <td className={classes.groupBoundary}>0.0%</td>
            <td>0.0%</td>
            <td className={classes.groupBoundary}>45.0%</td>
            <td>5.0%</td>
          </tr>
          <tr>
            <td colSpan={7} className="subtitle">
              Safe 2 (Eastern Africa, Southern Africa, Western Africa, Western
              Asia, Southern Asia, South-Eastern Asia)
            </td>
          </tr>
          <tr>
            <td>2019</td>
            <td className={classes.groupBoundary}>55.0%</td>
            <td>10.0%</td>
            <td className={classes.groupBoundary}>22.0%</td>
            <td>3.0%</td>
            <td className={classes.groupBoundary}>10.0%</td>
            <td>0.0%</td>
          </tr>
          <tr>
            <td>2030</td>
            <td className={classes.groupBoundary}>40.0%</td>
            <td>10.0%</td>
            <td className={classes.groupBoundary}>0.0%</td>
            <td>0.0%</td>
            <td className={classes.groupBoundary}>45.0%</td>
            <td>5.0%</td>
          </tr>
          <tr>
            <td colSpan={7} className="subtitle">
              Safe 3 (all other regions/countries)
            </td>
          </tr>
          <tr>
            <td>2019</td>
            <td className={classes.groupBoundary}>60.0%</td>
            <td>10.0%</td>
            <td className={classes.groupBoundary}>22.0%</td>
            <td>3.0%</td>
            <td className={classes.groupBoundary}>5.0%</td>
            <td>0.0%</td>
          </tr>
          <tr>
            <td>2030</td>
            <td className={classes.groupBoundary}>40.0%</td>
            <td>10.0%</td>
            <td className={classes.groupBoundary}>0.0%</td>
            <td>0.0%</td>
            <td className={classes.groupBoundary}>45.0%</td>
            <td>5.0%</td>
          </tr>
          <tr>
            <td colSpan={7} className="subtitle">
              Less Safe 1 (Southern Europe, Eastern Europe)
            </td>
          </tr>
          <tr>
            <td>2019</td>
            <td className={classes.groupBoundary}>30.0%</td>
            <td>40.0%</td>
            <td className={classes.groupBoundary}>13.5%</td>
            <td>1.5%</td>
            <td className={classes.groupBoundary}>13.5%</td>
            <td>1.5%</td>
          </tr>
          <tr>
            <td>2030</td>
            <td className={classes.groupBoundary}>40.0%</td>
            <td>10.0%</td>
            <td className={classes.groupBoundary}>20.0%</td>
            <td>2.5%</td>
            <td className={classes.groupBoundary}>25.0%</td>
            <td>2.5%</td>
          </tr>
          <tr>
            <td colSpan={7} className="subtitle">
              Less Safe 2 (India)
            </td>
          </tr>
          <tr>
            <td>2019</td>
            <td className={classes.groupBoundary}>10.0%</td>
            <td>15.0%</td>
            <td className={classes.groupBoundary}>0.0%</td>
            <td>0.0%</td>
            <td className={classes.groupBoundary}>67.5%</td>
            <td>7.5%</td>
          </tr>
          <tr>
            <td>2030</td>
            <td className={classes.groupBoundary}>10.0%</td>
            <td>15.0%</td>
            <td className={classes.groupBoundary}>0.0%</td>
            <td>0.0%</td>
            <td className={classes.groupBoundary}>67.5%</td>
            <td>7.5%</td>
          </tr>
          <tr>
            <td colSpan={7} className="subtitle">
              Less Safe 3 (Western Africa, Western Asia, Southern Asia,
              South-Eastern Asia)
            </td>
          </tr>
          <tr>
            <td>2019</td>
            <td className={classes.groupBoundary}>15.0%</td>
            <td>20.0%</td>
            <td className={classes.groupBoundary}>51.0%</td>
            <td>6.0%</td>
            <td className={classes.groupBoundary}>5.0%</td>
            <td>0.0%</td>
          </tr>
          <tr>
            <td>2030</td>
            <td className={classes.groupBoundary}>30.0%</td>
            <td>10.0%</td>
            <td className={classes.groupBoundary}>20.0%</td>
            <td>2.5%</td>
            <td className={classes.groupBoundary}>35.0%</td>
            <td>2.5%</td>
          </tr>
          <tr>
            <td colSpan={7} className="subtitle">
              Less Safe 4 (all other regions/countries)
            </td>
          </tr>
          <tr>
            <td>2019</td>
            <td className={classes.groupBoundary}>15.0%</td>
            <td>23.0%</td>
            <td className={classes.groupBoundary}>54.0%</td>
            <td>6.0%</td>
            <td className={classes.groupBoundary}>2.0%</td>
            <td>0.0%</td>
          </tr>
          <tr>
            <td>2030</td>
            <td className={classes.groupBoundary}>30.0%</td>
            <td>10.0%</td>
            <td className={classes.groupBoundary}>20.0%</td>
            <td>2.5%</td>
            <td className={classes.groupBoundary}>35.0%</td>
            <td>2.5%</td>
          </tr>
        </tbody>
      </table>

      <p className={classes.footnote}>
        <sup>*</sup>2019 estimates are based on the assumptions used in the
        Adding Up Report, but adjusted for increased use of mifepristone based
        on available data shared by DKT International and PSI, as well as data
        published in the{" "}
        <ExternalLink
          href="https://www.dktinternational.org/contraceptive-social-marketing-statistics/"
          text="DKT Social Marking Statistics"
        />
        .
      </p>
      <p className={classes.footnote}>
        <sup>&dagger;</sup>Surgical dilation methods are not costed in LEAP; see{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-scope")}
        >
          Scope
        </Link>{" "}
        for explanation.
      </p>
    </div>
  );
};

export default RgApacT3;
