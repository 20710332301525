import PropTypes from "prop-types";
import ExternalLink from "../../../Common/ExternalLink";
import Footnote from "../../../Common/Footnote";
import SideBySide from "../../../Common/SideBySide";
import MhCsF3 from "../Figures/MhCsF3";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeComparison,
  getRegionName,
  toLowerCase,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  const notReceived = data?.[0].methods.slice(-1)[0].services ?? 0;
  const lmicNotReceived = data?.slice(-1)[0].methods.slice(-1)[0].services ?? 1;

  return {
    methodShare: data?.[0].share ?? 0,
    notReceived,
    shortfallShare: (notReceived / lmicNotReceived) * 100,
  };
};

const MhCsS4 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/figures/1/${props.regionId}/110`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        Magnesium sulfate is classified as one of the three life-saving maternal
        health commodities, and is used to treat pre-eclampsia and eclampsia
        <Footnote number={5}>
          Abalos E, Cuesta C, Carroli G, Qureshi Z, Widmer M, Vogel JP, Souza
          JP {" "}
          <ExternalLink
            href="https://pubmed.ncbi.nlm.nih.gov/24641531/"
            text="WHO Multicountry Survey on Maternal and Newborn Health Research Network. Pre-eclampsia, eclampsia and adverse maternal and perinatal outcomes: a secondary analysis of the World Health Organization Multicountry Survey on Maternal and Newborn Health"
            useItalics={true}
          />{" "}
          BJOG. 2014 Mar;121 Suppl 1:14-24.
        </Footnote>
        . Roughly 2% of all pregnancies will lead to pre-eclampsia and/or
        eclampsia. In Figure 3, we present the cases of pre-eclampsia and
        eclampsia , separating each into cases where magnesium sulfate was
        provided and those where it was not.
      </p>

      <SideBySide
        component={
          <MhCsF3
            regionId={props.regionId}
            regionName={props.regionName}
            regionShortName={getRegionName(props.regionId, "short")}
            data={data}
          />
        }
      >
        <p>
          Results are shown for {toLowerCase(props.regionName)} in comparison to
          the three country-level income groups.
        </p>

        <p>
          In {toLowerCase(props.regionName)}, magnesium sulfate is given in{" "}
          {formatPercentage(metrics.methodShare)} of cases. This is{" "}
          {getQualitativeComparison(metrics.methodShare, 30)} low-income
          countries (30%) and{" "}
          {getQualitativeComparison(metrics.methodShare, 66)}{" "}
          upper-middle-income countries (66%).
        </p>

        <p>
          In {toLowerCase(props.regionName)}{" "}
          {formatNumberAsHumanReadable(metrics.notReceived)} cases do not
          receive the recommended drugs; this represents{" "}
          {formatPercentage(metrics.shortfallShare)} of the shortfall across all
          low- and middle-income countries.
        </p>
      </SideBySide>
    </>
  );
};

MhCsS4.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsS4;
