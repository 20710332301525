import { useState } from "react";
import BoxControls from "../../Common/Box/BoxControls";
import MethodChooser from "../../Common/Box/Choosers/MethodChooser";
import Figure from "../../Common/Box/Figure";
import initialData from "../../../data/initialData.json";
import useAreaQuery from "../../../hooks/useAreaQuery";
import { getMethod, getTooltip } from "../../../utilities";

const number = 3;

const reshapeData = (data) =>
  data.map((item) => {
    let name;

    switch (item.name) {
      case "Subsidized":
        name = "Private: Subsidized";

        break;

      case "Non-subsidized":
        name = "Private: Non-subsidized";

        break;

      default:
        name = item.name;
    }

    return {
      ...item,
      name,
    };
  });

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Public":
      return theme.palette.sectors.public;

    case "Private: Subsidized":
      return theme.palette.sectors.subsidized;

    case "Private: Non-subsidized":
      return theme.palette.sectors.private;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const series = data.map((series) => ({
    ...series,
    color: getSeriesColor(series.name, theme),
  }));

  return {
    chart: {
      type: "variwide",
      width: 600,
      height: 400,
      backgroundColor: null,
    },
    xAxis: [
      {
        opposite: true,
        categories: ["Low", "Lower-Middle", "Upper-Middle"],
        title: undefined,
        labels: {
          autoRotation: undefined,
          staggerLines: 2,
          useHTML: true,
          style: {
            color: theme.figure.axis.primary,
            fontSize: theme.figure.axis.fontSize,
          },
        },
      },
      {
        type: "linear",
        offset: 10,
        min: 0,
        max: 100,
        title: {
          text: "Share by Income Group",
          style: {
            color: theme.figure.axis.secondary,
            fontSize: theme.figure.axis.fontSize,
          },
        },
        labels: {
          format: "{value}%",
          style: {
            color: theme.figure.axis.secondary,
            fontSize: theme.figure.axis.fontSize,
          },
        },
        lineColor: theme.figure.axis.secondary,
        tickInterval: 20,
        tickPosition: "inside",
        tickLength: 5,
        tickColor: theme.figure.axis.secondary,
      },
    ],
    yAxis: {
      offset: 10,
      title: {
        text: "Share by Sector",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
      min: 0,
      max: 100,
      reversedStacks: false,
      labels: {
        format: "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
      lineWidth: 1,
      lineColor: theme.figure.axis.secondary,
      tickInterval: 20,
      tickPosition: "inside",
      tickLength: 5,
      tickWidth: 1,
      tickColor: theme.figure.axis.secondary,
    },
    plotOptions: {
      variwide: {
        stacking: "normal",
        borderWidth: 1,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip(undefined, this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const Component = () => {
  const [selectedMethodId, setSelectedMethodId] = useState(0);

  const { areaData: data } = useAreaQuery(
    `/fp/figures/3/${selectedMethodId}`,
    initialData["fp_f3"]
  );

  const handleMethodChange = (methodId) => {
    setSelectedMethodId(methodId);
  };

  return (
    <Figure
      id="FP_f3"
      number={number.toString()}
      fileStem={`fp_f${number}`}
      title="Share of Contraceptive Users by Sector"
      subtitle="By Income Group, 2019"
      selection={getMethod(selectedMethodId)}
      controls={
        <BoxControls>
          <MethodChooser
            area="contraception"
            value={selectedMethodId}
            onChange={handleMethodChange}
          />
        </BoxControls>
      }
      chartOptions={options(reshapeData(data))}
    />
  );
};

export default Component;
