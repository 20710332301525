import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { ErrorBoundary } from "react-error-boundary";
import { chartDownloadImageScaleFactor } from "../../../constants";
import Error from "../Error";
import { useAnalytics } from "../../../hooks/useAnalytics";
import DownloadButton from "./DownloadButton";
import DownloadMenu from "./DownloadMenu";

const useStyles = makeStyles((theme) => ({
  caption: {
    backgroundColor: theme.palette.area.primary,
    color: "white",
    fontWeight: "bold",
    marginTop: -1,
    padding: theme.spacing(1, 1.5),
    textTransform: "uppercase",
    width: "fit-content",
  },
  component: {
    backgroundColor: (props) =>
      props.backgroundColor || theme.palette.area.background,
    borderTop: `2px solid ${theme.palette.area.primary}`,
    overflow: "hidden",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(4, 0, 1),
  },
  controls: {
    marginTop: theme.spacing(2),
  },
  error: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
  },
  main: {
    padding: theme.spacing(2),
  },
  note: {
    fontStyle: "italic",
    marginTop: theme.spacing(),
  },
  source: {
    fontSize: 12,
    marginTop: theme.spacing(),
  },
  subtitle: {
    fontSize: "1.2rem",
    fontStyle: "italic",
    fontWeight: theme.typography.fontWeightLight,
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  titles: {
    flexGrow: 1,
  },
  toolbar: {
    display: "flex",
  },
}));

const ErrorBoundedComponent = (props) => (
  <ErrorBoundary key={props.id} FallbackComponent={Error}>
    {props.children}
  </ErrorBoundary>
);

const Box = (props) => {
  const [downloadMenuAnchorElement, setDownloadMenuAnchorElement] = useState();

  const analytics = useAnalytics();

  const classes = useStyles(props);

  const enablePngDownloads = props.onDownloadPng || props.highchartRef;
  const enableDownloads = props.onDownloadCsv || enablePngDownloads;

  const handleDownloadMenuClose = (e) => {
    setDownloadMenuAnchorElement(undefined);
  };

  const handlePngDownload = () => {
    const filename = `${props.fileStem || props.id || "chart"}`;

    if (props.onDownloadPng) {
      props.onDownloadPng();
    } else if (props.highchartRef) {
      props.highchartRef.current.chart.exportChart({
        type: "image/png",
        title: undefined,
        filename,
        scale: chartDownloadImageScaleFactor,
      });

      analytics.trackGoal(filename);
    }

    handleDownloadMenuClose();
  };

  const handleCsvDownload = () => {
    props.onDownloadCsv?.();

    handleDownloadMenuClose();
  };

  return (
    <ErrorBoundedComponent
      FallbackComponent={
        <div className={classes.error}>
          <Error />
        </div>
      }
    >
      <div className={classes.component}>
        <div className={classes.caption}>{props.caption}</div>

        <div className={classes.main}>
          <div className={classes.toolbar}>
            <div className={classes.titles}>
              <div className={classes.title}>{props.title}</div>
              {props.subtitle && (
                <div className={classes.subtitle}>{props.subtitle}</div>
              )}
            </div>
            {enableDownloads && (
              <div>
                <DownloadButton
                  onClick={(e) => {
                    setDownloadMenuAnchorElement(e.currentTarget);
                  }}
                />
              </div>
            )}
          </div>

          {props.note && <div className={classes.note}>{props.note}</div>}

          <div className={classNames(classes.content, props.classes?.content)}>
            {props.children}
          </div>

          {props.source && <div className={classes.source}>{props.source}</div>}

          {props.controls && (
            <div className={classes.controls}>{props.controls}</div>
          )}
        </div>
      </div>

      <DownloadMenu
        anchorElement={downloadMenuAnchorElement}
        open={Boolean(downloadMenuAnchorElement)}
        onDownloadCsv={props.onDownloadCsv && handleCsvDownload}
        onDownloadPng={enablePngDownloads ? handlePngDownload : undefined}
        onClose={handleDownloadMenuClose}
      ></DownloadMenu>
    </ErrorBoundedComponent>
  );
};

Box.propTypes = {
  id: PropTypes.string,
  backgroundColor: PropTypes.string,
  caption: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  note: PropTypes.string,
  source: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  controls: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  highchartRef: PropTypes.object,
  onDownloadCsv: PropTypes.func,
  onDownloadPng: PropTypes.func,
};

Box.defaultProps = {
  backgroundColor: "white",
  controls: null,
};

export default Box;
