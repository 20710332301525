import PropTypes from "prop-types";
import { Link } from "react-scroll";

const ScrollLink = (props) => (
  <Link
    to={props.to}
    offset={-140}
    smooth={true}
    duration={300}
    spy={true}
    hashSpy={false}
  >
    {props.children}
  </Link>
);

ScrollLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ScrollLink;
