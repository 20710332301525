import PropTypes from "prop-types";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeComparison,
  toLowerCase,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  if (!data) {
    return {
      menstruators: 0,
      percentageMenstruators: 0,
      percentageUsingPurposeMadeProducts: 0,
      minimumPercentageUsingPurposeMadeProducts: 0,
      maximumPercentageUsingPurposeMadeProducts: 0,
      percentageUsingPurposeMadeProductsInTopCountries: 0,
    };
  }

  const menstruators = data.reduce(
    (acc, country) =>
      acc + country.methods[0].menstruators + country.methods[1].menstruators,
    0
  );

  const menstruatorsUsingPurposeMadeProducts = data.reduce(
    (accumulator, country) => accumulator + country.methods[0].menstruators,
    0
  );

  const percentagesUsingPurposeMadeProducts = data.map(
    (country) =>
      (country.methods[0].menstruators /
        (country.methods[0].menstruators + country.methods[1].menstruators)) *
      100
  );

  const menstruatorsUsingPurposeMadeProductsInTopCountries = data
    .slice(0, 5)
    .reduce(
      (accumulator, country) => accumulator + country.methods[0].menstruators,
      0
    );

  return {
    menstruators,
    percentageMenstruators: (menstruators / 1672570756) * 100,
    percentageUsingPurposeMadeProducts:
      (menstruatorsUsingPurposeMadeProducts / menstruators) * 100,
    minimumPercentageUsingPurposeMadeProducts: Math.min(
      ...percentagesUsingPurposeMadeProducts
    ),
    maximumPercentageUsingPurposeMadeProducts: Math.max(
      ...percentagesUsingPurposeMadeProducts
    ),
    percentageUsingPurposeMadeProductsInTopCountries:
      (menstruatorsUsingPurposeMadeProductsInTopCountries /
        menstruatorsUsingPurposeMadeProducts) *
      100,
  };
};

const MhmCcCurrentUse = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cc/figures/1/${props.regionId}`
  );

  const metrics = calculateMetrics(data);

  return (
    <div>
      <p>
        There are a total of {formatNumberAsHumanReadable(metrics.menstruators)}{" "}
        menstruators living in {toLowerCase(props.regionName)}, which represents{" "}
        {formatPercentage(metrics.percentageMenstruators)} of the total
        menstruators living across all low- and middle-income countries.
      </p>

      <p>
        In {toLowerCase(props.regionName)}{" "}
        {formatPercentage(metrics.percentageUsingPurposeMadeProducts)} of
        menstruators use purpose-made methods; this is{" "}
        {getQualitativeComparison(
          Math.round(metrics.percentageUsingPurposeMadeProducts),
          72
        )}{" "}
        the average across all low- and middle-income countries (72%).
      </p>

      <p>
        The use of purpose-made products ranges from{" "}
        {formatPercentage(metrics.minimumPercentageUsingPurposeMadeProducts)} to{" "}
        {formatPercentage(metrics.maximumPercentageUsingPurposeMadeProducts)}{" "}
        across countries in {toLowerCase(props.regionName)}.
      </p>

      <p>
        Driven by demographic differences and differences in levels of use of
        purpose-made products,{" "}
        {formatPercentage(
          metrics.percentageUsingPurposeMadeProductsInTopCountries
        )}{" "}
        of menstruators using purpose-made products live in the top five
        countries in {toLowerCase(props.regionName)}.
      </p>

      <p>Variation by country can be seen in the figure below.</p>
    </div>
  );
};

MhmCcCurrentUse.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCcCurrentUse;
