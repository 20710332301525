import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import IndicatorChooser from "../../IndicatorChooser";
import { getTooltip, toTitleCase } from "../../../../utilities";

const number = 4;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Maintain product use":
      return theme.palette.scenarios.scenario1;

    case "Increase purpose-made":
      return theme.palette.scenarios.scenario2;

    case "Increase purpose-made & reusable":
      return theme.palette.scenarios.scenario3;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const financial = props.indicatorId === 1;

  const series = data.map((scenario) => ({
    name: scenario.name,
    data: scenario.incomeGroups.map(({ value }) => value),
    color: getSeriesColor(scenario.name, theme),
  }));

  return {
    chart: {
      type: "column",
      width: 500,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: ["Low", "Lower-Middle", "Upper-Middle"],
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        formatter: function () {
          return (
            (financial ? "$" : "") + this.axis.defaultLabelFormatter.call(this)
          );
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        pointWidth: 25,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip(
          props.indicatorName,
          this.y,
          undefined,
          financial
            ? {
                prefix: "$",
              }
            : undefined
        );
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const MhmCpF4 = (props) => (
  <Figure
    id="MHM_CP_f4"
    number={number.toString()}
    fileStem={`mhm_cp_f${number}`}
    title={`Future ${props.indicatorQualifier} ${toTitleCase(
      props.methodName
    )}`}
    subtitle="By Income Group and Scenario, 2030"
    controls={
      <BoxControls>
        <IndicatorChooser
          area="menstrual-hygiene"
          value={props.indicatorId}
          onChange={props.onIndicatorChange}
        />
      </BoxControls>
    }
    chartOptions={
      props.data &&
      options(props.data, {
        indicatorId: props.indicatorId,
        indicatorName: props.indicatorName,
      })
    }
  />
);

MhmCpF4.propTypes = {
  methodName: PropTypes.string.isRequired,
  indicatorId: PropTypes.number.isRequired,
  indicatorName: PropTypes.string.isRequired,
  indicatorQualifier: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      scenarios: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  onIndicatorChange: PropTypes.func,
};

export default MhmCpF4;
