import { useState } from "react";
import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import { getSingular, getTooltip } from "../../../../utilities";

const number = 1;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Heat-stable carbetocin":
      return theme.palette.areas.maternalHealth.methods.heatStableCarbetocin;

    case "Metronidazole":
      return theme.palette.areas.maternalHealth.methods.metronidazole;

    case "Oxytocin":
      return theme.palette.areas.maternalHealth.methods.oxytocin;

    case "Misoprostol":
      return theme.palette.areas.maternalHealth.methods.misoprostol;

    case "Magnesium sulfate":
      return theme.palette.areas.maternalHealth.methods.magnesiumSulfate;

    case "Methyldopa":
      return theme.palette.areas.maternalHealth.methods.methyldopa;

    case "Hydralazine":
      return theme.palette.areas.maternalHealth.methods.hydralazine;

    case "Iron-folic acid":
      return theme.palette.areas.maternalHealth.methods.ironFolicAcid;

    case "Out-of-scope":
      return theme.palette.areas.maternalHealth.methods.outOfScope;

    case "Needed: Not received":
      return theme.palette.areas.maternalHealth.methods.needsDidNotGet;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = data[0].methods.map((method, index) => ({
    name: method.name,
    data: data.map(({ methods }) => methods[index].services),
    color: getSeriesColor(method.name, theme),
  }));

  return {
    chart: {
      type: "bar",
      width: 800,
      height: 500,
      backgroundColor: null,
      marginBottom: 70,
    },
    xAxis: {
      categories: data.map(({ name }) => name),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      opposite: true,
      reversedStacks: false,
      max: absolute ? null : 100,
      labels: {
        format: absolute ? undefined : "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      bar: {
        stacking: absolute ? "normal" : "percent",
        borderWidth: 0,
        groupPadding: 0.1,
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      reversed: false,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Services", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const MhCcF1 = (props) => {
  const [comparisonMode, setComparisonMode] = useState("relative");

  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  return (
    <Figure
      id="MH_CC_f1"
      number={number.toString()}
      title="Cases that Need and Receive Drugs for Iron and Folic Acid Supplementation"
      subtitle={`By ${getSingular(props.regionalUnitName)}, 2019`}
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        />
      }
      chartOptions={
        props.data?.length
          ? options(props.data, {
              comparisonMode,
            })
          : undefined
      }
    />
  );
};

MhCcF1.propTypes = {
  regionalUnitName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      methods: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          services: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ),
};

export default MhCcF1;
