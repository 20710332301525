import PropTypes from "prop-types";
import MhCcF10 from "../Figures/MhCcF10";

const MhCcS13 = (props) => {
  return (
    <>
      <p>
        The change in cost varies across regions. Use the dropdowns below to
        explore results for each scenario and for specific drug(s).
      </p>

      <MhCcF10
        regionalUnitId={props.regionalUnitId}
        regionalUnitName={props.regionalUnitName}
      />
    </>
  );
};

MhCcS13.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcS13;
