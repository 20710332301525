import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const useStyles = makeStyles((theme) => ({
  table: {
    "& td": {
      padding: theme.spacing(0, 1),
    },
  },
}));

const AbortionTerms = () => {
  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Key Terms and Acronyms</Typography>

      <p>
        <strong>Unit</strong>: The unit of analysis for abortion and post
        abortion care (PAC) within LEAP is a &apos;service&apos;: this could be
        an abortion service to end a pregnancy, or a PAC service to complete an
        incomplete abortion or miscarriage. We have intentionally chosen the
        word &apos;service&apos; as it is a fairly neutral word, as opposed to
        &apos;procedure&apos;, &apos;termination&apos;, or other terms sometimes
        used to describe abortions.
      </p>
      <p>
        &apos;Service&apos; also allows us to account for when one pregnant
        woman may receive multiple services, for example when an abortion also
        requires PAC to complete the abortion. While in some places we use the
        term &apos;women and girls&apos; for readability purposes, we recognize
        that some abortion and PAC services may be obtained by transgender men,
        or non-binary people. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-abortion-services-by-type")}
        >
          Total Abortion Services by Type
        </Link>{" "}
        and{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-pac-services-by-type")}
        >
          Total PAC Services by Type
        </Link>{" "}
        below.
      </p>
      <p>
        <strong>Quantities</strong>: The &apos;quantities&apos; for abortion and
        PAC are the supplies needed to provide a service. For surgical services
        (vacuum aspiration) this includes manual vacuum aspiration (MVA) kits
        with aspirator and cannulae and one dose of misoprostol, while costs for
        medical services includes the number of misoprostol or mifepristone
        pills needed. We estimate the number of supplies needed for abortion and
        PAC services at different pregnancy gestations. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-quantities")}
        >
          Supply Quantities
        </Link>{" "}
        below.
      </p>
      <p>
        <strong>Cost</strong>: The &apos;cost&apos; is the price of the supplies
        to provide the abortion or PAC service, and not any associated fees
        (e.g. to cover a health provider&apos;s time to deliver a service, or
        provide any pre- or post-service counselling). These costs are generally
        paid by the consumer (i.e. the person seeking an abortion), though
        PAC-related costs may be more likely to be covered by public health
        systems. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-costs")}
        >
          Supply Costs
        </Link>{" "}
        below.
      </p>
      <p>
        <strong>Abortion Safety</strong>: &apos;Safe abortions&apos; are defined
        by the World Health Organization (WHO) as when the abortion: 1) is
        provided by an appropriately trained person and 2) uses a surgical or
        medical method recommended by the WHO appropriate to the pregnancy
        gestation
        <Footnote number={1}>
          World Health Organization{" "}
          <ExternalLink
            href="https://www.who.int/news-room/fact-sheets/detail/preventing-unsafe-abortion"
            text="Preventing Unsafe Abortion Factsheet."
            useItalics={true}
          />
          .
        </Footnote>
        . &apos;Less safe&apos; abortions meet only one of these two criteria;
        &apos;least safe&apos; abortions meet neither of these criteria. The
        quality of the medical and surgical supplies used also affects the
        safety of services. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-scope")}
        >
          Scope
        </Link>{" "}
        (What) below for more detail.
      </p>
      <p>
        <strong>Post abortion care (PAC)</strong>: PAC refers to a range of
        services to manage complications arising from abortions or miscarriage.
        For LEAP, we focus on a sub-set of PAC services&mdash;those that
        complete an incomplete abortion or miscarriage.
      </p>

      <p>
        <strong>Acronyms</strong>:
      </p>

      <table className={classes.table}>
        <tbody>
          <tr>
            <td>D&amp;C</td>
            <td>Dilation and curettage</td>
          </tr>
          <tr>
            <td>D&amp;E</td>
            <td>Dilation and evacuation</td>
          </tr>
          <tr>
            <td>EVA</td>
            <td>Electric vacuum aspiration</td>
          </tr>
          <tr>
            <td>LEAP</td>
            <td>
              LandscapE And Projection of Reproductive Health Supply Needs
            </td>
          </tr>
          <tr>
            <td>MVA</td>
            <td>Manual vacuum aspiration</td>
          </tr>
          <tr>
            <td>PAC</td>
            <td>Post abortion care</td>
          </tr>
          <tr>
            <td>SMO</td>
            <td>Social Marketing Organization</td>
          </tr>
          <tr>
            <td>WHO</td>
            <td>World Health Organization</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AbortionTerms;
