import PropTypes from "prop-types";
import SideBySide from "../../../Common/SideBySide";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatPercentage } from "../../../../utilities";
import MhCcT1 from "../Tables/MhCcT1";

const MhCcS1 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cc/tables/1/${props.regionalUnitId}`
  );

  return (
    <SideBySide
      component={
        <MhCcT1 regionalUnitName={props.regionalUnitName} data={data} />
      }
    >
      <p>
        In 2019 there were an estimated 214 million pregnancies across all low-
        and middle-income countries.
      </p>

      <p>
        The largest share of pregnancies occur among women living in{" "}
        {data?.[0].name}, this region accounts for{" "}
        {formatPercentage(
          ((data?.[0].pregnancies ?? 0) / 213694809.51380473) * 100
        )}{" "}
        of the total.
      </p>
    </SideBySide>
  );
};

MhCcS1.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcS1;
