import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import ComparisonModeSwitch from "./ComparisonModeSwitch";

const useStyles = makeStyles((theme) => ({
  component: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
}));

const BoxControls = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.component}>
      <div>{props.children}</div>
      {props.comparisionMode && (
        <ComparisonModeSwitch
          mode={props.comparisionMode}
          onToggle={props.onToggleComparisonMode}
        />
      )}
    </div>
  );
};

BoxControls.propTypes = {
  comparisionMode: PropTypes.oneOf(["absolute", "relative"]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onToggleComparisonMode: PropTypes.func,
};

BoxControls.defaultProps = {
  children: null,
};

export default BoxControls;
