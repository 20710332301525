import { createContext, useContext, useState } from "react";

const readersGuideContext = createContext();

const useProvideReadersGuide = () => {
  const [slug, setSlug] = useState();
  const [open, setOpen] = useState(false);

  return {
    slug,
    setSlug,
    open,
    setOpen,
  };
};

export const ProvideReadersGuide = ({ children }) => {
  const readersGuide = useProvideReadersGuide();

  return (
    <readersGuideContext.Provider value={readersGuide}>
      {children}
    </readersGuideContext.Provider>
  );
};

export const useReadersGuide = () => {
  return useContext(readersGuideContext);
};
