import { useState } from "react";
import PropTypes from "prop-types";
import { getIndicatorDetails } from "../../../../utilities";
import ApacCpF4 from "../Figures/ApacCpF4";

const ApacCpS4 = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(3);

  const selectedIndicatorDetails = getIndicatorDetails(selectedIndicatorId);

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  return (
    <>
      <p>
        The distribution of {selectedIndicatorDetails.name.toLowerCase()} across{" "}
        {props.regionalUnitName} can be seen in Figure 4. Use the dropdown to
        explore other indicators.
      </p>

      <ApacCpF4
        productId={props.productId}
        productName={props.productName}
        regionalUnitId={props.regionalUnitId}
        regionalUnitName={props.regionalUnitName}
        indicatorId={selectedIndicatorId}
        onIndicatorChange={handleIndicatorChange}
      />
    </>
  );
};

ApacCpS4.propTypes = {
  productId: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default ApacCpS4;
