import { useState } from "react";
import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CustomReport from "../CustomReport";
import DatasetButton from "../DatasetButton";
import { useReadersGuide } from "../../../hooks/useReadersGuide";
import RegionChooser from "../RegionChooser";
import { getRegionName } from "../../../utilities";
import MhCsB1 from "./Boxes/MhCsB1";
import MaternalHealthIntroduction from "./MaternalHealthIntroduction";
import MaternalHealthScenarios from "./MaternalHealthScenarios";
import MhCsS1 from "./Summaries/MhCsS1";
import MhCsS2 from "./Summaries/MhCsS2";
import MhCsS3 from "./Summaries/MhCsS3";
import MhCsS4 from "./Summaries/MhCsS4";
import MhCsS5 from "./Summaries/MhCsS5";
import MhCsS6 from "./Summaries/MhCsS6";
import MhCsS7 from "./Summaries/MhCsS7";
import MhCsS8 from "./Summaries/MhCsS8";
import MhCsS9 from "./Summaries/MhCsS9";
import MhCsS10 from "./Summaries/MhCsS10";
import MhCsS11 from "./Summaries/MhCsS11";
import MhCsS12 from "./Summaries/MhCsS12";
import MhCsS13 from "./Summaries/MhCsS13";
import MhCsT4 from "./Tables/MhCsT4";
import MhCsT5 from "./Tables/MhCsT5";
import MhCsT6 from "./Tables/MhCsT6";
import MhCsT7 from "./Tables/MhCsT7";

const useStyles = makeStyles((theme) => ({
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
}));

const MaternalHealthLandscape = () => {
  const [selectedRegionId, setSelectedRegionId] = useState(158);

  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const selectedRegionLongName = getRegionName(selectedRegionId, "long");

  const handleRegionChange = (regionId) => {
    setSelectedRegionId(regionId);
  };

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <CustomReport>
      <Typography variant="h1">
        Maternal Health Landscape for {selectedRegionLongName}
      </Typography>

      <RegionChooser value={selectedRegionId} onChange={handleRegionChange} />

      <section id="introduction">
        <Typography variant="h2">Background</Typography>

        <div className={classes.boxes}>
          <MaternalHealthIntroduction>
            The <strong>Maternal Health Custom Landscape</strong> provides
            estimates on the number of cases needing and receiving each drug,
            the volumes of maternal health drugs, and related drug costs for the
            selected region.
          </MaternalHealthIntroduction>

          <MhCsB1 regionId={selectedRegionId} />
        </div>
      </section>

      <section id="current">
        <Typography variant="h2">Where We Are Now</Typography>

        <div className={classes.boxes}>
          <Typography variant="h6">
            Current Use of Priority Maternal Health Drugs in{" "}
            {selectedRegionLongName}
          </Typography>

          <MhCsS1
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <p>
            Different interventions are needed throughout pregnancy and
            childbirth. In this section we present results based on the
            intervention or group of interventions using one of the seven
            priority health drugs. In cases where multiple drugs are used for
            the same/similar intervention results are presented together.
          </p>

          <MhCsS2
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhCsS3
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhCsS4
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhCsS5
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhCsS6
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhCsS7
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <Typography variant="h6">
            Current Cost of Seven Priority Maternal Health Drugs in{" "}
            {selectedRegionLongName}
          </Typography>

          <p>
            We estimate the cost of the drugs received by women during pregnancy
            and childbirth. It is important to note that we assess costs from
            the perspective of the institutional purchasers of the drugs
            (governments, international donors, etc.), as opposed to the women
            receiving the drugs, since they are often provided free of charge by
            the public health system. See the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("maternal-health-costs")}
            >
              Reader&apos;s Guide
            </Link>{" "}
            for more details.
          </p>

          <MhCsS8
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />
        </div>
      </section>

      <section id="future">
        <Typography variant="h2">Changes Ahead</Typography>

        <div className={classes.boxes}>
          <MaternalHealthScenarios />

          <Typography variant="h6">
            Future Use of Priority Maternal Health Drugs in{" "}
            {selectedRegionLongName}
          </Typography>

          <MhCsS9
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhCsS10
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhCsS11
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <Typography variant="h6">
            Future Cost of Priority Maternal Health Drugs in{" "}
            {selectedRegionLongName}
          </Typography>

          <p>
            We project changes in the cost of the seven priority maternal health
            drugs plus the two emerging drugs, from 2019 to 2030, under each of
            the three Scenarios. As noted previously, we assess cost from the
            perspective of institutional procurers, as opposed to the women
            receiving the drugs, since they are often provided free of charge by
            the public health system. Note that all costs are based on current
            drug costs, held constant for all years through to 2030. See the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("maternal-health-costs")}
            >
              Reader&apos;s Guide
            </Link>{" "}
            for more detail.
          </p>

          <MhCsS12
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhCsS13
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />
        </div>
      </section>

      <section id="annex">
        <Typography variant="h2">Annex</Typography>

        <div className={classes.boxes}>
          <MhCsT4
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhCsT5
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhCsT6
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhCsT7
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <div className={classes.datasetDownload}>
            <DatasetButton area="Maternal Health" regionId={selectedRegionId} />
          </div>
        </div>
      </section>
    </CustomReport>
  );
};

export default MaternalHealthLandscape;
