import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Information from "../../Common/Box/Information";

const useStyles = makeStyles((theme) => ({
  table: {
    display: "flex",
    "& > div": {
      padding: theme.spacing(0, 2),
      width: "50%",
    },
    "& > div:first-child": {
      borderRight: `1px ${theme.table.border.secondary} solid`,
    },
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    textAlign: "center",
  },
}));

const MhmB2 = ({ onOpenReadersGuide }) => {
  const classes = useStyles();

  return (
    <Information key="MHM_b1" number="2" title="Menstrual Hygiene Products">
      <p>
        Menstrual hygiene products can be disposable or reusable. Some products
        are purpose-made for managing menstruation, while others are adapted for
        that purpose. See the{" "}
        <Link
          href="#"
          onClick={onOpenReadersGuide?.("menstrual-hygiene-scope")}
        >
          Reader&apos;s Guide
        </Link>{" "}
        more detail.
      </p>
      <div className={classes.table}>
        <div>
          <div className={classes.title}>Purpose-Made Products</div>

          <p>Include:</p>

          <ul>
            <li>
              Disposable pads<sup>&dagger;</sup>
            </li>
            <li>
              Disposable tampons<sup>&dagger;</sup>
            </li>
            <li>Reusable pads and underwear</li>
            <li>Reusable cups</li>
          </ul>

          <p>
            We estimate: the number of menstruators using these products; the
            quantities of supplies they use; and the associated cost.
          </p>

          <p>
            <sup>&dagger;</sup>Combined in results
          </p>
        </div>

        <div>
          <div className={classes.title}>Non-Purpose-Made Products</div>

          <p>Include:</p>

          <ul>
            <li>Cloth</li>
            <li>Cotton wool</li>
            <li>Natural materials</li>
            <li>Newspaper</li>
            <li>Other materials</li>
          </ul>

          <p>
            We estimate: the overall number of menstruators using these
            products; no quantities or costs are estimated.
          </p>
        </div>
      </div>
    </Information>
  );
};

MhmB2.propTypes = {
  onOpenReadersGuide: PropTypes.func,
};

export default MhmB2;
