import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import BoxControls from "../../../Common/Box/BoxControls";
import DrugChooser from "../../../Common/Box/Choosers/DrugChooser";
import ScenarioChooser from "../../../Common/Box/Choosers/ScenarioChooser";
import Figure from "../../../Common/Box/Figure";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  getSingular,
  getTooltip,
  translateSectorName,
} from "../../../../utilities";

const number = 7;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Heat-stable carbetocin":
      return theme.palette.areas.maternalHealth.methods.heatStableCarbetocin;

    case "Metronidazole":
      return theme.palette.areas.maternalHealth.methods.metronidazole;

    case "Oxytocin":
      return theme.palette.areas.maternalHealth.methods.oxytocin;

    case "Misoprostol":
      return theme.palette.areas.maternalHealth.methods.misoprostol;

    case "Magnesium sulfate":
      return theme.palette.areas.maternalHealth.methods.magnesiumSulfate;

    case "Methyldopa":
      return theme.palette.areas.maternalHealth.methods.methyldopa;

    case "Hydralazine":
      return theme.palette.areas.maternalHealth.methods.hydralazine;

    case "Iron-folic acid":
      return theme.palette.areas.maternalHealth.methods.ironFolicAcid;

    case "Out-of-scope":
      return theme.palette.areas.maternalHealth.methods.outOfScope;

    case "Needed: Not received":
      return theme.palette.areas.maternalHealth.methods.needsDidNotGet;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = data.map((method) => ({
    name: method.name,
    data: method.countryGroups.map(({ services }) => services),
    color: getSeriesColor(method.name, theme),
  }));

  return {
    chart: {
      type: "bar",
      width: 800,
      height: 500,
      backgroundColor: null,
      marginBottom: 70,
    },
    xAxis: {
      categories: data[0].countryGroups.map(({ name }) => name),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      opposite: true,
      reversedStacks: false,
      max: absolute ? null : 100,
      labels: {
        format: absolute ? undefined : "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      bar: {
        stacking: absolute ? "normal" : "percent",
        borderWidth: 0,
        groupPadding: 0.1,
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      reversed: false,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Services", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const useStyles = makeStyles((theme) => ({
  choosers: {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(2),
    width: "fit-content",
  },
}));

const MhCcF7 = (props) => {
  const [selectedScenarioId, setSelectedScenarioId] = useState(5);
  const [selectedSectorId, setSelectedSectorId] = useState(112);
  const [selectedSectorName, setSelectedSectorName] = useState(
    "Iron and folic acid"
  );
  const [comparisonMode, setComparisonMode] = useState("relative");

  const { areaData: data } = useAreaQuery(
    `/custom/mh/cc/figures/7/${props.regionalUnitId}/${selectedScenarioId}/${selectedSectorId}`
  );

  const classes = useStyles();

  const handleScenarioChange = (scenarioId) => {
    setSelectedScenarioId(scenarioId);
  };

  const handleSectorChange = (sector) => {
    setSelectedSectorId(sector.id);
    setSelectedSectorName(sector.name);
  };

  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  return (
    <Figure
      id="MH_CC_f7"
      number={number.toString()}
      title={`Future Cases that Need and Receive Drugs for ${translateSectorName(
        selectedSectorName
      )}`}
      subtitle={`By ${getSingular(
        props.regionalUnitName
      )} for Selected Scenario, 2030`}
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        >
          <div className={classes.choosers}>
            <ScenarioChooser
              area="maternal-health"
              value={selectedScenarioId}
              onChange={handleScenarioChange}
            />
            <DrugChooser
              value={selectedSectorId}
              onChange={handleSectorChange}
            />
          </div>
        </BoxControls>
      }
      chartOptions={
        data?.length
          ? options(data, {
              comparisonMode,
            })
          : undefined
      }
    />
  );
};

MhCcF7.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcF7;
