import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { LoremIpsum } from "react-lorem-ipsum";
import AbortionAbortionServicesByType from "./Abortion/AbortionAbortionServicesByType";
import AbortionCosts from "./Abortion/AbortionCosts";
import AbortionDataSources from "./Abortion/AbortionDataSources";
import AbortionFutureScenarios from "./Abortion/AbortionFutureScenarios";
import AbortionPacServicesByType from "./Abortion/AbortionPacServicesByType";
import AbortionQuantities from "./Abortion/AbortionQuantities";
import AbortionScope from "./Abortion/AbortionScope";
import AbortionTerms from "./Abortion/AbortionTerms";
import AbortionTotalAbortions from "./Abortion/AbortionTotalAbortions";
import ContraceptionCosts from "./Contraception/ContraceptionCosts";
import ContraceptionDataSources from "./Contraception/ContraceptionDataSources";
import ContraceptionFutureScenarios from "./Contraception/ContraceptionFutureScenarios";
import ContraceptionQuantities from "./Contraception/ContraceptionQuantities";
import ContraceptionScope from "./Contraception/ContraceptionScope";
import ContraceptionTerms from "./Contraception/ContraceptionTerms";
import ContraceptionTotalUsers from "./Contraception/ContraceptionTotalUsers";
import ContraceptionUsersByMethod from "./Contraception/ContraceptionUsersByMethod";
import ContraceptionUsersBySector from "./Contraception/ContraceptionUsersBySector";
import MaternalHealthBirths from "./MaternalHealth/MaternalHealthBirths";
import MaternalHealthCases from "./MaternalHealth/MaternalHealthCases";
import MaternalHealthCosts from "./MaternalHealth/MaternalHealthCosts";
import MaternalHealthDataSources from "./MaternalHealth/MaternalHealthDataSources";
import MaternalHealthFutureScenarios from "./MaternalHealth/MaternalHealthFutureScenarios";
import MaternalHealthQuantities from "./MaternalHealth/MaternalHealthQuantities";
import MaternalHealthScope from "./MaternalHealth/MaternalHealthScope";
import MaternalHealthTerms from "./MaternalHealth/MaternalHealthTerms";
import MenstrualHygieneCosts from "./MenstrualHygiene/MenstrualHygieneCosts";
import MenstrualHygieneDataSources from "./MenstrualHygiene/MenstrualHygieneDataSources";
import MenstrualHygieneFutureScenarios from "./MenstrualHygiene/MenstrualHygieneFutureScenarios";
import MenstrualHygieneMenstruatorsByProductType from "./MenstrualHygiene/MenstrualHygieneMenstruatorsByProductType";
import MenstrualHygieneQuantities from "./MenstrualHygiene/MenstrualHygieneQuantities";
import MenstrualHygieneScope from "./MenstrualHygiene/MenstrualHygieneScope";
import MenstrualHygieneTerms from "./MenstrualHygiene/MenstrualHygieneTerms";
import MenstrualHygieneTotalMenstruators from "./MenstrualHygiene/MenstrualHygieneTotalMenstruators";
import CountryGroups from "./Overall/CountryGroups";
import DataSources from "./Overall/DataSources";
import Definitions from "./Overall/Definitions";
import FutureScenarios from "./Overall/FutureScenarios";
import IncomeGroups from "./Overall/IncomeGroups";
import Interpretations from "./Overall/Interpretations";
import LeapResources from "./Overall/LeapResources";
import OverviewOfLeap from "./Overall/OverviewOfLeap";
import RelativeVersusAbsolute from "./Overall/RelativeVersusAbsolute";
import Rounding from "./Overall/Rounding";
import Timeframe from "./Overall/Timeframe";

const useStyles = makeStyles((theme) => ({
  component: {
    maxWidth: 1152,
    width: "100%",
  },
}));

const Placeholder = () => (
  <div>
    <Typography variant="h1">Reader&apos;s Guide</Typography>
    <LoremIpsum p={3} />
  </div>
);

const Section = ({ section }) => {
  const classes = useStyles();

  const getSelectedContent = () => {
    switch (section) {
      case "overview-of-leap":
        return <OverviewOfLeap />;

      case "leap-resources":
        return <LeapResources />;

      case "income-groups":
        return <IncomeGroups />;

      case "definitions":
        return <Definitions />;

      case "interpretations":
        return <Interpretations />;

      case "data-sources":
        return <DataSources />;

      case "timeframe":
        return <Timeframe />;

      case "future-scenarios":
        return <FutureScenarios />;

      case "country-groups":
        return <CountryGroups />;

      case "relative-vs-absolute":
        return <RelativeVersusAbsolute />;

      case "rounding":
        return <Rounding />;

      case "contraception-terms":
        return <ContraceptionTerms />;

      case "contraception-scope":
        return <ContraceptionScope />;

      case "contraception-data-sources":
        return <ContraceptionDataSources />;

      case "contraception-total-users":
        return <ContraceptionTotalUsers />;

      case "contraception-users-by-method":
        return <ContraceptionUsersByMethod />;

      case "contraception-users-by-sector":
        return <ContraceptionUsersBySector />;

      case "contraception-quantities":
        return <ContraceptionQuantities />;

      case "contraception-costs":
        return <ContraceptionCosts />;

      case "contraception-future-scenarios":
        return <ContraceptionFutureScenarios />;

      case "menstrual-hygiene-terms":
        return <MenstrualHygieneTerms />;

      case "menstrual-hygiene-scope":
        return <MenstrualHygieneScope />;

      case "menstrual-hygiene-data-sources":
        return <MenstrualHygieneDataSources />;

      case "menstrual-hygiene-total-menstruators":
        return <MenstrualHygieneTotalMenstruators />;

      case "menstrual-hygiene-menstruators-by-product-type":
        return <MenstrualHygieneMenstruatorsByProductType />;

      case "menstrual-hygiene-quantities":
        return <MenstrualHygieneQuantities />;

      case "menstrual-hygiene-costs":
        return <MenstrualHygieneCosts />;

      case "menstrual-hygiene-future-scenarios":
        return <MenstrualHygieneFutureScenarios />;

      case "abortion-terms":
        return <AbortionTerms />;

      case "abortion-scope":
        return <AbortionScope />;

      case "abortion-data-sources":
        return <AbortionDataSources />;

      case "abortion-total-abortions":
        return <AbortionTotalAbortions />;

      case "abortion-abortion-services-by-type":
        return <AbortionAbortionServicesByType />;

      case "abortion-pac-services-by-type":
        return <AbortionPacServicesByType />;

      case "abortion-quantities":
        return <AbortionQuantities />;

      case "abortion-costs":
        return <AbortionCosts />;

      case "abortion-future-scenarios":
        return <AbortionFutureScenarios />;

      case "maternal-health-terms":
        return <MaternalHealthTerms />;

      case "maternal-health-scope":
        return <MaternalHealthScope />;

      case "maternal-health-data-sources":
        return <MaternalHealthDataSources />;

      case "maternal-health-pregnancies-and-births":
        return <MaternalHealthBirths />;

      case "maternal-health-cases":
        return <MaternalHealthCases />;

      case "maternal-health-quantities":
        return <MaternalHealthQuantities />;

      case "maternal-health-costs":
        return <MaternalHealthCosts />;

      case "maternal-health-future-scenarios":
        return <MaternalHealthFutureScenarios />;

      default:
        return <Placeholder />;
    }
  };

  return <div className={classes.component}>{getSelectedContent()}</div>;
};

Section.propTypes = {
  section: PropTypes.string.isRequired,
};

export default Section;
