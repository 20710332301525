import { Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import RgApacT2 from "./RgApacT2";

const AbortionQuantities = () => (
  <div>
    <Typography variant="h1">Supply Quantities</Typography>

    <p>
      The quantity is the amount of supplies that will be needed for each
      abortion or PAC service, multiplied by the number of services.
    </p>
    <p>
      For vacuum aspiration services, we based quantities on supplies needed for
      manual vacuum aspiration (MVA). The quantity unit for MVA is an MVA kit,
      which can be used to provide multiple abortion and PAC services. Each kit
      contains one manual aspirator and eight cannulae, and it is assumed that
      on average each aspirator is used 25 times. Thus, each MVA service uses
      0.04 MVA kits, plus two tablets of misoprostol (see Table 2 below). Note
      that in LEAP services provided using electric vacuum aspiration (EVA) are
      counted as if they were provided by MVA; EVA services require misoprostol
      and the same cannulae, but an electric vacuum is used instead of the
      manual aspirator.
    </p>
    <p>
      For medical services, different quantities of misoprostol and mifepristone
      are needed, depending on gestational age, whether or not misoprostol is
      used in combination with mifepristone, and if it is an abortion or PAC
      service. See Table 2 below for estimates of the quantity of misoprostol
      and mifepristone needed; these are based on the average dose per service
      <Footnote number={26}>
        Averages were developed based on{" "}
        <ExternalLink
          href="https://www.who.int/reproductivehealth/publications/medical-management-abortion/en/"
          text="WHO Guidelines for the Medical Management of Abortion"
        />{" "}
        and a review of literature on dosing at different gestational ages.
      </Footnote>
      .
    </p>

    <RgApacT2 />

    <p>
      The quantities presented in this work are different from procurement
      volumes, which may be higher or lower depending on context. Procurement
      needs are typically a function of broader programmatic considerations,
      such as availability (or lack thereof) of resources; the desire to fill
      supply pipelines and maintain adequate inventory levels, or simply the
      volumes of product already in the supply chain.
    </p>
  </div>
);

export default AbortionQuantities;
