import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const useStyles = makeStyles((theme) => ({
  table: {
    "& td": {
      padding: theme.spacing(0, 1),
    },
  },
}));

const MenstrualHygieneTerms = () => {
  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Key Terms and Acronyms</Typography>

      <p>
        <strong>Unit</strong>: The &apos;unit&apos; for menstrual hygiene is a
        menstruator. We use the term 'menstruators' to refer to anyone who
        menstruates, including some, but not all, women, girls, transgender men,
        and non-binary people. In some places we use the term ‘women and girls'
        for readability purposes. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen(
            "menstrual-hygiene-total-menstruators"
          )}
        >
          Total Menstruators
        </Link>{" "}
        below.
      </p>
      <p>
        <strong>Quantities</strong>: The &apos;quantities&apos; for menstrual
        hygiene are &apos;products&apos; which are any clean material used to
        absorb or collect menstrual blood. We divide products into those
        purpose-made for menstruation, and non-purpose-made products.
        Purpose-made products are divided between reusable products (pads and
        cups) and disposable products (pads and tampons). We estimate the
        quantity of products menstruators will use over the course of a year.
        See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("menstrual-hygiene-quantities")}
        >
          Product Quantities
        </Link>{" "}
        below.
      </p>
      <p>
        <strong>Cost</strong>: The &apos;cost&apos; is the price paid by
        menstruators for menstrual hygiene products, which for menstrual hygiene
        largely reflects out-of-pocket expenditures by consumers. In some
        contexts, products may be made freely available, such as distribution of
        pads in schools. However, limited data exist on the source of payment
        for menstrual hygiene products and so we value all products at the
        consumer price, although some might be paid for by a Government or
        donor, who could pay a different price. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("menstrual-hygiene-costs")}
        >
          Product Costs
        </Link>{" "}
        below.
      </p>

      <p>
        <strong>Acronyms</strong>:
      </p>

      <table className={classes.table}>
        <tbody>
          <tr>
            <td>DHS</td>
            <td>Demographic and Health Survey</td>
          </tr>
          <tr>
            <td>IUD</td>
            <td>Intrauterine Device</td>
          </tr>
          <tr>
            <td>IUS</td>
            <td>Intrauterine System</td>
          </tr>
          <tr>
            <td>LEAP</td>
            <td>
              LandscapE and Projection of Reproductive Health Supply Needs
            </td>
          </tr>
          <tr>
            <td>MICS</td>
            <td>Multiple Indicator Cluster Survey</td>
          </tr>
          <tr>
            <td>PMA</td>
            <td>Performance Monitoring for Accountability</td>
          </tr>
          <tr>
            <td>SDG</td>
            <td>Sustainable Development Goal</td>
          </tr>
          <tr>
            <td>UNPD</td>
            <td>United Nations Population Division</td>
          </tr>
          <tr>
            <td>WPP</td>
            <td>World Population Prospects</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MenstrualHygieneTerms;
