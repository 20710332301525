import { Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import RgMhT2 from "./RgMhT2";

const MaternalHealthCases = () => (
  <div>
    <Typography variant="h1">Cases Needing and Receiving Drugs</Typography>

    <p>
      Not all pregnancies or births will need all the drugs included in our
      analysis, while some pregnancies and births will require multiple
      different drugs. In addition, not all pregnancies that need a drug will
      receive it. Therefore, in this report rather than focusing on pregnancies
      or births we focus on number of cases that need and receive a given
      intervention and the associated drug(s), versus cases who need the
      intervention and the drug(s) but do not receive them. This calculation
      reflects the proportion of pregnancies or births which need different
      types of care, as well as the actual coverage of different interventions.
    </p>
    <p>
      Cases requiring each intervention were estimated based on norms or
      guidelines for prenatal and childbirth care, as well as the incidence or
      prevalence of hypertension
      <Footnote number={7}>
        Abalos E, Cuesta C, Carroli G, Qureshi Z, Widmer M, Vogel JP, Souza JP{" "}
        <ExternalLink
          href="https://pubmed.ncbi.nlm.nih.gov/24641531/"
          text="WHO Multicountry Survey on Maternal and Newborn Health Research Network. Pre-eclampsia, eclampsia and adverse maternal and perinatal outcomes: a secondary analysis of the World Health Organization Multicountry Survey on Maternal and Newborn Health"
          useItalics={true}
        />{" "}
        BJOG. 2014 Mar;121 Suppl 1:14-24.
      </Footnote>
      , postpartum hemorrhage
      <Footnote number={8}>
        Calvert C, Thomas SL, Ronsmans C, Wagner KS, Adler AJ, et al. (2012){" "}
        <ExternalLink
          href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0041114"
          text="Identifying Regional Variation in the Prevalence of Postpartum Haemorrhage: A Systematic Review and Meta-Analysis"
          useItalics={true}
        />{" "}
        PLOS ONE 7(7): e41114.
      </Footnote>
      , and maternal infection
      <Footnote number={9}>
        Bonet, M., Brizuela, V., Abalos, E., & Knight, M{" "}
        <ExternalLink
          href="https://www.thelancet.com/journals/langlo/article/PIIS2214-109X(20)30109-1/fulltext"
          text="Frequency and management of maternal infection in health facilities in 52 countries (GLOSS): a 1-week inception cohort study"
          useItalics={true}
        />{" "}
        Lancet Global Health, 8(5), E661–E671.
      </Footnote>
      . The coverage of interventions was aligned to default data used in the{" "}
      <ExternalLink
        href="https://www.livessavedtool.org/"
        text="Lives Saved Tool (LiST)"
      />{" "}
      at baseline and projected to increase as described under Future Scenarios
      below. Depending on the intervention, estimates were linked to the number
      of pregnancies or the number of births (Table 2).
    </p>
    <p>
      Results are presented based on cases for a single intervention, or for
      similar interventions if the same drugs are used to treat them. For
      example, oxytocin could be used for induction, augmentation, as well as
      PPH prevention and treatment. In addition, for some interventions multiple
      drugs could potentially be used to treat the same condition. In this case,
      results are presented by the drug being used; either split between
      multiple drugs or counted for each drug in the case the drugs are used
      together. For some results, results are presented by the group of
      interventions rather than the specific drugs (e.g. hypertension management
      is sometimes presented together showing results for both methyldopa and
      hydralazine).
    </p>

    <RgMhT2 />

    <p>
      For cases where different drugs may be used in different settings for the
      same purpose we distribute the cases across the different options based on
      available data and expert consultation.
    </p>

    <ul>
      <li>
        Antihypertensives: Limited data was available on the distribution of
        antihypertensives used. Based on expert opinion, hydralazine was assumed
        to be used for 30% of acute hypertensive events, while methyldopa would
        be used for 60% of acute events, and 80% of chronic and gestational
        hypertension cases. The remainder would be treated by products outside
        the scope of this analysis.
      </li>
      <li>
        Uterotonics: This analysis assumes that misoprostol would be used for
        PPH prevention for home births
        <Footnote number={10}>
          Data on home vs facility birth was taken from DHS and MICS surveys; a
          total of 100 countries had data available. For the remaining countries
          regional averages were used. For future projections, facility delivery
          was projected to increase to the average of the next highest income
          group, and reach 100% for upper-middle-income countries.
        </Footnote>
        , and a portion of facility births with constraints on using oxytocin
        (e.g. lack of availability of assured cold chain storage), while
        oxytocin would be used for PPH prevention at those facilities where cold
        chain could be maintained and staff were trained to use it. As described
        in the subsequent section on scenarios, we assumed in one scenario that
        heat-stable carbetocin use is scaled up and replaces use of both
        oxytocin and misoprostol for PPH prevention in some settings.
      </li>
    </ul>
  </div>
);

export default MaternalHealthCases;
