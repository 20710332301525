import { makeStyles } from "@material-ui/styles";
import SectionMenuItem from "./SectionMenuItem";

const useStyles = makeStyles((theme) => ({
  component: {
    display: "grid",
    fontSize: "1rem",
    gridAutoFlow: "row",
    gridRowGap: theme.spacing(2),
    whiteSpace: "nowrap",
  },
}));

const SectionMenu = () => {
  const classes = useStyles();

  return (
    <div className={classes.component}>
      <SectionMenuItem linkTo="introduction">Background</SectionMenuItem>
      <SectionMenuItem linkTo="analysis">Analysis Approach</SectionMenuItem>
      <SectionMenuItem linkTo="current">Where We Are Now</SectionMenuItem>
      <SectionMenuItem linkTo="future">Changes Ahead</SectionMenuItem>
      <SectionMenuItem linkTo="conclusion">What&apos;s Next</SectionMenuItem>
    </div>
  );
};

export default SectionMenu;
