import { Link } from "@material-ui/core";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const AbortionScenarios = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <div>
      <p>
        Based on demographic changes, changes in contraceptive use, and changes
        in fertility rates, the overall number of pregnancies and births is
        likely to increase (though not for all countries). In addition, there
        are likely to be increases in a) coverage of the interventions that use
        the priority maternal health drugs and b) the use of emerging drugs.
      </p>

      <p>
        For the analysis of possible future trends presented in this section, we
        developed three scenarios:
      </p>

      <ul>
        <li>
          <strong>Scenario 1: Maintain Coverage.</strong> This scenario accounts
          for changes in the number of pregnancies and births. However, we
          maintain current levels of intervention coverage, by which we mean the
          share of women needing an intervention that uses one of the seven
          maternal health drugs under review who receive the appropriate drug.
        </li>
        <li>
          <strong>Scenario 2: Increase Coverage.</strong> This scenario accounts
          for the same changes in the number of pregnancies and births as in
          Scenario 1 but increases coverage of included interventions to reach
          the coverage in the next highest income group by the final year.
          Upper-middle-income countries were shifted to the highest values
          within that income group. Where coverage in a given country already
          met or exceeded the next income level up, it was held constant.
        </li>
        <li>
          <strong>
            Scenario 3: Increase Coverage and Scale-Up Emerging Drugs.
          </strong>{" "}
          This scenario is the same as Scenario 2 but incorporates scale up of
          heat-stable carbetocin and tranexamic acid following an s-shape curve
          to account for initial slow growth as the drugs are registered and
          introduced in more countries.
        </li>
      </ul>

      <p>
        See the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("maternal-health-future-scenarios")}
        >
          Reader&apos;s Guide
        </Link>{" "}
        for more information about the scenarios.
      </p>
    </div>
  );
};

export default AbortionScenarios;
