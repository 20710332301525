import PropTypes from "prop-types";
import Footnote from "../../../Common/Footnote";
import MhCcF1 from "../Figures/MhCcF1";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatPercentage } from "../../../../utilities";

const calculateMetrics = (data) => {
  const shares = data?.map(({ share }) => share);

  return {
    minimumShare: shares ? Math.min(...shares) : 0,
    maximumShare: shares ? Math.max(...shares) : 0,
  };
};

const MhCcS2 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cc/figures/1/${props.regionalUnitId}/112`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        The WHO recommends that daily oral iron and folic acid supplementation
        should be used in all pregnancies to prevent anemia and birth defects
        <Footnote number={2}>
          World Health Organization. Standards for Maternal and Neonatal Care.
          Group 1: General standards of care for healthy pregnancy and
          childbirth. 2007.
        </Footnote>
        . Figure 1, therefore, shows all pregnant women divided into those who
        received iron and folic acid and those who did not
        <Footnote number={3}>
          Specifically the cases here are all women who carry their pregnancies
          to term.
        </Footnote>
        .
      </p>

      <p>
        Across all low- and middle-income countries, iron and folic acid is
        given in 35% of cases. This ranges from{" "}
        {formatPercentage(metrics.minimumShare)} to{" "}
        {formatPercentage(metrics.maximumShare)} across {props.regionalUnitName}
        .
      </p>

      <MhCcF1 regionalUnitName={props.regionalUnitName} data={data} />
    </>
  );
};

MhCcS2.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcS2;
