import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import theme from "../../theme";

const chapters = [
  {
    id: "overall",
    label: "Overall",
    sections: [
      {
        id: "overview-of-leap",
        label: "Overview of LEAP",
      },
      {
        id: "definitions",
        label: "Definitions",
      },
      {
        id: "leap-resources",
        label: "LEAP Resources",
      },
      {
        id: "income-groups",
        label: "Income Groups",
      },
      {
        id: "data-sources",
        label: "Data Sources",
      },
      {
        id: "timeframe",
        label: "Timeframe",
      },
      {
        id: "future-scenarios",
        label: "Future Scenarios",
      },
      {
        id: "country-groups",
        label: "Country Groups",
      },
      {
        id: "relative-vs-absolute",
        label: "Relative vs Absolute",
      },
      {
        id: "rounding",
        label: "Rounding",
      },
    ],
  },
  {
    id: "contraception",
    label: "Contraception",
    sections: [
      {
        id: "contraception-terms",
        label: "Key Terms and Acronyms",
      },
      {
        id: "contraception-scope",
        label: "Scope",
      },
      {
        id: "contraception-data-sources",
        label: "Data Sources",
      },
      {
        id: "contraception-total-users",
        label: "Total Users",
      },
      {
        id: "contraception-users-by-method",
        label: "Users by Method",
      },
      {
        id: "contraception-users-by-sector",
        label: "Users by Sector",
      },
      {
        id: "contraception-quantities",
        label: "Supply Quantities",
      },
      {
        id: "contraception-costs",
        label: "Supply Costs",
      },
      {
        id: "contraception-future-scenarios",
        label: "Future Scenarios",
      },
    ],
  },
  {
    id: "menstrual-hygiene",
    label: "Menstrual Hygiene",
    sections: [
      {
        id: "menstrual-hygiene-terms",
        label: "Key Terms and Acronyms",
      },
      {
        id: "menstrual-hygiene-scope",
        label: "Scope",
      },
      {
        id: "menstrual-hygiene-data-sources",
        label: "Data Sources",
      },
      {
        id: "menstrual-hygiene-total-menstruators",
        label: "Total Menstruators",
      },
      {
        id: "menstrual-hygiene-menstruators-by-product-type",
        label: "Menstruators by Product Type",
      },
      {
        id: "menstrual-hygiene-quantities",
        label: "Product Quantities",
      },
      {
        id: "menstrual-hygiene-costs",
        label: "Product Costs",
      },
      {
        id: "menstrual-hygiene-future-scenarios",
        label: "Future Scenarios",
      },
    ],
  },
  {
    id: "abortion",
    label: "Abortion and PAC",
    sections: [
      {
        id: "abortion-terms",
        label: "Key Terms and Acronyms",
      },
      {
        id: "abortion-scope",
        label: "Scope",
      },
      {
        id: "abortion-data-sources",
        label: "Data Sources",
      },
      {
        id: "abortion-total-abortions",
        label: "Total Abortions and Miscarriages",
      },
      {
        id: "abortion-abortion-services-by-type",
        label: "Total Abortion Services by Type",
      },
      {
        id: "abortion-pac-services-by-type",
        label: "Total PAC Services by Type",
      },
      {
        id: "abortion-quantities",
        label: "Supply Quantities",
      },
      {
        id: "abortion-costs",
        label: "Supply Costs",
      },
      {
        id: "abortion-future-scenarios",
        label: "Future Scenarios",
      },
    ],
  },
  {
    id: "maternal-health",
    label: "Maternal Health",
    sections: [
      {
        id: "maternal-health-terms",
        label: "Key Terms and Acronyms",
      },
      {
        id: "maternal-health-scope",
        label: "Scope",
      },
      {
        id: "maternal-health-data-sources",
        label: "Data Sources",
      },
      {
        id: "maternal-health-pregnancies-and-births",
        label: "Pregnancies and Births",
      },
      {
        id: "maternal-health-cases",
        label: "Cases Needing and Receiving Drugs",
      },
      {
        id: "maternal-health-quantities",
        label: "Drug Quantities",
      },
      {
        id: "maternal-health-costs",
        label: "Drug Costs",
      },
      {
        id: "maternal-health-future-scenarios",
        label: "Future Scenarios",
      },
    ],
  },
];

const useStyles = makeStyles({
  chapter: {
    boxShadow: "none",
    "&:before": {
      backgroundColor: theme.palette.title.accent,
    },
    "&:first-child": {
      borderTop: `1px solid ${theme.palette.title.accent}`,
    },
    "&.Mui-expanded": {
      border: `1px solid ${theme.palette.title.accent}`,
      borderLeft: "none",
      borderRight: "none",
      margin: 0,
    },
  },
  chapterDetails: {
    padding: 0,
  },
  chapterTitle: {
    borderBottom: `1px solid ${theme.palette.title.accent}`,
    color: theme.palette.title.primary,
    marginBottom: -1,
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0",
    },
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "initial",
    },
  },
  sections: {
    color: theme.palette.title.primary,
    flexGrow: 1,
    padding: 0,
  },
  selected: {
    fontWeight: "bold",
  },
});

const ChapterItem = (props) => {
  const classes = useStyles();

  const handleChange = (section) => (e) => {
    props.onChange?.({
      chapter: props.id,
      section,
    });
  };

  return (
    <Accordion
      square
      expanded={props.expanded}
      className={classes.chapter}
      onChange={props?.onToggle}
    >
      <AccordionSummary className={classes.chapterTitle}>
        <Typography>{props.label}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.chapterDetails}>
        <List className={classes.sections}>
          {props.sections.map((section) => (
            <ListItem
              key={section.id}
              button
              className={
                props.selectedSection === section.id
                  ? classes.selected
                  : undefined
              }
              onClick={handleChange(section.id)}
            >
              {section.label}
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

const TableOfContents = (props) => {
  const [expanded, setExpanded] = useState(props.selectedChapter ?? "");

  useEffect(() => {
    setExpanded(props.selectedChapter);
  }, [props.selectedChapter]);

  const handleExpansion = (panel) => (_, newlyExpanded) => {
    setExpanded(newlyExpanded ? panel : "");
  };

  return (
    <div>
      {chapters.map((chapter) => (
        <ChapterItem
          {...chapter}
          key={chapter.id}
          expanded={expanded === chapter.id}
          selectedSection={props.selectedSection}
          onToggle={handleExpansion(chapter.id)}
          onChange={props.onChange}
        />
      ))}
    </div>
  );
};

TableOfContents.propTypes = {
  selectedChapter: PropTypes.string,
  selectedSection: PropTypes.string,
  onChange: PropTypes.func,
};

export default TableOfContents;
