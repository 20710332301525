import { Link } from "@material-ui/core";
import Information from "../../Common/Box/Information";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const Component = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <Information key="APAC_definitions" number="1" title="Definitions">
      <p>
        <strong>Unit</strong>: The unit of analysis for Abortion and PAC within
        LEAP is a &apos;service&apos;: this could be an abortion service to end
        a pregnancy or a post abortion care (PAC) service to complete an
        incomplete abortion or miscarriage. We have intentionally chosen the
        word &apos;service&apos; as it is a fairly neutral word, as opposed to
        &apos;procedure&apos;, &apos;termination&apos;, or other terms sometimes
        used to describe abortions.
      </p>
      <p>
        &apos;Service&apos; also allows us to account for when one pregnant
        women may receive multiple services, for example when an abortion also
        requires PAC to complete the abortion. While in some places we use the
        term &apos;women and girls&apos; for readability purposes, we recognize
        that some abortion and PAC services may be obtained by transgender men
        or non-binary people.
      </p>
      <p>
        <strong>Cost</strong>: The &apos;cost&apos; is the price of the supplies
        to provide the abortion or PAC service, and not any associated fees
        (e.g. to cover a health provider&apos;s time to deliver a service, or
        provide any pre- or post-service counselling). Costs for surgical
        abortion (vacuum aspiration) are based on the costs of manual vacuum
        aspiration (MVA) kits with aspirator and cannulae plus one dose of
        misoprostol, while costs for medical abortion are based on costs of the
        drugs (misoprostol with or without mifepristone; see the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-costs")}
        >
          Reader&apos;s Guide
        </Link>{" "}
        for more detail). These costs are generally paid by the consumer (i.e.
        the person seeking an abortion), though PAC-related costs may be more
        likely to be covered by public health systems.
      </p>
    </Information>
  );
};

export default Component;
