import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import BoxControls from "../../../Common/Box/BoxControls";
import ScenarioChooser from "../../../Common/Box/Choosers/ScenarioChooser";
import Table from "../../../Common/Box/Table";
import { formatNumber, formatPercentage } from "../../../../utilities";

const key = "APAC_CC_t2";
const number = 2;

const format = (n) =>
  formatNumber(n, {
    significantDigits: 3,
  });

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child td:not(:first-child)": {
      minWidth: "6rem",
    },
  },
}));

const ApacCcT2 = (props) => {
  const classes = useStyles();

  return (
    <Table
      key={key}
      number={number.toString()}
      title="Change in Abortions and PAC Services"
      subtitle={`By ${props.regionalUnitName} and ${props.scenarioName}, 2019-2030`}
      controls={
        <BoxControls>
          <ScenarioChooser
            area="abortion"
            value={props.scenarioId}
            onChange={props.onScenarioChange}
          />
        </BoxControls>
      }
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th colSpan={3}>Total Services</th>
            <th colSpan={2} className={classes.changeGroupBoundary}>
              Change (2019 to 2030)
            </th>
          </tr>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th>2019</th>
            <th>2025</th>
            <th>2030</th>
            <th className={classes.changeGroupBoundary}>#</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {props.data &&
            props.data.map((region) => {
              return (
                <tr key={region.name}>
                  <td className={classes.nameGroupBoundary}>{region.name}</td>

                  {region.years.map((year) => (
                    <td key={year.year}>{format(year.services)}</td>
                  ))}

                  <td className={classes.changeGroupBoundary}>
                    {format(region.change)}
                  </td>
                  <td>{formatPercentage(region.percentageChange)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Table>
  );
};

ApacCcT2.propTypes = {
  regionalUnitName: PropTypes.string.isRequired,
  scenarioId: PropTypes.number.isRequired,
  scenarioName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      years: PropTypes.arrayOf(
        PropTypes.shape({
          year: PropTypes.number.isRequired,
          services: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ),
  onScenarioChange: PropTypes.func,
};

export default ApacCcT2;
