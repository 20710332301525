import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExternalLink from "../Common/ExternalLink";
import Footnote from "../Common/Footnote";
import Landscape from "../Common/Landscape";
import SideBySide from "../Common/SideBySide";
import WithCallouts from "../Common/WithCallouts";
import { useReadersGuide } from "../../hooks/useReadersGuide";
import ApacB1 from "./Boxes/ApacB1";
import ApacB3 from "./Boxes/ApacB3";
import ApacB4 from "./Boxes/ApacB4";
import ApacB5 from "./Boxes/ApacB5";
import ApacB6 from "./Boxes/ApacB6";
import ApacBe from "./Boxes/ApacBe";
import ApacDefinitions from "./Boxes/ApacDefinitions";
import ApacF1 from "./Figures/ApacF1";
import ApacF2 from "./Figures/ApacF2";
import ApacF3 from "./Figures/ApacF3";
import ApacF5 from "./Figures/ApacF5";
import ApacF6 from "./Figures/ApacF6";
import ApacF7 from "./Figures/ApacF7";
import ApacF8 from "./Figures/ApacF8";
import ApacF9 from "./Figures/ApacF9";
import ApacF10 from "./Figures/ApacF10";
import ApacMethods from "./Boxes/ApacMethods";
import ApacT1 from "./Tables/ApacT1";
import ApacT2 from "./Tables/ApacT2";
import ApacT3 from "./Tables/ApacT3";
import ApacT4 from "./Tables/ApacT4";
import ApacT7 from "./Tables/ApacT7";
import ApacT9 from "./Tables/ApacT9";

const useStyles = makeStyles((theme) => ({
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
  sectionTitle: {
    marginTop: 0,
    paddingTop: "3rem",
  },
}));

const Abortion = () => {
  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <Landscape>
      <section id="introduction">
        <Typography variant="h1" className={classes.sectionTitle}>
          Background
        </Typography>

        <div className={classes.boxes}>
          <WithCallouts
            area="Abortion and PAC"
            landscapeDescription="Landscape for a single region"
            comparisonDescription="Compare across regions"
            deepDiveDescription="Deep dive into one service"
            videoUrl="https://youtu.be/oVaFCvBafpI"
          >
            <p>
              Abortion and Post-Abortion Care (PAC) services are an important
              component of the sexual and reproductive health and rights for
              women and girls globally. All people have the right to make
              decisions about their own bodies and health, including if, when
              and with whom to have children, and safe abortion and PAC services
              are essential to achieve this right.
            </p>
            <p>
              Nearly half of all pregnancies in low- and middle-income countries
              are unintended
              <Footnote number={1}>
                Sully EA, Biddlecom A, Darroch JE, Riley T, Ashford LS,
                Lince-Deroche N et al{" "}
                <ExternalLink
                  href="https://www.guttmacher.org/report/adding-it-up-investing-in-sexual-reproductive-health-2019"
                  text="Adding It Up: Investing in Sexual and Reproductive Health 2019"
                  useItalics={true}
                />{" "}
                New York: Guttmacher Institute, 2020.
              </Footnote>
              , with a substantial share of these pregnancies ending in
              abortion. Currently in low-income countries 40% of unintended
              pregnancies are estimated to end in abortion; in
              lower-middle-income countries the figure is 64% and in
              upper-middle-income countries 67%
              <Footnote number={2}>
                Bearak J, Popinchalk A, Ganatra B, et al{" "}
                <ExternalLink
                  href="http://dx.doi.org/10.1016/S2214-109X(20)30315-6"
                  text="Unintended pregnancy and abortion by income, region, and the legal status of abortion: estimates from a comprehensive model for 1990–2019"
                  useItalics={true}
                />{" "}
                Lancet Glob Health 2020; published online July 22.
              </Footnote>
              ; access to abortion services also varies greatly by country (see
              below).
            </p>
          </WithCallouts>

          <ApacDefinitions />

          <p>
            Even if there was global access to uninterrupted, affordable and
            effective contraception, there would still be a need for abortion
            due to method failure, unprotected sex (consensual and
            non-consensual) and medical reasons. PAC services would also still
            be required, as PAC may be required following an incomplete induced
            abortion or a miscarriage.
          </p>

          <p>
            Laws and policies regulating abortion and PAC services vary
            immensely across and within countries. Abortion services may be
            allowed on request, for broad social or economic reasons, to
            preserve health, only to save the pregnant person&apos;s life, or it
            may be prohibited altogether
            <Footnote number={3}>
              <ExternalLink
                href="https://maps.reproductiverights.org/worldabortionlaws"
                text="Centre for Reproductive Rights The World's Abortion Laws"
              />
            </Footnote>
            . The continued stigma around the need for, and provision of,
            abortion services has multiple adverse consequences. These include
            harming the physical and mental health of those with an unwanted
            pregnancy, provider and service reluctance to provide abortion and
            PAC services to the full extent allowed by national laws and
            policies, and continued violation of human rights by denying or
            severely restricting access to safe and legal abortion services
            <Footnote number={4}>
              LeTourneau, K. (2016){" "}
              <ExternalLink
                href="https://inroads-cdn.s3.amazonaws.com/files/inroads-technical-report-ENG-LR.pdf"
                text="Abortion stigma around the world: A synthesis of the qualitative literature. A technical report for members of The International Network for the Reduction of Abortion Discrimination and Stigma (inroads)"
                useItalics={true}
              />{" "}
              Chapel Hill, NC: inroads.
            </Footnote>
            <Footnote number={5}>
              Latt SM, Milner A, Kavangah A{" "}
              <ExternalLink
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6321671/pdf/12905_2018_Article_705.pdf"
                text="Abortion laws reform may reduce maternal mortality: an ecological study in 162 countries"
                useItalics={true}
              />{" "}
              BMC Women’s Health 2019: 19;1.
            </Footnote>
            .
          </p>

          <p>
            The variation in access to abortion access, as well as the stigma of
            these services, has created great variability in the safety of
            abortion services. The World Health Organization (WHO) uses the
            following classification of abortion safety
            <Footnote number={6}>
              <ExternalLink
                href="https://www.who.int/news-room/fact-sheets/detail/preventing-unsafe-abortion"
                text="World Health Organization Prevening Unsafe Abortion"
              />
            </Footnote>
            :
          </p>

          <SideBySide component={<ApacB1 />}>
            <ul>
              <li>
                <strong>&apos;Safe abortions&apos;</strong> are abortions that
                are both provided by an appropriately trained person and carried
                out using a surgical or medical method recommended by WHO
                appropriate to the pregnancy gestation.
              </li>
              <li>
                <strong>&apos;Less safe abortions&apos;</strong> meet only one
                of these two criteria e.g. abortions provided by a trained
                health worker using an outdated method, or if a person
                self-induces an abortion using a safe method (e.g. misoprostol)
                without adequate information or support from a trained
                individual.
              </li>
              <li>
                <strong>&apos;Least safe abortions&apos;</strong> meet neither
                of the two criteria; they are provided by untrained people using
                dangerous methods, such as toxic substances or insertion of
                sharp objects.
              </li>
            </ul>
          </SideBySide>

          <p>
            Just 22% of abortion services in low-income countries are safe,
            compared to 67% in upper-middle-income countries
            <Footnote number={7}>
              Ganatra B, Gerdts C, Rossier C, Johnson Jnr BR, Tunçalp Ö, Assifi
              A et al{" "}
              <ExternalLink
                href="https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(17)31794-4/fulltext#tables"
                text="Global, regional, and subregional classification of abortions by safety, 2010-14: estimates from a Bayesian hierarchical model"
                useItalics={true}
              />{" "}
              Lancet. 2017 Nov 25;390(10110):2372-23.
            </Footnote>
            . Overall, less and least safe abortions are estimated to cause
            23,000 maternal deaths in low- and middle-income countries annually
            <Footnote number={8}>
              Sully EA, Biddlecom A, Darroch JE, Riley T, Ashford LS,
              Lince-Deroche N et al{" "}
              <ExternalLink
                href="https://www.guttmacher.org/report/adding-it-up-investing-in-sexual-reproductive-health-2019"
                text="Adding It Up: Investing in Sexual and Reproductive Health 2019"
                useItalics={true}
              />{" "}
              New York: Guttmacher Institute, 2020
            </Footnote>
            .
          </p>

          <p>
            To provide safe and less safe abortion services, as well as PAC
            services, either medical or surgical methods can be used (see Box
            2). Medical methods include use of misoprostol, alone or in
            combination with mifepristone. Surgical methods include vacuum
            aspiration or dilation methods. For the purpose of LEAP, we have
            only costed safe abortion, less safe abortion and PAC services using
            surgical aspiration methods or medical methods. We have not costed
            least safe abortion services or services provided using surgical
            dilation methods. (see Box 2 and the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("abortion-scope")}
            >
              Reader&apos;s Guide
            </Link>
            ).
          </p>

          <p>
            The &apos;safe&apos; and &apos;less safe&apos; abortion safety
            classifications from WHO are based on use of an appropriate abortion
            method and medication dosage for pregnancy gestation. They do not,
            however, consider the quality of the supplies used themselves, which
            also affects safety and efficacy of abortion and PAC services. Just
            seven misoprostol and/or mifepristone products currently hold
            pre-qualification from WHO
            <Footnote number={9}>
              <ExternalLink
                href="https://extranet.who.int/pqweb/medicines/prequalified-lists/finished-pharmaceutical-products"
                text="WHO Prequalified List of Medicines/Finished Pharmaceutical Products"
                useItalics={true}
              />{" "}
              Accessed 16th July 2021.
            </Footnote>
            , and the quality of many medical products available in low- and
            middle-income countries is not assured (by WHO or stringent
            regulatory authority (SRA) recognized by WHO). The quality of these
            non-assured products has been shown to vary greatly
            <Footnote number={10}>
              Clark H, RamoRao S, Townsend J{" "}
              <ExternalLink
                href="https://www.rhsupplies.org/uploads/tx_rhscpublications/Safe_Abortion_Supplies_Landscaping_Report.pdf"
                text="Ensuring Access to Safe Abortion Supplies: Landscaping of barriers and Opportunities"
                useItalics={true}
              />{" "}
              2017: Population Council.
            </Footnote>
            . There are also concerns around the durability of manual vacuum
            aspiration (MVA) products used for surgical abortions and PAC
            services
            <Footnote number={11}>
              Clark H, RamoRao S, Townsend J{" "}
              <ExternalLink
                href="https://www.rhsupplies.org/uploads/tx_rhscpublications/Safe_Abortion_Supplies_Landscaping_Report.pdf"
                text="Ensuring Access to Safe Abortion Supplies: Landscaping of barriers and Opportunities"
              />{" "}
              2017: Population Council.
            </Footnote>
            . If poor quality supplies are used, services may be less safe and
            effective than their categorization of &apos;safe&apos; or
            &apos;less safe&apos; may indicate.
          </p>

          <p>
            Much of the existing data and research on abortion focuses on its
            prevalence, safety and/or legality
            <Footnote number={12}>
              Singh S, Remez L, Sedgh G, Kwok L, Onda T.{" "}
              <ExternalLink
                href="https://www.guttmacher.org/sites/default/files/report_pdf/abortion-worldwide-2017.pdf"
                text="Abortion Worldwide 2017: Uneven Progress and Unequal Access"
              />{" "}
              Guttmacher Institute. 2018.
            </Footnote>
            . This analysis focuses on the supplies needed to provide the main
            abortion and PAC methods (both surgical and medical). It considers
            the associated costs of these supplies, what services are currently
            needed, and estimates what the need for them may be in the future.
            The analysis also explores the implications of increased
            availability and use of medical methods, especially the increased
            use of mifepristone in combination with misoprostol.
          </p>

          <ApacMethods />
        </div>
      </section>

      <section id="analysis">
        <Typography variant="h1" className={classes.sectionTitle}>
          Analysis Approach
        </Typography>

        <p>
          Building on the approach developed and refined for the 2019 family
          planning Commodity Gap Analysis
          <Footnote
            number={15}
            href="https://www.rhsupplies.org/activities-resources/commodity-gap-analysis/"
          />
          , this report draws on a range of data sources to estimate for all
          low-and middle-income countries:
        </p>

        <ul>
          <li>The number of abortion and PAC services;</li>
          <li>
            The cost of the main supplies required to provide these services
            (see the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("abortion-costs")}
            >
              Reader&apos;s Guide
            </Link>{" "}
            for more detail); and
          </li>
          <li>
            Projections for how the number and costs of abortion and PAC
            services might change from 2019 to 2030. We used 2019 as our
            &apos;current&apos; year because 2020 was atypical due to the COVID
            pandemic.
          </li>
        </ul>

        <p>
          Our analysis focuses on supplies needed to carry out safe and less
          safe abortions, as well as to provide PAC (see Box 2). Our results
          focus on costing the supplies required to provide three services:{" "}
        </p>

        <ul>
          <li>Vacuum aspiration, using a manual or electric vacuum;</li>
          <li>Medical abortion or PAC using misoprostol on its own; and</li>
          <li>
            Medical abortion using misoprostol in combination with mifepristone
            (packaged separately or packaged together in combipacks).
          </li>
        </ul>

        <p>
          The costs presented in LEAP reflect the supplies used by individuals
          in a given year, not what is necessarily bought or
          &apos;procured&apos; in that year, as the latter, often referred to as
          &apos;procurement costs&apos;, are typically a function of broader
          programmatic considerations, such as availability (or lack thereof) of
          resources; the desire to fill supply pipelines and maintain adequate
          inventory levels, or simply the volumes of product already in the
          supply chain.
        </p>

        <p>
          Our analysis focused on 129 low- and middle-income countries (see the{" "}
          <Link
            underline="always"
            onClick={handleReadersGuideOpen("income-groups")}
          >
            Reader&apos;s Guide
          </Link>
          ) with the main unit of analysis being services (see Box 1). Results
          are disaggregated by country-level income group. Country-specific
          results are not included as most existing data is at regional rather
          than country level. For more information about the analysis, including
          data sources and the development of the scenarios for future
          projections, see the{" "}
          <Link
            underline="always"
            onClick={handleReadersGuideOpen("abortion-scope")}
          >
            Reader&apos;s Guide
          </Link>
          .
        </p>
      </section>

      <section id="current">
        <Typography variant="h1" className={classes.sectionTitle}>
          Where We Are Now
        </Typography>

        <div className={classes.boxes}>
          <Typography variant="h2">
            Current Number of Abortion and PAC Services in Low- and
            Middle-Income Countries
          </Typography>

          <SideBySide component={<ApacT1 />} componentPosition="right">
            <p>
              In 2019 there were an estimated 72.5 million abortion and PAC
              services across low- and middle-income countries annually. This
              includes all methods and safety levels of these services. The
              distribution of abortion and PAC services across income groups is
              broadly in line with the proportion of women of reproductive age
              in each income group (data not shown).
            </p>
            <p>
              Of the 72.5 million abortion and PAC services, the majority (81%)
              are abortion services. However this varies somewhat by income
              group, with PAC accounting for 24% of services in low-income
              countries compared to 18% in upper-middle-income countries. This
              may be attributed to the fact that in regions where there are
              fewer safe abortions, more PAC services are required. Furthermore,
              our data only include PAC services provided; it is likely that
              many more people require these services, but do not actually
              receive them, especially in regions where a high proportion of
              abortions are less and least safe.
            </p>
          </SideBySide>

          <SideBySide component={<ApacF1 />}>
            For the remainder of the report, we do not distinguish between
            services for abortion and services for PAC; rather, we present
            results for abortion and PAC services combined. We have focused on
            the methods that have been included in our costing (surgical methods
            provided via vacuum aspiration and medical methods using misoprostol
            with and without mifepristone; see Box 2). While we have not costed
            least safe abortion and surgical dilation services (see Box 2),
            where relevant we have included estimated numbers of these services.
          </SideBySide>

          <SideBySide component={<ApacT2 />} componentPosition="right">
            Various methods are used for abortion and PAC services (see Box 2).
            Medical methods make up the largest share, accounting for 43% of
            services (31.1 million). This is followed by surgical methods using
            vacuum aspiration, which accounts for just over one-third of
            services (35%, 25.1 million). The remaining services (22%, 16.3
            million) are least safe abortion services (13%, 9.3 million) and
            services provided using surgical dilation (10%, 7.0 million).
          </SideBySide>

          <SideBySide component={<ApacF2 />}>
            <p>
              The methods used for abortion and PAC services vary across income
              groups. The proportion of services that are least safe abortion
              services decline as income rises; 30% of services in low-income
              countries are least safe compared to just 5% in
              upper-middle-income countries.
            </p>
            <p>
              In contrast, surgical aspiration methods account for a similar
              share of services in low-income (29%) and lower-middle-income
              (31%) countries, and a slightly higher share in
              upper-middle-income countries (40%). Medical methods account for
              one-third of services in low-income countries and 44% of services
              in both lower-middle- and upper-middle-income countries. In
              low-income countries, the majority of medical services use
              misoprostol only (87%); misoprostol used in combination with
              mifepristone accounts for a larger share in both lower-middle- and
              upper-middle-income countries (54% and 48% respectively).
            </p>
          </SideBySide>

          <Typography variant="h2">
            Current Costs of Abortion and PAC Supplies in Low- and Middle-Income
            Countries
          </Typography>

          <p>
            We estimated the cost of the supplies needed to provide abortion and
            PAC services using surgical methods provided via vacuum aspiration
            and medical methods using misoprostol with and without mifepristone.
            For surgical services provided using vacuum aspiration, these costs
            only account for a small share of the total cost since a skilled
            provider and clean clinical space are also needed to provide these
            services. The costs for vacuum aspiration includes the cost of an
            MVA kit as well as the cost of two misoprostol pills. For medical
            services different assumptions are used about the number of
            misoprostol and mifepristone pills needed based on the type of
            service (abortion versus PAC) and gestational age. See the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("abortion-quantities")}
            >
              Reader&apos;s Guide
            </Link>{" "}
            for more details.
          </p>

          <p>
            Limited data exist to capture regional price differences for MVA
            kits. Therefore, a global price of $16.50 was used based on the
            price of the IPAS MVA kit along with an assumption that each kit can
            be used 25 times, resulting in a per-service cost of $0.66. For
            misoprostol and mifepristone, however, more price data are available
            and show large variation across regions. We used price data from the
            Medical Abortion Commodities Database, including additional country
            data that was shared for use in this analysis.
          </p>

          <p>
            The cost of misoprostol pills varies widely. The lowest prices are
            found in Asia at just $0.16 per pill, in contrast to Latin America
            and the Caribbean where a single misoprostol pill costs more than 16
            times this ($2.59). Similar variation in cost is found for
            mifepristone; with prices nearly six times higher in Latin America
            and the Caribbean ($24.47) than in Asia ($4.20). Combipacks
            combining misoprostol and mifepristone are generally cheaper, pill
            for pill, than buying misoprostol and mifepristone individually, but
            are not as widely available.
          </p>

          <ApacT3 />

          <ApacB3 />

          <p>
            The total cost of supplies for surgical services via aspiration and
            medical services using misoprostol with and without mifepristone is
            estimated as $226 million annually. Upper-middle-income countries
            account for 58% of these costs (even though they only account for
            41% of services) due to the high proportion of, and higher cost of,
            medical methods in this income group.
          </p>

          <p>
            Across all income groups, medical methods account for 90% or more of
            the total supply costs. This is because the supply cost of the
            aspirator and cannulae to provide surgical services via aspiration
            has a very low cost per service; this does not include associated
            labor costs or costs of having a clean clinical space to provide the
            surgical service.
          </p>

          <ApacT4 />

          <SideBySide component={<ApacF3 />} componentPosition="right">
            Within medical methods, misoprostol currently dominates the total
            cost within all income groups, and accounts for 63% of the total
            cost of these methods. In low-income countries misoprostol accounts
            for 86% of the cost of medical methods due to the large share of
            abortion services that use only misoprostol. This is also due to the
            greater need for PAC services in low-income countries because of the
            high rates of least safe abortions; PAC services, when provided
            using medical methods, use misoprostol only. Further, for abortion
            services using both misoprostol and mifepristone, four to six
            misoprostol pills are needed (depending on gestation). Therefore
            even in locations where mifepristone is relatively cheap,
            misoprostol still accounts for a substantial share of the cost of
            these services. This cost split may change, however, as countries
            continue to register mifepristone for abortions
            <Footnote number={19}>
              <ExternalLink
                href="https://gynuity.org/resources/list-of-mifepristone-approvals"
                text="List of Mifepristone Approvals"
                useItalics={true}
              />{" "}
              (updated June 2020). Gynuity Health Projects.
            </Footnote>
            , and if the cost of misoprostol is reduced (see Box 4). These
            changes are explored further in our future scenarios below.
          </SideBySide>

          <ApacB4 />
        </div>
      </section>

      <section id="future" className={classes.sectionTitle}>
        <Typography variant="h1">Changes Ahead</Typography>

        <div className={classes.boxes}>
          <p>
            Looking ahead, based on demographic changes and changes in
            contraceptive use (see Box 5), the overall number of abortion and
            PAC services is expected to increase, although not evenly, or in all
            countries (see Table 5). In addition, there are likely to be shifts
            in the safety profile of services (i.e. proportion of abortion
            services that are safe, less safe and least safe), and increased use
            of medical methods, particularly the use of mifepristone in
            combination with misoprostol.
          </p>

          <ApacB5 />

          <p>
            For the analysis of possible future trends presented in this
            section, we developed three scenarios, which are described more
            fully in Box 6:
          </p>

          <ul>
            <li>Scenario 1: Maintain safety profile and method mix </li>
            <li>Scenario 2: Shift safety profile</li>
            <li>Scenario 3: Shift safety profile and method mix</li>
          </ul>

          <p>
            In all three scenarios the number of abortions change in accordance
            with the projected changes in population and contraceptive use.
            However the safety profile of the abortion services and the methods
            used vary, as do the resulting number of PAC services.
          </p>

          <p>
            The increase in the proportion of services that are safe in
            Scenarios 2 and 3 is based on the current average safety profile
            (i.e. proportion of abortion services that are safe, less safe and
            least safe) of countries within the next higher income group.
            Scenario 3 also includes an increase in the proportion of abortion
            services using medical methods; these are based on regional-specific
            shifts to increase the overall share of medical methods to at least
            50% of all services and/or increasing the proportion of medical
            services that use both misoprostol and mifepristone (see Box 6 for
            more detail).
          </p>

          <p>
            These scenarios are not intended to be predictive but show what
            would happen if these changes were achieved. The further into the
            future our projections run, the less certainty we have; this
            uncertainty is represented in the figures with a dotted line.
          </p>

          <ApacB6 />

          <Typography variant="h2">
            Future Number of Abortion and PAC Services in Low- and Middle-Income
            Countries
          </Typography>

          <p>
            The total number of abortion services increases by the same
            magnitude in all three scenarios; what differs is the change in PAC
            services based on the shifts in safety profile and method mix.
            However, the general pattern of change in total abortion and PAC
            services is similar in all three scenarios.
          </p>

          <p>
            The biggest increase in the number of services would be seen in
            low-income countries, where annual services would increase by 19-24%
            (+1.4 to +1.79 million) from 2019 to 2030, driven mostly by
            increases in the number of women of reproductive age in these
            countries. Lower-middle-income countries would see a more modest
            increase in annual services of 3-7% (+958,000 to +2.59 million) from
            2019 to 2030, corresponding to a more modest increase in the number
            of women of reproductive age. In upper-middle-income countries, the
            total number of services provided annually would actually decrease
            by 8-11% (-2.41 to -3.25 million), driven primarily by the expected
            decline in the number of women of reproductive age in these
            countries.
          </p>

          <ApacT9 />

          <p>
            The profile of services would change even more substantially by 2030
            if there is a shift in the safety profile of services (Scenario 2),
            and if this shift in safety profile is combined with increased use
            of medical methods (Scenario 3).{" "}
          </p>

          <p>
            The proportion of least safe abortion services would drop
            dramatically in all income groups, most notably in low-income
            countries where by 2030 they would account for 14% of all services
            compared to 30% currently. In lower-middle-income countries least
            safe abortions would account for 4% of all services by 2030
            (compared to 16% currently) and in upper-middle-income countries
            they would account for 0.4% of all services (compared to 6%
            currently).
          </p>

          <p>
            The proportion of abortion and PAC services using medical methods,
            and the types of medical methods used, would also change
            substantially in low-income countries by 2030. If the safety profile
            and method mix are maintained (Scenario 1) medical methods would
            account for 33% of all services by 2030 in low-income countries.
            This would increase to 40% with a shift in safety profile (Scenario
            2), and to 44% with a shift in safety profile and method mix
            (Scenario 3). The share of services that use misoprostol and
            mifepristone combined in low-income countries would increase
            dramatically from 4% in Scenario 1 to 28% in Scenario 3.
          </p>

          <p>
            Conversely, in lower-middle-income countries 44% of services would
            be provided using medical methods by 2030 if the safety profile and
            method mix are maintained (Scenario 1). This would remain
            essentially the same (45%) under Scenario 2 and increase to 51%
            under Scenario 3.
          </p>

          <ApacF5 />

          <p>
            Different trends in the total number of costed services are seen in
            each income group between 2019 and 2030 (Figure 6). Overall the
            direction of change of costed services matched the direction of
            change for all abortion and PAC services (Table 5).
          </p>

          <p>
            In low-income countries, costed services would grow by 25% (from 4.6
            million in 2019 to 5.7 million in 2030, +1.1 million) even without
            any changes to safety profile or method mix (Scenario 1). The change
            would be even greater (+2.2 million, +48%) if there was a shift in
            safety profile (Scenario 2) and greater still (+2.4 million, +52%)
            with shifts in the safety profile and method mix.
          </p>

          <p>
            Lower-middle-income countries would see slower growth in costed
            services during this timeframe. Just on the basis of changes in
            demography, contraceptive uptake and method mix (Scenario 1) there
            would 28.3 million costed services by 2030, an increase of 7% (+1.8
            million) from 2019. The growth in services would be greater (+4.8
            million, +18%) if there was a shift in safety profile (Scenario 2) ;
            and slightly greater (+5.2 million, +20%) with shifts in the safety
            profile and method mix (Scenario 3).
          </p>

          <p>
            Upper-middle-income countries under Scenario 1 would see fewer
            costed services by 2030 ―from 25.1 million in 2019 to 23.0 million
            in 2030, a decrease of 8% (-2.1 million). The decrease would be
            somewhat less under Scenario 2 (-1.2 million or -5%) and Scenario 3
            (-909,000 or -4%).
          </p>

          <ApacF6 />

          <p>
            Between 2019 and 2030, different trends in costed abortion and PAC
            services are also seen by method (Figure 7).
          </p>

          <p>
            With no changes in the safety profile or method mix (Scenario 1),
            the number of surgical aspiration services would remain almost
            unchanged (+267 thousand, +1%). A more substantial increase in
            surgical aspiration services would be seen if there was a shift in
            the safety profile, but the method mix did not change (Scenario 2)
            (+5.4 million, +22%). This is because a higher proportion of safe
            abortion services use surgical methods; as the proportion of safe
            abortions increases, this results in a much larger number of
            surgical services provided. However, more than half of the increase
            in surgical services would be offset if the method mix also shifted
            towards medical methods using mifepristone (Scenario 3, +2.3
            million, 9%).
          </p>

          <p>
            There would be a small increase in the number of medical abortion
            and PAC services between 2019 and 2030 under Scenarios 1 and 2
            (+496,000 and +312,000 respectively). However, introducing a shift
            in the method mix towards medical methods, as well as a shift in
            safety profile (Scenario 3), would lead to an increase of 4.4
            million medical abortion and PAC services by 2030 (+14%).
          </p>

          <p>
            Within medical methods, changes in the number of misoprostol-only or
            misoprostol and mifepristone combined services would be minimal
            under either Scenario 1 or 2 between 2019 and 2030. However, with a
            shift in safety profile and method mix (Scenario 3) misoprostol-only
            services would decline by 54% (-8.6 million) while more effective
            combined misoprostol and mifepristone services would increase by 86%
            (+12.9 million).
          </p>

          <ApacF7 />

          <Typography variant="h2">
            Future Costs of Abortion and PAC Supplies in Low- and Middle-Income
            Countries
          </Typography>

          <SideBySide component={<ApacF8 />}>
            <p>
              We projected the change in cost from 2019-2030 for three services:
              surgical services provided via vacuum aspiration, misoprostol-only
              medical abortion and PAC services, and medical abortion using both
              misoprostol and mifepristone. Surgical services provided via
              dilation methods and least safe abortion services were not costed
              (see Box 2). As discussed earlier, our cost estimates only include
              the cost of supplies and not the costs of labor or provision of a
              clean clinical environment. Note that all costs are based on
              current supply costs, held constant for all years through to 2030.
            </p>
            <p>
              Under Scenario 1 of maintaining safety profile and method mix, the
              total overall cost of abortion and PAC supplies would remain
              relatively constant, increasing only by 0.4% from 2019 to 2030
              (+$808,000). With a shift in the safety profile of services
              (Scenario 2), total overall cost would grow by 8% from $226
              million in 2019 to $244 million by 2030 (+$17.8 million).
            </p>
          </SideBySide>

          <p>
            If the shift in safety is combined with a shift in method mix
            (Scenario 3), total cost would increase substantially; reaching $357
            million by 2030, an increase of $131 million from 2019 (+58%). The
            large difference in total cost between Scenario 2 and Scenario 3 by
            2030 (difference of +$113 million) is driven by the increased use of
            combined misoprostol and mifepristone, and the current high price of
            mifepristone, which makes the total supply cost of a medical
            abortion much higher than the use of misoprostol alone (see Table 3
            and the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("abortion-quantities")}
            >
              Reader&apos;s Guide
            </Link>
            ). As demand for mifepristone grows and as manufacturers secure
            regulatory authority in more and more countries and see the
            prospects for greater return on investment, there is every reason to
            believe that greater competition will drive down the price of
            mifepristone over time. And that, coupled with its anticipated
            growth in use, should give way to a substantial reduction in the
            total abortion and PAC supply cost by 2030 (see Box 6).
          </p>

          <ApacF9 />

          <ApacT7 />

          <p>
            The main change in total cost of abortion and PAC supplies occurs
            under Scenario 3, where the shift in safety profile and method mix
            would lead to greater use of medical methods, particularly the
            combined use of misoprostol with mifepristone. This large change in
            cost in Scenario 3 would be driven by the increased use, and current
            high cost of mifepristone (see Table 3 and Box 7). The magnitude of
            the cost increase, however, varies by income group. For low-income
            countries, total cost under Scenario 3 would more than double
            between 2019 and 2030 (+222%, +$25.2 million). Lower-middle- and
            upper-middle-income countries would see a greater absolute rise in
            total cost (+$50.5 million and +$55.2 million respectively), but
            this would be a smaller proportional increase in cost (+168% and
            +142% respectively) compared to low-income countries.
          </p>

          <p>
            In all three Scenarios, by 2030 upper-middle-income countries would
            continue to account for just over half of total overall costs even
            though their share of total services would decrease. In 2030 these
            countries would account for 55% of the total costs in Scenario 1 and
            52% of the total costs in Scenario 2 and 3. This is somewhat
            disproportionate to the share of costed services occurring in
            upper-middle-income countries (40% under Scenario 1 and 38-39% in
            Scenarios 2 and 3).
          </p>

          <ApacF10 />

          <p>
            As might be expected, changes in total costs vary by the method used
            for abortion and PAC services. The cost of supplies for surgical
            services provided via vacuum aspiration would grow fairly modestly
            overall. Irrespective of income group or Scenario, there is limited
            projected change in uptake of this method between 2019 and 2030.
            There would be, however, a large increase in the total cost of
            medical supplies, particularly in Scenario 3, where the total cost
            would reach $339 million by 2030, an increase of $129 million (62%)
            compared to 2019. As noted previously, this is driven by the
            increased use, and associated current high cost of, mifepristone. It
            is hoped that as production and consumption of mifepristone
            increases; and the use of combipacks grows (which offers a lower
            price, pill for pill, for both mifepristone and misoprostol; see Box
            6) we will ultimately see significant reductions in the costs of
            medical method supplies (Box 7).
          </p>

          <ApacBe />
        </div>
      </section>

      <section id="conclusion">
        <Typography variant="h1" className={classes.sectionTitle}>
          What&apos;s Next
        </Typography>

        <p>
          This report is the first comprehensive assessment of current and
          future use and cost of abortion and PAC supplies in low- and
          middle-income countries. Currently abortion and PAC services are
          provided using a variety of methods, with safety of these services
          varying greatly by income group. Medical methods account for the
          largest share of costed services in all income groups. There is,
          however, wide variation in the cost of the supplies for these methods
          because of differences in the price of misoprostol and mifepristone
          pills across countries and regions.
        </p>

        <p>
          By 2030 the overall number of abortion and PAC services is likely to
          increase in low-income and lower-middle-income countries, and decrease
          in upper-middle-income countries.
        </p>

        <p>
          We expect the need for future abortion and PAC services to be shaped
          by demographic changes and changes in contraceptive use, as well as
          by:
        </p>

        <ul>
          <li>
            A shift in the safety profile of abortion services, including a
            decrease in the provision of &apos;least safe&apos; abortion
            services (services that are provided by an unskilled person using
            methods that are not approved by WHO). The increased safety of
            services will result in a corresponding decrease in the need for PAC
            services;
          </li>
          <li>
            Increases in abortion services using medical rather than surgical
            methods, and;
          </li>
          <li>
            Increases in the use of medical abortions using the more effective
            combination of misoprostol and mifepristone, as opposed to
            misoprostol-only services.
          </li>
        </ul>

        <p>
          The increased use of mifepristone has significant cost implications
          for the delivery of safe and effective abortion services. At
          today&apos;s prices, it is the single most expensive component of
          medical abortion. But the market for the product is still very much in
          its infancy. The first product to receive WHO pre-qualification did so
          only a couple years ago. Relatively few countries include mifepristone
          in their list of essential medicines, and production volumes remain
          low. It is easy to apply today&apos;s prices to growth projections a
          decade away, and view that as a looming bottleneck to growth and
          access. But it is also just as likely that increased demand will give
          way to increased competition and ultimately lower prices.
        </p>

        <p>
          LEAP is cautious in projecting too far into the future. But in the
          near term, there are steps that might be taken to increase access and
          affordability for abortion and PAC services. These include
          encouraging:
        </p>

        <ul>
          <li>Greater public sector provision of abortion and PAC services;</li>
          <li>
            Inclusion of mifepristone in national essential medicine lists; and
          </li>
          <li>Regulatory approval of mifepristone by countries.</li>
        </ul>

        <p>
          Expanding the availability of medical methods also allows for
          provision of services by mid-level providers other that physicians, as
          well as at different types of outlets, which may further increase
          access to and affordability of abortion and PAC services. Alternative
          payment models, where public health systems or insurance schemes,
          rather than individuals, bear a larger share of the supply cost could
          also be explored.
        </p>

        <p>
          These options could still allow for a competitive and profitable
          supplies market, particularly as the number of services is expected to
          continue to grow in low- and lower-middle income countries, while at
          the same time ensuring the more effective method of medical abortion
          of combined misoprostol and mifepristone remain affordable to those
          requiring these services.
        </p>

        <p>
          In aiming for affordable abortion and PAC services, quality must also
          be assured. There is currently a large variation in the quality of
          supplies, and quality assured products have been shown on average to
          have a higher price than non-quality assured products{" "}
          <Footnote number={22}>
            <ExternalLink
              href="https://www.medab.org/our-research#cost-of-medical-abortion-products"
              text="IPPF Medical Abortion Commodities Database"
            />
          </Footnote>
          . Furthermore, three of the seven products with pre-qualification from
          WHO are from the same manufacturer
          <Footnote number={23}>
            <ExternalLink
              href="https://extranet.who.int/pqweb/medicines/prequalified-lists/finished-pharmaceutical-products"
              text="WHO Prequalified List of Medicines/Finished Pharmaceutical Products"
              useItalics={true}
            />{" "}
            Accessed 16th July 2021.
          </Footnote>
          , which may be limiting competition and driving higher prices of
          pre-qualified products
          <Footnote number={24}>
            Clark H, RamoRao S, Townsend J{" "}
            <ExternalLink
              href="https://www.rhsupplies.org/uploads/tx_rhscpublications/Safe_Abortion_Supplies_Landscaping_Report.pdf"
              text="Ensuring Access to Safe Abortion Supplies: Landscaping of barriers and Opportunities"
              useItalics={true}
            />{" "}
            2017: Population Council.
          </Footnote>
          . Other aspects of quality to consider include user satisfaction and
          integration of contraception counseling and services following an
          abortion or PAC service. Available evidence suggests that users are
          most satisfied with abortion services when they can choose between a
          surgical or medical method
          <Footnote number={25}>
            Kapp N, Lohr P{" "}
            <ExternalLink
              href="https://www.sciencedirect.com/science/article/pii/S1521693419301762"
              text="Modern methods to induce abortion: Safety, efficacy and choice"
              useItalics={true}
            />{" "}
            Best Practice & Research Clinical Obstetrics and Gynaecology 63
            (2020) 37-44.
          </Footnote>
          .
        </p>

        <p>
          Thus efforts are needed to ensure that those needing abortion or PAC
          services are able to access a choice of affordable, high quality
          methods. Due to the contested nature of these services in many
          countries, any efforts to address the availability and cost of
          supplies cannot be treated in isolation; they must also take into
          consideration the wider context affecting access. This requires
          addressing a variety of issues including:
        </p>

        <ul>
          <li>
            Better regulation of the quality of available supplies for abortion
            and PAC services, while ensuring costs remain affordable;
          </li>
          <li>
            Increasing the range and choice of available abortion services,
            including registration of mifepristone in countries where it is not
            yet available and advocacy to include mifepristone in the WHO
            essential drugs list; and
          </li>
          <li>
            Ongoing advocacy to reduce legal and practical restrictions, and
            social stigma, around abortion.
          </li>
        </ul>

        <p>
          Ultimately, if more abortion services can be provided safely and
          affordably, this will improve the health and wellbeing of those
          seeking abortions, and reduce the mortality and morbidity associated
          with unsafe abortions.
        </p>

        <p>
          For the first time LEAP provides comprehensive estimates of supply
          needs across a full set of low- and middle-income countries. Several
          data gaps remain; among which are country-level estimates of abortion
          and PAC services, and the cost of supplies associated with them. These
          gaps, however, are likely a reflection on the legal restrictions and
          social stigma associated with abortion in general. As more data become
          available and community-wide discussions evolve, future iterations of
          LEAP will be better able to estimate future needs for services and
          their associated costs.
        </p>
      </section>
    </Landscape>
  );
};

export default Abortion;
