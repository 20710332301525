import { useState } from "react";
import PropTypes from "prop-types";
import MhmCpF1 from "../Figures/MhmCpF1";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import SideBySide from "../../../Common/SideBySide";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getIndicatorDetails,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  const total = data.reduce((accumulator, { value }) => accumulator + value, 0);

  const lowIncomePercentage = (data[0].value / total) * 100;
  const lowerMiddleIncomePercentage = (data[1].value / total) * 100;
  const upperMiddleIncomePercentage = (data[2].value / total) * 100;

  return {
    total,
    lowIncomePercentage,
    lowerMiddleIncomePercentage,
    upperMiddleIncomePercentage,
  };
};

const MenstruatorSummary = (props) => (
  <>
    <p>
      Currently there are an estimated{" "}
      {formatNumberAsHumanReadable(props.total)} menstruators using{" "}
      {props.methodName.toLowerCase()} across all low- and middle-income
      countries.
    </p>

    <p>
      {formatPercentage(props.lowIncomePercentage)} reside in low-income
      countries, {formatPercentage(props.lowerMiddleIncomePercentage)} reside in
      lower-middle-income countries, and the remaining{" "}
      {formatPercentage(props.upperMiddleIncomePercentage)} reside in
      upper-middle-income countries.
    </p>
  </>
);

const CostSummary = (props) => (
  <>
    <p>
      The total current cost of {props.methodName.toLowerCase()} in low- and
      middle-income countries is ${formatNumberAsHumanReadable(props.total)}.
    </p>

    <p>
      {formatPercentage(props.lowIncomePercentage)} of costs come from
      low-income countries,{" "}
      {formatPercentage(props.lowerMiddleIncomePercentage)} come from
      lower-middle-income countries, and the remaining{" "}
      {formatPercentage(props.upperMiddleIncomePercentage)} come from
      upper-middle-income countries.
    </p>
  </>
);

const QuantitySummary = (props) => (
  <>
    <p>
      An estimated {formatNumberAsHumanReadable(props.total)}{" "}
      {props.methodName.toLowerCase()} are purchased by menstruators across all
      low- and middle-income countries.
    </p>

    <p>
      {formatPercentage(props.lowIncomePercentage)} are purchased in low-income
      countries, {formatPercentage(props.lowerMiddleIncomePercentage)} are
      purchased in lower-middle-income countries, and the remaining{" "}
      {formatPercentage(props.upperMiddleIncomePercentage)} are purchased in
      upper-middle-income countries.
    </p>
  </>
);

const Summary = ({ methodName, indicatorId, ...rest }) => {
  const metrics = calculateMetrics(rest.data);

  switch (indicatorId) {
    case 1:
      return <CostSummary methodName={methodName} {...metrics} />;

    case 2:
      return <MenstruatorSummary methodName={methodName} {...metrics} />;

    case 3:
      return <QuantitySummary methodName={methodName} {...metrics} />;

    default:
      return null;
  }
};

const MhmCpCurrentIncomeGroups = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(2);

  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cp/figures/1/${props.methodId}/${selectedIndicatorId}`
  );

  const selectedIndicatorDetails = getIndicatorDetails(selectedIndicatorId);

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  return (
    <>
      <SideBySide
        component={
          <MhmCpF1
            methodName={props.methodName}
            indicatorId={selectedIndicatorId}
            indicatorName={selectedIndicatorDetails.name}
            indicatorQualifier={selectedIndicatorDetails.qualifier}
            data={data}
            onIndicatorChange={handleIndicatorChange}
          />
        }
      >
        {data ? (
          <Summary
            methodName={props.methodName}
            indicatorId={selectedIndicatorId}
            data={data}
          />
        ) : (
          <div />
        )}
      </SideBySide>
    </>
  );
};

MhmCpCurrentIncomeGroups.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
};

export default MhmCpCurrentIncomeGroups;
