import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router-dom";
import ExternalLink from "../Common/ExternalLink";
import Footnote from "../Common/Footnote";
import Landscape from "../Common/Landscape";
import SideBySide from "../Common/SideBySide";
import WithCallouts from "../Common/WithCallouts";
import { useReadersGuide } from "../../hooks/useReadersGuide";
import MhB1 from "./Boxes/MhB1";
import MhB2 from "./Boxes/MhB2";
import MhB3 from "./Boxes/MhB3";
import MhB4 from "./Boxes/MhB4";
import MhF1 from "./Figures/MhF1";
import MhF2 from "./Figures/MhF2";
import MhF3 from "./Figures/MhF3";
import MhF4 from "./Figures/MhF4";
import MhF5 from "./Figures/MhF5";
import MhF6 from "./Figures/MhF6";
import MhF7A from "./Figures/MhF7A";
import MhF12A from "./Figures/MhF12A";
import MhF19 from "./Figures/MhF19";
import MhF20 from "./Figures/MhF20";
import MhF21 from "./Figures/MhF21";
import MhT1 from "./Tables/MhT1";
import MhT11 from "./Tables/MhT11";
import MhT12 from "./Tables/MhT12";
import MhT14 from "./Tables/MhT14";
import MhT16 from "./Tables/MhT16";
import MhT2 from "./Tables/MhT2";
import MhT3 from "./Tables/MhT3";

const useStyles = makeStyles((theme) => ({
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
  sectionTitle: {
    marginTop: 0,
    paddingTop: "3rem",
  },
}));

const MaternalHealth = () => {
  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <Landscape>
      <section id="introduction">
        <Typography variant="h1" className={classes.sectionTitle}>
          Background
        </Typography>

        <div className={classes.boxes}>
          <WithCallouts
            area="Maternal Health"
            videoUrl="https://youtu.be/GTdXrm6bs4E"
          >
            The health of women worldwide is at substantial risk due to
            pregnancy-related causes. In 2017, the global Maternal Mortality
            Ratio (MMR) was 211 maternal deaths per 100,000 live births.
            Sustainable Development Goal (SGD) 3.1 sets the target of reducing
            global MMR to less than 70 per 100,000 live births by 2030
            <Footnote number={1}>
              United Nations{" "}
              <ExternalLink
                href="https://sdgs.un.org/goals/goal3"
                text="Sustainable Development Goal 3"
                useItalics={true}
              />
            </Footnote>
            .
          </WithCallouts>

          <MhB1 />

          <p>
            Postpartum hemorrhage (PPH) and pre-eclampsia/eclampsia are the two
            leading causes of maternal deaths, accounting for 40% of all
            maternal deaths
            <Footnote number={4}>
              Say L, Chou D, Gemmill A, Tunçalp Ö, Moller AB, Daniels JD, et al{" "}
              <ExternalLink
                href="http://www.thelancet.com/journals/langlo/article/PIIS2214-109X(14)70227-X/fulltext"
                text="Global Causes of Maternal Death: A WHO Systematic Analysis"
                useItalics={true}
              />{" "}
              Lancet Global Health. 2014;2(6): e323-e333.
            </Footnote>
            . Other major causes of maternal death include hypertension,
            infection, and unsafe abortion
            <Footnote number={5}>
              Say L, Chou D, Gemmill A, Tunçalp Ö, Moller AB, Daniels JD, et al{" "}
              <ExternalLink
                href="http://www.thelancet.com/journals/langlo/article/PIIS2214-109X(14)70227-X/fulltext"
                text="Global Causes of Maternal Death: A WHO Systematic Analysis"
                useItalics={true}
              />{" "}
              Lancet Global Health. 2014;2(6): e323-e333.
            </Footnote>
            <Footnote number={6}>
              The remainder are caused by or associated with infections such as
              malaria or related to chronic conditions like cardiac diseases or
              diabetes.
            </Footnote>
            . Most of these complications can be prevented or treated with
            access to skilled care, including appropriate facilities and drugs
            during and after pregnancy and childbirth. Between 2000 and 2017,
            the global MMR fell by around 38% worldwide. It dropped by almost
            50% in less-developed countries, and by close to 40% in sub-Saharan
            Africa
            <Footnote number={7}>
              WHO{" "}
              <ExternalLink
                href="https://unfpa.org/sites/default/files/pub-pdf/Maternal_mortality_report.pdf"
                text="Trends in maternal mortality 2000 to 2017: estimates by WHO, UNICEF, UNFPA, World Bank Group and the United Nations Population Division"
                useItalics={true}
              />{" "}
              Geneva: World Health Organization; 2019. Licence: CC BY-NC-SA 3.0
              IGO.
            </Footnote>
            . Declines during the previous decade, however, fell far short of
            the Millennium Development Goal of reducing MMR by 75%
            <Footnote number={8}>
              Millennium Development Goals. Gender Chart 2015{" "}
              <ExternalLink
                href="https://unstats.un.org/unsd/mdg/Resources/Static/Products/Progress2015/Gender_Chart_Web.pdf"
                useItalics={true}
              />{" "}
              Accessed 21st June 2021.
            </Footnote>
            .
          </p>

          <MhB2 />

          <p>
            Many barriers prevent pregnant women from accessing necessary
            services. These barriers are exacerbated in remote areas, where
            skilled healthcare workers are in short supply, health facilities
            are less accessible, and services and drugs are often more costly
            <Footnote number={11}>
              WHO{" "}
              <ExternalLink
                href="https://unfpa.org/sites/default/files/pub-pdf/Maternal_mortality_report.pdf"
                text="Trends in maternal mortality 2000 to 2017: estimates by WHO, UNICEF, UNFPA, World Bank Group and the United Nations Population Division"
                useItalics={true}
              />{" "}
              Geneva: World Health Organization; 2019. Licence: CC BY-NC-SA 3.0
              IGO.
            </Footnote>
            . Reaching the ambitious SDG target will not be possible without
            increased access to quality, affordable maternal health drugs,
            including the three life-saving maternal health commodities
            <Footnote number={12}>
              <ExternalLink
                href="http://www.everywomaneverychild.org/images/UN_Commission_Report_September_2012_Final.pdf"
                text="United Nations: Every Woman Every Child UN Commission on life-saving commodities for women and children: commissioners' report"
              />{" "}
              September 2012. United Nations, New York.
            </Footnote>{" "}
            (oxytocin, misoprostol, and magnesium sulfate) (see Table 1 below).
          </p>

          <p>
            Access to quality maternal health drugs is very uneven across low-
            and middle-income countries
            <Footnote number={13}>
              Droti B, O&apos;Neill KP, Mathai M, Yao Tsidi Dovlo D, Robertson J{" "}
              <ExternalLink
                href="https://gh.bmj.com/content/bmjgh/4/Suppl_9/e001306.full.pdf"
                text="Poor availability of essential medicines for women and children threatens progress towards Sustainable Development Goal 3 in Africa"
                useItalics={true}
              />{" "}
              BMJ Global Health 2019;4:e001306. IGO.
            </Footnote>
            . Drugs are often unavailable, especially in primary care facilities
            <Footnote number={14}>
              Droti B, O&apos;Neill KP, Mathai M, Yao Tsidi Dovlo D, Robertson J{" "}
              <ExternalLink
                href="https://gh.bmj.com/content/bmjgh/4/Suppl_9/e001306.full.pdf"
                text="Poor availability of essential medicines for women and children threatens progress towards Sustainable Development Goal 3 in Africa"
                useItalics={true}
              />{" "}
              BMJ Global Health 2019;4:e001306. IGO.
            </Footnote>
            . Registered drugs often don&apos;t meet international standards,
            there are challenges with storage (for example, limited shelf life
            and cold chain storage requirements), and the variations in product
            formulations (such as magnesium sulfate) can lead to incorrect
            administration
            <Footnote number={15}>
              Bahl, K, Lee, C, Palmer, A, Rosenber, T{" "}
              <ExternalLink
                href="https://www.rhsupplies.org/uploads/tx_rhscpublications/Increasing_Access_to_Essential_Maternal_Health_Supplies_-_A_scoping_of_market-based.pdf"
                text="Increasing Access to Essential Maternal Health Supplies. A scoping of market-based activities, gaps and opportunities"
              />{" "}
              March 2016. RHSC, PATH, R4D.
            </Footnote>
            . At less than $1 per dose
            <Footnote number={16}>
              RHSC{" "}
              <ExternalLink
                href="https://www.msh.org/sites/default/files/essential_medicines_maternal_health.pdf"
                text="Essential Medicines for Maternal Health. Ensuring Equitable Access for All"
              />{" "}
              2014.
            </Footnote>
            , the three life-saving maternal health commodities are relatively
            inexpensive, meaning that financing is often assumed by national
            governments
            <Footnote number={17}>
              R4D. Towards Increasing Access to Maternal, Neonatal, and Child
              Health (MNCH) Products: An Analysis of Financing, Quantification,
              and Procurement Practices and Data. Project Brief.
            </Footnote>
            <Footnote number={18}>
              RHSC{" "}
              <ExternalLink
                href="http://lifesavingcommodities.org/wp-content/uploads/2015/03/BusinessCase_Misoprostol.pdf"
                text="Business Case: Investing in Production of High-Quality Misoprostol for Low-Resource Settings"
                useItalics={true}
              />{" "}
              December 2014. Jhpiego, Baltimore.
            </Footnote>
            . While such arrangements offer many of the advantages associated
            with economies of scale, they also present risks&mdash;especially if
            commodity procurement is deprioritized or subject to pressures to
            buy locally (where quality may be less assured). In addition, in
            some cases women may purchase needed maternal health drugs from
            private pharmacies, either by policy or due to shortages
            <Footnote number={19}>
              Meda, I.B., Baguiya, A., Ridde, V. et al{" "}
              <ExternalLink
                href="https://healtheconomicsreview.biomedcentral.com/articles/10.1186/s13561-019-0228-8"
                text="Out-of-pocket payments in the context of a free maternal health care policy in Burkina Faso: a national cross-sectional survey"
                useItalics={true}
              />{" "}
              Health Econ Rev 9, 11 (2019).
            </Footnote>
            <Footnote number={20}>
              Richard, F., Antony, M., Witter, S., Kelley, A., Sieleunou, I.,
              Meessen, B., &amp; Kafando, Y. (2013){" "}
              <ExternalLink
                href="http://blogs.shu.edu/ghg/files/2014/02/GHGJ_62_53-73_RICHARD_ET_AL.pdf"
                text="Fee exemption for maternal care in Sub-Saharan Africa: a review of 11 countries and lessons for the region"
                useItalics={true}
              />{" "}
              Global Health Governance, VI(2), 1-21.
            </Footnote>
            .
          </p>

          <p>
            This analysis complements existing work on maternal health services
            <Footnote number={21}>
              Sully EA, Biddlecom A, Darroch JE, Riley T, Ashford LS,
              Lince-Deroche N et al{" "}
              <ExternalLink
                href="https://www.guttmacher.org/report/adding-it-up-investing-in-sexual-reproductive-health-2019"
                text="Adding It Up: Investing in Sexual and Reproductive Health 2019"
                useItalics={true}
              />{" "}
              New York: Guttmacher Institute, 2020.
            </Footnote>
            <Footnote number={22}>
              United Nations Population Fund{" "}
              <ExternalLink
                href="https://www.unfpa.org/resources/chapter-1-cost-ending-preventable-maternal-deaths"
                text="Chapter 1. Cost of Ending Preventable Maternal Deaths"
                useItalics={true}
              />{" "}
              In: Costing the Three Transformative Results. 2019. New York, New
              York.
            </Footnote>
            , by focusing more specifically on the use and associated cost of
            priority maternal health drugs (See Table 1) and expands the focus
            beyond the three live-saving commodities that were captured in
            earlier RHSC business cases
            <Footnote number={23}>
              RHSC{" "}
              <ExternalLink
                href="https://www.rhsupplies.org/fileadmin/uploads/rhsc/Working_Groups/Maternal_Health_Supplies_Caucus/Documents/BusinessCase_MagnesiumSulfate.pdf"
                text="Business Case: Investing in Production of High-Quality Magnesium Sulfate for Low-Resource Settings"
                useItalics={true}
              />{" "}
              December 2014. Jhpiego, Baltimore.
            </Footnote>
            <Footnote number={24}>
              RHSC{" "}
              <ExternalLink
                href="https://www.rhsupplies.org/fileadmin/uploads/rhsc/Working_Groups/Maternal_Health_Supplies_Caucus/Documents/BusinessCase_Oxytocin.pdf"
                text="Business Case: Investing in Production of High-Quality Oxytocin for Low-Resource Settings"
                useItalics={true}
              />{" "}
              December 2014. Jhpiego, Baltimore.
            </Footnote>
            <Footnote number={25}>
              RHSC{" "}
              <ExternalLink
                href="http://lifesavingcommodities.org/wp-content/uploads/2015/03/BusinessCase_Misoprostol.pdf"
                text="Business Case: Investing in Production of High-Quality Misoprostol for Low-Resource Settings"
                useItalics={true}
              />{" "}
              December 2014. Jhpiego, Baltimore.
            </Footnote>
            . We include seven priority maternal health drugs:
          </p>

          <ul>
            <li>Iron and folic acid;</li>
            <li>Hydralazine;</li>
            <li>Methyldopa;</li>
            <li>Magnesium sulfate;</li>
            <li>Oxytocin;</li>
            <li>Misoprostol, and;</li>
            <li>Metronidazole.</li>
          </ul>

          <p>
            We project changes in the use of these drugs and the cost of
            purchasing them by taking into account changes in demographics and
            in the coverage of maternal health interventions. We also explore
            the cost implications of introducing and scaling up two emerging
            drugs: heat-stable carbetocin (HSC) and tranexamic acid (TXA). These
            two drugs, recently added to the WHO List of Essential Medicines
            <Footnote number={26}>
              Gill R, Ganatra B, Althabe F{" "}
              <ExternalLink
                href="https://gh.bmj.com/content/4/6/e002150"
                text="WHO essential medicines for reproductive health"
                useItalics={true}
              />{" "}
              BMJ Global Health 2019;4:e002150.
            </Footnote>
            , offer the prospect for improving maternal health outcomes by
            increasing access to quality uterotonics and by offering further
            treatment for PPH
            <Footnote number={27}>
              RHSC and USAID{" "}
              <ExternalLink
                href="https://www.rhsupplies.org/uploads/tx_rhscpublications/Uses_of_Medicines_for_Prevention_and_Treatment_of_Post-partum_Hemorrhage_and_Other_Obstertric_Indications.pdf"
                text="Uses of Medicines for Prevention and Treatment of Post-partum Hemorrhage and Other Obstetric Indications: A Summary of Information on Indications, Contraindications, and Supply Chain Considerations for Program Managers"
                useItalics={true}
              />{" "}
              January 2019.
            </Footnote>
            . This means our future scenarios cover nine drugs; seven priority
            maternal health drugs plus two emerging drugs.
          </p>

          <p>
            Unlike the other health areas explored in LEAP, there is no single
            measure of &apos;users&apos; of maternal health drugs as different
            women require different drugs during pregnancy and childbirth
            depending on what health risks they have or health problems they
            experience. We therefore build a picture of use by determining the
            share of pregnant women or women in childbirth who will require
            different interventions that use the drugs under review, and what
            share of those who need an intervention actually receive it. These
            estimates are presented as &apos;cases&apos;; see Box 3 for more
            details.
          </p>

          <MhB3 />
        </div>
      </section>

      <section id="analysis">
        <Typography variant="h1" className={classes.sectionTitle}>
          Analysis Approach
        </Typography>

        <div className={classes.boxes}>
          <p>
            Building on the approach developed and refined for the 2019
            Contraceptive Commodity Gap Analysis
            <Footnote number={29}>
              <ExternalLink href="https://www.rhsupplies.org/activities-resources/commodity-gap-analysis/ " />
            </Footnote>
            , this report draws on a range of data sources to estimate the use
            and cost of priority maternal health drugs in 129 low- and
            middle-income countries. Given the exceptional circumstances arising
            from the advent of COVID-19 in 2020, we use 2019 as our
            &apos;current&apos; scenario, and coverage in 2019 is used to
            estimate baseline coverage for all our future scenarios.
          </p>

          <p>
            We included in this report seven &quot;priority&quot; drugs that, if
            more widely available, would prevent a significant number of deaths,
            and two emerging drugs. This choice of drugs was informed by the UN
            Commission on Life-Saving Commodities for Women and Children
            <Footnote number={30}>
              UN Commission on Life-Saving Commodities for Women and Children.
              Commissioners&apos; report. September 2012.{" "}
              <ExternalLink
                href="https://www.unfpa.org/sites/default/files/pub-pdf/Final%20UN%20Commission%20Report_14sept2012.pdf"
                useItalics={true}
              />{" "}
              Accessed 07.27.21.
            </Footnote>
            , the WHO&apos;s list of priority live-saving medicines
            <Footnote number={31}>
              WHO. Priority life-saving medicines for women and children 2012.{" "}
              <ExternalLink
                href="https://www.who.int/medicines/publications/EN_A4_WHOEMPMAR20121.pdf#:~:text=Updates%C2%A0,icines.%C2%A0"
                useItalics={true}
              />{" "}
              Accessed 07.27.21.
            </Footnote>
            , and R4D&apos;s list of priority maternal health medicines
            <Footnote number={32}>
              R4D. Increasing Access to Life-Saving Maternal, Newborn and Child
              Health Medicines 2021.{" "}
              <ExternalLink
                href="https://r4d.org/projects/increasing-access-to-life-saving-maternal-newborn-and-child-health-medicines/"
                useItalics={true}
              />{" "}
              Accessed 07.27.21.
            </Footnote>
            . The two emerging drugs figure into our analysis of future changes
            in product use and cost (see Changes Ahead below). See Table 1 and
            the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("maternal-health-scope")}
            >
              Reader&apos;s Guide
            </Link>{" "}
            for more details about the drugs included and the complications they
            prevent, manage or treat.
          </p>

          <p>
            For each case, the number of drugs received will differ depending on
            the intervention for which they were used. We estimated the total
            volume of drugs used for different cases and then used this amount
            to estimate the cost. We did not include other associated costs such
            as labor, health infrastructure, and medical equipment. Note the
            costs of drugs included in LEAP are not the same as procurement
            costs, which may be higher or lower than drug costs depending on
            context. Procurement needs reflect the volume needed to fill supply
            pipelines and maintain adequate inventory levels, and also take into
            account volumes already in the supply chain.
          </p>

          <p>
            See Box 3 for an explanation of how results are presented here to
            account for: multiple drugs being used within one pregnancy; some
            complications being managed or treated by more than one drug, and;
            not all pregnant women receiving the intervention they need.
          </p>

          <p>
            For drugs that are used for the same or similar interventions, we
            sometimes present their results together. This applies to drugs used
            for hypertension management (hydralazine and methyldopa) and drugs
            used for induction and augmentation of labor, and prevention and
            treatment of PPH (oxytocin, misoprostol, heat-stable carbetocin, and
            tranexamic acid). Note that within these groups, use of some drugs
            will be more prevalent than others.
          </p>

          <p>
            Our results are generally presented in the order in which the
            interventions would occur (from pregnancy to delivery), see Table 1.
            Costs are primarily based on global UNICEF list prices
            <Footnote number={33}>
              <ExternalLink
                href="https://supply.unicef.org/"
                text="UNICEF Supply Catalogue"
                useItalics={true}
              />{" "}
              Accessed March 15, 2021.
            </Footnote>{" "}
            meaning there is no regional or country price variation.
          </p>

          <MhT11 />

          <p>
            Results are also disaggregated by country-level income group. For
            more information about the analysis, including data sources and the
            development of the scenarios for future projections, see the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("maternal-health-scope")}
            >
              Reader&apos;s Guide
            </Link>
            .
          </p>
        </div>
      </section>

      <section id="current">
        <Typography variant="h1" className={classes.sectionTitle}>
          Where We Are Now
        </Typography>

        <div className={classes.boxes}>
          <Typography variant="h2">
            Current Use of Priority Maternal Health Drugs in Low- and
            Middle-Income Countries
          </Typography>

          <SideBySide component={<MhT12 />}>
            In 2019 there were an estimated 214 million pregnancies in low- and
            middle-income countries. Half of these were in lower-middle-income
            countries (106 million), one third in upper-middle-income countries
            (75.2 million), and less than 1/6 in low-income countries (32.4
            million). Overall, 39% of all pregnancies end in an abortion or
            miscarriage, ranging from 29% in low-income countries to 44% in
            upper-middle-income countries (see the{" "}
            <RouterLink to="/abortion">
              Abortion &amp; PAC Landscape Report
            </RouterLink>{" "}
            for more on the supplies used for these pregnancy outcomes).
          </SideBySide>

          <p>
            Different interventions are needed throughout pregnancy and
            childbirth. In this section we present results based on the
            intervention or group of interventions using one of the seven
            priority health drugs. In cases where multiple drugs are used for
            the same/similar intervention results are presented together.
          </p>

          <SideBySide component={<MhF1 />} componentPosition="right">
            The WHO recommends that daily oral iron and folic acid
            supplementation should be used in all pregnancies to prevent anemia
            and birth defects
            <Footnote number={34}>
              World Health Organization{" "}
              <ExternalLink
                href="https://www.who.int/reproductivehealth/publications/maternal_perinatal_health/a91272/en/"
                text="Standards for Maternal and Neonatal Care. Group 1: General standards of care for healthy pregnancy and childbirth"
              />{" "}
              2007.
            </Footnote>
            . Figure 1, therefore, shows all pregnant women within each of our
            three country-level income groups, divided into those who received
            iron and folic acid and those who did not
            <Footnote number={35}>
              Specifically the cases here are all women who carry their
              pregnancies to term.
            </Footnote>
            . In low-income countries, iron and folic acid is given in just 15%
            of cases, compared to 36% in lower-middle-income countries and 44%
            in upper-middle-income countries. As such, 84.4 million cases do not
            receive the recommended drug. Nearly half of this shortfall is in
            lower-middle-income countries; with the remainder fairly evenly
            split between low-income (23%) and upper-middle-income (28%)
            countries.
          </SideBySide>

          <SideBySide component={<MhF2 />}>
            Hydralazine and methyldopa are both used to manage hypertension,
            which occurs in roughly 4-7% of all pregnancies
            <Footnote number={36}>
              Abalos E, Cuesta C, Carroli G, Qureshi Z, Widmer M, Vogel JP,
              Souza JP{" "}
              <ExternalLink
                href="https://pubmed.ncbi.nlm.nih.gov/24641531/"
                text="WHO Multicountry Survey on Maternal and Newborn Health Research Network. Pre-eclampsia, eclampsia and adverse maternal and perinatal outcomes: a secondary analysis of the World Health Organization Multicountry Survey on Maternal and Newborn Health"
                useItalics={true}
              />{" "}
              BJOG. 2014 Mar;121 Suppl 1:14-24.
            </Footnote>
            . Both drugs are included in Figure 2, which represents all cases
            where a pregnant woman experienced acute, gestational, and/or
            chronic hypertension. These are the most commonly used hypertension
            control drugs; instances of other drugs being used for hypertension
            are noted as &quot;out of scope&quot;. See the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen(
                "maternal-health-pregnancies-and-births-requiring-each-type-of-product"
              )}
            >
              Reader&apos;s Guide
            </Link>{" "}
            for more detail on distributions of use of each drug.
          </SideBySide>

          <p>
            Antihypertensive drugs have relatively low coverage: in low-income
            countries they are used in just 9% of cases where they are needed
            <Footnote number={37}>
              Okello, S., Muhihi, A., Mohamed, S.F. et al{" "}
              <ExternalLink
                href="https://bmcpublichealth.biomedcentral.com/articles/10.1186/s12889-020-09829-5"
                text="Hypertension prevalence, awareness, treatment, and control and predicted 10-year CVD risk: a cross-sectional study of seven communities in East and West Africa (SevenCEWA)."
                useItalics={true}
              />{" "}
              BMC Public Health 20, 1706. 2020.
            </Footnote>
            <Footnote number={38}>
              Kayima J, Wanyenze RK, Katamba A, et al{" "}
              <ExternalLink
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3750220/"
                text="Hypertension awareness, treatment and control in Africa: a systematic review."
                useItalics={true}
              />{" "}
              BMC Cardiovasc Disord. 2013;13:54.
            </Footnote>
            . This compares to 14% in lower-middle-income countries and 21% in
            upper-middle-income countries. The low coverage can be attributed to
            multiple factors: the lack of guidelines for hypertension
            management; limited availability of the drugs themselves; lack of
            providers trained in hypertension management; low public awareness
            of hypertension as a pregnancy-related risk; and limited inclusion
            of hypertension management in antenatal care (ANC) meaning that
            women are not screened and identified. Overall, 7.26 million cases
            do not receive the required treatment. Almost half of the shortfall
            is in lower-middle-income countries, a third in upper-middle-income
            countries, and the remainder is in low-income countries.
          </p>

          <SideBySide component={<MhF3 />} componentPosition="right">
            Magnesium sulfate is classified as one of the three life-saving
            maternal health commodities, and is used to treat pre-eclampsia and
            eclampsia
            <Footnote number={39}>
              Abalos E, Cuesta C, Carroli G, Qureshi Z, Widmer M, Vogel JP,
              Souza JP{" "}
              <ExternalLink
                href="https://pubmed.ncbi.nlm.nih.gov/24641531/"
                text="WHO Multicounty Survey on Maternal and Newborn Health Research Network. Pre-eclampsia, eclampsia and adverse maternal and perinatal outcomes: a secondary analysis of the World Health Organization Multicounty Survey on Maternal and Newborn Health."
                useItalics={true}
              />{" "}
              BJOG. 2014 Mar;121 Suppl 1:14-24.
            </Footnote>
            . Roughly 2% of all pregnancies will lead to pre-eclampsia and/or
            eclampsia. In Figure 3, we present the cases of pre-eclampsia and
            eclampsia across the three country-level income groups, separating
            each into cases where magnesium sulfate was provided and those where
            it was not. In low-income countries, one third (30%) of cases
            needing treatment for these conditions received magnesium sulfate,
            compared to half (51%) in lower-middle-income countries, and two
            thirds (66%) in upper-middle-income countries. This represents a
            deficit of 1.37 million cases across all three income groups.
            Roughly half of the shortfall is in lower-middle-income countries,
            just under a third is in upper-middle-income countries, and a
            quarter is in low-income countries.
          </SideBySide>

          <SideBySide component={<MhF4 />}>
            <p>
              Misoprostol and oxytocin (both uterotonics) can be used for
              induction of labor and to prevent and treat PPH, with oxytocin
              additionally used for augmentation. Our estimates are based on a
              global assumption that 8% of women need induction, 20% need
              augmentation, all births need PPH prevention, and 11% need PPH
              treatment
              <Footnote number={40}>
                JSI and SIAPS. 2015{" "}
                <ExternalLink
                  href="https://marketbookshelf.com/publications/quantification-health-commodities-forecasting-consumption-select-reproductive-maternal-newborn-child-health-commodities/"
                  text="Quantification of Health Commodities: RMNCH Supplement Forecasting Consumption of Select Reproductive, Maternal, Newborn and Child Health Commodities"
                />{" "}
                Submitted to the US Agency for International Development by the
                Systems for Improved Access to Pharmaceuticals and Services
                (SIAPS) Program. Arlington, VA: Management Sciences for Health.
                Submitted to the United Nations Children&apos;s Fund by JSI,
                Arlington, VA: JSI Research &amp; Training Institute, Inc.
              </Footnote>
              <Footnote number={41}>
                Calvert C, Thomas SL, Ronsmans C, Wagner KS, Adler AJ, Filippi V{" "}
                <ExternalLink
                  href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0041114"
                  text="Identifying regional variation in the prevalence of postpartum hemorrhage: a systematic review and meta-analysis"
                />{" "}
                PLoS One. 2012;7(7):e41114. doi: 10.1371/journal.pone.0041114.
              </Footnote>
              . In Figure 4, we present cases requiring these interventions
              across the three country-level income groups, separating each into
              cases where uterotonics were provided and those where they were
              not.
            </p>
            <p>
              Misoprostol does not require cold-chain storage so is used more
              frequently for non-facility births or births in facilities without
              adequate cold chain. See the{" "}
              <Link
                underline="always"
                onClick={handleReadersGuideOpen("maternal-health-cases")}
              >
                Reader&apos;s Guide
              </Link>{" "}
              for more detail on how we distributed coverage of each drug.
              Overall, uterotonic drugs are not used in most of the cases where
              they are needed, although there is better coverage of their use in
              PPH prevention and treatment compared with induction and
              augmentation. They are used in only 27% of cases in low-income
              countries and 40% of cases in lower-middle-income countries. This
              compares to 60% of cases in upper-middle-income countries. The
              most widely used uterotonic is oxytocin.
            </p>
          </SideBySide>

          <p>
            Coverage of uterotonics also varies widely with each intervention -
            it is much lower for induction and augmentation than for PPH
            prevention and treatment (data not shown). Although misoprostol and
            oxytocin account for two of the three WHO-defined &quot;life-saving
            commodities&quot;, approximately 102 million cases that need them do
            not receive them. Just over half of the shortfall is in
            lower-middle-income countries, and one quarter each in low-income
            and upper-middle-income countries.
          </p>

          <SideBySide component={<MhF5 />} componentPosition="right">
            Metronidazole is an antibiotic; while used to treat a range of
            infections here we focus on its use to treat maternal infections
            which occur among roughly 7% of women during and immediately after
            childbirth
            <Footnote number={42}>
              The WHO Global Maternal Sepsis Study (GLOSS) Research Group{" "}
              <ExternalLink
                href="https://www.thelancet.com/journals/langlo/article/PIIS2214-109X(20)30109-1/fulltext"
                text="Frequency and management of maternal infection in health facilities in 52 countries (GLOSS): a 1-week inception cohort study"
              />{" "}
              Lancet Glob Health, 8 (2020), pp. e661-e671.
            </Footnote>
            . In Figure 5, we present cases of maternal infection across the
            three country-level income groups, separating each into cases where
            metronidazole was provided and those where it was not. Only a third
            of cases in low-income-countries are given maternal infection
            treatment (32%), half in lower-middle-income countries (54%), and
            just over two thirds in upper-middle-income countries (69%). This
            represents a deficit of 4.24 million cases that do not receive
            required treatment across all three income groups. Half of the
            shortfall is in lower-middle-income countries and a quarter each are
            in low-income and upper-middle-income countries.
          </SideBySide>

          <p>
            Because the seven priority maternal drugs can play different
            therapeutic roles in different interventions, and coverage of
            services varies by intervention, there is considerable variation in
            the number of cases requiring and receiving each drug. Figures 1-5
            illustrated the different coverage levels separately for each drug
            or group of drugs; Table 3 brings this data together to compare the
            number of cases for each of the priority maternal health drugs.
          </p>

          <p>
            The largest number of cases in which drugs are actually provided are
            cases receiving uterotonics. This is driven by the fact that
            uterotonics are used for four different interventions, including PPH
            prevention which all women should receive during or immediately
            after childbirth. Iron and folic acid supplementation represents the
            second largest number of cases receiving drugs because, similar to
            PPH prevention and unlike other fundamentally curative
            interventions, it is recommended for all pregnant women. The number
            of cases needing antihypertensive drugs and metronidazole are
            roughly comparable, but those receiving antihypertensive drugs are
            fewer due to extremely low coverage. The number of cases receiving
            magnesium sulfate is low because both the number of cases needing it
            is low, and only half the cases needing it will receive it.
          </p>

          <MhT1 />

          <Typography variant="h2">
            Current Cost of Seven Priority Maternal Health Drugs in Low- and
            Middle-Income Countries
          </Typography>

          <p>
            We estimate the cost of the drugs received by women during pregnancy
            and childbirth. It is important to note that we assess costs from
            the perspective of the institutional purchasers of the drugs
            (governments, international donors, etc.), as opposed to the women
            receiving the drugs, since they are often provided free of charge by
            the public health system.
          </p>

          <SideBySide component={<MhT14 />}>
            <p>
              Table 4 presents the unit prices we used for these drugs. We
              valued all drugs at a single global unit price informed primarily
              by UNICEF list prices; in reality the price paid by governments is
              likely to vary. In addition, as noted earlier, in some instances
              women may purchase these drugs from pharmacies rather than
              receiving them at the health facility where they receive their
              services.
            </p>
            <p>
              It is important to note that the total price of drugs received in
              any given intervention will vary depending on the quantity of
              drugs needed. Iron and folic acid, for example, has a very low
              unit price, but 180 tablets are needed to cover a woman across her
              pregnancy (see the{" "}
              <Link
                underline="always"
                onClick={handleReadersGuideOpen("maternal-health-quantities")}
              >
                Reader&apos;s Guide
              </Link>{" "}
              for details on units needed per intervention and data sources for
              unit prices). For LEAP, we estimated the total volume of drugs
              needed for each case and applied the above unit prices to these
              drug volumes to estimate the total costs.
            </p>
          </SideBySide>

          <p>
            The total cost of the seven priority maternal health drugs in low-
            and middle-income countries is currently $103 million. This figure
            is a function of the unit prices of the drugs, the number of cases
            receiving the drugs, and the units needed per case. The vast
            majority of costs are in lower-middle-income ($48.7 million) and
            upper-middle-income ($44.4 million) countries due to the numbers of
            pregnancies in those countries, and to the higher proportion of
            women receiving the drugs when needed. The current total cost in
            low-income countries is $10.2 million.
          </p>

          <MhT2 />

          <SideBySide component={<MhF6 />} componentPosition="right">
            <p>
              Despite accounting for only a small share of cases, metronidazole
              accounts for the largest share of costs across all three income
              groups; making up around one-third of costs in each (32%-35%).
              This is due to the relatively high unit cost of the drug, coupled
              with the large volume of drug needed per case (one unit every
              eight hours for four days).
            </p>
            <p>
              Uterotonics (misoprostol and oxytocin) also make up a large share
              of costs given the large number of cases receiving these drugs.
              Uterotonics account for around one-quarter of costs in each
              country-level income group (23-27%).
            </p>
            <p>
              Iron and folic acid supplements also account for a fairly large
              share of costs (20-27%) despite its extremely low unit cost (it is
              185 times cheaper per unit than metronidazole). This is because it
              is recommended for all pregnancies and requires one tablet a day
              throughout most of pregnancy.
            </p>
          </SideBySide>

          <p>
            The total current cost of the three life-saving commodities
            (oxytocin, misoprostol and magnesium sulfate) is $30.5 million,
            which represents just 29% of the total cost of the seven priority
            maternal health drugs.
          </p>
        </div>
      </section>

      <section id="future" className={classes.sectionTitle}>
        <Typography variant="h1">Changes Ahead</Typography>

        <div className={classes.boxes}>
          <p>
            Based on demographic changes, changes in contraceptive use, and
            changes in fertility rates, the overall number of pregnancies and
            births is likely to increase (though not for all countries). In
            addition, there are likely to be increases in a) coverage of the
            interventions that use the priority maternal health drugs and b) the
            use of emerging drugs, specifically the following two:
          </p>

          <ol>
            <li>
              Heat-stable carbetocin (HSC)&mdash;a uterotonic used for the
              prevention of PPH that could be used in place of misoprostol and
              oxytocin.
            </li>
            <li>
              Tranexamic acid (TXA)&mdash;used for treatment of PPH in addition
              to either misoprostol or oxytocin. The WHO currently recommends
              using tranexamic acid in all cases of PPH
              <Footnote number={43}>
                <ExternalLink
                  href="https://www.who.int/reproductivehealth/publications/tranexamic-acid-pph-treatment/en/"
                  text="WHO recommendation on tranexamic acid for the treatment of postpartum haemorrhage"
                />{" "}
                Geneva: World Health Organization; 2017. Licence: CC BY-NC-SA
                3.0 IGO.
              </Footnote>
              .
            </li>
          </ol>

          <p>
            These two emerging drugs bring to nine the number of maternal health
            drugs in our analysis of future changes. See Table 1 for details
            about the drugs included in our analysis.
          </p>

          <p>
            For the analysis of possible future trends presented in this
            section, we developed three scenarios, which are described more
            fully in Box 4:
          </p>

          <ul>
            <li>Scenario 1: Maintain Coverage;</li>
            <li>Scenario 2: Increase Coverage;</li>
            <li>Scenario 3: Increase Coverage and Scale-Up Emerging Drugs</li>
          </ul>

          <p>
            In all three scenarios the number of pregnancies and births changes
            in the same way, based on projected changes in population,
            contraceptive use, and fertility rates. Changes in contraceptive use
            are aligned to the estimates developed for LEAP&apos;s{" "}
            <RouterLink to="/contraception">
              Contraceptive Landscape Report
            </RouterLink>
            . Coverage of interventions that use the nine maternal health drugs,
            however, differs. Increased coverage of maternal health
            interventions in Scenarios 2 and 3 is based on coverage levels
            reaching the current coverage within the next highest income group.
            Scenario 3 reflects differences in drugs used and the associated
            cost attributable to the introduction and scale-up of heat-stable
            carbetocin and tranexamic acid. Scale-up of these two drugs is based
            on an S-shape curve that assumes coverage would begin slowly as
            these drugs are registered in more countries and then speed up as
            availability increases.
          </p>

          <p>
            These scenarios are not intended to be predictive but rather
            indicate what would happen if these levels of coverage were to be
            achieved. Equally, none of these scenarios reflect a scenario of
            &quot;full-coverage&quot;, which we do not deem achievable in the
            near term. The further into the future our projections run, the less
            certainty we have; this uncertainty is represented graphically in
            the figures with a dotted line.
          </p>

          <MhB4 />

          <Typography variant="h2">
            Future Use of Priority Maternal Health Drugs in Low- and
            Middle-Income Countries
          </Typography>

          <p>
            Between 2019 and 2030, the total number of births
            <Footnote number={44}>
              This includes both live births and stillbirths; within LEAP some
              interventions are limited to women who have a live birth, while
              others are calculated among all women who carry a pregnancy to
              term. See the{" "}
              <Link
                underline="always"
                onClick={handleReadersGuideOpen(
                  "maternal-health-pregnancies-and-births"
                )}
              >
                Reader&apos;s Guide
              </Link>{" "}
              for more detail.
            </Footnote>{" "}
            would increase by 37% in low-income countries (+8.40 million), and
            by 14% in lower-middle-income countries (+8.86 million) if changes
            in contraceptive use and abortion follow the trajectories
            established for the other sections of this LEAP analysis. The number
            would decrease slightly in upper-middle-income countries (-7%, -2.80
            million), driven by the expected changes in population, fertility
            rates, and contraceptive use and need. The same changes underlie all
            three scenarios.
          </p>

          <MhT16 />

          <p>
            In 2030, the total number of cases that need each drug or group of
            drugs will be the same in all three scenarios, however, the share of
            cases that receive the drug(s) will vary based on the coverage of
            interventions under each scenario. Figure 7 shows the share of cases
            that need and receive versus need but don&apos;t receive each drug
            across the three income groups. As above, we present results based
            on the intervention or group of interventions using one of the seven
            priority health drugs, plus the two emerging drugs, and drugs that
            are used for the same/similar interventions are presented together.
          </p>

          <p>
            We start with iron and folic acid supplementation; the remaining
            drugs can be explored using the dropdown list under the graph. For
            most drugs, only Scenarios 1 and 2 are relevant, since Scenario 3
            pertains only to the drugs used in the prevention and treatment of
            PPH. Heat-stable carbetocin is shown in this graph alongside the
            other uterotonics; tranexamic acid is not presented here as it is
            not a uterotonic and so would be used in conjunction with, not
            instead of, the drugs shown.
          </p>

          <p>
            The share of cases that receive each drug will continue to vary
            widely by country-level income group in 2030 under all scenarios.
            With increased coverage (Scenario 2), the largest difference across
            income groups will be seen for iron and folic acid supplementation;
            only 37% of cases in low-income countries would receive iron and
            folic acid compared to 81% in upper-middle-income countries. Under
            this scenario, the share of cases needing and receiving the two
            antihypertensive drugs (methyldopa and hydralazine) will continue to
            be low across all income groups; just 14% in low-income countries,
            17% in lower-middle-income countries, and 35% in upper-middle-income
            countries. For the remaining drugs (magnesium sulfate, uterotonics,
            and metronidazole), under Scenario 2 more than half of cases needing
            these drugs in low-income countries would receive them; in
            lower-middle-income countries the share of cases receiving drugs
            would be higher yet (63-70%); and in upper-middle-income countries
            more than 70% of cases needing each of these three drugs would
            receive them. For uterotonics, Scenario 3 shows the addition of
            heat-stable carbetocin&mdash;the share of cases receiving
            uterotonics stays the same as in Scenario 2; however which
            uterotonic is received varies. The scale-up of heat-stable
            carbetocin would mostly replace use of oxytocin.
          </p>

          <MhF7A />

          <p>
            Based on changes in the number of pregnancies and births, the number
            of cases receiving the needed drugs will change over time, even if
            coverage of maternal health interventions is maintained at current
            levels, as envisioned under Scenario 1. When coupled with increases
            in intervention coverage encompassed under Scenario 2, ever more
            dramatic changes can be seen. Meanwhile, Scenario 3, which layers in
            the scale-up of two emerging drugs, holds significant implications
            for the use of drugs for prevention and treatment of PPH
            (uterotonics and tranexamic acid).
          </p>

          <p>
            With increased coverage (Scenario 2), oxytocin would see the largest
            increase in the number of cases receiving this drug, increasing from
            69.5 million cases in 2019 to 107 million cases in 2030 (+54%).
            However, in Scenario 3 this increase would be more than offset by
            the scale-up of heat-stable carbetocin; under this scenario the
            total cases receiving oxytocin would decline. Based on the assumed
            scale-up patterns of the two emerging drugs, 58.4 million cases
            would receive heat-stable carbetocin and 6.51 million cases would
            receive tranexamic acid in 2030.
          </p>

          <p>
            Cases receiving iron and folic acid would also see a large increase
            under Scenario 2, from 45.6 million in 2019 to 76.1 million in 2030
            (+67%). Despite increases in coverage, the total number of cases
            receiving antihypertensives (hydralazine and methyldopa), magnesium
            sulfate, and metronidazole would remain low even under Scenario 2.
          </p>

          <MhF12A />

          <Typography variant="h2">
            Future Cost of Priority Maternal Health Drugs in Low- and
            Middle-Income Countries
          </Typography>

          <p>
            We project changes in the cost of the seven priority maternal health
            drugs plus the two emerging drugs, from 2019 to 2030, under each of
            the three Scenarios. As noted previously, we assess cost from the
            perspective of institutional procurers, as opposed to the women
            receiving the drugs, since they are often provided free of charge by
            the public health system. Note that all costs are based on current
            drug costs, held constant for all years through to 2030. See Table 1
            for details about the drugs included in our analysis, and Box 4 for
            details about the future scenarios we analyze.
          </p>

          <SideBySide component={<MhF19 />}>
            If intervention coverage across all low- and middle-income countries
            were maintained at current levels as envisioned under Scenario 1,
            costs would remain relatively similar, increasing from $103 million
            in 2019 to $110 million in 2030 (+$6.36 million, +6%). Building in
            the increases in intervention coverage to levels envisioned under
            Scenario 2 would see costs increase more rapidly&mdash;growing by
            60% from $103 million in 2019 to $166 million in 2030 (+$62.4
            million). If the increase in coverage levels were combined with the
            scale-up of heat-stable carbetocin and tranexamic acid envisioned
            under Scenario 3, costs would increase even more rapidly, growing by
            74%, from $103 million in 2019 to $180 million in 2030 (+$76.4
            million). This reflects the current cost of heat-stable carbetocin
            relative to current uterotonics, and additional costs from cases
            receiving tranexamic acid.
          </SideBySide>

          <p>
            The projected changes in cost vary across the three income groups
            (Figure 10). Under Scenario 1, for example, costs would increase by
            35% in low-income from $10.2 million in 2019 to $13.7 in 2030, and
            only 12% in lower-middle-income countries from $48.7 million in 2019
            to $54.4 million in 2030, whereas upper-middle-income countries
            would see costs decrease by 7%, from $44.4 in 2019 to $41.4 in 2030.
          </p>

          <MhF20 />

          <p>
            With increases in coverage (Scenario 2) the change in cost would be
            larger; adding in the two emerging drugs (Scenario 3) would increase
            costs further, though the change between Scenarios 2 and 3 is fairly
            minimal. In low-income countries, Scenario 2 would see costs
            increase by 167% between 2019 ($10.2 million) and 2030 ($27.2
            million), compared to 190% under Scenario 3 (reaching $29.5 million
            in 2030).
          </p>

          <p>
            In lower-middle-income countries, the cost increases between 2019
            and 2030 would be smaller in relative terms: 59% under Scenario 2
            ($48.7 million in 2019 to $77.5 million in 2030) compared to 74%
            (reaching $84.6 million in 2030) under Scenario 3. Finally, cost
            increases would be smaller still (yet positive) in
            upper-middle-income countries where Scenario 2 would see an increase
            of 37% (from $44.4 million in 2019 to $61.0 million in 2030)
            compared to 48% under Scenario 3 (increasing to $65.6 million in
            2030).
          </p>

          <p>
            These differences are driven by different rates of change in the
            number of pregnant women in each income group, coupled with
            differences in the magnitude of increase in coverage for each
            intervention.
          </p>

          <MhT3 />

          <p>
            Figure 11 shows the change in cost for each of the seven priority
            maternal health drugs and the two emerging drugs between 2019 and
            2030. In absolute terms, the cost of some drugs is expected to
            remain fairly low, either because they are inexpensive or because
            cases receiving the drug(s) would remain low, even with increased
            coverage.
          </p>

          <p>
            The cost of the antihypertensive drug <strong>hydralazine</strong>,
            for which coverage is very low, would increase by just $29,400 (5%)
            between 2019 and 2030 under Scenario 1, and by $484,000 (85%) under
            Scenario 2. <strong>Magnesium sulfate</strong> is only needed in
            cases of pre-eclampsia and eclampsia so case numbers would also
            remain relatively low. Under Scenario 1, costs would increase by
            just $256,000 (+5%) between 2019 and 2030, compared to $2.54 million
            (+48%) under Scenario 2. <strong>Misoprostol</strong> case numbers
            are also low, coupled with a fairly low unit cost. If coverage
            levels were maintained at current levels as envisioned under
            Scenario 1, the cost of misoprostol would increase by just $336,000
            (+8%) between 2019 and 2030. Under Scenario 2, they would double
            (+$4.33 million). And because heat-stable carbetocin is likely to
            replace some misoprostol services, the scale-up of this emerging
            drug under Scenario 3 would offset some of the cost of increased
            coverage of misoprostol. As such, Scenario 3 would see the cost of
            misoprostol increase by only $2.12 million (+49%) between 2019 and
            2030, half the increase in Scenario 2.
          </p>

          <MhF21 />

          <p>
            For the other drugs that have higher case numbers, larger increases
            in cases or higher unit prices, cost increases would be larger. In
            the case of <strong>iron and folic acid</strong> for example, which
            is a daily pill, Scenario 1 would see costs increase by only $1.75
            million (+7%). Scenario 2, however, would see costs increase by
            $17.0 million (+67%). Although coverage of{" "}
            <strong>methyldopa</strong> is low and would only see costs under
            Scenario 1 increase by $827,000 (+6%), under Scenario 2, costs would
            rise by $11.2 million (+86%) due to cases using this drug nearly
            doubling in this scenario coupled with the need for many units of
            the drug for most cases (three tablets a day for five to nine months
            depending on if hypertension is gestational or chronic). Because of
            the higher case numbers associated with <strong>oxytocin</strong>,
            which is more commonly used in facilities with adequate cold chain
            storage, maintaining current coverage levels under Scenario 1 would
            see the cost of oxytocin increase by $1.04 million (+5%). An
            increase in coverage levels envisioned under Scenario 2, however,
            would lead to a $11.2 million increase in costs (+53%) over this
            same time period. The scale-up of heat-stable carbetocin envisioned
            within Scenario 3 would see the cost of oxytocin decrease by $1.64
            million (-8%) between 2019 and 2030 because of the substitution
            effect. <strong>Metronidazole</strong> has a high unit price and
            requires many units per case. Although Scenario 1 would see the cost
            of metronidazole increase by $2.12 million (+6%) between 2019 and
            2030, under Scenario 2 where cases receiving the drug increase,
            costs would increase by $15.7 million (+46%).
          </p>

          <p>
            As noted previously, the growth in cases using{" "}
            <strong>heat-stable carbetocin</strong> and{" "}
            <strong>tranexamic acid</strong> is expected to follow an S-shape
            curve with the first cases occurring in 2021 to account for recent
            registration efforts. Across all low- and middle-income countries,
            the costs of heat-stable carbetocin would grow from $657,000 in 2021
            to $20.6 million in 2030. During this same timeframe, costs of
            tranexamic acid would increase from $295,000 to $8.47 million.
            Because heat-stable carbetocin is likely to have a substitution
            effect relative to other uterotonics, the overall cost increase of
            uterotonics under Scenarios 2 and 3 would be similar. Under Scenario
            2, for example, total cost of uterotonics would increase from $25.2
            million in 2019 to $40.7 million in 2030, compared to $46.3 million
            under Scenario 3.
          </p>

          <p>
            With regards to the <strong>three life-saving commodities</strong>{" "}
            (magnesium sulfate, oxytocin and misoprostol), costs under Scenario
            1 would grow from $30.5 million in 2019 to $32.1 million in 2030, an
            increase of only 5% (+$1.64 million). Under Scenario 2, costs would
            reach $48.5 million in 2030, an increase of 59% (+$18.1 million).
            And under Scenario 3, which assumes increases in coverage levels and
            the introduction of the two emerging drugs, costs would only grow to
            $33.5 million in 2030, an increase of 10% (+$3.02 million) due to
            the substitution effect of heat-stable carbetocin.
          </p>
        </div>
      </section>

      <section id="conclusion">
        <Typography variant="h1" className={classes.sectionTitle}>
          What&apos;s Next
        </Typography>

        <p>
          LEAP brings a unique focus on the drugs needed and their associated
          costs across low- and middle-income countries. Our analysis focuses on
          seven priority maternal health drugs that prevent, manage or treat the
          most common maternal health complications. If available to all women
          who need them, these drugs could dramatically reduce maternal
          mortality and morbidity. Unfortunately, current coverage of many
          maternal health interventions is low, especially in low-income
          countries. Our analysis also explores the implications of scaling up
          two emerging drugs: heat-stable carbetocin and tranexamic acid, as
          discussed further below.
        </p>

        <p>
          The total cost of these nine drugs, today and in the future, is a
          function of numerous factors, including the number of cases needing
          and receiving each drug, the unit price of the drug, and the number of
          units required for each case. LEAP takes all these dynamics into
          account. Because very limited data are available on the prices paid by
          individual countries to procure these drugs, we opted to use global
          unit prices. We recognize, however, that the prices of drugs and their
          quality is likely to vary widely across countries.
        </p>

        <p>
          We find pronounced differences in the share of cases that receive each
          drug across the three income groups. While the pattern differs some
          for each drug, low-income countries consistently see the lowest share
          of cases receiving needed drugs while upper-middle-income countries
          see the highest share. Though for many drugs, the share of cases
          receiving the needed drugs remains low even in upper-middle-income
          countries; only 21% of cases requiring antihypertensive drugs receive
          them and only 44% of pregnant women receive iron and folic acid
          supplementation. This suggests that investments are needed to improve
          access across all three income groups; but with a particular focus on
          low-income countries where coverage falls furthest behind.
        </p>

        <p>
          By 2030, the overall number of pregnancies and births will have likely
          increased in low-income and lower-middle-income countries and
          decreased in upper-middle-income countries. This will affect the need
          for future maternal health interventions and corresponding drugs. By
          2030, we also expect that increased use of antenatal care services and
          facility-based deliveries will increase coverage of maternal health
          interventions. To estimate the possible effect of these changes, we
          explore a scenario of increased coverage informed by the coverage
          levels seen in the next highest income group. In some instances, these
          increases are modest and still far from achievement of universal
          access so there may be room for coverage levels to grow even beyond
          what we&apos;ve projected here.
        </p>

        <p>
          However, even these sometimes-modest increases would substantially
          increase the cost of drugs between 2019 and 2030, especially in
          low-income countries where costs would more than double. But,
          considering the overall magnitude of the costs, the seven priority
          maternal health drugs represent an affordable investment for
          governments and/or donors, especially given the positive health
          impacts on women and their families. In addition, LEAP shows that only
          29% of the costs are projected to come from the three life-saving
          commodities, suggesting a need to focus on a broader set of drugs.
        </p>

        <p>
          In line with recent conversations about the scale-up of heat-stable
          carbetocin and tranexamic acid, used to prevent or treat PPH, we
          explore a scenario to understand the cost implications of introducing
          these two emerging drugs. Although tranexamic acid in particular is
          expensive, the addition of these two emerging drugs to maternal health
          interventions would only add $14.0 million in costs in 2030, partly
          because heat-stable carbetocin would be used in place of, not as well,
          other uterotonics. This is valued at today&apos;s prices and as the
          markets for these drugs increase their unit prices may decline, adding
          even less cost. On the other hand, this estimate doesn&apos;t include
          the costs of introducing new drugs, such as registration and training.
          From the perspective of drug costs, increasing the coverage of key
          maternal health interventions will have much larger cost consequences
          then scaling up these two emerging drugs.
        </p>

        <p>
          Efforts to ensure high-quality maternal health drugs are available
          will be an important first step to achieving SDG 3.1 and making
          pregnancy and childbirth safe for women across the globe. Scaling up
          coverage of maternal health interventions and the associated drugs and
          supplies, including those included in this analysis, implies increased
          investment on the part of governments and donors. These investments
          must be made in high quality drugs; quality should not be sacrificed
          to gain lower prices. While that investment is significant, so are the
          potential health gains for women and their families. The UN Commission
          on Life-Saving Commodities for Women and Children determined that
          scaling up quality maternal and family planning commodities represents
          &quot;excellent global development investments&quot; based on the
          estimated cost per lives saved
          <Footnote number={45}>
            UN Commission on Life-Saving Commodities for Women and Children.
            Commissioners&apos; report. September 2012.{" "}
            <ExternalLink
              href="https://www.unfpa.org/sites/default/files/pub-pdf/Final%20UN%20Commission%20Report_14sept2012.pdf"
              useItalics={true}
            />
          </Footnote>
          . Should funding from governments and donors fall short, women may
          either go without these priority drugs, or they may need to purchase
          the drugs themselves from private pharmacies which comes with critical
          concerns of the quality of drugs and equity of access, especially in
          low-income countries.
        </p>

        <p>
          LEAP helps build a foundation to this first step by providing the
          first comprehensive estimate of current and future use and cost of
          priority maternal health drugs across a full set of low- and
          middle-income countries. Several data gaps remain, including country-
          or regional-level drug prices, and information about the quality of
          drugs being procured. As more data become available and community-wide
          discussions evolve, future iterations of LEAP will be better able to
          estimate future needs for interventions and their associated drug
          costs.
        </p>
      </section>
    </Landscape>
  );
};

export default MaternalHealth;
