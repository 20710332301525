import { Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";

const DataSources = () => (
  <div>
    <Typography variant="h1">Data Sources</Typography>
    <p>
      Building on the approach developed for the{" "}
      <ExternalLink
        href="https://www.rhsupplies.org/activities-resources/commodity-gap-analysis/"
        text="Commodity Gap Analysis"
      />
      , we drew on a wide-range of data sources in order to develop the most
      comprehensive estimates possible for each health area. This included
      analysis of:
    </p>

    <ul>
      <li>
        Results from household surveys like Demographic Health Surveys (
        <ExternalLink href="https://dhsprogram.com/" text="DHS" />
        ), Multiple Indicator Cluster Surveys (
        <ExternalLink href="https://mics.unicef.org/surveys" text="MICS" />
        ), and other National Surveys;
      </li>
      <li>
        Existing projections developed by the United Nations Population Division
        <Footnote number={4}>
          Including one of{" "}
          <ExternalLink
            href="https://population.un.org/wpp/"
            text="World Population Prospects"
          />
          ,{" "}
          <ExternalLink
            href="https://www.un.org/en/development/desa/population/theme/family-planning/cp_model.asp"
            text="Family Planning Indicators"
          />{" "}
          and{" "}
          <ExternalLink
            href="https://population.un.org/wup/"
            text="World Urbanization Prospects"
          />
          .
        </Footnote>{" "}
        and estimates derived as part of Guttmacher&apos;s{" "}
        <ExternalLink
          href="https://www.guttmacher.org/report/adding-it-up-investing-in-sexual-reproductive-health-2019-methodology"
          text="Adding It Up"
        />{" "}
        report;
      </li>
      <li>
        Data provided by partners in the sexual and reproductive health
        community, including DKT International, International Planned Parenthood
        Federation (IPPF), MSI Reproductive Choices, and PSI, and;
      </li>
      <li>
        Data purchased from private sector entities, including IQVIA and{" "}
        <ExternalLink
          href="https://www.marketsandmarkets.com/Market-Reports/feminine-hygiene-product-market-69114569.html"
          text="MarketsandMarkets"
        />
        , to provide visibility into private sector pricing.
      </li>
    </ul>

    <p>
      The unique methodology developed for LEAP allowed us to combine all these
      data sources to create a single picture of the current and future
      potential markets for reproductive health supplies.
    </p>
  </div>
);

export default DataSources;
