import { useLocation } from "react-router-dom";

const normalizeChapterSlug = (chapterSlug) => {
  let normalizedChapterSlug = chapterSlug;

  if (
    ![
      "contraception",
      "menstrual-hygiene",
      "abortion",
      "maternal-health",
      "custom",
    ].includes(normalizedChapterSlug)
  ) {
    normalizedChapterSlug = "introduction";
  }

  return normalizedChapterSlug;
};

const useChapterDetails = () => {
  const pathComponents = useLocation().pathname.replace(/^\//, "").split("/");

  const slug = normalizeChapterSlug(pathComponents[0]);

  let sectionSlug;

  if (slug === "custom") {
    sectionSlug = normalizeChapterSlug(pathComponents[1]);
  }

  return {
    slug,
    sectionSlug,
  };
};

export default useChapterDetails;
