import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import classNames from "classnames";
import Box from "./Box";

const useStyles = makeStyles((theme) => ({
  content: {
    "& p:first-child": {
      marginTop: 0,
    },
    "& p:last-child": {
      marginBottom: 0,
    },
  },
  boxContent: {
    marginTop: theme.spacing(2),
  },
}));

const Information = ({ children, ...rest }) => {
  const theme = useTheme();

  const classes = useStyles();

  return (
    <Box
      {...rest}
      caption={`Box ${rest.number}`}
      backgroundColor={theme.palette.area.background}
      classes={{
        content: classNames(rest.classes?.content, classes.boxContent),
      }}
    >
      <div className={classes.content}>{children}</div>
    </Box>
  );
};

Information.propTypes = {
  number: PropTypes.string.isRequired,
};

export default Information;
