import { makeStyles } from "@material-ui/styles";
import Table from "../../Common/Box/Table";
import initialData from "../../../data/initialData.json";
import useCsvDownload from "../../../hooks/useCsvDownload";
import { formatNumber } from "../../../utilities";

const number = 3;

const format = (n) =>
  formatNumber(n, {
    decimalPlaces: 2,
  });

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& th:not(:first-child)": {
      minWidth: "6rem",
    },
  },
  groupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  total: {
    backgroundColor: theme.palette.area.background,
  },
}));

const Component = () => {
  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleCsvDownload = () => {
    downloadCsv("/mhm/tables/3/csv", `mhm_t${number}.csv`);
  };

  const data = initialData["mhm_t3"];

  return (
    <Table
      id="MHM_t3"
      number={number.toString()}
      title="Annualized Cost per Menstruator by Product Type"
      subtitle="By Income Group, 2019"
      onDownloadCsv={handleCsvDownload}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th colSpan={2} />
            <th colSpan={2} className={classes.groupBoundary}>
              Reusable
            </th>
          </tr>
          <tr>
            <th />
            <th>Disposable</th>
            <th className={classes.groupBoundary}>Pad</th>
            <th>Cup</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.incomeGroup}>
              <td>{item.incomeGroup}</td>
              <td>${format(item.disposable)}</td>
              <td className={classes.groupBoundary}>
                ${format(item.reusablePad)}
              </td>
              <td>${format(item.cup)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Table>
  );
};

export default Component;
