import { useState } from "react";
import BoxControls from "../../Common/Box/BoxControls";
import Figure from "../../Common/Box/Figure";
import initialData from "../../../data/initialData.json";
import { getTooltip } from "../../../utilities";

const number = 2;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Abortion":
      return "#f37576";

    case "PAC":
      return "#f39977";

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = data.map((series) => ({
    ...series,
    color: getSeriesColor(series.name, theme),
  }));

  return {
    chart: {
      type: "column",
      width: 470,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: ["Low", "Lower-Middle", "Upper-Middle"],
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        format: absolute ? undefined : "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: absolute ? "normal" : "percent",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Services", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const Component = () => {
  const [comparisonMode, setComparisonMode] = useState("absolute");

  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  return (
    <Figure
      id="APAC_f1"
      number={number.toString()}
      fileStem={`apac_f${number}`}
      title="Abortion and PAC Services by Type"
      subtitle="By Income Group, 2019"
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        />
      }
      chartOptions={options(initialData["apac_f1"], { comparisonMode })}
    />
  );
};

export default Component;
