import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  component: {
    alignItems: "center",
    backgroundColor: theme.palette.area.background,
    // border: (props) =>
    //   props.border ? `1px solid ${theme.palette.area.primary}` : undefined,
    color: theme.palette.area.primary,
    display: "flex",
    padding: theme.spacing(0.5),
    "& a": {
      color: theme.palette.area.primary,
    },
  },
  content: {
    flexGrow: 1,
  },
  icon: {
    display: "inline-flex",
    marginRight: theme.spacing(),
  },
}));

const Callout = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.component}>
      <div className={classes.icon}>{props.icon}</div>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

Callout.propTypes = {
  border: PropTypes.bool,
  icon: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Callout.defaultProps = {
  border: true,
};

export default Callout;
