import { useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "../../Common/Box/Box";
import Chart from "../../Common/Box/Chart";
import { chartDownloadImageScaleFactor } from "../../../constants";
import initialData from "../../../data/initialData.json";
import exportCharts from "../../../export";
import useCsvDownload from "../../../hooks/useCsvDownload";
import { getTooltip } from "../../../utilities";

const key = "FP_f5";
const number = 5;

const segmentColors = [
  "#de6746",
  "#f37447",
  "#f69877",
  "#f8b096",
  "#fddfd3",
  "#bfbfbf",
];

const labels = {
  lowIncome: [
    {
      text: "Ethiopia",
      x: 220,
      y: 120,
      color: "black",
    },
    {
      text: "DPR Korea",
      x: 242,
      y: 187,
      color: "black",
    },
    {
      text: "Uganda",
      x: 245,
      y: 245,
      color: "black",
    },
    {
      text: "Madagascar",
      x: 235,
      y: 310,
      color: "black",
    },
    {
      text: "DR Congo",
      x: 162,
      y: 320,
      color: "black",
    },
    {
      text: "Remaining<br />countries",
      x: 95,
      y: 168,
      color: "black",
    },
  ],
  lowMiddleIncome: [
    {
      text: "India",
      x: 230,
      y: 190,
      color: "black",
    },
    {
      text: "Bangladesh",
      x: 75,
      y: 310,
      color: "black",
    },
    {
      text: "Egypt",
      x: 35,
      y: 242,
      color: "black",
    },
    {
      text: "Viet Nam",
      x: 45,
      y: 275,
      color: "black",
    },
    {
      text: "Pakistan",
      x: 10,
      y: 211,
      color: "black",
    },
    {
      text: "Remaining<br />countries",
      x: 100,
      y: 130,
      color: "black",
    },
  ],
  upperMiddleIncome: [
    {
      text: "China",
      x: 230,
      y: 195,
      color: "black",
    },
    {
      text: "Brazil",
      x: 105,
      y: 260,
      color: "black",
    },
    {
      text: "Indonesia",
      x: 72,
      y: 212,
      color: "black",
    },
    {
      text: "Russia",
      x: 20,
      y: 145,
      color: "black",
    },
    {
      text: "Mexico",
      x: 70,
      y: 180,
      color: "black",
    },
    {
      text: "Remaining<br />countries",
      x: 113,
      y: 110,
      color: "black",
    },
  ],
};

const options = (data, labels) => (theme) => ({
  chart: {
    type: "pie",
    backgroundColor: null,
    height: 350,
    width: 380,
    events: {
      render: function () {
        const chart = this;

        for (let label of labels) {
          chart.renderer
            .label(label.text, label.x, label.y, null, null, null, true)
            .css({ color: label.color, textAlign: "center" })
            .attr({
              zIndex: 6,
            })
            .add();
        }
      },
    },
  },
  title: {
    text: data[0].name,
    align: "center",
  },
  plotOptions: {
    pie: {
      size: "95%",
      dataLabels: {
        enabled: false,
        distance: 10,
        style: {
          fontWeight: "normal",
        },
      },
    },
  },
  tooltip: {
    backgroundColor: "white",
    useHTML: true,
    outside: true,
    formatter: function () {
      return getTooltip("Users", this.y, this.percentage);
    },
    style: {
      fontSize: "0.875rem",
      color: theme.palette.tooltip.primary,
    },
  },
  exporting: {
    chartOptions: {
      title: {
        text: data[0].name,
      },
    },
    enabled: false,
  },
  series: [
    {
      name: data[0].name,

      data: data[0].data.map((item, i) => ({
        ...item,
        color: segmentColors[i],
      })),
    },
  ],
});

const useStyles = makeStyles((theme) => ({
  chart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  facetPanel: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  title: {
    color: theme.palette.title.chart,
    fontSize: "1.25rem",
  },
}));

const Component = () => {
  const highchartRefs = [useRef(), useRef(), useRef()];

  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleCsvDownload = () => {
    downloadCsv("/fp/figures/5/csv", `fp_f${number}.csv`);
  };

  const data = initialData["fp_f5"];

  return (
    <Box
      id={key}
      caption={`Figure ${number}`}
      title="Contraceptive Users"
      subtitle="Top Five Countries in Each Income Group, 2019"
      onDownloadCsv={handleCsvDownload}
      onDownloadPng={() => {
        exportCharts(
          {
            chart1: highchartRefs[0].current,
            chart2: highchartRefs[1].current,
            chart3: highchartRefs[2].current,
          },
          {
            filename: key,
            scale: chartDownloadImageScaleFactor,
          }
        );
      }}
    >
      <div className={classes.facetPanel}>
        <Chart
          highchartRef={highchartRefs[0]}
          options={options(data["fp_f5_a"], labels.lowIncome)}
        />
        <Chart
          highchartRef={highchartRefs[1]}
          options={options(data["fp_f5_b"], labels.lowMiddleIncome)}
        />
        <Chart
          highchartRef={highchartRefs[2]}
          options={options(data["fp_f5_c"], labels.upperMiddleIncome)}
        />
      </div>
    </Box>
  );
};

export default Component;
