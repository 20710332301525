import PropTypes from "prop-types";
import {
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import { makeStyles } from "@material-ui/styles";
import { ReactComponent as CsvIcon } from "../../../images/csv.svg";

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.box.download.primary,
    width: 24,
  },
}));

const DownloadMenu = (props) => {
  const classes = useStyles();

  return (
    <Menu
      anchorEl={props.anchorElement}
      open={props.open}
      onClose={props.onClose}
    >
      {props.onDownloadCsv && (
        <MenuItem onClick={props.onDownloadCsv}>
          <ListItemIcon>
            <CsvIcon className={classes.icon} />
          </ListItemIcon>
          <Typography variant="inherit">Download as CSV</Typography>
        </MenuItem>
      )}

      {props.onDownloadCsv && props.onDownloadPng && <Divider />}

      {props.onDownloadPng && (
        <MenuItem onClick={props.onDownloadPng}>
          <ListItemIcon>
            <ImageIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Download as PNG</Typography>
        </MenuItem>
      )}
    </Menu>
  );
};

DownloadMenu.propTypes = {
  anchorElement: PropTypes.object,
  open: PropTypes.bool,
  onDownloadCsv: PropTypes.func,
  onDownloadPng: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

DownloadMenu.defaultProps = {
  open: false,
};

export default DownloadMenu;
