import { Typography } from "@material-ui/core";
import RgMhT3 from "./RgMhT3";

const MaternalHealthQuantities = () => (
  <div>
    <Typography variant="h1">Drug Quantities</Typography>

    <p>
      The quantity is the amount of drugs that are needed for each intervention,
      multiplied by the number of cases receiving each intervention. While
      calculations are done separately by intervention, results are presented
      summed into cases that may include similar interventions that use the same
      drugs. While some variation can be expected in the actual provision of
      care, this analysis applies a normative approach to estimate the quantity
      of supplies to provide quality care.
    </p>

    <RgMhT3 />

    <p>
      The quantities presented in this work are different from procurement
      volumes, which may be higher or lower depending on context. Procurement
      needs are typically a function of broader programmatic considerations,
      such as availability (or lack thereof) of resources; the desire to fill
      supply pipelines and maintain adequate inventory levels, or simply the
      volumes of product already in the supply chain.
    </p>
  </div>
);

export default MaternalHealthQuantities;
