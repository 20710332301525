import { useState } from "react";
import PropTypes from "prop-types";
import { Collapse, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles({
  content: {
    fontSize: "0.875rem",
  },
  header: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
});

const CountryGroup = (props) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  return (
    <>
      <ListItem
        onClick={() => {
          setOpen((open) => !open);
        }}
      >
        <div className={classes.header}>
          {props.name} ({props.countries.length})
        </div>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ListItem>
          <div className={classes.content}>
            <p>{props.statement}</p>
            <p>{props.countries.join(", ")}</p>
          </div>
        </ListItem>
      </Collapse>
    </>
  );
};

CountryGroup.propTypes = {
  name: PropTypes.string.isRequired,
  statement: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CountryGroup;
