import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import BoxControls from "../../../Common/Box/BoxControls";
import ScenarioChooser from "../../../Common/Box/Choosers/ScenarioChooser";
import Figure from "../../../Common/Box/Figure";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import IndicatorChooser from "../../IndicatorChooser";
import PeriodChooser, { getPeriodDescription } from "../../PeriodChooser";
import {
  getIndicatorDetails,
  getSingular,
  getTooltip,
  toTitleCase,
} from "../../../../utilities";

const number = 4;

const getScenarioName = (scenarioId) => {
  switch (scenarioId) {
    case 5:
      return "1: Maintain Coverage";

    case 1:
      return "2: Increase Coverage";

    case 104:
      return "3: Increase Coverage and Scale-Up Emerging Drugs";

    default:
      return `Scenario ${scenarioId}`;
  }
};

const options = (data, props) => (theme) => {
  const percentage = props.periodId === 3;
  const financial = !percentage && props.indicatorId === 1;

  const series = [
    {
      data: data.map(({ value }) => value),
      color: theme.palette.areas.maternalHealth.primary,
    },
  ];

  return {
    chart: {
      type: "column",
      width: 800,
      height: 400,
      inverted: true,
      backgroundColor: null,
    },
    xAxis: {
      categories: data.map(({ region }) => region),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        formatter: function () {
          return (
            (financial ? "$" : "") +
            this.axis.defaultLabelFormatter.call(this) +
            (percentage ? "%" : "")
          );
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderWidth: 0,
        groupPadding: 0.1,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip(
          getPeriodDescription(props.periodId),
          this.y,
          undefined,
          {
            prefix: financial ? "$" : "",
            suffix: percentage ? "%" : "",
          }
        );
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const useStyles = makeStyles((theme) => ({
  controls: {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: "fit-content",
  },
}));

const Controls = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.controls}>
      <IndicatorChooser
        area="maternal-health"
        value={props.indicatorId}
        onChange={props.onIndicatorChange}
      />
      <PeriodChooser
        indicatorId={props.indicatorId}
        value={props.periodId}
        onChange={props.onPeriodChange}
      />
      <ScenarioChooser
        area="maternal-health"
        value={props.scenarioId}
        onChange={props.onScenarioChange}
      />
    </div>
  );
};

const MhCpF4 = (props) => {
  const [selectedPeriodId, setSelectedPeriodId] = useState(0);
  const [selectedScenarioId, setSelectedScenarioId] = useState(5);

  const { areaData: data } = useAreaQuery(
    `/custom/mh/cp/figures/4/${props.methodId}/${props.regionalUnitId}/${props.indicatorId}/${selectedPeriodId}/${selectedScenarioId}`
  );

  const selectedIndicatorDetails = getIndicatorDetails(props.indicatorId);

  const handlePeriodChange = (periodId) => {
    setSelectedPeriodId(periodId);
  };

  const handleScenarioChange = (scenarioId) => {
    setSelectedScenarioId(scenarioId);
  };

  return (
    <Figure
      id="MH_CP_f4"
      number={number.toString()}
      fileStem={`mh_cp_f${number}`}
      title={`Future ${selectedIndicatorDetails.qualifier} ${toTitleCase(
        props.methodName
      )}`}
      subtitle={`Scenario ${getScenarioName(
        selectedScenarioId
      )} by ${getSingular(props.regionalUnitName)}, ${toTitleCase(
        getPeriodDescription(selectedPeriodId)
      )}`}
      controls={
        <BoxControls>
          <Controls
            indicatorId={props.indicatorId}
            periodId={selectedPeriodId}
            scenarioId={selectedScenarioId}
            onIndicatorChange={props.onIndicatorChange}
            onPeriodChange={handlePeriodChange}
            onScenarioChange={handleScenarioChange}
          />
        </BoxControls>
      }
      chartOptions={
        data &&
        options(data, {
          indicatorId: props.indicatorId,
          indicatorName: selectedIndicatorDetails.qualifier,
          periodId: selectedPeriodId,
        })
      }
    />
  );
};

MhCpF4.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
  indicatorId: PropTypes.number.isRequired,
  onIndicatorChange: PropTypes.func,
};

export default MhCpF4;
