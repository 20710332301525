import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router-dom";
import ExternalLink from "../Common/ExternalLink";
import Footnote from "../Common/Footnote";
import Landscape from "../Common/Landscape";
import ScrollLink from "../Common/ScrollLink";
import SideBySide from "../Common/SideBySide";
import WithCallouts from "../Common/WithCallouts";
import { enableCustomReports } from "../../constants";
import { useReadersGuide } from "../../hooks/useReadersGuide";
import FpB1 from "./Boxes/FpB1";
import FpB2 from "./Boxes/FpB2";
import FpB3 from "./Boxes/FpB3";
import FpB4 from "./Boxes/FpB4";
import FpB5 from "./Boxes/FpB5";
import FpF1 from "./Figures/FpF1";
import FpF2 from "./Figures/FpF2";
import FpF3 from "./Figures/FpF3";
import FpF4 from "./Figures/FpF4";
import FpF5 from "./Figures/FpF5";
import FpF6 from "./Figures/FpF6";
import FpF7 from "./Figures/FpF7";
import FpF8 from "./Figures/FpF8";
import FpF9 from "./Figures/FpF9";
import FpF10 from "./Figures/FpF10";
import FpF11 from "./Figures/FpF11";
import FpF12 from "./Figures/FpF12";
import FpF13 from "./Figures/FpF13";
import FpF14 from "./Figures/FpF14";
import FpF15 from "./Figures/FpF15";
import FpF16 from "./Figures/FpF16";
import FpF17 from "./Figures/FpF17";
import FpF18 from "./Figures/FpF18";
import FpT1 from "./Tables/FpT1";
import FpT2 from "./Tables/FpT2";
import FpT3 from "./Tables/FpT3";
import FpT4 from "./Tables/FpT4";
import FpT10 from "./Tables/FpT10";

const useStyles = makeStyles((theme) => ({
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
  sectionTitle: {
    marginTop: 0,
    paddingTop: "3rem",
  },
  subtitle: {
    fontWeight: "bold",
    fontSize: "1.1rem",
    marginTop: "2rem",
  },
  customReports: {
    display: enableCustomReports ? "inline" : "none",
  },
}));

const Contraception = () => {
  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <Landscape>
      <section id="introduction">
        <Typography variant="h1" className={classes.sectionTitle}>
          Background
        </Typography>

        <div className={classes.boxes}>
          <WithCallouts
            area="Contraception"
            videoUrl="https://youtu.be/n9v1KUTjCk8"
          >
            <p>
              In the ten years since the London Family Planning Summit, the
              global community has witnessed a renewed focus on meeting the
              family planning needs of women and men in low- and middle-income
              countries. The call for &quot;universal access to sexual and
              reproductive health-care services, including family planning&quot;
              within the Sustainable Development Goals
              <Footnote number={1}>
                <ExternalLink
                  href="https://sdgs.un.org/goals/goal3"
                  text="Sustainable Development Goal 3.7"
                />
              </Footnote>{" "}
              has catalyzed efforts to improve access to high-quality and
              affordable contraceptives. The goal of achieving universal access
              will require commitment and investment on all fronts&mdash;and in
              particular the delivery of quality-assured contraceptives required
              to meet women&apos;s diverse needs and preferences. The type of
              contraceptive will be determined by the decisions of individual
              women and couples and/or by those entities that buy supplies on
              their behalf. And of course, the availability, affordability, and
              acceptability of contraceptives will also shape the future market
              for them. As with past Commodity Gap Analysis reports, this LEAP
              analysis provides rich findings to help inform discussions about
              the future of contraceptive supplies. This report does not hold
              all the answers but can help highlight challenges and spark
              discussions.
            </p>
          </WithCallouts>
          <p>
            We have updated our analysis with more recent data, but have done so
            with one notable change. This year, instead focusing on the
            &apos;gap&apos; we focus on how supply costs will change going
            forward. While useful to call attention to a &apos;gap&apos; for
            advocacy purposes, this gap was calculated based on the assumption
            that today&apos;s spending levels would be maintained in ten
            years&apos; time. But spending is not static, and so a focus on
            future gaps provides a false sense of the need.
          </p>
          <p>
            In LEAP we focus our analysis on the contraceptive supplies
            themselves. We recognize the importance of strong service delivery
            systems and behavior change interventions, but these costs are
            beyond the scope of this report. This work, therefore, should be
            considered alongside other analyses at both the global and country
            levels that take into account the full range of costs. Adding It Up
            <Footnote number={2}>
              Sully EA, Biddlecom A, Darroch JE, Riley T, Ashford LS,
              Lince-Deroche N et al{" "}
              <ExternalLink
                href="https://www.guttmacher.org/report/adding-it-up-investing-in-sexual-reproductive-health-2019"
                text="Adding It Up: Investing in Sexual and Reproductive Health 2019"
                useItalics={true}
              />{" "}
              New York: Guttmacher Institute, 2020.
            </Footnote>
            , for example, estimates the current cost and impact of delivering
            contraceptive services across low- and middle-income countries.
          </p>

          <FpB1 />
        </div>
      </section>

      <section id="analysis">
        <Typography variant="h1" className={classes.sectionTitle}>
          Analysis Approach
        </Typography>

        <p>
          This report utilizes the same approach as the 2019 Commodity Gap
          Analysis
          <Footnote number={3}>
            <ExternalLink href="https://www.rhsupplies.org/activities-resources/commodity-gap-analysis/" />
          </Footnote>
          , but updates the data sources to capture more recent changes. This
          report estimates current use of modern contraceptive supplies in
          low-income and middle-income countries and the cost of these supplies.
          It then projects how use and cost might change from 2019 to 2030.
        </p>
        <p>
          The analysis includes all modern methods of contraception, presented
          in the following seven categories:
        </p>

        <p>Long-acting and permanent methods</p>

        <ul>
          <li>Sterilization (male and female)</li>
          <li>Implants</li>
          <li>IUDs</li>
        </ul>

        <p>Short-acting methods</p>

        <ul>
          <li>Injectables</li>
          <li>Pills</li>
          <li>
            Condoms (male; for contraception only)
            <Footnote number={4}>
              LEAP only quantifies condoms used primarily for contraception;
              therefore, estimates do not represent the full condom market.
            </Footnote>
          </li>
          <li>
            Other
            <Footnote number={5}>
              &quot;Other&quot; methods of contraception include, where data are
              available, female condom, emergency contraception, Standard Days
              Method, LAM, spermicide, and other barrier methods.
            </Footnote>
          </li>
        </ul>

        <p>
          Our primary focus is on the quantity of contraceptive supplies{" "}
          <em>used</em> in a given year by contraceptive users. As noted
          earlier, this is not the same as the volume of supplies{" "}
          <em>procured</em> in a given year.
        </p>
        <p>
          Our analysis focused on 129 low- and middle-income countries (see the{" "}
          <Link
            underline="always"
            onClick={handleReadersGuideOpen("income-groups")}
          >
            Reader&apos;s Guide
          </Link>
          ). In 2019, China and Venezuela were excluded from the results, but
          have been included here. Some countries have shifted from one income
          group to another since the 2019 Commodity Gap Analysis was prepared.
          The results, therefore, cannot be compared to 2019 estimates.
        </p>
        <p>
          Results are disaggregated by country-level income group. For more
          information about the analysis, including data sources and the
          development of the scenarios for future projections, see the{" "}
          <Link
            underline="always"
            onClick={handleReadersGuideOpen("contraception-terms")}
          >
            Reader&apos;s Guide
          </Link>
          .
        </p>
      </section>

      <section id="current">
        <Typography variant="h1" className={classes.sectionTitle}>
          Where We Are Now
        </Typography>

        <div className={classes.boxes}>
          <Typography variant="h2" className={classes.sectionTitle}>
            Current Use of Contraception in Low- and Middle-Income Countries
          </Typography>

          <SideBySide component={<FpF1 />}>
            <div className={classes.subtitle}>Use by Method and Sector</div>

            <p>
              Currently there are 703 million contraceptive users living in low-
              and middle-income countries. Just over half (55%) of users are
              using short-acting methods, with condoms being the most commonly
              used method. Overall sterilization is the most widely used method.
              With 211 million users, this method accounts for 30% of the total
              method mix.
            </p>
            <p>
              Nearly six out of every 10 users obtain their method from the
              public sector. But there are stark differences in method mix
              between users who obtain their methods in the public versus
              private sectors. Within the public sector, 72% of users rely on
              long-acting and permanent methods. By contrast, in the private
              sector, most users (70%) use short-term methods such as pills,
              injectables, and condoms.
            </p>
          </SideBySide>

          <SideBySide component={<FpF2 />} componentPosition="right">
            <p>
              Contrasting the public and private sectors, method by method
              (Figure 2), provides a clearer picture of the distinct role each
              sector plays. The vast majority of users of sterilization,
              implants, and IUDs&mdash;all long-acting and permanent methods -
              received their method from the public sector. By contrast, the
              vast majority of pill and condom users get their methods from the
              private sector. Injectables are the one short-acting method more
              commonly received through the public sector, although the
              difference between sectors is less stark than for other methods
              (58% from the public sector, 42% from the private sector).
            </p>
          </SideBySide>

          <p>
            In Figure 2, and elsewhere in this report, the private sector is
            further subdivided based on the role of subsidy. Many of the
            products sold through private sector outlets are procured (in whole
            or in part) with public sector resources allowing them to be sold at
            below-market prices. Accounting for this public contribution is
            especially critical to understanding the relative contribution of
            public sector partners such as social marketing organizations (SMOs)
            which often, though not always, benefit from such subsidies as
            opposed to the purely commercial actors. Overall only a small share
            of private sector users are purchasing subsidized products in low-
            and middle-income countries. However, when viewing results by income
            group (as shown below) the difference in the role of subsidy becomes
            more pronounced (See Box 2).
          </p>

          <FpB2 />

          <p>
            The distinct roles of the public and private sector reflect, in
            part, differences in health infrastructure in each sector. In many
            countries the private sector is dominated by pharmacies and drug
            shops
            <Footnote number={9}>
              Bradley S, Shiras T. 2020{" "}
              <ExternalLink
                href="https://www.shopsplusproject.org/resource-center/sources-family-planning-36-countries"
                text="Sources for Family Planning in 36 Countries: Where Women Go and Why It Matters"
                useItalics={true}
              />{" "}
              Brief. Rockville, MD: Sustaining Health Outcomes through the
              Private Sector Plus Project, Abt Associates.
            </Footnote>{" "}
            that do not have the staff or facilities to provide a full range of
            methods, in particular long-acting and permanent methods. In some
            cases, policy restrictions contribute to these patterns, especially
            if affect the provision of injectables in pharmacies. There may also
            be financial barriers, both on the part of the client and the
            provider, that limit the provision of long-acting methods in the
            private sector. While these distinct patterns hold true across low-
            and middle-income countries; some countries may be an exception to
            this trend
            <span className={classes.customReports}>
              {" "}
              (explore country differences in the{" "}
              <RouterLink to="/custom">Custom Report: Compare</RouterLink>)
            </span>
            .
          </p>

          <div className={classes.subtitle}>Use by Income Group</div>

          <p>
            Reflecting differences in population size and contraceptive use
            across countries within each income group, 58% of contraceptive
            users live in upper-middle-income countries (411 million), 36% in
            lower-middle-income countries (256 million), and the remaining 5%
            live in low-income countries (35.2 million). A similar ordering is
            seen among both public sector and private sector users.
          </p>

          <FpT1 />

          <SideBySide component={<FpF3 />}>
            Most users in each income group get their method from the public
            sector; however the role of that sector diminishes from 72% within
            low-income countries to just 55% among upper-middle-income
            countries. The role of the subsidized private sector also
            diminishes, from 10% of all users (and 36% of private sector users)
            in low-income countries to 8% of users (and 22% of private sector
            users) in lower-middle-income countries, to a negligible role in
            upper-middle-income countries. This suggests the subsidies are well
            targeted to countries where users likely have the least ability to
            pay for services. See the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("contraception-users-by-sector")}
            >
              Reader&apos;s Guide
            </Link>{" "}
            for more information on the distinction between the subsidized and
            non-subsidized private sector.
          </SideBySide>

          <SideBySide component={<FpF4 />} componentPosition="right">
            There are pronounced differences in the method mix of contraceptive
            users in each country income group, with different methods
            accounting for the largest share in each group; injectables in
            low-income countries (38% of method mix), sterilization in
            lower-middle-income countries (44%), and IUDs in upper-middle-income
            countries (28%) (Figure 4). Upper-middle-income countries also have
            a more balanced method mix, with no single method dominating as is
            the case in low- and lower-middle-income countries. These
            differences become even more pronounced when looking at the public
            sector method mix; where the same three methods&mdash;injectables,
            sterilization and IUD&mdash;hold the largest share in each income
            group but to a larger degree. There are some differences in private
            sector method mix across the three income groups; however in all
            three the majority (68%-76%) of the private sector method mix is
            made up of just three methods: injectables, pills, and condoms. The
            relative contribution of each to the whole, however, varies;
            injectables account for the largest share in low-income countries;
            pills and condoms each account for a similar share in
            lower-middle-income countries, and condoms account for the largest
            share in upper-middle-income countries.
          </SideBySide>

          <p>
            Looking at contraceptive users across countries by country-level
            income group reveals interesting patterns. Among low-income
            countries, five countries are home to just over half of
            contraceptive users: Ethiopia, Democratic People&apos;s Republic of
            Korea, Uganda, Madagascar, and Democratic Republic of Congo. By
            contrast, in lower-middle-income and upper-middle-income countries a
            single country (India and China respectively) accounts for more than
            half the use of contraceptives in their respective income group, due
            primarily to the large population sizes of these two countries.
            <span className={classes.customReports}>
              {" "}
              Explore more country variation in the{" "}
              <RouterLink to="/custom">Custom Report: Compare</RouterLink>.
            </span>
          </p>

          <FpF5 />

          <Typography variant="h2">
            Current Cost of Contraceptive Supplies in Low- and Middle-Income
            Countries
          </Typography>

          <p>
            As noted previously, we define &quot;cost&quot; as the last price
            paid for a contraceptive method before it is actually used or
            obtained by the consumer. We employ this definition because it is at
            this &quot;handover&quot; point in a product&apos;s value chain
            where price acts as the principal cost barrier to access. For
            products made available through the public sector, cost is
            calculated on the basis of unit costs paid by procurers (governments
            and/or donors) before it is distributed, generally for free, to
            users. In the private sector, costs reflect the prices paid by users
            themselves, typically through direct out-of-pocket expenditures. The
            public sector cost also includes the value of the subsidy for
            subsidized products purchased by users in the private sector (see{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("contraception-costs")}
            >
              Reader&apos;s Guide
            </Link>
            ).
          </p>

          <SideBySide component={<FpT10 />}>
            Table 2 shows the average unit prices derived from our analysis;
            these reflect the prices paid in each country and the number of
            users paying each price point. Across all low- and middle-income
            countries we find that the private non-subsidized price is anywhere
            from two to 10 times higher than the public price. These private
            prices are expected to be higher, as they also include detailing and
            distribution costs. However, large price differentials may also
            reflect additional markups. The highest prices are consistently
            found in upper-middle-income countries.
          </SideBySide>

          <p>
            These costs reflect the unit cost per product; in the case of
            short-acting methods women will need multiple units over the course
            of the year (4 injectables, 14 pill cycles, and 77 condoms). By
            contrast, for long-acting and permanent methods only a share of
            users incur a cost in a given year as others are continuing to rely
            on a method they received in a previous year. This means the
            annualized costs for short-acting methods are higher than the unit
            costs shown above, while the annualized cost for long-term methods
            are lower. For example, the average annual cost of pills for users
            purchasing non-subsidized products in the private sector is $44.42.
            As will be seen below, private non-subsidized pills are a large
            driver of costs. Conversely, the average annual cost of an IUD for
            users purchasing non-subsidized products in the private sector is
            just $2.14. See the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("contraception-costs")}
            >
              Reader&apos;s Guide
            </Link>{" "}
            for more information.
          </p>

          <SideBySide component={<FpF6 />} componentPosition="right">
            <div className={classes.subtitle}>Cost by Method and Sector</div>

            <p>
              The total current cost of contraceptive supplies used by women and
              girls across low- and middle-income countries is $3.87 billion.
              The vast majority of this cost ($3.16 billion) comes from users
              purchasing their methods from private sector providers. This
              pronounced disparity is due to the preponderance of short-acting
              methods (which require frequent resupply and therefore greater
              volumes) in the private sector, coupled with the fact that private
              sector consumers often pay higher prices than public sector
              procurers for the same method. One short-acting method, pills, are
              the main driver of this pattern; accounting for more than three
              quarters of the private sector costs. Within the public sector,
              injectables ($186 million) and pills ($173 million) each account
              for around one-quarter of costs.
            </p>
          </SideBySide>

          <SideBySide component={<FpF7 />}>
            The costs of sterilization kits and implants are overwhelmingly
            incurred by the public sector (79% and 72%, respectively); by
            contrast the cost of pills and condoms are overwhelmingly borne by
            users purchasing supplies from the private sector (93% and 74%
            respectively). This pattern is driven both by differences in use by
            sector, as well as by price differentials between the public and
            private sector (see more below on{" "}
            <ScrollLink to="use-vs-cost">Use vs Cost</ScrollLink>).
          </SideBySide>

          <p>
            Within the private sector, subsidized supplies currently account for
            only 1% of supply costs. While this figure may seem small, it
            equates to $40.2 million. For many of the users purchasing
            subsidized supplies, especially those in the poorest countries,
            these products may be unaffordable without this subsidy. The role of
            subsidy is less pronounced in terms of costs as compared to use, due
            to the sometimes large price differentials between subsidized and
            non-subsidized products.
          </p>

          <div className={classes.subtitle}>Cost by Income Group</div>

          <p>
            By far the largest share of contraceptive supply costs are from
            upper-middle-income countries, which at a total cost of $3.01
            billion account for 78% of the total. This income group is even more
            dominant among private sector supply costs (84%), but accounts for a
            smaller share among public sector supply costs (50%). The current
            cost of contraceptive supplies used by women and girls in
            lower-middle-income countries is $703 million; nearly four and a
            half times higher than the cost in low-income countries ($159
            million).
          </p>

          <FpT2 />

          <SideBySide component={<FpF8 />} componentPosition="right">
            Within low-income countries public sector costs account for 56% of
            the total costs. The role of the public sector diminishes the higher
            the income level. It accounts for 38% of total costs in lower-middle
            income countries and just 12% in in upper-middle income countries.
            In absolute terms, however, public sector costs are lowest in
            low-income countries as only a small share of overall users and
            costs come from these countries. Costs incurred by users purchasing
            subsidized supplies from private providers account for a similar
            share in both low-income and lower-middle-income countries (4-5% of
            total). Costs from users purchasing non-subsidized supplies from the
            private sector dominate within upper-middle-income countries (88%).
            The $2.66 billion spent by users purchasing supplies from the
            private sector in upper-middle-income countries accounts for nearly
            70% of the total cost of contraceptive supplies across all low- and
            middle-income countries.
          </SideBySide>

          <SideBySide component={<FpF9 />}>
            <p>
              Pills account for the largest share of costs in each income group;
              however, the size of the share grows from 40% of the total in
              low-income countries to 58% in lower-middle-income countries and
              71% in upper-middle-income countries. In low-income countries
              injectables also account for a relatively large share of total
              costs (36%).
            </p>
            <p>
              Narrowing in on public sector costs reveals more variation. In
              low-income countries, injectables account for the largest share of
              public sector supply costs (55%). In lower-middle-income
              countries, pills account for the largest share of public sector
              supply costs (34%). And in upper middle-income countries public
              sector supply costs are relatively evenly split across condoms
              (21%), injectables (20%), and pills (20%). Within the private
              sector, pills dominate costs across all three income groups,
              accounting for between 72% and 78% of total costs.
            </p>
          </SideBySide>

          <p>
            While country-level income groups provide a useful lens to segment
            countries and understand how patterns differ by income level, the
            observed pattern in each of the three income groups are often driven
            by just a few large countries (see Figure 5). In addition, the
            countries that account for the largest share of use are not always
            the same as those that account for the largest share of costs.
            Amongst low-income countries only Ethiopia and Uganda are among the
            top five for both users and costs. Among lower-middle-income
            countries India still holds the largest share but its dominance is
            less pronounced; it accounts for only 28% of costs as compared to
            46% of users. Finally, in upper-middle-income countries, while China
            accounts for the largest share of use (48%), Brazil accounts for the
            largest share of costs (34%) within these countries. These
            differences are driven by differences in method mix, source mix, and
            unit prices at the country level (see{" "}
            <ScrollLink to="use-vs-cost">Use vs Cost</ScrollLink> below).
          </p>

          <FpF10 />

          <div id="use-vs-cost" className={classes.subtitle}>
            Use vs Cost
          </div>

          <p>
            The cost of contraceptive supplies is not simply a function of the
            number of contraceptive users. Rather it reflects differences in the
            methods used, the number of units required to achieve a year of
            protection, and the sector in which the methods are obtained.
            Understanding these differences is important when considering how to
            ensure equitable and affordable access to contraception for women
            and girls living across low- and middle-income countries. We examine
            the dynamics of use versus cost in three ways: by method, by sector,
            and by income group. As noted above, these are all interrelated
            given the distinct patterns of use found within each income group.
          </p>
          <p>
            Among all 129 low- and middle-income countries, long-acting and
            permanent methods account for 55% of use but just 10% of supply
            costs. This is due to the fact that many of these methods are low
            cost to begin with (such as the IUD) and because many users of these
            methods do not require an initial provision or resupply to maintain
            their protection over the course of the year. By contrast, pills
            account for just 14% of use but a striking 68% of supply costs. This
            is because pill users require a cycle each month, and because prices
            are often high, especially in the private sector where most pill
            users go for their supplies. These patterns lead to a similar
            picture when examining use versus cost by sector. The public sector
            accounts for 59% of use but just 18% of supply costs.
          </p>

          <SideBySide component={<FpF11 />}>
            <FpF13 />
          </SideBySide>

          <SideBySide component={<FpF12 />} componentPosition="right">
            The distinct role of the public and private sector as well as
            differences in method mix and prices means that the distribution of
            users by country-level income group looks different to that of
            supply costs. Lower-middle-income countries account for 36% of all
            users but their share of supply costs is only 18%. By contrast
            upper-middle-income countries account for only 58% of all users but
            78% of supply costs. As discussed above, the high supply cost in
            upper-middle-income countries is driven largely by users purchasing
            pills from the private sector. Were China to be excluded from our
            analysis, as it was in 2019, the results would be even starker.
            One-third of all users across the 129 low- and middle-income
            countries live in China, but they account for only 12% of supply
            costs. This pattern is also influences by Brazil, which despite only
            accounting for 5% of users, accounts for 27% of the total cost (see
            Box 3).
          </SideBySide>

          <FpB3 />
        </div>
      </section>

      <section id="future" className={classes.sectionTitle}>
        <Typography variant="h1">Changes Ahead</Typography>

        <div className={classes.boxes}>
          <p>
            In this LEAP analysis we explore a single future projection based on
            changes in modern contraceptive users developed by the UN Population
            Division and projected changes in method mix informed by recent
            surveys. These projected changes in contraceptive use also influence
            the demographic projections underlying the rest of the LEAP
            analysis, so having a single trend allows for aligned demographic
            projections across the analysis (see Box 4). A single future
            projection is also fitting to the unique complexities of
            contraceptive use dynamics. While there is a clear directionality of
            progress for many other areas of reproductive health (e.g. an
            increase in the safety of abortion, or an increase in the coverage
            of key maternal health services), contraceptive use is more complex.
            The scenarios for the other health areas within LEAP are built on
            countries &apos;progressing&apos; with the directionality being
            informed by the next highest income group. This concept does not
            easily apply to contraception as both levels of use and method mix
            are driven by a range of contextual factors.
          </p>

          <FpB4 />

          <p>
            We draw on the median-variant estimates of contraceptive users
            produced by the UN Population Division under the expectation that it
            provides the most likely trajectory forward in each country. We then
            layer in projected changes to method mix informed by recent patterns
            of change at the regional level (see the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("contraception-users-by-method")}
            >
              Reader&apos;s Guide
            </Link>
            ). Many factors will influence shifts in method mix and we do not
            purport to be predictive. Rather, we build on existing evidence and
            look at where we would be if recent patterns continue. This provides
            a starting point for discussions on what lies ahead. The further
            into the future our projections run, the less certainty we have;
            this uncertainty is represented graphically in the figures with a
            dotted line.
          </p>

          <Typography variant="h2">
            Future Use of Contraceptives in Low- and Middle-Income Countries
          </Typography>

          <p>
            Between 2019 and 2030, the total number of contraceptive users would
            increase by 11% (+75.3 million) reaching a total of 777.9 million
            users across low- and middle-income countries in 2030. If current
            patterns in the evolution of the method mix persist, the growth
            would not be seen evenly across all methods. The use of implants
            would see the most rapid growth in both absolute (+60.2 million) and
            relative (+213%) terms. By contrast, the number of users of
            sterilization would decline by 11% (-22.3 million). The remaining
            methods would see modest increases.
          </p>

          <FpF14 />

          <FpB5 />

          <SideBySide component={<FpF15 />}>
            Because gains and declines in the number of users of each method are
            relatively small when compared to the total number of users, the
            impact on method mix overall will be fairly minimal. Overall,
            long-acting and permanent methods would continue to account for just
            over half of use between 2019 and 2030; with the increases in
            implants largely offsetting the declines in sterilization. For the
            remaining methods very little change would be seen.
          </SideBySide>

          <p>
            The changes in the number of contraceptive users would also be
            uneven across country-level income groups, given changes in the
            number of women of reproductive age and their use of contraception.
            The total number of users in low-income countries would increase
            from 35.2 million in 2019 to 61.9 million in 2030, an increase of
            76%. Lower-middle-income countries would see the largest increase in
            absolute terms, increasing by 61.3 million to a total of 317.7
            million in 2030, though the relative increase (+24%) will be
            smaller. Finally, despite a slight decline (-3%) in users in
            upper-middle-income countries, this country-income group would
            continue to be home to the largest share of users. Their
            contribution would, however, decline from 58% of all users in 2019
            to 51% in 2030.
          </p>

          <FpF16 />

          <p>
            When exploring changes in method use by country income group some
            notable patterns emerge:
          </p>

          <ul>
            <li>
              Use of implants would likely grow rapidly across all country
              income spectrum: by +16.1 million (+242%) in low-income countries,
              by +28.7 million (+267%) in lower-middle-income countries, and by
              +15.4 million (+142%) in upper-middle-income countries. In fact,
              in low-income countries, implants would become the most commonly
              used method by 2030 (37% of method mix). Meanwhile, in
              lower-middle-income and upper-middle-income countries, implants
              would still continue to account for only a small share of the
              method mix (12% and 7% respectively).
            </li>
            <li>
              Differential patterns in condom use would also be evident across
              country income groups. In low-income countries the number of
              condom users would remain relatively unchanged. They would
              increase in lower-middle-income countries (+12.2 million, +39%)
              but decline in upper-middle-income countries (-13.5 million,
              -14%).
            </li>
            <li>
              Increases in injectable use would be concentrated in low-income
              countries. The total number of injectable users would grow by 14.8
              million across low- and middle-income countries, but nearly half
              (7.31 million) of this increase would come from low-income
              countries.
            </li>
          </ul>

          <FpT3 />

          <Typography variant="h2">
            Future Cost of Contraceptive Supplies in Low- and Middle-Income
            Countries
          </Typography>

          <p>
            As noted previously, we analyze &quot;cost&quot; based on the last
            price paid for a contraceptive method before it is actually used or
            obtained by the consumer. We employ this definition because it is at
            this &quot;handover&quot; point in a product&apos;s value chain
            where price acts as the principal cost barrier to access. For
            products made available through the public sector, cost is
            calculated on the basis of unit costs paid by procurers (governments
            and/or donors) before it is distributed, generally for free, to
            users. In the private sector, costs reflect the prices paid by users
            themselves, typically through direct out-of-pocket expenditures.
            Note that all costs are based on current supply costs, held constant
            for all years through to 2030.
          </p>
          <p>
            As with future use of contraceptives, we project the change in the
            total cost of contraceptive supplies from 2019 to 2030 across all
            low- and middle-income countries. If current trends continue, we
            anticipate there would be a modest 9% increase in the total cost,
            increasing from $3.87 billion in 2019 to $4.21 billion in 2030
            (+$340 million). Much of this increase would be attributable to the
            increased in the use of implants (+$253 million); however in overall
            terms implants would still account for only 10% of the total
            contraceptive supply cost in 2030 and a 11% share of the total
            method mix. Total costs for contraceptive supplies in 2030 would
            still be dominated by pills which, despite a small decline in total
            costs, would still account for 6 out of every 10 dollars.
          </p>

          <FpF17 />

          <p>
            The change in contraceptive supply costs would be uneven across
            income groups. In low-income countries, costs would grow from $159
            million in 2019 to $283 million in 2030, an increase of 78%. In
            lower-middle-income countries costs would top $939 million by 2030,
            an increase of 34%. Finally, in upper-middle-income countries costs
            would remain virtually unchanged between 2019 and 2030 (-$19.7
            million, -1%).
          </p>

          <FpF18 />

          <p>
            When exploring changes in contraceptive supply cost by country-level
            income group, some notable patterns emerge:
          </p>

          <ul>
            <li>
              Across all low-and middle-income countries, the costs of pills
              would decline slightly between 2019 and 2030; but this decline
              would be concentrated in upper-middle-income countries. Increases
              in pill costs would be seen in both low-income countries (+37.6
              million, +59%) and lower-middle-income countries (+82.7 million,
              +20%).
            </li>
            <li>
              The overall cost of implants would double in upper-middle-income
              countries, and triple in both low-income and lower-middle-income
              countries. While only 7% of total supply costs in 2030 would come
              from low-income countries, these same countries would account for
              nearly 20% of implant costs. This is due to implants accounting
              for a much larger share of the method mix in low-income countries.
            </li>
            <li>
              The overall cost of injectables would increase between 2019 and
              2030 in all three income groups, though the size and pace of the
              increase would vary. Upper-middle-income countries would see the
              largest increase in absolute terms (+$81.3 million); while
              low-income countries would see the greatest relative increase
              (+55%). The increase in costs in low-income countries (+$30.9
              million) would be nearly double the increase in
              lower-middle-income countries (+$15.5 million).
            </li>
          </ul>

          <FpT4 />
        </div>
      </section>

      <section id="conclusion">
        <Typography variant="h1" className={classes.sectionTitle}>
          What&apos;s Next
        </Typography>

        <p>
          This report updates results presented in the 2019 Commodity Gap
          Analysis, drawing from the most recently available data sources. This
          year&apos;s analysis is expanded to include China, a country whose
          contraceptive use shapes the overall picture of low-and middle-income
          countries as well as patterns within upper-middle-income countries.
          Nonetheless, the overarching findings remain similar between the two
          reports. As in the 2019 report, we continue to see pronounced
          differences in the role of the public and private sectors. We also
          continue to see more users getting their methods from public
          facilities. The methods women receive also continue to vary by sector;
          long-acting and permanent methods dominate provision in the public
          sector, while condoms and pills dominate in the private sector. Our
          results also continue to highlight the disproportionate role of
          out-of-pocket expenditure on contraceptive supplies, most notably
          pills, obtained from the private sector, especially in
          upper-middle-income countries. These sustained patterns provide a
          cautionary tale; as countries transition away from donor-funded
          supplies, mechanisms must be put into place to ensure affordable
          access to a wide range of contraceptives.
        </p>
        <p>
          Our findings also point to important differences across country income
          groups. Due to demographic changes and changes in contraceptive use,
          the total number of modern contraceptive users is projected to
          increase in low-income and lower-middle-income countries and stabilize
          or decrease slightly in upper-middle-income countries by 2030. Method
          choice is expected to evolve, depending on factors such as user
          preferences, community norms, method availability and affordability.
          Continuing recent patterns of change would lead to large increases in
          the use of implants; however the overall method mix is likely to
          remain similar across all low- and middle-income countries. Low-income
          countries would see the most change, both in terms of method mix and
          rate of increase in total users. Though, in absolute terms
          lower-middle-income countries would see the largest gains in users.
        </p>
        <p>
          Our community is at a critical moment: the year 2020 has passed, and
          eyes are now increasingly focusing on the world of 2030. Recent
          events, specifically COVID-19 and the sudden advent of donor funding
          cuts, have uncovered potential vulnerabilities in contraceptive supply
          chains. In the public sector, we continue to see high reliance on a
          few donors, a few manufacturers, and a few geographic priorities.
          Ensuring a stable supply of quality contraception in the future will
          require the diversification of financing, and potentially of
          manufacturers, to ensure that a wide-range of high quality and
          affordable contraceptives are available.
        </p>
        <p>
          As we look to 2030 and the goal of universal access to reproductive
          health services, it is critical we understand the current landscape of
          contraceptive supplies and how things may change in the future. The
          results presented in this report will help to inform these
          discussions.
        </p>
      </section>
    </Landscape>
  );
};

export default Contraception;
