import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import Information from "../../Common/Box/Information";

const MhB2 = () => (
  <Information
    key="MH_b2"
    number="2"
    title="The Impact of COVID-19 on Maternal Mortality"
  >
    <p>
      While pregnant women do not appear to be at greater risk of contracting
      COVID-19, those with symptomatic COVID-19 are at a greater risk of
      experiencing severe symptoms and adverse events
      <Footnote number={9}>
        Kotlar B, Gerson E, Petrillo S, Langer A, Tiemeier H{" "}
        <ExternalLink
          href="https://reproductive-health-journal.biomedcentral.com/articles/10.1186/s12978-021-01070-6"
          text="The impact of the COVID-19 pandemic on maternal and perinatal health: a scoping review"
          useItalics={true}
        />{" "}
        Reprod Health. 2021 Jan 18;18(1):10. PMID: 33461593; PMCID: PMC7812564.
      </Footnote>
      . Growing hesitation by the public to seek health care, coupled with
      restrictions on the movement of goods and services, and reductions in the
      availability of quality health care services means that COVID-19 could, at
      least indirectly, impact maternal mortality. One recent study has
      predicted an 8-39% increase in maternal mortality due to COVID-19 in low-
      and middle-income countries
      <Footnote number={10}>
        Roberton T, Carter ED, Chou VB, Stegmuller AR, Jackson BD, Tam Y,
        Sawadogo-Lewis T, Walker N{" "}
        <ExternalLink
          href="https://www.thelancet.com/journals/langlo/article/PIIS2214-109X(20)30229-1/fulltext"
          text="Early estimates of the indirect effects of the COVID-19 pandemic on maternal and child mortality in low-income and middle-income countries: a modelling study"
          useItalics={true}
        />{" "}
        Lancet Glob Health. 2020 Jul;8(7):e901-e908. Epub 2020 May 12. PMID:
        32405459; PMCID: PMC7217645.
      </Footnote>
      . While we acknowledge these potential impacts, the results presented here
      do not directly figure in the effects of COVID-19 on service coverage or
      health status.
    </p>
  </Information>
);

export default MhB2;
