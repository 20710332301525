import { Link, Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { useReadersGuide } from "../../../hooks/useReadersGuide";
import RgApacT1 from "./RgApacT1";

const AbortionPacServicesByType = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Total PAC Services by Type</Typography>

      <p>
        PAC services to treat an incomplete abortion or miscarriage may be
        provided surgically, using vacuum aspiration, or medically using
        misoprostol. The total number of PAC services included in LEAP are a
        function of the proportion of miscarriages that require and receive
        care, as well as the proportion of abortions that result in incomplete
        abortion that are treated. We sourced both the proportion of
        miscarriages requiring care by region, and the distribution of methods
        used for PAC services from the Adding it Up Methodology Report
        <Footnote number={22}>
          Riley T, Sully EA, Lince-Derroche N, Firestein L, Murro R, Biddlecom
          A, Darroch JE{" "}
          <ExternalLink
            href="https://www.guttmacher.org/sites/default/files/report_pdf/adding-it-up-investing-in-sexual-reproductive-health-2019-methodology.pdf"
            text="Adding It Up: Investing in Sexual and Reproductive Health 2019&mdash;Methodology Report"
            useItalics={true}
          />{" "}
          New York: Guttmacher Institute, 2020.
        </Footnote>
        .
      </p>
      <p>
        The proportion of abortions that result in incomplete abortion will vary
        based on the method used and the context of where the abortion takes
        places (e.g. by WHO classification of abortion safety (safe, less safe
        and least safe)). For LEAP, we developed estimates of the share of
        abortions that would be incomplete; these estimates varied by method and
        safety. These were informed by available literature
        <Footnote number={23}>
          Dahiya K, Ahuja K, Dhingra A, Duhan N, Nanda S{" "}
          <ExternalLink
            href="https://pubmed.ncbi.nlm.nih.gov/22009509/"
            text="Efficacy and safety of mifepristone and buccal misoprostol versus buccal misoprostol alone for medical abortion"
            useItalics={true}
          />{" "}
          Arch Gynecol Obstet. 2012 Apr;285(4):1055-8.
        </Footnote>
        <Footnote number={24}>
          Wong KS, Ngai CSW, Yeo ELK, Tang LCH,, Ho PC{" "}
          <ExternalLink
            href="https://doi.org/10.1093/humrep/15.3.709"
            text="A comparison of two regimens of intravaginal misoprostol for termination of second trimester pregnancy: a randomized comparative trial"
            useItalics={true}
          />{" "}
          Human Reproduction, Volume 15, Issue 3, March 2000, Pages 709–712.
        </Footnote>
        , and adjusted to match to other estimates of total PAC cases (Table 1).
      </p>

      <RgApacT1 />

      <p>
        We then calculated weighted averages of the share of abortions that
        would be incomplete in each safety group based on the method
        distribution. This was done for each year to account for future changes
        in the distribution of methods used within each safety profile (see{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-future-scenarios")}
        >
          Future Scenarios
        </Link>{" "}
        below); meaning in our projections the share of abortions that need PAC
        services varies over time. We assumed that a share of these incomplete
        abortions would require comprehensive EmOC services (which are not
        included in this report); and further adjusted to account for the fact
        that not all women will access PAC services. These two assumptions were
        aligned to the Adding It Up Methodology report
        <Footnote number={25}>
          Riley T, Sully EA, Lince-Derroche N, Firestein L, Murro R, Biddlecom
          A, Darroch JE{" "}
          <ExternalLink
            href="https://www.guttmacher.org/sites/default/files/report_pdf/adding-it-up-investing-in-sexual-reproductive-health-2019-methodology.pdf"
            text="Adding It Up: Investing in Sexual and Reproductive Health 2019&mdash;Methodology Report"
            useItalics={true}
          />{" "}
          New York: Guttmacher Institute, 2020.
        </Footnote>
        . Multiplying the incomplete abortion rate by the need for and coverage
        of treatment results in the percentage of abortions receiving included
        PAC services, which range from 3-4% of safe abortions and 14-25% of
        least safe abortions.
      </p>
    </div>
  );
};

export default AbortionPacServicesByType;
