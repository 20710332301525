import PropTypes from "prop-types";
import ExternalLink from "../../../Common/ExternalLink";
import Footnote from "../../../Common/Footnote";
import SideBySide from "../../../Common/SideBySide";
import MhCsF5 from "../Figures/MhCsF5";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeComparison,
  getRegionName,
  toLowerCase,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  const notReceived = data?.[0].methods.slice(-1)[0].services ?? 0;
  const lmicNotReceived = data?.slice(-1)[0].methods.slice(-1)[0].services ?? 1;

  return {
    methodShare: data?.[0].share ?? 0,
    notReceived,
    shortfallShare: (notReceived / lmicNotReceived) * 100,
  };
};

const MhCsS6 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/figures/1/${props.regionId}/111`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        Metronidazole is an antibiotic; while used to treat a range of
        infections; here we focus on its use to treat maternal infections which
        occur among roughly 7% of women during and immediately after childbirth
        <Footnote number={8}>
          The WHO Global Maternal Sepsis Study (GLOSS) Research Group{" "}
          <ExternalLink
            href="https://www.thelancet.com/journals/langlo/article/PIIS2214-109X(20)30109-1/fulltext"
            text="Frequency and management of maternal infection in health facilities in 52 countries (GLOSS): a 1-week inception cohort study"
            useItalics={true}
          />{" "}
          Lancet Glob Health, 8 (2020), pp. e661-e671.
        </Footnote>
        . In Figure 5, we present cases of maternal infections, separating each
        into cases where metronidazole was provided and those where it was not.
      </p>

      <SideBySide
        component={
          <MhCsF5
            regionId={props.regionId}
            regionName={props.regionName}
            regionShortName={getRegionName(props.regionId, "short")}
            data={data}
          />
        }
      >
        <p>
          Results are shown for {toLowerCase(props.regionName)} in comparison to
          the three country-level income groups.
        </p>

        <p>
          In {toLowerCase(props.regionName)}, metronidazole is given in{" "}
          {formatPercentage(metrics.methodShare)} of cases. This is{" "}
          {getQualitativeComparison(metrics.methodShare, 32)} low-income
          countries (32%) and{" "}
          {getQualitativeComparison(metrics.methodShare, 69)}{" "}
          upper-middle-income countries (69%).
        </p>

        <p>
          In {toLowerCase(props.regionName)}{" "}
          {formatNumberAsHumanReadable(metrics.notReceived)} cases do not
          receive the recommended drugs; this represents{" "}
          {formatPercentage(metrics.shortfallShare)} of the shortfall across all
          low- and middle-income countries.
        </p>
      </SideBySide>
    </>
  );
};

MhCsS6.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsS6;
