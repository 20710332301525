import { useMemo } from "react";
import PropTypes from "prop-types";
import { FormControl, FormHelperText, NativeSelect } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import countries from "../../data/countries.json";
import countryGroups from "../../data/countryGroups.json";

const createRegions = (options, order = []) =>
  options
    .map(({ id, longName, name }) => ({
      name: longName ?? name,
      value: id,
    }))
    .sort(
      (a, b) =>
        order.findIndex((id) => id === a.value) -
        order.findIndex((id) => id === b.value)
    );

const createRegionGroups = (area, excludeAllCountries, includeCountries) => {
  const items = [
    {
      name: "World Bank Regions",
      regions: createRegions(countryGroups.regional.worldBank, [
        157,
        147,
        143,
        138,
        152,
        134,
      ]),
    },
    {
      name: "UNPD Regions",
      regions: createRegions(countryGroups.regional.unpd, [
        135,
        146,
        154,
        162,
        148,
        131,
        132,
        151,
        137,
        156,
        133,
        155,
        153,
        136,
        163,
        149,
      ]),
    },
    {
      name: "Donor Priority",
      regions: createRegions(countryGroups.donorPriority),
    },
  ];

  if (true) {
    items.unshift({
      name: "Income groups",
      regions: createRegions(countryGroups.incomeGroup),
    });
  }

  if (!excludeAllCountries) {
    items.unshift({
      name: "135 LMI countries",
      regions: [
        {
          name: "Low- and Middle-Income Countries",
          value: 158,
        },
      ],
    });
  }

  if (includeCountries) {
    items.push({
      name: "Countries",
      regions: countries.map((country) => ({
        name: country.longName ?? country.name,
        value: country.id,
      })),
    });
  }

  return items;
};

const useStyles = makeStyles({
  component: {
    height: "fit-content",
  },
});

const RegionChooser = (props) => {
  const regionGroups = useMemo(
    () =>
      createRegionGroups(
        props.area,
        props.excludeAllCountries,
        props.includeCountries
      ),
    [props.area, props.excludeAllCountries, props.includeCountries]
  );

  const classes = useStyles();

  return (
    <FormControl>
      <NativeSelect
        value={props.value}
        onChange={(e) => {
          props.onChange?.(parseInt(e.target.value, 10));
        }}
        className={classNames(props.className, classes.component)}
      >
        {regionGroups.map((regionGroup) => (
          <optgroup key={regionGroup.name} label={regionGroup.name}>
            {regionGroup.regions.map((region) => (
              <option key={region.value} value={region.value}>
                {region.name}
              </option>
            ))}
          </optgroup>
        ))}
      </NativeSelect>
      <FormHelperText>
        {props.includeCountries
          ? "Choose the region or country for your report"
          : "Choose the region for your report"}
      </FormHelperText>
    </FormControl>
  );
};

RegionChooser.propTypes = {
  area: PropTypes.oneOf([
    "contraception",
    "menstrual-hygiene",
    "abortion",
    "maternal-health",
  ]),
  excludeAllCountries: PropTypes.bool,
  includeCountries: PropTypes.bool,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

RegionChooser.defaultProps = {
  excludeAllCountries: false,
  includeCountries: false,
  value: 145,
};

export default RegionChooser;
