import PropTypes from "prop-types";
import Box from "./Box";

const Table = (props) => <Box {...props} caption={`Table ${props.number}`} />;

Table.propTypes = {
  number: PropTypes.string.isRequired,
};

export default Table;
