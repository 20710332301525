import { useState } from "react";
import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import { capitalizePhrase, getTooltip } from "../../../../utilities";

const number = 4;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Surgical: Vacuum aspiration":
      return theme.palette.areas.abortion.methods.mva;

    case "Medical: Miso only":
      return theme.palette.areas.abortion.methods.miso;

    case "Medical: Miso & mife":
      return theme.palette.areas.abortion.methods.mife;

    case "Surgical: Dilation (not costed)":
      return theme.palette.areas.abortion.methods.dilation;

    case "Least safe abortions (not costed)":
      return theme.palette.areas.abortion.methods.leastSafeAbortions;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = data.map((method) => ({
    name: method.name,
    color: getSeriesColor(method.name, theme),
    data: method.scenarios.map((scenario) => scenario.services),
  }));

  return {
    chart: {
      type: "column",
      width: 600,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: [
        "1: Maintain safety profile & method mix",
        "2: Shift safety profile",
        "3: Shift safety profile & method mix",
      ],
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          textAlign: "center",
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        enabled: true,
        format: absolute ? undefined : "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: absolute ? "normal" : "percent",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Menstruators", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: {
          text: data.incomeGroup,
        },
      },
      enabled: false,
    },
    series,
  };
};

const ApacCsF4 = (props) => {
  const [comparisonMode, setComparisonMode] = useState("relative");

  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  return (
    <Figure
      id="APAC_CS_f4"
      number={number.toString()}
      fileStem={`apac_cs_f${number}`}
      title="Future Abortion and PAC Services by Method"
      subtitle={`${capitalizePhrase(
        props.regionName
      )} by Future Scenario, 2030`}
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        />
      }
      chartOptions={
        props.data &&
        options(props.data, {
          regionName: props.regionName,
          comparisonMode,
        })
      }
    />
  );
};

ApacCsF4.propTypes = {
  regionName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      scenarios: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          services: PropTypes.number.isRequired,
        })
      ),
    }).isRequired
  ),
};

export default ApacCsF4;
