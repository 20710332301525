import { useState } from "react";
import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SideBySide from "../../Common/SideBySide";
import CustomReport from "../CustomReport";
import DatasetButton from "../DatasetButton";
import { useReadersGuide } from "../../../hooks/useReadersGuide";
import RegionChooser from "../RegionChooser";
import { getCountryName, getRegionName } from "../../../utilities";
import MhmCsB1 from "./Boxes/MhmCsB1";
import MhmCsF1 from "./Figures/MhmCsF1";
import MhmCsF2 from "./Figures/MhmCsF2";
import MhmCsF3 from "./Figures/MhmCsF3";
import MhmCsF4 from "./Figures/MhmCsF4";
import MhmCsF5 from "./Figures/MhmCsF5";
import MhmCsChangeInMenstruators from "./Summaries/MhmCsChangeInMenstruators";
import MhmCsCurrentUse from "./Summaries/MhmCsCurrentUse";
import MhmCsCurrentCosts from "./Summaries/MhmCsCurrentCosts";
import MhmCsFutureCosts from "./Summaries/MhmCsFutureCosts";
import MhmCsFutureUseMenstruators from "./Summaries/MhmCsFutureUseMenstruators";
import FutureUseProducts from "./Summaries/MhmCsFutureUseProducts";
import MhmCsFutureCostsByMethod from "./Summaries/MhmCsFutureCostsByMethod";
import MhmCsT1 from "./Tables/MhmCsT1";
import MhmCsT2 from "./Tables/MhmCsT2";
import MhmCsT3 from "./Tables/MhmCsT3";
import MhmCsT4 from "./Tables/MhmCsT4";
import MenstrualHygieneIntroduction from "./MenstrualHygieneIntroduction";
import MenstrualHygieneScenarios from "./MenstrualHygieneScenarios";

const useStyles = makeStyles((theme) => ({
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
}));

const MenstrualHygieneLandscape = () => {
  const [selectedRegionId, setSelectedRegionId] = useState(158);

  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const selectedRegionName = getRegionName(selectedRegionId);
  const selectedRegionLongName = getRegionName(selectedRegionId, "long");

  const handleRegionChange = (regionId) => {
    setSelectedRegionId(regionId);
  };

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  const countrySelected = !!getCountryName(selectedRegionId);

  return (
    <CustomReport>
      <Typography variant="h1">
        Menstrual Hygiene Landscape for {selectedRegionName}
      </Typography>

      <RegionChooser
        includeCountries={true}
        value={selectedRegionId}
        onChange={handleRegionChange}
      />

      <section id="introduction">
        <Typography variant="h2">Background</Typography>

        <div className={classes.boxes}>
          <MenstrualHygieneIntroduction>
            The <strong>Menstrual Hygiene Custom Landscape</strong> provides
            estimates of the number of menstruators, the methods they use, and
            related costs for the selected country or region.
          </MenstrualHygieneIntroduction>

          <MhmCsB1 regionId={selectedRegionId} />
        </div>
      </section>

      <section id="current">
        <Typography variant="h2">Where We Are Now</Typography>

        <div className={classes.boxes}>
          <Typography variant="h6">
            Current Use of Menstrual Hygiene Products in{" "}
            {selectedRegionLongName}
          </Typography>

          <MhmCsCurrentUse
            countrySelected={countrySelected}
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhmCsF1
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
            regionShortName={getRegionName(selectedRegionId, "short")}
          />

          <Typography variant="h6">
            Current Costs of Menstrual Hygiene Products in{" "}
            {selectedRegionLongName}
          </Typography>

          <SideBySide
            component={
              <MhmCsF2
                regionId={selectedRegionId}
                regionName={selectedRegionLongName}
                regionShortName={getRegionName(selectedRegionId, "short")}
              />
            }
          >
            <MhmCsCurrentCosts
              countrySelected={countrySelected}
              regionId={selectedRegionId}
              regionName={selectedRegionLongName}
            />
          </SideBySide>
        </div>
      </section>

      <section id="future">
        <Typography variant="h2">Changes Ahead</Typography>

        <div className={classes.boxes}>
          <MenstrualHygieneScenarios />

          <p>
            See the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen(
                "menstrual-hygiene-future-scenarios"
              )}
            >
              Reader&apos;s Guide
            </Link>{" "}
            for more information about the scenarios.
          </p>

          <Typography variant="h6">
            Future Use of Menstrual Hygiene Products in {selectedRegionLongName}
          </Typography>

          <SideBySide
            component={
              <MhmCsF3
                regionId={selectedRegionId}
                regionName={selectedRegionLongName}
              />
            }
            componentPosition="right"
          >
            <MhmCsFutureUseMenstruators regionId={selectedRegionId} />
          </SideBySide>

          <SideBySide
            component={
              <MhmCsF4
                regionId={selectedRegionId}
                regionName={selectedRegionLongName}
              />
            }
          >
            <FutureUseProducts
              regionId={selectedRegionId}
              regionName={selectedRegionLongName}
            />
          </SideBySide>

          <MhmCsChangeInMenstruators regionId={selectedRegionId} />

          <MhmCsF5
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <Typography variant="h6">
            Future Costs of Menstrual Hygiene Products in{" "}
            {selectedRegionLongName}
          </Typography>

          <MhmCsFutureCosts
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhmCsFutureCostsByMethod
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />
        </div>
      </section>

      <section id="annex">
        <Typography variant="h2">Annex</Typography>

        <div className={classes.boxes}>
          <MhmCsT1
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhmCsT2
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhmCsT3
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <MhmCsT4
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <div>
            <DatasetButton
              area="Menstrual Hygiene"
              regionId={selectedRegionId}
            />
          </div>
        </div>
      </section>
    </CustomReport>
  );
};

export default MenstrualHygieneLandscape;
