import PropTypes from "prop-types";
import Figure from "../../../Common/Box/Figure";
import { getSingular, getTooltip } from "../../../../utilities";

const number = 2;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Surgical: Vacuum aspiration":
      return theme.palette.areas.abortion.methods.mva;

    case "Medical: Miso only":
      return theme.palette.areas.abortion.methods.miso;

    case "Medical: Mife":
      return theme.palette.areas.abortion.methods.mife;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = data.slice(0, -1).map((method) => ({
    name: method.name,
    data: method.regions.map(({ cost }) => cost),
    color: getSeriesColor(method.name, theme),
  }));

  return {
    chart: {
      type: "bar",
      width: 500,
      height: 400,
      backgroundColor: null,
      marginBottom: 70,
    },
    xAxis: {
      categories: data[0].regions.map(({ name }) => name),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      opposite: true,
      reversedStacks: false,
      max: absolute ? undefined : 100,
      labels: {
        formatter: function () {
          return absolute
            ? "$" + this.axis.defaultLabelFormatter.call(this)
            : `${this.value}%`;
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      bar: {
        stacking: absolute ? "normal" : "percent",
        borderWidth: 0,
        groupPadding: 0.1,
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      reversed: false,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Costs", this.y, this.percentage, {
          prefix: "$",
        });
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const ApacCcF2 = (props) => (
  <Figure
    id="APAC_CC_f2"
    number={number.toString()}
    title="Cost of Abortion and PAC Supplies by Method"
    subtitle={`By ${getSingular(props.regionalUnitName)}, 2019`}
    chartOptions={
      props.data?.length
        ? options(props.data, {
            comparisonMode: "absolute",
          })
        : undefined
    }
  />
);

ApacCcF2.propTypes = {
  regionalUnitName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      regions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          cost: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ),
};

export default ApacCcF2;
