import PropTypes from "prop-types";
import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router-dom";
import Information from "../Common/Box/Information";
import ExternalLink from "../Common/ExternalLink";
import Footnote from "../Common/Footnote";
import Landscape from "../Common/Landscape";
import ScrollLink from "../Common/ScrollLink";
import SideBySide from "../Common/SideBySide";
import WithCallouts from "../Common/WithCallouts";
import { useReadersGuide } from "../../hooks/useReadersGuide";
import MhmB1 from "./Boxes/MhmB1";
import MhmB2 from "./Boxes/MhmB2";
import MhmF1 from "./Figures/MhmF1";
import MhmF3 from "./Figures/MhmF3";
import MhmF4 from "./Figures/MhmF4";
import MhmF5 from "./Figures/MhmF5";
import MhmF6 from "./Figures/MhmF6";
import MhmF8 from "./Figures/MhmF8";
import MhmF9 from "./Figures/MhmF9";
import MhmF10 from "./Figures/MhmF10";
import MhmF11 from "./Figures/MhmF11";
import MhmF12 from "./Figures/MhmF12";
import MhmF13 from "./Figures/MhmF13";
import MhmT1 from "./Tables/MhmT1";
import MhmT2 from "./Tables/MhmT2";
import MhmT3 from "./Tables/MhmT3";
import MhmT4 from "./Tables/MhmT4";
import MhmT5 from "./Tables/MhmT5";

const useStyles = makeStyles((theme) => ({
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
  sectionTitle: {
    marginTop: 0,
    paddingTop: "3rem",
  },
}));

const MenstrualHygiene = ({ onOpenReadersGuide }) => {
  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <Landscape>
      <section id="introduction">
        <Typography variant="h1" className={classes.sectionTitle}>
          Background
        </Typography>

        <div className={classes.boxes}>
          <WithCallouts
            area="Menstrual Hygiene"
            deepDiveDescription="Deep dive into one product"
            videoUrl="https://youtu.be/fEnaq6vYz4c"
          >
            <p>
              Menstrual health and hygiene are important components of sexual
              and reproductive health and rights for women and girls. The
              ability to manage menstruation is linked to girls&apos; and
              women&apos;s ability to attend school, take part in society, work,
              and protect their health, including sexual and mental health.
              Menstrual health and hygiene are also linked to the fulfilment of
              girls&apos; and women&apos;s rights, and gender equality, as
              outlined in the Sustainable Development Goals (SDG), specifically
              SDG 6.2 which calls for access to adequate and equitable
              sanitation and hygiene for all
              <Footnote number={1}>
                <ExternalLink
                  href="https://www.sdg6monitoring.org/indicators/target-6-2/"
                  text="SDG6.2"
                  useItalics={true}
                />
                : By 2030, achieve access to adequate and equitable sanitation
                and hygiene for all and end open defecation, paying special
                attention to the needs of women and girls and those in
                vulnerable situations.
              </Footnote>
              . And yet, prior research shows that nearly a third of
              menstruators worldwide are unable to safely and effectively manage
              their menstruation
              <Footnote number={2}>
                UNICEF{" "}
                <ExternalLink
                  href="https://www.unicef.org/documents/guidance-menstrual-health-and-hygiene"
                  text="Guidance on Menstrual Health and Hygiene"
                  useItalics={true}
                />{" "}
                March 2019.
              </Footnote>
              <Footnote number={3}>
                Amaya L, Marcatalli J, Bhavaraju N{" "}
                <ExternalLink
                  href="https://www.fsg.org/publications/advancing-gender-equity-improving-menstrual-health"
                  text="Advancing Gender Equity by Improving Menstrual Health"
                  useItalics={true}
                />{" "}
                FSG. April 2020.
              </Footnote>
              .
            </p>
          </WithCallouts>

          <p>
            The field of menstrual health and hygiene covers the following key
            imperatives, each of which needs to be addressed for girls and women
            to be able to safely and effectively manage their menstruation:
          </p>

          <ol>
            <li>
              The need to ensure access to a range of high quality, affordable
              menstrual hygiene products, defined as any clean material used to
              absorb or collect menstrual blood;
            </li>
            <li>
              The need to increase knowledge of products and how to safely use
              them, as well as knowledge of menstrual cycles, fertility, and
              pain management;
            </li>
            <li>
              The need to expand access to private and clean sanitation
              facilities in homes, schools, workplaces, and public spaces, and;
            </li>
            <li>
              The need to reduce stigma and discriminatory attitudes around
              menstruation.
            </li>
          </ol>

          <SideBySide component={<MhmB1 />} componentPosition="right">
            <p>
              Menstrual hygiene products have different characteristics making
              them more or less suitable for individual menstruators. The
              decision of which product to use (assuming a choice is available)
              will depend on many factors, including:
            </p>

            <ul>
              <li>How much they cost and their availability;</li>
              <li>How frequently the products have to be changed;</li>
              <li>Whether or not they need to be used with underwear;</li>
              <li>Their safety and effectiveness, and;</li>
              <li>
                What&apos;s needed to change and dispose of them (e.g. water, a
                place for washing and drying, ability to store, and waste
                management).
              </li>
            </ul>
          </SideBySide>

          <p>
            Purpose-made menstrual hygiene products (see Box 2) are generally
            safe, but there are risks of infection with poor hygiene and
            extended wear time, and tampons have a small risk of Toxic Shock
            Syndrome
            <Footnote number={5}>
              UNICEF{" "}
              <ExternalLink
                href="https://www.unicef.org/documents/guide-menstrual-hygiene-materials"
                text="Guide to Menstrual Hygiene Materials"
                useItalics={true}
              />{" "}
              May 2019.
            </Footnote>
            .
          </p>

          <MhmB2 onOpenReadersGuide={handleReadersGuideOpen} />

          <p>
            To ensure menstruators can choose and effectively use their
            preferred product(s), it is crucial to ensure the availability of a
            range of affordable products. In recent years there has been some
            progress in increasing access to menstrual hygiene products.
            However, there is still a lack of access in low-income countries
            especially, and in particular in rural areas where the available,
            affordable products are often of poor quality
            <Footnote number={6}>
              Amaya L, Marcatalli J, Bhavaraju N{" "}
              <ExternalLink
                href="https://www.unicef.org/documents/guide-menstrual-hygiene-materials"
                text="Advancing Gender Equity by Improving Menstrual Health"
                useItalics={true}
              />{" "}
              FSG. April 2020.
            </Footnote>
            . The existing research and available data around menstrual health
            and hygiene is limited but growing{" "}
            <Footnote number={7}>
              UNICEF{" "}
              <ExternalLink
                href="https://www.unicef.org/documents/guidance-menstrual-health-and-hygiene"
                text="Guidance on Menstrual Health and Hygiene"
                useItalics={true}
              />{" "}
              March 2019.
            </Footnote>
            <Footnote number={8}>
              UNICEF{" "}
              <ExternalLink
                href="https://www.unicef.org/documents/guide-menstrual-hygiene-materials"
                text="Guide to Menstrual Hygiene Materials"
                useItalics={true}
              />{" "}
              May 2019.
            </Footnote>
            <Footnote number={9}>
              Amaya L, Marcatalli J, Bhavaraju N{" "}
              <ExternalLink
                href="https://www.fsg.org/publications/advancing-gender-equity-improving-menstrual-health"
                text="Advancing Gender Equity by Improving Menstrual Health"
                useItalics={true}
              />{" "}
              FSG. April 2020.
            </Footnote>
            <Footnote number={10}>
              PSI{" "}
              <ExternalLink
                href="https://www.psi.org/2021/01/making-the-case-for-investing-in-menstrual-health-and-hygiene-introducing-a-new-standard/"
                text="Making the Case for Investing in Menstrual Health and Hygiene"
                useItalics={true}
              />{" "}
              January 2021.
            </Footnote>
            . Some global surveys have recently included relevant questions
            related to menstrual health and hygiene and work is underway to
            identify key global metrics that can be used sector-wide{" "}
            <Footnote number={11}>
              Sommer M, Zulaika G, Schmitt M, Gruer C. (Eds.){" "}
              <ExternalLink
                href="https://menstrualhygieneday.org/wp-content/uploads/2019/08/green_paper_monitoring_menstrual_health_and_hygiene.pdf"
                text="Monitoring Menstrual Health and Hygiene: Measuring Progress for Girls on Menstruation; Meeting Report"
                useItalics={true}
              />{" "}
              March 2019.
            </Footnote>
            .
          </p>

          <p>
            We believe this report represents the first time that menstrual
            hygiene products have been explored in such detail. As more datasets
            include menstrual health and hygiene indicators, and standardized
            indicators are developed, LEAP will be able to provide greater depth
            of analysis and make projections with more certainty.
          </p>
        </div>
      </section>

      <section id="analysis">
        <Typography variant="h1" className={classes.sectionTitle}>
          Analysis Approach
        </Typography>

        <div className={classes.boxes}>
          <p>
            Building on the approach developed and refined for the 2019
            Contraceptive Commodity Gap Analysis{" "}
            <Footnote
              number={12}
              href="https://www.rhsupplies.org/activities-resources/commodity-gap-analysis/"
            />
            , this report draws on a range of data sources to estimate for all
            low- and middle-income countries:
          </p>

          <ul>
            <li>Current use of menstrual hygiene products;</li>
            <li>The cost to menstruators of the products used, and;</li>
            <li>
              How use and cost of menstrual hygiene products might change from
              2019 to 2030. We used 2019 as our &apos;current&apos; scenario
              because 2020 was atypical due to the COVID pandemic, and created
              projections to model different scenarios.
            </li>
          </ul>

          <p>
            To project the number of women menstruating, we accounted for the
            impact of contraceptive use (see Box 3, below), pregnancy and the
            postpartum period on women&apos;s menses&mdash;for the purpose of
            this analysis pregnant and postpartum amenorrheic women are not
            counted as menstruators. Our analysis focused on the main
            purpose-made products to manage menstrual hygiene (i.e. disposable
            pads and tampons, and reusable pads and cups). We did not include
            supportive items to manage menstruation, such as panty-liners, body
            and laundry soap, underwear, and pain relief.
          </p>

          <p>
            The costs presented in LEAP reflect the supplies used by individuals
            in a given year, not what is necessarily bought or
            &apos;procured&apos; in that year, as the latter, often referred to
            as &apos;procurement costs&apos;, are typically a function of
            broader programmatic considerations, such as availability (or lack
            thereof) of resources; the desire to fill supply pipelines and
            maintain adequate inventory levels, or simply the volumes of product
            already in the supply chain.
          </p>

          <p>
            Our analysis focused on 129 low- and middle-income countries (see
            the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("income-groups")}
            >
              Reader&apos;s Guide
            </Link>
            ) and included menstruators aged 12-49. Results are disaggregated by
            country-level income group. For more information about the analysis,
            including data sources and the development of the scenarios for
            future projections, see the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("menstrual-hygiene-scope")}
            >
              Reader&apos;s Guide
            </Link>
            .
          </p>
        </div>
      </section>

      <section id="current">
        <Typography variant="h1" className={classes.sectionTitle}>
          Where We Are Now
        </Typography>

        <div className={classes.boxes}>
          <Typography variant="h2">
            Current Use of Menstrual Hygiene Products in Low- and Middle-Income
            Countries
          </Typography>

          <SideBySide component={<MhmT1 />}>
            Currently there are an estimated 1.67 billion menstruators across
            low- and middle-income countries. Reflecting population size and
            fertility rates within each income group, 765 million menstruators
            live in lower-middle-income countries (46%), 752 million in
            upper-middle-income countries (45%), and the remaining 156 million
            in low-income countries (9%).
          </SideBySide>

          <SideBySide component={<MhmF1 />} componentPosition="right">
            <p>
              The vast majority (90%) of menstruators in upper-middle-income
              countries use products that are purpose-made for menstrual hygiene
              management, compared to 62% in lower-middle-income countries, and
              39% low-income countries. This indicates that, in most cases,
              menstruators in low-income countries are using a product not
              specifically designed for menstrual hygiene management; products
              for which the quality, safety and reliability cannot be ensured.
            </p>
            <p>
              Among menstruators using purpose-made menstrual hygiene products,
              the current market is massively dominated by disposable products
              (pads and tampons) rather than reusable products. Reusable
              products make up a very small proportion of product use at this
              time. Due to limited data, for the purpose of the analysis we
              assumed 5% of menstruators who use purpose-made menstrual hygiene
              products, use reusable products, based on analysis of available
              surveys and in consultation with experts. Our analysis of future
              scenarios explored the possible impact on use and cost of
              menstrual hygiene products if use of reusable products in low- and
              middle-income countries increased (see Changes Ahead below).
            </p>
          </SideBySide>

          <p>
            Looking at use of purpose-made products across countries by
            country-level income group reveals interesting patterns. Among
            low-income countries, five countries make up more than half of the
            use of purpose-made menstrual hygiene products: Ethiopia, Democratic
            Republic of the Congo, Uganda, Sudan, and Democratic People&apos;s
            Republic of Korea. By contrast, in both lower-middle-income and
            upper-middle-income countries a single country (India and China
            respectively) accounts for nearly half the use of purpose-made
            menstrual hygiene products in their respective income group, due
            primarily to the large population sizes of these two countries.
          </p>

          <MhmF3 />

          <Typography variant="h2">
            Current Costs of Menstrual Hygiene Products in Low- and
            Middle-Income Countries
          </Typography>

          <SideBySide component={<MhmT2 />}>
            We analyzed the cost of menstrual hygiene products from the
            perspective of the price a user would pay for a product, focusing
            only on purpose-made products. To estimate the unit price for
            disposable products, we used country-specific data from a limited
            number of countries where data were available, and regional averages
            elsewhere (see the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("menstrual-hygiene-costs")}
            >
              Reader&apos;s Guide
            </Link>
            ). Note that this lack of country-level data limits our ability to
            identify differences between countries within region. Some slight
            variation in the cost of disposable products can be seen in unit
            prices by region. The lowest prices are seen in Asia, likely due to
            the presence of large manufacturers in those countries. For reusable
            products, due to small markets and limited data, we used global unit
            prices: $2 per reusable pad and $10 per cup. For more detail see the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("menstrual-hygiene-costs")}
            >
              Reader&apos;s Guide
            </Link>
            .
          </SideBySide>

          <SideBySide component={<MhmT3 />} componentPosition="right">
            We calculated annualized costs to more accurately reflect the true
            out-of-pocket cost for menstruators. For reusable products we
            assumed menstruators need five reusable pads a year (to be replaced
            each year), and that they can use a cup for an average of five
            years. For disposable products we assumed menstruators need 154
            disposable products per year (either pads or tampons), but also
            account for the fact that some menstruators do not use disposable
            methods exclusively, as well as for reduced menstruation due to use
            of hormonal contraception (see Box 3). See the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("menstrual-hygiene-quantities")}
            >
              Reader&apos;s Guide
            </Link>{" "}
            for more detail. Despite higher upfront costs, over the course of a
            year, reusable products, in particular reusable cups, are
            significantly cheaper for menstruators than disposable products.
          </SideBySide>

          <Information
            key="MHM_b3"
            number="3"
            title="The Impact of Hormonal Contraceptive Use on Menstrual Hygiene Product Use and Costs"
          >
            <p>
              On average, menstruators who use hormonal contraceptive methods
              (including implants, injectables, and pills) experience reduced
              menstruation, due to shorter, lighter, and less frequent periods.
              They thus have a reduced need for menstrual hygiene products,
              which translates on average to reduced costs.
            </p>
            <p>
              Due to the 198 million menstruators currently using hormonal
              contraception, in 2019 an estimated 12.2 billion fewer disposable
              menstrual hygiene products were used than if they had been using
              non-hormonal contraception. This translates to $2.03 billion less
              spent on these products, or a 7% reduction in the cost of
              disposable products in 2019.
            </p>
            <p>
              Use of hormonal contraceptives is expected to continue to
              increase, reaching 279 million by 2030 if current trends continue
              (see the{" "}
              <RouterLink to="/contraception">
                Contraception Landscape Report
              </RouterLink>
              ). We included this projected use of hormonal contraceptives in
              our analysis of future use and costs (see{" "}
              <ScrollLink to="future">Changes Ahead</ScrollLink> below). This
              additional use of hormonal contraceptives would offset the
              projected increases in use of purpose-made menstrual hygiene
              products, leading to between 175 and 199 billion fewer disposable
              products used cumulatively from 2019 to 2030, with cost savings to
              menstruators of between $29.1 and $32.5 billion over these 12
              years.
            </p>
            <p>
              See the{" "}
              <Link
                underline="always"
                onClick={handleReadersGuideOpen("menstrual-hygiene-quantities")}
              >
                Reader&apos;s Guide
              </Link>{" "}
              for more detail on how we account for use of hormonal
              contraceptive methods in the analysis.
            </p>
          </Information>

          <SideBySide component={<MhmF4 />}>
            Total cost of purpose-made menstrual hygiene products differs
            significantly across income groups, based on the varying use and
            price of products in each group. The total current cost to
            menstruators of purpose-made products across low- and middle-income
            countries is $28.0 billion, of which the vast majority ($17.7
            billion) is spent in upper-middle-income countries. This is almost
            double the total cost in lower-middle-income countries ($9.09
            billion), and 14 times the cost in low-income countries ($1.25
            billion). Almost all product costs are for disposable products
            (98-99%), due to the very small proportion of reusable purpose-made
            products, coupled with the lower annualized costs of reusable
            products.
          </SideBySide>

          <Information
            key="MHM_b4"
            number="4"
            title="Partial Use of Purpose-Made Menstrual Hygiene Products"
          >
            Some menstruators using purpose-made products may not be using
            enough to fully meet their needs. Based on available survey data, we
            estimate that 9% of those using disposable products are
            supplementing with products not specifically made for managing
            menstruation, such as cloth. This share varies from 4% of
            menstruators in upper-middle-income countries to 19% in low-income
            countries. For these menstruators, we assumed they used fewer
            disposable products and thus have a lower cost associated with their
            use. See the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("menstrual-hygiene-quantities")}
            >
              Reader&apos;s Guide
            </Link>{" "}
            for more detail.
          </Information>

          <SideBySide component={<MhmF5 />} componentPosition="right">
            The higher total cost to menstruators in upper-middle-income
            countries is a function of the relatively higher share of
            menstruators using purpose-made products (compared to other income
            groups) and the higher prices of those products. Although
            upper-middle-income countries account for 45% of menstruators, they
            account for 63% of the total cost of menstrual hygiene products.
            This is due to the much higher (near universal) use of purpose-made
            menstrual hygiene products in this income group (see Figure 1),
            combined with higher unit prices for disposable products in many
            upper-middle-income countries.
          </SideBySide>

          <p>
            We also looked at differences in the total cost to menstruators
            within income groups and found similar patterns to use of products
            (see above). Total cost of menstrual hygiene products is also
            dominated by a small number of countries within each income group.
            Within low-income countries, just over half of costs are
            concentrated in five countries: Ethiopia, Democratic Republic of the
            Congo, Uganda, Sudan and Yemen. By contrast, in both
            lower-middle-income and upper-middle-income countries a single
            country (India and China, respectively) accounts for just over
            one-third of the cost of menstrual hygiene products in these groups.
            The predominance of India and China is less pronounced in terms of
            cost than it is in terms of use of menstrual hygiene products
            primarily due to the lower cost of products in these countries as
            compared to other countries in the same income group.
          </p>

          <MhmF6 />
        </div>
      </section>

      <section id="future">
        <Typography variant="h1" className={classes.sectionTitle}>
          Changes Ahead
        </Typography>

        <div className={classes.boxes}>
          <p>
            Looking ahead, based on demographic changes, changes in
            contraceptive use, and changes in fertility rates, the overall
            number of menstruators is likely to increase. In addition, there are
            likely to be shifts in the proportions of menstruators using a)
            purpose-made products and b) reusable products. However, changes in
            the number of menstruators using purpose-made and reusable products
            are impossible to accurately predict due in part to a lack of
            historical data.
          </p>

          <p>
            For the analysis of possible future trends presented in this
            section, we developed three scenarios:
          </p>

          <ul>
            <li>Scenario 1: Maintain Product Use</li>
            <li>Scenario 2: Increase Purpose-Made Use</li>
            <li>Scenario 3: Increase Purpose-Made and Reusable Use</li>
          </ul>

          <p>
            See Box 5 for a full explanation of these scenarios. In all three
            scenarios, the increase in the number of menstruators by 2030
            remains the same, based on projected changes in population,
            contraceptive use, and fertility rates; but the uptake of
            purpose-made and reusable menstrual hygiene products differs.
            Increased use of purpose-made products in Scenarios 2 and 3 is based
            on current patterns of use within the next highest income group.
            Increased use of reusable products in Scenario 3 is an ambitious
            goal of 25% of all menstruators who use purpose-made products using
            reusable products, to show potential growth in this market over
            time.
          </p>

          <p>
            These scenarios are not intended to be predictive but show what
            would happen if these levels of purpose-made and reusable product
            use were achieved. The further into the future our projections run,
            the less certainty we have; this uncertainty is represented in the
            Figures with a dotted line.
          </p>

          <Information key="MHM_b5" number="5" title="Future Scenarios">
            <p>
              <strong>Scenario 1: Maintain Product Use.</strong> This scenario
              reflects demographic changes and changes in contraceptive uptake
              and method mix (based on existing trend data) but does not change
              the proportion of menstruators using purpose-made products or
              reusable products in each income group. In fact, we do believe
              that the proportion of menstruators using purpose-made and
              reusable products will change, but we have kept it constant so
              that this scenario can provide a baseline for comparison.
            </p>
            <p>
              <strong>Scenario 2: Increase Purpose-Made Use.</strong> This
              scenario reflects the same demographic changes and changes in
              contraceptive use as Scenario 1, but adjusts for increased use of
              purpose-made products by matching the proportion of menstruators
              using purpose-made products in each income group to patterns seen
              in the next highest income group within the same region.
              Upper-middle-income countries were shifted to the average for
              upper-middle-income Europe and Central Asia countries, which have
              the highest values within that income group.
            </p>
            <p>
              <strong>
                Scenario 3: Increase Purpose-Made and Reusable Use.
              </strong>{" "}
              This scenario maintains the same demographic, contraceptive use
              and product-type changes as in Scenario 2 but reflects greater use
              of reusable products, set to reach 25% of those using purpose-made
              products by 2030 (increasing from 5% in Scenario 1 and Scenario
              2). This scenario would also result in reduced environmental
              impact of disposable products (see Box 6).
            </p>
            <p>
              See the{" "}
              <Link
                underline="always"
                onClick={handleReadersGuideOpen(
                  "menstrual-hygiene-future-scenarios"
                )}
              >
                Reader&apos;s Guide
              </Link>{" "}
              for more detail about the development of these scenarios.
            </p>
          </Information>

          <Typography variant="h2">
            Future Use of Menstrual Hygiene Products in Low- and Middle-Income
            Countries
          </Typography>

          <p>
            In all scenarios, the total number of menstruators (users of
            purpose-made and non-purpose-made products), would increase by 36%
            between 2019 and 2030 in low-income countries (+56.1 million), and
            by 13% in lower-middle-income countries (+101 million). The number
            would decrease slightly by 2030 in upper-middle-income countries
            (-3%, -20.1 million), driven by the expected decline in the number
            of women of reproductive age in these countries.
          </p>

          <MhmT4 />

          <p>
            With an increased use of purpose-made products alone, or combined
            with an increase in use of reusable products (Scenarios 2 and 3),
            the projected product profile among menstruators would be
            dramatically different in low-income and lower-middle-income
            countries by 2030 (Figure 6). In low-income countries, the share of
            menstruators using purpose-made products would be 40% in Scenario 1,
            compared to 71% in Scenarios 2 and 3. In lower-middle-income
            countries this proportion would be 63% in Scenario 1 and 92% in
            Scenarios 2 and 3. However, in upper-middle-income countries only a
            small difference in use of purpose-made products (90% to 96%) would
            be seen, due to the already high proportion of menstruators using
            these products.
          </p>

          <p>
            In Scenarios 1 and 2, reusable products would continue to account
            for a small percentage of overall use by 2030. But increasing the
            proportion of purpose-made and reusable products as per Scenario 3
            would yield 18% of menstruators in low-income countries using
            reusable products by 2030, compared to 23% in lower-middle-income
            countries, and 24% in upper-middle-income countries. The resulting
            decrease in use of disposable products would also reduce the
            environmental impact of menstrual hygiene products (see Box 6).
          </p>

          <MhmF9 />

          <Information
            key="MHM_b6"
            number="6"
            title="The Environmental Impact of Menstrual Hygiene Products"
          >
            <p>
              Disposable menstrual hygiene products have a significant
              environmental impact. The production of the plastic components is
              highly resource-intensive and the products themselves, whether
              disposed in landfills or flushed down the toilet, pollute
              waterways and sewers. Reusable products offer a more
              environmentally-friendly alternative, but are not always feasible
              for people without easy access to water and sanitation facilities.
              Although there has been some innovation around products made of
              locally available natural materials (such as bamboo or banana
              fiber), some of the products marketed as being biodegradable or
              compostable require very specific facilities for composting or
              recycling, which often do not exist in many countries
              <Footnote number={13}>
                Amaya L, Marcatalli J, Bhavaraju N{" "}
                <ExternalLink
                  href="https://www.fsg.org/publications/advancing-gender-equity-improving-menstrual-health"
                  text="Advancing Gender Equity by Improving Menstrual Health"
                  useItalics={true}
                />{" "}
                FSG. April 2020.
              </Footnote>
              .
            </p>
            <p>
              Our analysis demonstrates the potential impact of an increase in
              reusable products on the number of disposable products that would
              be needed in low- and middle-income countries. In the scenario
              maintaining product use (Scenario 1), demographic and
              contraceptive use changes alone would necessitate 2,540 billion
              pads and tampons between 2019 and 2030. Projecting an increase in
              the proportion of menstruators using purpose-made products
              (Scenario 2) would increase the number needed by 327 billion above
              Scenario 1 to a total of 2,867 billion. In contrast, factoring in
              a shift to 25% of menstruators who use purpose-made products using
              reusable products (Scenario 3) would increase the number of
              disposable products needed from Scenario 1 by 47 billion, to a
              total of 2,587 billion, or 280 billion fewer disposable products
              compared to Scenario 2.
            </p>
          </Information>

          <p>
            Different trends in use of purpose-made products by menstruators can
            be seen in each income group between 2019 and 2030 (Figure 7). In
            low-income countries, the number of menstruators using purpose-made
            products would increase even if there were no change in the product
            use profile (Scenario 1). The number of users of purpose-made
            products would increase from 60.9 million in 2019 to 84.9 million in
            2030, an increase of 39% (+24.0 million). The increase is even
            greater if a growth in purpose-made products is factored in
            (Scenario 2) (+89.5 million, +147%). From a baseline of 475 million
            in 2019, by 2030 lower-middle-income countries would see an
            additional 66.6 million menstruators using purpose-made products in
            Scenario 1 (14%) and an additional 317 million in Scenario 2 (67%).
          </p>

          <p>
            Upper-middle-income countries would see a slight decrease in the
            number of menstruators using purpose-made products in Scenario 1,
            declining by -12.4 million (-2%) by 2030 from a 2019 baseline of 673
            million. However, this decrease in use would be offset by the
            projected increase in use of purpose-made products in Scenario 2,
            which would lead to a slight increase by 2030 of 28 million (+4%).
            Results for Scenarios 2 and 3 would be identical since they have the
            same trajectory of purpose-made product use.
          </p>

          <MhmF8 />

          <p>
            Different trends in use are also seen by menstrual hygiene product
            type (Figure 8). With an increase in use of purpose-made products,
            but no shift from disposable to reusable products (Scenario 2), the
            number of menstruators using disposable pads and tampons by 2030
            would increase substantially, from 1.15 billion in 2019 to 1.56
            billion in 2030 (+413 million, +36%). This increase, however, would
            be almost entirely offset if, under Scenario 3, menstruators shifted
            away from disposable products to reusable products. Such a shift
            would increase the use of disposable products in a manner comparable
            to Scenarios 3 (+74.2 million, +6%) and 1 (maintaining product use)
            (+84.6 million, +7%).
          </p>

          <p>
            For reusable products, Scenario 1 (maintaining product use) and
            Scenario 2 (increase in use of purpose-made products) would result
            in a small increase in the number menstruators using reusable
            products (+3.91 million, +6% in Scenario 1 and +21.8 million, +36%
            in Scenario 2). Projecting a shift to 25% of menstruators using
            reusable products by 2030 among those using purpose-made products
            (Scenario 3) would see the number of menstruators using reusable
            products increase by 351 million (+580%), from 60.4 million in 2019
            to 411 million in 2030. This increase would largely be made up of
            users of reusable pads.
          </p>

          <MhmF10 />

          <Typography variant="h2">
            Future Costs of Menstrual Hygiene Products in Low- and Middle-Income
            Countries
          </Typography>

          <SideBySide component={<MhmF11 />}>
            We analyzed the cost of menstrual hygiene products from the
            perspective of the price a user would pay for a product, focusing
            only on purpose-made products. As with future use, we projected the
            change in total cost of purpose-made menstrual hygiene products from
            2019 to 2030, across all low- and middle-income countries. Under
            Scenario 1, total cost to menstruators would remain relatively
            similar, increasing by 4% from 2019 to 2030 (+$1.1 billion).
            Building in increases in use of purpose-made products (Scenario 2),
            costs would increase more rapidly&mdash;growing by 31% from $28.0
            billion in 2019 to $36.6 billion by 2030 (+$8.63 billion). If the
            increase in use of purpose-made products is combined with a shift to
            reusable products (Scenario 3), costs would increase by $3.72
            billion between 2019 and 2030 (13%), less than half the increase in
            Scenario 2. This reflects the important cost savings gained through
            increased use of reusable products.
          </SideBySide>

          <p>
            The projected future changes in cost of menstrual hygiene products
            show distinct patterns across the different income groups (Figure
            10). In low-income and lower-middle-income countries, increase in
            use of purpose-made products, with or without increased use of
            reusable products (Scenarios 2 and 3), would lead to a large
            increase in costs to menstruators. Under Scenario 2, costs would
            increase by $1.78 billion (+142%) in low-income countries and $6.36
            billion (+70%) in lower-middle-income countries. Under Scenario 3,
            cost increases would be slightly smaller (+$1.38 billion, +111% in
            low-income and +$4.52 billion, +50% in lower-middle-income). The
            large increases are due to the relatively low proportion of
            menstruators currently using purpose-made products in these two
            income groups.
          </p>

          <p>
            Conversely, in upper-middle-income countries, purpose-made products
            are already fairly ubiquitous, so an increase in their use would
            have less of an impact on cost (Scenario 2, +$492 million, +3%),
            than in the other country groupings. In upper-middle-income
            countries, due to declines in the total number of menstruators over
            the period 2019 to 2030, and the cost savings of reusable products,
            costs would actually decline if product use is maintained (Scenario
            1) or if there is a shift to reusable products (Scenario 3). In
            Scenario 1, costs would decrease by $421 million (-2%) during this
            timeframe, while Scenario 3 would lead to a 12% decrease in costs by
            2030 (-$2.18 billion).
          </p>

          <MhmF12 />

          <MhmT5 />

          <p>
            The mix of product type is another important factor in driving the
            overall change in costs within each scenario (Figure 11). Disposable
            products are the highest cost contributor to purpose-made product
            costs because they dominate the product mix and are more expensive
            than reusable products on an annualized basis. Assuming no change in
            product use (Scenario 1), there would be a 4% increase in costs of
            disposable products (+$1.06 billion) across all countries by 2030,
            driven by changes in the overall number of menstruators. An increase
            in the proportion of menstruators using purpose-made products
            (Scenario 2), would lead to a much larger increase in the cost of
            disposable products (+$8.43 billion, +31%) as most of the increase
            in use of purpose-made products would be among disposable products.
            However, with the shift to 25% using reusable products among those
            who use purpose-made products in Scenario 3, the increase in cost of
            disposable products seen in Scenario 2 is minimized, resulting in
            just a 3% increase in costs by 2030 (+$692 million).
          </p>

          <p>
            In terms of total costs of reusable products, there would be a 7%
            increase (+$37.2 million) in costs of reusable products in Scenario
            1 (maintain product use), and a larger increase in the scenario
            predicting increased purpose-made use (Scenario 2) (+$194 million,
            +38%). The most dramatic change for reusable products would of
            course be the scenario predicting increases in use of both
            purpose-made and reusable products (Scenario 3). In that case, the
            overall cost of reusable pads would increase almost sixfold, from
            $478 million in 2019 to $3.31 billion in 2030. The cost of reusable
            cups would increase over sevenfold, from $25.3 million in 2019 to
            $217 million in 2030.
          </p>

          <MhmF13 />
        </div>
      </section>

      <section id="conclusion">
        <Typography variant="h1" className={classes.sectionTitle}>
          What&apos;s Next
        </Typography>

        <div className={classes.boxes}>
          <p>
            This report is the first comprehensive assessment of current and
            future use and cost of menstrual hygiene products in low- and
            middle-income countries. Most menstruators live in
            lower-middle-income and upper-middle-income countries, where the
            majority of menstruators use purpose-made, disposable menstrual
            hygiene products. By contrast, most menstruators in low-income
            countries use products not specifically designed for managing
            menstruation. Due to demographic changes as well as increases in the
            use of purpose-made products, the number of menstruators and the
            total cost of menstrual hygiene products are likely to increase in
            low- and lower-middle-income countries, and stabilize or decrease
            slightly in upper-middle-income countries by 2030.
          </p>

          <p>
            In summary, we expect the future menstrual hygiene product market to
            be shaped by the following factors:
          </p>

          <ul>
            <li>
              Varying demographic changes in low-income, lower-middle-income,
              and upper-middle-income country groups;
            </li>
            <li>
              An increase in the proportion of menstruators using purpose-made
              products in low-income and lower-middle-income countries;
            </li>
            <li>
              A shift towards greater use of reusable menstrual hygiene
              products, and;
            </li>
            <li>An increase in use of hormonal contraceptives.</li>
          </ul>

          <p>
            The shift to reusable products and increased use of hormonal
            contraceptives can be expected to reduce the number of disposable
            products needed, which would in turn reduce the environmental impact
            and overall cost of menstrual hygiene products. While we cannot
            predict precisely the extent of these changes, the three scenarios
            presented in this report provide a starting place for discussions
            about how to ensure menstrual hygiene product needs are met between
            now and 2030.
          </p>

          <p>
            The inability to manage menstruation safely and in an effective
            manner restricts girls&apos; and women&apos;s ability to fully
            participate in society, and negatively impacts their health. In
            order to ensure increased use of purpose-made menstrual hygiene
            products, the products themselves must exist, and women and girls
            must be able to afford them. The data presented in this report can
            help decision makers at global- and country-level to ensure adequate
            resources to realize the potential gains explored in our projected
            future scenarios.
          </p>

          <p>
            This report makes available for the first time comprehensive use and
            cost estimates across a full set of low- and middle-income
            countries. As more data become available and discussions in the
            community continue to evolve, future iterations of LEAP will offer
            greater certainty into the growing demand for menstrual health
            products, the evolving make-up of the wider product mix, and the
            increasing resource base required to sustain product use over time.
          </p>
        </div>
      </section>
    </Landscape>
  );
};

MenstrualHygiene.propTypes = {
  onOpenReadersGuide: PropTypes.func,
};

export default MenstrualHygiene;
