import { useState } from "react";
import PropTypes from "prop-types";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import ApacCsT3 from "../Tables/ApacCsT3";
import {
  formatPercentage,
  getQualitativeChangeDirection,
  getScenarioNumber,
  toLowerCase,
} from "../../../../utilities";

const ApacCsS4 = (props) => {
  const [selectedScenarioId, setSelectedScenarioId] = useState(5);

  const { areaData: data } = useAreaQuery(
    `/custom/apac/cs/tables/3/${props.regionId}/${selectedScenarioId}`
  );

  const selectedScenarioName = `Scenario ${getScenarioNumber(
    selectedScenarioId
  )}`;

  const handleScenarioChange = (scenarioId) => {
    setSelectedScenarioId(scenarioId);
  };

  return (
    <>
      <p>
        The total number of abortion services increases by the same magnitude in
        all three scenarios; what differs is the change in PAC services based on
        the shifts in safety profile and method mix. However, the general
        pattern of change in total abortion and PAC services is similar in all
        three scenarios.
      </p>

      <p>
        In {toLowerCase(props.regionName)}, the total number of abortion and PAC
        services would{" "}
        {getQualitativeChangeDirection(data?.[0].percentageChange ?? 0)} by{" "}
        {formatPercentage(data?.[0].percentageChange ?? 0)} between 2019 and
        2030 under {selectedScenarioName}; this compares to a{" "}
        {formatPercentage(data?.[1].percentageChange ?? 0)}{" "}
        {getQualitativeChangeDirection(data?.[1].percentageChange ?? 0)} across
        all low- and middle-income countries over the same time period.
      </p>

      <ApacCsT3
        regionName={props.regionName}
        scenarioId={selectedScenarioId}
        scenarioName={selectedScenarioName}
        data={data}
        onScenarioChange={handleScenarioChange}
      />
    </>
  );
};

ApacCsS4.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default ApacCsS4;
