import { getChapterTheme } from "../utilities";
import useChapterDetails from "./useChapterDetails";

const useChapterTheme = () => {
  const chapterDetails = useChapterDetails();

  return getChapterTheme(chapterDetails.sectionSlug ?? chapterDetails.slug);
};

export default useChapterTheme;
