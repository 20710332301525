import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const useStyles = makeStyles((theme) => ({
  table: {
    "& td": {
      padding: theme.spacing(0, 1),
    },
  },
}));

const MaternalHealthTerms = () => {
  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Key Terms and Acronyms</Typography>

      <p>
        <strong>Unit</strong>: The &apos;unit&apos; for maternal health is a
        &apos;case&apos; which we define as a health intervention to prevent,
        manage or treat complications during pregnancy and childbirth that uses
        one of the maternal health drugs under review (see Table 1 in{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("maternal-health-scope")}
        >
          Scope
        </Link>
        ). We use the term &apos;women&apos; throughout, while acknowledging
        that some, but not all, women, girls, transgender men, and non-binary
        people can become pregnant. Using &apos;case&apos; as the unit of
        analysis allows for the fact that:
      </p>

      <ol>
        <li>
          One pregnant woman may receive multiple interventions that use the
          same drug;
        </li>
        <li>A &apos;case&apos; can include multiple doses of a drug;</li>
        <li>Not everyone who needs an intervention receives it</li>
      </ol>

      <p>
        We split cases into those that needed but did not receive a drug and
        those that received a drug. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("maternal-health-cases")}
        >
          Cases Needing and Receiving Drugs
        </Link>
        .
      </p>
      <p>
        <strong>Quantities</strong>: The &apos;quantities&apos; for maternal
        health are the number of drugs required to fully complete an
        intervention, multiplied by the number of cases receiving that
        intervention. For each case, the number of drugs received will differ
        depending on the intervention for which they were used. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("maternal-health-quantities")}
        >
          Drug Quantities
        </Link>{" "}
        below.
      </p>
      <p>
        <strong>Cost</strong>: The &apos;cost&apos; is the cost of the drug(s)
        used for an intervention. We determine cost based on the price paid by
        governments or donors to procure supplies for public sector distribution
        (generally at no charge to the user). We do not include in our cost
        figures: products that were purchased but never used; the costs that
        would be associated with women who need but don&apos;t receive a drug;
        or other associated costs such as labor, health infrastructure, and
        medical equipment. See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("maternal-health-costs")}
        >
          Drug Costs
        </Link>{" "}
        below.
      </p>

      <p>
        <strong>Acronyms</strong>:
      </p>

      <table className={classes.table}>
        <tbody>
          <tr>
            <td>HSC</td>
            <td>Heat-stable carbetocin</td>
          </tr>
          <tr>
            <td>IFA</td>
            <td>Iron and folic acid</td>
          </tr>
          <tr>
            <td>LEAP</td>
            <td>
              LandscapE And Projection of Reproductive Health Supply Needs
            </td>
          </tr>
          <tr>
            <td>MDG</td>
            <td>Millennium Development Goal</td>
          </tr>
          <tr>
            <td>MMR</td>
            <td>Maternal Mortality Ratio</td>
          </tr>
          <tr>
            <td>MTN</td>
            <td>Metronidazole</td>
          </tr>
          <tr>
            <td>PPH</td>
            <td>Postpartum hemorrhage</td>
          </tr>
          <tr>
            <td>SDG</td>
            <td>Sustainable Development Goal</td>
          </tr>
          <tr>
            <td>TXA</td>
            <td>Tranexamic acid</td>
          </tr>
          <tr>
            <td>UNPD</td>
            <td>United Nations Population Division</td>
          </tr>
          <tr>
            <td>WHO</td>
            <td>World Health Organization</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MaternalHealthTerms;
