import { useState } from "react";
import PropTypes from "prop-types";
import SideBySide from "../../../Common/SideBySide";
import MhmCsF6 from "../Figures/MhmCsF6";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeChangeDirection,
  getQualitativeChangeMagnitude,
} from "../../../../utilities";

const calculateChange = (scenarioIndex, data) => {
  if (!data) {
    return {
      start: 0,
      end: 0,
      change: 0,
      percentageChange: 0,
    };
  }

  const start = data[0].data[scenarioIndex];
  const end = data[2].data[scenarioIndex];
  const change = end - start;

  return {
    start,
    end,
    change,
    percentageChange: (change / start) * 100,
  };
};

const calculateMetrics = (data) => {
  if (!data) {
    return Array(3).fill(calculateChange(0));
  }

  return Array.from({ length: 3 }, (_, i) => calculateChange(i, data));
};

const Summary = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cs/figures/6/${props.regionId}/${props.methodId}`
  );

  const metrics = calculateMetrics(data);

  return (
    <div>
      <p>
        Changes in the number of menstruators using purpose-made products and
        the types of products they use would also result in changes to costs:
      </p>
      <ul>
        <li>
          If current levels of use were maintained (Scenario 1), there would be
          a {getQualitativeChangeMagnitude(metrics[0].percentageChange)}{" "}
          {getQualitativeChangeDirection(metrics[0].percentageChange)} in total
          costs from{" "}
          {formatNumberAsHumanReadable(metrics[0].start, { prefix: "$" })} in
          2019 to {formatNumberAsHumanReadable(metrics[0].end, { prefix: "$" })}{" "}
          in 2030 (
          {formatNumberAsHumanReadable(metrics[0].change, {
            prefix: "$",
            prefixWithSign: true,
          })}
          ,{" "}
          {formatPercentage(metrics[0].percentageChange, {
            prefixWithSign: true,
          })}
          ).
        </li>
        <li>
          Building in increases in use of purpose-made products (Scenario 2),
          there would be a{" "}
          {getQualitativeChangeMagnitude(metrics[1].percentageChange)}{" "}
          {getQualitativeChangeDirection(metrics[1].percentageChange)} in total
          costs from{" "}
          {formatNumberAsHumanReadable(metrics[1].start, { prefix: "$" })} in
          2019 to {formatNumberAsHumanReadable(metrics[1].end, { prefix: "$" })}{" "}
          in 2030 (
          {formatNumberAsHumanReadable(metrics[1].change, {
            prefix: "$",
            prefixWithSign: true,
          })}
          ,{" "}
          {formatPercentage(metrics[1].percentageChange, {
            prefixWithSign: true,
          })}
          ).
        </li>
        <li>
          If the increase in use of purpose-made products is combined with a
          shift to reusable products (Scenario 3), there would be a{" "}
          {getQualitativeChangeMagnitude(metrics[2].percentageChange)}{" "}
          {getQualitativeChangeDirection(metrics[2].percentageChange)} in total
          costs from{" "}
          {formatNumberAsHumanReadable(metrics[2].start, { prefix: "$" })} in
          2019 to {formatNumberAsHumanReadable(metrics[2].end, { prefix: "$" })}{" "}
          in 2030 (
          {formatNumberAsHumanReadable(metrics[2].change, {
            prefix: "$",
            prefixWithSign: true,
          })}
          ,{" "}
          {formatPercentage(metrics[2].percentageChange, {
            prefixWithSign: true,
          })}
          ). This reflects the important cost savings gained through increased
          use of reusable products.
        </li>
      </ul>
    </div>
  );
};

const MhmCsFutureCosts = (props) => {
  const [selectedMethodId, setSelectedMethodId] = useState(0);

  const handleMethodChange = (methodId) => {
    setSelectedMethodId(methodId);
  };

  return (
    <SideBySide
      component={
        <MhmCsF6
          regionId={props.regionId}
          regionName={props.regionName}
          methodId={selectedMethodId}
          onMethodChange={handleMethodChange}
        />
      }
      componentPosition="right"
    >
      <Summary regionId={props.regionId} methodId={selectedMethodId} />
    </SideBySide>
  );
};

MhmCsFutureCosts.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCsFutureCosts;
