import { useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CustomReport from "../CustomReport";
import DatasetButton from "../DatasetButton";
import RegionChooser from "../RegionChooser";
import { getRegionName } from "../../../utilities";
import FpCsB1 from "./Boxes/FpCsB1";
import ContraceptionIntroduction from "./ContraceptionIntroduction";
import FpCsCurrentCosts from "./Summaries/FpCsCurrentCosts";
import FpCsCurrentUse from "./Summaries/FpCsCurrentUse";
import FpCsFutureCosts from "./Summaries/FpCsFutureCosts";
import FpCsFutureUse from "./Summaries/FpCsFutureUse";
import ContraceptionScenarios from "./ContraceptionScenarios";
import FpCsT1 from "./Tables/FpCsT1";
import FpCsT2 from "./Tables/FpCsT2";
import FpCsT3 from "./Tables/FpCsT3";
import FpCsT4 from "./Tables/FpCsT4";
import FpCsT5 from "./Tables/FpCsT5";
import FpCsT6 from "./Tables/FpCsT6";

const useStyles = makeStyles((theme) => ({
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
}));

const ContraceptionLandscape = () => {
  const [selectedRegionId, setSelectedRegionId] = useState(158);

  const classes = useStyles();

  const selectedRegionLongName = getRegionName(selectedRegionId, "long");

  const handleRegionChange = (regionId) => {
    setSelectedRegionId(regionId);
  };

  return (
    <CustomReport>
      <Typography variant="h1">
        Contraception Landscape for {selectedRegionLongName}
      </Typography>

      <RegionChooser
        includeCountries={true}
        value={selectedRegionId}
        onChange={handleRegionChange}
      />

      <section id="introduction">
        <Typography variant="h2">Background</Typography>

        <div className={classes.boxes}>
          <ContraceptionIntroduction>
            The <strong>Contraception Custom Landscape</strong> provides
            estimates of the number of users, they methods they use, and related
            costs for the selected country or region.
          </ContraceptionIntroduction>

          <FpCsB1 regionId={selectedRegionId} />
        </div>
      </section>

      <section id="current">
        <Typography variant="h2">Where We Are Now</Typography>

        <div className={classes.boxes}>
          <Typography variant="h6">
            Current Use of Contraceptives in {selectedRegionLongName}
          </Typography>

          <FpCsCurrentUse
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <Typography variant="h6">
            Current Cost of Contraceptive Supplies in {selectedRegionLongName}
          </Typography>

          <FpCsCurrentCosts
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />
        </div>
      </section>

      <section id="future">
        <Typography variant="h2">Changes Ahead</Typography>

        <ContraceptionScenarios />

        <div className={classes.boxes}>
          <Typography variant="h6">
            Future Use of Contraceptives in {selectedRegionLongName}
          </Typography>

          <FpCsFutureUse
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <Typography variant="h6">
            Future Cost of Contraceptive Supplies in {selectedRegionLongName}
          </Typography>

          <FpCsFutureCosts
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />
        </div>
      </section>

      <section id="annex">
        <Typography variant="h2">Annex</Typography>

        <div className={classes.boxes}>
          <FpCsT1
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <FpCsT2
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <FpCsT3
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <FpCsT4
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <FpCsT5
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <FpCsT6
            regionId={selectedRegionId}
            regionName={selectedRegionLongName}
          />

          <div>
            <DatasetButton area="Contraception" regionId={selectedRegionId} />
          </div>
        </div>
      </section>
    </CustomReport>
  );
};

export default ContraceptionLandscape;
