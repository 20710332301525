import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExternalLink from "../../Common/ExternalLink";
import { enableCustomReports } from "../../../constants";

const useStyles = makeStyles({
  customReports: {
    display: enableCustomReports ? "block" : "none",
  },
});

const LeapResources = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h1">LEAP Resources</Typography>

      <p>
        The results of LEAP have been presented in several ways, to maximize the
        utility for the Reproductive Health community. The available resources
        include:
      </p>

      <ul>
        <li>
          <strong>Landscape Reports</strong> for each health area provide a
          brief background to the health area, an explanation of the analysis
          performed, and present overall results of the use and cost of supplies
          currently, and through 2030. They include results for all 129 low- and
          middle-income countries, disaggregated by income group (low-,
          lower-middle and upper-middle) and, for the contraception report, by
          sector (public, private).
        </li>
        <li className={classes.customReports}>
          <strong>Custom Reports</strong> for each health area allow you to find
          results for a specific country or region, compare countries or
          regions, or explore a single product/supply.
        </li>
        <li>
          <strong>Reader&apos;s Guide</strong> (this document) details our data
          sources and analysis approaches.
        </li>
      </ul>

      <p>
        For more information, or technical support, visit{" "}
        <ExternalLink
          href="https://www.rhsupplies.org/activities-resources/leap/"
          text="LEAP Hub"
        />{" "}
        or email <a href="mailto:leap@rhsupplies.org">leap@rhsupplies.org</a>.
      </p>
    </div>
  );
};

export default LeapResources;
