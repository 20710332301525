import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
    },
    "& td:not(first-child)": {
      textAlign: "center",
    },
    "& th": {
      padding: theme.table.cellPadding,
    },
  },
  groupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
}));

const RgApacT1 = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6">
        Table 2. Supply quantity estimates for abortion and PAC services, by
        method, service type and pregnancy gestation
      </Typography>

      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th className={classes.groupBoundary}>
              Surgical:
              <br />
              Vacuum
              <br />
              aspiration
            </th>
            <th colSpan={3} className={classes.groupBoundary}>
              Medical:
              <br />
              Misoprostol only
            </th>
            <th colSpan={2} className={classes.groupBoundary}>
              Medical:
              <br />
              Mifepristone &amp;
              <br />
              misoprostol
            </th>
          </tr>
          <tr>
            <th />
            <th className={classes.groupBoundary}>
              Abortion
              <br />
              and PAC
            </th>
            <th className={classes.groupBoundary}>
              Abortion
              <br />
              &lt;12 weeks
            </th>
            <th>
              Abortion
              <br />
              &ge;12 weeks
            </th>
            <th>PAC</th>
            <th className={classes.groupBoundary}>
              Abortion
              <br />
              &lt;12 weeks
            </th>
            <th>
              Abortion
              <br />
              &ge;12 weeks
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MVA kit</td>
            <td className={classes.groupBoundary}>0.04</td>
            <td className={classes.groupBoundary}>n/a</td>
            <td>n/a</td>
            <td>n/a</td>
            <td className={classes.groupBoundary}>n/a</td>
            <td>n/a</td>
          </tr>
          <tr>
            <td>
              Misoprostol pill
              <br />
              (200 &mu;g units)
            </td>
            <td className={classes.groupBoundary}>2</td>
            <td className={classes.groupBoundary}>8</td>
            <td>8</td>
            <td>3</td>
            <td className={classes.groupBoundary}>4</td>
            <td>6</td>
          </tr>
          <tr>
            <td>
              Mifepristone pills
              <br />
              (200 mg units)
            </td>
            <td className={classes.groupBoundary}>n/a</td>
            <td className={classes.groupBoundary}>n/a</td>
            <td>n/a</td>
            <td>n/a</td>
            <td className={classes.groupBoundary}>1</td>
            <td>1</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RgApacT1;
