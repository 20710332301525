import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import IndicatorChooser from "../../IndicatorChooser";
import {
  getIndicatorDetails,
  getTooltip,
  toTitleCase,
} from "../../../../utilities";

const number = 3;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Maintain":
      return theme.palette.scenarios.scenario1;

    case "Shift in safety":
      return theme.palette.scenarios.scenario2;

    case "Shift in safety & method":
      return theme.palette.scenarios.scenario3;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const financial = props.indicatorId === 1;

  const series = data.map((scenario) => ({
    name: scenario.name,
    data: scenario.incomeGroups.map(({ value }) => value),
    color: getSeriesColor(scenario.name, theme),
  }));

  return {
    chart: {
      type: "column",
      width: 500,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: ["Low", "Lower-Middle", "Upper-Middle"],
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        formatter: function () {
          return (
            (financial ? "$" : "") + this.axis.defaultLabelFormatter.call(this)
          );
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        pointWidth: 25,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip(
          props.indicatorName,
          this.y,
          undefined,
          financial
            ? {
                prefix: "$",
              }
            : undefined
        );
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const ApacCpF3 = (props) => {
  const indicatorDetails = getIndicatorDetails(props.indicatorId);

  return (
    <Figure
      id="APAC_CP_f3"
      number={number.toString()}
      fileStem={`apac_cp_f${number}`}
      title={`Future ${indicatorDetails.qualifier} of ${toTitleCase(
        props.productName
      )}`}
      subtitle="By Income Group and Future Scenario, 2030"
      controls={
        <BoxControls>
          <IndicatorChooser
            area="abortion"
            value={props.indicatorId}
            onChange={props.onIndicatorChange}
          />
        </BoxControls>
      }
      chartOptions={
        props.data &&
        options(props.data, {
          indicatorId: props.indicatorId,
          indicatorName: indicatorDetails.name,
        })
      }
    />
  );
};

ApacCpF3.propTypes = {
  productName: PropTypes.string.isRequired,
  indicatorId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      scenarios: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  onIndicatorChange: PropTypes.func,
};

export default ApacCpF3;
