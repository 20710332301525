import { Typography } from "@material-ui/core";

const MenstrualHygieneFutureScenarios = () => (
  <div>
    <Typography variant="h1">Future Scenarios</Typography>

    <p>
      Based on consultations with experts, we selected three scenarios to
      explore potential changes in menstrual hygiene product use and cost
      between 2019 and 2030: 1) product use is maintained at 2019 levels; 2) the
      proportion of product use that is purpose-made increases; 3) proportion of
      product use that is purpose-made and reusable increases. The number of
      menstruators changes over time, informed by demographic changes, chances
      in contraceptive use, and changes in fertility&mdash;but the magnitude of
      this change is the same in all three scenarios.
    </p>

    <ul>
      <li>
        <strong>Scenario 1</strong>: Maintain Product Use. This scenario
        reflects population changes, changes in fertility rates, and changes in
        contraceptive uptake and method mix (based on existing trend data) but
        does not change the proportion of menstruators using purpose-made or
        reusable menstrual hygiene products from 2019 levels. While we do expect
        that the proportion of menstruators using purpose-made and reusable
        products will change, we have kept it constant so that this scenario can
        provide a baseline, and help to understand what additional volumes of
        products and costs would be needed to simply keep up with changes in the
        number of menstruators.
      </li>
      <li>
        <strong>Scenario 2</strong>: Increase Purpose-Made Use. This scenario
        reflects the same demographic changes and changes in contraceptive use
        as Scenario 1, but adjusts for an increase in use of purpose-made
        products over time. It is difficult to project how use of purpose-made
        products may change, especially as historical trend data is limited.
        Instead we took an approach to use the next highest income group within
        the same region to inform potential changes. Low-income countries in a
        region were shifted to the profile of lower-middle-income countries in
        that same region by 2030; with a linear trend between 2019 and 2030.
        Likewise, lower-middle-income countries in a region were shifted to the
        profile of upper-middle-income countries in that same region by 2030.
        Finally, a small shift was built in for upper-middle-income countries,
        increasing to the highest level of use of purpose-made products seen
        across regions. In addition, it was assumed that there would be a shift
        away from supplementing use of disposable products with non-purpose-made
        products (which impacts on the quantities and costs per user). For this
        shift, countries were moved up to the profile of supplemented use seen
        in the next-highest income group.
      </li>
      <li>
        <strong>Scenario 3</strong>: Increase Purpose-Made and Reusable Use.
        This scenario maintains the same demographic, contraceptive use and
        product-type changes as in Scenario 2 but reflects greater use of
        reusable products. For Scenarios 1 and 2, the percent of purpose-made
        product use that was reusable products was held constant at 5% of
        overall purpose-made product use. For Scenario 3, that proportion was
        increased from 5% in 2019 to 25% by 2030 for all countries, following a
        linear trend. While we recognize this is a sizable increase that is not
        expected without substantial investments to shift from disposable to
        reusable products, this scenario demonstrates the potential cost and
        environmental impacts that a shift like this could generate.
      </li>
    </ul>
  </div>
);

export default MenstrualHygieneFutureScenarios;
