import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Information from "../../../Common/Box/Information";
import customReportLookups from "../../../../data/customReportLookups.json";

const useStyles = makeStyles(() => ({
  content: {
    width: "100%",
    justifyContent: "flex-start",
  },
}));

const CountryGroupDetails = ({ regionId }) => {
  const countryGroup = customReportLookups.countryGroups[regionId];

  return <div>{countryGroup.inclusionNote}</div>;
};

const MhCsB1 = ({ regionId }) => {
  const classes = useStyles();

  return (
    <Information key="MH_CS_b1" number="1" title="Scope" classes={classes}>
      <p>
        The methodology for LEAP was developed to produce results for all low-
        and middle-income countries. Inputs include default assumptions about
        prices which are drawn from global databases, and regional or income
        level estimates for incidence and prevalence of conditions. While we
        make global and regional results available, they should be viewed in
        this context and caution should be used in the application of these
        results for decision making.
      </p>

      <CountryGroupDetails regionId={parseInt(regionId, 10)} />
    </Information>
  );
};

MhCsB1.propTypes = {
  regionId: PropTypes.number.isRequired,
};

export default MhCsB1;
