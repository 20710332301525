import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.title.primary,
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "none",
  },
  component: {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(4),
    margin: theme.spacing(0, 2),
    whiteSpace: "nowrap",
    width: "fit-content",
  },
}));

const MainMenu = ({ onOpenReadersGuide }) => {
  const classes = useStyles();

  return (
    <div className={classes.component}>
      <Button
        className={classes.button}
        onClick={() => {
          onOpenReadersGuide?.();
        }}
      >
        Reader&apos;s Guide
      </Button>
    </div>
  );
};

MainMenu.propTypes = {
  onOpenReadersGuide: PropTypes.func,
};

export default MainMenu;
