import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Information from "../../../Common/Box/Information";
import customReportLookups from "../../../../data/customReportLookups.json";

const useStyles = makeStyles(() => ({
  content: {
    width: "100%",
    justifyContent: "flex-start",
  },
}));

const ApacCsB1 = (props) => {
  const classes = useStyles();

  const countryGroup = customReportLookups.countryGroups[props.regionId];

  return (
    <Information key="APAC_CS_b1" number="1" title="Scope" classes={classes}>
      <p>
        The methodology for LEAP was developed to produce results for all low-
        and middle-income countries. Many inputs are reliant of regional and
        global estimates, given the limited data availability on abortion and
        PAC. While we make global and regional results available, they should be
        viewed in this context.
      </p>
      <p>{countryGroup.inclusionNote}</p>
    </Information>
  );
};

ApacCsB1.propTypes = {
  regionId: PropTypes.number.isRequired,
};

export default ApacCsB1;
