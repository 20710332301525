import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import sum from "lodash/sum";
import Table from "../../../Common/Box/Table";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  capitalizePhrase,
  formatNumber,
  formatPercentage,
} from "../../../../utilities";

const number = 2;

const format = (n) =>
  formatNumber(n, {
    significantDigits: 3,
  });

const calculateScenarioTotals = (methods) => {
  const years = methods
    .slice(1)
    .reduce(
      (accumulator, method) =>
        accumulator.map((value, index) => value + method.years[index]),
      methods[0].years
    );

  return {
    name: "Total",
    years,
  };
};

const summarizeMethod = (years) => {
  const mainYears = [years[0], years[6], years[11]];
  const change = mainYears[2] - mainYears[0];

  return {
    years: mainYears,
    change,
    percentageChange: (change / mainYears[0]) * 100,
    cumulativeTotal: sum(years.slice(2)),
  };
};

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
      borderBottom: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child td:not(:first-child)": {
      minWidth: "6rem",
    },
  },
  tables: {
    display: "grid",
    gridAutoFlow: "row",
    gridRowGap: theme.spacing(2),
  },
}));

const ScenarioTable = (props) => {
  const classes = useStyles();

  const scenarioName = `${props.scenarioNumber}. ${props.data.name}`;

  const methods = [
    ...props.data.methods,
    calculateScenarioTotals(props.data.methods),
  ];

  return (
    <table className={classes.table}>
      {props.scenarioNumber === 1 ? (
        <thead>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th colSpan={3}>Total Menstruators</th>
            <th colSpan={2} className={classes.changeGroupBoundary}>
              Change (2019 to 2030)
            </th>
          </tr>
          <tr>
            <th className={classes.nameGroupBoundary} />
            <th>2019</th>
            <th>2025</th>
            <th>2030</th>
            <th className={classes.changeGroupBoundary}>#</th>
            <th>%</th>
          </tr>
        </thead>
      ) : null}
      <tbody>
        <tr>
          <td colSpan={6}>{scenarioName}</td>
        </tr>
        {methods.map((method) => {
          const summary = summarizeMethod(method.years);

          return (
            <tr key={method.name}>
              <td className={classes.nameGroupBoundary}>{method.name}</td>

              {summary.years.map((value, index) => (
                <td key={[2019, 2025, 2030][index]}>{format(value)}</td>
              ))}

              <td className={classes.changeGroupBoundary}>
                {format(summary.change)}
              </td>
              <td>{formatPercentage(summary.percentageChange)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const MhmCsT2 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cs/tables/2/${props.regionId}`
  );

  const classes = useStyles();

  return (
    <Table
      id="MHM_CS_t2"
      number={number.toString()}
      title="Change in Menstruators Using Purpose-Made Menstrual Hygiene Products"
      subtitle={`${capitalizePhrase(props.regionName)} by Scenario, 2019`}
    >
      <div className={classes.tables}>
        {data ? (
          data.map((scenario, index) => (
            <ScenarioTable
              key={scenario.name}
              scenarioNumber={index + 1}
              data={scenario}
            />
          ))
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </Table>
  );
};

MhmCsT2.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCsT2;
