import { useState } from "react";
import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import DrugChooser from "../../../Common/Box/Choosers/DrugChooser";
import Figure from "../../../Common/Box/Figure";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { getTooltip, translateSectorName } from "../../../../utilities";

const number = 7;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Heat-stable carbetocin":
      return theme.palette.areas.maternalHealth.methods.heatStableCarbetocin;

    case "Metronidazole":
      return theme.palette.areas.maternalHealth.methods.metronidazole;

    case "Oxytocin":
      return theme.palette.areas.maternalHealth.methods.oxytocin;

    case "Misoprostol":
      return theme.palette.areas.maternalHealth.methods.misoprostol;

    case "Magnesium sulfate":
      return theme.palette.areas.maternalHealth.methods.magnesiumSulfate;

    case "Methyldopa":
      return theme.palette.areas.maternalHealth.methods.methyldopa;

    case "Hydralazine":
      return theme.palette.areas.maternalHealth.methods.hydralazine;

    case "Iron-folic acid":
      return theme.palette.areas.maternalHealth.methods.ironFolicAcid;

    case "Out-of-scope":
      return theme.palette.areas.maternalHealth.methods.outOfScope;

    case "Needed: Not received":
      return theme.palette.areas.maternalHealth.methods.needsDidNotGet;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = data.map((method) => ({
    name: method.name,
    color: getSeriesColor(method.name, theme),
    data: method.scenarios.map((scenario) => scenario.services),
  }));

  return {
    chart: {
      type: "column",
      width: 500,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: [
        "1: Maintain coverage",
        "2: Increase coverage",
        "3: Increase coverage and scale-up emerging drugs",
      ],
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          textAlign: "center",
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        enabled: true,
        format: absolute ? undefined : "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: absolute ? "normal" : "percent",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Menstruators", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: {
          text: data.incomeGroup,
        },
      },
      enabled: false,
    },
    series,
  };
};

const MhCsF7 = (props) => {
  const [selectedSectorId, setSelectedSectorId] = useState(112);
  const [selectedSectorName, setSelectedSectorName] = useState(
    "Iron and folic acid"
  );
  const [comparisonMode, setComparisonMode] = useState("relative");

  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/figures/7/${props.regionId}/${selectedSectorId}`
  );

  const handleSectorChange = (sector) => {
    setSelectedSectorId(sector.id);
    setSelectedSectorName(sector.name);
  };

  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  return (
    <Figure
      id="MH_CS_f7"
      number={number.toString()}
      fileStem={`mh_cs_f${number}`}
      title={`Future Cases that Need and Receive Drugs for ${translateSectorName(
        selectedSectorName
      )}`}
      subtitle={`${props.regionName} by Future Scenario, 2030`}
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        >
          <DrugChooser value={selectedSectorId} onChange={handleSectorChange} />
        </BoxControls>
      }
      chartOptions={
        data &&
        options(data, {
          regionName: props.regionName,
          comparisonMode,
        })
      }
    />
  );
};

MhCsF7.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsF7;
