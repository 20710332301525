import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
    },
    "& th": {
      padding: theme.table.cellPadding,
    },
  },
}));

const RgMhT2 = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6">Table 2. Intervention Assumptions</Typography>

      <table className={classes.table}>
        <thead>
          <tr>
            <th>Drug</th>
            <th>Target population</th>
            <th>
              Proportion of the target population in potential need of the
              product
            </th>
            <th>Associated interventions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Iron and folic acid (IFA)</td>
            <td>Pregnant women</td>
            <td>100%</td>
            <td>Iron and folic acid supplementation during pregnancy</td>
          </tr>
          <tr>
            <td>Methyldopa</td>
            <td>Pregnant women</td>
            <td>% with acute, gestational or chronic hypertension</td>
            <td>Hypertension management</td>
          </tr>
          <tr>
            <td>Hydralazine</td>
            <td>Pregnant women</td>
            <td>% with acute hypertension events</td>
            <td>Hypertension management</td>
          </tr>
          <tr>
            <td>Magnesium sulfate</td>
            <td>Births</td>
            <td>% with pre-eclampsia or eclampsia</td>
            <td>Pre-eclampsia and eclampsia management</td>
          </tr>
          <tr>
            <td>Oxytocin</td>
            <td>Births</td>
            <td>
              % needing induction, augmentation, PPH prevention or treatment
            </td>
            <td>
              Induction
              <br />
              Augmentation
              <br />
              PPH prevention and treatment
            </td>
          </tr>
          <tr>
            <td>Misoprostol</td>
            <td>Births</td>
            <td>
              % needing induction, PPH prevention or treatment (for home or
              community births or facilities without the ability to use
              oxytocin)
            </td>
            <td>
              Induction
              <br />
              Augmentation
              <br />
              PPH prevention and treatment
            </td>
          </tr>
          <tr>
            <td>Metronidazole</td>
            <td>Births</td>
            <td>% with maternal infection</td>
            <td>Infection treatment</td>
          </tr>
          <tr>
            <td>Heat-stable carbetocin (HSC)</td>
            <td>Births</td>
            <td>% needing PPH prevention</td>
            <td>PPH prevention</td>
          </tr>
          <tr>
            <td>Tranexamic acid (TXA)</td>
            <td>Births</td>
            <td>% needing PPH treatment</td>
            <td>PPH treatment</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RgMhT2;
