import PropTypes from "prop-types";
import Figure from "../../../Common/Box/Figure";
import { getTooltip } from "../../../../utilities";

const number = 6;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Metronidazole":
      return theme.palette.areas.maternalHealth.methods.metronidazole;

    case "Oxytocin":
      return theme.palette.areas.maternalHealth.methods.oxytocin;

    case "Misoprostol":
      return theme.palette.areas.maternalHealth.methods.misoprostol;

    case "Magnesium sulfate":
      return theme.palette.areas.maternalHealth.methods.magnesiumSulfate;

    case "Methyldopa":
      return theme.palette.areas.maternalHealth.methods.methyldopa;

    case "Hydralazine":
      return theme.palette.areas.maternalHealth.methods.hydralazine;

    case "Iron-folic acid":
      return theme.palette.areas.maternalHealth.methods.ironFolicAcid;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const series = data.map((series) => ({
    name: series.name,
    data: [series.cost],
    color: getSeriesColor(series.name, theme),
  }));

  return {
    chart: {
      type: "column",
      width: 500,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: [props.regionName],
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        formatter: function () {
          return `$${this.axis.defaultLabelFormatter.call(this)}`;
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Cost", this.y, this.percentage, {
          prefix: "$",
        });
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const MhCsF6 = (props) => {
  return (
    <Figure
      id="MH_CS_f6"
      number={number.toString()}
      fileStem={`mh_cs_f${number}`}
      title="Total Cost for Seven Priority Maternal Health Drugs"
      subtitle={`${props.regionName}, 2019`}
      chartOptions={
        props.data &&
        options(props.data, {
          regionName: props.regionShortName,
        })
      }
    />
  );
};

MhCsF6.propTypes = {
  regionName: PropTypes.string.isRequired,
  regionShortName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      cost: PropTypes.number.isRequired,
    })
  ),
};

export default MhCsF6;
