import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Box from "../../../Common/Box/Box";
import BoxControls from "../../../Common/Box/BoxControls";
import Chart from "../../../Common/Box/Chart";
import { capitalizePhrase, getTooltip } from "../../../../utilities";

const key = "FP_CC_f2";
const number = 2;

const createProportionalSeries = (data) => {
  if (!data?.length) {
    return [];
  }

  const series = [
    {
      name: "Public",
      data: data.map((country) => -country.sectors[0].value),
    },
    {
      name: "Subsidized",
      data: data.map((country) => country.sectors[1].value),
    },
    {
      name: "Non-subsidized",
      data: data.map((country) => country.sectors[2].value),
    },
  ];

  const totals = [];

  for (let i = 0; i < series[0].data.length; i++) {
    const total = series.reduce(
      (accumulator, value) => accumulator + Math.abs(value.data[i]),
      0
    );

    totals.push(total);
  }

  return series.map((series) => ({
    name: series.name,
    showInLegend: series.name !== "Public",
    data: series.data.map((value, index) => ({
      y: value,
      share: Math.abs(value) / totals[index],
    })),
  }));
};

const getMaximumValue = (data) => {
  let maximumValue = 0;

  for (let sector of data) {
    for (let point of sector.data) {
      maximumValue = Math.max(maximumValue, Math.abs(point.y));
    }
  }

  return maximumValue;
};

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Public":
      return theme.palette.sectors.public;

    case "Subsidized":
      return theme.palette.sectors.subsidized;

    case "Non-subsidized":
      return theme.palette.sectors.private;

    default:
      return "black";
  }
};

const options = (countries, data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = data.map((series) => ({
    ...series,
    data: [...series.data],
    color: getSeriesColor(series.name, theme),
  }));

  return {
    chart: {
      type: "bar",
      width: 1000,
      height: 1000,
      backgroundColor: null,
    },
    xAxis: {
      categories: countries,
      title: undefined,
      lineWidth: 0,
      gridLineWidth: 1,
      gridLineDashStyle: "shortDot",
      gridLineColor: theme.figure.gridLineColor,
      tickmarkPlacement: "on",
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      offset: 10,
      reversedStacks: false,
      min: absolute ? -props.maximumValue : -100,
      max: absolute ? props.maximumValue : 100,
      lineWidth: 1,
      lineColor: theme.figure.axis.secondary,
      tickPosition: "inside",
      tickLength: 5,
      tickWidth: 1,
      tickColor: theme.figure.axis.secondary,
      gridLineWidth: 0,
      labels: {
        formatter: function () {
          const label = this.axis.defaultLabelFormatter.call({
            ...this,
            value: Math.abs(this.value),
          });

          return label + (absolute ? "" : "%");
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
      plotLines: [
        {
          color: "white",
          width: 1,
          value: 0,
          zIndex: 4,
        },
      ],
    },
    plotOptions: {
      bar: {
        stacking: absolute ? "normal" : "percent",
        borderWidth: 0,
        groupPadding: 0.1,
      },
    },
    legend: {
      verticalAlign: "bottom",
      align: "right",
      layout: "horizontal",
      reversed: false,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip(
          "Users",
          Math.abs(this.y),
          Math.abs(this.point.share * 100)
        );
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    marginLeft: 250,
  },
  heading: {
    textAlign: "center",
    width: 370,
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.heading}>Public</div>
      <div className={classes.heading}>Private</div>
    </div>
  );
};

const FpCcF2 = (props) => {
  const [comparisonMode, setComparisonMode] = useState("relative");

  const highchartRef = useRef();

  const countries = props.data ? props.data.map(({ name }) => name) : [];
  const data = createProportionalSeries(props.data);

  const xAxisMaximum = getMaximumValue(data) * 1.02;

  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  return (
    <Box
      id={key}
      caption={`Figure ${number}`}
      title="Share of Contraceptive Users by Sector"
      subtitle={`${capitalizePhrase(props.regionName)} by Country, 2019`}
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        />
      }
      highchartRef={data && highchartRef}
    >
      <div>
        <Header />
        <Chart
          highchartRef={highchartRef}
          options={options(countries, data, {
            maximumValue: xAxisMaximum,
            comparisonMode,
          })}
        />
      </div>
    </Box>
  );
};

FpCcF2.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      sectors: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        })
      ),
    })
  ),
};

export default FpCcF2;
