import PropTypes from "prop-types";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeChangeDirection,
  getQualitativeChangeMagnitude,
} from "../../../../utilities";

const calculateChange = (trend) => {
  if (!trend) {
    return {
      start: 0,
      end: 0,
      change: 0,
      percentageChange: 0,
    };
  }

  const start = trend[0].value;
  const end = trend[2].value;
  const change = end - start;

  return {
    start,
    end,
    change,
    percentageChange: (change / start) * 100,
  };
};

const calculateMetrics = (data) => {
  if (!data) {
    return {
      disposable: Array(3).fill().map(calculateChange),
      reuseable: Array(3).fill().map(calculateChange),
    };
  }

  return {
    disposable: data[0].scenarios.map((scenario) =>
      calculateChange(scenario.years)
    ),
    reuseable: data[1].scenarios.map((scenario) =>
      calculateChange(scenario.years)
    ),
  };
};

const MhmCsChangeInMenstruators = ({ regionId }) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cs/figures/5/${regionId}`
  );

  const metrics = calculateMetrics(data);

  return (
    <div>
      <p>
        The total number of menstruators using each menstrual hygiene product
        will change differently in each scenario:
      </p>

      <ul>
        <li>
          If current levels of use were maintained (Scenario 1), the number of
          menstruators using disposable products would see a{" "}
          {getQualitativeChangeMagnitude(
            metrics.disposable[0].percentageChange
          )}{" "}
          {getQualitativeChangeDirection(
            metrics.disposable[0].percentageChange
          )}{" "}
          from {formatNumberAsHumanReadable(metrics.disposable[0].start)} in
          2019 to {formatNumberAsHumanReadable(metrics.disposable[0].end)} in
          2030 (
          {formatNumberAsHumanReadable(metrics.disposable[0].change, {
            prefixWithSign: true,
          })}
          ,{" "}
          {formatPercentage(metrics.disposable[0].percentageChange, {
            prefixWithSign: true,
          })}
          ).
        </li>
        <li>
          With an increase in use of purpose-made products but no change in the
          types of methods being used (Scenario 2) there would be a{" "}
          {getQualitativeChangeMagnitude(
            metrics.disposable[1].percentageChange
          )}{" "}
          {getQualitativeChangeDirection(
            metrics.disposable[1].percentageChange
          )}{" "}
          in the number of menstruators using disposable products, from{" "}
          {formatNumberAsHumanReadable(metrics.disposable[1].start)} in 2019 to{" "}
          {formatNumberAsHumanReadable(metrics.disposable[1].end)} in 2030 (
          {formatNumberAsHumanReadable(metrics.disposable[1].change, {
            prefixWithSign: true,
          })}
          ,{" "}
          {formatPercentage(metrics.disposable[1].percentageChange, {
            prefixWithSign: true,
          })}
          ).
        </li>
        <li>
          With an increase in the use of purpose-made products as well as an
          increase in the use of reusables (Scenario 3), there would be a{" "}
          {getQualitativeChangeMagnitude(
            metrics.disposable[2].percentageChange
          )}{" "}
          {getQualitativeChangeDirection(
            metrics.disposable[2].percentageChange
          )}{" "}
          in the number of menstruators using disposable products, from{" "}
          {formatNumberAsHumanReadable(metrics.disposable[2].start)} in 2019 to{" "}
          {formatNumberAsHumanReadable(metrics.disposable[2].end)} in 2030 (
          {formatNumberAsHumanReadable(metrics.disposable[2].change, {
            prefixWithSign: true,
          })}
          ,{" "}
          {formatPercentage(metrics.disposable[2].percentageChange, {
            prefixWithSign: true,
          })}
          ) and a{" "}
          {getQualitativeChangeMagnitude(metrics.reuseable[2].percentageChange)}{" "}
          {getQualitativeChangeDirection(metrics.reuseable[2].percentageChange)}{" "}
          in the number of menstruators using reusable products, from{" "}
          {formatNumberAsHumanReadable(metrics.reuseable[2].start)} in 2019 to{" "}
          {formatNumberAsHumanReadable(metrics.reuseable[2].end)} in 2030 (
          {formatNumberAsHumanReadable(metrics.reuseable[2].change, {
            prefixWithSign: true,
          })}
          ,{" "}
          {formatPercentage(metrics.reuseable[2].percentageChange, {
            prefixWithSign: true,
          })}
          ).
        </li>
      </ul>
    </div>
  );
};

MhmCsChangeInMenstruators.propTypes = {
  regionId: PropTypes.number.isRequired,
};

export default MhmCsChangeInMenstruators;
