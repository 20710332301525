import PropTypes from "prop-types";
import MhCcF6 from "../Figures/MhCcF6";

const MhCcS8 = (props) => (
  <>
    <p>
      The total cost of the seven priority maternal health drugs in low- and
      middle-income countries is currently $103 million. This figure is a
      function of the unit prices of the drugs, the number of cases receiving
      the drugs, and the units needed per case.
    </p>

    <p>
      Costs vary widely by region as shown in the figure below. This is a
      function of the number of pregnant women in each region, the need for and
      coverage of each intervention.
    </p>

    <MhCcF6
      regionalUnitId={props.regionalUnitId}
      regionalUnitName={props.regionalUnitName}
    />
  </>
);

MhCcS8.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcS8;
