import PropTypes from "prop-types";
import FpCcF6 from "../Figures/FpCcF6";
import FpCcF7 from "../Figures/FpCcF7";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getMethodPlural,
  getQualitativeChangeDirection,
  toLowerCase,
} from "../../../../utilities";

const FpCcFutureUse = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/fp/cc/summaries/6/${props.regionId}/5`
  );

  const userTotal = data?.slice(-1)[0];

  return (
    <>
      {data && (
        <>
          <p>
            The total number of modern contraceptive users in{" "}
            {toLowerCase(props.regionName)} would{" "}
            {getQualitativeChangeDirection(userTotal.percentageChange)} from{" "}
            {formatNumberAsHumanReadable(userTotal.initial)} in 2019 to{" "}
            {formatNumberAsHumanReadable(userTotal.final)} in 2030 (
            {formatNumberAsHumanReadable(userTotal.change, {
              prefixWithSign: true,
            })}
            ,{" "}
            {formatPercentage(userTotal.percentageChange, {
              prefixWithSign: true,
            })}
            ).
          </p>

          <p>
            If current patterns in the evolution of method mix persist, growth
            would not be seen evenly across all methods. The use of{" "}
            {getMethodPlural(data[0].name)} would see the most rapid growth in
            absolute terms (
            {formatNumberAsHumanReadable(data[0].change, {
              prefixWithSign: true,
            })}
            ,{" "}
            {formatPercentage(data[0].percentageChange, {
              prefixWithSign: true,
            })}
            ).
          </p>
        </>
      )}

      <p>
        Changes in total users and users by method would differ by country as
        shown in the figure below.
      </p>

      <FpCcF6 regionId={props.regionId} regionName={props.regionName} />

      <p>
        Because gains and declines in the number of users of each method are
        relatively small when compared to the total number of users, the impact
        on method mix overall will be fairly minimal in most countries.
      </p>

      <FpCcF7 regionId={props.regionId} regionName={props.regionName} />
    </>
  );
};

FpCcFutureUse.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default FpCcFutureUse;
