const Citation = ({ className }) => (
  <p className={className}>
    To cite this report use,{" "}
    <em>
      Weinberger M, Eva G, Gold J, Bellows N, Reidy M, Sanders R, and Skibiak J.
      LEAP: Landscape and Projection of Reproductive Health Supply Needs.
      Reproductive Health Supplies Coalition. 2021.
    </em>
  </p>
);

export default Citation;
