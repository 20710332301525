import { makeStyles } from "@material-ui/styles";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { Typography } from "@material-ui/core";
import IncomeGroupMap from "./IncomeGroupMap";

const useStyles = makeStyles({
  map: {
    width: 800,
  },
});

const IncomeGroups = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h1">Income Groups</Typography>

      <p>
        LEAP includes 129 low- and middle-income countries
        <Footnote number={2}>
          Based on{" "}
          <ExternalLink
            href="https://datahelpdesk.worldbank.org/knowledgebase/articles/906519-world-bank-country-and-lending-groups"
            text="World Bank classifications as of June 2020"
          />
          . This list includes 135 countries, however, six countries are
          excluded due to unavailability of UN Population Division estimate of
          population and other key indicators: American Samoa, Dominica, Kosovo,
          Marshall Islands, Tuvalu, Federated States of Micronesia.
        </Footnote>
        . For the landscape reports these countries are further segmented into
        three income groups as defined by the World Bank: low-income countries
        (29 countries), lower-middle-income countries (49 countries), and
        upper-middle-income countries (51 countries). Countries are segmented
        based on their Gross National Income per capita
        <Footnote number={3}>
          The World Bank segments countries into income groups based on their
          Gross National Income per capita. Classifications used in this report
          are based on 2019 Gross National Income per capita using the World
          Bank Atlas Method: low-income: $1,035 or less; lower-middle-income:
          $1,036 - 4,045; upper-middle-income: $4,046-12,535; and high-income:
          $12,536 or more.{" "}
        </Footnote>
        . This segmentation allows for visibility into differential patterns
        across each income group and can help focus discussions on the different
        needs of countries at different income levels. For future projections,
        results are aggregated based on a country&#39;s current income group; we
        recognize however that countries may shift between income groups in the
        future.
      </p>

      <div className={classes.map}>
        <IncomeGroupMap />
      </div>

      <p>
        Throughout LEAP, results are presented in two ways. Comparisons of data
        sets are made across the three income groups (e.g. what share of
        contraceptive users live in each income group) as well as within each
        income group (e.g. mix of menstrual health products used within each
        income group). These two different perspectives provide useful insights
        into differences across the income groups.
      </p>
      <p>
        It is important to keep in mind, however, that while this segmentation
        provides a useful lens for viewing various patterns, there can still be
        great variation across the countries within an income group.
      </p>
      <p>Changes to countries since the 2019 Commodity Gap Analysis:</p>

      <ul>
        <li>
          Despite being classified as low- and middle-income, China and
          Venezuela were excluded from the 2019 analysis but have now been
          included.
        </li>
        <li>
          Three countries were included in 2019 that have been excluded in this
          report due to unavailability of published data: Dominica, Marshall
          Islands, Tuvalu.
        </li>
        <li>
          Three countries were included in the count in 2019 but had no data
          associated with them; these have been excluded in this report due to
          unavailability of published data: American Samoa, Kosovo, Federal
          States of Micronesia.
        </li>
        <li>
          Three countries that were included in 2019 are no longer classified as
          low- and middle-income countries (i.e. they shifted from
          upper-middle-income to high-income) and so are not included in this
          report: Mauritius, Nauru, Romania.
        </li>
        <li>
          One country that was not classified as low- and middle-income in 2019
          is now classified as upper-middle-income so has been included in this
          report: Argentina.
        </li>
        <li>
          The following ten countries were included in 2019 and are still
          included in this report, but their income group has shifted since the
          2019 report:
          <ul>
            <li>
              Low to lower-middle: Benin, Comoros, Nepal, Senegal, Tanzania,
              Zimbabwe
            </li>
            <li>Lower-middle to upper-middle: Georgia, Indonesia</li>
            <li>Lower-middle to low: Sudan</li>
            <li>Upper-middle to lower-middle: Algeria</li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default IncomeGroups;
