import { Link } from "@material-ui/core";
import Information from "../../Common/Box/Information";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const MhB4 = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <Information key="MH_b4" number="4" title="Future Scenarios">
      <p>
        <strong>Scenario 1: Maintain Coverage.</strong> This scenario accounts
        for changes in the number of pregnancies and births. However, we
        maintain current levels of intervention coverage, by which we mean the
        share of women needing an intervention that uses one of the seven
        maternal health drugs under review who receive the appropriate drug. We
        see this scenario as providing a baseline for comparison.
      </p>

      <p>
        <strong>Scenario 2: Increase Coverage.</strong> This scenario accounts
        for the same changes in the number of pregnancies and births as in
        Scenario 1 but increases coverage of included interventions to reach the
        coverage in the next highest income group by the final year.
        Upper-middle-income countries were shifted to the highest values within
        that income group. Where coverage in a given country already met or
        exceeded the next income level up, it was held constant.
      </p>

      <p>
        <strong>
          Scenario 3: Increase Coverage and Scale-Up Emerging Drugs.
        </strong>{" "}
        This scenario is the same as Scenario 2 but incorporates scale up of
        heat-stable carbetocin and tranexamic acid. Heat-stable carbetocin is
        assumed to be used in 54% of cases receiving uterotonics for PPH
        prevention during facility births by 2030 (thus reducing the use of
        other uterotonics). Tranexamic acid is assumed to be used in 50% of all
        cases receiving treatment for PPH by 2030 (in addition to the other
        uterotonics). Scale-up of these two emerging drugs is modeled following
        an S-shape curve to account for initial slow growth as the drugs are
        registered and introduced in more countries.
      </p>

      <p>
        See the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("maternal-health-future-scenarios")}
        >
          Reader&apos;s Guide
        </Link>{" "}
        for more detail about the development of these scenarios.
      </p>
    </Information>
  );
};

export default MhB4;
