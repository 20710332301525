import { makeStyles } from "@material-ui/styles";
import Information from "../../../Common/Box/Information";

const useStyles = makeStyles(() => ({
  content: {
    width: "100%",
    justifyContent: "flex-start",
  },
}));

const MhCcB1 = () => {
  const classes = useStyles();

  return (
    <Information key="MH_CC_b1" number="1" title="Scope" classes={classes}>
      <p>
        The methodology for LEAP was developed to produce results for all low-
        and middle-income countries. Inputs include default assumptions about
        prices which are drawn from global databases, and regional or income
        level estimates for incidence and prevalence of conditions. While we
        make regional results available, they should be viewed in this context
        and caution should be used in the application of these results for
        decision making.
      </p>
      <p>
        Regional results are for low- and middle-income countries within the
        region; these are not reflective of the full region as high income
        countries are excluded.
      </p>
    </Information>
  );
};

export default MhCcB1;
