import { makeStyles } from "@material-ui/styles";
import Table from "../../Common/Box/Table";
import initialData from "../../../data/initialData.json";
import useCsvDownload from "../../../hooks/useCsvDownload";
import { formatNumber } from "../../../utilities";

const number = 5;

const format = (n) =>
  formatNumber(n, {
    prefix: "$",
    significantDigits: 3,
  });

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& th:not(:first-child)": {
      width: "8rem",
    },
  },
  total: {
    backgroundColor: theme.palette.area.background,
  },
}));

const Component = () => {
  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const data = initialData["mh_t2"];

  const handleCsvDownload = () => {
    downloadCsv("/mh/tables/2/csv", `mh_t${number}.csv`);
  };

  return (
    <Table
      key="MH_t2"
      number={number.toString()}
      title="Total Cost for Seven Priority Maternal Health Drugs"
      subtitle="By Income Group, 2019"
      onDownloadCsv={handleCsvDownload}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th className={classes.total}>Total</th>
            <th>Iron-folic acid</th>
            <th>Antihypertensives</th>
            <th>Magnesium sulfate</th>
            <th>Uterotonics</th>
            <th>Metronidazole</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.incomeGroup}>
              <td>{item.incomeGroup}</td>
              <td className={classes.total}>{format(item.total)}</td>
              <td>{format(item.ironFolicAcid)}</td>
              <td>{format(item.antihypertensives)}</td>
              <td>{format(item.magnesiumSulfate)}</td>
              <td>{format(item.uterotonics)}</td>
              <td>{format(item.metronidazole)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Table>
  );
};

export default Component;
