import PropTypes from "prop-types";
import MhCsF9 from "../Figures/MhCsF9";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeChangeDirection,
  toLowerCase,
} from "../../../../utilities";

const calculateChange = (data, scenarioNumber) => {
  const scenarioIndex = scenarioNumber - 1;

  const initial = data?.[0].data[scenarioIndex] ?? 0;
  const final = data?.[2].data[scenarioIndex] ?? 0;
  const change = final - initial;

  return {
    initial,
    final,
    change,
    percentageChange: initial > 0 ? (change / initial) * 100 : 0,
  };
};

const MhCsS12 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/figures/9/${props.regionId}`
  );

  const scenario1 = calculateChange(data, 1);
  const scenario2 = calculateChange(data, 2);
  const scenario3 = calculateChange(data, 3);

  return (
    <>
      <ul>
        <li>
          If intervention coverage across {toLowerCase(props.regionName)} were
          maintained at current levels as envisioned under Scenario 1, costs
          would {getQualitativeChangeDirection(scenario1.percentageChange)} from{" "}
          {formatNumberAsHumanReadable(scenario1.initial, {
            prefix: "$",
          })}{" "}
          in 2019 to{" "}
          {formatNumberAsHumanReadable(scenario1.final, {
            prefix: "$",
          })}{" "}
          (
          {formatNumberAsHumanReadable(scenario1.change, {
            prefix: "$",
            prefixWithSign: true,
          })}
          ,{" "}
          {formatPercentage(scenario1.percentageChange, {
            prefixWithSign: true,
          })}
          ).
        </li>
        <li>
          Building in the increases in intervention coverage to levels
          envisioned under Scenario 2 would see costs{" "}
          {getQualitativeChangeDirection(scenario2.percentageChange)} to{" "}
          {formatNumberAsHumanReadable(scenario2.final, {
            prefix: "$",
          })}{" "}
          in 2030 (
          {formatNumberAsHumanReadable(scenario2.change, {
            prefix: "$",
            prefixWithSign: true,
          })}
          ,{" "}
          {formatPercentage(scenario2.percentageChange, {
            prefixWithSign: true,
          })}
          ).
        </li>
        <li>
          If the increase in coverage levels were combined with the scale-up of
          heat-stable carbetocin and tranexamic acid envisioned under Scenario
          3, costs would{" "}
          {getQualitativeChangeDirection(scenario3.percentageChange)} to{" "}
          {formatNumberAsHumanReadable(scenario3.final, {
            prefix: "$",
          })}{" "}
          (
          {formatNumberAsHumanReadable(scenario3.change, {
            prefix: "$",
            prefixWithSign: true,
          })}
          ,{" "}
          {formatPercentage(scenario3.percentageChange, {
            prefixWithSign: true,
          })}
          ). This reflects the current cost of heat-stable carbetocin relative
          to current uterotonics, and additional costs from cases receiving
          tranexamic acid.
        </li>
      </ul>

      <MhCsF9 regionId={props.regionId} regionName={props.regionName} />
    </>
  );
};

MhCsS12.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsS12;
