import { useState } from "react";
import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CustomReport from "../CustomReport";
import { useReadersGuide } from "../../../hooks/useReadersGuide";
import RegionalUnitChooser from "../RegionalUnitChooser";
import { getRegionalUnitName } from "../../../utilities";
import MhCcB1 from "./Boxes/MhCcB1";
import MaternalHealthIntroduction from "./MaternalHealthIntroduction";
import MaternalHealthScenarios from "./MaternalHealthScenarios";
import MhCcS1 from "./Summaries/MhCcS1";
import MhCcS2 from "./Summaries/MhCcS2";
import MhCcS3 from "./Summaries/MhCcS3";
import MhCcS4 from "./Summaries/MhCcS4";
import MhCcS5 from "./Summaries/MhCcS5";
import MhCcS6 from "./Summaries/MhCcS6";
import MhCcS7 from "./Summaries/MhCcS7";
import MhCcS8 from "./Summaries/MhCcS8";
import MhCcS9 from "./Summaries/MhCcS9";
import MhCcS10 from "./Summaries/MhCcS10";
import MhCcS11 from "./Summaries/MhCcS11";
import MhCcS12 from "./Summaries/MhCcS12";
import MhCcS13 from "./Summaries/MhCcS13";
import MhCcS14 from "./Summaries/MhCcS14";

const useStyles = makeStyles((theme) => ({
  boxes: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },
}));

const MaternalHealthComparison = () => {
  const [selectedRegionalUnitId, setSelectedRegionalUnitId] = useState(0);

  const readersGuide = useReadersGuide();

  const classes = useStyles();

  const selectedRegionalUnitName = getRegionalUnitName(
    selectedRegionalUnitId,
    true
  );

  const handleRegionalUnitChange = (regionalUnitId) => {
    setSelectedRegionalUnitId(regionalUnitId);
  };

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <CustomReport>
      <Typography variant="h1">
        Maternal Health Comparison Across {selectedRegionalUnitName}
      </Typography>

      <RegionalUnitChooser
        value={selectedRegionalUnitId}
        onChange={handleRegionalUnitChange}
      />

      <section id="introduction">
        <Typography variant="h2">Background</Typography>

        <div className={classes.boxes}>
          <MaternalHealthIntroduction>
            The <strong>Maternal Health Comparison Report</strong> provides
            estimates on the number of cases needing and receiving each drug,
            the volumes of maternal health drugs, and related drug costs for by
            region for the selected regional breakdown.
          </MaternalHealthIntroduction>

          <MhCcB1 />
        </div>
      </section>

      <section id="current">
        <Typography variant="h2">Where We Are Now</Typography>

        <div className={classes.boxes}>
          <Typography variant="h6">
            Current Use of Priority Maternal Health Drugs across{" "}
            {selectedRegionalUnitName}
          </Typography>

          <MhCcS1
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <p>
            Different interventions are needed throughout pregnancy and
            childbirth. In this section we present results based on the
            intervention or group of interventions using one of the seven
            priority health drugs. In cases where multiple drugs are used for
            the same/similar intervention results are presented together.
          </p>

          <MhCcS2
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <MhCcS3
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <MhCcS4
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <MhCcS5
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <MhCcS6
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <MhCcS7
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <Typography variant="h6">
            Current Cost of Seven Priority Maternal Health Drugs across{" "}
            {selectedRegionalUnitName}
          </Typography>

          <p>
            We estimate the cost of the drugs received by women during pregnancy
            and childbirth. It is important to note that we assess costs from
            the perspective of the institutional purchasers of the drugs
            (governments, international donors, etc.), as opposed to the women
            receiving the drugs, since they are often provided free of charge by
            the public health system. See the{" "}
            <Link
              underline="always"
              onClick={handleReadersGuideOpen("maternal-health-costs")}
            >
              Reader&apos;s Guide
            </Link>{" "}
            for more details.
          </p>

          <MhCcS8
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />
        </div>
      </section>

      <section id="future">
        <Typography variant="h2">Changes Ahead</Typography>

        <div className={classes.boxes}>
          <MaternalHealthScenarios />

          <Typography variant="h6">
            Future Use of Priority Maternal Health Drugs across{" "}
            {selectedRegionalUnitName}
          </Typography>

          <MhCcS9
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <MhCcS10
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <MhCcS11
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <MhCcS12
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <Typography variant="h6">
            Future Cost of Priority Maternal Health Drugs across{" "}
            {selectedRegionalUnitName}
          </Typography>

          <p>
            We project changes in the cost of the seven priority maternal health
            drugs plus the two emerging drugs, from 2019 to 2030, under each of
            the three Scenarios. As noted previously, we assess cost from the
            perspective of institutional procurers, as opposed to the women
            receiving the drugs, since they are often provided free of charge by
            the public health system. Note that all costs are based on current
            drug costs, held constant for all years through to 2030.
          </p>

          <p>
            If intervention coverage across all low- and middle-income countries
            were maintained at current levels as envisioned under Scenario 1,
            costs would remain relatively similar, increasing from $103 million
            in 2019 to $110 million in 2030 (+$6.36 million, +6%). Building in
            the increases in intervention coverage to levels envisioned under
            Scenario 2 would see costs increase more rapidly—growing by 60% from
            $103 million in 2019 to $166 million in 2030 (+$62.4 million). If
            the increase in coverage levels were combined with the scale-up of
            heat-stable carbetocin and tranexamic acid envisioned under Scenario
            3, costs would increase even more rapidly, growing by 74%, from $103
            million in 2019 to $180 million in 2030 (+$76.4 million). This
            reflects the current cost of heat-stable carbetocin relative to
            current uterotonics, and additional costs from cases receiving
            tranexamic acid.
          </p>

          <MhCcS13
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />

          <MhCcS14
            regionalUnitId={selectedRegionalUnitId}
            regionalUnitName={selectedRegionalUnitName}
          />
        </div>
      </section>
    </CustomReport>
  );
};

export default MaternalHealthComparison;
