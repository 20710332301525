import { useState } from "react";
import PropTypes from "prop-types";
import MhmCpF4 from "../Figures/MhmCpF4";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import SideBySide from "../../../Common/SideBySide";
import {
  formatNumberAsHumanReadable,
  getIndicatorDetails,
} from "../../../../utilities";

const calculateTotal = (scenario) =>
  scenario.incomeGroups.reduce(
    (accumulator, { value }) => accumulator + value,
    0
  );

const MenstruatorSummary = (props) => (
  <>
    <p>
      If current levels of use were maintained (Scenario 1) there would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data[0]))} users of{" "}
      {props.methodName.toLowerCase()} in 2030 across all low- and middle-income
      countries;{" "}
      {formatNumberAsHumanReadable(props.data[0].incomeGroups[0].value)} in
      low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data[0].incomeGroups[1].value)} in
      lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data[0].incomeGroups[2].value)} in
      upper-middle-income countries.
    </p>

    <p>
      Building in increases in use of purpose-made products (Scenario 2), there
      would be {formatNumberAsHumanReadable(calculateTotal(props.data[0]))}{" "}
      users of {props.methodName.toLowerCase()} in 2030 across all low- and
      middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data[1].incomeGroups[0].value)} in
      low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data[1].incomeGroups[1].value)} in
      lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data[1].incomeGroups[2].value)} in
      upper-middle-income countries.
    </p>

    <p>
      If the increase in use of purpose-made products is combined with a shift
      to reusable products (Scenario 3), there would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data[0]))} users of{" "}
      {props.methodName.toLowerCase()} in 2030 across all low- and middle-income
      countries;{" "}
      {formatNumberAsHumanReadable(props.data[2].incomeGroups[0].value)} in
      low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data[2].incomeGroups[1].value)} in
      lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data[2].incomeGroups[2].value)} in
      upper-middle-income countries.
    </p>
  </>
);

const CostSummary = (props) => (
  <>
    <p>
      If current levels of use were maintained (Scenario 1), the cost of{" "}
      {props.methodName.toLowerCase()} would be $
      {formatNumberAsHumanReadable(calculateTotal(props.data[0]))} in 2030
      across all low- and middle-income countries; $
      {formatNumberAsHumanReadable(props.data[0].incomeGroups[0].value)} in
      low-income countries, $
      {formatNumberAsHumanReadable(props.data[0].incomeGroups[1].value)} in
      lower-middle-income countries, and $
      {formatNumberAsHumanReadable(props.data[0].incomeGroups[2].value)} in
      upper-middle-income countries.
    </p>

    <p>
      Building in increases in use of purpose-made products (Scenario 2), the
      cost of {props.methodName.toLowerCase()} would be $
      {formatNumberAsHumanReadable(calculateTotal(props.data[1]))} in 2030
      across all low- and middle-income countries; $
      {formatNumberAsHumanReadable(props.data[1].incomeGroups[0].value)} in
      low-income countries, $
      {formatNumberAsHumanReadable(props.data[1].incomeGroups[1].value)} in
      lower-middle-income countries, and $
      {formatNumberAsHumanReadable(props.data[1].incomeGroups[2].value)} in
      upper-middle-income countries.
    </p>

    <p>
      If the increase in use of purpose-made products is combined with a shift
      to reusable products (Scenario 3), the cost of{" "}
      {props.methodName.toLowerCase()} would be $
      {formatNumberAsHumanReadable(calculateTotal(props.data[2]))} in 2030
      across all low- and middle-income countries; $
      {formatNumberAsHumanReadable(props.data[2].incomeGroups[0].value)} in
      low-income countries, $
      {formatNumberAsHumanReadable(props.data[2].incomeGroups[1].value)} in
      lower-middle-income countries, and $
      {formatNumberAsHumanReadable(props.data[2].incomeGroups[2].value)} in
      upper-middle-income countries.
    </p>
  </>
);

const QuantitySummary = (props) => (
  <>
    <p>
      If current levels of use were maintained (Scenario 1) there would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data[0]))}{" "}
      {props.methodName.toLowerCase()} purchased for use in 2030 across all low-
      and middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data[0].incomeGroups[0].value)} in
      low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data[0].incomeGroups[1].value)} in
      lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data[0].incomeGroups[2].value)} in
      upper-middle-income countries.
    </p>

    <p>
      Building in increases in use of purpose-made products (Scenario 2), there
      would be {formatNumberAsHumanReadable(calculateTotal(props.data[1]))}{" "}
      {props.methodName.toLowerCase()} purchased for use in 2030 across all low-
      and middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data[1].incomeGroups[0].value)} in
      low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data[1].incomeGroups[1].value)} in
      lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data[1].incomeGroups[2].value)} in
      upper-middle-income countries.
    </p>

    <p>
      If the increase in use of purpose-made products is combined with a shift
      to reusable products (Scenario 3), there would be{" "}
      {formatNumberAsHumanReadable(calculateTotal(props.data[2]))}{" "}
      {props.methodName.toLowerCase()} purchased for use in 2030 across all low-
      and middle-income countries;{" "}
      {formatNumberAsHumanReadable(props.data[2].incomeGroups[0].value)} in
      low-income countries,{" "}
      {formatNumberAsHumanReadable(props.data[2].incomeGroups[1].value)} in
      lower-middle-income countries, and{" "}
      {formatNumberAsHumanReadable(props.data[2].incomeGroups[2].value)} in
      upper-middle-income countries.
    </p>
  </>
);

const Summary = ({ methodName, indicatorId, ...rest }) => {
  switch (indicatorId) {
    case 1:
      return <CostSummary methodName={methodName} data={rest.data} />;

    case 2:
      return <MenstruatorSummary methodName={methodName} data={rest.data} />;

    case 3:
      return <QuantitySummary methodName={methodName} data={rest.data} />;

    default:
      return null;
  }
};

const MhmCpFutureIncomeGroups = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(2);

  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cp/figures/4/${props.methodId}/${selectedIndicatorId}`
  );

  const selectedIndicatorDetails = getIndicatorDetails(selectedIndicatorId);

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  return (
    <>
      <SideBySide
        component={
          <MhmCpF4
            methodName={props.methodName}
            indicatorId={selectedIndicatorId}
            indicatorName={selectedIndicatorDetails.name}
            indicatorQualifier={selectedIndicatorDetails.qualifier}
            data={data}
            onIndicatorChange={handleIndicatorChange}
          />
        }
      >
        {data ? (
          <Summary
            methodName={props.methodName}
            indicatorId={selectedIndicatorId}
            data={data}
          />
        ) : (
          <div />
        )}
      </SideBySide>
    </>
  );
};

MhmCpFutureIncomeGroups.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
};

export default MhmCpFutureIncomeGroups;
