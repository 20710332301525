import PropTypes from "prop-types";
import MhCcF11 from "../Figures/MhCcF11";

const MhCcS14 = (props) => {
  return (
    <>
      <p>
        The cost of the seven priority maternal health drugs and two emerging
        drugs varies across scenario. Use the dropdowns below to explore
        differences in the cost of drugs in 2030 across the scenarios.
      </p>

      <MhCcF11
        regionalUnitId={props.regionalUnitId}
        regionalUnitName={props.regionalUnitName}
      />
    </>
  );
};

MhCcS14.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcS14;
