import { Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";

const ContraceptionUsersBySector = () => (
  <div>
    <Typography variant="h1">Users by Sector</Typography>

    <p>
      Users of each method were divided based on the source of supplies (from
      either the public sector or one of multiple private sector sources
      <Footnote number={6}>
        For the purpose of this analysis all non-public sources are classified
        as private. This includes a small number of sources such as shops and
        acquaintances that may not otherwise be considered private sector.
      </Footnote>
      ) using the most recent DHS or MICS in a country or a sub-regional
      average. As done in the 2019 Commodity Gap Analysis, we made a further
      distinction between private sector users who purchase subsidized products
      (or receive them for free) and those who purchase non-subsidized products.
      This was done based on data and information shared by DKT International,
      PSI, MSI Reproductive Choices, the{" "}
      <ExternalLink
        href="https://www.dktinternational.org/contraceptive-social-marketing-statistics/"
        text="Social Marketing Statistics"
      />{" "}
      published annually by DKT International and data on India&apos;s social
      marketing program gathered from{" "}
      <ExternalLink
        href="https://main.mohfw.gov.in/publications/annual-report-department-health-and-family-welfare-2017-18"
        text="Department of Health and Family Welfare Annual Reports"
      />
      . For each method, a country specific market share of subsidized products
      was calculated by dividing the volume of subsidized products provided by
      NGOs and SMOs by our estimate of total private sector consumption. These
      calculations did not include the full set of services or products provided
      by NGOs and SMOs, but rather limited this to subsidized products that were
      ultimately provided through private sector channels
      <Footnote number={7}>
        Data was removed for delivery channels likely being picked up under
        &quot;public sector&quot; (e.g. mobile outreach services provided within
        public sector clinics and sales of commodities to governments), and for
        countries where programs are largely commercial, meaning little or no
        donor subsidy was attached to the provision (e.g. DKT Philippines
        receives almost no donor funding).
      </Footnote>
      .
    </p>
  </div>
);

export default ContraceptionUsersBySector;
