import { useEffect } from "react";
import PropTypes from "prop-types";
import { animateScroll } from "react-scroll";

const CustomReport = ({ children }) => {
  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 0,
    });
  }, []);

  return <div>{children}</div>;
};

CustomReport.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default CustomReport;
