import PropTypes from "prop-types";
import FpCpF4 from "../Figures/FpCpF4";

const FpCpS4 = (props) => (
  <>
    <FpCpF4 methodId={props.methodId} methodName={props.methodName} />
  </>
);

FpCpS4.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
};

export default FpCpS4;
