import { useState, useEffect } from "react";

const options = {
  root: null,
  rootMargin: "0px 0px 0px 0px",
  threshold: 0,
};

const useVisible = (ref) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisible(true);
            observer.unobserve(ref.current);
          }
        });
      }, options);

      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [ref]);

  return visible;
};

export default useVisible;
