import { useState } from "react";
import PropTypes from "prop-types";
import SideBySide from "../../../Common/SideBySide";
import FpCpF3 from "../Figures/FpCpF3";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatMethodLowerCase,
  formatPercentage,
  getMethodPlural,
  getMethodVerb,
  getQuantitiesAndCostsMethodPlural,
} from "../../../../utilities";

const Users = (props) => (
  <p>
    The top ten countries in terms of the number of{" "}
    {formatMethodLowerCase(props.methodName)} users account for{" "}
    {formatPercentage(props.share)} of all{" "}
    {formatMethodLowerCase(props.methodName)} users across low- and
    middle-income countries.
  </p>
);

const Quantities = (props) => (
  <p>
    The top ten countries in terms of the number of{" "}
    {getQuantitiesAndCostsMethodPlural(props.methodName)}{" "}
    {getMethodVerb(props.methodId)} account for {formatPercentage(props.share)}{" "}
    of all {getMethodPlural(props.methodName)} {getMethodVerb(props.methodId)}{" "}
    across low- and middle-income countries.
  </p>
);

const Costs = (props) => (
  <p>
    The top ten countries in terms of the supply cost of{" "}
    {getQuantitiesAndCostsMethodPlural(props.methodName)}{" "}
    {getMethodVerb(props.methodId)} account for {formatPercentage(props.share)}{" "}
    of the cost of {getMethodPlural(props.methodName)}{" "}
    {getMethodVerb(props.methodId)} across low- and middle-income countries.
  </p>
);

const Summary = ({ indicatorId, ...rest }) => {
  switch (indicatorId) {
    case 5:
      return <Users {...rest} />;

    case 3:
      return <Quantities {...rest} />;

    case 1:
      return <Costs {...rest} />;

    default:
      return null;
  }
};

const FpCpS3 = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(5);

  const { areaData: data } = useAreaQuery(
    `/custom/fp/cp/figures/3/${props.methodId}/${selectedIndicatorId}`
  );

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  return (
    <>
      <SideBySide
        component={
          <FpCpF3
            methodName={props.methodName}
            indicatorId={selectedIndicatorId}
            data={data?.countries}
            onIndicatorChange={handleIndicatorChange}
          />
        }
      >
        {data ? (
          <Summary
            indicatorId={selectedIndicatorId}
            methodId={props.methodId}
            methodName={props.methodName}
            share={data?.share}
          />
        ) : (
          <div />
        )}
      </SideBySide>
    </>
  );
};

FpCpS3.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
};

export default FpCpS3;
