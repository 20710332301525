import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { useReadersGuide } from "../../../../hooks/useReadersGuide";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  toLowerCase,
} from "../../../../utilities";

const calculateCostOfPurposeMadeProducts = (data) => {
  if (!data) {
    return 0;
  }

  let cost = 0;

  for (let series of data) {
    cost += series.data[0];
  }

  return cost;
};

const calculateDisposableProductShare = (data) => {
  if (!data) {
    return {
      purposeMade: 0,
      nonPurposeMade: 0,
    };
  }

  const totalCost = calculateCostOfPurposeMadeProducts(data);

  return (data[0].data[0] / totalCost) * 100;
};

const MhmCsCurrentCosts = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cs/figures/2/${props.regionId}`
  );

  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  const costOfPurposeMadeProducts = calculateCostOfPurposeMadeProducts(data);
  const disposableProductShare = calculateDisposableProductShare(data);

  return (
    <div>
      <p>
        We analyzed the cost of menstrual hygiene products from the perspective
        of the user, focusing only on purpose-made products. See the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("menstrual-hygiene-costs")}
        >
          Reader&apos;s Guide
        </Link>{" "}
        to learn more about how we develop these cost estimates.
      </p>

      <p>
        The total current cost of purpose-made products in{" "}
        {toLowerCase(props.regionName)} is $
        {formatNumberAsHumanReadable(costOfPurposeMadeProducts)}.
      </p>

      <p>
        Almost all product costs are for disposable products (
        {formatPercentage(disposableProductShare)}) due to the very small
        proportion of purpose-made products that are reusable products, coupled
        with the lower annualized costs of reusable products.
      </p>
    </div>
  );
};

MhmCsCurrentCosts.propTypes = {
  countrySelected: PropTypes.bool.isRequired,
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCsCurrentCosts;
