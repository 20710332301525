import { useState } from "react";
import PropTypes from "prop-types";
import ApacCcF4 from "../Figures/ApacCcF4";
import useAreaQuery from "../../../../hooks/useAreaQuery";

const ApacCcS6 = (props) => {
  const [selectedScenarioId, setSelectedScenarioId] = useState(5);
  const [selectedProductId, setSelectedProductId] = useState(1);

  const { areaData: data } = useAreaQuery(
    `/custom/apac/cc/figures/4/${props.regionalUnitId}/${selectedScenarioId}/${selectedProductId}`
  );

  const handleScenarioChange = (scenarioId) => {
    setSelectedScenarioId(scenarioId);
  };

  const handleProductChange = (productId) => {
    setSelectedProductId(productId);
  };

  return (
    <>
      <p>
        The pace of change differs by region, as shown in the figure below. Use
        the dropdowns to explore changes by method across scenarios.
      </p>

      <ApacCcF4
        regionalUnitName={props.regionalUnitName}
        scenarioId={selectedScenarioId}
        productId={selectedProductId}
        data={data}
        onScenarioChange={handleScenarioChange}
        onProductChange={handleProductChange}
      />
    </>
  );
};

ApacCcS6.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default ApacCcS6;
