import { Link, Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const AbortionDataSources = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Data Sources</Typography>

      <p>
        Data for prevalence and incidence of maternal health issues for this
        analysis mostly rely on regional averages, while estimates of
        pregnancies and births were developed for each country individually. The
        following were the primary data sources used in the development of our
        estimates:
      </p>

      <ul>
        <li>
          <ExternalLink
            href="https://www.avenirhealth.org/software-spectrum.php"
            text="FamPlan Module of Spectrum"
          />{" "}
          (using default baseline demographic data that aligns to the{" "}
          <ExternalLink
            href="https://population.un.org/wpp/"
            text="UN Population Prospects (WPP) 2019"
          />
          ), estimates of abortion rates from{" "}
          <ExternalLink
            href="https://www.thelancet.com/journals/langlo/article/PIIS2214-109X(20)30315-6/fulltext"
            text="Bearak et al (2020)"
          />
          , estimates of{" "}
          <Link
            underline="always"
            onClick={handleReadersGuideOpen("contraception-total-users")}
          >
            contraceptive use
          </Link>{" "}
          and{" "}
          <Link
            underline="always"
            onClick={handleReadersGuideOpen("contraception-users-by-method")}
          >
            method mix
          </Link>{" "}
          developed for LEAP, and{" "}
          <ExternalLink
            href="https://www.un.org/en/development/desa/population/theme/family-planning/cp_model.asp"
            text="UNPD projections of Unmet Need"
          />{" "}
          were used to estimate pregnancies and births.
        </li>
        <li>
          Estimates of the need for each included maternal health interventions
          were drawn from the existing literature, namely{" "}
          <ExternalLink
            href="https://pubmed.ncbi.nlm.nih.gov/24641531/"
            text="Abalos et al (2014)"
          />
          for hypertension, pre-eclampsia, and eclampsia;{" "}
          <ExternalLink
            href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0041114"
            text="Calvert et al (2012)"
          />
          for PPH, and the{" "}
          <ExternalLink
            href="https://www.thelancet.com/journals/langlo/article/PIIS2214-109X(20)30109-1/fulltext"
            text="WHO Global Maternal Sepsis Study (GLOSS) Research Group (2020)"
          />{" "}
          for maternal infection.
        </li>
        <li>
          Default data from the{" "}
          <ExternalLink
            href="https://www.livessavedtool.org/"
            text="Lives Saved Tool (LiST)"
          />{" "}
          was used for baseline coverage of interventions.
        </li>
        <li>
          WHO&apos;s{" "}
          <ExternalLink
            href="https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf"
            text="Managing complications in pregnancy and childbirth: a guide for midwives and doctors"
          />{" "}
          was used for dosing.
        </li>
        <li>
          The{" "}
          <ExternalLink
            href="https://supply.unicef.org/"
            text="UNICEF Supply Catalogue was used for drug prices"
          />
          .
        </li>
      </ul>
    </div>
  );
};

export default AbortionDataSources;
