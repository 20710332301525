import { Typography } from "@material-ui/core";
import RgMhT5 from "./RgMhT5";

const MaternalHealthFutureScenarios = () => (
  <div>
    <Typography variant="h1">Future Scenarios</Typography>

    <p>
      Based on consultations with experts, we selected three scenarios to
      explore potential changes in maternal health drug cost between 2019 and
      2030: 1) Maintain Coverage; 2) Increase Coverage; 3) Increase Coverage and
      Scale-Up Emerging Drugs. In all three scenarios the number of pregnancies
      and births changes in the same way, informed by changes in population,
      contraceptive use, and fertility rates. Coverage of the interventions that
      use the nine maternal health drugs included in our analysis changes for
      each scenario, as described below.
    </p>
    <p>
      <strong>Scenario 1: Maintain Coverage.</strong>
      This scenario accounts for changes in the number of pregnancies and
      births. However, we maintain current levels of intervention coverage, by
      which we mean the share of women needing an intervention that uses one of
      the seven maternal health drugs under review who receive the appropriate
      drug. We see this scenario as providing a baseline for comparison.
    </p>
    <p>
      <strong>Scenario 2: Increase Coverage.</strong> This scenario accounts for
      the same changes in the number of pregnancies and births as in Scenario 1
      but increases coverage of included interventions to reach the coverage in
      the next highest income group by the final year. Upper-middle-income
      countries were shifted to the highest values within that income group.
      Where coverage in a given country already met or exceeded the next income
      level up, it was held constant.
    </p>
    <p>
      <strong>
        Scenario 3: Increase Coverage and Scale-Up Emerging Drugs.
      </strong>{" "}
      This scenario is the same as Scenario 2 but incorporates scale up of
      heat-stable carbetocin and tranexamic acid. Heat-stable carbetocin is
      assumed to be used in 54% of cases receiving uterotonics for PPH
      prevention during facility births by 2030 (thus reducing the use of other
      uterotonics) (see Table 5 below). For tranexamic acid it is assumed that
      by 2030 50% of all cases receiving treatment for PPH would also receive
      tranexamic acid. Scale-up of these two emerging drugs is modeled following
      an S-shape curve to account for initial slow growth as the drugs are
      registered and introduced in more countries. These drugs are added to the
      projection in 2021 to account for recent registration efforts; some small
      quantities may be in use before 2021 but they were assumed to be
      negligible.
    </p>

    <RgMhT5 />
  </div>
);

export default MaternalHealthFutureScenarios;
