import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const MenstrualHygieneIntroduction = ({ children }) => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <>
      <p>
        Menstrual health and hygiene are important components of sexual and
        reproductive health and rights for women and girls. The ability to
        manage menstruation is linked to girls&apos; and women&apos;s ability to
        attend school, take part in society, work, and protect their health,
        including sexual and mental health. Menstrual health and hygiene are
        also linked to the fulfilment of girls&apos; and women&apos;s rights,
        and gender equality, as outlined in the Sustainable Development Goals
        (SDG), specifically SDG 6.2 which calls for access to adequate and
        equitable sanitation and hygiene for all{" "}
        <Footnote number={1}>
          <ExternalLink
            href="https://www.sdg6monitoring.org/indicators/target-6-2/"
            text="SDG6.2"
            useItalics={true}
          />
          : By 2030, achieve access to adequate and equitable sanitation and
          hygiene for all and end open defecation, paying special attention to
          the needs of women and girls and those in vulnerable situations.
        </Footnote>
        .
      </p>
      <p>{children}</p>
      <p>
        The &apos;unit&apos; of analysis for menstrual hygiene in LEAP is a
        menstruator. We use the term &apos;menstruator&apos; to refer to anyone
        who menstruates, including some, but not all, women, girls, transgender
        men, and non-binary people. In some places we use the term &apos;women
        and girls&apos; for readability purposes
        <Footnote number={2}>
          Amaya L, Marcatalli J, and Bhavaraju N
          <ExternalLink
            href="hhttps://www.fsg.org/publications/advancing-gender-equity-improving-menstrual-health"
            text="Advancing Gender Equity by Improving Menstrual Health"
            useItalics={true}
          />{" "}
          FSG. April 2020.
        </Footnote>
        . Additionally, the &apos;cost&apos; is the price paid by menstruators
        for menstrual hygiene products. While we use the term &apos;cost&apos;
        across LEAP, for menstrual hygiene this largely reflects out-of-pocket
        expenditure by consumers. In some contexts, products may be made freely
        available, for example distribution of pads in schools. However, limited
        data exist to provide visibility into who bears these costs.
      </p>
      <p>
        For information across all low- and middle-income countries, please
        refer to the{" "}
        <RouterLink to="/menstrual-hygiene">
          Menstrual Hygiene Landscape Report
        </RouterLink>
        . Additional methodological details can also be found in the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("menstrual-hygiene-scope")}
        >
          Reader&apos;s Guide
        </Link>
        .
      </p>
    </>
  );
};

MenstrualHygieneIntroduction.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MenstrualHygieneIntroduction;
