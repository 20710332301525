import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Information from "../../../Common/Box/Information";
import customReportLookups from "../../../../data/customReportLookups.json";
import { getCountryName } from "../../../../utilities";

const useStyles = makeStyles(() => ({
  content: {
    width: "100%",
    justifyContent: "flex-start",
  },
}));

const CountryDetails = () => (
  <p>
    The methodology for LEAP was developed to produce results for all low- and
    middle-income countries. While we make country results available, they
    should be viewed in this context.
  </p>
);

const CountryGroupDetails = ({ regionId }) => {
  const countryGroup = customReportLookups.countryGroups[regionId];

  return <div>{countryGroup.inclusionNote}</div>;
};

const FpCsB1 = ({ regionId }) => {
  const classes = useStyles();

  const countryName = getCountryName(regionId);

  return (
    <Information key="FP_CS_b1" number="1" title="Scope" classes={classes}>
      {countryName ? (
        <CountryDetails regionId={regionId} />
      ) : (
        <CountryGroupDetails regionId={parseInt(regionId, 10)} />
      )}
    </Information>
  );
};

FpCsB1.propTypes = {
  regionId: PropTypes.number.isRequired,
};

export default FpCsB1;
