import { useState } from "react";
import PropTypes from "prop-types";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import MhCpF1 from "../Figures/MhCpF1";
import SideBySide from "../../../Common/SideBySide";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  if (!data) {
    return {
      total: 0,
      lowIncomeShare: 0,
      lowerMiddleIncomeShare: 0,
      upperMiddleIncomeShare: 0,
    };
  }

  return {
    total: data?.[3].value ?? 0,
    lowIncomeShare: data?.[0].share ?? 0,
    lowerMiddleIncomeShare: data?.[1].share ?? 0,
    upperMiddleIncomeShare: data?.[2].share ?? 0,
  };
};

const Cases = (props) => (
  <>
    <p>
      Currently {formatNumberAsHumanReadable(props.metrics.total)} cases receive{" "}
      {props.methodName.toLowerCase()} across low- and middle-income countries.
    </p>

    <p>
      {formatPercentage(props.metrics.lowIncomeShare)} of cases are in
      low-income countries,{" "}
      {formatPercentage(props.metrics.lowerMiddleIncomeShare)} are in
      lower-middle-income countries, and the remaining{" "}
      {formatPercentage(props.metrics.upperMiddleIncomeShare)} are in
      upper-middle-income countries.
    </p>
  </>
);

const Quantities = (props) => (
  <>
    <p>
      Currently {formatNumberAsHumanReadable(props.metrics.total)} units of{" "}
      {props.methodName.toLowerCase()} are provided across low- and
      middle-income countries.
    </p>

    <p>
      {formatPercentage(props.metrics.lowIncomeShare)} of units are used in
      low-income countries{" "}
      {formatPercentage(props.metrics.lowerMiddleIncomeShare)} are used in
      lower-middle-income countries, and the remaining{" "}
      {formatPercentage(props.metrics.upperMiddleIncomeShare)} are used in
      upper-middle-income countries.
    </p>
  </>
);

const Costs = (props) => (
  <>
    <p>
      Currently the cost of {props.methodName.toLowerCase()} is{" "}
      {formatNumberAsHumanReadable(props.metrics.total, {
        prefix: "$",
      })}{" "}
      across low- and middle-income countries.
    </p>

    <p>
      {formatPercentage(props.metrics.lowIncomeShare)} of costs come from
      low-income countries,{" "}
      {formatPercentage(props.metrics.lowerMiddleIncomeShare)} are from
      lower-middle-income countries, and the remaining{" "}
      {formatPercentage(props.metrics.upperMiddleIncomeShare)} are from
      upper-middle-income countries.
    </p>
  </>
);

const Summary = ({ indicatorId, ...rest }) => {
  switch (indicatorId) {
    case 1:
      return <Costs {...rest} />;

    case 3:
      return <Quantities {...rest} />;

    case 4:
      return <Cases {...rest} />;

    default:
      return null;
  }
};

const MhCpS1 = (props) => {
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(4);

  const { areaData: data } = useAreaQuery(
    `/custom/mh/cp/figures/1/${props.methodId}/${selectedIndicatorId}`
  );

  const metrics = calculateMetrics(data);

  const handleIndicatorChange = (indicatorId) => {
    setSelectedIndicatorId(indicatorId);
  };

  return (
    <SideBySide
      component={
        <MhCpF1
          methodName={props.methodName}
          indicatorId={selectedIndicatorId}
          data={data?.slice(0, -1)}
          onIndicatorChange={handleIndicatorChange}
        />
      }
    >
      <Summary
        methodName={props.methodName}
        indicatorId={selectedIndicatorId}
        metrics={metrics}
      />

      <p>Use the dropdown to explore other indicators.</p>
    </SideBySide>
  );
};

MhCpS1.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
};

export default MhCpS1;
