import { Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";

const ContraceptionCosts = () => (
  <div>
    <Typography variant="h1">Supply Costs</Typography>

    <p>
      The cost reflects the quantity of supplies used multiplied by their unit
      price. This includes both the cost of the contraceptive commodity itself
      as well as associated clinical supplies
      <Footnote number={10}>
        Associated clinical supply costs are added for sterilization based on
        kits. For implants, IUDs, and injectables supply costs are added for the
        public sector only. Supply costs are based on data published in{" "}
        <ExternalLink
          href="https://www.guttmacher.org/report/adding-it-up-investing-in-sexual-reproductive-health-2019-methodology"
          text="Adding It Up 2019 Methodology Tables"
          useItalics={true}
        />
        .
      </Footnote>
      . The cost does not include other cost factors such as fees paid for
      necessary medical services or required visits, service delivery costs,
      taxes, freight, or tariffs
      <Footnote number={11}>
        However, in the case of private sector costs some of these factors may
        be inherently included in the price that the client is ultimately
        charged at the point of sale.
      </Footnote>
      . All costs are in US dollars and unit prices are held constant into the
      future (e.g. no inflation).
    </p>
    <p>
      For our analysis, costs were based on the last price paid for a
      contraceptive before it is actually used or obtained by the user. We
      employ this definition because it is at this &quot;handover&quot; point in
      a product&apos;s value chain where price acts as the principal cost
      barrier to access. For products made available through the public sector,
      cost is calculated on the basis of unit costs paid by procurers
      (Governments and/or donors) before it is distributed, generally for free,
      to users. In the private sector, costs reflect the prices paid by users
      themselves, typically through direct out-of-pocket expenditures.
    </p>
    <p>
      Country specific public sector unit prices were estimated from two
      sources: (1) RHInterchange (now the RHViz) data on procurement volumes and
      values using shipments from 2017 to 2019
      <Footnote number={12}>
        Data was cleaned to identify errors and remove extreme outliers. Price
        data was available for 122 countries, though availability differs by
        method (most coverage for condoms (N=122), least coverage for emergency
        contraception (N=65)).
      </Footnote>
      , and (2) price estimates for eight Latin American and Caribbean countries
      obtained through an RHSC survey that asked governments to identify the
      prices of contraceptive commodities they procure
      <Footnote number={13}>
        RHSC survey included: Honduras, El Salvador, Guatemala, Nicaragua,
        Mexico, Paraguay, Bolivia, and Peru.
      </Footnote>
      . For sterilization, a supply cost of $5.20 is applied to all countries
      <Footnote number={14}>
        Based on Adding it Up 2019 Methodology Report Appendix Table MA8.5; a
        global supply cost of $0.52 annualized, multiplied by 10 (CYP factor for
        sterilization) to get an un-annualized cost of $5.20. Male sterilization
        procedures have a lower supply cost, but the vast majority of
        sterilizations are female so that cost was used in this work.
      </Footnote>
      , no other cost is included for sterilization as there is no
      &apos;commodity&apos;. The public sector cost also includes the value of
      the subsidy for subsidized products purchased by users in the private
      sector
      <Footnote number={15}>
        This was calculated as the difference between the public sector price
        and the private subsidized price for each product; therefore, sharing
        the total cost between the two sectors.
      </Footnote>
      .
    </p>
    <p>
      Country specific private sector unit prices were estimated at two price
      points (subsidized and non-subsidized) drawing on a wide-range of sources
      including IQVIA, FP Watch, SHOPS Plus Private Sector Assessments, Track20
      M&amp;E Officer retail audits (unpublished), DHS &amp; PMA2020 analysis of
      prices paid by private sector users. A detailed methodology for this is
      documented elsewhere
      <Footnote number={16}>
        More can be found in this publication: Weinberger M, Bellows N, Stover J{" "}
        <ExternalLink
          href="https://gh.bmj.com/content/6/4/e004635"
          text="Estimating private sector out-of-pocket expenditures on family planning commodities in low-and-middle-income countries"
          useItalics={true}
        />{" "}
        BMJ Global Health 2021.
      </Footnote>
      . This was done for four methods with sufficient data: implants, IUDs,
      injectables, and pills. For the remaining methods, we used the public
      sector unit prices
      <Footnote number={17}>
        This was done for sterilization, and other methods due to limited data
        availability. For condoms there is large variation in price across
        brands and it is difficult to accurately capture this variation;
        therefore, using a single price for all condom consumption was the more
        conservative approach.
      </Footnote>
      . While we do our best to isolate the commodity cost in some cases these
      prices may include the cost of associated clinical services.
    </p>
    <p>
      Where country specific unit prices were not available, sub-regional
      averages of the available data were used.
    </p>
  </div>
);

export default ContraceptionCosts;
