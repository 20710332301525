import PropTypes from "prop-types";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeChangeDirection,
  toLowerCase,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  if (!data) {
    return {
      initial: 0,
      final: 0,
      change: 0,
      percentageChange: 0,
    };
  }

  const initial = data[0].menstruators;
  const final = data[1].menstruators;
  const change = final - initial;

  return {
    initial,
    final,
    change,
    percentageChange: (change / initial) * 100,
  };
};

const MhmCcChangeInMenstruators = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cc/summaries/3/${props.regionId}`
  );

  const metrics = calculateMetrics(data);

  return (
    <div>
      <p>
        In all scenarios, the total number of menstruators in{" "}
        {toLowerCase(props.regionName)} would{" "}
        {getQualitativeChangeDirection(metrics.percentageChange)} from{" "}
        {formatNumberAsHumanReadable(metrics.initial)} in 2019 to{" "}
        {formatNumberAsHumanReadable(metrics.final)} in 2030 (
        {formatNumberAsHumanReadable(metrics.change, {
          prefixWithSign: true,
        })}
        ,{" "}
        {formatPercentage(metrics.percentageChange, {
          prefixWithSign: true,
        })}
        ) .
      </p>

      <p>
        The pace of change differs by country, as shown in the figure below. Use
        the dropdowns to explore changes by product across scenarios.
      </p>
    </div>
  );
};

MhmCcChangeInMenstruators.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCcChangeInMenstruators;
