import { Link } from "@material-ui/core";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const ContraceptionScenarios = (props) => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <>
      <p>
        In this LEAP analysis we explore a single future projection. We draw on
        the median-variant estimates of contraceptive users produced by the UN
        Population Division under the expectation that it provides the most
        likely trajectory forward in each country. We then layer in projected
        changes to method mix informed by recent patterns of change at the
        regional level (see the 
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-future-scenarios")}
        >
          Reader&apos;s Guide
        </Link>
        ). Many factors will influence shifts in method mix, and we do not
        purport to be predictive. Rather, we build on existing evidence and look
        at where we would be if recent patterns continue. This provides a
        starting point for discussions on what lies ahead. The further into the
        future our projections run, the less certainty we have; this uncertainty
        is represented graphically in the figures with a dotted line.
      </p>
    </>
  );
};

export default ContraceptionScenarios;
