import PropTypes from "prop-types";
import FpCpF6 from "../Figures/FpCpF6";

const FpCpS6 = (props) => {
  return (
    <>
      <FpCpF6 methodId={props.methodId} methodName={props.methodName} />
    </>
  );
};

FpCpS6.propTypes = {
  methodId: PropTypes.number.isRequired,
  methodName: PropTypes.string.isRequired,
};

export default FpCpS6;
