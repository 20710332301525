import { Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";
import Footnote from "../../Common/Footnote";

const MenstrualHygieneCosts = () => (
  <div>
    <Typography variant="h1">Product Costs</Typography>

    <p>
      The cost reflects the quantity of products used multiplied by their unit
      price. For menstrual hygiene, costs are from the perspective of the user,
      and represent the retail price that is paid at point of sale. All costs
      are in US dollars and unit prices are held constant into the future (e.g.
      no inflation).
    </p>
    <p>
      For disposable menstrual hygiene products, country-specific unit prices
      were available for a small subset of countries based on data purchased
      from MarketsandMarkets
      <Footnote number={6}>
        MarketsandMarkets (2020){" "}
        <ExternalLink
          href="https://www.marketsandmarkets.com/Market-Reports/feminine-hygiene-product-market-69114569.html"
          text="Feminine Hygiene Products Market Global Forecast to 2025"
          useItalics={true}
        />
        .
      </Footnote>
      . Country specific prices were used from: Argentina, Brazil, China, India,
      Indonesia, Malaysia, Mexico, South Africa, and Thailand; additional price
      data from non-low- and middle-income countries was also used to inform
      regional averages. These data were used to create regional unit prices
      used for other countries. For reusable products, a global unit price of
      $2.00 per reusable pad and $10.00 for a menstrual cup were used based on a
      review of available products and expert consultations.
    </p>
    <p>
      Note that due to limited data, our analysis doesn't account for the fact
      that some products are made available for free or subsidized, for example
      through schools. However, free or subsidized distribution is likely to
      make up only a small proportion of products.
    </p>
  </div>
);

export default MenstrualHygieneCosts;
