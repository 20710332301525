import { useState } from "react";
import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import { capitalizePhrase, getTooltip } from "../../../../utilities";

const number = 4;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Disposable":
      return theme.palette.areas.menstrualHygiene.methods.disposable;

    case "Reusable":
      return theme.palette.areas.menstrualHygiene.methods.reusable;

    case "Non-purpose-made":
      return "#ddd";

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = data.map((method) => ({
    name: method.name,
    color: getSeriesColor(method.name, theme),
    data: method.scenarios.map((scenario) => scenario.menstruators),
  }));

  return {
    chart: {
      type: "column",
      width: 500,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: [
        "1: Maintain product use",
        "2: Increase purpose-made",
        "3: Increase purpose-made & reusable",
      ],
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          textAlign: "center",
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        format: absolute ? undefined : "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: absolute ? "normal" : "percent",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Menstruators", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: {
          text: data.incomeGroup,
        },
      },
      enabled: false,
    },
    series,
  };
};

const MhmCcF4 = (props) => {
  const [comparisonMode, setComparisonMode] = useState("relative");

  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  return (
    <Figure
      id="MHM_CC_f4"
      number={number.toString()}
      fileStem={`mhm_cs_f${number}`}
      title="Future Use of Menstrual Hygiene Products"
      subtitle={`${capitalizePhrase(
        props.regionName
      )} by Future Scenario, 2030`}
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        />
      }
      chartOptions={
        props.data &&
        options(props.data, {
          comparisonMode,
        })
      }
    />
  );
};

MhmCcF4.propTypes = {
  regionName: PropTypes.string.isRequired,
};

export default MhmCcF4;
