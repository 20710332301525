import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Route, Routes } from "react-router";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAnalytics } from "../hooks/useAnalytics";
import { useReadersGuide } from "../hooks/useReadersGuide";
import Abortion from "./Abortion/Abortion";
import Contraception from "./Contraception/Contraception";
import CustomReports from "./CustomReports/CustomReports";
import Introduction from "./Introduction/Introduction";
import MaternalHealth from "./MaternalHealth/MaternalHealth";
import MenstrualHygiene from "./MenstrualHygiene/MenstrualHygiene";
import NavBar from "./NavBar/NavBar";
import ReadersGuide from "./ReadersGuide/ReadersGuide";

const useStyles = makeStyles((theme) => ({
  component: {
    minWidth: 1152,
  },
  readersGuide: {
    height: `calc(100% - ${theme.appBar.toolbar.height}px)`,
    top: theme.appBar.toolbar.height,
    width: "100%",
  },
}));

const Main = () => {
  const analytics = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    analytics.trackPageView({
      url: location.pathname,
    });
  }, [analytics, location]);

  const readersGuide = useReadersGuide();

  const classes = useStyles();

  return (
    <div className={classes.component}>
      <NavBar
        onOpenReadersGuide={() => {
          readersGuide.setSlug("overview-of-leap");
          readersGuide.setOpen(true);
        }}
      />
      <div
        style={{
          marginTop: 140,
        }}
      >
        <Routes>
          <Route path="/" element={<Introduction />} />
          <Route path="/contraception" element={<Contraception />} />
          <Route path="/menstrual-hygiene" element={<MenstrualHygiene />} />
          <Route path="/abortion" element={<Abortion />} />
          <Route path="/maternal-health" element={<MaternalHealth />} />
          <Route path="/custom/*" element={<CustomReports />} />
          <Route element={<Navigate to="/" />} />
        </Routes>
      </div>

      <Drawer
        open={readersGuide.open}
        anchor="right"
        classes={{
          paper: classes.readersGuide,
        }}
        onClose={() => {
          readersGuide.setOpen(false);
        }}
      >
        <ReadersGuide
          onClose={() => {
            readersGuide.setOpen(false);
          }}
        />
      </Drawer>
    </div>
  );
};

export default Main;
