import { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Box from "../../../Common/Box/Box";
import Chart from "../../../Common/Box/Chart";
import Legend from "../../../Common/Box/Legend";
import { chartDownloadImageScaleFactor } from "../../../../constants";
import exportCharts from "../../../../export";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import theme from "../../../../theme";
import {
  getIncomeGroup,
  getMethodDescription,
  getTooltip,
} from "../../../../utilities";

const key = "MH_CS_f8";
const number = 8;

const getMaximumValue = (data) => {
  let maximumValue = 0;

  for (let method of data) {
    for (let scenario of method.scenarios)
      for (let year of scenario.years) {
        maximumValue = Math.max(maximumValue, year.value);
      }
  }

  return maximumValue;
};

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Maintain":
      return theme.palette.scenarios.scenario1;

    case "Inc. coverage":
      return theme.palette.scenarios.scenario2;

    case "Inc. coverage & new tech.":
      return theme.palette.scenarios.scenario3;

    default:
      return "black";
  }
};

const options = (data, maximumValue, showYAxis) => (theme) => {
  const yAxisWidth = showYAxis ? 45 : 0;

  const enableTitleTooltip = [
    "IFA",
    "Mag sulfate",
    "HSC",
    "TXA",
    "MTN",
  ].includes(data.method);

  return {
    chart: {
      type: "spline",
      height: 350,
      backgroundColor: null,
      width: yAxisWidth + 110,
      events: {
        load: function () {
          if (!enableTitleTooltip) {
            return;
          }

          const chart = this;

          chart.title.on("mouseover", () => {
            chart.myLabel = chart.renderer
              .label(
                getMethodDescription(chart.title.textStr),
                yAxisWidth + 55,
                30,
                "rectangle",
                null,
                null,
                true
              )
              .css({
                color: "#FFFFFF",
                textAlign: "center",
              })
              .attr({
                align: "center",
                fill: "rgba(0, 0, 0, 0.75)",
                padding: 8,
                r: 4,
                textAnchor: "center",
                // width: 80,
              })
              .add()
              .toFront();
          });

          chart.title.on("mouseout", () => {
            if (chart.myLabel) {
              chart.myLabel.destroy();
            }
          });
        },
      },
    },
    title: {
      text: data.method,
      align: "center",
      x: showYAxis ? 28 : undefined,
      style: {
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
    },
    xAxis: {
      type: "datetime",
      min: 2017,
      max: 2032,
      tickPositions: [2019, 2025, 2030],
      tickLength: 0,
      labels: {
        formatter: function () {
          return [2019, 2030].includes(this.value) ? this.value : null;
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      min: 0,
      max: maximumValue,
      labels: {
        enabled: showYAxis,
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      series: {
        lineWidth: 3,
        marker: {
          symbol: "circle",
          lineWidth: 3,
          fillColor: "white",
        },
        zoneAxis: "x",
        zones: [
          {
            value: 2025,
          },
          {
            dashStyle: "shortDot",
          },
        ],
        events: {
          mouseOut: function () {
            this.chart.tooltip.hide(0);
          },
        },
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Cases", this.y);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      enabled: false,
    },
    series: data.scenarios.map((scenario) => {
      const color = getSeriesColor(scenario.name, theme);

      return {
        ...scenario,
        color,
        marker: {
          lineColor: color,
        },
        data: scenario.years.map((year) => ({
          x: year.year,
          y: year.value,
          marker: {
            enabled: [2019, 2025, 2030].includes(year.year),
          },
        })),
      };
    }),
  };
};

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  facet: {
    margin: "2rem 0",
  },
  facetPanel: {
    display: "flex",
    justifyContent: "space-between",
  },
  legend: {
    marginTop: 0,
  },
}));

const MhCsF8 = (props) => {
  const highchartRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/figures/8/${props.regionId}/4`
  );

  const classes = useStyles();

  const yAxisMaximum = data ? getMaximumValue(data) * 1.02 : 0;

  return (
    <Box
      key={key}
      caption={`Figure ${number}`}
      title="Change in Cases that Receive Seven Priority Maternal Health Drugs Plus Two Emerging Drugs"
      subtitle={`${props.regionName} by Future Scenario and Drug, 2019-2030`}
      selection={getIncomeGroup(0)}
      onDownloadPng={() => {
        exportCharts(
          {
            chart1: highchartRefs[0].current,
            chart2: highchartRefs[1].current,
            chart3: highchartRefs[2].current,
            chart4: highchartRefs[3].current,
            chart5: highchartRefs[4].current,
            chart6: highchartRefs[5].current,
            chart7: highchartRefs[6].current,
            chart8: highchartRefs[7].current,
            chart9: highchartRefs[8].current,
          },
          {
            filename: key,
            scale: chartDownloadImageScaleFactor,
          }
        );
      }}
    >
      <div className={classes.content}>
        <div className={classes.facetPanel}>
          {(data ?? []).map((method, index) => (
            <div key={method.method} className={classes.facet}>
              <Chart
                highchartRef={highchartRefs[index]}
                options={options(method, yAxisMaximum, index === 0)}
              />
            </div>
          ))}
        </div>

        <div className={classes.legend}>
          <Legend
            series={[
              {
                name: "3: Increase coverage and scale-up emerging drugs ",
                color: getSeriesColor("Inc. coverage & new tech.", theme),
              },
              {
                name: "2: Increase coverage",
                color: getSeriesColor("Inc. coverage", theme),
              },
              {
                name: "1: Maintain coverage",
                color: getSeriesColor("Maintain", theme),
              },
            ].reverse()}
            markerStyle="point"
            orientation="horizontal"
          />
        </div>
      </div>
    </Box>
  );
};

MhCsF8.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsF8;
