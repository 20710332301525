import PropTypes from "prop-types";
import MhCcF9 from "../Figures/MhCcF9";

const MhCcS12 = (props) => {
  return (
    <>
      <p>
        The number of cases receiving drug(s) varies across scenario. Use the
        dropdowns below to explore differences in the number of cases receiving
        each drug in 2030 across the scenarios.
      </p>

      <MhCcF9
        regionalUnitId={props.regionalUnitId}
        regionalUnitName={props.regionalUnitName}
      />
    </>
  );
};

MhCcS12.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcS12;
