import PropTypes from "prop-types";
import ExternalLink from "../../../Common/ExternalLink";
import Footnote from "../../../Common/Footnote";
import SideBySide from "../../../Common/SideBySide";
import MhCsF4 from "../Figures/MhCsF4";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getQualitativeComparison,
  getRegionName,
  toLowerCase,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  const notReceived = data?.[0].methods.slice(-1)[0].services ?? 0;
  const lmicNotReceived = data?.slice(-1)[0].methods.slice(-1)[0].services ?? 1;

  return {
    methodShare: data?.[0].share ?? 0,
    notReceived,
    shortfallShare: (notReceived / lmicNotReceived) * 100,
  };
};

const MhCsS5 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/figures/1/${props.regionId}/101`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        Misoprostol and oxytocin (both uterotonics) can be used for induction of
        labor and to prevent and treat PPH, with oxytocin additionally used for
        augmentation. Our estimates are based on a global assumption that 8% of
        women need induction, 20% need augmentation, all births need PPH
        prevention, and 11% need PPH treatment
        <Footnote number={6}>
          JSI and SIAPS. 2015{" "}
          <ExternalLink
            href="https://marketbookshelf.com/publications/quantification-health-commodities-forecasting-consumption-select-reproductive-maternal-newborn-child-health-commodities/"
            text="Quantification of Health Commodities: RMNCH Supplement Forecasting Consumption of Select Reproductive, Maternal, Newborn and Child Health Commodities"
            useItalics={true}
          />{" "}
          Submitted to the US Agency for International Development by the
          Systems for Improved Access to Pharmaceuticals and Services (SIAPS)
          Program. Arlington, VA: Management Sciences for Health. Submitted to
          the United Nations Children's Fund by JSI, Arlington, VA: JSI Research
          & Training Institute, Inc.
        </Footnote>
        <Footnote number={7}>
          Calvert C, Thomas SL, Ronsmans C, Wagner KS, Adler AJ, Filippi V.  {" "}
          <ExternalLink
            href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0041114"
            text="Identifying regional variation in the prevalence of postpartum hemorrhage: a systematic review and meta-analysis"
            useItalics={true}
          />{" "}
          PLoS One. 2012;7(7):e41114.
        </Footnote>
        . In Figure 4, we present cases requiring these interventions,
        separating each into cases where uterotonics were provided and those
        where they were not.
      </p>

      <SideBySide
        component={
          <MhCsF4
            regionId={props.regionId}
            regionName={props.regionName}
            regionShortName={getRegionName(props.regionId, "short")}
            data={data}
          />
        }
        componentPosition="right"
      >
        <p>
          Results are shown for {toLowerCase(props.regionName)} in comparison to
          the three country-level income groups.
        </p>

        <p>
          In {toLowerCase(props.regionName)}, uterotonics are provided in{" "}
          {formatPercentage(metrics.methodShare)} of cases. This is{" "}
          {getQualitativeComparison(metrics.methodShare, 27)} low-income
          countries (27%) and{" "}
          {getQualitativeComparison(metrics.methodShare, 60)}{" "}
          upper-middle-income countries (60%).
        </p>

        <p>
          In {toLowerCase(props.regionName)}{" "}
          {formatNumberAsHumanReadable(metrics.notReceived)} cases do not
          receive the recommended drugs; this represents{" "}
          {formatPercentage(metrics.shortfallShare)} of the shortfall across all
          low- and middle-income countries.
        </p>
      </SideBySide>
    </>
  );
};

MhCsS5.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsS5;
