import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import FpCcF3 from "../Figures/FpCcF3";
import FpCcF4 from "../Figures/FpCcF4";
import FpCcF5 from "../Figures/FpCcF5";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { useReadersGuide } from "../../../../hooks/useReadersGuide";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getMethodPlural,
  toLowerCase,
} from "../../../../utilities";

const calculateMetricsForSummary3 = (data) => {
  const methodCostDictionary = data.reduce((accumulator, country) => {
    for (let method of country.methods) {
      if (!accumulator[method.name]) {
        accumulator[method.name] = 0;
      }

      accumulator[method.name] += method.value;
    }

    return accumulator;
  }, {});

  const methodCosts = Object.keys(methodCostDictionary).map((method) => ({
    name: method,
    costs: methodCostDictionary[method],
  }));

  const costOfContraception = methodCosts.reduce(
    (accumulator, { costs }) => accumulator + costs,
    0
  );

  const methodCostShares = methodCosts
    .map((method) => ({
      ...method,
      percentage: (method.costs / costOfContraception) * 100,
    }))
    .sort((a, b) => b.percentage - a.percentage);

  const mostCommonMethod = methodCostShares[0];

  return {
    mostCommonMethod,
  };
};

const calculateMetricsForSummary4 = (data) => {
  const usersOfContraception = data.reduce(
    (accumulator, { total }) => accumulator + total,
    0
  );

  const usersOfPublicSectorContraception = data.reduce(
    (accumulator, country) => accumulator + country.sectors[0].value,
    0
  );

  const countryPublicSectorShares = data.map(
    (country) => (country.sectors[0].value / country.total) * 100
  );

  return {
    publicSectorShare:
      (usersOfPublicSectorContraception / usersOfContraception) * 100,
    smallestPublicSectorShare: Math.min(...countryPublicSectorShares),
    largestPublicSectorShare: Math.max(...countryPublicSectorShares),
  };
};

const FpCsCurrentCosts = (props) => {
  const [selectedSectorId, setSelectedSectorId] = useState(0);

  const { areaData: costData } = useAreaQuery(
    `/custom/fp/cc/summaries/1/${props.regionId}/1`
  );

  const { areaData: methodData } = useAreaQuery(
    `/custom/fp/cc/figures/1/${props.regionId}/1/${selectedSectorId}`
  );

  const { areaData: sectorData } = useAreaQuery(
    `/custom/fp/cc/figures/2/${props.regionId}/1/${0}`
  );

  const { areaData: publicSectorShareData } = useAreaQuery(
    `/custom/fp/cc/summaries/5/${props.regionId}`
  );

  const readersGuide = useReadersGuide();

  const metricsForSummary3 =
    methodData && calculateMetricsForSummary3(methodData);

  const metricsForSummary4 =
    sectorData && calculateMetricsForSummary4(sectorData);

  const handleSectorChange = (sectorId) => {
    setSelectedSectorId(sectorId);
  };

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <>
      <p>
        We define &quot;cost&quot; as the last price paid for a contraceptive
        method before it is actually used or obtained by the consumer. We employ
        this definition because it is at this &quot;handover&quot; point in a
        product&apos;s value chain where price acts as the principal cost
        barrier to access. For products made available through the public
        sector, cost is calculated on the basis of unit costs paid by procurers
        (governments and/or donors) before it is distributed, generally for
        free, to users. In the private sector, costs reflect the prices paid by
        users themselves, typically through direct out-of-pocket expenditures.
        See the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-costs")}
        >
          Reader&apos;s Guide
        </Link>{" "}
        for more details.
      </p>

      {costData && (
        <p>
          The total current cost of contraceptive supplies used by women and
          girls in {toLowerCase(props.regionName)} is $
          {formatNumberAsHumanReadable(costData.total)} , which represents{" "}
          {formatPercentage(costData.share)} of the total supply cost across all
          low- and middle-income countries.
        </p>
      )}

      {metricsForSummary3?.mostCommonMethod && (
        <p>
          Costs are not evenly distributed across methods; the cost of{" "}
          {getMethodPlural(metricsForSummary3.mostCommonMethod.name)} accounts
          for the largest share of supply costs. At $
          {formatNumberAsHumanReadable(
            metricsForSummary3.mostCommonMethod.costs
          )}{" "}
          million this method accounts for{" "}
          {formatPercentage(metricsForSummary3.mostCommonMethod.percentage)} of
          the total supply cost.
        </p>
      )}

      <p>
        Contraceptive supply costs vary by country as show in the figure below.
      </p>

      <FpCcF3
        regionId={props.regionId}
        regionName={props.regionName}
        sectorId={selectedSectorId}
        onSectorChange={handleSectorChange}
      />

      {metricsForSummary4 && (
        <>
          <p>
            In {toLowerCase(props.regionName)}{" "}
            {formatPercentage(metricsForSummary4.publicSectorShare)} of costs
            are from the public sector. This includes the costs from users who
            go to the public sector, as well as the cost of the subsidy from
            private subsidized users. The roles of the public and private sector
            vary by country.
          </p>

          <p>
            The share of supply costs from the public sector ranges from{" "}
            {formatPercentage(metricsForSummary4.smallestPublicSectorShare)} to{" "}
            {formatPercentage(metricsForSummary4.largestPublicSectorShare)}{" "}
            across countries in {toLowerCase(props.regionName)}. Variation can
            be seen in the figure below.
          </p>
        </>
      )}

      <FpCcF4
        regionId={props.regionId}
        regionName={props.regionName}
        data={sectorData}
      />

      <p>
        The cost of contraceptive supplies is not simply a function of the
        number of contraceptive users. In some countries, the source and method
        mix of users and supply costs looks very similar, while in others stark
        contrasts are found. Similarities and differences between use and cost
        reflect differences in the methods used, the number of units required to
        achieve a year of protection, the sector in which the methods are
        obtained, and the relative cost of supplies in the public and private
        sector.
      </p>

      {publicSectorShareData && (
        <p>
          In {toLowerCase(props.regionName)}{" "}
          {formatPercentage(publicSectorShareData.publicSectorUserShare)} of
          users obtain their method from the public sector; while at the same
          time the public sector accounts for{" "}
          {formatPercentage(publicSectorShareData.publicSectorCostShare)} of
          supply costs.
        </p>
      )}

      <p>Variation by country is shown in the figure below.</p>

      <FpCcF5 regionId={props.regionId} regionName={props.regionName} />
    </>
  );
};

FpCsCurrentCosts.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default FpCsCurrentCosts;
