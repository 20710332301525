import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Table from "../../../Common/Box/Table";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { capitalizePhrase, formatNumber } from "../../../../utilities";

const number = 1;

const format = (n) =>
  n !== 0
    ? formatNumber(n, {
        significantDigits: 3,
      })
    : "";

const getSectorValue = (method, sectorName) => {
  const sector = method.sectors.find((sector) => sector.name === sectorName);

  return sector.years[0].value;
};

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child td:not(:first-child)": {
      minWidth: "6rem",
    },
  },
  total: {
    backgroundColor: theme.palette.area.background,
  },
}));

const FpCsT1 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/fp/cs/tables/1/${props.regionId}/5`
  );

  const classes = useStyles();

  return (
    <Table
      key="FP_CS_t1"
      number={number.toString()}
      title="Contraceptive Users by Sector"
      subtitle={`${capitalizePhrase(props.regionName)} by Method, 2019`}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th className={classes.total} />
            <th colSpan={2} />
            <th colSpan={2} className={classes.changeGroupBoundary}>
              Within Private
            </th>
          </tr>
          <tr>
            <th />
            <th className={classes.total}>Total</th>
            <th>Public</th>
            <th>Private</th>
            <th className={classes.changeGroupBoundary}>Subsidized</th>
            <th>Non-Subsidized</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((method) => (
              <tr key={method.name}>
                <td>{method.name}</td>
                <td className={classes.total}>
                  {format(getSectorValue(method, "Total"))}
                </td>
                <td>{format(getSectorValue(method, "Public"))}</td>
                <td>{format(getSectorValue(method, "Private"))}</td>
                <td className={classes.changeGroupBoundary}>
                  {format(getSectorValue(method, "Subsidized"))}
                </td>
                <td>{format(getSectorValue(method, "Non-subsidized"))}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </Table>
  );
};

FpCsT1.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default FpCsT1;
