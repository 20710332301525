import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import sum from "lodash/sum";
import Table from "../../../Common/Box/Table";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatNumber, formatPercentage } from "../../../../utilities";

const number = 4;

const format = (n) =>
  formatNumber(n, {
    significantDigits: 3,
  });

const calculateScenarioTotals = (methods) => {
  const years = methods
    .slice(1)
    .reduce(
      (accumulator, method) =>
        accumulator.map((value, index) => value + method.years[index]),
      methods[0].years
    );

  return {
    name: "Total",
    years,
  };
};

const summarizeMethod = (years) => {
  const mainYears = [years[0], years[6], years[11]];
  const change = mainYears[2] - mainYears[0];

  return {
    years: mainYears,
    change,
    percentageChange: mainYears[0] !== 0 ? (change / mainYears[0]) * 100 : 0,
    cumulativeTotal: sum(years.slice(2)),
  };
};

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  scenarioTitle: {
    borderBottom: `1px solid ${theme.table.border.secondary}`,
    borderTop: `1px solid ${theme.table.border.secondary}`,
    color: theme.palette.grays.gray5,
    fontWeight: "bold",
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& td.scenarioTitle": {
      borderBottom: `1px solid ${theme.table.border.secondary}`,
      borderTop: `1px solid ${theme.table.border.secondary}`,
      fontWeight: "bold",
      paddingTop: "1.5rem",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child td:not(:first-child)": {
      minWidth: "6rem",
    },
  },
}));

const ScenarioSubTable = (props) => {
  const classes = useStyles();

  const scenarioName = `${props.scenarioNumber}. ${props.data.name}`;

  const methods = [
    ...props.data.methods,
    calculateScenarioTotals(props.data.methods),
  ];

  return (
    <>
      <tr>
        <td colSpan={7} className="scenarioTitle">
          {scenarioName}
        </td>
      </tr>
      {methods.slice(0, -1).map((method) => {
        const summary = summarizeMethod(method.years);

        return (
          <tr key={method.name}>
            <td className={classes.nameGroupBoundary}>{method.name}</td>

            {summary.years.map((value, index) => (
              <td key={[2019, 2025, 2030][index]}>{format(value)}</td>
            ))}

            <td className={classes.changeGroupBoundary}>
              {format(summary.change)}
            </td>
            <td>
              {" "}
              {summary.percentageChange !== 0
                ? formatPercentage(summary.percentageChange)
                : ""}
            </td>

            <td className={classes.changeGroupBoundary}>
              {format(summary.cumulativeTotal)}
            </td>
          </tr>
        );
      })}
    </>
  );
};

const ScenariosTable = ({ data }) => {
  const classes = useStyles();

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <th className={classes.nameGroupBoundary} />
          <th colSpan={3}>Total Quantities</th>
          <th colSpan={2} className={classes.changeGroupBoundary}>
            Change (2019 to 2030)
          </th>
          <th className={classes.changeGroupBoundary}>
            10-year cumulative total
          </th>
        </tr>
        <tr>
          <th className={classes.nameGroupBoundary} />
          <th>2019</th>
          <th>2025</th>
          <th>2030</th>
          <th className={classes.changeGroupBoundary}>#</th>
          <th>%</th>
          <th className={classes.changeGroupBoundary}>(2021-2030)</th>
        </tr>
      </thead>
      <tbody>
        {data.map((scenario, index) => (
          <ScenarioSubTable
            key={scenario.name}
            scenarioNumber={index + 1}
            data={scenario}
          />
        ))}
      </tbody>
    </table>
  );
};

const MhCsT7 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mh/cs/tables/7/${props.regionId}`
  );

  return (
    <Table
      id="MH_CS_t7"
      number={number.toString()}
      title="Change in Quantities of Seven Priority Maternal Health Drugs Plus Two Emerging Drugs"
      subtitle={`${props.regionName} by Drug and Scenario, 2019-2030`}
    >
      {<ScenariosTable data={data ?? []} />}
    </Table>
  );
};

MhCsT7.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhCsT7;
