import { Link as RouterLink } from "react-router-dom";
import Footnote from "../../Common/Footnote";
import Information from "../../Common/Box/Information";

const MhB3 = () => (
  <Information key="MH_b3" number="3" title="Definitions">
    <p>
      <strong>Unit</strong>: The core unit of analysis for maternal health
      within LEAP is a &apos;case&apos;, which we define as a health
      intervention to prevent, manage or treat complications during pregnancy
      and childbirth that uses one of the maternal health drugs under review
      <Footnote number={28}>
        Misoprostol is also used to induce abortion and provide post-abortion
        care (PAC). These uses of misoprostol are captured in the{" "}
        <RouterLink to="/abortion">
          Abortion &amp; PAC Landscape Report
        </RouterLink>
        .
      </Footnote>{" "}
      (see Table 1).
    </p>
    <p>
      By focusing on &apos;cases&apos;, we are able to account for the fact that
      one pregnant woman may receive multiple interventions that use the same
      drug. Oxytocin, for example, could be used during the course of a single
      pregnancy for induction, augmentation, as well as PPH prevention and
      treatment, which would count as four cases.
    </p>
    <p>
      A &apos;case&apos; can include multiple doses of a drug, according to the
      recommended regimen for an intervention. For example, PPH prevention
      typically requires three 200 μg tablets of misoprostol; this would count
      as one case.
    </p>
    <p>
      Finally, not everyone who needs an intervention receives it. We address
      this in our results by presenting the proportion of cases for which
      interventions were needed and received, vs those that were needed but not
      received.
    </p>
    <p>
      <strong>Cost</strong>: The &apos;cost&apos; is the cost of the drug(s)
      provided to women. We determine cost based on the price paid by
      governments or donors to procure supplies for public sector distribution
      (generally at no charge to the user). We do not include in our cost
      figures products that were purchased but never used or the costs that
      would be associated with women who need but don&apos;t receive a drug.
    </p>
  </Information>
);

export default MhB3;
