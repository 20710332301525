import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
    },
    "& td": {
      padding: theme.table.cellPadding,
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& td:not(first-child)": {
      textAlign: "right",
    },
    "& th": {
      padding: theme.table.cellPadding,
    },
  },
}));

const RgMhT5 = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6">
        Table 5. Uterotonic Distribution Assumptions{" "}
      </Typography>

      <table className={classes.table}>
        <thead>
          <tr>
            <th>Drug</th>
            <th>Baseline</th>
            <th>Target</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Misoprostol</td>
            <td>11%</td>
            <td>6%</td>
          </tr>
          <tr>
            <td>Oxytocin</td>
            <td>89%</td>
            <td>40%</td>
          </tr>
          <tr>
            <td>Heat-stable carbetocin (HSC)</td>
            <td>0%</td>
            <td>54%</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>100%</td>
            <td>100%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RgMhT5;
