import { useState } from "react";
import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import { getSingular, getTooltip } from "../../../../utilities";

const number = 1;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Surgical: Vacuum aspiration":
      return theme.palette.areas.abortion.methods.mva;

    case "Medical: Miso only":
      return theme.palette.areas.abortion.methods.miso;

    case "Medical: Miso & mife":
      return theme.palette.areas.abortion.methods.mife;

    case "Surgical: Dilation (not costed)":
      return theme.palette.areas.abortion.methods.dilation;

    case "Least safe abortions (not costed)":
      return theme.palette.areas.abortion.methods.leastSafeAbortions;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = data
    .filter(({ name }) => name !== "Medical")
    .map((method) => ({
      name: method.name,
      data: method.regions.map(({ services }) => services),
      color: getSeriesColor(method.name, theme),
    }));

  return {
    chart: {
      type: "bar",
      width: 800,
      height: 400,
      backgroundColor: null,
      marginBottom: 70,
    },
    xAxis: {
      categories: data[0].regions.map(({ name }) => name),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      opposite: true,
      reversedStacks: false,
      max: absolute ? null : 100,
      labels: {
        format: absolute ? undefined : "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      bar: {
        stacking: absolute ? "normal" : "percent",
        borderWidth: 0,
        groupPadding: 0.1,
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      reversed: false,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Services", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const ApacCcF1 = (props) => {
  const [comparisonMode, setComparisonMode] = useState("relative");

  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  return (
    <Figure
      id="APAC_CC_f1"
      number={number.toString()}
      title="Abortion and PAC Services by Method"
      subtitle={`By ${getSingular(props.regionalUnitName)}, 2019`}
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        />
      }
      chartOptions={
        props.data?.length
          ? options(props.data, {
              comparisonMode,
            })
          : undefined
      }
    />
  );
};

ApacCcF1.propTypes = {
  regionalUnitName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      regions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          services: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ),
};

export default ApacCcF1;
