import { makeStyles } from "@material-ui/styles";
import Table from "../../Common/Box/Table";
import ExternalLink from "../../Common/ExternalLink";
import initialData from "../../../data/initialData.json";
import useCsvDownload from "../../../hooks/useCsvDownload";
import { formatNumber } from "../../../utilities";

const number = 3;

const format = (n) =>
  formatNumber(n, {
    prefix: "$",
    decimalPlaces: 2,
  });

const useStyles = makeStyles((theme) => ({
  groupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
    },
    "& th:not(:first-child)": {
      width: "10rem",
    },
    "& thead tr:last-child th": {
      fontWeight: "normal",
    },
  },
}));

const Component = () => {
  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const handleCsvDownload = () => {
    downloadCsv("/apac/tables/3/csv", `apac_t${number}.csv`);
  };

  const data = initialData["apac_t3"];

  return (
    <Table
      key="APAC_t3"
      number={number.toString()}
      title="Price of Medical Abortion Supplies"
      subtitle="Unit Price per Pill and Service by Region"
      source={
        <div>
          Source:{" "}
          <ExternalLink
            href="https://www.medab.org/our-research#cost-of-medical-abortion-products"
            text="IPPF Medical Abortion Commodities Database"
          />
        </div>
      }
      onDownloadCsv={handleCsvDownload}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th colSpan={2} className={classes.groupBoundary}>
              Median Price per Pill
            </th>
            <th colSpan={3} className={classes.groupBoundary}>
              Price per Medical Abortion (&lt;12 weeks)
            </th>
          </tr>
          <tr>
            <th />
            <th className={classes.groupBoundary}>Misoprostol</th>
            <th>Mifepristone</th>
            <th className={classes.groupBoundary}>
              Combipack
              <br />
              (1 mifepristone +<br />4 misoprostol)
            </th>
            <th>
              Mifepristone &amp; misoprostol
              <br />
              purchased separately
              <br />
              (1 mifepristone +<br />4 misoprostol)
            </th>
            <th>
              Misoprostol only
              <br />
              (8 pills)
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.region}>
              <td>{item.region}</td>
              <td className={classes.groupBoundary}>{format(item.miso)}</td>
              <td>{format(item.mife)}</td>
              <td className={classes.groupBoundary}>
                {item.combipack != null ? format(item.combipack) : null}
              </td>
              <td>{format(item.notCombipack)}</td>
              <td>{format(item.misoOnly)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Table>
  );
};

export default Component;
