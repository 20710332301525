import PropTypes from "prop-types";
import factors from "../../../../data/factors.json";
import Select from "../Select";
import ChooserOptions from "./ChooserOptions";

const IncomeGroupChooser = (props) => {
  const incomeGroups = Object.keys(factors.incomeGroups).map((key) => ({
    name: key,
    value: factors.incomeGroups[key],
  }));

  return (
    <Select
      label="Income group"
      value={props.value}
      onChange={(value) => {
        props.onChange?.(parseInt(value, 10));
      }}
    >
      <ChooserOptions
        options={[
          {
            name: "All",
            value: 0,
          },
          ...incomeGroups,
        ]}
      />
    </Select>
  );
};

IncomeGroupChooser.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default IncomeGroupChooser;
