import { Link, Typography } from "@material-ui/core";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const ContraceptionFutureScenarios = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);

    e.preventDefault();
  };

  return (
    <div>
      <Typography variant="h1">Future Scenarios</Typography>

      <p>
        For contraception we used only one future scenario for several reasons.
        First, model-based projections of contraceptive use developed by the UN
        Population Division provide a strong foundation for projecting future
        changes. Such projections do not exist for menstrual hygiene, abortion
        and PAC, or maternal health. Second, changes in contraceptive use
        influence the demographic projections that underlie the rest of LEAP,
        therefore having a single trend allows for aligned demographic
        projections across the analysis. And finally, while there is a clear
        directionality of progress for many other areas of reproductive health
        (e.g. an increase in the safety of abortion, or an increase in the
        coverage of key maternal health interventions), contraceptive use is
        more complex. The scenarios for the other health areas within LEAP are
        built on countries &apos;progressing&apos; with this progress being
        informed by the next highest income group. This concept does not apply
        to contraception as both levels of use and method mix are driven by a
        range of country-specific contextual factors.
      </p>
      <p>
        Therefore, we use the median-variant estimates of contraceptive users
        from the UN Population Division to give us the most likely trajectory
        forward in each country. We then layer in projected changes to method
        mix informed by recent patterns of change at the regional level (See{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-users-by-method")}
        >
          Users by Method
        </Link>{" "}
        above).
      </p>
      <p>
        Many factors will influence shifts in method mix; we are not trying to
        be predictive of these changes but rather look at where we would be if
        recent patterns continue. This provides a starting point for discussions
        about what may lie ahead. The further into the future our projections
        run, the less certainty we have; this uncertainty is represented in the
        figures with a dotted line.
      </p>
    </div>
  );
};

export default ContraceptionFutureScenarios;
