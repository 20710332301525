import PropTypes from "prop-types";
// import { Checkbox, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import IndicatorChooser from "../../IndicatorChooser";
import { getTooltip, toTitleCase } from "../../../../utilities";

const number = 2;

const useStyles = makeStyles((theme) => ({
  controls: {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: "fit-content",
  },
  excludeLargeCountries: {
    fontSize: "0.875rem",
  },
}));

const options = (data, props) => (theme) => {
  const financial = props.indicatorId === 1;

  const series = [
    {
      data: data.slice(1).map(({ value }) => value),
      color: theme.palette.areas.menstrualHygiene.primary,
    },
  ];

  return {
    chart: {
      type: "column",
      width: 500,
      height: 300,
      inverted: true,
      backgroundColor: null,
    },
    xAxis: {
      categories: data.slice(1).map(({ country }) => country),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        formatter: function () {
          return (
            (financial ? "$" : "") + this.axis.defaultLabelFormatter.call(this)
          );
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        pointWidth: 20,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip(
          props.indicatorName,
          this.y,
          undefined,
          financial
            ? {
                prefix: "$",
              }
            : undefined
        );
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const Controls = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.controls}>
      <IndicatorChooser
        area="menstrual-hygiene"
        value={props.indicatorId}
        onChange={props.onIndicatorChange}
      />
      {/* <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={props.excludeLargeCountries}
            color="primary"
            onChange={(e) => {
              props.onExcludeLargeCountriesChange(e.target.checked);
            }}
          />
        }
        label="Exclude China & India"
        checked={props.excludeLargeCountries}
        classes={{
          label: classes.excludeLargeCountries,
        }}
      /> */}
    </div>
  );
};

const MhmCpF2 = (props) => (
  <Figure
    id="MHM_CP_f2"
    number={number.toString()}
    fileStem={`mhm_cp_f${number}`}
    title={`${props.indicatorQualifier} ${toTitleCase(props.methodName)}`}
    subtitle="Top 10 Countries, 2019"
    controls={
      <BoxControls>
        <Controls
          indicatorId={props.ndicatorId}
          excludeLargeCountries={props.excludeLargeCountries}
          onIndicatorChange={props.onIndicatorChange}
          onExcludeLargeCountriesChange={props.onExcludeLargeCountriesChange}
        />
      </BoxControls>
    }
    chartOptions={
      props.data &&
      options(props.data, {
        indicatorId: props.indicatorId,
        indicatorName: props.indicatorName,
      })
    }
  />
);

MhmCpF2.propTypes = {
  methodName: PropTypes.string.isRequired,
  indicatorId: PropTypes.number.isRequired,
  indicatorName: PropTypes.string.isRequired,
  indicatorQualifier: PropTypes.string.isRequired,
  excludeLargeCountries: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  onIndicatorChange: PropTypes.func,
  onExcludeLargeCountriesChange: PropTypes.func,
};

export default MhmCpF2;
