import { makeStyles } from "@material-ui/styles";
import Information from "../../../Common/Box/Information";

const useStyles = makeStyles(() => ({
  content: {
    width: "100%",
    justifyContent: "flex-start",
  },
}));

const ApacCcB1 = () => {
  const classes = useStyles();

  return (
    <Information key="APAC_CC_b1" number="1" title="Scope" classes={classes}>
      <p>
        The methodology for LEAP was developed to produce results for all low-
        and middle-income countries. Many inputs are reliant of regional and
        global estimates, given the limited data availability on abortion and
        PAC. While we make global and regional results available, they should be
        viewed in this context.
      </p>
      <p>
        Regional results are for low- and middle-income countries within the
        region; these are not reflective of the full region as high income
        countries are excluded.
      </p>
    </Information>
  );
};

export default ApacCcB1;
