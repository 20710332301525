import PropTypes from "prop-types";
import ApacCsF2 from "../Figures/ApacCsF2";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatPercentage,
  getRegionName,
  toLowerCase,
} from "../../../../utilities";
import ApacCsT2 from "../Tables/ApacCsT2";

const calculateMetrics = (data) => {
  if (!data) {
    return {
      mvaShare: 0,
      medicalShare: 0,
      leastSafeAbortionShare: 0,
    };
  }

  const totalServices = data[0].methods[0].services;

  return {
    mvaShare: (data[0].methods[1].services / totalServices) * 100,
    medicalShare: (data[0].methods[2].services / totalServices) * 100,
    leastSafeAbortionShare: (data[0].methods[4].services / totalServices) * 100,
  };
};

const ApacCsS2 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/apac/cs/tables/2/${props.regionId}`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        In {toLowerCase(props.regionName)}, {formatPercentage(metrics.mvaShare)}{" "}
        of services are surgical using vacuum aspiration and{" "}
        {formatPercentage(metrics.medicalShare)} are medical services. The
        proportion of services that are least safe is{" "}
        {formatPercentage(metrics.leastSafeAbortionShare)}, compared with the
        average of 13% across all low- and middle-income countries.
      </p>

      <ApacCsT2
        regionId={props.regionId}
        regionName={props.regionName}
        data={data}
      />

      <ApacCsF2
        regionId={props.regionId}
        regionName={props.regionName}
        regionShortName={getRegionName(props.regionId, "short")}
      />
    </>
  );
};

ApacCsS2.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default ApacCsS2;
