import PropTypes from "prop-types";
import Figure from "../../../Common/Box/Figure";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { capitalizePhrase, getTooltip } from "../../../../utilities";

const number = 2;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Disposable":
      return theme.palette.areas.menstrualHygiene.methods.disposable;

    case "Reusable pads":
      return theme.palette.areas.menstrualHygiene.methods.reusable;

    case "Cups":
      return theme.palette.areas.menstrualHygiene.methods.reusableCup;

    default:
      return "black";
  }
};

const options = (data) => (theme) => {
  const series = data.map((series) => ({
    ...series,
    color: getSeriesColor(series.name, theme),
  }));

  return {
    chart: {
      type: "column",
      width: 500,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: ["Costs"],
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        formatter: function () {
          return `$${this.axis.defaultLabelFormatter.call(this)}`;
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Cost", this.y, this.percentage, {
          prefix: "$",
        });
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const MhmCsF2 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cs/figures/2/${props.regionId}`
  );

  return (
    <Figure
      id="MHM_CS_f2"
      number={number.toString()}
      fileStem={`mhm_cs_f${number}`}
      title="Cost of Purpose-Made Menstrual Hygiene Products"
      subtitle={`${capitalizePhrase(props.regionName)}, 2019`}
      chartOptions={
        data &&
        options(data, {
          regionName: props.regionName,
          regionShortName: props.regionShortName,
        })
      }
    />
  );
};

MhmCsF2.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
  regionShortName: PropTypes.string.isRequired,
};

export default MhmCsF2;
