import PropTypes from "prop-types";
import { FormControl, FormHelperText, NativeSelect } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const getSupplies = (area) => {
  switch (area) {
    case "contraception":
      return [
        { name: "Sterilization", value: 17 },
        { name: "Implant", value: 5 },
        { name: "IUD", value: 4 },
        { name: "Injectable", value: 6 },
        { name: "Pill", value: 15 },
        { name: "Condom", value: 2 },
      ];

    case "menstrual-hygiene":
      return [
        { name: "Disposables", value: 14 },
        { name: "Reusable pads", value: 16 },
        { name: "Cups", value: 3 },
      ];

    case "abortion":
      return [
        { name: "Manual vacuum aspiration kits", value: 7 },
        { name: "Misoprostol", value: 10 },
        { name: "Mifepristone", value: 8 },
      ];

    case "maternal-health":
      return [
        { name: "Iron-folic acid", value: 111 },
        { name: "Hydralazine", value: 116 },
        { name: "Methyldopa", value: 125 },
        { name: "Magnesium Sulfate", value: 110 },
        { name: "Oxytocin", value: 105 },
        { name: "Misoprostol", value: 120 },
        { name: "Metronidazole", value: 1011 },
        { name: "Heat-stable carbetocin", value: 108 },
        { name: "Tranexamic acid", value: 126 },
      ];

    default:
      return [];
  }
};

const useStyles = makeStyles({
  component: {
    height: "fit-content",
  },
});

const SupplyChooser = (props) => {
  const classes = useStyles();

  const supplies = getSupplies(props.area);

  return (
    <FormControl>
      <NativeSelect
        value={props.value}
        onChange={(e) => {
          const id = parseInt(e.target.value, 10);
          const supply = supplies.find(({ value }) => value === id);

          props.onChange?.({
            id,
            name: supply.name,
          });
        }}
        className={classNames(props.className, classes.component)}
      >
        {supplies.map(({ name, value }) => (
          <option key={value} value={value}>
            {name}
          </option>
        ))}
      </NativeSelect>
      <FormHelperText>Choose the supply for your report</FormHelperText>
    </FormControl>
  );
};

SupplyChooser.propTypes = {
  area: PropTypes.oneOf([
    "contraception",
    "menstrual-hygiene",
    "abortion",
    "maternal-health",
  ]).isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default SupplyChooser;
