import { useRef } from "react";
import PropTypes from "prop-types";
import { useAnalytics } from "../../../hooks/useAnalytics";
import { csvDownloadGoalId } from "../../../hooks/useCsvDownload";
import ChartPlaceholder from "../Placeholders/ChartPlaceholder";
import { getDownloadFileStem } from "../../../utilities";
import Box from "./Box";
import Chart from "./Chart";

const Figure = (props) => {
  const highchartRef = useRef();

  const analytics = useAnalytics();

  const handleCsvDownload = () => {
    if (!props.onDownloadCsv) {
      highchartRef.current.chart.downloadCSV();
    } else {
      props.onDownloadCsv();
    }

    analytics.trackGoal(csvDownloadGoalId);
  };

  const fileStem = getDownloadFileStem(props.id, props.number, props.selection);

  return (
    <Box
      {...props}
      caption={`Figure ${props.number}`}
      highchartRef={props.chartOptions && highchartRef}
      onDownloadCsv={handleCsvDownload}
    >
      {props.chartOptions ? (
        <Chart
          highchartRef={highchartRef}
          options={props.chartOptions}
          filename={fileStem}
          annotations={props.annotations}
        />
      ) : (
        <ChartPlaceholder />
      )}
    </Box>
  );
};

Figure.propTypes = {
  id: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  selection: PropTypes.string,
  chartOptions: PropTypes.func,
  annotations: PropTypes.bool,
  onDownloadCsv: PropTypes.func,
};

export default Figure;
