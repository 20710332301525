import { Link } from "@material-ui/core";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const AbortionScenarios = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <div>
      <p>
        Looking ahead, based on demographic changes and changes in contraceptive
        use, the overall number of abortion and PAC services is expected to
        increase, although not evenly, or in all countries. In addition, there
        are likely to be shifts in the safety profile of services, and increased
        use of medical methods, particularly the use of mifepristone in
        combination with misoprostol.
      </p>

      <p>
        For the analysis of possible future trends presented in this section, we
        developed three scenarios:
      </p>

      <ul>
        <li>
          <strong>Scenario 1: Maintain safety profile and method mix.</strong>{" "}
          This scenario accounts for demographic changes, changes in
          contraceptive uptake (and thus resulting changes in fertility) and
          contraceptive method mix (based on existing trend data). However, we
          maintain the same distribution of the safety profile of abortion
          services (i.e. the proportion that are safe/less safe/least safe), and
          the same distribution of surgical and medical methods, currently found
          in each income group.
        </li>
        <li>
          <strong>Scenario 2: Shift safety profile.</strong> This scenario
          assumes the same demographic and contraceptive use changes in Scenario
          1. But it improves the safety profile of services by matching the
          proportion of abortion services that are safe/less safe/least safe to
          the average pattern seen in the next highest income group. This shift
          occurs over time following a linear trend. However if in 2019 a
          country already has a distribution with more safe abortions than what
          they would shift to using this average pattern, then they keep this
          original distribution through to 2030.
        </li>
        <li>
          <strong>Scenario 3: Shift safety profile and method mix.</strong> This
          scenario is the same as Scenario 2 but also builds in a shift to
          greater use of medical (especially combined use of misoprostol and
          mifepristone) rather than surgical methods among safe and less safe
          abortions.
        </li>
      </ul>

      <p>
        See the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-future-scenarios")}
        >
          Reader&apos;s Guide
        </Link>{" "}
        for more information about the scenarios.
      </p>
    </div>
  );
};

export default AbortionScenarios;
