import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const AbortionIntroduction = ({ children }) => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <>
      <p>
        Abortion and Post-Abortion Care (PAC) services are an important
        component of the sexual and reproductive health and rights for women and
        girls globally. All people have the right to make decisions about their
        own bodies and health, including if, when and with whom to have
        children, and safe abortion and PAC services are essential to achieve
        this right.
      </p>
      <p>{children}</p>
      <p>
        In this report, the unit of analysis is a &apos;service&apos;: this
        could be an abortion service to end a pregnancy or a post abortion care
        (PAC) service to complete an incomplete abortion or miscarriage.
        Additionally, the &apos;cost&apos; is the price of the supplies to
        provide the abortion or PAC service, and not any associated fees (e.g.
        to cover a health provider&apos;s time to deliver a service, or provide
        any pre- or post-service counselling). For the purpose of LEAP, we have
        focused on the costing the supplies used for the following methods:
        surgical abortion services provided via vacuum aspiration, medical
        abortion services, and PAC services. Costs for surgical abortion (vacuum
        aspiration) are based on the costs of manual vacuum aspiration (MVA)
        kits with aspirator and cannulae plus one dose of misoprostol, while
        costs for medical abortion are based on costs of the drugs (misoprostol
        with or without mifepristone). These costs are generally paid by the
        consumer (i.e. the person seeking an abortion), though PAC-related costs
        may be more likely to be covered by public health systems.
      </p>
      <p>
        For information across all low- and middle-income countries, please
        refer to the{" "}
        <RouterLink to="/abortion">
          Abortion &amp; PAC Landscape Report
        </RouterLink>
        . Additional methodological details can also be found in the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-scope")}
        >
          Reader&apos;s Guide
        </Link>
        .
      </p>
    </>
  );
};

AbortionIntroduction.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AbortionIntroduction;
