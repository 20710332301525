import PropTypes from "prop-types";
import { AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import useChapterDetails from "../../hooks/useChapterDetails";
import { ReactComponent as ApplicationLogo } from "../../images/leap_logo.svg";
import { ReactComponent as RhscLogo } from "../../images/rhsc_logo.svg";
import ChapterMenu from "./ChapterMenu";
import MainMenu from "./MainMenu";

const useStyles = makeStyles((theme) => ({
  applicationLogo: {
    height: 40,
  },
  chapterMenu: {
    overflow: "auto",
  },
  component: {
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
  },
  logos: {
    alignItems: "center",
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(6),
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  menu: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  rhscLogo: {
    height: 48,
  },
  toolbar: {
    backgroundColor: theme.palette.navBar.toolbar.background,
    display: "flex",
    height: 80,
    justifyContent: "center",
  },
}));

const NavBar = ({ onOpenReadersGuide }) => {
  const chapterDetails = useChapterDetails();
  const classes = useStyles();

  return (
    <AppBar position="fixed" color="default" className={classes.component}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.logos}>
          <a href="https://www.rhsupplies.org" target="_blank" rel="noreferrer">
            <RhscLogo className={classes.rhscLogo} />
          </a>
          <ApplicationLogo className={classes.applicationLogo} />
        </div>
        <div className={classes.menu}>
          <MainMenu onOpenReadersGuide={onOpenReadersGuide} />
        </div>
      </Toolbar>
      <div className={classes.chapterMenu}>
        <ChapterMenu selectedChapter={chapterDetails.slug} />
      </div>
    </AppBar>
  );
};

NavBar.propTypes = {
  onOpenReadersGuide: PropTypes.func,
};

export default NavBar;
