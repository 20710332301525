import PropTypes from "prop-types";
import Select from "../Common/Box/Select";
import ChooserOptions from "../Common/Box/Choosers/ChooserOptions";

const periods = [
  {
    name: "2025",
    value: 0,
  },
  {
    name: "2030",
    value: 1,
  },
  {
    name: "# change (2019-2030)",
    value: 2,
  },
  {
    name: "% change (2019-2030)",
    value: 3,
  },
  {
    name: "Cumulative total (2021-2030)",
    value: 4,
  },
];

const PeriodChooser = (props) => (
  <Select
    label="Year(s)"
    value={props.value}
    onChange={(value) => {
      const id = parseInt(value, 10);

      props.onChange?.(id);
    }}
  >
    <ChooserOptions
      options={periods.filter(
        (product) => ![2, 5].includes(props.indicatorId) || product.value !== 4
      )}
    />
  </Select>
);

PeriodChooser.propTypes = {
  value: PropTypes.number,
  indicatorId: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

PeriodChooser.defaultProps = {
  allowAll: true,
};

export const getPeriodDescription = (periodId) =>
  periods.find((period) => period.value === periodId).name;

export default PeriodChooser;
