import { List, ListItem, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  component: {
    color: theme.palette.areas.general.primary,
    width: theme.navigation.section.width,
    "& > li > .MuiListItemText-root": {
      marginBottom: 0,
      marginTop: theme.spacing(2),
      "& .MuiListItemText-primary": {
        fontWeight: "bold",
      },
    },
  },
  submenu: {
    marginLeft: theme.spacing(),
    paddingBottom: 0,
    paddingTop: 0,
    "& .MuiListItemText-root": {
      marginBottom: 0,
      marginTop: 0,
    },
  },
}));

const ListLink = (props) => (
  <ListItem button component={Link} to={props.to}>
    <ListItemText primary={props.label} />
  </ListItem>
);

const CustomReportsMenu = () => {
  const classes = useStyles();

  return (
    <List dense className={classes.component}>
      <ListItem>
        <ListItemText primary="Contraception" />
      </ListItem>
      <List className={classes.submenu} dense>
        <ListLink label="Custom Landscape" to="/custom/contraception" />
        <ListLink
          label="Comparison Report"
          to="/custom/contraception/comparison"
        />
        <ListLink
          label="Product Deep Dive"
          to="/custom/contraception/deep-dive"
        />
      </List>
      <ListItem>
        <ListItemText primary="Menstrual Hygiene" />
      </ListItem>
      <List className={classes.submenu} dense>
        <ListLink label="Custom Landscape" to="/custom/menstrual-hygiene" />
        <ListLink
          label="Comparison Report"
          to="/custom/menstrual-hygiene/comparison"
        />
        <ListLink
          label="Product Deep Dive"
          to="/custom/menstrual-hygiene/deep-dive"
        />
      </List>
      <ListItem>
        <ListItemText primary="Abortion and PAC" />
      </ListItem>
      <List className={classes.submenu} dense>
        <ListLink label="Custom Landscape" to="/custom/abortion" />
        <ListLink label="Comparison Report" to="/custom/abortion/comparison" />
        <ListLink label="Product Deep Dive" to="/custom/abortion/deep-dive" />
      </List>
      <ListItem>
        <ListItemText primary="Maternal Health" />
      </ListItem>
      <List className={classes.submenu} dense>
        <ListLink label="Custom Landscape" to="/custom/maternal-health" />
        <ListLink
          label="Comparison Report"
          to="/custom/maternal-health/comparison"
        />
        <ListLink
          label="Product Deep Dive"
          to="/custom/maternal-health/deep-dive"
        />
      </List>
    </List>
  );
};

export default CustomReportsMenu;
