import PropTypes from "prop-types";
import SideBySide from "../../../Common/SideBySide";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { formatPercentage } from "../../../../utilities";
import ApacCcT1 from "../Tables/ApacCcT1";

const ApacCcS1 = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/apac/cc/tables/1/${props.regionalUnitId}`
  );

  return (
    <SideBySide
      component={
        <ApacCcT1 regionalUnitName={props.regionalUnitName} data={data} />
      }
    >
      <p>
        In 2019, there were a total of 72.5 million abortion and post-abortion
        care services across all low- and middle-income countries.
      </p>

      <p>
        The largest share of abortion and PAC services occur among women living
        in {data?.[0].name}, this region accounts for{" "}
        {formatPercentage(data?.[0].share ?? 0)} of the total.
      </p>
    </SideBySide>
  );
};

ApacCcS1.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default ApacCcS1;
