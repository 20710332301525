import PropTypes from "prop-types";
import Select from "../../Common/Box/Select";
import ChooserOptions from "../../Common/Box/Choosers/ChooserOptions";

const YearChooser = (props) => (
  <Select
    label="Year"
    value={props.value}
    onChange={(value) => {
      const year = parseInt(value, 10);

      props.onChange?.(year);
    }}
  >
    <ChooserOptions
      options={[2019, 2025, 2030].map((year) => ({
        name: year.toString(),
        value: year,
      }))}
    />
  </Select>
);

YearChooser.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default YearChooser;
