import { Typography } from "@material-ui/core";
import ExternalLink from "../../Common/ExternalLink";

const ContraceptionDataSources = () => (
  <div>
    <Typography variant="h1">Data Sources</Typography>

    <p>
      The following were the primary data sources used in the development of our
      estimates for contraceptive supplies:
    </p>

    <ul>
      <li>
        <ExternalLink
          href="https://www.un.org/en/development/desa/population/theme/family-planning/cp_model.asp"
          text="UNPD Estimates and Projections of Family Planning Indicators 2020"
        />{" "}
        were used for the annual modern contraceptive users;
      </li>
      <li>
        <ExternalLink
          href="https://dhsprogram.com/"
          text="Demographic and Health Surveys (DHS)"
        />{" "}
        were used for method mix and source mix;
      </li>
      <li>
        <ExternalLink
          href="https://mics.unicef.org/"
          text="Multiple Indicator Cluster Surveys (MICS)"
        />{" "}
        were used for method mix and source mix (in a limited number of
        countries that included this question);
      </li>
      <li>
        <ExternalLink
          href="https://www.pmadata.org/"
          text="Performance Monitoring for Accountability (PMA) 2020"
        />{" "}
        surveys were used for method mix;
      </li>
      <li>
        <ExternalLink
          href="https://www.un.org/development/desa/pd/node/3285"
          text="World Contraceptive Use 2020"
        />{" "}
        was used for method mix;
      </li>
      <li>
        <ExternalLink
          href="https://www.rhsupplies.org/activities-resources/tools/rh-viz/"
          text="RH Viz"
        />{" "}
        historic data was used to estimate public sector unit prices;
      </li>
      <li>
        <ExternalLink
          href="https://www.guttmacher.org/report/adding-it-up-investing-in-sexual-reproductive-health-2019-methodology"
          text="Adding It Up 2019 Methodology Tables"
        />{" "}
        were used to estimate supply costs;
      </li>
      <li>
        A range of sources as documented in{" "}
        <ExternalLink
          href="https://gh.bmj.com/content/6/4/e004635"
          text="this journal article"
        />{" "}
        were used to estimate private sector unit prices and;
      </li>
      <li>
        <ExternalLink
          href="https://www.dktinternational.org/contraceptive-social-marketing-statistics/"
          text="DKT Social Marketing Statistics"
        />
        ,{" "}
        <ExternalLink
          href="https://www.dktinternational.org/resources/results/"
          text="DKT International Sales Reports"
        />
        ,{" "}
        <ExternalLink
          href="https://www.psi.org/monthly-impact-dashboard/"
          text="PSI Monthly Performance Reports"
        />
        , and unpublished data shared by MSI Reproductive Choices were used to
        estimate the role of subsidy within the private sector.
      </li>
    </ul>
  </div>
);

export default ContraceptionDataSources;
