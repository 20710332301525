import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Table from "../../../Common/Box/Table";
import { formatNumber, getSingular } from "../../../../utilities";

const number = 1;

const format = (n) =>
  n !== 0
    ? formatNumber(n, {
        significantDigits: 3,
      })
    : "";

const useStyles = makeStyles((theme) => ({
  changeGroupBoundary: {
    borderLeft: `1px solid ${theme.table.border.primary}`,
  },
  nameGroupBoundary: {
    borderRight: `1px solid ${theme.table.border.primary}`,
  },
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& tbody tr:last-child": {
      fontWeight: "bold",
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
    "& tr:last-child td:not(:first-child)": {
      minWidth: "6rem",
    },
  },
  total: {
    backgroundColor: theme.palette.area.background,
  },
}));

const ApacCcT1 = (props) => {
  const classes = useStyles();

  return (
    <Table
      key="APAC_CC_t1"
      number={number.toString()}
      title="Abortion and PAC Services by Type"
      subtitle={`By ${getSingular(props.regionalUnitName)}, 2019`}
    >
      {props.data ? (
        <table className={classes.table}>
          <thead>
            <tr>
              <th />
              <th className={classes.total}>Total</th>
              <th>Abortion</th>
              <th>PAC</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((countryGroup) => (
              <tr key={countryGroup.name}>
                <td>{countryGroup.name}</td>

                {countryGroup.contraceptionTypes.map((contraceptionType) => (
                  <td
                    key={contraceptionType.name}
                    className={
                      contraceptionType.name === "Total"
                        ? classes.total
                        : undefined
                    }
                  >
                    {format(contraceptionType.services)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div />
      )}
    </Table>
  );
};

ApacCcT1.propTypes = {
  regionalUnitName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      contraceptionTypes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          services: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ),
};

export default ApacCcT1;
