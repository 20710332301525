import PropTypes from "prop-types";
import MhmCcF4 from "../Figures/MhmCcF4";
import MhmCcF5 from "../Figures/MhmCcF5";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatPercentage,
  getQualitativeChangeDirection,
  toLowerCase,
} from "../../../../utilities";
import SideBySide from "../../../Common/SideBySide";

const getQualitativeChangeMagnitude = (percentage) => {
  if (Math.abs(percentage) < 0.5) {
    return "no";
  } else if (Math.abs(percentage) < 10) {
    return "slight";
  } else if (Math.abs(percentage) < 50) {
    return "modest";
  } else if (Math.abs(percentage) < 200) {
    return "substantial";
  } else {
    return "dramatic";
  }
};

const calculateMetrics = (data) => {
  if (!data) {
    return {
      percentageUsingPurposeMadeProductsUnderScenario2: 0,
      changeInPercentageUsingPurposeMadeProductsUnderScenario2: 0,
      percentageUsingReusableProductsUnderScenario3: 0,
    };
  }

  const menstruatorsUnderScenario1 = data.reduce(
    (acc, method) => acc + method.scenarios[0].menstruators,
    0
  );

  const percentageUsingPurposeMadeProductsUnderScenario1 =
    ((data[0].scenarios[0].menstruators + data[1].scenarios[0].menstruators) /
      menstruatorsUnderScenario1) *
    100;

  const percentageUsingPurposeMadeProductsUnderScenario2 =
    ((data[0].scenarios[1].menstruators + data[1].scenarios[1].menstruators) /
      menstruatorsUnderScenario1) *
    100;

  const changeInPercentageUsingPurposeMadeProductsUnderScenario2 =
    percentageUsingPurposeMadeProductsUnderScenario2 -
    percentageUsingPurposeMadeProductsUnderScenario1;

  const percentageUsingReusableProductsUnderScenario3 =
    (data[1].scenarios[2].menstruators /
      data.reduce(
        (accumulator, method) => accumulator + method.scenarios[2].menstruators,
        0
      )) *
    100;

  return {
    percentageUsingPurposeMadeProductsUnderScenario2,
    changeInPercentageUsingPurposeMadeProductsUnderScenario2,
    percentageUsingReusableProductsUnderScenario3,
  };
};

const MhmCcFutureUse = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/mhm/cc/figures/4/${props.regionId}`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        With an increase in the use of purpose-made products (scenarios 2 &amp;
        3), there would be a{" "}
        {getQualitativeChangeMagnitude(
          metrics.changeInPercentageUsingPurposeMadeProductsUnderScenario2
        )}{" "}
        {getQualitativeChangeDirection(
          metrics.changeInPercentageUsingPurposeMadeProductsUnderScenario2
        )}{" "}
        in the use of purpose made-products in {toLowerCase(props.regionName)}{" "}
        {getQualitativeChangeDirection(
          metrics.changeInPercentageUsingPurposeMadeProductsUnderScenario2,
          true
        )}{" "}
        {formatPercentage(
          metrics.percentageUsingPurposeMadeProductsUnderScenario2
        )}{" "}
        in 2030.
      </p>

      <SideBySide
        component={<MhmCcF4 regionName={props.regionName} data={data} />}
      >
        Assuming a shift to increased use of purpose-made products coupled with
        an increase in reusable product use (Scenario 3), by 2030{" "}
        {formatPercentage(
          metrics.percentageUsingReusableProductsUnderScenario3
        )}{" "}
        of menstruators in {toLowerCase(props.regionName)} would be using
        reusable products.
      </SideBySide>

      <p>
        Patterns in the use of purpose-made menstrual hygiene products vary
        across countries. Use the dropdown to explore differences by product
        between the three scenarios in 2030.
      </p>

      <MhmCcF5 regionId={props.regionId} regionName={props.regionName} />
    </>
  );
};

MhmCcFutureUse.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default MhmCcFutureUse;
