import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import IndicatorChooser from "../../IndicatorChooser";
import {
  getIndicatorDetails,
  getMethodPlural,
  getQuantitiesAndCostsMethodPlural,
  getTooltip,
  toTitleCase,
} from "../../../../utilities";

const number = 3;

const getSeriesColor = (name, theme) => {
  switch (name) {
    case "Public":
      return theme.palette.sectors.public;

    case "Subsidized":
      return theme.palette.sectors.subsidized;

    case "Non-subsidized":
      return theme.palette.sectors.private;

    default:
      return "black";
  }
};

const options = (data, props) => (theme) => {
  const financial = props.indicatorDetails.id === 1;

  const series = [
    {
      name: "Public",
      data: data.map((country) => country.sectors[0].value),
      color: getSeriesColor("Public", theme),
    },
    {
      name: "Subsidized",
      data: data.map((country) => country.sectors[1].value),
      color: getSeriesColor("Subsidized", theme),
    },
    {
      name: "Non-subsidized",
      data: data.map((country) => country.sectors[2].value),
      color: getSeriesColor("Non-subsidized", theme),
    },
  ];

  return {
    chart: {
      type: "column",
      width: 600,
      height: 400,
      inverted: true,
      backgroundColor: null,
    },
    xAxis: {
      categories: data.map(({ name }) => name),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        formatter: function () {
          return (
            (financial ? "$" : "") + this.axis.defaultLabelFormatter.call(this)
          );
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        pointWidth: 20,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip(
          props.indicatorDetails.name,
          this.y,
          undefined,
          financial
            ? {
                prefix: "$",
              }
            : undefined
        );
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const FpCpF3 = (props) => {
  const indicatorDetails = getIndicatorDetails(props.indicatorId);

  return (
    <Figure
      id="FP_CP_f3"
      number={number.toString()}
      title={`${indicatorDetails.qualifier} ${toTitleCase(
        props.indicatorId === 3 || props.indicatorId === 1
          ? getQuantitiesAndCostsMethodPlural(props.methodName)
          : getMethodPlural(props.methodName)
      )}`}
      subtitle="Top 10 Countries, 2019"
      controls={
        <BoxControls>
          <IndicatorChooser
            area="contraception"
            value={props.indicatorId}
            onChange={props.onIndicatorChange}
          />
        </BoxControls>
      }
      chartOptions={
        props.data &&
        options(props.data, {
          indicatorDetails,
        })
      }
    />
  );
};

FpCpF3.propTypes = {
  methodName: PropTypes.string.isRequired,
  indicatorId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      sectors: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ),
  onIndicatorChange: PropTypes.func,
};

export default FpCpF3;
