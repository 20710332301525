import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import SideBySide from "../../../Common/SideBySide";
import ApacCsF3 from "../Figures/ApacCsF3";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import { useReadersGuide } from "../../../../hooks/useReadersGuide";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getRegionName,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  if (!data) {
    return {
      totalCost: 0,
      medicalMethodShare: 0,
      misoprostolShare: 0,
    };
  }

  const medicalMethodCost = data[1].cost + data[2].cost;
  const totalCost = data[0].cost + medicalMethodCost;

  return {
    totalCost,
    medicalMethodShare: (medicalMethodCost / totalCost) * 100,
    misoprostolShare: (data[1].cost / medicalMethodCost) * 100,
  };
};

const ApacCsS3 = (props) => {
  const readersGuide = useReadersGuide();

  const { areaData: data } = useAreaQuery(
    `/custom/apac/cs/figures/3/${props.regionId}`
  );

  const metrics = calculateMetrics(data);

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <>
      <p>
        We analyze the cost of the supplies used to provide abortion and PAC
        services, and not any associated fees. Costs for surgical abortion
        (vacuum aspiration) are based on the costs of manual vacuum aspiration
        (MVA) kits with aspirator and cannulae plus one dose of misoprostol,
        while costs for medical abortion are based on costs of the drugs
        (misoprostol with or without mifepristone; see the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("abortion-costs")}
        >
          Reader&apos;s Guide
        </Link>{" "}
        for more detail). These costs are generally paid by the consumer (i.e.
        the person seeking an abortion), though PAC-related costs may be more
        likely to be covered by public health systems.
      </p>

      <SideBySide
        component={
          <ApacCsF3
            regionName={props.regionName}
            regionShortName={getRegionName(props.regionName, "short")}
            data={data}
          />
        }
      >
        The cost of abortion and PAC supplies in {props.regionName} in 2019 was{" "}
        {formatNumberAsHumanReadable(metrics.totalCost, {
          prefix: "$",
        })}
        . Medical methods account for{" "}
        {formatPercentage(metrics.medicalMethodShare)} of the total costs, and
        within that misoprostol makes up{" "}
        {formatPercentage(metrics.misoprostolShare)} of the cost.
      </SideBySide>
    </>
  );
};

ApacCsS3.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default ApacCsS3;
