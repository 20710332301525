import { useState } from "react";
import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import MethodChooser from "../../../Common/Box/Choosers/MethodChooser";
import Figure from "../../../Common/Box/Figure";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import theme from "../../../../theme";
import { getTooltip } from "../../../../utilities";

const number = 11;

const getSeries = (data, theme) => {
  return [
    {
      name: "1: Maintain",
      data: data.map((country, index) => ({
        x: index,
        y: country.scenarios[0].value,
      })),
      color: theme.palette.scenarios.scenario1,
      marker: {
        fillColor: theme.palette.scenarios.scenario1,
      },
    },
    {
      name: "2: Increase coverage",
      data: data.map((country, index) => ({
        x: index,
        y: country.scenarios[1].value,
      })),
      color: theme.palette.scenarios.scenario2,
      marker: {
        fillColor: theme.palette.scenarios.scenario2,
      },
    },
    {
      name: "3: Increase coverage & new tech",
      data: data.map((country, index) => ({
        x: index,
        y: country.scenarios[2].value,
      })),
      color: theme.palette.scenarios.scenario3,
      marker: {
        fillColor: theme.palette.scenarios.scenario3,
      },
    },
  ];
};

const drawRange = (chart, countryCount, countryIndex, y1, y2) => {
  const countryOffset = countryCount - countryIndex;

  const xAxis = chart.xAxis[0];
  const yAxis = chart.yAxis[0];

  const itemGap = xAxis.translate(countryCount - 1.5);
  const itemCenter = xAxis.top + xAxis.translate(countryOffset) + itemGap;

  chart.renderer
    .path([
      "M",
      yAxis.left + yAxis.translate(y1),
      itemCenter,
      "L",
      yAxis.left + yAxis.translate(y2),
      itemCenter,
    ])
    .attr({
      "z-index": -1,
      "stroke-width": 1,
      stroke: theme.palette.grays.gray4,
    })
    .add();
};

const drawRanges = (chart, data) => {
  const countryCount = data.length;

  for (let i = 0; i < countryCount; i++) {
    const y1 = Math.min(...data[i].scenarios.map((scenario) => scenario.value));
    const y2 = Math.max(...data[i].scenarios.map((scenario) => scenario.value));

    drawRange(chart, countryCount, i, y1, y2);
  }
};

const options = (data) => (theme) => {
  const series = getSeries(data, theme);

  return {
    chart: {
      type: "scatter",
      width: 1000,
      height: 600,
      inverted: true,
      backgroundColor: null,
      events: {
        redraw: function () {
          drawRanges(this, data, series);
        },
      },
    },
    xAxis: {
      categories: data.map((country) => country.name),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      opposite: true,
      labels: {
        formatter: function () {
          return "$" + this.axis.defaultLabelFormatter.call(this);
        },
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          symbol: "circle",
          radius: 4,
          lineWidth: 1,
          lineColor: "silver",
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      reversed: false,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Cost", this.y, undefined, {
          prefix: "$",
        });
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const MhCcF11 = (props) => {
  const [selectedMethodId, setSelectedMethodId] = useState(0);

  const { areaData: data } = useAreaQuery(
    `/custom/mh/cc/figures/9/${props.regionalUnitId}/${selectedMethodId}/1`
  );

  const handleMethodChange = (methodId) => {
    setSelectedMethodId(methodId);
  };

  return (
    <Figure
      id="MH_CC_f11"
      number={number.toString()}
      title="Future Cost of Seven Priority Maternal Health Drugs Plus Two Emerging Drugs"
      subtitle={`By ${props.regionalUnitName} and Future Scenario for Selected Drug(s), 2030`}
      chartOptions={data && options(data)}
      controls={
        <BoxControls>
          <MethodChooser
            area="maternal-health"
            value={selectedMethodId}
            onChange={handleMethodChange}
          />
        </BoxControls>
      }
      annotations={true}
    />
  );
};

MhCcF11.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcF11;
