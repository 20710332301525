import PropTypes from "prop-types";
import FpCsF8 from "../Figures/FpCsF8";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import {
  formatNumberAsHumanReadable,
  formatPercentage,
  getMethodPlural,
  getQualitativeChangeDirection,
  getQualitativeChangeMagnitude,
  toLowerCase,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  if (!data) {
    return {
      totalCost2019: 0,
      totalCost2030: 0,
      change: 0,
      percentageChange: 0,
      mostRapidlyGrowingMethod: {
        name: "",
        change: 0,
        percentageChange: 0,
      },
    };
  }

  const totalCost2019 = data.reduce(
    (accumulator, method) => accumulator + method.years[0].value,
    0
  );

  const totalCost2030 = data.reduce(
    (accumulator, method) => accumulator + method.years[2].value,
    0
  );

  const change = totalCost2030 - totalCost2019;

  const methodGrowths = data
    .map((method) => {
      const change = method.years[2].value - method.years[0].value;

      return {
        name: getMethodPlural(method.name),
        change,
        percentageChange: (change / method.years[0].value) * 100,
      };
    })
    .sort((a, b) => b.change - a.change);

  return {
    totalCost2019,
    totalCost2030,
    change,
    percentageChange: (change / totalCost2019) * 100,
    mostRapidlyGrowingMethod: methodGrowths[0],
  };
};

const FpCsFutureCosts = (props) => {
  const { areaData: data } = useAreaQuery(
    `/custom/fp/cs/figures/6/${props.regionId}/1`
  );

  const metrics = calculateMetrics(data);

  return (
    <>
      <p>
        We analyze &quot;cost&quot; based on the last price paid for a
        contraceptive method before it is actually used or obtained by the
        consumer. We employ this definition because it is at this
        &quot;handover&quot; point in a product&apos;s value chain where price
        acts as the principal cost barrier to access. For products made
        available through the public sector, cost is calculated on the basis of
        unit costs paid by procurers (governments and/or donors) before it is
        distributed, generally for free, to users. In the private sector, costs
        reflect the prices paid by users themselves, typically through direct
        out-of-pocket expenditures. Note that all costs are based on current
        supply costs, held constant for all years through to 2030.
      </p>

      <p>
        If current trends continue, we anticipate there would be a{" "}
        {getQualitativeChangeMagnitude(metrics.percentageChange)}{" "}
        {getQualitativeChangeDirection(metrics.percentageChange)} in the total
        cost of contraceptive supplies in {toLowerCase(props.regionName)},{" "}
        {getQualitativeChangeDirection(metrics.percentageChange, true).replace(
          "to",
          "from"
        )}{" "}
        {formatNumberAsHumanReadable(metrics.totalCost2019, {
          prefix: "$",
        })}{" "}
        in 2019 to{" "}
        {formatNumberAsHumanReadable(metrics.totalCost2030, {
          prefix: "$",
        })}{" "}
        in 2030 (
        {formatNumberAsHumanReadable(metrics.change, {
          prefix: "$",
          prefixWithSign: true,
        })}
        ,{" "}
        {formatPercentage(metrics.percentageChange, {
          prefixWithSign: true,
        })}
        ).
      </p>

      <p>
        As with users, if current patterns in the evolution of method mix
        persist, change in costs would not be even across all methods. The cost
        of {metrics.mostRapidlyGrowingMethod.name} would see the most rapid
        growth in absolute terms (
        {formatNumberAsHumanReadable(metrics.mostRapidlyGrowingMethod.change, {
          prefix: "$",
          prefixWithSign: true,
        })}
        ,{" "}
        {formatPercentage(metrics.mostRapidlyGrowingMethod.percentageChange, {
          prefixWithSign: true,
        })}
        ).
      </p>

      <FpCsF8 regionName={props.regionName} data={data} />
    </>
  );
};

FpCsFutureCosts.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
};

export default FpCsFutureCosts;
