import axios from "axios";

const service = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const fetchData = (resource, options) => service.get(resource, options);

const api = {
  fetchData,
};

export default api;
