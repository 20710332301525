import { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import BoxControls from "../../Common/Box/BoxControls";
import IncomeGroupChooser from "../../Common/Box/Choosers/IncomeGroupChooser";
import Table from "../../Common/Box/Table";
import unitPrices from "../../../data/unitPrices.json";
import useCsvDownload from "../../../hooks/useCsvDownload";
import {
  formatNumber,
  getDownloadFileStem,
  getIncomeGroup,
} from "../../../utilities";

const key = "FP_t10";
const number = 2;

const getSubtitle = (incomeGroupId) => {
  let incomeGroupDescription;

  switch (incomeGroupId) {
    case 1:
      incomeGroupDescription = "Low-Income";

      break;

    case 2:
      incomeGroupDescription = "Lower-Middle-Income";

      break;

    case 3:
      incomeGroupDescription = "Upper-Middle-Income";

      break;

    default:
      incomeGroupDescription = "All Low- and Middle-Income";

      break;
  }

  return `${incomeGroupDescription} Countries, 2019`;
};

const getData = (incomeGroupId) => {
  switch (incomeGroupId) {
    case 1:
      return unitPrices["Low"];

    case 2:
      return unitPrices["Lower-Middle"];

    case 3:
      return unitPrices["Upper-Middle"];

    default:
      return unitPrices["All"];
  }
};

const format = (n) =>
  formatNumber(n, {
    prefix: "$",
    decimalPlaces: 2,
  });

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    fontSize: theme.table.fontSize,
    "& tbody tr": {
      borderTop: `1px solid ${theme.table.border.primary}`,
    },
    "& tbody tr:first-child": {
      borderTop: `1px solid ${theme.table.border.secondary}`,
    },
    "& td": {
      padding: theme.table.cellPadding,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    "& td.category": {
      textAlign: "left",
    },
    "& th": {
      padding: theme.table.cellPadding,
      whiteSpace: "nowrap",
    },
  },
}));

const Component = () => {
  const [selectedIncomeGroupId, setSelectedIncomeGroupId] = useState(0);

  const downloadCsv = useCsvDownload();

  const classes = useStyles();

  const data = getData(selectedIncomeGroupId);

  const handleIncomeGroupChange = (incomeGroupId) => {
    setSelectedIncomeGroupId(incomeGroupId);
  };

  const handleCsvDownload = () => {
    const fileStem = getDownloadFileStem(
      key,
      number,
      getIncomeGroup(selectedIncomeGroupId)
    );

    downloadCsv(
      `/fp/tables/10/${selectedIncomeGroupId}/csv`,
      `${fileStem}.csv`
    );
  };

  return (
    <Table
      key={key}
      number={number.toString()}
      title="Unit Prices by Method and Sector"
      subtitle={getSubtitle(selectedIncomeGroupId)}
      controls={
        <BoxControls>
          <IncomeGroupChooser
            value={selectedIncomeGroupId}
            onChange={handleIncomeGroupChange}
          />
        </BoxControls>
      }
      onDownloadCsv={handleCsvDownload}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th colSpan={2} />
            <th colSpan={2}>Within Private</th>
          </tr>
          <tr>
            <th />
            <th>Public</th>
            <th>Subsidized</th>
            <th>Non-Subsidized</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data)
            .filter((key) => key !== "Other")
            .map((key) => (
              <tr key={key}>
                <td>{key}</td>

                {data[key].map((value, index) => (
                  <td key={index}>{value && format(value)}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </Table>
  );
};

export default Component;
