import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import BoxControls from "../../../Common/Box/BoxControls";
import MethodChooser from "../../../Common/Box/Choosers/MethodChooser";
import ScenarioChooser from "../../../Common/Box/Choosers/ScenarioChooser";
import Figure from "../../../Common/Box/Figure";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import theme from "../../../../theme";
import {
  darken,
  getSingular,
  getTooltip,
  lighten,
} from "../../../../utilities";

const number = 8;

const getSeries = (data) => {
  return [
    {
      name: "2019",
      data: data.map((country, index) => ({
        x: index,
        y: country.years[0].value,
      })),
      color: lighten(theme.palette.areas.maternalHealth.primary),
      marker: {
        fillColor: lighten(theme.palette.areas.maternalHealth.primary),
      },
    },
    {
      name: "2025",
      data: data.map((country, index) => ({
        x: index,
        y: country.years[1].value,
      })),
      color: theme.palette.areas.maternalHealth.primary,
      marker: {
        fillColor: theme.palette.areas.maternalHealth.primary,
      },
    },
    {
      name: "2030",
      data: data.map((country, index) => ({
        x: index,
        y: country.years[2].value,
      })),
      color: darken(theme.palette.areas.maternalHealth.primary),
      marker: {
        fillColor: darken(theme.palette.areas.maternalHealth.primary),
      },
    },
  ];
};

const drawRange = (chart, countryCount, countryIndex, y1, y2) => {
  const countryOffset = countryCount - countryIndex;

  const xAxis = chart.xAxis[0];
  const yAxis = chart.yAxis[0];

  const itemGap = xAxis.translate(countryCount - 1.5);
  const itemCenter = xAxis.top + xAxis.translate(countryOffset) + itemGap;

  chart.renderer
    .path([
      "M",
      yAxis.left + yAxis.translate(y1),
      itemCenter,
      "L",
      yAxis.left + yAxis.translate(y2),
      itemCenter,
    ])
    .attr({
      "z-index": -1,
      "stroke-width": 1,
      stroke: theme.palette.grays.gray4,
    })
    .add();
};

const drawRanges = (chart, data) => {
  const countryCount = data.length;

  for (let i = 0; i < countryCount; i++) {
    const y1 = Math.min(...data[i].years.map((year) => year.value));
    const y2 = Math.max(...data[i].years.map((year) => year.value));

    drawRange(chart, countryCount, i, y1, y2);
  }
};

const options = (data) => (theme) => {
  const series = getSeries(data);

  return {
    chart: {
      type: "scatter",
      width: 1000,
      height: 600,
      inverted: true,
      backgroundColor: null,
      events: {
        redraw: function () {
          drawRanges(this, data, series);
        },
      },
    },
    xAxis: {
      categories: data.map((country) => country.name),
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    yAxis: {
      title: undefined,
      opposite: true,
      labels: {
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          symbol: "circle",
          radius: 4,
          lineWidth: 1,
          lineColor: "silver",
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      reversed: false,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip(`Cases (${this.series.name})`, this.y);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const useStyles = makeStyles((theme) => ({
  choosers: {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.spacing(2),
    width: "fit-content",
  },
}));

const MhCcF8 = (props) => {
  const [selectedScenarioId, setSelectedScenarioId] = useState(5);
  const [selectedMethodId, setSelectedMethodId] = useState(0);

  const { areaData: data } = useAreaQuery(
    `/custom/mh/cc/figures/8/${props.regionalUnitId}/${selectedScenarioId}/${selectedMethodId}`
  );

  const classes = useStyles();

  const handleScenarioChange = (scenarioId) => {
    setSelectedScenarioId(scenarioId);
  };

  const handleMethodChange = (methodId) => {
    setSelectedMethodId(methodId);
  };

  return (
    <Figure
      id="MH_CC_f8"
      number={number.toString()}
      title="Change in Cases that Receive Seven Priority Maternal Health Drugs Plus Two Emerging Drugs"
      subtitle={`By ${getSingular(
        props.regionalUnitName
      )} for Selected Scenario and Drug(s), 2019-2030`}
      chartOptions={data && options(data)}
      controls={
        <BoxControls>
          <div className={classes.choosers}>
            <ScenarioChooser
              area="maternal-health"
              value={selectedScenarioId}
              onChange={handleScenarioChange}
            />
            <MethodChooser
              area="maternal-health"
              value={selectedMethodId}
              onChange={handleMethodChange}
            />
          </div>
        </BoxControls>
      }
      annotations={true}
    />
  );
};

MhCcF8.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcF8;
