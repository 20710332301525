const MenstrualHygieneScenarios = () => (
  <div>
    <p>
      Based on demographic changes, changes in contraceptive use, and changes in
      fertility rates, the overall number of menstruators is likely to increase
      (though not in all countries). In addition, there are likely to be shifts
      in the proportions of menstruators using a) purpose-made products and b)
      reusable products. However, changes in the number of menstruators using
      purpose-made and reusable products are impossible to accurately predict
      due in part to a lack of historical data.
    </p>
    <p>
      For the analysis of possible future trends presented in this section, we
      developed three scenarios:
    </p>
    <ul>
      <li>
        <strong>Scenario 1: Maintain Product Use.</strong> This scenario
        accounts for demographic changes and changes in contraceptive use, but
        does not change the proportion of menstruators using purpose-made
        products or reusable products.
      </li>
      <li>
        <strong>Scenario 2: Increase Purpose-Made Use.</strong> This scenario
        accounts for the same demographic changes and changes in contraceptive
        use as Scenario 1, and also adjusts for increased use of purpose-made
        products informed by the next highest income group within the same
        region.
      </li>
      <li>
        <strong>Scenario 3: Increase Purpose-Made and Reusable Use.</strong>{" "}
        This scenario is the same as Scenario 2 but also builds in a shift to
        greater use of reusable products, set to reach 25% of those using
        purpose-made products by 2030.
      </li>
    </ul>
  </div>
);

export default MenstrualHygieneScenarios;
