import { useState } from "react";
import PropTypes from "prop-types";
import BoxControls from "../../../Common/Box/BoxControls";
import Figure from "../../../Common/Box/Figure";
import { capitalizePhrase, getTooltip } from "../../../../utilities";

const options = (data, props) => (theme) => {
  const absolute = props.comparisonMode !== "relative";

  const series = [
    {
      name: "Hydralazine",
      data: data.slice(0, -1).map(({ methods }) => methods[0].services),
      color: theme.palette.areas.maternalHealth.methods.hydralazine,
    },
    {
      name: "Methyldopa",
      data: data.slice(0, -1).map(({ methods }) => methods[1].services),
      color: theme.palette.areas.maternalHealth.methods.methyldopa,
    },
    {
      name: "Out-of-scope",
      data: data.slice(0, -1).map(({ methods }) => methods[2].services),
      color: theme.palette.areas.maternalHealth.methods.outOfScope,
    },

    {
      name: "Needed: Not received",
      data: data.slice(0, -1).map(({ methods }) => methods[3].services),
      color: theme.palette.areas.maternalHealth.methods.needsDidNotGet,
    },
  ];

  return {
    chart: {
      type: "column",
      width: 600,
      height: 300,
      backgroundColor: null,
    },
    xAxis: {
      categories: [
        props.regionShortName,
        "Low",
        "Lower-Middle",
        "Upper-Middle",
      ],
      title: undefined,
      labels: {
        autoRotation: undefined,
        useHTML: true,
        style: {
          color: theme.figure.axis.primary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
      plotLines: [
        {
          color: theme.figure.gridLineColor,
          dashStyle: "solid",
          value: 0.5,
          width: 3,
          zIndex: 2,
        },
      ],
    },
    yAxis: {
      title: undefined,
      reversedStacks: false,
      labels: {
        format: absolute ? undefined : "{value}%",
        style: {
          color: theme.figure.axis.secondary,
          fontSize: theme.figure.axis.fontSize,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: absolute ? "normal" : "percent",
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      reversed: true,
      itemStyle: {
        fontSize: theme.figure.legend.fontSize,
      },
    },
    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      outside: true,
      formatter: function () {
        return getTooltip("Services", this.y, this.percentage);
      },
      style: {
        fontSize: "0.875rem",
        color: theme.palette.tooltip.primary,
      },
    },
    exporting: {
      chartOptions: {
        title: undefined,
        subtitle: undefined,
      },
      enabled: false,
    },
    series,
  };
};

const MhCsF2 = (props) => {
  const [comparisonMode, setComparisonMode] = useState("relative");

  const handleComparisonModeToggle = () => {
    setComparisonMode((comparisonMode) =>
      comparisonMode === "absolute" ? "relative" : "absolute"
    );
  };

  return (
    <Figure
      id="MH_CS_f2"
      number="2"
      title="Cases that Need and Receive Drugs for Hypertension Management"
      subtitle={`${capitalizePhrase(
        props.regionName
      )} and Comparison by Income Group, 2019`}
      controls={
        <BoxControls
          comparisionMode={comparisonMode}
          onToggleComparisonMode={handleComparisonModeToggle}
        />
      }
      chartOptions={
        props.data &&
        options(props.data, {
          regionName: props.regionName,
          regionShortName: props.regionShortName,
          comparisonMode,
        })
      }
    />
  );
};

MhCsF2.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string.isRequired,
  regionShortName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      methods: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          services: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ),
};

export default MhCsF2;
