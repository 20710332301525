import { Link } from "@material-ui/core";
import Information from "../../Common/Box/Information";
import { useReadersGuide } from "../../../hooks/useReadersGuide";

const FpB1 = () => {
  const readersGuide = useReadersGuide();

  const handleReadersGuideOpen = (slug) => (e) => {
    readersGuide.setSlug(slug);
    readersGuide.setOpen(true);

    e.preventDefault();
  };

  return (
    <Information key="FP_b1" number="1" title="Definitions">
      <p>
        <strong>Unit</strong>: The &apos;unit&apos; of analysis for
        contraception in LEAP is a &apos;user&apos; defined as a person using a
        modern contraceptive method (see Analysis Approach below for full list
        of methods included). We calculate contraceptive use and measure
        contraceptive prevalence based on the women using methods. Contraceptive
        users may use a method themselves, or rely on protection provided by
        their partner&apos;s method use. While in some places in this report we
        use the term &apos;women and girls&apos; for readability purposes, we
        recognize that some users may identify as transgender men and non-binary
        people.
      </p>
      <p>
        <strong>Cost</strong>: The &apos;cost&apos; reflects the last price paid
        for a contraceptive before it is actually used or obtained by the user.
        We employ this definition because it is at this &quot;handover&quot;
        point in a product&apos;s value chain where price acts as the principal
        cost barrier to access. For products made available through the public
        sector, cost is calculated on the basis of unit costs paid by procurers
        (Governments and/or donors) before it is distributed, generally for
        free, to users. In the private sector, costs reflect the prices paid by
        users themselves, typically through direct out-of-pocket expenditures.
      </p>
      <p>
        Note total public sector costs also include any contributions paid by
        them for subsidized supplies sold in the private sector. See Box 2 for
        more information.
      </p>
      <p>
        The costs presented in LEAP reflect the volumes used by individuals in a
        given year, not what is necessarily bought or &quot;procured&quot; in
        that year, as the latter, often referred to as &quot;procurement
        costs&quot;, are typically a function of broader programmatic
        considerations, such as availability (or lack thereof) of resources, the
        desire to fill supply pipelines and maintain adequate inventory levels,
        or simply the volumes of product already in the supply chain.
      </p>

      <p>
        See the{" "}
        <Link
          underline="always"
          onClick={handleReadersGuideOpen("contraception-costs")}
        >
          Reader&apos;s Guide
        </Link>{" "}
        for more detail.
      </p>
    </Information>
  );
};

export default FpB1;
