import { useState } from "react";
import PropTypes from "prop-types";
import useAreaQuery from "../../../../hooks/useAreaQuery";
import ApacCcT2 from "../Tables/ApacCcT2";
import {
  formatPercentage,
  getQualitativeChangeDirection,
  getScenarioNumber,
} from "../../../../utilities";

const calculateMetrics = (data) => {
  if (!data) {
    return {
      minimumPercentageChange: 0,
      maximumPercentageChange: 0,
    };
  }

  const percentageChanges = data.map(
    ({ percentageChange }) => percentageChange
  );

  return {
    minimumPercentageChange: Math.min(...percentageChanges),
    maximumPercentageChange: Math.max(...percentageChanges),
  };
};

const ApacCcS4 = (props) => {
  const [selectedScenarioId, setSelectedScenarioId] = useState(5);

  const { areaData: data } = useAreaQuery(
    `/custom/apac/cc/tables/2/${props.regionalUnitId}/${selectedScenarioId}`
  );

  const selectedScenarioName = `Scenario ${getScenarioNumber(
    selectedScenarioId
  )}`;

  const metrics = calculateMetrics(data);

  const handleScenarioChange = (scenarioId) => {
    setSelectedScenarioId(scenarioId);
  };

  return (
    <>
      <p>
        Between 2019 and 2030, the total number of abortion and PAC services
        would increase by 3% in low- and middle-income countries if changes in
        contraceptive use follow the trajectories established for the other
        sections of this LEAP analysis.
      </p>

      <p>
        This ranges from a{" "}
        {getQualitativeChangeDirection(metrics.minimumPercentageChange)} of{" "}
        {formatPercentage(Math.abs(metrics.minimumPercentageChange))} to an{" "}
        {getQualitativeChangeDirection(metrics.maximumPercentageChange)} of{" "}
        {formatPercentage(Math.abs(metrics.maximumPercentageChange))} across{" "}
        {props.regionalUnitName}.
      </p>

      <ApacCcT2
        regionalUnitName={props.regionalUnitName}
        scenarioId={selectedScenarioId}
        scenarioName={selectedScenarioName}
        data={data}
        onScenarioChange={handleScenarioChange}
      />
    </>
  );
};

ApacCcS4.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default ApacCcS4;
