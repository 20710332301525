import PropTypes from "prop-types";
import MhCcF8 from "../Figures/MhCcF8";

const MhCcS11 = (props) => {
  return (
    <>
      <p>
        The pace of change varies by region, as shown in the figure below. Use
        the dropdowns to explore changes by drug(s).
      </p>

      <MhCcF8
        regionalUnitId={props.regionalUnitId}
        regionalUnitName={props.regionalUnitName}
      />
    </>
  );
};

MhCcS11.propTypes = {
  regionalUnitId: PropTypes.number.isRequired,
  regionalUnitName: PropTypes.string.isRequired,
};

export default MhCcS11;
